import { EnterpriseSelector } from "./../../../../core/states/enterprise.state.selector";
import { LicenseStateSelector } from "./../../../../core/states/license.state.selector";
import { SubscriptionType } from "./../../../../core/model/subscription.state";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { SubscriptionState } from "../../../../core/model/subscription.state";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DialogAddUserComponent } from "./dialog-add-user.component";
import { RoleEntity } from "../../../../core/model/userRole.model";
import { FeatureState, FeatureType } from "../../../../core/model/feature.state";

@Component({
  selector: "app-dialog-license-limit-exceed",
  templateUrl: "./dialog-license-limit-exceed.component.html",
  styleUrls: ["./dialog-license-limit-exceed.component.scss"],
})
export class DialogLicenseLimitExceedComponent implements OnInit {
  msg: string;
  feature: FeatureState;
  subscription: SubscriptionState;
  upgradeOption: boolean = false;
  role: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogAddUserComponent>,
    private enterpriseSelector: EnterpriseSelector,
    private licenseSelector: LicenseStateSelector
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.feature = this.data.feature;

      if (this.feature == null) {
        this.msg = "Feature not found.";
        return;
      }

      this.subscription = this.licenseSelector.getOrgSubscription(
        this.enterpriseSelector.getCurrentOrgId()
      );
      var orgUser = this.enterpriseSelector.getCurrentOrgUser();
      this.role = orgUser?.role;


      if (this.feature.isRestricted) {
        if (
          this.role &&
          (this.role.toUpperCase() == RoleEntity[RoleEntity.OWNER] ||
            this.role.toUpperCase() == RoleEntity[RoleEntity.ADMIN])
        ) {
          if (this.subscription.type == SubscriptionType.Free) {
            this.upgradeOption = true;
            this.msg =
            "Your organization has hit one of the limits set in your Free Tier Plan. Enable Full Tier Pay-As-You-Go Plan to remove the restriction.";
            return;
          } else {
            this.msg =
            "Your organization has hit one of the limits set by the system put in place as a security safeguard. Please contact our customer support immediately at (646) 868-8838 or support@everleagues.com for help.";
            return;
          }
        } else {
          if (this.subscription.type == SubscriptionType.Free) {
            this.msg =
            "This organization have hit one of the limits set in the organization's Free Tier Plan. We have notified the owner of the account to have the restriction removed. Please try again later.";
            return;
          } else {
            this.msg =
            "This organization have hit one of the limits set by the system, put in place as a security safeguard. We have notified the owner of the account to have the restriction removed. Please try again later.";
            return;
          }
        }
      } else {
        this.msg =`Current usage exceeds limit. ${this.licenseSelector.getFeatureRemaining(this.feature)} ${this.feature.unit} remaining.`;
        return ;
      }
      this.dialogRef.close();
    }
  }
}
