import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OUService } from '../../../../core/services/ou.service';
import { TeamService } from '../../../../core/services/team.service';
import { UserService } from '../../../../core/services/user.service';
import { forkJoin, Observable } from 'rxjs';
import { OrgUserState, OUState, TeamState, TeamRole } from '../../../../core/model/org.state';
import { SubSink } from 'subsink';
import { RoleEntity } from '../../../../core/model/userRole.model';
import { EnterpriseSelector } from '../../../../core/states/enterprise.state.selector';
import { OrgHub } from '../../../../core/hub/org.hub';
import { Select } from '@ngxs/store';
import { EnterpriseState } from '../../../../core/states/enterprise.state';

@Component({
  selector: 'app-dialog-delete-user',
  templateUrl: './dialog-delete-user.component.html',
  styleUrls: ['./dialog-delete-user.component.scss']
})
export class DialogDeleteUserComponent implements OnInit, OnDestroy {
  isLoading = false;
  isLoadingTeams = false;
  hasTeamOwners = false;
  ownedTeams: {id: string, name: string}[] = [];
  hasClientAssignment = false;
  hasEmployeeAssignment = false;

  selectedUser: OrgUserState;
  orgTeams: TeamState[] = [];
  orgUsers: OrgUserState[] = [];

  // @Select(EnterpriseState.currentTeams)
  // currentTeams$: Observable<TeamState[]>;

  private _subSink: SubSink;

  get orgCoWorkers(): OrgUserState[] {
    return this.orgUsers.filter(x => RoleEntity[x.role] === RoleEntity.ADMIN ||
      RoleEntity[x.role] === RoleEntity.OWNER ||
      RoleEntity[x.role] === RoleEntity.COWORKER);
  };

  get orgClients(): OrgUserState[] {
    return this.orgUsers.filter(x => RoleEntity[x.role] === RoleEntity.CLIENT);
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogDeleteUserComponent>, private enterpriseSelector: EnterpriseSelector, private orgHub: OrgHub) {

    this._subSink = new SubSink();
  }

  ngOnInit() {
    this.selectedUser = this.data.selectedUser;
    this.orgUsers = this.data.orgUsers;

    // this.orgTeams = this.enterpriseSelector.getCurrentTeams();
    // this._subSink.sink = this.currentTeams$.subscribe(state => {
    //   this.orgTeams = state;
    // });

    if (!this.enterpriseSelector.isCurrentPersonalOrg) {
      this.isLoadingTeams = true;
      // find current owned teams
      this.orgHub
        .getOwnedTeams(this.selectedUser.userId)
        .then((res) => {
          this.isLoadingTeams = false;
          if (res.length > 0) {
            // find team states
            this.ownedTeams = res;
            this.hasTeamOwners = true;
          }
        })
        .catch((err) => {
          console.error(err);
          this.isLoadingTeams = false;
        });
      // let currentOwnedTeam = this.enterpriseSelector
      //   .getCurrentTeamMembers()
      //   .filter(
      //     (x) =>
      //       x.userId === this.selectedUser.userId && x.role === TeamRole.Owner
      //   );
      // if (currentOwnedTeam.length > 0) {
      //   // find team states
      //   currentOwnedTeam.forEach((t) => {
      //     let teamState = this.orgTeams.find((x) => x.id === t.teamId);
      //     console.log({ ...teamState });
      //     this.ownedTeams.push({ ...teamState });
      //   });
      //   this.hasTeamOwners = true;
      // }
    }

    //!!-- TO DO, check for assigned clients, employees
    // let resClient = this.ouService.getClientList(this.ouId);
    // let resEmployee = this.ouService.getEmployeeList(this.ouId);

    // forkJoin([resOwnedTeam, resClient, resEmployee]).subscribe(results => {
    //   this.initLoading = false;

    //   this.teamOwners = results[0];
    //   if (results[0].length > 0) {
    //     this.hasTeamOwners = true;
    //   }
    //   if (results[1]) {
    //     let userData = results[1].filter(x => x.id === this.userId)[0];
    //     if (userData && userData.associates && userData.associates.length > 0) {
    //       this.hasEmployeeAssignment = true;
    //     }
    //   }
    //   if (results[2]) {
    //     let userData = results[2].filter(x => x.id === this.userId)[0];
    //     if (userData && userData.associates && userData.associates.length > 0) {
    //       this.hasClientAssignment = true;
    //     }
    //   }
    // });
  }

  deleteUser() {
    this.isLoading = true;
    this.orgHub.deleteOrgUsers(this.selectedUser.ouId, this.selectedUser.userId)
      .subscribe(res => {
        this.isLoading = false;
        if (!res) {
          this.dialogRef.close(res);
        } else {
          this.dialogRef.close('success');
        }
      }, err => {
        this.isLoading = false;
        this.dialogRef.close(err);
      });
  }

  ngOnDestroy() {
    if (this._subSink) this._subSink.unsubscribe();
  }
}
