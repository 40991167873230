import { EnterpriseSelector } from "./../../core/states/enterprise.state.selector";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MembershipReqService } from "../../core/services/membership-req.service";
import { RoleEntity } from "../../core/model/userRole.model";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "dialog-ps-join-req-link",
  templateUrl: "./dialog-ps-join-req-link.component.html",
  styleUrls: ["./dialog-ps-join-req-link.component.scss"],
})
export class DialogPSJoinReqLinkComponent implements OnInit {
  link: string;
  linkLoading: boolean = false;

  constructor(
    private enterpriseSelector: EnterpriseSelector,
    private snackBar: MatSnackBar,
    private membershipReqService: MembershipReqService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.getLink();
  }

  private async getLink() {
    const orgId = this.enterpriseSelector.getCurrentOrgId();
    const partnerRole = RoleEntity[RoleEntity.PARTNER].toLowerCase();

    this.linkLoading = true;
    this.membershipReqService
      .getJoinReqLink(orgId, partnerRole, orgId)
      .then((res) => {
        this.linkLoading = false;
        this.link = res;
      })
      .catch((err) => {
        this.linkLoading = false;
        console.log(err);
      });
  }

  async copy(link: any) {
    await navigator.clipboard.writeText(link);
    this.openSnackBar(
      this.translateService.instant("REPEAT.LINK_COPIED"),
      this.translateService.instant("REPEAT.OK")
    );
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}
