import { Injectable, Injector } from "@angular/core";
import { IFeedRedirectHandler } from "./IFeedRedirectHandler";
import { NotificationEventType } from "../enum/notification-event-type.enum";
import { EnterpriseSelector } from "../states/enterprise.state.selector";
import { Feed } from "../model/feed";
import { TranslateService } from "@ngx-translate/core";
import { UIService } from "../services/ui.service";
import { PluginManager } from "../../tools/plugin.manager";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root",
})
export class ExternalRedirectHandler implements IFeedRedirectHandler {
    private pluginManager: PluginManager;
    private enterpriseSelector: EnterpriseSelector;
    private translateService: TranslateService;
    private uiService: UIService;
    private router: Router;

    constructor(
        private injector: Injector
    ) {
        if (!this.pluginManager) {
            this.pluginManager = this.injector.get(PluginManager);
        }

        if (!this.router) {
            this.router = this.injector.get(Router);
        }

        if (!this.enterpriseSelector) {
            this.enterpriseSelector = this.injector.get(EnterpriseSelector);
        }

        if (!this.translateService) {
            this.translateService = this.injector.get(TranslateService);
        }

        if (!this.uiService) {
            this.uiService = this.injector.get(UIService);
        }
    }

    handle(feed: Feed): Promise<boolean> {
        if (!this.enterpriseSelector.isToolEnabled(feed.orgId, feed.event)) {
            let msg = this.translateService.instant("ORGANIZATION.TOOLS.REDIRECT_DISABLED_TOOLS_ERR");
            let action = this.translateService.instant("OK");
            this.uiService.openSnackBar(msg, action);

            return;
        }

        var plugin = this.pluginManager.get(feed.event);
        if (plugin && plugin.tapRedirectPath) {
            //redirect to tool page 
            this.router.navigate(["main", "tools", ...plugin.tapRedirectPath.split("/").filter(i => i)], { state: feed.data });
        }
    }

}