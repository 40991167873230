import { Router } from "@angular/router";
import { OrgType } from "./../enum/org-type";
import { ExplorerService } from "./../services/explorer.service";
import {
  Directive,
  Input,
  ElementRef,
  OnInit,
  OnChanges,
  SimpleChanges,
  HostListener,
  Renderer2,
  Output,
  EventEmitter
} from "@angular/core";

@Directive({
  selector: "[el-storage-permission]", // Attribute selector
})
export class StoragePermissionDirective implements OnInit, OnChanges {
  @Input() drive: string;
  @Input() orgId: string;
  @Input() orgType: OrgType;
  @Input() userId: string;
  @Input() reevaluate: boolean = false;
  @Output() onUpdateComplete = new EventEmitter();

  /** [Obsolete] 
   * If true, on click the element will reevaluate if it should hide or show itself.
   * If it is showing, it navigates to the redirect link provided,
   * otherwise it redirects to the dashboard.
   */
  @Input() reevaluateOnClick: boolean = false;
  @Input() redirectLink: string = null;
  @HostListener("click", ["$event.target"]) async onClick(target) {
    await this.router.navigateByUrl(this.redirectLink);
    return; 
    // if (this.reevaluateOnClick == false) {
    //   await this.router.navigateByUrl(this.redirectLink);
    //   return;
    // }
    // this._showSpinner();
    // let show = await this.hideOrShow().finally(()=>{
    //   this._hideSpinner();
    // });    
    // if (!this.redirectLink) return;
    // if (show) await this.router.navigateByUrl(this.redirectLink);
    // else await this.router.navigateByUrl("main/dashboard");
  }

  private defaultDisplay: string;
  constructor(
    private el: ElementRef,
    private explorerService: ExplorerService,
    private router: Router,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.defaultDisplay = this.el.nativeElement.style.display;
    this.hideOrShow();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && (changes.orgId || changes.userId || changes.orgType || (changes.reevaluate && this.reevaluate == true))) {
      this.hideOrShow();
    }
  }
  ngOnDestroy() {}

  private _showSpinner(): void{
    let element = this.renderer.createElement("i");
    let el: HTMLElement = this.el.nativeElement;
    el.style.display = "inline-flex";
    this.renderer.setAttribute(element, "class", "fa fa-spinner fa-spin");
    this.renderer.setAttribute(element, "style", "align-self: center;");

    el.appendChild(element);
  }

  private _hideSpinner(): void{
    let el: HTMLElement = this.el.nativeElement;
    let spinner = el.children.item(el.children.length - 1);
    if(spinner.tagName.toLowerCase() == "i")
    el.removeChild(spinner);
  }

  /** Returns true if the element is shown, false if hidden. */
  hideOrShow(): Promise<boolean> {
    try {
    let explorer = this.explorerService.build(
      this.orgId,
      this.orgType,
      this.userId
    );

    if (explorer == null) return Promise.resolve(false);

    let selectedDrive = explorer.getDrive(this.drive);
    if (selectedDrive == null) {
      this.defaultDisplay = "block";
      this.hideContent();
      Promise.resolve(false);
    }
    let promise: Promise<boolean> = selectedDrive.checkReadAccessDeleg();
    return promise
      .then((res) => {
        if (res == true) this.showContent();
        else this.hideContent();
        return res;
      })
      .catch((err) => {
        this.hideContent();
        return false;
      }).finally(()=>{
        this.onUpdateComplete.emit();
      });
    } catch(ex){
      this.onUpdateComplete.emit();
    }
  }

  hideContent(): void {
    if (this.el.nativeElement.style.display != "none")
      this.el.nativeElement.style.display = "none";
  }

  showContent() {
    if (!this.defaultDisplay) {
      this.defaultDisplay = "block";
    }
    if (this.el.nativeElement.style.display != this.defaultDisplay)
      this.el.nativeElement.style.display = this.defaultDisplay;
  }
}
