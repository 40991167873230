import { CancellationToken, Flow } from ".";
import { Emitter, Emittable } from "@ngxs-labs/emitter";
import { OrgHub } from "../hub/org.hub";
import * as _ from "lodash";
import { ContactState } from "../model/org.state";
import { EnterpriseState } from "../states/enterprise.state";
import { UIState } from "../states/ui.state";
import { EnterpriseSelector } from "../states/enterprise.state.selector";
import { StartOrgHubFlow } from "./org-hub.flow";

export class GetContactFlow extends Flow {
  @Emitter(EnterpriseState.addOrUpdateContacts)
  addOrUpdateContacts: Emittable<ContactState[]>;

  @Emitter(UIState.setContactReady)
  setContactReady: Emittable<void>;

  @Emitter(EnterpriseState.deleteContacts)
  deleteContacts: Emittable<ContactState[]>;

  constructor(
    private orgHub: OrgHub,
    private enterpriseSelector: EnterpriseSelector
  ) {
    super();
  }

  public async execute(
    orgId: any = null,
    token: CancellationToken = null
  ): Promise<any> {
    if (!this.orgHub.isConnected) {
      var orgHubStarted = await new StartOrgHubFlow(this.orgHub).execute(
        null,
        token
      );
      if (orgHubStarted == false) {
        this.orgHub.failedOverHandling();
        return;
      }
    }
    var contacts = await this.orgHub.getContactsMethod(orgId);
    if (token != null && token.isCancelled) return null;

    if (contacts) {
      //check for deleted contacts
      var currentContacts = this.enterpriseSelector.getContacts();
      const deletedContacts = _.differenceBy(currentContacts, contacts, "id");
      if (deletedContacts.length > 0) {
        await this.deleteContacts.emit(deletedContacts).toPromise();
      }

      if (contacts && contacts.length > 0) {
        await this.addOrUpdateContacts.emit(contacts).toPromise();
        if (token != null && token.isCancelled) return null;

        this.setContactReady.emit(null);
      }
    }

    this.output = orgId;

    return await super.execute(this.output, token);
  }
}
