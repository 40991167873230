import { AppStateSelector } from './../states/app.state.selector';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { ApiService } from '../api/api.service';
import { Injectable } from '@angular/core';
import { ProfileContactCardDto } from '../model/profile-contact-card.dto';
import { ProfileDto } from '../model/profile.dto';
import { UserSettingsDto } from '../model/userSettings.dto';

@Injectable()
export class UserService {
    constructor(private api: ApiService, private localDb: LocalStorageService, private appSelector: AppStateSelector) {
    }

    updateProfile(user: ProfileDto): Promise<any> {
        return this.api.putAsync('v2/user/profile', user, this.localDb.getUserId())
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    updateUser(user: any): Promise<any> {
        return this.api.putAsync('v2/contact/member', user, this.localDb.getUserId())
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    updateContactCard(contactCard: ProfileContactCardDto): Promise<any> {
        return this.api.putAsync('v2/contact/member/current', contactCard, this.localDb.getUserId())
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    uploadProfileImage(imgFile: any): Promise<any> {
        return this.api.postAsyncFile('v2/user/avatar', imgFile, this.localDb.getUserId()).toPromise()
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    getProfile(): Promise<any> {
        return this.api.getAsync('v2/user/profile/', null, this.localDb.getUserId())
            .then(res => {
                return res;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    getProfiles(): Promise<any> {
        return this.api.getAsync('v2/user/contacts/', null, this.localDb.getUserId())
            .then(res => {
                return res;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    getSettings(): Promise<any> {
        return this.api.getAsync('v2/user/settings', null, this.localDb.getUserId())
            .then(res => {
                return res;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    getContactCard(ouId): Promise<any> {
        return this.api.getAsync('v2/user/ou/' + ouId + '/contact', null, this.localDb.getUserId())
            .then(res => {
                return res;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }


    updateSettings(user2faSettings: UserSettingsDto): Promise<any> {
        return this.api.putAsync('v2/user/settings', user2faSettings, this.localDb.getUserId())
            .then(res => {
                return res;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    changePassword(currentPwd: string, newPwd: string): Promise<any> {
        return this.api.postAsync<any>('v2/account/pwd',
            {
                newPassword: newPwd,
                currentPassword: currentPwd
            },
            this.localDb.getUserId())
            .then(data => {
                return Promise.resolve(data);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    reqResetPassword(resetEmail: string, token?: string): Promise<any> {
        return this.api.postWithCaptchaTokenAsync<any>('v2/account/resetPwd/req', {
            email: resetEmail,
            platform: 'web'
        }, token
        )
            .then(data => {
                return Promise.resolve({ success: true, error: null, errorCode: null });
            }).catch(data => {
                return Promise.resolve({ success: false, error: data.error.error, errorCode: data.error.errorCode });
            });
    }

    validateResetPassword(token: string): Promise<any> {
        return this.api.postAsync<any>('v2/account/resetPwd/req/token', JSON.stringify(token))
            .then(data => {
                return Promise.resolve({ success: true, error: null, errorCode: null });
            }).catch(data => {
                return Promise.resolve({ success: false, error: 'An error has occured' });
            });
    }

    resetPassword(newPassword: string, passcode: string, token?: string): Promise<any> {

        return this.api.postWithCaptchaTokenAsync<any>('v2/account/resetPwd/reset', {
            newPassword: newPassword,
            validation: passcode
        }, token
        )
            .then(data => {
                return Promise.resolve({ success: true, error: null, errorCode: null });
            }).catch(data => {
                return Promise.resolve({ success: false, error: data.error.error, errorCode: data.error.errorCode });
            });
    }

    // initLoginDetails(ouId?: string): Promise<any> {
    //     return this.fcp
    //         .getKeyPair()
    //         .then(() => {
    //             //return this.ouService.getUserOU();

    //         })
    //         .then(ouRes => {
    //             // remove deleted ou
    //             ouRes = ouRes.filter(x => x.userStatus !== 8);
    //             console.log(ouRes);
    //             this.localDb.setOrgUnit(JSON.stringify(ouRes));
    //             // init default org unit
    //             let defaultOrg = ouRes[0];
    //             if (ouId) {
    //                 let newInvOrg = ouRes.filter(x => x.orgUnitId === ouId)[0];
    //                 defaultOrg = newInvOrg;
    //             }

    //             // get last login
    //             else {
    //                 let lastLoginOUId = this.localDb.getLastLogin(
    //                     this.localDb.getUserId()
    //                 );
    //                 if (lastLoginOUId) {
    //                     let lastLoginOrg = ouRes.filter(
    //                         x => x.orgUnitId === lastLoginOUId
    //                     )[0];
    //                     if (lastLoginOrg) {
    //                         defaultOrg = lastLoginOrg;
    //                     }
    //                 }
    //             }

    //             if (defaultOrg) {
    //                 if (defaultOrg.type === 1) {
    //                     defaultOrg = ouRes[1];
    //                 }
    //                 // check for userStatus suspended
    //                 if (defaultOrg.userStatus === 2) {
    //                     // change to other active org
    //                     let activeOrgs = ouRes.filter(
    //                         x =>
    //                             x.orgUnitId !== defaultOrg.orgUnitId && x.userStatus !== 2
    //                     );
    //                     if (activeOrgs.length > 0) {
    //                         defaultOrg = activeOrgs[0];
    //                     }
    //                 }
    //                 return this.ouService
    //                     .switchOU(defaultOrg.orgUnitId)
    //                     .then(p => {
    //                         // set root ou
    //                         this.localDb.setRootOU(defaultOrg.rootOrgUnitId);
    //                         return Promise.resolve(true);
    //                     })
    //                     .catch(res => {
    //                         console.log(res);
    //                         return Promise.resolve(false);
    //                     });
    //             } else {
    //                 // no active org detected.
    //                 // create blank permission obj in storage
    //                 let permission: PermissionStorage = {
    //                     orgUnitId: null,
    //                     permission: {},
    //                     roleId: null,
    //                     roleName: "N/A"
    //                 };
    //                 this.localDb.setPermission(JSON.stringify(permission));
    //                 return Promise.resolve(true);
    //             }
    //         })
    //         .catch(res => {
    //             console.log(res);
    //             return Promise.reject(false);
    //         });
    // }
    // get user owned teams
    getUserOwnedTeams(userId: string, orgId: string): Promise<any> {
        return this.api.getAsync('v2/user/' + userId + '/ou/' + orgId + '/group/owner', null, orgId)
            .then(res => {
                return res;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    getProducts(): Promise<any> {
        return this.api.getAsync('v2/account/product')
            .then(res => {
                return res;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    updateFcmToken(fcmToken: string): Promise<boolean> {
        var deviceId = this.appSelector.getDeviceId();
        if (deviceId == null) return Promise.resolve(false);
        return this.api.putAsync("v2/user/fcmToken", { deviceId, fcmToken });
    }

    deleteFcmToken(): Promise<boolean> {
        var deviceId = this.appSelector.getDeviceId();
        if (deviceId == null) return Promise.resolve(false);
        return this.api.deleteAsync("v2/user/fcmToken", { deviceId });
    }

    updateUiState(isBackground: boolean): Promise<boolean> {
        var deviceId = this.appSelector.getDeviceId();
        if (deviceId == null) return Promise.resolve(false);
        return this.api.putAsync("v2/user/uiState", { deviceId, isBackground });
    }

    updateCurrentOrgId(orgId: string): Promise<boolean> {
        var deviceId = this.appSelector.getDeviceId();
        if (deviceId == null) return Promise.resolve(false);
        return this.api.putAsync("v2/user/currentOrg", { deviceId, orgId });
    }

    updateSkip2FA(skip2FA: boolean): Promise<boolean> {
        var deviceId = this.appSelector.getDeviceId();
        if (deviceId == null) return Promise.resolve(false);
        return this.api.putAsync("v2/user/skip2fa", { deviceId, skip2FA });
    }

}
