import { MsgReaction } from "./../../core/model/message.state";
import { Component, OnInit, Input, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-msg-reactions",
  templateUrl: "./msg-reactions.component.html",
  styleUrls: ["./msg-reactions.component.scss"],
})
export class MsgReactionsComponent implements OnInit {
  @Input()
  reactions: MsgReaction[];
  summary: {
    content: string;
    count: number;
  }[];
  constructor() {}

  ngOnInit(): void {
    this.updateSummary();
  }

  private updateSummary(){
    this.summary = this.reactions.map((r) => {
      return {
        content: MsgReaction.getEmoji(r.content),
        count: r.senders.length,
      };
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.updateSummary();
    }
  }
}
