import { timer, Observable } from 'rxjs';
import { Directive, Input, EventEmitter, Output } from '@angular/core';
import { NG_ASYNC_VALIDATORS, AsyncValidator, AbstractControl } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { EnterpriseSelector } from '../states/enterprise.state.selector';

@Directive({
    selector: '[el-coupon-check]',
    providers: [{
        provide: NG_ASYNC_VALIDATORS, useExisting: CouponValidatorDirective, multi:
            true
    }]
})
export class CouponValidatorDirective implements AsyncValidator {
    constructor(public authService: AuthService, private enterpriseSelector: EnterpriseSelector) {
    }
    @Input() planId: string;
    @Output() couponSuccessEvent: EventEmitter<any> = new EventEmitter<any>();



    validate(control: AbstractControl) {
        if (control.value) {
            var orgId = this.enterpriseSelector.getCurrentOrgId();
            return timer(2000).pipe(
                switchMap(() => {
                    return this.authService.checkCoupon(this.planId, control.value, orgId).then(res => {
                        if (!res) {
                            this.couponSuccessEvent.emit(null);
                            return { 'invalid': true };
                        } else {
                            this.couponSuccessEvent.emit(res);
                            return null;
                        }
                    }).catch(err => {
                        this.couponSuccessEvent.emit(null);
                        return { 'serverError': true };
                    });

                }))
        } else {
            this.couponSuccessEvent.emit(null);
            return Promise.resolve(null)
        }
    }
}
