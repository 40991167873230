import { Injectable } from '@angular/core';
import { ApiService } from './../api/api.service';
import { ContactCardDto } from './../model/contact-card.dto';
import { LocalStorageService } from '../localStorage/local-storage.service';

@Injectable()
export class ContactService {
    constructor(private api: ApiService, private localDb: LocalStorageService) { }

    createLabel(labelName: string): Promise<any> {
        return this.api.postAsync('v2/contact/label', JSON.stringify([labelName]), this.localDb.getUserId())
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    editLabel(labelId: string, labelName: string): Promise<any> {
        return this.api.postAsync('v2/contact/label/' + labelId + '/edit', JSON.stringify(labelName), this.localDb.getUserId())
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    // associate label to contact, create label if not exists
    addContactLabel(contactId: string, labelName: string): Promise<any> {
        return this.api.postAsync('v2/contact/' + contactId + '/label', JSON.stringify(labelName), this.localDb.getUserId())
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    // associate label to contact, create label if not exists
    addContactLabels(contactId: string, labels: ContactLabelRequest[]): Promise<any> {
        return this.api.postAsync('v2/contact/' + contactId + '/label/all', labels, this.localDb.getUserId())
            .then(res => {
                return res;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }


    removeContactLabel(contactId: string, labelId: string): Promise<any> {
        return this.api.deleteAsync('v2/contact/' + contactId + '/label/' + labelId, null, this.localDb.getUserId())
            .then(res => {
                return true;
            }).catch(res => {
                if (!res.error) {
                    res.error = this.api.handleError(res);
                }
                return Promise.reject(res.error);
            });
    }

    setContactCircle(contactId: string, labelId: string): Promise<any> {
        return this.api.postAsync('v2/contact/' + contactId + '/circle', JSON.stringify(labelId), this.localDb.getUserId())
            .then(res => {
                return res;
            }).catch(res => {
                if (!res.error) {
                    res.error = this.api.handleError(res);
                }
                return Promise.reject(res.error);
            });
    }

    deleteLabel(labelId: string, userid?): Promise<any> {
        if (!userid) {
            userid = this.localDb.getUserId()
        }
        return this.api.deleteAsync('v2/contact/label/' + labelId, null, userid)
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    getLabels(showSystemLabel: boolean = false): Promise<any> {
        return this.api.getAsync('v2/contact/label', null, this.localDb.getUserId())
            .then((res: any[]) => {
                if (showSystemLabel) {
                    return res;
                } else {
                    let label = res.filter(x => x.isSystemLabel === false)
                    return label;
                }
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    getContactsWithLabel(labelId: string): Promise<any> {
        return this.api.getAsync('v2/contact/label/' + labelId, null, this.localDb.getUserId())
            .then(res => {
                return res;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    getContactLabels(contactId: string): Promise<any> {
        return this.api.getAsync('v2/contact/' + contactId + '/label', null, this.localDb.getUserId())
            .then(res => {
                return res;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    addNewOfflineContact(contact: ContactCardDto): Promise<any> {
        return this.api.postAsync('v2/contact/offline', contact, this.localDb.getUserId())
            .then(res => {
                return res;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }
    editOfflineContact(contact: ContactCardDto): Promise<any> {
        return this.api.putAsync('v2/contact/offline', contact, contact.contactId)
            .then(res => {
                console.log(res);
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    deleteOfflineContact(contactId: string): Promise<any> {
        return this.api.deleteAsync('v2/contact/offline/' + contactId, null, contactId)
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }
    editMemberContact(contact: ContactCardDto): Promise<any> {
        return this.api.putAsync('v2/contact/member', contact, this.localDb.getUserId())
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    uploadContactImage(contactId: any, imgFile: any): Promise<any> {
        return this.api.postAsyncFile('v2/contact/' + contactId + '/avatar', imgFile, contactId).toPromise()
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }
}

export interface ContactLabelRequest {
    labelId: string,
    name: string
}
