import { ImmutableContext, ImmutableSelector } from "@ngxs-labs/immer-adapter";
import { State, Selector, StateContext } from "@ngxs/store";
import { EmitterAction, Receiver } from "@ngxs-labs/emitter";
import { Injectable } from '@angular/core';
import * as _ from "lodash";

export class UIStateModel {
  activeRoom: string;
  activeChannel: string; //to filter posts in channel
  status: UIStatus;
  ready: string[];
  readyChannels: string[]; // list of channel ids with posts in the state that are up-to-date
  isRedirectOnSwitchOrg: boolean; // disables main-page from handling redirect for next switch.

  constructor() {
    this.activeRoom = null;
    this.activeChannel = null;
    this.status = UIStatus.Ready;
    this.ready = [];
    this.readyChannels = [];
    this.isRedirectOnSwitchOrg = false;
  }
}

export enum UIStatus {
  Ready,
  SwitchingOrg,
  UpdatingVersion,
  AwaitingResponse // used when multi tab dialog appears
}

@State<UIStateModel>({
  name: "ui",
  defaults: new UIStateModel(),
})
@Injectable()
export class UIState {
  constructor() { }

  ngxsAfterBootstrap(ctx: StateContext<UIStateModel>) {
    console.log("[UIState] - ngxsAfterBootstrap");
  }

  // ROOM MATRIX ID
  @Selector()
  static activeRoom(state: UIStateModel): string | null {
    return _.cloneDeep(state.activeRoom);
  }

  @Selector()
  static activeChannel(state: UIStateModel): string | null {
    return _.cloneDeep(state.activeChannel);
  }

  @Selector()
  static readyChannels(state: UIStateModel): string[] {
    return _.cloneDeep(state.readyChannels);
  }

  @Selector()
  static status(state: UIStateModel): UIStatus {
    return _.cloneDeep(state.status);
  }

  @Selector()
  static isRedirectOnSwitchOrg(state: UIStateModel): boolean {
    return _.cloneDeep(state.isRedirectOnSwitchOrg);
  }

  @Selector()
  static isContactReady(state: UIStateModel): boolean {
    return state.ready.findIndex((r) => r == "contact") != -1;
  }

  @Selector()
  static isAssignmentsReady(state: UIStateModel): boolean {
    return state.ready.findIndex((r) => r == "assignments") != -1;
  }

  @Receiver()
  @ImmutableContext()
  static setActiveRoom(
    ctx: StateContext<UIStateModel>,
    arg: EmitterAction<string>
  ) {
    const state = ctx.getState();
    state.activeRoom = arg.payload;
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  static setActiveChannel(
    ctx: StateContext<UIStateModel>,
    arg: EmitterAction<string>
  ) {
    const state = ctx.getState();
    state.activeChannel = arg.payload;
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  static setUIReady(
    ctx: StateContext<UIStateModel>,
  ) {
    const state = ctx.getState();
    state.status = UIStatus.Ready;
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  static setUISwitchingOrg(
    ctx: StateContext<UIStateModel>,
  ) {
    const state = ctx.getState();
    state.status = UIStatus.SwitchingOrg;
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  static setUIUpdatingVersion(
    ctx: StateContext<UIStateModel>,
  ) {
    const state = ctx.getState();
    state.status = UIStatus.UpdatingVersion;
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  static setUIAwaitingResponse(
    ctx: StateContext<UIStateModel>,
  ) {
    const state = ctx.getState();
    state.status = UIStatus.AwaitingResponse;
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  static setAssignmentsReady(ctx: StateContext<UIStateModel>) {
    UIState.setReady(ctx, "assignments");
  }

  @Receiver()
  @ImmutableContext()
  static setAssignmentsNotReady(ctx: StateContext<UIStateModel>) {
    UIState.setNotReady(ctx, "assignments");
  }

  @Receiver()
  @ImmutableContext()
  static setContactReady(ctx: StateContext<UIStateModel>) {
    UIState.setReady(ctx, "contact");
  }

  @Receiver()
  @ImmutableContext()
  static setContactNotReady(ctx: StateContext<UIStateModel>) {
    UIState.setNotReady(ctx, "contact");
  }
  
  @Receiver()
  @ImmutableContext()
  static setChannelPostsReady(ctx: StateContext<UIStateModel>, arg: EmitterAction<string>) {
    if (!arg.payload) return;
    const channelId = arg.payload;
    
    const state = ctx.getState();
    if (state.readyChannels.indexOf(channelId) == -1) {
      state.readyChannels = [channelId, ...state.readyChannels];
      ctx.setState(state);
    }
  }
  
  @Receiver()
  @ImmutableContext()
  static clearReadyChannels(ctx: StateContext<UIStateModel>) {
    const state = ctx.getState();
    state.readyChannels = [];
    ctx.setState(state);
  }

  private static setReady(ctx: StateContext<UIStateModel>, name: string) {
    const state = ctx.getState();
    if (state.ready.indexOf(name) == -1) {
      state.ready = [name, ...state.ready];
      ctx.setState(state);
    }
  }

  private static setNotReady(ctx: StateContext<UIStateModel>, name: string) {
    const state = ctx.getState();
    let index = state.ready.indexOf(name);
    if (index !== -1) {
      const ready = [...state.ready];
      ready.splice(index, 1);
      state.ready = [...ready];
      ctx.setState(state);
    }
  }

  @Receiver()
  @ImmutableContext()
  static setRedirectOnSwitchOrg(
    ctx: StateContext<UIStateModel>,
    arg: EmitterAction<boolean>
  ) {
    const state = ctx.getState();
    state.isRedirectOnSwitchOrg = arg.payload;
    ctx.setState(state);
  }

  @Receiver()
  static clean(ctx: StateContext<UIStateModel>) {
    ctx.setState({ ...new UIStateModel() });
  }
}
