import { SharedStorageSearchResource, StorageSearchResource } from './../model/search-resource.model';
import { ApiService } from '../api/api.service';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class SearchService {
    constructor(private api: ApiService
        ) {
    }

    searchUserDrive(orgId: string, query: string = ""): Promise<StorageSearchResource[]> {
        if (!orgId) throw "Missing orgId";
        var body = {
            orgId: orgId,
            query: query
        };
        var endpoint = `search/storage/user`;
        return this.api.post<any>(endpoint, body)
            .pipe(
                map((result: any[]) => {
                    return this.parseList<StorageSearchResource>(result, StorageSearchResource.parse.bind(this))
                }),
                catchError(err => {
                    this.api.handleError(err);
                    throw err;
                })
            ).toPromise();
    }

    searchEnterpriseSharedDrive(orgId: string, driveId: string, query: string = ""): Promise<StorageSearchResource[]> {
        if (!orgId) throw "Missing orgId";
        if (!driveId) throw "Missing shared drive name";
        var body = {
            orgId: orgId,
            query: query,
            driveName: driveId,
        };
        var endpoint = `search/storage/drive`;
        return this.api.post<any>(endpoint, body)
            .pipe(
                map((result: any[]) => {
                    return this.parseList<StorageSearchResource>(result, StorageSearchResource.parse.bind(this))
                }),
                catchError(err => {
                    this.api.handleError(err);
                    throw err;
                })
            ).toPromise();
    }

    searchSharedWithMeDrive(orgId: string, query: string = ""): Promise<StorageSearchResource[]> {
        if (!orgId) throw "Missing orgId";
        var body = {
            orgId: orgId,
            query: query
        };
        var endpoint = `search/storage/shared-with-me`;
        return this.api.post<any>(endpoint, body)
            .pipe(
                map((result: any[]) => {
                    return this.parseList<StorageSearchResource>(result, StorageSearchResource.parse.bind(this))
                }),
                catchError(err => {
                    this.api.handleError(err);
                    throw err;
                })
            ).toPromise();
    }

    private parseList<T>(list: any[], parseFunc: (dto: any) => T): T[] {
        if (!list || list.length == 0) return [];
        return list.map((d) => parseFunc(d));
      }
}
