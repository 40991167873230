import { MembershipReqService } from "./../../../../core/services/membership-req.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { EnterpriseSelector } from "../../../../core/states/enterprise.state.selector";
import { Component, OnInit, Input, Inject } from "@angular/core";
import { RoleEntity } from "../../../../core/model/userRole.model";

declare var navigator;
@Component({
  selector: "app-dialog-join-req-link",
  templateUrl: "./dialog-join-req-link.component.html",
  styleUrls: ["./dialog-join-req-link.component.scss"],
})
export class DialogJoinReqLinkComponent implements OnInit {
  clientLink: string;
  partnerLink: string;
  orgName: string;
  clientRole: string;
  partnerRole: string;
  ouId: string;
  partnerLinkLoad: boolean = false;
  clientLinkLoad: boolean = false;
  constructor(
    private enterpriseSelector: EnterpriseSelector,
    private snackBar: MatSnackBar,
    private membershipReqService: MembershipReqService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.ouId = this.data.ouId;
    this.getLink();

    const org = this.enterpriseSelector.getCurrentOrg();
    this.orgName = org ? org.name : "organization";

    const roles = this.enterpriseSelector.getOrgRoles(org.id);
    const clientRole = roles.find(
      (r) => r.normalizedName === RoleEntity[RoleEntity.CLIENT]
    );
    this.clientRole = clientRole ? clientRole.label : "Client";

    const partnerRole = roles.find(
      (r) => r.normalizedName === RoleEntity[RoleEntity.PARTNER]
    );
    this.partnerRole = partnerRole ? partnerRole.label : "External Associate";

    // this.clientLink =
    //   "https://lab.everleagues.com/join/Y2RiZTM4NWMtYWE5MS00ZWFlLWFlMGQtY2Q1Yjc2ZDQ3MTI4O2NsaWVudA%3D%3D";
    // this.partnerLink =
    //   "https://lab.everleagues.com/join/Y2RiZTM4NWMtYWE5MS00ZWFlLWFlMGQtY2Q1Yjc2ZDQ3MTI4O2NsaWVudA%3D%3D";
  }

  private async getLink() {
    const orgId = this.enterpriseSelector.getCurrentOrgId();
    const clientRole = RoleEntity[RoleEntity.CLIENT].toLowerCase();
    const partnerRole = RoleEntity[RoleEntity.PARTNER].toLowerCase();
    if (clientRole) {
      this.clientLinkLoad = true;
      this.membershipReqService
        .getJoinReqLink(orgId, clientRole, this.ouId)
        .then((res) => {
          this.clientLinkLoad = false;
          this.clientLink = res;
        })
        .catch((err) => {
          this.clientLinkLoad = false;
          console.log(err);
        });
      // var data = `${orgId};${clientRole}`;
      // this.clientLink = `${location.origin}/join/${encodeURIComponent(btoa(data))}`;
    }

    if (partnerRole) {
      this.partnerLinkLoad = true;
      this.membershipReqService
        .getJoinReqLink(orgId, partnerRole, this.ouId)
        .then((res) => {
          this.partnerLinkLoad = false;
          this.partnerLink = res;
        })
        .catch((err) => {
          this.partnerLinkLoad = false;
          console.log(err);
        });
      // var data = `${orgId};${partnerRole}`;
      // this.partnerLink = `${location.origin}/join/${encodeURIComponent(btoa(data))}`;
    }
  }

  async copy(link: any) {
    // document.addEventListener("copy", function (e) {
    //   e.clipboardData.setData("text/html", link.outerHTML);
    //   e.preventDefault();
    // });

    // document.execCommand("copy");

    // this.openSnackBar("HTML Copied!", "OK");

    await navigator.clipboard.writeText(link);
    this.openSnackBar("Link Copied!", "OK");

    // // document.addEventListener("copy", function (e) {
    // //   e.clipboardData.setData("text/plain", link);
    // //   e.preventDefault();
    // // });

    // // document.execCommand("copy");

    // // this.openSnackBar("HTML Copied!", "OK");
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}
