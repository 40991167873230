import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class ToolsLocalStateSelector  {
  private _elToolsAccessToken: any = null;
  private _tokenuserId:any = null;
  private _navistate:any=null;
  constructor() {
  }

  clearData() {
    this._elToolsAccessToken = null;
    this._tokenuserId=null;
    this._navistate=null;
  }

  setNaviState(state){
    if(state){
      this._navistate=state;
    }
  }
  getNaviState(){
    return this._navistate;
  }
  clearNaviState(){
    this._navistate=null;
  }
  getElToolsAccessToken(): any {
    if (this._elToolsAccessToken) {
      return {
        accessToken: this._elToolsAccessToken,
        tokenUserId: this._tokenuserId
      };
    } else {
      return null;
    }
  }

  setElToolsAccessToken(accessToken: any,userId:any) {
    this._elToolsAccessToken = accessToken;
    this._tokenuserId=userId;
  }
}

