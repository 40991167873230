import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MembershipReq } from "../../core/model/membershipReq.model";
import { MembershipReqStatus } from "../../core/model/org.state";
import { MediaService } from "../../core/services/media.service";
import { MembershipReqService } from "../../core/services/membership-req.service";
import { OUService } from "../../core/services/ou.service";
import { AppState } from "../../core/states/app.state";
import { AuthStateSelector } from "../../core/states/auth.state.selector";
import { TurnstileComponent } from "../../core/auth/turnstile/turnstile.component";

@Component({
  selector: "request-to-join-2",
  templateUrl: "./request-to-join-2.component.html",
  styleUrls: ["./request-to-join.component.scss"],
})
export class RequestToJoin2Component implements OnInit {
  @ViewChild('elturnstile') turnstileComponent: TurnstileComponent;
  private _token: string;
  private _email: string;
  private _sendReq: boolean = false;
  isInit: boolean = false;
  isLoading: boolean = false;
  showSuccessWindow: boolean = false;
  isSuccess: boolean = false;
  showMsg: string;
  orgName: string;
  orgId: string;
  roleName: string;
  orgAvatar: string;
  orgOwner: string;
  isPersonalSpace: string;

  constructor(
    private membershipReqService: MembershipReqService,
    private translateService: TranslateService,
    private mediaService: MediaService,
    private ouService: OUService,
    private authSelector: AuthStateSelector,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private appState: AppState
  ) {}

  get sendReqBtn(): string {
    const txt = this.isPersonalSpace
      ? "JOIN_REQ.SEND_FRIEND_REQ"
      : "JOIN_REQ.JOIN_ORG";
    return this.translateService.instant(txt);
  }

  ngOnInit(): void {
    this.isInit = true;
    this.appState.ready().then(() => {
      this.activatedRoute.params.subscribe((params: Params) => {
        this._token = params.token;
        if (this._token) {
          this.validateToken().then(() => {
            if (this._sendReq) {
              this.checkIfLogin();
            } else {
              this.isLoading = false;
              this.isInit = false;
            }
          });
        }
      });

      this.activatedRoute.queryParams.subscribe((params: Params) => {
        if (params && params.sendReq) {
          this._sendReq = true;
        }
      });
    });
  }

  private validateToken() {
    this.isLoading = true;
    this.isInit = true;

    return this.membershipReqService
      .validateReqToJoinToken(this._token)
      .then((res) => {
        this.orgName = res.name;
        this.roleName = res.role;
        this.orgId = res.orgId;
        this.isPersonalSpace = res.isPersonalSpace;
        this.orgOwner = res.ownerName;
        if (res.imageUrl) {
          let fullImageUrl = this.mediaService.generateOrgImageUrl(
            res.orgId,
            res.imageUrl
          );
          this.orgAvatar = fullImageUrl;
        }
      });
  }

  checkIfLogin() {
    this.isLoading = true;

    if (this.authSelector.isAuthenticated) {
      this._email = this.authSelector.data.userName;
      this.reqToJoin();
    } else {
      this.router.navigate(["/register"], {
        queryParams: { reqJoinToken: this._token },
      });
    }
  }

  private async reqToJoin() {
    var captchaToken = await this.turnstileComponent.getToken();
    let isExistingMember = await this.checkIfExisting();
    if (isExistingMember) {
      const txt = this.isPersonalSpace
        ? `${this.translateService.instant("JOIN_REQ.ALREADY_CONNECTED")} ${
            this.orgOwner
          }.`
        : `${this.translateService.instant("JOIN_REQ.MEMBER_ALREADY_EXIST")} ${
            this.orgName
          }.`;
      this.handleSuccessWindow(txt, false);
    } else {
      this.membershipReqService
        .requestToJoin(this._token, null, null, this._email,captchaToken)
        .then((res: MembershipReq) => {
          if (res.status == MembershipReqStatus.Completed) {
            const txt = this.isPersonalSpace
              ? `${this.translateService.instant(
                  "JOIN_REQ.ALREADY_CONNECTED"
                )} ${this.orgOwner}.`
              : `${this.translateService.instant("JOIN_REQ.APPROVE_SUCCESS")} ${
                  this.orgName
                }.`;
            this.handleSuccessWindow(txt, true);
          } else {
            const text = this.isPersonalSpace
              ? this.translateService.instant("JOIN_REQ.GET_PS_NOTIFIED")
              : this.translateService.instant("JOIN_REQ.GET_BIZ_NOTIFIED");
            this.handleSuccessWindow(text, true);
          }
        })
        .catch((err) => {
          console.error(err);
          this.handleSuccessWindow(err ? err.error : err, false);
        });
    }
  }

  private handleSuccessWindow(msg: string, isSuccess: boolean) {
      this.isSuccess = isSuccess;
    this.isLoading = false;
    this.isInit = false;
    this.showSuccessWindow = true;
    this.showMsg = msg;
  }

  private checkIfExisting() {
    return this.ouService.isExistingOrgUser(this.orgId, this._email);
  }

  close() {
    window.location.href = "http://www.everleagues.com";
  }

  redirectToApp() {
    this.router.navigateByUrl("/main");
  }
}
