import { Component, OnInit } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { LocalStorageService } from "../../../core/localStorage/local-storage.service";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import {
  StripeService,
  Elements,
  Element as StripeElement,
  CardDataOptions
} from "ngx-stripe";
import { OUService } from "../../../core/services/ou.service";
import { ELStripeElementOptions } from "../../../core/model/stripe-element.model";
import { OrgState, PaymentMethodState } from "../../../core/model/org.state";
import { EnterpriseSelector } from "../../../core/states/enterprise.state.selector";
import { OrgHub } from "../../../core/hub/org.hub";
import { SubSink } from "subsink";
import { Observable, Subscription } from "rxjs";
import { Select } from "@ngxs/store";
import { EnterpriseState } from "../../../core/states/enterprise.state";

@Component({
  selector: "app-dialog-update-payment",
  templateUrl: "./dialog-update-payment.component.html",
  styleUrls: ["./dialog-update-payment.component.scss"]
})
export class DialogUpdatePaymentComponent implements OnInit {
  
  @Select(EnterpriseState.currentPaymentMethods)
  currentPaymentMethods$: Observable<PaymentMethodState[]>;
  
  isLoading = false;  
  // payment
  cardName: string;
  cardZip: string;

  // stripe
  elements: Elements;
  cardNumber: StripeElement;
  cardExpiry: StripeElement;
  cardCVC: StripeElement;

  paymentFormGroup: UntypedFormGroup;

  // payment cards
  cards: PaymentMethodState[] = []
  selectedCard: PaymentMethodState;

  showError = false;
  errorMsg: string;

  private _subPaymentMethod: Subscription;
  private _subSink: SubSink;
  constructor(
    public dialogRef: MatDialogRef<DialogUpdatePaymentComponent>,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private stripeService: StripeService,
    private enterpriseSelector: EnterpriseSelector,
    private orgHub: OrgHub
  ) {
    this._subSink = new SubSink();
   }

  ngOnInit() {
    this.initStripeInput();
  }

  //#region init
  initStripeInput() {

    this.paymentFormGroup = this.fb.group({
      cardName: ['', [Validators.required]],
      cardZip: ['', [Validators.required]],
    });
    this.stripeService
      .elements({
        // Stripe's examples are localized to specific languages, but if
        // you wish to have Elements automatically detect your user's locale,
        // use `locale: 'auto'` instead.
        locale: "auto"
      })
      .subscribe(elements => {
        // Floating labels
        var elementStyles = {
          base: {
            "::placeholder": {
              color: "grey"
            }
          }
        };

        var elementClasses: ELStripeElementOptions = {
          style: elementStyles,
          classes: {
            focus: "focused",
            empty: "empty",
            invalid: "invalid"
          }
        };
        if (!this.cardNumber) {
          this.cardNumber = elements.create('cardNumber', elementClasses);
          this.cardNumber.mount('#payment-card-number');
        }
  
        if (!this.cardExpiry) {
          this.cardExpiry = elements.create('cardExpiry', elementClasses);
          this.cardExpiry.mount('#payment-card-expiry');
        }
        if (!this.cardCVC) {
          this.cardCVC = elements.create('cardCvc', elementClasses);
          this.cardCVC.mount('#payment-card-cvc');
        }
      });
  }
  //#endregion

  upgradeSub() {
    this.isLoading = true;
    let additionalData: CardDataOptions = {
      name: this.paymentFormGroup.get('cardName').value,
      address_zip: this.paymentFormGroup.get('cardZip').value
    }
    const currentOrgId = this.enterpriseSelector.getCurrentOrgId();
    this.stripeService
      .createToken(this.cardNumber, additionalData)
      .subscribe(result => {
        if (result.token) {
          this.orgHub.subUpdateFailedPayment(currentOrgId, result.token.id).subscribe(
            res => {
              this.isLoading = false;
              this.dialogRef.close({ status: "success", response: res });
            },
            err => {
              this.isLoading = false;
              this.dialogRef.close({ status: "failed", error: err })
            }
          );
        } else if (result.error) {
          this.isLoading = false;
          this.showError = true;
          this.errorMsg = result.error.message;
          // Error creating the token
          console.log(result.error.message);
        }
      });
  }

  private processCards(cards: PaymentMethodState[] = []) {
    this.cards = cards;
    this.cards.forEach(card => {
      card.icon = this.getCardIcon(card.brand);
    });
    console.log("[DialogUpgradeSub] Cards %o", this.cards);
  }

  getCardIcon(brand: string) {
    let cardBrand = brand.toLowerCase();
    if (cardBrand === "american express") {
      return "cc-amex";
    } else if (cardBrand === "diners club") {
      return "cc-diners-club"
    } else if (cardBrand === "unionpay") {
      return "credit-card"
    }
    return "cc-" + cardBrand;
  }

  selectCard($event) {
  
  }
}
