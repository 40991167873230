import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UserService } from '../../../services/user.service';
import { LocalStorageService } from '../../../localStorage/local-storage.service';

@Component({
  selector: 'app-dialog-change-password',
  templateUrl: './dialog-change-password.component.html',
  styleUrls: ['./dialog-change-password.component.scss']
})
export class DialogChangePasswordComponent implements OnInit {
  isLoading = false;
  oldPassword: string = "";
  password: string = "";
  currentPassword = true;

  showOldPasswordType:string = "password";
  showNewPasswordType:string = "password";  
  showConfirmPasswordType:string = "password";

  passwordMatch = true;
  confirmPassword: any;

  constructor(public dialogRef: MatDialogRef<DialogChangePasswordComponent>, public dialog: MatDialog, private userService: UserService,
    private localDb: LocalStorageService) { }

  ngOnInit() {
  }

  checkPasswordMatch() {
    if (this.password != null && this.confirmPassword != null) {
      if (this.password.toLowerCase() === this.confirmPassword.toLowerCase()) {
        this.passwordMatch = true;
      } else {
        this.passwordMatch = false;
      }
    }
  }

  changePassword() {
    this.isLoading = true;
    this.userService.changePassword(this.oldPassword, this.password)
      .then(res => {
        if (res.error) {
          this.isLoading = false;
          this.currentPassword = false;
          // this.dialogRef.close(res);
        } else {
          this.isLoading = false;
          this.dialogRef.close('success');
        }
      }).catch(err => {
        this.isLoading = false;
        this.currentPassword = false;
        // this.dialogRef.close(err);
      });
  }

  toggleOldPassword(){
    if (this.showOldPasswordType == "password") {
        this.showOldPasswordType = "text";
    } else {
      this.showOldPasswordType = "password";
    }
  }

  toggleNewPassword(){
    if (this.showNewPasswordType == "password") {
        this.showNewPasswordType = "text";
    } else {
      this.showNewPasswordType = "password";
    }
  }

  toggleConfirmPassword(){
    if (this.showConfirmPasswordType == "password") {
        this.showConfirmPasswordType = "text";
    } else {
      this.showConfirmPasswordType = "password";
    }
  }
}
