import { RoomHandshakeFlow } from "./room-hub.flow";
import { Flow } from "./flow";
import {
  FcpSetupFlow,
  SystemHandshakeFlow,
  EndPointFlow,
} from ".";
import { FcpService } from "../fcp/fcp.service";
import { RoomHub } from "../hub/room.hub";
import { SystemHub } from "../hub/system.hub";
export class PrepareMeetFlow extends Flow {
  constructor(
    private roomHub: RoomHub,
    private fcpService: FcpService,
    private systemHub: SystemHub,
  ) {
    super();
  }
  public sketch(): Flow {
    let roomHubFlow = new RoomHandshakeFlow(this.roomHub);
    let fcpSetupFlow = new FcpSetupFlow(this.fcpService);
    let systemHandshakeFlow = new SystemHandshakeFlow(this.systemHub);

    return super.sketch(
      [roomHubFlow, fcpSetupFlow, systemHandshakeFlow],
      new EndPointFlow()
    );
  }
}
