import { OrgHub } from "./../hub/org.hub";
import { AuthService } from "./../auth/auth.service";
import { ApiService } from "./../api/api.service";
import { MembershipReq } from "../model/membershipReq.model";
import { Injectable } from "@angular/core";
import { Emitter, Emittable } from "@ngxs-labs/emitter";
import { EnterpriseState } from "../states/enterprise.state";
import { MembershipReqState, MembershipReqType } from "../model/org.state";
import { User } from '../ui/dialog/invite-users/dialog-invite-users.component';
import { UserDataState } from '../states/user-data.state';

@Injectable()
export class MembershipReqService {
  //#region NGXS operation
  @Emitter(EnterpriseState.addOrUpdateMembershipReq)
  public addOrUpdateMembershipReq: Emittable<MembershipReqState[]>;
  @Emitter(UserDataState.addOrUpdateTrialFlag)
  public addOrUpdateTrialFlag: Emittable<boolean>;

  //#endregion
  constructor(
    private api: ApiService,
    private auth: AuthService,
    private orgHub: OrgHub
  ) { }

  verifyMembership(req: MembershipReq) {
    if (req.type == MembershipReqType.SignUp) {
      return this.api
        .putAsync<any>(`req/signup/${req.id}/complete`, {})
        .catch((res: any) => {
          this.api.handleError(res);
          return Promise.reject(res.error);
        });
    } else if (req.type == MembershipReqType.RequestJoin) {
      return this.api
        .putAsync<any>(`req/join/${req.id}/verify`, {})
        .catch((res: any) => {
          this.api.handleError(res);
          return Promise.reject(res.error);
        });
    } else {
      return Promise.resolve();
    }
  }
  getMembershipReq(contactId: string) {
    return this.api
      .getAsync<MembershipReq>(`req/invite/contact/${contactId}`)
      .then((res) => {
        return res;
      })
      .catch((res: any) => {
        console.error(res);
        return null;
      })
  }

  validateToken(token: string): Promise<MembershipReq> {
    return this.api
      .getAsync<MembershipReq>(`req/view/${token}`)
      .catch((res: any) => {
        this.api.handleError(res);
        return Promise.reject(res.error);
      });
  }

  validateReqToJoinToken(token: string) {
    return this.api.getAsync<any>(`req/join/${token}`).catch((res: any) => {
      this.api.handleError(res);
      return Promise.reject(res.error);
    });
  }

  requestToJoin(
    token: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    captchaToken?:string,
  ) {
    var body = {
      firstName,
      lastName,
      email,
    };
    return this.api
      .postWithCaptchaTokenAsync<any>(`req/join/${token}/issue`, body,captchaToken,null,null,null,true)
      .catch((res: any) => {
        this.api.handleError(res);
        return Promise.reject(res.error);
      });
  }

  registerWithoutBizOrg(
    email: string,
    firstName: string,
    lastName: string,
    password: string,
    token?: string
  ) {
    var body = {
      email,
      password,
      firstName,
      lastName,
    };

    var promise = null;
    if (!token) {
      promise = this.api.postAsyncWithCredentials<any>("v2/account/personal", body);
    } else {
      promise = this.api.postWithCaptchaTokenAsync<any>("v2/account/personal", body, token);
    }
    return promise
      .then((res) => {
        if (res.hasTrial) this.addOrUpdateTrialFlag.emit(res.hasTrial)
        return this.auth.successLogin(res);
      })
      .catch((res: any) => {
        this.api.handleError(res);
        return Promise.reject(res.error);
      });
  }

  reqSignUp(email: string, firstName: string, lastName: string, token?:string) {
    var body = {
      email,
      firstName,
      lastName,
    };
    return this.api
      .postWithCaptchaTokenAsync<any>("req/signup/issue", body,token,null,null,null,true)
      .catch((res: any) => {
        this.api.handleError(res);
        return Promise.reject(res.error);
      });
  }

  reqSignUpIssueCode(email: string, firstName: string, lastName: string, token?:string) {
    var body = {
      email,
      firstName,
      lastName,
    };
    return this.api
      .postWithCaptchaTokenAsync<any>("req/signup/issue/code", body,token,null,null,null,true)
      .catch((res: any) => {
        this.api.handleError(res);
        return Promise.reject(res.error);
      });
  }

  verifySignUpCode(email: string, code: string) {
    var body = {
      email,
      code
    };
    return this.api
      .postAsync<any>("req/signup/verify", body)
      .catch((res: any) => {
        this.api.handleError(res);
        return Promise.reject(res.error);
      });
  }

  resendReqJoinEmail(email: string, encodedOrgToken: string, token?:string) {
    return this.api
      .postWithCaptchaTokenAsync<any>(
        `req/join/${encodedOrgToken}/reissue/email`,
        JSON.stringify(email),token,null,null,null,true
      )
      .catch((res: any) => {
        this.api.handleError(res);
        return Promise.reject(res.error);
      });
  }

  resendSignUpEmail(email: string, useCode: boolean = false, token?:string) {
    var body = {
      email,
      useCode
    };
    return this.api
      .postWithCaptchaTokenAsync<any>("req/signup/reissue/email", body, token,null,null,null,true)
      .catch((res: any) => {
        this.api.handleError(res);
        return Promise.reject(res.error);
      });
  }

  resendEmailByReqId(reqId: string, token?:string) {
    return this.api
      .postWithCaptchaTokenAsync<any>(`req/signup/${reqId}/reissue`, null, token,null,null,null,true)
      .catch((res: any) => {
        this.api.handleError(res);
        return Promise.reject(res.error);
      });
  }

  issueInvitation(
    email: string,
    firstName: string,
    lastName: string,
    roleId: string,
    orgId: string,
    ouId: string,
    preassignedSettings: any = [],
    contactId: string = null
  ) {
    let req: any = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      roleId: roleId,
      engagedOuId: ouId,
      jsonData: preassignedSettings ? JSON.stringify(preassignedSettings) : [],
      orgId: orgId
    };

    if (contactId) req.contactId = contactId;
    console.log(req)

    return this.api
      .postAsync<any>("req/invite/issue", req, orgId)
      .then((res) => {
        var req = this.orgHub.parseMembershipReq(res);
        this.addOrUpdateMembershipReq.emit([req]);
        return res;
      })
      .catch((res: any) => {
        this.api.handleError(res);
        return Promise.reject(res.error);
      });
  }

  reissueContactInvitation(reqId: string) {
    return this.api
      .postAsync<MembershipReq>(`req/invite/${reqId}/reissue/contact`, null, reqId)
      .then((res) => {
        return res;
      })
      .catch((res: any) => {
        return Promise.reject(res.error);
      });
  }



  reissueInvitation(reqId: string) {
    return this.api
      .postAsync<any>(`req/invite/${reqId}/reissue`, null, reqId)
      .then((res) => {
        return res;
      })
      .catch((res: any) => {
        this.api.handleError(res);
        return Promise.reject(res.error);
      });
  }

  deleteInvitation(reqId: string) {
    return this.api
      .deleteAsync<any>(`req/invite/${reqId}`, null, reqId)
      .then((res) => {
        var req = this.orgHub.parseMembershipReq(res);
        this.addOrUpdateMembershipReq.emit([req]);
        return res;
      })
      .catch((res: any) => {
        this.api.handleError(res);
        return Promise.reject(res.error);
      });
  }

  acceptInvitation(reqId: string, companyName: string) {
    return this.api
      .postAsync<any>(`req/invite/${reqId}/accept`, JSON.stringify(companyName))
      .then((res) => {
        return res;
      })
      .catch((err: any) => {
        this.api.handleError(err);
        return Promise.reject(err.error);
      });
  }

  approvePendingJoin(reqId: string) {
    return this.api
      .putAsync<any>(`req/join/${reqId}/approve`, null, reqId)
      .then((res) => {
        var req = this.orgHub.parseMembershipReq(res);
        this.addOrUpdateMembershipReq.emit([req]);
        return res;
      })
      .catch((err: any) => {
        this.api.handleError(err);
        return Promise.reject(err.error);
      });
  }

  rejectPendingJoin(reqId: string) {
    return this.api
      .putAsync<any>(`req/join/${reqId}/reject`, null, reqId)
      .then((res) => {
        var req = this.orgHub.parseMembershipReq(res);
        this.addOrUpdateMembershipReq.emit([req]);
        return res;
      })
      .catch((err: any) => {
        this.api.handleError(err);
        return Promise.reject(err.error);
      });
  }

  getJoinReqLink(orgId: string, role: string, ouId: string): Promise<string> {
    return this.api.postAsync("req/join/link", {
      orgId: orgId,
      roleNormalizedName: role,
      ouId: ouId
    }).catch((err) => {
      this.api.handleError(err);
      return Promise.reject(err.error);
    });
  }

  issueInvitations(newUsers: any[], orgId: string, ouId: string, customDomainID?: string) {
    try {
      let newUsersList = newUsers.map((u) => {
        return {
          firstName: u.firstName,
          lastName: u.lastName,
          email: u.email,
          roleId: u.roleId,
          engagedOuId: ouId,
          jsonData: "",
          orgId: orgId,
        };
      });
      let req = JSON.stringify(newUsersList);

      return this.api
        .postAsync("req/invite/issue/" + orgId + "/many", req, orgId, undefined, customDomainID)
        .then((res) => {
          return res;
        })
        .catch((err) => {
          this.api.handleError(err);
          return Promise.reject(err ? err.error : err);
        });
    } catch (err) {
      return Promise.reject(err);
    }
  }

}
