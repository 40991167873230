import { OrgType } from "../enum/org-type";
import { StorageLocationType } from "./storage-location-type.enum";

export class StorageLocationState {
  orgId: string;
  orgType: OrgType;
  type: StorageLocationType;
  path: string;
  data: any;
  name: string;
  isRoot: boolean;
  constructor(orgId: string, orgType: OrgType, type: StorageLocationType, path: string, name: string, isRoot: boolean = false) {
    this.orgId = orgId;
    this.orgType = orgType;
    this.type = type;
    this.path = path;
    this.name = name;
    this.isRoot = isRoot;
  }
}

