import { State, StateContext, Selector, Action } from "@ngxs/store";
import { ImmutableContext, ImmutableSelector } from "@ngxs-labs/immer-adapter";
import { Receiver, EmitterAction } from "@ngxs-labs/emitter";
import { LoginHistoryState } from '../model/login-history.state';
import { UserDataState } from './user-data.state';
import { UserProfileState } from '../model/user-profile.state';
import { Injectable } from '@angular/core';
import * as _ from "lodash";

export class HistoryStateModel {

  loginHistory: LoginHistoryState[];

  constructor() {
    this.loginHistory = [];
  }
}
@State<HistoryStateModel>({
  name: "history",
  defaults: new HistoryStateModel()
})
@Injectable()
export class HistoryState {

  ngxsAfterBootstrap(ctx: StateContext<HistoryStateModel>) {
    console.log("[HistoryState] - ngxsAfterBootstrap");
  }

  @Selector()
  @ImmutableSelector()
  static history(state: HistoryStateModel): LoginHistoryState[] | null {
    var result = [...state.loginHistory];
    return _.cloneDeep(result);
  }

  @Selector([UserDataState.userProfile, HistoryState.history])
  @ImmutableSelector()
  static userLastLogin(user: UserProfileState, history: LoginHistoryState[]): LoginHistoryState | null {
    var result = history.filter(x => x.userId == user.userId)[0];
    return _.cloneDeep(result);
  }

  @Receiver()
  @ImmutableContext()
  static save(
    ctx: StateContext<HistoryStateModel>,
    arg: EmitterAction<LoginHistoryState>
  ) {
    if (!arg || !arg.payload) return;
    const state = ctx.getState();

    let history = [...state.loginHistory];
    let currentOrg = history.find(x => x.userId === arg.payload.userId);

    if (currentOrg) {
      currentOrg.orgId = arg.payload.orgId;
    } else {
      let newHistoryState: LoginHistoryState = { userId: arg.payload.userId, orgId: arg.payload.orgId }
      history.push({ ...newHistoryState });
    }
    state.loginHistory = [...history];
    ctx.setState(state);
  }

  @Receiver()
  static clean(ctx: StateContext<LoginHistoryState>) {
    ctx.setState({ ...new LoginHistoryState() });
  }
}
