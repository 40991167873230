import { RoomType } from "./room.model";
import { EntityStatus } from "../enum/entity-status.enum";
import { MessageState } from "./message.state";

export class RoomState {
  id: string;
  matrixId: string;
  orgId: string;

  createdOn: Date;
  creatorMatrixId: string;
  creatorId: string;
  //privateKey: string;
  //publicKey: string;

  name: string;
  imageUrl: string;
  type: RoomType;
  status: EntityStatus;

  nextBatchNumber: string;
  lastBatchNumber: string;
  isHistoryLoaded: boolean;

  lastMsg: MessageState;
  messages: string[];
  //flags: { [flag: string]: string[] };

  timeStamp: number;

  //participants
  participants: string[] = [];

  constructor() {
    this.type = RoomType.Direct;
    this.status = EntityStatus.Active;
    //this.flags = {};
    this.messages = [];
    this.participants = [];
  }
}
