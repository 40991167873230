import { Component, HostBinding, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { routerAnimation } from '../../utils/page.animation';
import { InvitationService } from '../../core/services/invitation.service';
import { AuthService } from '../../core/auth/auth.service';
import { LocalStorageService } from '../../core/localStorage/local-storage.service';
import { UserService } from '../../core/services/user.service';
import { environment as ENV } from './../../../environments/environment';

@Component({
  selector: 'app-invite-page',
  templateUrl: './invite-page.component.html',
  styleUrls: ['./invite-page.component.scss'],
  animations: [routerAnimation]
})
export class InvitePageComponent implements OnInit {
  token: string;
  showError: boolean;
  errorMsg: string;
  showLoading: boolean;
  firstName: string;
  lastName: string;
  password: string = "";
  confirmPassword: string = "";
  email: string;
  isMember: boolean;
  role: string;
  orgName: string;
  orgUnitId: string;
  orgUnitRole: string;

  // recaptcha 
  showCaptcha: boolean = false;
  private _captchaRes: string;
  readonly recaptchaSiteKey: string = ENV.recaptchaSiteKey;

  showPasswordType:string = "password";  
  showConfirmPasswordType:string = "password";

  // Add router animation
  @HostBinding('@routerAnimation') routerAnimation = true;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private invitationService: InvitationService,
    private auth: AuthService, private localStorage: LocalStorageService, private userService: UserService) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.token = params.token;
      if (this.token) {
        // ensure token flag is removed
        this.token = this.token.split('@')[0];

        this.validateInvite(this.token);
      }
    });
  }

  resolved(captchaResponse: string) {
    this._captchaRes = captchaResponse;
    console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  validateInvite(token: string) {
    this.showLoading = true;
    this.invitationService.validateInvite(token).then(res => {
      this.showLoading = false;
      if (res.reqId) {
        this.isMember = res.isMember;
        this.firstName = res.firstName;
        this.lastName = res.lastName;
        this.email = res.email;
        this.orgName = res.orgUnitName;
        this.orgUnitId = res.orgUnitId;
        this.role = res.roleName;
        this.orgUnitRole = res.orgUnitRole;
        console.log(res);
      } else {
        this.showError = true;
        this.errorMsg = 'An error validating token has occured.';
      }
    })
      .catch(error => {
        this.showLoading = false;
        this.showError = true;
        this.errorMsg = error.error;
      });
  }

  confirmInvite() {
    this.showLoading = true;

    if (!this._captchaRes && this.showCaptcha) {
      this.showLoading = false;
      this.showError = true;
      this.errorMsg = "Please enter recaptcha!";
      return;
    }

    this.invitationService.confirmInvite(this.token, this.password, this.firstName, this.lastName, this.email,
      this.orgUnitRole, this._captchaRes)
      .then(res => {
        this.localStorage.clearUserLastLogin(this.localStorage.getAuth().id);
        this.showLoading = false;
        this.router.navigateByUrl('/main');
      }).catch(err => {
        this.showLoading = false;
        this.showError = true;
        this.errorMsg = err.error;
        if (err.showRecaptcha) {
          this.showCaptcha = true;
        }
      })
    if (this._captchaRes) grecaptcha.reset();
  }

  togglePassword(){
    if (this.showPasswordType == "password") {
        this.showPasswordType = "text";
    } else {
      this.showPasswordType = "password";
    }
  }

  toggleConfirmPassword(){
    if (this.showConfirmPasswordType == "password") {
        this.showConfirmPasswordType = "text";
    } else {
      this.showConfirmPasswordType = "password";
    }
  }  
}
