import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ApiService } from '../../api/api.service';

@Component({
  selector: 'link-preview',
  templateUrl: './link-preview.component.html',
  styleUrls: ['./link-preview.component.scss']
})

export class LinkPreviewComponent implements OnInit {
  //public isLoading: boolean = false;
  public hasLinks: boolean = false;
  public links: LinkPreviewItem[] = [];
  @Input() text: string;

  constructor(
    private api: ApiService
  ) { }

  ngOnInit(): void {
    this.init();
  }

  init(): void {
    var urlRegex = /(https?:\/\/[^\s]+)/g;
    if (this.text) {
      let links = this.text.match(urlRegex)
      if (links && links.length) {
        this.hasLinks = true;
        //this.isLoading = true;
        var url = links[0];
        let apiUrl = "linkpreview?url=" + btoa(url);
        this.api.get<LinkPreviewItem>(apiUrl).toPromise().then(m => {
          if (!!m) {
            this.links.push(m);
          }
          //this.isLoading = false;
        });
      }
    }
  }
}

export interface LinkPreviewItem {
  title: string,
  description: string,
  image: string,
  url: string
}
