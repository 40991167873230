import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { UserService } from '../../../services/user.service';
import { LocalStorageService } from '../../../localStorage/local-storage.service';
import { Elements, Element as StripeElement, StripeService, CardDataOptions } from 'ngx-stripe';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { ELStripeElementOptions } from '../../../model/stripe-element.model';
import { OrgHub } from '../../../hub/org.hub';

@Component({
  selector: 'app-dialog-payment-method',
  templateUrl: './dialog-payment-method.component.html',
  styleUrls: ['./dialog-payment-method.component.scss']
})
export class DialogPaymentMethodComponent implements OnInit {
  isLoading: boolean;

  // payment
  cardName: string;
  cardAddress: string;
  cardCity: string;
  cardState: string;
  cardZip: string;

  // stripe
  elements: Elements;
  cardNumber: StripeElement;
  cardExpiry: StripeElement;
  cardCVC: StripeElement;
  stripeTest: UntypedFormGroup;
  runInitStripe = true

  constructor(public dialogRef: MatDialogRef<DialogPaymentMethodComponent>, public dialog: MatDialog, private fb: UntypedFormBuilder,
    private stripeService: StripeService, private orgHub: OrgHub) { }

  ngOnInit() {

    this.stripeTest = this.fb.group({
      cardName: ['', [Validators.required]],
      cardAddress: ['', [Validators.required]],
      cardCity: ['', [Validators.required]],
      cardState: ['', [Validators.required]],
      cardZip: ['', [Validators.required]],
    });

    this.initPaymentForm();
  }

  initPaymentForm() {
    if (this.runInitStripe) {
      this.runInitStripe = false;
      this.stripeService.elements({
        // Stripe's examples are localized to specific languages, but if
        // you wish to have Elements automatically detect your user's locale,
        // use `locale: 'auto'` instead.
        locale: 'auto'
      }).subscribe(elements => {
        // Floating labels
        var elementStyles = {
          base: {
            '::placeholder': {
              color: 'grey'
            }
          }
        };

        var elementClasses: ELStripeElementOptions = {
          style: elementStyles,
          classes: {
            focus: 'focused',
            empty: 'empty',
            invalid: 'invalid',
          }
        };

        if (!this.cardNumber) {
          this.cardNumber = elements.create('cardNumber', elementClasses);
          this.cardNumber.mount('#example2-card-number');
        }

        if (!this.cardExpiry) {
          this.cardExpiry = elements.create('cardExpiry', elementClasses);
          this.cardExpiry.mount('#example2-card-expiry');
        }
        if (!this.cardCVC) {
          this.cardCVC = elements.create('cardCvc', elementClasses);
          this.cardCVC.mount('#example2-card-cvc');
        }
      });
    }
  }

  getToken() {
    this.isLoading = true;
    let additionalData: CardDataOptions = {
      name: this.stripeTest.get('cardName').value,
      address_line1: this.stripeTest.get('cardAddress').value,
      address_city: this.stripeTest.get('cardCity').value,
      address_state: this.stripeTest.get('cardState').value,
      address_zip: this.stripeTest.get('cardZip').value,
    }

    this.stripeService
      .createToken(this.cardNumber, additionalData)
      .subscribe(result => {
        if (result.token) {
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges
          console.log(result.token);

          this.dialogRef.close({ status: 'success', token: result.token.id });
        } else if (result.error) {
          this.isLoading = false;
          // Error creating the token
          console.log(result.error.message);
          this.dialogRef.close({ status: 'error' });
        }
      });
  }
}
