import { AccessRight, ExternalAccessRight } from './cloud-access-right';
import { Resource } from './resource';

export class CloudFile extends Resource {
    get contentType(): string {
        return this._contentType;
    }

    set contentType(value: string) {
        this._contentType = value;
        if (this._contentType) {
            if (this._contentType in this.readableContentType) {
                this.description = this.readableContentType[this._contentType];
            } else {
                this.description = this.capitalize(this._contentType.slice(0, this._contentType.indexOf('/')));
            }
        }
    }


    get icon(): string {
        return Resource.getType(this.extension);
    }
    
    get nameWithExtension(): string {
        if (!this.name || !this.extension) return null;
        return this.name + "." + this.extension;
    }

    extension: string;
    // fullPath: string;
    sizeInBytes: number;
    sizeInKiloBytes: number;
    sizeInString: string;

    constructor() {
        super();
        this.resourceType = "file";
        // this.scope = "NORMAL";
    }

    isSameResource(resource: CloudFile){
        return resource instanceof CloudFile && super.isSameResource(resource);
    }
    
    static parse(dto: any) {
        if (!dto) return null;
        var obj = new CloudFile();
        obj.container = dto.container;
        obj.name = dto.displayName;
        obj.path = dto.path;
        obj.createdOn = dto.createdOn;
        obj.modifiedOn = dto.modifiedOn;

        // obj.fullPath = dto.fullPath;
        obj.contentType = dto.contentType;
        obj.sizeInBytes = dto.sizeInBytes;
        obj.sizeInKiloBytes = dto.sizeInKiloBytes;
        obj.sizeInString = dto.sizeInString;
        obj.extension = dto.extension;
        obj.enableNotifications = dto.enableNotifications;
        obj.ownerId = dto.ownerIdentity;

        if (dto.grantees) {
            dto.grantees.forEach(f => obj.grantees.push(AccessRight.parse(f)));
        }

        return obj;
    }    
}