import { SharedDriveFile } from "./shared-drive-file";
import { AccessPermission, AccessRight } from "./cloud-access-right";
import { CloudFolder } from "./cloud-folder";

/**
 * Folder that represents a shared drive
 */
export class SharedDriveFolder extends CloudFolder {
  id: string; //if has id it is a drive folder
  driveId: string; //belong to which drive

  hasDrivePermission(
    userId: string,
    permission: AccessPermission,
    driveGrantees: AccessRight[]
  ) {
    var grantees = driveGrantees;
    if (!grantees || grantees.length == 0) return false;
    var currentGrantee = grantees
      .filter((g) => !g.isSharedAccess)
      .find((g) => g.identity == userId);
    if (!currentGrantee) return false;
    return (
      (currentGrantee.permission & permission) != 0 ||
      currentGrantee.permission == permission
    );
  }

  static parseWithDriveId(dto: any, driveId: string): SharedDriveFolder {
    if (!dto) return null;
    var obj = new SharedDriveFolder();
    obj.container = dto.container;
    obj.name = dto.displayName;
    obj.path = dto.path;
    obj.createdOn = dto.createdOn;
    obj.modifiedOn = dto.modifiedOn;
    obj.isDefault = dto.isDefault;
    obj.driveId = driveId;
    obj.enableNotifications = dto.enableNotifications;
    obj.ownerId = dto.ownerIdentity;
    if (dto.folders) {
      obj.folders = dto.folders.map((f) =>
        SharedDriveFolder.parseWithDriveId(f, driveId)
      );
    }

    if (dto.files) {
      obj.files = dto.files.map((f) =>
        SharedDriveFile.parseWithDriveId(f, driveId)
      );
    }

    if (dto.grantees) {
      obj.grantees = dto.grantees.map((f) => AccessRight.parse(f));
    }

    if (obj.isShared) {
      obj.icon = "folder_shared";
    }
    return obj;
  }

  static parseDrive(dto: any) {
    if (!dto) return null;
    var obj = new SharedDriveFolder();
    obj.container = dto.container;
    obj.name = dto.displayName;
    obj.path = dto.path;
    obj.createdOn = dto.createdOn;
    obj.modifiedOn = dto.modifiedOn;
    obj.icon = "hard_drive";
    obj.id = dto.name;

    if (dto.folders) {
      obj.folders = dto.folders.map((f) =>
        SharedDriveFolder.parseWithDriveId(f, obj.id)
      );
    }

    if (dto.files) {
      obj.files = dto.files.map((f) =>
        SharedDriveFile.parseWithDriveId(f, obj.id)
      );
    }

    if (dto.grantees) {
      obj.grantees = dto.grantees.map((f) => AccessRight.parse(f));
    }

    return obj;
  }

  static parseCollection(dto: any) {
    if (!dto) return null;
    var obj = new SharedDriveFolder();
    obj.icon = "storage";
    obj.container = dto.container;
    obj.name = dto.displayName;
    obj.path = dto.path;
    obj.createdOn = dto.createdOn;
    obj.modifiedOn = dto.modifiedOn;
    obj.isDefault = dto.isDefault;
    obj.isRoot = true;
    obj.id = dto.name;

    if (dto.folders) {
      obj.folders = dto.folders.map((f) => SharedDriveFolder.parseDrive(f));
    }

    if (dto.grantees) {
      obj.grantees = dto.grantees.map((f) => AccessRight.parse(f));
    }

    return obj;
  }
}
