import { timer } from 'rxjs';
import { Directive } from '@angular/core';
import { NG_ASYNC_VALIDATORS, AsyncValidator, AbstractControl } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Directive({
    selector: '[el-email-check]',
    providers: [{
        provide: NG_ASYNC_VALIDATORS, useExisting: EmailValidatorDirective, multi:
            true
    }]
})
export class EmailValidatorDirective implements AsyncValidator {
    constructor(public authService: AuthService) {
    }

    validate(control: AbstractControl) {
        return timer(500).pipe(
            switchMap(() => {
                return this.authService.checkEmail(control.value).then(res => {
                    if (res == true) {
                        return { 'emailExists': true };
                    } else {
                        return null
                    }
                }).catch(err => {
                    return { 'serverError': true };
                });
            })
        )
    }
}
