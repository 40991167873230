import { ReplyToPipe } from "./../pipe/reply-to.pipe";
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Renderer2,
} from "@angular/core";

@Directive({
  selector: "[el-reply-to-remover]",
  providers: [ReplyToPipe],
})
export class ChatReplyToRemoverDirective {
  constructor(
    private elemRef: ElementRef,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private replyToPipe: ReplyToPipe
  ) {}

  ngAfterViewInit(): void {
    let content =
      this.elemRef.nativeElement.innerText == null ||
      this.elemRef.nativeElement.innerText.trim() == ""
        ? this.elemRef.nativeElement.value //for textarea
        : this.elemRef.nativeElement.innerText;
    this.transform(content);
  }

  private transform(replyTo: string) {
    if (
      replyTo == null ||
      replyTo.trim() == "" ||
      !replyTo.includes("el-reply-to")
    )
      return;

    var el = this.elemRef.nativeElement as HTMLElement;
    if (el.hasChildNodes()) {
      el.childNodes.forEach((ch) => {
        if (ch.textContent.includes("el-reply-to")) {
          ch.textContent = this.replyToPipe.undoTransform(ch.textContent);
        }
      });
      return;
    }

    this.renderer.setProperty(this.elemRef.nativeElement, "innerHTML", replyTo);

    var replyToEl = this.elemRef.nativeElement
      .getElementsByTagName("el-reply-to")
      .item(0);
    if (replyToEl) {
      replyToEl.parentElement.removeChild(replyToEl);

      this.changeDetectorRef.detectChanges();
    } else {
      let substring = this.replyToPipe.undoTransform(replyTo);
      this.elemRef.nativeElement.value = substring;
    }
  }
}
