import { Subject, Subscription, BehaviorSubject, iif } from "rxjs";
import { Injectable } from "@angular/core";
import { skip } from "rxjs/operators";
@Injectable()
export class PubSub {
  //UI
  public static readonly MENU_ENABLED: string = "sub-menu-enabled";

  //PAGE
  public static readonly ON_APP_INIT: string = "sub-on-app-init";
  public static readonly AFTER_APP_INIT: string = "sub-after-app-init";
  public static readonly BEFORE_APP_DESTROY: string = "sub-before-app-destroy";

  //AUTH
  public static readonly AFTER_USER_AUTHORIZED: string =
    "sub-after-user-authorized";
  public static readonly BEFORE_USER_LOGOUT: string = "sub-before-user-logout";
  public static readonly AFTER_USER_LOGOUT: string = "sub-after-user-logout";
  public static readonly FORCE_LOGOUT: string = "sub-force-logout";

  //ENTERPRISE
  public static readonly ON_ORG_SWITCHED: string = "sub-on-org-switched";
  public static readonly ON_FIRST_ORG_SWITCH: string = "sub-on-first-org-switch";
  public static readonly ON_CURRENT_ORG_DELETED: string = "sub-on-current-org-deleted";
  public static readonly ON_USER_SUSPENDED: string = "sub-on-user-suspended";

  //ORG FEED
  public static readonly ON_NEW_FEED_RECEIVED: string = "sub-new-feed-received";
  public static readonly ON_EXT_FEED_RECEIVED: string = "sub-ext-feed-received";

  public static readonly ON_HUB_NOTIFICATION_RECEIVED: string = "sub-hub-notification-received";

  public static readonly GET_CONTACTS: string = "sub-get-contacts";

  public static readonly ON_TOOLS_UPDATED: string = "sub-on-tools-updated";

  private _subSink: { [key: string]: BehaviorSubject<any> };

  constructor() {
    this._subSink = {};
  }

  private containsKey(key: string) {
    if (typeof this._subSink[key] === "undefined") {
      return false;
    }

    return true;
  }

  public remove(key: string) {
    if (!this.containsKey(key)) return;
    delete this[key];
  }

  public next<T>(key: string, value?: T | null): void {
    if (!this.containsKey(key)) {
      console.log("[PubSub]-next, key: %s not found, will create one...", key);
      this._subSink[key] = new BehaviorSubject<T>(null);
    }
    this._subSink[key].next(value);
  }

  public subscribe<T>(
    key: string,
    next?: (value: T | any) => void,
    error?: (error: any) => void,
    complete?: () => void,
    skipFirst: boolean = false
  ): Subscription {
    if (!this.containsKey(key)) {
      console.log(
        "[PubSub]-subscribe, key: %s not found, will create one...",
        key
      );
      this._subSink[key] = new BehaviorSubject<T>(null);
    }

    return iif(
      () => skipFirst,
      this._subSink[key].pipe(skip(1)),
      this._subSink[key]
    ).subscribe(next, error, complete);
  }

  public sub<T>(key: string):  BehaviorSubject<T>{
    if (!this.containsKey(key)) {
      console.log(
        "[PubSub]-subscribe, key: %s not found, will create one...",
        key
      );
      this._subSink[key] = new BehaviorSubject<T>(null);
    }

    return this._subSink[key];
  }
}
