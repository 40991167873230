import { MsgReactionCode } from "./../../core/model/message.state";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-dialog-select-reaction",
  templateUrl: "./dialog-select-reaction.component.html",
  styleUrls: ["./dialog-select-reaction.component.scss"],
})
export class DialogSelectReactionComponent implements OnInit {
  existing: string;
  emojis = Object.keys(MsgReactionCode).map((key) => {
    return { code: key, content: MsgReactionCode[key] };
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogSelectReactionComponent>
  ) {}

  ngOnInit(): void {
    this.existing = this.data.selected;
  }

  click(value: string) {
    if (value == this.existing) {
      this.dialogRef.close({ unselected: value });
    } else {
      this.dialogRef.close({ selected: value });
    }
  }
}
