import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ToolsAuthService } from '../../common-services/tools-auth.service';
import { EZSignDocResource } from '../../ustax/beans/us-tax-beans';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class InfoTrackerService {

  onCategoriesChanged: BehaviorSubject<any>;
  // public baseurl = environment.apiEsignLink;
  // this is pass along the ezsign document information between components
  // private _ezsignDocHistory: BehaviorSubject<EZSignDocResource> = new BehaviorSubject((new EZSignDocResource()));
  // public readonly cur_ezsignDocHistory: Observable<EZSignDocResource> = this._ezsignDocHistory.asObservable();



  constructor(private http: HttpClient, public auth: ToolsAuthService) {
    // Set the defaults
    this.onCategoriesChanged = new BehaviorSubject({});
  }

  GetOrgInfoTrackForms(orgUnitId: string, employeeId: string): Observable<any> {
    const url = this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() + '/user/'
      + this.auth.getUserID() + '/formtemplates';
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  GetInfoTrackFormTemplates(employeeId: string): Observable<any> {
    const url = this.auth.baseurl + '/infotracker/user/'
      + this.auth.getUserID() + '/formtemplates';
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  ActivateInfoTrack(orgUnitId: string, employeeId: string, assignFormjson: any): Observable<any> {
    console.log('ActivateInfoTrack:');
    console.log(orgUnitId);
    console.log(employeeId);
    return this.http.post(this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() + '/employee/' +
      this.auth.getUserID() + '/infotrack/activation',
      assignFormjson, this.auth.getElToolsOptions());
  }

  GetOrgStaff(orgUnitId: string, employeeId: string): Observable<any> {
    const url = this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() + '/user/'
      + this.auth.getUserID() + '/orgstaff';
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  GetOrgAllInfoTrackForms(orgUnitId: string, employeeId: string): Observable<any> {
    const url = this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() + '/user/'
      + this.auth.getUserID() + '/allformtemplates';
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  GetPDFBlob(url: string) {
    const opps = this.auth.getElToolsOptionswithoutElToken();
    opps['responseType'] = 'arraybuffer';
    console.log('get content url:' + url);
    return this.http.get(url, opps);
  }

  AddDesignatedStaff(orgUnitId: string, employeeId: string, designatedStaff: any): Observable<any> {
    console.log('AddDesignatedStaff:');
    return this.http.post(this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
      '/employee/' + this.auth.getUserID() + '/adddesignatedstaff',
      designatedStaff, this.auth.getElToolsOptions());
  }

  GetDesignatedOrgStaff(orgUnitId: string, employeeId: string): Observable<any> {
    const url = this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() + '/employee/'
      + this.auth.getUserID() + '/orgdesignatedstaff';
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  GetOrgLocations(orgUnitId: string, employeeId: string): Observable<any> {
    const url = this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() + '/user/'
      + this.auth.getUserID() + '/locations';
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  AddUpdateLocation(orgUnitId: string, employeeId: string, location: any): Observable<any> {
    console.log('Add update location:');
    return this.http.post(this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
      '/employee/' + this.auth.getUserID() + '/addupdatelocation',
      location, this.auth.getElToolsOptions());
  }

  DeleteLocation(orgUnitId: string, employeeId: string, locationSeqNo: number): Observable<any> {
    console.log('Delete location:');
    const url = this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
      '/employee/' + this.auth.getUserID() + '/location/' + locationSeqNo + '/deletelocation'
    console.log('url:' + url);
    return this.http.delete(url, this.auth.getElToolsOptions());
  }

  GetAllUserStatus(orgUnitId: string, employeeId: string, reportedStartDate: string,
    userType: string, reportedEndDate: string): Observable<any> {

    const url = this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
      '/employee/' + this.auth.getUserID() + '/usertype/' + userType + '/reportedstartdate/' +
      reportedStartDate + '/reportedenddate/' + reportedEndDate + '/userstatus';
    console.log('url:');
    console.log(url);
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  GetReportedDates(orgUnitId: string, employeeId: string,
    templateId: number): Observable<any> {
    const url = this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
      '/employee/' + this.auth.getUserID() + '/formtemplate/' +
      templateId + '/reporteddates';
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  GetFormTemplateConfig(orgUnitId: string, employeeId: string,
    templateId: number): Observable<any> {
    console.log('Get form template config');
    const url = this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
      '/user/' + this.auth.getUserID() + '/formtemplate/' +
      templateId + '/config';
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  AddUpdateUser(orgUnitId: string, employeeId: string, newUser: any): Observable<any> {
    console.log('Add update user:');
    return this.http.post(this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
      '/employee/' + this.auth.getUserID() + '/adduser',
      newUser, this.auth.getElToolsOptions());
  }

  SearchUsers(orgUnitId: string, employeeId: string,
    usertype: string, userSearchToken: string) {
    console.log('Search users');
    const url = this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
      '/employee/' + this.auth.getUserID() + '/usertype/' +
      usertype + '/usersearch/' + userSearchToken;
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  GetFormInfo(orgUnitId: string, employeeId: string,
    trackerId: string): Observable<any> {
    console.log('Get form info');
    const url = this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
      '/employee/' + this.auth.getUserID() + '/track/' +
      trackerId + '/forminfo';
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  SubmitForm(orgUnitId: string, employeeId: string, templateId: number, pageId: number, formInfo: any): Observable<any> {
    console.log('Submit form:');
    return this.http.post(this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
      '/employee/' + this.auth.getUserID() + '/formtemplate/' + templateId + '/page/' + pageId +
      '/submitform',
      formInfo, this.auth.getElToolsOptions());
  }

  EditForm(orgUnitId: string, employeeId: string, trackerId: string, formInfo: any): Observable<any> {
    console.log('Edit form:');
    return this.http.put(this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
      '/employee/' + this.auth.getUserID() + '/track/' + trackerId + '/editform',
      formInfo, this.auth.getElToolsOptions());
  }

  GetUserCurrentFormStatus(orgUnitId: string, employeeId: string, userId: string,
    templateId: number, reportedDate: string): Observable<any> {
    console.log('GetUserCurrentFormStatus');
    const url = this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
      '/employee/' + this.auth.getUserID() + '/user/' + userId +
      '/formtemplate/' + templateId + '/reporteddate/' + reportedDate + '/currentstatus';
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  PostAuditAgreement(orgUnitId: string, employeeId: string, agreementResource): Observable<any> {
    console.log('PostAuditAgreement:');
    return this.http.post(this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
      '/employee/' + this.auth.getUserID() + '/agreement/audit',
      agreementResource, this.auth.getElToolsOptions());
  }

  ReviewedFormsPrePosting(orgUnitId: string, employeeId: string, reviewFormData: any): Observable<any> {
    console.log('ReviewedFormsPrePosting:');
    let url = this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
    '/employee/' + this.auth.getUserID() + '/reviewedforms/preposting';
    const opps = this.auth.getElToolsOptionswithoutElToken();
    opps['responseType'] = 'arraybuffer';
    return this.http.post(url, reviewFormData, opps);
  }

  ReviewedFormsFinalPosting(orgUnitId: string, employeeId: string, reviewFormData: any): Observable<any> {
    console.log('ReviewedFormsFinalPosting:');
    return this.http.post(this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
      '/employee/' + this.auth.getUserID() + '/reviewedforms/finalposting',
      reviewFormData, this.auth.getElToolsOptions());
  }

  GetAdminReviewForms(orgUnitId: string, employeeId: string,
    reportedDate: string): Observable<any> {
    console.log('GetAdminReviewForms');
    const url = this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
      '/employee/' + this.auth.getUserID() + '/reporteddate/' + reportedDate + '/reviewforms';
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  GetInfoTrackReviewHistory(orgUnitId: string, employeeId: string,
    reportedDate: string): Observable<any> {
    console.log('GetInfoTrackReviewHistory');
    const url = this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
      '/employee/' + this.auth.getUserID() + '/reporteddate/' + reportedDate + '/review/history';
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  GetLocations(orgUnitId: string, employeeId: string): Observable<any> {
    console.log('GetLocations');
    const url = this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
      '/employee/' + this.auth.getUserID() + '/locations';
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  QuestionnaireAddendum(orgUnitId: string, employeeId: string, trackerId: string,
    notes: any): Observable<any> {
      console.log('QuestionnaireAddendum');
      return this.http.put(this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
        '/employee/' + this.auth.getUserID() + '/track/' + trackerId + '/questionnaire/addendum',
        notes, this.auth.getElToolsOptions());
  }


  submitSignatureForm(jsonData: any) {
    const url = this.auth.baseurl + '/Clients/esign/formsubmit';
    return this.http.post(url, jsonData, this.auth.getElToolsOptions());
  }

  GetReviewStatusReports(orgUnitId: string, employeeId: string): Observable<any> {
    console.log('GetReviewStatusReports');
    const url = this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
      '/employee/' + this.auth.getUserID() + '/review/statusreports';
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  GetInfoTrackerDocumentStream(orgUnitId: string, employeeId: string,
    docId: string): Observable<any> {
    console.log('GetInfoTrackerDocumentStream');
    const url = this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
      '/employee/' + this.auth.getUserID() + '/doc/' + docId + '/pdfcontent';
      const opps = this.auth.getElToolsOptionswithoutElToken();
      opps['responseType'] = 'arraybuffer';
    return this.http.get(url, opps);
  }

  GetInfoTrackerUsers(orgUnitId: string, employeeId: string) {
    console.log('GetInfoTrackerUsers');
    const url = this.auth.baseurl + '/infotracker/orgunit/' + this.auth.getOrgUnitID() +
      '/employee/' + this.auth.getUserID() + '/users';
    return this.http.get(url, this.auth.getElToolsOptions());
  }
}
