import { DialogConfirmComponent } from './../ui/dialog/dialog-confirm/dialog-confirm.component';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DialogWelcomeNewOrgComponent } from '../ui/dialog/welcome-new-org/dialog-welcome-new-org.component';
import { DialogInviteUsersComponent } from '../ui/dialog/invite-users/dialog-invite-users.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EnterpriseSelector } from '../states/enterprise.state.selector';
import { FlowManager } from '../workflow';
import { Router } from '@angular/router';
import { DialogWelcomeNewAccountComponent } from '../ui/dialog/welcome-new-account/dialog-welcome-new-account.component';
import { DialogNewOrgComponent } from '../ui/dialog/new-org/dialog-new-org.component';
import { DialogCountdownComponent } from '../ui/dialog/dialog-countdown/dialog-countdown.component';

@Injectable()
export class UIService {
    // profile img link
    private _imgProfile = new BehaviorSubject<string>('');
    imgProfile = this._imgProfile.asObservable();

    // name profile
    private _profileName = new Subject<string>();
    profileName = this._profileName.asObservable();

    // org unit img link
    private _imgOrgUnit = new Subject<string>();
    imgOrgUnit = this._imgOrgUnit.asObservable()

    // org name
    private _orgName = new Subject<string>();
    orgName = this._orgName.asObservable()

    // channels
    onChannelChanged = new Subject<void>();

    // system error
    showSystemError = false;

    constructor(
        private snackBar: MatSnackBar,
        public dialog: MatDialog,
        private enterpriseSelector: EnterpriseSelector,
        private flowManager: FlowManager,
        private router: Router) { }

    updateProfileImage(item: any) {
        this._imgProfile.next(item);
    }

    updateProfileName(item: any) {
        this._profileName.next(item);
    }

    updateOrgImage(item: any) {
        this._imgOrgUnit.next(item);
    }
    updateOrgName(item: any) {
        this._orgName.next(item);
    }

    enableSystemErrorMessage(msg) {
        if (!this.showSystemError) {
            this.openSystemSnackBar(msg);
            this.showSystemError = true;
        }
    }

    disableSystemErrorMessage() {
        if (this.showSystemError) {
            this.showSystemError = false;
            this.snackBar.dismiss();
        }
    }

    openSystemSnackBar(message: string) {
        let systemSBConfig: MatSnackBarConfig = {
            // horizontalPosition: 'center',
            // verticalPosition: 'bottom',
            duration: 0
            // panelClass: 'system-snackbar'
        }
        this.snackBar.open(message, null, systemSBConfig);
    }

    openSnackBar(message: string, action: string, duration: number = 3000) {
        return this.snackBar.open(message, action, {
            duration: duration
        });
    }

    openCountdownDialog(title: string, content: string, countdownMessage: string, actionBtnText: string, secondsLeft: number, action): MatDialogRef<DialogCountdownComponent, any> {
        const dialogRef = this.dialog.open(DialogCountdownComponent, {
            width: "400px",
            data: {
                title: title,
                content: content,
                countdownDescription: countdownMessage,
                action: actionBtnText,
                secondsLeft: secondsLeft,
              },
        });
        dialogRef.afterClosed().subscribe(() => {
            action();
        });
        return dialogRef;
    }

    openWelcomeNewAccountDialog() {
        const dialogRef = this.dialog.open(DialogWelcomeNewAccountComponent, {
            width: "750px", height: "400px", panelClass: "border-dialog",
        });
        dialogRef.afterClosed().subscribe((res) => {
            // handle buttons from dialog
            if (res) {
                if (res.open === "createOrg") {
                    this.openNewOrgDialog();
                } else if (res.open) {
                    var currentOrg = this.enterpriseSelector.getCurrentOrg();
                    this.openInviteUsersDialog(currentOrg.id);
                }
            }
        });
    }

    private openNewOrgDialog() {
        const dialogRef = this.dialog.open(DialogNewOrgComponent, {
            width: "700px", panelClass: "border-dialog", height: "530px",
        });
        dialogRef.afterClosed().subscribe((res) => {
            if (res && res.status === "success") {
                let orgId = res.response.id;
                this.openInviteUsersDialog(orgId, true);

            } else if (res && res.status === "failed") {
                // error
                this.openSnackBar(res.response, "OK");
                this.router.navigate(["/main/dashboard"]);
            } else {
                //cancel
                this.router.navigate(["/main/dashboard"]);
            }
        });
    }

    openWelcomeNewOrgDialog(orgId) {
        var org = this.enterpriseSelector.getOrg(orgId);
        if (org) {
            const dialogRef = this.dialog.open(DialogWelcomeNewOrgComponent, {
                width: "700px", height: "450px", panelClass: "border-dialog",
                data: {
                    orgName: org.name
                }
            });
            dialogRef.afterClosed().subscribe((res) => {
                if (res) {
                    if (res.open) {
                        this.openInviteUsersDialog(orgId);
                    }
                }
            });
        }
    }

    openInviteUsersDialog(orgId: string, redirectToDashboard = false) {
        const dialogRef = this.dialog.open(DialogInviteUsersComponent, {
            width: "500px",
            height: "650px", panelClass: "border-dialog",
            disableClose: true,
            data: {
                orgId: orgId,
                customDomainID: orgId
            },
        });
        dialogRef.afterClosed().subscribe((res) => {
            if (res && res.status === "success") {
                this.openSnackBar(
                    "You have successfully send join invitations to those contacts. ",
                    "OK"
                );
            } else if (res && res.status === "failed") {
                // error
                this.openSnackBar(res.response, "OK");
                return;
            }
            // else {
            //     //cancel  
            //     this.openSnackBar("Successfully created new organization", "OK");
            // }
            if (redirectToDashboard) {
                this.goToOrgDashboard(orgId);
            }
        });
    }

    private goToOrgDashboard(orgId: string) {
        this.flowManager.switchOrg(orgId).catch(() => {
            this.openSnackBar("Failed to prepare org", "OK");
        });
        this.router.navigate(["/main/dashboard?status=newOrg"]);
    }
}
