import { Injectable } from "@angular/core";
import { Store, Selector } from "@ngxs/store";
import {
  HubConnectionState,
  RoomHubConnectionState,
  HubHandshakeStatus
} from "../model/hubConnection.state";
import { HubState, HubStateModel } from './hub.state';
import { ImmutableSelector } from '@ngxs-labs/immer-adapter';
import * as _ from "lodash";

@Injectable({
  providedIn: "root"
})
export class HubStateSelector {
  constructor(private store: Store) { }

  //#region Snapshots

  getRoomHub(): RoomHubConnectionState {
    const hubState = this.store.selectSnapshot<RoomHubConnectionState>(
      state => state.hub.roomhub
    );
    return { ...hubState };
  }

  getOrgHub(): HubConnectionState {
    const hubState = this.store.selectSnapshot<HubConnectionState>(
      state => state.hub.orghub
    );
    return { ...hubState };
  }

  getSysHub(): HubConnectionState {
    const hubState = this.store.selectSnapshot<HubConnectionState>(
      state => state.hub.syshub
    );
    return { ...hubState };
  }

  //#endregion

  //#region Selectors
  @Selector([HubState.roomhub])
  static roomHubHandshakeStatus(hub: RoomHubConnectionState): HubHandshakeStatus {
    if (!hub) return null;
    return _.cloneDeep(hub.handshakeStatus);
  }
  @Selector([HubState.syshub])
  static sysHubHandshakeStatus(hub: HubConnectionState): HubHandshakeStatus {
    if (!hub) return null;
    return _.cloneDeep(hub.handshakeStatus);
  }
  @Selector([HubState.orghub])
  static orgHubHandshakeStatus(hub: HubConnectionState): HubHandshakeStatus {
    if (!hub) return null;
    return _.cloneDeep(hub.handshakeStatus);
  }
  //#endregion
}
