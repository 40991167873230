import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl, AbstractControl } from '@angular/forms';
import { OUService } from '../../../services/ou.service';
import { Elements, Element as StripeElement, StripeService, CardDataOptions, Token } from 'ngx-stripe';
import { ELStripeElementOptions } from '../../../model/stripe-element.model';
import { UserService } from '../../../services/user.service';
import { pocolog } from 'pocolog';
import { DialogOrderConfirmationComponent } from '../order-confirmation/dialog-order-confirmation.component';
import { OrgHub } from '../../../hub/org.hub';
import { SubSink } from 'subsink';
import { InputFreeOrgLimitStateMatcher } from '../../../state-matcher/input-free-org-limit.state-matcher';
import { UserDataSelector } from '../../../states/user-data.state.selector';
import { Router } from "@angular/router";
import { SubType } from '../../../enum/sub-type';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { MatStepper } from '@angular/material/stepper';
@Component({
  selector: 'app-dialog-new-org-wizard',
  templateUrl: './dialog-new-org-wizard.component.html',
  styleUrls: ['./dialog-new-org-wizard.component.scss']
})

/*
 This component has been abandoned half way and it is not fully functional. Commiting codes for future reference for stepper implementation
*/
export class DialogNewOrgWizardComponent implements OnInit, OnDestroy {
  // expose enum to template
  SubType = SubType;

  // formGroups
  orgFormGroup: UntypedFormGroup;
  subFormGroup: UntypedFormGroup;
  paymentFormGroup: UntypedFormGroup;

  //ui flags
  isFree = true;
  isLoading: boolean;

  showError: boolean;
  errorMsg: string;
  industries: any;

  orgName: string;
  ouCode: any;
  industry: string;

  // payment
  cardName: string;
  cardZip: string;

  // stripe
  elements: Elements;
  cardNumber: StripeElement;
  cardExpiry: StripeElement;
  cardCVC: StripeElement;
  runInitStripe = true

  // free org limit
  currentFreeAccountCount: number;
  freeOrgLimit = 20;
  orgLimitMatcher: any;

  subType = SubType.Free;

  // token
  token: Token;

  get isFreePlan(): boolean {
    return this.subType === SubType.Free;
  }

  private _subSink: SubSink;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogNewOrgWizardComponent>,
    private fb: UntypedFormBuilder, private ouService: OUService, private stripeService: StripeService, private userService: UserService,
    public dialog: MatDialog, private orgHub: OrgHub, private userDataSelector: UserDataSelector, private router: Router) {
    this._subSink = new SubSink();
    this.ouService.getIndustry().then(res => {
      this.industries = res;
    })
      .catch(error => {
        pocolog.error(error);
        this.showError = true;
        this.errorMsg = error.error;
      });
  }
  ngOnDestroy(): void {
    if (this._subSink) this._subSink.unsubscribe();
  }

  ngOnInit() {

    this.orgFormGroup = this.fb.group({
      orgName: ['', Validators.required],
      ouCode: ['', Validators.required],
      industry: ['', Validators.required]
    });

    this.subFormGroup = this.fb.group({
      subType: [SubType.Free, Validators.required]
    });

    this.paymentFormGroup = this.fb.group({
      cardName: ['', [Validators.required]],
      cardZip: ['', [Validators.required]],
    });

    this.initSubType();
  }

  stepperChanged(event: StepperSelectionEvent) {

  }

  onSubTypeChanged(event) {
    this.subType = event.value;
    if (event.value !== SubType.Free) {
      this.isFree = false;
      this.initPaymentForm();
    }
    else this.isFree = true;
  }

  initSubType() {
    // set free org limit
    this.orgLimitMatcher = new InputFreeOrgLimitStateMatcher(this.freeOrgLimit, this.currentFreeAccountCount, this.subType);
  }

  initPaymentForm() {
    if (this.runInitStripe) {
      console.log("initiate paymentform")
      this.runInitStripe = false;
      this.stripeService.elements({
        // Stripe's examples are localized to specific languages, but if
        // you wish to have Elements automatically detect your user's locale,
        // use `locale: 'auto'` instead.
        locale: 'auto'
      }).subscribe(elements => {
        // Floating labels
        var elementStyles = {
          base: {
            '::placeholder': {
              color: 'grey'
            }
          }
        };

        var elementClasses: ELStripeElementOptions = {
          style: elementStyles,
          classes: {
            focus: 'focused',
            empty: 'empty',
            invalid: 'invalid',
          }
        };

        if (!this.cardNumber) {
          this.cardNumber = elements.create('cardNumber', elementClasses);
          this.cardNumber.mount('#example2-card-number');
        }

        if (!this.cardExpiry) {
          this.cardExpiry = elements.create('cardExpiry', elementClasses);
          this.cardExpiry.mount('#example2-card-expiry');
        }
        if (!this.cardCVC) {
          this.cardCVC = elements.create('cardCvc', elementClasses);
          this.cardCVC.mount('#example2-card-cvc');
        }
      });
    }
  }

  //#region stepper button events
  createOrg(stepper: MatStepper) {
    if (this.orgFormGroup.valid) {
      this.isLoading = true;
      console.log(this.orgFormGroup.value.orgName)
      this.orgName = this.orgFormGroup.value.orgName;
      var code = this.orgFormGroup.value.code;
      var industry = this.orgFormGroup.value.industry;
      this.orgHub.createOrg(this.orgName, code, industry, null, null, null, null, null, SubType.Free)
        .subscribe((orgState) => {
          this.isLoading = false;
          stepper.selected.completed = true;
          stepper.selected.editable = false;
          stepper.next();
        });
    }
  }

  subComplete(stepper: MatStepper) {
    if (this.subFormGroup.valid) {
      console.log(this.subFormGroup.value.subType)
      if (this.subFormGroup.value.subType === SubType.Free) {
        this.isLoading = true;
        // show the loading for a second to act like UI is doing something
        setTimeout(() => {
          this.isLoading = false;
          stepper.selected.completed = true;
          stepper.selected.editable = false;
          stepper.next();
          stepper.selected.completed = true;
          stepper.selected.editable = false;
        }, 2000);

      } else {
        if (this.paymentFormGroup.valid) {
          console.log("valid!")
        } else {
          console.log("invalid")
        }
      }
    }
  }
  //#endregion


  //#region events
  onChangeSubType($event) {
    this.initSubType();
  }
  ////#endregion

  getCardToken() {
    this.isLoading = true;
    this.token = null;
    let additionalData: CardDataOptions = {
      name: this.paymentFormGroup.get('cardName').value,
      address_zip: this.paymentFormGroup.get('cardZip').value
    }


    this.stripeService
      .createToken(this.cardNumber, additionalData)
      .subscribe(result => {
        if (result.token) {
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges
          console.log(result.token);
          this.token = result.token;
        } else if (result.error) {
          this.isLoading = false;
          this.errorMsg = result.error.message;
          this.showError = true;
          // Error creating the token
          console.log(result.error.message);
        }
      });
  }

  createNewOrg(cardToken = null) {
    this.isLoading = true;
    // let planId = this.selectedPlan ? this.selectedPlan.planId : null
    // let couponId = "";
    // if (this.coupon && this.coupon.isValid)
    //   couponId = this.coupon.couponId;


    this.orgHub.createOrg(this.orgName, this.ouCode, this.industry, null, null, null, null, null, this.subType, cardToken)
      .subscribe(res => {
        this.isLoading = false;
        this.dialogRef.close({ status: 'success', response: res });
      }, error => {
        this.isLoading = false;
        this.dialogRef.close({ status: 'failed', response: error });
      });
  }

  // couponSuccessEvent($event) {
  //   let couponId = this.coupon.couponId
  //   this.coupon = new Coupon(); // new obj to trigger product-calc-table ngOnChange
  //   this.coupon.couponId = couponId;
  //   if ($event) {
  //     this.coupon.isValid = true;
  //     this.coupon.name = $event.name;
  //     this.coupon.percentOff = $event.percent_off;
  //   } else {
  //     this.coupon.isValid = false;
  //     this.coupon.percentOff = null;
  //     this.coupon.name = null;
  //   }
  //   this.updateTotal();
  // }

  // disable form for free limit
  orgFreeLimitCheck() {
    if (this.subType === SubType.Free) {
      if (this.currentFreeAccountCount >= this.freeOrgLimit) {
        return true;
      }
    }
  }

  isAtActivate() {
    return this.router.url.includes("/activate");
  }


  activeStep1Event(): void {
    console.log('Active event emitted.');
  }

  deactiveStep1Event(): void {
    console.log('Deactive event emitted.');
  }
}
