import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { SystemHub } from "../hub/system.hub";
import { UserDataSelector } from "../states/user-data.state.selector";
import { FeedStateSelector } from "../states/feed.state.selector";
import { FeedState } from "../states/feed.state";
import { Feed, FeedStatus } from "../model/feed";

@Injectable()
export class FeedService {

  onFeedClear: Subject<FeedToRemove> = new Subject<FeedToRemove>();
  
  constructor(
    private userDataSelector: UserDataSelector,
    private feedSelector: FeedStateSelector,
    private systemHub: SystemHub
  ) {}

  //---- old implementation to be removed ------
  clearFeedUnreads(orgId: string, key: string) {
    let feedIds = this.userDataSelector.getThreadUnreads(key);
    
    this.clearLocalFeeds(orgId, key, feedIds);

    if (!feedIds || feedIds.length == 0) return;
    this.systemHub.clearFeed(key, feedIds).toPromise();
  }

  clearFeeds(orgId: string, key: string, feedIds: string[]) {
    this.clearLocalFeeds(orgId, key, feedIds);
    this.systemHub.clearFeed(key, feedIds).toPromise();
  }

  clearLocalFeeds(orgId: string, key: string, feedIds: string[]) {
    var toBeRemoved = new FeedToRemove(orgId, key, feedIds);
    this.onFeedClear.next(toBeRemoved);
  }
  //-------------------------------------------

  updateRecentsAsRead() {
    let unreadIds = this.feedSelector.getRecentFeeds().filter(f => f.status === FeedStatus.Unread).map(f => f.id);
    if (!unreadIds || unreadIds.length < 1) return;
    this.systemHub.updateFeedStatus(unreadIds);
  }

  updateFeedStatus(feed: Feed) {
    let key = FeedState.getUnreadKey(feed);
    this.updateFeedStatusByKey(key);
  }

  updateFeedStatusByKey(key: string) {
    let feedIds = this.feedSelector.getUnreadsById(key);
    if (!feedIds || feedIds.length == 0) return;
    this.systemHub.updateFeedStatus(feedIds);
  }
}

export class FeedToRemove {
  orgId: string;
  key: string;  //roomId / channel Id / postId
  feedIds: string[];

  constructor(orgId: string, key: string, feedIds: string[]) {
    this.orgId = orgId;
    this.key = key;
    this.feedIds = feedIds ? feedIds : [];
  }
}
