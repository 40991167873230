import { timer } from 'rxjs';
import { Directive } from '@angular/core';
import { NG_ASYNC_VALIDATORS, AsyncValidator, AbstractControl } from '@angular/forms';
import { OUService } from '../services/ou.service';
import { switchMap } from 'rxjs/operators';

@Directive({
    selector: '[el-oucode-check]',
    providers: [{
        provide: NG_ASYNC_VALIDATORS, useExisting: OuCodeValidatorDirective, multi:
            true
    }]
})
export class OuCodeValidatorDirective implements AsyncValidator {
    constructor(public ouService: OUService) {
    }

    validate(control: AbstractControl) {
        return timer(500).pipe(
            switchMap(() => {
                return this.ouService.checkOrgId(control.value).then(res => {
                    if (!res) {

                        return { 'ouCodeExists': true };
                    } else {
                        return null
                    }
                }).catch(err => {
                    return { 'serverError': true };
                });
            })
        )
    }
}
