export class NotificationData {
  title: string;
  body: string;
  orgId: string;
  eventType: string; // must not be enum to support ext tools. Use constants NotificationEventType for core events.
  data: any;

  constructor() { }

  static parseFromFirebase(dto: any): NotificationData {
    var notification = new NotificationData();
    //initialize title and body
    if (dto.notification) {
      notification.title = dto.notification.title;
      notification.body = dto.notification.body;
    }

    notification.title = dto.title || notification.title;
    notification.body = dto.body || notification.body;

    notification.eventType = dto.data.event_type;
    notification.orgId = dto.data.orgUnitId ? dto.data.orgUnitId : dto.data.orgId;
    notification.data = dto.data;

    return notification;
  }

  static parse(d: any): NotificationData {
    var notification = new NotificationData();
    notification.title = d.title;
    notification.body = d.body;
    notification.eventType = d.eventName ? d.eventName : d.eventType;
    notification.orgId = d.orgId ? d.orgId : d.orgUnitId;
    notification.data = d.data;

    return notification;
  }
}
