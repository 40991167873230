import { ComposerComponent } from './../chat/chat-room/composer/composer.component';
import { EditorComponent } from './../chat/chat-room/editor/editor.component';
import { InvitedUserTableComponent } from './../organization/user/invited-user-table/invited-user-table.component';
import { FileUploadModule } from 'ng2-file-upload';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FlexLayoutModule } from '@angular/flex-layout';


import { CovalentDataTableModule } from "@covalent/core/data-table";
import { CovalentLayoutModule } from '@covalent/core/layout';
import { CovalentStepsModule } from '@covalent/core/steps';
//import { CovalentHttpModule } from '@covalent/http';
import { CovalentHighlightModule } from '@covalent/highlight';
import { CovalentMarkdownModule } from '@covalent/markdown';
import { CovalentDynamicFormsModule } from '@covalent/dynamic-forms';
import { CovalentPagingModule } from '@covalent/core/paging';
import { CovalentSearchModule } from '@covalent/core/search';
import { CovalentNotificationsModule } from '@covalent/core/notifications';
import { CovalentFileModule } from '@covalent/core/file';

import { ResizeModule } from './../resize/resize.module';
import { TranslateModule } from '@ngx-translate/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { PortalModule } from '@angular/cdk/portal';
import { NgxEchartsModule } from 'ngx-echarts';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { PermissionDirective } from '../core/directives/permission.directive';
import { OuCodeValidatorDirective } from '../core/directives/oucode-check.directive';
import { EmailValidatorDirective } from '../core/directives/email-check.directive';
import { OrgTreeComponent } from '../core/ui/org-tree/org-tree.component';
import { TreeModule } from '@circlon/angular-tree-component';
import { InputSelectOUComponent } from '../core/ui/input/select-ou/input-select-ou.component';
import { DialogOrgSelectorComponent } from '../core/ui/dialog/org-selector/dialog-org-selector.component';
// import { Select2Module } from 'ng2-select2';
import { RelativeDatePipe } from '../core/pipe/relative-date.pipe';
import { LastActivityPipe } from '../core/pipe/last-activity.pipe';
import { FileSizePipe } from '../core/pipe/file-size.pipe';
import { ChatRoomComponent } from '../chat/chat-room/chat-room.component';
import { OrgAvatarComponent } from '../core/ui/org-avatar/org-avatar';
import { PasscodeValidatorDirective } from '../core/directives/passcode-check.directive';
import { UserAvatarComponent } from '../core/ui/user-avatar/user-avatar';
import { ReadMoreComponent } from '../core/ui/read-more/read-more.component';
import { NgxLinkifyjsModule, NgxLinkifyjsPipe } from 'ngx-linkifyjs';
import { InputContactEmailComponent } from '../core/ui/input/contact-email/input-contact-email.component';
import { InputContactPhoneComponent } from '../core/ui/input/contact-phone/input-contact-phone.component';
import { InputContactAddressComponent } from '../core/ui/input/contact-address/input-contact-address.component';
import { FileAuthPipe } from '../core/pipe/file-auth.pipe';
import { ProductCalcTableComponent } from '../core/ui/product-calc-table/product-calc-table.component';
import { ChatMentionsDirective } from '../core/directives/chat-mentions.directive';
import { ChatMentionsOthersComponent } from '../core/ui/chat-mentions-others/chat-mentions-others.component';
import { ChatMentionsListComponent } from '../core/ui/chat-mentions-list/chat-mentions-list.component';
import { TextLinkDirective } from '../core/directives/text-link.directive';
import { AutosizeModule } from 'ngx-autosize';
import { DragDropDirective } from '../core/directives/drag-drop.directive';
import { ChatRoomListComponent } from '../chat/chat-room-list/chat-room-list.component';
import { ChatMessageComponent } from '../chat/chat-room/chat-message/chat-message.component';
import { CouponValidatorDirective } from '../core/directives/coupon-check.directive';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DateAgoPipe } from '../core/pipe/date-ago.pipe';
import { ActionMenuDirective } from '../core/directives/action-menu.directive';
import { TableButtonListComponent } from '../core/ui/table-button-list/table-button-list.component';
import { InputNewGuestComponent } from '../core/ui/input/new-guest/input-new-guest.component';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { EmojiModule } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { HighlightSearchPipe } from '../core/pipe/highlight.search';
import { TruncateTextPipe } from '../core/pipe/truncate-text.pipe';
import { ResizableModule } from 'angular-resizable-element';
import { LinkPreviewComponent } from '../core/ui/link-preview/link-preview.component';
import { NgxMatDateFormats, NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { CountdownModule } from 'ngx-countdown';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSortModule } from '@angular/material/sort';
import { ChatReplyToComponent } from '../chat/chat-room/chat-replyto/chat-replyto.component';
import { ChatReplyToDirective } from '../core/directives/chat-reply-to.directive';
import { ChatReplyToRemoverDirective } from '../core/directives/chat-reply-to-remover.directive';
import { ReplyToPipe } from '../core/pipe/reply-to.pipe';
import { MsgReactionsComponent } from '../chat/msg-reactions/msg-reactions.component';
import { StoragePermissionDirective } from '../core/directives/storage-permission.directive';
import { DateTimePipe } from '../core/pipe/date-time.pipe';
import { StorageExplorerComponent } from '../core/ui/storage-explorer/storage-explorer.component';
import { DialogExplorerProgressComponent } from 'app/core/ui/dialog-explorer-progress/dialog-explorer-progress.component';
//import { TurnstileComponent } from '../core/auth/turnstile/turnstile.component';
//import { NgxTurnstileModule } from 'ngx-turnstile';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {};

const INTL_DATE_INPUT_FORMAT = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hourCycle: 'h12',
  hour: '2-digit',
  minute: '2-digit',
};

const MAT_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: INTL_DATE_INPUT_FORMAT,
  },
  display: {
    dateInput: INTL_DATE_INPUT_FORMAT,
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatBadgeModule,
    MatExpansionModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSortModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    OverlayModule,
    PortalModule,
    PerfectScrollbarModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    //CustomFormsModule,
    NgxEchartsModule,
    CovalentLayoutModule,
    CovalentDataTableModule,
    CovalentStepsModule,
    //CovalentHttpModule.forRoot(),
    CovalentHighlightModule,
    CovalentMarkdownModule,
    CovalentDynamicFormsModule,
    CovalentPagingModule,
    CovalentSearchModule,
    CovalentNotificationsModule,
    CovalentFileModule,
    ResizeModule,
    HttpClientModule,
    AutosizeModule,
    TreeModule,
    // Select2Module,
    NgxLinkifyjsModule.forRoot(),
    MatTableModule,
    NgxFileDropModule,
    TranslateModule,
    FileUploadModule,
    NgxSkeletonLoaderModule,
    PickerModule,
    EmojiModule,
    ResizableModule,
    MatTreeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxMatIntlTelInputComponent,
    CountdownModule,
    //NgxTurnstileModule
  ],
  declarations: [
    PermissionDirective,
    OuCodeValidatorDirective,
    EmailValidatorDirective,
    ChatMentionsOthersComponent,
    TextLinkDirective,
    ChatMentionsDirective,
    ChatMentionsListComponent,
    DragDropDirective,
    PasscodeValidatorDirective,
    OrgTreeComponent,
    InputSelectOUComponent,
    OrgAvatarComponent,
    UserAvatarComponent,
    DialogOrgSelectorComponent,
    ChatRoomComponent,
    ReadMoreComponent,
    RelativeDatePipe,
    LastActivityPipe,
    DateAgoPipe,
    DateTimePipe,
    FileSizePipe,
    InputContactEmailComponent,
    InputContactPhoneComponent,
    InputContactAddressComponent,
    FileAuthPipe,
    ProductCalcTableComponent,
    ChatRoomListComponent,
    ChatMessageComponent,
    ChatReplyToComponent,
    ChatReplyToDirective,
    ChatReplyToRemoverDirective,
    ReplyToPipe,
    CouponValidatorDirective,
    InvitedUserTableComponent,
    ActionMenuDirective,
    TableButtonListComponent,
    InputNewGuestComponent,
    HighlightSearchPipe,
    TruncateTextPipe,
    LinkPreviewComponent,
    EditorComponent,
    ComposerComponent,
    MsgReactionsComponent,
    StoragePermissionDirective,
    DialogExplorerProgressComponent
    //TurnstileComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatBadgeModule,
    MatExpansionModule,
    MatChipsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    MatSortModule,
    OverlayModule,
    PortalModule,
    PerfectScrollbarModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    //CustomFormsModule,
    NgxEchartsModule,
    CovalentLayoutModule,
    CovalentDataTableModule,
    CovalentStepsModule,
    //CovalentHttpModule,
    CovalentHighlightModule,
    CovalentMarkdownModule,
    CovalentDynamicFormsModule,
    CovalentPagingModule,
    CovalentSearchModule,
    CovalentNotificationsModule,
    CovalentFileModule,
    ResizeModule,
    HttpClientModule,
    TreeModule,
    PermissionDirective,
    OuCodeValidatorDirective,
    EmailValidatorDirective,
    TextLinkDirective,
    ChatMentionsDirective,
    DragDropDirective,
    PasscodeValidatorDirective,
    // Select2Module,
    NgxLinkifyjsPipe,
    OrgTreeComponent,
    InputSelectOUComponent,
    OrgAvatarComponent,
    UserAvatarComponent,
    ChatRoomComponent,
    ReadMoreComponent,
    RelativeDatePipe,
    LastActivityPipe,
    DateAgoPipe,
    DateTimePipe,
    FileSizePipe,
    InputContactEmailComponent,
    InputContactPhoneComponent,
    InputContactAddressComponent,
    FileAuthPipe,
    ProductCalcTableComponent,
    MatTableModule,
    ChatRoomListComponent,
    ChatMessageComponent,
    ChatReplyToComponent,
    ChatReplyToDirective,
    ChatReplyToRemoverDirective,
    ReplyToPipe,
    TranslateModule,
    CouponValidatorDirective,
    NgxSkeletonLoaderModule,
    InvitedUserTableComponent,
    ActionMenuDirective,
    TableButtonListComponent,
    InputNewGuestComponent,
    HighlightSearchPipe,
    TruncateTextPipe,
    ResizableModule,
    MatTreeModule,
    LinkPreviewComponent,
    EditorComponent,
    ComposerComponent,
    NgxMatIntlTelInputComponent,
    CountdownModule,
    MsgReactionsComponent,
    StoragePermissionDirective,
    DialogExplorerProgressComponent
    //TurnstileComponent
  ],
  entryComponents: [
    DialogOrgSelectorComponent,
    ChatMentionsListComponent,
    ChatMentionsOthersComponent,
    ChatReplyToComponent,
    DialogExplorerProgressComponent
  ],
  providers: [{
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  }, { provide: MatDialogRef, useValue: {} },
  { provide: MAT_DIALOG_DATA, useValue: [] },
  { provide: NGX_MAT_DATE_FORMATS, useValue: MAT_DATE_FORMATS },],
})
export class SharedBaseModule { }
