import { EventModule, NotificationEventType } from "../enum/notification-event-type.enum";
import { Feed, FeedStatus } from "./feed";

//display concatenated feeds
export class ActivityDisplay {
    id: string;    //the first item of list of feeds
    feeds: Feed[];
    title: string;
    subtitle: string;
    body: string;
    timestamp: number;
    tag: string;
    orgId: string;
    creatorId: string;
    status: FeedStatus;
  
    constructor(
      id: string,
      feeds: Feed[],
      title: string,
      subtitle: string,
      body: string,
      timestamp: number,
      tag: string,
      orgId: string,
      creatorId: string,
      status: FeedStatus
    ) {
      this.id = id;
      this.feeds = feeds ?? feeds;
      this.title = title;
      this.subtitle = subtitle;
      this.body = body;
      this.timestamp = timestamp;
      this.tag = tag;
      this.orgId = orgId;
      this.creatorId = creatorId;
      this.status = status;
    }
    
    get module() {
      if (!this.feeds || this.feeds.length == 0) return null;
      let feed = this.feeds[0];
      switch(feed.module) {
        case EventModule.TEAM:
          return ActivityModule.TEAM;
        case EventModule.CHAT:
          return ActivityModule.CHAT;
        case EventModule.STORAGE:
          return ActivityModule.CLOUDSTORAGE;
        default:
          return ActivityModule.ORGANIZATION;
      }
    }

    //concatenation logic
    public static buildActivity(fds: Feed[]): ActivityDisplay[] {
      if (!fds || fds.length == 0) return [];
  
      let activityDisplays: ActivityDisplay[] = [];
      let concatAct: Feed[] = [];
      
      for (let i=0; i < fds.length; i++) {
        if (concatAct.length == 0 || (fds[i].batchId != null && concatAct[0].batchId == fds[i].batchId && fds[i].event != NotificationEventType.NEW_POST)) {
          concatAct.push(fds[i]);      
        } else {
          activityDisplays.push(ActivityDisplay._createActivity(concatAct));       
          concatAct = [fds[i]];
        }           
      }

      //if it is last item, create the activity
      activityDisplays.push(ActivityDisplay._createActivity(concatAct));
  
      return activityDisplays;
      }

    private static _createActivity(concatAct: Feed[]): ActivityDisplay {
      if (!concatAct || concatAct.length == 0) return;
      let unreadIdx = concatAct.findIndex(c => c.status == FeedStatus.Unread);
      return new ActivityDisplay(
        concatAct[0].id,
        concatAct,
        concatAct[0].title,
        concatAct[0].subTitle,
        concatAct[0].body,
        concatAct[0].timestamp,
        concatAct[0].id,
        concatAct[0].orgId,
        concatAct[0].creatorId,
        unreadIdx == -1 ? concatAct[0].status : concatAct[unreadIdx].status
      );
    }
  }

export enum ActivityModule {
  ALL = 'All',
  CHAT = 'Chat',
  TEAM = 'Team',
  CLOUDSTORAGE = 'Cloud Storage',
  ORGANIZATION = 'Organization/OU'
}