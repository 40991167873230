import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EnterpriseSelector } from '../../../../core/states/enterprise.state.selector';
import { ChannelState } from '../../../../core/model/org.state';
import { OrgHub } from '../../../../core/hub/org.hub';

@Component({
  selector: "app-dialog-edit-channel",
  templateUrl: "./dialog-edit-channel.component.html",
  styleUrls: ["./dialog-edit-channel.component.scss"]
})
export class DialogEditChannelComponent implements OnInit {
  channel: ChannelState;
  updatedChannel: ChannelState;

  isLoading = false;
  isValidName = false;
  channelChanged:boolean=false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogEditChannelComponent>,
    private enterpriseSelector: EnterpriseSelector, private orgHub: OrgHub) { }

  ngOnInit() {
    this.channel = this.enterpriseSelector.getChannel(this.data.channelId);
    this.updatedChannel = { ...this.channel }
  }

  updateChannel() {
    this.isLoading = true;
    this.orgHub.updateChannel(this.channel.id, this.updatedChannel.name)
      .subscribe(res => {
        this.isLoading = false;
        this.channelChanged=false;
        if (!res) {
          this.dialogRef.close(res)
        } else {
          this.dialogRef.close({ status: 'success', response: res });
        }
      },
        err => {
          this.isLoading = false;
          this.dialogRef.close(err);
        });
  }

  handlechange(event){
    this.channelChanged=true;
  }

}
