import { Emittable, Emitter } from "@ngxs-labs/emitter";
import { SystemHub } from "../hub/system.hub";
import { FeedState } from "../states/feed.state";
import { CancellationToken } from "./cancellable-promise";
import { Flow } from "./flow";
import { Feed } from "../model/feed";
import { StartSystemHubFlow } from "./system-hub.flow";

export class GetFeedsFlow extends Flow {
    @Emitter(FeedState.addOrUpdateFeeds)
    addOrUpdateFeeds: Emittable<Feed[]>;

    @Emitter(FeedState.addOrUpdateUnreads)
    addOrUpdateUnreads: Emittable<Feed[]>;

    @Emitter(FeedState.updateReadUnreads)
    updateReadUnreads: Emittable<Feed[]>;
    
    constructor(private sysHub: SystemHub) {
        super();
    }

    public async execute(
        orgId: any = null, 
        token: CancellationToken = null
    ): Promise<any> {
        console.log("executing get-feeds flow start");

        if (!this.sysHub.isConnected) {
            var sysHubStarted = await new StartSystemHubFlow(this.sysHub).execute(
              null,
              token
            );
            if (sysHubStarted == false) {
              this.sysHub.failedOverHandling();
              return;
            }
          }

        var data = await this.sysHub.getRecentFeed(orgId);
        console.log("executing get-feeds flow end %o", data);
        if (token != null && token.isCancelled) return null;

        if (data) {
            this.addOrUpdateFeeds.emit(data);
            this.addOrUpdateUnreads.emit(data);
            this.updateReadUnreads.emit(data); //to remove unreads that have been cleared when app is closed/hub disconnected
        }

        this.output = orgId;

        return await super.execute(this.output, token);
    }
}