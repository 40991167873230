//Note: Promise.allSettled is available from Node version >12.9.
export class PromiseAllSettled {
    static result = promises =>
              Promise.all(
                promises.map((promise, i) =>
                  promise
                    .then(value => ({
                      status: "fulfilled",
                      value,
                    }))
                    .catch(error => ({
                      status: "rejected",
                      error,
                    }))
                )
              );
}
