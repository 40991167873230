import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { EMPTY, Observable, Subject, throwError, from } from 'rxjs';
import { Router } from '@angular/router';
import { AuthStateSelector } from '../states/auth.state.selector';
import { catchError, switchMap, map } from 'rxjs/operators';
@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
    private ERROR_INVALID_GRANT = "invalid_grant";
    refreshTokenInProgress = false;

    tokenRefreshedSource = new Subject();
    tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
    constructor(public auth: AuthService, private router: Router,
        private authStateSelector: AuthStateSelector) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        console.log("intercepting")
        // console.time('[HttpTokenInterceptor] Jwt check')
        // if (this.authStateSelector.isAuthenticated) {
        //     this.checkJwtExpiry();
        // }
        // console.timeEnd('[HttpTokenInterceptor] Jwt check')
        // request = request.clone({
        //     setHeaders: {
        //         Authorization: `Bearer ${this.auth.getToken()}`
        //     }
        // });
        return next.handle(request).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    return this.handleResponseError(err, request, next)
                }
            }));
    }

    private refreshJwtToken(): Observable<any> {

        if (this.refreshTokenInProgress) {
            return new Observable(observer => {
                this.tokenRefreshed$.subscribe(() => {
                    observer.next();
                    observer.complete();
                });
            });
        } else {
            this.refreshTokenInProgress = true;
            return new Observable(observer => {
                this.auth.refreshAccessToken().then((token) => {
                    this.refreshTokenInProgress = false;
                    this.tokenRefreshedSource.next(true);
                    observer.next();
                    observer.complete();
                }).catch(err => {
                    // error refreshing, logout user
                    console.log("[TokenInterceptor] Error refreshing token")
                    this.refreshTokenInProgress = false;
                    // this.auth.logout();
                    observer.error();
                    // this.router.navigate(['/login'], { queryParams: { status: 'jwtexpired' } });
                });
            })
        }
    }

    handleResponseError(error, request?, next?) {
        // Invalid token error
        if (error.status === 401) {
            var isRevokeTokenError = error && (error.url.indexOf("user/fcmToken") >= 0 || error.url.indexOf("revokeToken") >= 0)
            var is2FAError = (error.url.indexOf("2fa") >= 0);

            if (is2FAError) {
                console.error("2FA error");
                return throwError(error);
            }
            if (isRevokeTokenError) {
                console.error("Token revocation error");
                return throwError(error);
            }

            return this.refreshJwtToken().pipe(
                switchMap(() => {
                    let newToken = this.authStateSelector.accessToken
                    console.log("new token " + newToken)
                    request = this._setNewAccessToken(request, newToken);
                    return next.handle(request);
                }),
                catchError(e => {
                    this.refreshTokenInProgress = false;
                    console.error("Refresh JWT exception" + e);
                    // this.auth.logout();
                    // this.router.navigate(['/login'], { queryParams: { status: 'jwtexpired' } });

                    return throwError(error);
                }));
        } else if (error.status === 400) {
            if ((error.error && error.error.code === this.ERROR_INVALID_GRANT)) {
                // Refresh token error
                // this.auth.logout();
                console.error("Error 400: Invalid grant for refresh token. Session has expired");
                this.refreshTokenInProgress = false;
                this.auth.logout();
                this.router.navigate(['/login'], { queryParams: { status: 'jwtexpired' } });

                return throwError(error);
            } else if (error.url.indexOf("revokeToken") >= 0) {
                console.error('Revoke token failed');
                return throwError(error);
            }
        }

        return throwError(error);
    }

    private _setNewAccessToken(request: HttpRequest<any>, accessToken: string) {
        let headers = request.headers
            .set('Content-Type', 'application/json')
            .set('Authorization', "Bearer " + accessToken);

        const cloneReq = request.clone({ headers });
        console.log("new access tokens")
        console.log(cloneReq)
        return cloneReq;
    }
}
