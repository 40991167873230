import { Injectable, Injector } from "@angular/core";
import { Selector, Store } from "@ngxs/store";
import { Feed, FeedStatus } from "../model/feed";
import { FeedState } from "./feed.state";
import { Observable } from "rxjs";
import { StringDictionary } from "../util/dictionary";
import { EnterpriseSelector } from "./enterprise.state.selector";
import { MessagingSelector } from "./messaging.state.selector";
import { EventModule, NotificationEventType } from "../enum/notification-event-type.enum";

@Injectable({
    providedIn: "root"
})

export class FeedStateSelector {
    private static enterpriseStateSelector: EnterpriseSelector;
    private static messagingStateSelector: MessagingSelector;

    constructor(public store: Store, injector: Injector) {
        FeedStateSelector.enterpriseStateSelector = injector.get<
            EnterpriseSelector
        >(EnterpriseSelector);

        FeedStateSelector.messagingStateSelector = injector.get<
            MessagingSelector
        >(MessagingSelector);
    }

    getAllFeeds() {
        return this.store.selectSnapshot(FeedState.allFeeds);
    }

    getAllFeeds$(): Observable<Feed[]> {
        return this.store.select(FeedState.allFeeds);
    }

    getRecentFeeds() {
        return this.store.selectSnapshot(FeedState.recentFeeds);
    }

    getRecentFeeds$(): Observable<Feed[]> {
        return this.store.select(FeedState.recentFeeds);
    }

    getUnreads() {
        return this.store.selectSnapshot(FeedState.unreads);
    }

    getUnreadsById(id: string) {
        var allUnreads = this.getUnreads();
        return allUnreads.get(id);
    }

    // @Selector([FeedState.unreads])
    // static channelTotalUnreads(unreads: StringDictionary): number {
    //     if (!unreads) return 0;
    //     var channels = this.enterpriseStateSelector.getCurrentChannels();
    //     if (!channels || channels.length == 0) return 0;

    //     var count = 0;
    //     channels.forEach(ch => {
    //         if (unreads.containsKey(ch.id)) {
    //             count += unreads.count(ch.id);
    //         }
    //     })

    //     return count;
    // }

    @Selector([FeedState.recentFeeds])
    static hasUnreadActivity(fds: Feed[]): number {
        if (!fds) return 0;

        var count = 0;
        fds.forEach((f) => {
            if (f.status == FeedStatus.Unread) count++;
        });

        return count;
    }

    @Selector([FeedState.recentFeeds])
    static channelTotalUnreads(fds: Feed[]): number {
        if (!fds) return 0;

        var count = 0;
        fds.forEach((f) => {
            if (f.event == NotificationEventType.NEW_POST && f.status == FeedStatus.Unread) count++;
        });

        return count;
    }


    @Selector([FeedState.recentFeeds])
    static msgsTotalUnreads(fds: Feed[]): number {
        if (!fds) return 0;

        var count = 0;
        fds.forEach((f) => {
            if (
              f.module == EventModule.CHAT &&
              f.status == FeedStatus.Unread &&
              f.event != NotificationEventType.EXPORT_CHAT_HISTORY_SUCCESS &&
              f.event != NotificationEventType.EXPORT_CHAT_HISTORY_FAILED
            )
              count++;
        });

        return count;
    }

    @Selector([FeedState.recentFeeds])
    static sharedTotalUnreads(fds: Feed[]): number {
        if (!fds) return 0;

        var count = 0;
        fds.forEach((f) => {
            if ((f.event == NotificationEventType.SHARE_FOLDER || f.event == NotificationEventType.SHARE_FILE) && f.status == FeedStatus.Unread) count++;
        });

        return count;
    }
}