import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { PlanType } from '../model/subscription.model';
import { SubType } from '../enum/sub-type';

// check if free org limit is hit
export class InputFreeOrgLimitStateMatcher implements ErrorStateMatcher {

    constructor(private freeOrgLimit: number, private currentFreeOrgs: number, private subType: SubType) { }
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;

        if (this.subType === SubType.Free) {
            if (this.currentFreeOrgs >= this.freeOrgLimit) {
                return true;
            }
        }

        return (control && (!control.valid && !control.pristine));
    }
}
