import { Component, Input, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: "app-dialog-offline-logout",
  templateUrl: "./dialog-offline-logout.component.html",
  styleUrls: ["./dialog-offline-logout.component.scss"],
})
export class DialogOfflineLogoutComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogOfflineLogoutComponent>) { }

  ngOnInit() {
  }

  close(action: string) {
    this.dialogRef.close(action);
  }
}
