import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrgUserState } from '../../../../core/model/org.state';
import { OrgHub } from '../../../../core/hub/org.hub';

@Component({
  selector: 'app-dialog-restore-user',
  templateUrl: './dialog-restore-user.component.html',
  styleUrls: ['./dialog-restore-user.component.scss']
})
export class DialogRestoreUserComponent implements OnInit {

  isLoading = false;
  selectedUser: OrgUserState

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private orgHub: OrgHub,
    public dialogRef: MatDialogRef<DialogRestoreUserComponent>) { }

  ngOnInit() {
    this.selectedUser = this.data.selectedUser;
  }

  restoreUser() {
    this.isLoading = true;
    this.orgHub.restoreOrgUsers(this.selectedUser.ouId, this.selectedUser.userId)
      .subscribe(res => {
        this.isLoading = false;
        if (!res) {
          this.dialogRef.close(res);
        } else {
          this.dialogRef.close('success');
        }
      }, err => {
        this.isLoading = false;
        this.dialogRef.close(err);
      });
  }
}
