import { FlowManager } from './../../../workflow/flow-manager';
import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Emitter, Emittable } from "@ngxs-labs/emitter";
import { EnterpriseState } from "../../../states/enterprise.state";
import { LoginHistoryState } from "../../../model/login-history.state";
import { UserDataSelector } from "../../../states/user-data.state.selector";
import { HistoryState } from "../../../states/history.state";
import { UIState } from '../../../states/ui.state';

@Component({
  selector: "app-dialog-switch-ou",
  templateUrl: "./dialog-switch-ou.component.html",
  styleUrls: ["./dialog-switch-ou.component.scss"]
})
export class DialogSwitchOUComponent implements OnInit {
  isLoading = false;
  orgId: string;
  redirectUrl: string;
  source: SwitchOUSource = SwitchOUSource.Item;

  @Emitter(HistoryState.save)
  saveLoginHistory: Emittable<LoginHistoryState>;
  @Emitter(UIState.setRedirectOnSwitchOrg)
  setRedirectOnSwitchOrg: Emittable<boolean>;


  redirectOnSwitch = true;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogSwitchOUComponent>,
    private userSelector: UserDataSelector,
    private flowManager: FlowManager,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.orgId = this.data.orgId;
    if (this.data.source) {
      this.source = this.data.source;
    }

    if (this.data.disableRedirect) this.redirectOnSwitch = false;
  }

  switchOU() {
    this.isLoading = true;

    // save login history
    let history: LoginHistoryState = {
      userId: this.userSelector.userId,
      orgId: this.orgId
    };
    this.saveLoginHistory.emit(history);

    if (!this.redirectOnSwitch) {
      this.setRedirectOnSwitchOrg.emit(false);
    }
    console.info("##########################this is new")
    this.flowManager.switchOrg(this.orgId).then(() => {
      this.dialogRef.close({ status: "success" });
    }).catch(() => {
      this.snackBar.open("Failed to prepare org", "OK", {
        duration: 3000,
      });
    });
    //this.esignauth.updateOrg(this.orgId);

  }
}

export enum SwitchOUSource {
  Chat = "CHAT",
  Card = "CARD",
  Item = "ITEM"
}
