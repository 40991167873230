import { MembershipReqService } from './../../../../../core/services/membership-req.service';
import { ValidatorService } from './../../../../../core/services/validator.service';
import { EnterpriseSelector } from './../../../../../core/states/enterprise.state.selector';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SelectOU } from '../../../../../core/model/select-ou.model';
import { OrgRoleState } from '../../../../../core/model/org.state';
import { RoleEntity } from '../../../../../core/model/userRole.model';
import { environment as ENV } from "../../../../../../environments/environment";
import * as _ from "lodash";

@Component({
  selector: 'app-dialog-csv-invite',
  templateUrl: './dialog-csv-invite.component.html',
  styleUrls: ['./dialog-csv-invite.component.scss']
})
export class DialogCsvInviteComponent implements OnInit {

  selectOU: SelectOU = new SelectOU();
  isImporting: boolean = false;
  isImportDone: boolean = false;
  errorMsg: string;
  ttlImportSuccess: string;
  importFailedUsers: ImportedUser[];
  csvFile: File;
  csvFileName: string;
  private _currentOrgId: string;
  private _roles: OrgRoleState[] = [];
  private _importedUsers: ImportedUser[] = [];
  private _csvColumns: number = 4;
  private _csvHeaderRow: number = 3;
  private _ignoredRows: number = 4;
  csvTemplateUrl: string = ENV.publicStorageLink + "default/Import Users CSV Template.csv";
;

  @ViewChild('csvReader') csvReader: any;

  constructor( 
    public dialogRef: MatDialogRef<DialogCsvInviteComponent>,
    private translateService: TranslateService,
    private enterpriseSelector: EnterpriseSelector,
    private validator: ValidatorService,
    private membershipReqService: MembershipReqService) { }

  get hasErrorMsg(): boolean {
    return this.errorMsg && this.errorMsg.trim() != "";
  }

  get hasFailedImport(): boolean {
    return this.importFailedUsers && this.importFailedUsers.length > 0;
  }

  get ttlImportFailed(): string {
    return this.importFailedUsers ? this.importFailedUsers.length.toString() : "0";
  }

  get fileName(): string {
    return this.csvFileName && this.csvFileName.trim() != "" ? this.csvFileName : this.translateService.instant("ORGANIZATION.BATCH_INV_USERS.NO_FILE");
  }

  get disableImportBtn(): boolean {
    return !this.csvFile || !this.selectOU;
  }

  ngOnInit(): void {
    let ou = this.enterpriseSelector.getCurrentOu();
    this._currentOrgId = this.enterpriseSelector.getCurrentOrgId();
    if (!ou || !this._currentOrgId) this.dialogRef.close();
    this.selectOU.ouId = ou.id;
    this.selectOU.ouName = ou.name;

    this._roles = this.enterpriseSelector.getOrgRoles(this._currentOrgId).filter(x =>
      RoleEntity[x.normalizedName] !== RoleEntity.OWNER && RoleEntity[x.normalizedName] !== RoleEntity.ADMIN);
  }

  uploadCSVFile() {
    let fileElement = this.csvReader.nativeElement as HTMLElement;
    this.csvReader.nativeElement.value = "";
    fileElement.nodeValue = null;
    fileElement.click();
    
  }

  getCSVFile($event) {
    console.log($event);
    this.csvFile = $event.target.files[0];
    this.csvFileName = this.csvFile.name;
    
  }

  isValidCSVFile(file: any) {  
    return file.name.endsWith(".csv");  
  }

  resetCSVFile() {
    this.csvFile = null;
    this.csvFileName = "";
  }

  getCSVData() { 
    this.isImporting = true;
    if (this.isValidCSVFile(this.csvFile)) {  
    
      let reader = new FileReader();  
      reader.readAsText(this.csvFile);  
  
      reader.onload = () => {  
        let csvData = reader.result;  
        let csvRecordsArray = (<string>csvData).split(/\r\n|\n/);  

        let isHeaderCorrect = this.checkCSVHeader(csvRecordsArray);
        if (!isHeaderCorrect) {
          this.errorMsg = this.translateService.instant("ORGANIZATION.BATCH_INV_USERS.CSV_READ_ERROR");
          this.isImporting = false;
          this.resetCSVFile();
          return;
        }

        let res = this.getDataRecordsArrayFromCSVFile(csvRecordsArray);  
        if (res) {
          this.importUsers();
        } else {
          this.isImporting = false;
        }
      };  
  
      reader.onerror = () => {  
        this.errorMsg = this.translateService.instant("ORGANIZATION.BATCH_INV_USERS.CSV_READ_ERROR"); 
        this.isImporting = false;
        this.resetCSVFile();
      };  
  
    } else {  
      this.errorMsg = this.translateService.instant("ORGANIZATION.BATCH_INV_USERS.INVALID_FILE_ERR");  
      this.isImporting = false;
      this.resetCSVFile();
    }
  }

  checkCSVHeader(csvRecordsArray: any) {
    try {
      let headers = (<string>csvRecordsArray[this._csvHeaderRow]).split(',');  
    
      if (headers.length != this._csvColumns) return false;
      if(headers[0].toLowerCase().trim() == "first name" &&
      headers[1].toLowerCase().trim() == "last name" &&
      headers[2].toLowerCase().trim() == "email" &&
      headers[3].toLowerCase().trim() == "organization role") {
        return true; 
      } 

      return false;
    } catch (err) {
      return false;
    }
    
    
  }

  getDataRecordsArrayFromCSVFile(csvRecordsArray: any) {  
    this._importedUsers = [];

    try {
      for (let i = this._ignoredRows; i < csvRecordsArray.length; i++) {  
        let currentRecord = (<string>csvRecordsArray[i]).split(',');  

          //if row is empty
          if (currentRecord.join().trim() == "") continue;
    
          let csvRecord: ImportedUser = new ImportedUser();  
          csvRecord.firstName = currentRecord[0].trim();  
          csvRecord.lastName = currentRecord[1].trim();  
          csvRecord.email = currentRecord[2].trim();  
          csvRecord.roleId = this.getUserRole(currentRecord[3]).trim();

          //check empty names
          if (!csvRecord.firstName || !csvRecord.lastName) {
            this.errorMsg = this.translateService.instant("ORGANIZATION.BATCH_INV_USERS.INVALID_NAME") + (i+1);
            this.resetCSVFile();
            return false;
          }
          
          //check email
          const emailValidator = this.validator.validateEmail(csvRecord.email);
          if (!emailValidator || !emailValidator.success) {
            this.errorMsg = this.translateService.instant("ORGANIZATION.BATCH_INV_USERS.INVALID_EMAIL") + (i+1) + ": " + csvRecord.email;
            this.resetCSVFile();
            return false;
          }
  
          //check role
          if (csvRecord.roleId == "") {
            this.errorMsg = this.translateService.instant("ORGANIZATION.BATCH_INV_USERS.INVALID_ROLE") + (i+1) + ": " + currentRecord[3];
            this.resetCSVFile();
            return false;
          }
    
          this._importedUsers.push(csvRecord);  
          
      }  
      return true;
    } catch (err) {
      this.errorMsg = this.translateService.instant("ORGANIZATION.BATCH_INV_USERS.CSV_READ_ERROR");
      this.resetCSVFile(); 
      return false;
    }

    
 
  }

  getUserRole(roleStr: string): string {
    try {
      var idx = Number(roleStr);
      var role = this._roles.find(r => RoleEntity[r.normalizedName] === idx);
      return role ? role.id : "";
    } catch (err) {
      return "";
    }   
  }

  importUsers() {
    //console.log(this.selectOU.ouId);
    //console.log(this._importedUsers);

    if (!this._importedUsers || this._importedUsers.length == 0) {
      this.errorMsg = "No users to import";
      this.isImporting = false;
      return;
    }
        var grps = _.groupBy(this._importedUsers, (d: ImportedUser) => d.email);

        for (var key in grps) {
          if (grps[key].length > 1) {
            this.errorMsg = "Duplicated email";
            this.isImporting = false;

            return;
          }
        }

    this.membershipReqService.issueInvitations(this._importedUsers, this._currentOrgId, this.selectOU.ouId)
    .then((res) => {
      //console.log("issueInvitation res: %o", res);
      if (res) {
        this.ttlImportSuccess = res.reqSuccess ? res.reqSuccess.length.toString() : "0";
        this.importFailedUsers = res.failedReqs ? ImportedUser.parseList(res.failedReqs) : [];
      }
      this.isImportDone = true;
    }).catch((err) => {
      if (err) {
        this.errorMsg = err.error;
      }
    }).finally(() => this.isImporting = false);

  }
  

}

export class ImportedUser {
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;

  get displayName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  static parseList(ls: any[]) {
    let result: ImportedUser[] = [];
    if (ls) {
        ls.forEach(d => {
            let usr = this.parse(d);

            result.push(usr);
        });
    }
    return result;
  }

  static parse(data: any) {
    let usr = new ImportedUser;
    usr.firstName = data.firstName;
    usr.lastName = data.lastName;
    usr.email = data.email;
    usr.roleId = data.roleId;

    return usr;
  }

}
