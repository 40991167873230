import { PlanType } from './subscription.model';

export class Plan {
    planId: string;
    planType: PlanType;
    productId: string;
    productName: string;
    nickName: string;
    trialPeriodDays: number;
    currency: string;
    interval: Interval;
    intervalCount: number;
    billingScheme: BillingScheme;
    amount: number;
    created: Date;
    tiersMode: TiersMode;
    usageType: string;
    tiers: Tier[] = [];
    metadata: string;
    transformUsageDivideBy: number;
    productType: ProductType;
    preassignedUnit: number;
}

export class Tier {
    flatAmount: number;
    upTo: number;
    unitAmount: number;
    // UI variable
    quantity: number;
}

export enum Interval {
    Day = "day",
    Month = "month",
    Year = "year"
}

export enum TiersMode {
    Graduated = "graduated",
    Volume = "volume"
}

export enum BillingScheme {
    Tiered = "tiered",
    PerUnit = "per_unit"
}

export enum ProductType { Internal = 0, External = 1, AddOn = 2 }
