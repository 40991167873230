import { AccessRight } from "./cloud-access-right";
import { Resource } from "./resource";
import { SharedResource } from "./shared-resource";

export class SharedFile extends SharedResource {

    originExtension: string;
    originSizeInBytes: number;
    originSizeInString: string;

    private _originContentType: string;
    get originContentType(): string {
        return this._originContentType;
    }

    set originContentType(value: string) {
        this._originContentType = value;
        if (this._originContentType) {
            if (this._originContentType in this.readableContentType) {
                this.description = this.readableContentType[this._originContentType];
            } else {
                this.description = this.capitalize(this._originContentType.slice(0, this._originContentType.indexOf('/')));
            }
        }
    }


    get icon(): string {
        return Resource.getType(this.extension);
    }

    get extension(): string {
        return this.originExtension;
    }

    get sizeInBytes(): number {
        return this.originSizeInBytes;
    }

    get sizeInString(): string {
        return this.originSizeInString;
    }

    get nameWithExtension(): string {
        if (!this.name || !this.extension) return null;
        return this.name + "." + this.extension;
    }

    isSameResource(resource){
        return resource instanceof SharedFile && super.isSameResource(resource);
    }
    constructor() {
        super();
        this.resourceType = "file";
        // this.scope = "SHARED";
    }

    /** DTO is SharedDummyFileDto from file server. */
    public static parse(dto: any) {
        if (!dto) return null;
        var obj = new SharedFile();

        obj.container = dto.container;
        obj.path = dto.path;
        obj.name = dto.displayName;
        obj.ownerId = dto.ownerIdentity;
        obj.createdOn = dto.createdOn;
        obj.modifiedOn = dto.modifiedOn;

        obj.originContainer = dto.originContainer;
        obj.originPath = dto.originPath;

        obj.originContentType = dto.originContentType;
        obj.originExtension = dto.originExtension;
        obj.originSizeInBytes = dto.originSizeInBytes;
        obj.originSizeInString = dto.originSizeInString;

        obj.enableNotifications = dto.enableNotifications;

        if (dto.grantees) {
            dto.grantees.forEach(f => obj.grantees.push(AccessRight.parse(f)));
        }
        
        return obj;
    }
}

