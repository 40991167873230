import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ESignCase, ESignDoc, ClassifyPage, ESignCPA, ClientReminder, ESignClient } from '../../income-expense/beans/income-expense-beans';
import { ToolsAuthService } from '../../common-services/tools-auth.service';
@Injectable()
export class IncomeExpenseService  {
  // public baseurl = environment.apiEsignLink;
  // this is pass along the case information between components
  private _case: BehaviorSubject<ESignCase> = new BehaviorSubject((new ESignCase()));
  public readonly cur_case: Observable<ESignCase> = this._case.asObservable();
  cacheData = {};
  esignauth = null;
  CPAID = '';  // we need to change this later
  esign_key = 'ESIGN_AUTH';
  role: string;
  constructor(private http: HttpClient, public auth: ToolsAuthService) {
  }

  setCacheData(key, value) {
    this.cacheData[key] = value;
  }

  getCacheData(key) {
    return this.cacheData[key];
  }

  
  // IET related server api - start

  getPDFBlob(url: string) {
    const opps = this.auth.getElToolsOptionswithoutElToken();
    opps['responseType'] = 'arraybuffer';
    console.log('get content url:' + url);
    return this.http.get(url, opps);
  } 
  
  getCompanyTypes() {
    console.log('calling getCompanyTypes server api');
    const url: string = this.auth.baseurl + '/iet/Lookups/CompanyTypes';
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  getClientCompanies(orgUnitId: string, clientId: string) {
    console.log('calling getClientCompanies server api');
    const url: string = this.auth.baseurl + '/iet/ClientCompanies/orgunitid/' +
      orgUnitId + '/clientid/' + clientId + '/mycompanies';
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  getCompanyStaff() {
    console.log('calling getCompanyStaff server api');
    const url: string = this.auth.baseurl + '/iet/Lookups/OrgUnitId/' + this.auth.getOrgUnitID()
    + '/staff/' + this.auth.getUserID();
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  createCompany(orgUnitId: string, clientId: string, newCompanyJson: any) {
    return this.http.post(this.auth.baseurl + '/iet/ClientCompanies/orgunitid/' +
      orgUnitId + '/clientid/' + clientId + '/clientcompany/create', newCompanyJson, this.auth.getElToolsOptions());
  }

  deleteCompany(orgUnitId: string, clientId: string, companyId: string) {
    return this.http.delete(this.auth.baseurl + '/iet/ClientCompanies/orgUnitId/' + orgUnitId +
    '/clientid/' + clientId + '/company/' + companyId + '/delete' ,  this.auth.getElToolsOptions());
  }



  getAccountTypes(companyTypeId: string) {
    console.log('calling getAccountTypes server api');
    const url: string = this.auth.baseurl + '/iet/Lookups/company/' + companyTypeId + '/accounttypes';
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  getFiscalYearlist(orgUnitId: string, companyId: string) {
    console.log('calling getFiscalYearlist server api');
    const url: string = this.auth.baseurl + '/iet/ClientCompanies/orgunitid/' +
    orgUnitId  + '/clientcompany/' + companyId + '/fiscalyears' ;
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  getCompanyAccountLevelReceipts(orgUnitId: string, companyId: string, year: string) {
    console.log('calling getCompanyAccountLevelReceipts server api');
    const url: string = this.auth.baseurl + '/iet/ClientCompanies/orgunitid/' +
    orgUnitId  + '/clientcompany/' + companyId + '/receipts/year/' + year;
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  deleteReceiptImage (orgUnitId: string, companyId: string, docId: string) {
    console.log('calling deleteReceiptImage server api');
    const url: string = this.auth.baseurl + '/iet/ClientCompanies/orgunitid/' +
    orgUnitId  + '/clientcompany/' + companyId + '/receipts/' + docId + '/delete';
    return this.http.delete(url, this.auth.getElToolsOptions());
  }

  getDataUrl(uploadedFile: File) {
    console.log('getDataUrl');
    const formData: FormData = new FormData();
    formData.append('ff', uploadedFile, uploadedFile.name);
    console.log(formData.getAll('ff'));
    return this.http.post(this.auth.baseurl + '/iet/ClientCompanies/dataurl', formData,
    this.auth.getElToolsOptionswithoutElToken());
  }

  downloadYearlyReceipts(orgUnitId: string, companyId: string, companyName: string,  fiscalYear: string): any {
    const url: string = this.auth.baseurl + '/iet/ClientCompanies/orgunitid/' + orgUnitId + '/clientcompany/' + companyId + '/year/' +
                        fiscalYear + '/mergedreceipts';
    console.log(url);
    this.getPDFBlob(url).subscribe(resp => {
      const file = new Blob([<any>resp], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      console.log('set pdf:' + fileURL);
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = fileURL;
      link.download = companyName + '_' + fiscalYear + '_Annual Receipts.pdf';
      link.click();
    });
  }

  updateCompany(orgUnitId: string, clientId: string, companyId: string, updateCompanyJson: any) {
    return this.http.post(this.auth.baseurl + '/iet/ClientCompanies/orgunitid/' +
      orgUnitId + '/clientid/' + clientId + '/clientcompany/' + companyId + '/update', updateCompanyJson, this.auth.getElToolsOptions());
  }

  downloadReceipt(orgUnitId: string, companyId: string, docId: string, attachment: any): any {

    console.log('download Receipt..');
    const url: string = this.auth.baseurl + '/iet/ClientCompanies/orgunitid/' +
                        orgUnitId + '/clientcompany/' + companyId + '/receipts/' +
                        docId + '/download';
    console.log(url);
    this.getPDFBlob(url).subscribe(resp => {
      const file = new Blob([<any>resp], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      console.log('set pdf:' + fileURL);
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = fileURL;
      // link.download = 'IncomeExpenseReceipt_' + docId + '.pdf';
      link.download = attachment;
      link.click();
    });
  }
  updateReceipt (orgUnitId: string, companyId: string, fiscalYear: string, accountType: string,
              newReceiptJson: any) {
    return this.http.post(this.auth.baseurl + '/iet/ClientCompanies/orgunitid/' +
      orgUnitId + '/clientcompany/' + companyId + '/year/' + fiscalYear + '/account/' + accountType +
      '/receipts/update', newReceiptJson,
      this.auth.getElToolsOptions());
  }

  addNewReceipt (orgUnitId: string, clientId: string, companyId: string, newReceiptJson: any) {
    return this.http.post(this.auth.baseurl + '/iet/ClientCompanies/orgunitid/' +
      orgUnitId + '/clientid/' + clientId + '/clientcompany/' + companyId + '/receipts/new', newReceiptJson,
      this.auth.getElToolsOptions());
  }

  addNewReceiptPDF(orgUnitId: string, clientId: string, companyId: string, amount: any,
    notes: string, uploadCustomerId: string,
    receiptDate: string, vendorName: string, accountTypeSeqNo: string, fileToUpload: File) {
    console.log('addNewReceiptPDF...')
    const formData: FormData = new FormData();
    formData.append('amount', amount);
    formData.append('notes', notes);
    formData.append('uploadCustomerId', uploadCustomerId);
    formData.append('receiptDate', receiptDate);
    formData.append('vendorName', vendorName);
    formData.append('accountTypeSeqNo', accountTypeSeqNo);
    formData.append('ff', fileToUpload, fileToUpload.name);
    console.log(formData.getAll('amount'));
    console.log(formData.getAll('notes'));
    console.log(formData.getAll('uploadCustomerId'));
    console.log(formData.getAll('receiptDate'));
    console.log(formData.getAll('vendorName'));
    console.log(formData.getAll('accountTypeSeqNo'));
    console.log(formData.getAll('ff'));
    return this.http.post(this.auth.baseurl + '/iet/ClientCompanies/orgunitid/' +
    orgUnitId + '/clientid/' + clientId + '/clientcompany/' + companyId + '/receipts/pdf/new', formData,
    this.auth.getElToolsOptionswithoutElToken());
  }

  getVendors(orgUnitId: string, searchToken: string) {
    console.log('calling getVendors server api');
    const url: string = this.auth.baseurl + '/iet/ClientCompanies/orgunitid/' +  orgUnitId + '/vendor/' + searchToken
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  getAccountTypesBySearchToken(companyTypeId: string, searchToken: string) {
    console.log('calling getAccountTypesBySearchToken server api');
    const url: string = this.auth.baseurl + '/iet/Lookups/company/' +  companyTypeId + '/accounttype/' + searchToken
    return this.http.get(url, this.auth.getElToolsOptions());
  }
  // IET related server api - end

  getPdfFormMirrorImage(docId: string, seqNo: string) {
    console.log('calling getPdfFormMirrorImage api:' + docId + ',' + seqNo);
    const url: string = this.auth.baseurl + '/Contents/docid/' +  docId + '/pageSeqNo/' + seqNo + '/formmirrorimage'
    console.log(url);
    return this.http.get(url, this.auth.getElToolsOptions());
  }
  updateSignatureBoxCoordinates(docId: string, seqNo: string, eSignFields: any) {
    console.log('calling updateSignatureBoxCoordinates api:');
    const url: string = this.auth.baseurl + '/Contents/docid/' +  docId + '/pageSeqNo/' + seqNo + '/formboxcoordinates/update'
    return this.http.put(url, eSignFields, this.auth.getElToolsOptions());
  }

  getCaseTemplatesData() {
    console.log('calling getCaseTemplatesData server api');
    const url: string = this.auth.baseurl + '/cases/casetemplates';
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  uploadCaseTemplates( fileToUpload: File | FileList) {
    console.log('uploadCaseTemplates...')
    let ff: File;
    if (fileToUpload instanceof (FileList)) {
      ff = fileToUpload.item(0);
    } else {
      ff = fileToUpload;
    }
    const formData: FormData = new FormData();
    formData.append('ff', ff, ff.name);
    console.log(formData.getAll('ff'));
    return this.http.post(this.auth.baseurl + '/Cases/casetemplates/upload', formData,
    this.auth.getElToolsOptionswithoutElToken());
  }

  downTaxPaperForms(caseId: string): any {
    const url: string = this.auth.baseurl + '/Clients' + '/case/' + caseId + '/download/paperforms';
    console.log(url);
    this.getPDFBlob(url).subscribe(resp => {
      const file = new Blob([<any>resp], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      console.log('set pdf:' + fileURL);
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = fileURL;
      link.download = caseId + '_Tax paper forms to be signed.pdf';
      link.click();
    });
  }

  getCompanyStaffBySearchToken(searchToken: string) {
    console.log('calling getCompanyStaffBySearchToken server api');
    const url: string = this.auth.baseurl + '/iet/Lookups/OrgUnitId/' + this.auth.getOrgUnitID()
    + '/staff/' + this.auth.getUserID() + '/searchtoken/' + searchToken;
    return this.http.get(url, this.auth.getElToolsOptions());
  }

  getClientCompanyInfo(companyId: any) {
    console.log('calling getClientCompanyInfo server api');
    const url: string = this.auth.baseurl + '/iet/ClientCompanies/orgunitid/' +
    this.auth.getOrgUnitID() + '/clientid/' + this.auth.getUserID() + '/clientcompany/' + companyId;
    return this.http.get(url, this.auth.getElToolsOptions());
  }
  downloadUSTaxSignedDocument(caseId: string, documentName: string) {
    console.log('downloadUSTaxSignedDocument service api call..');

    const url = this.auth.baseurl + "/cases/orgunit/" + this.auth.getOrgUnitID()
    + "/user/" + this.auth.getUserID() + "/case/" + caseId
    + "/signeddocument";

    console.log(url);
    this.getPDFBlob(url).subscribe(resp => {
      const file = new Blob([<any>resp], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      console.log('set pdf:' + fileURL);
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = fileURL;
      link.download = documentName;
      link.click();
    });
  }

  previewUSTaxDocument(caseId, docId) {
    const url = this.auth.baseurl + "/cases/orgunit/" + this.auth.getOrgUnitID()
    + "/receiver/" + this.auth.getUserID() + "/case/" + caseId
    + "/document/" + docId + "/signeddocument/preview";
    this.getPDFBlob(url).subscribe(resp => {
      const file = new Blob([<any>resp], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
     });
  }

  viewUSTaxSignedFinalDocument(caseId) {
    const url = this.auth.baseurl + "/cases/orgunit/" + this.auth.getOrgUnitID()
    + "/user/" + this.auth.getUserID() + "/case/" + caseId
    + "/signeddocument";
    this.getPDFBlob(url).subscribe(resp => {
      const file = new Blob([<any>resp], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL, '_blank');
     });
  }

  preSubmitSigningForm(jsondata:any){
    //http://localhost:55940/api/cases/orgunit/4a55653e-fcab-4736-91af-30f25ab208d3/receiver/db608b99-d878-428c-8f7a-3daad5fd596a/signedpage/presubmit
    const url= this.auth.baseurl+"/cases/orgunit/"+this.auth.getOrgUnitID()+"/receiver/"+this.auth.getUserID()
    +"/signedpage/presubmit";
    return this.http.post(url,jsondata,this.auth.getElToolsOptions());
  }
  getEsignForms(caseId) {
    const url = this.auth.baseurl +'/cases/case/' + caseId + '/ustaxforms';
    return this.http.get(url,this.auth.getElToolsOptions());
  }
  finalizeSigning(caseId,docId,signer_type)
  {
    //http://localhost:55940/api/cases/orgunit/4a55653e-fcab-4736-91af-30f25ab208d3/receiver/cf0907c8-dafd-4235-a793-5afce024b1f0/case/CS2012040391/document/DOC202012041449212207/signeddocument/finalsubmit
    const url = this.auth.baseurl + "/cases/orgunit/" + this.auth.getOrgUnitID()
    + "/receiver/" + this.auth.getUserID() + "/case/" + caseId
    + "/document/" + docId + "/signeddocument/finalsubmit";
    return this.http.post(url, {type:signer_type}, this.auth.getElToolsOptions());
  }
}

