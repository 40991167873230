import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();

  if(window){
    window.console.log = window.console.time = window.console.timeEnd = window.console.info = window.console.error = function(){
      // Don't log anything.
    };
  }
}

declare global {
  interface Window {
      styleMedia:any;
  }
}

platformBrowserDynamic().bootstrapModule(AppModule);
