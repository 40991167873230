import { Convert } from "./convert";

export class CipherBlock {
  public secret: string;
  public nonce: string;

  public encode(): string {
    var serialized = JSON.stringify(this);
    var encoder = new TextEncoder();

    return Convert.toBase64String(encoder.encode(serialized));
  }

  public static decode(source: string): CipherBlock {
    var uint8 = Convert.fromBase64String(source);
    var decoder = new TextDecoder();
    var decoded = decoder.decode(uint8);
    var json = JSON.parse(decoded);
    if (json) {
      var key = new CipherBlock();
      key.secret = json.Secret;
      key.nonce = json.Nonce;
      return key;
    } else {
      return null;
    }
  }
}
