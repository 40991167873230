import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { PlanType } from "./../../../../../core/model/subscription.model";
import { LicenseStateSelector } from "./../../../../../core/states/license.state.selector";
import { SubscriptionState } from "./../../../../../core/model/subscription.state";

@Component({
  selector: 'app-dialog-invitation-method',
  templateUrl: './dialog-invitation-method.component.html',
  styleUrls: ['./dialog-invitation-method.component.scss']
})
export class DialogInvitationMethodComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogInvitationMethodComponent>,
    private licenseSelector: LicenseStateSelector,) { }
  
    sub: SubscriptionState;

  get isFreePlan(): boolean {
    return this.sub && this.sub.type == PlanType.Free;
  }

  get isManualBilling(): boolean {
    return this.sub && this.sub.manualBilling;
  }

  ngOnInit(): void {
    this.sub = this.licenseSelector.getCurrentOrgSubscription();
  }

  inviteUser() {
    this.dismiss("single-invite");
  }

  inviteBatch() {
    if (!this.isFreePlan) {
      this.dismiss("csv-invite");
    } 
  }

  dismiss(inviteMethod: string = null) {
    this.dialogRef.close(inviteMethod);
  }

}
