import * as nacl from "tweetnacl";
import Rijndael from "rijndael-js";
import { KeyPair } from "./keyPair";
import { FcpError } from "./fcpError";
import { Convert } from "./convert";
import { CipherBlock } from "./cipherBlock";
import { MessageKey } from "./messageKey";
//var randomstring = require("randomstring");

export class FcpCipher {
  private static readonly _publicKeyLength: number = nacl.box.publicKeyLength;
  private static readonly _secretKeyLength: number = nacl.box.secretKeyLength;
  private static readonly _nonceLength: number = nacl.box.nonceLength;

  static generateKeyPair(): KeyPair {
    var kv = nacl.box.keyPair();
    if (!kv) throw new FcpError("Failed to initialize key pair");

    var p = Convert.toBase64String(kv.publicKey);
    // console.log(kv.publicKey);
    // console.log(p);
    // console.log(Convert.fromBase64String(p));

    let keyPair = new KeyPair();
    keyPair.publicKey = Convert.toBase64String(kv.publicKey);
    keyPair.secretKey = Convert.toBase64String(kv.secretKey);

    return keyPair;
  }

  static box(
    source: string,
    publicKey: string,
    secretKey: string
  ): CipherBlock {
    let secretKeyBytes: Uint8Array = Convert.fromBase64String(secretKey);
    let publicKeyBytes: Uint8Array = Convert.fromBase64String(publicKey);
    let sourceBytes: Uint8Array = Convert.fromBase64String(source);
    let nonceBytes: Uint8Array = nacl.randomBytes(FcpCipher._nonceLength);

    let boxedBytes = nacl.box(
      sourceBytes,
      nonceBytes,
      publicKeyBytes,
      secretKeyBytes
    );

    let block: CipherBlock = new CipherBlock();
    block.secret = Convert.toBase64String(boxedBytes);
    block.nonce = Convert.toBase64String(nonceBytes);

    return block;
  }

  static open(
    source: string,
    nonce: string,
    publicKey: string,
    secretKey: string
  ): string {
    try {
      let secretKeyBytes: Uint8Array = Convert.fromBase64String(secretKey);
      let publicKeyBytes: Uint8Array = Convert.fromBase64String(publicKey);
      let sourceBytes: Uint8Array = Convert.fromBase64String(source);
      let nonceBytes: Uint8Array = Convert.fromBase64String(nonce);

      let openedBytes = nacl.box.open(
        sourceBytes,
        nonceBytes,
        publicKeyBytes,
        secretKeyBytes
      );

      return Convert.toBase64String(openedBytes);
    } catch {
      return null;
    }
  }

  static encrypt(msgKey: MessageKey, source: string): string {
    var arrKey = Array.from(Convert.fromBase64String(msgKey.Key));
    var arrIV = Array.from(Convert.fromBase64String(msgKey.IV));
    var cipher = new Rijndael(arrKey, "cbc");

    var encoder = new TextEncoder();
    let uint8 = encoder.encode(source);
    let encodedStr = Convert.toBase64String(uint8);

    const arrBytes = cipher.encrypt(encodedStr, 256, arrIV);
    return Convert.toBase64String(Uint8Array.from(arrBytes));
  }

  static decrypt(msgKey: MessageKey, source: string): string {
    const decoded = Convert.fromBase64String(source);

    var arrKey = Array.from(Convert.fromBase64String(msgKey.Key));
    var arrIV = Array.from(Convert.fromBase64String(msgKey.IV));

    var cipher = new Rijndael(arrKey, "cbc");
    const arrBytes = cipher.decrypt(decoded, 256, arrIV);
    let base64Str = Convert.bytesToAscii(arrBytes);

    let uint8 = Convert.fromBase64String(base64Str);

    var decoder = new TextDecoder();
    return(decoder.decode(uint8));
  }

  static getMessageKey(): MessageKey {
    let msgKey: MessageKey = new MessageKey();
    let keyBytes = nacl.randomBytes(FcpCipher._publicKeyLength);
    let ivBytes = nacl.randomBytes(FcpCipher._publicKeyLength);
    msgKey.Key = Convert.toBase64String(keyBytes);
    msgKey.IV = Convert.toBase64String(ivBytes);

    return msgKey;
  }

  static initPayload(keyPair: KeyPair, publicKey: string) {
    var encoded = keyPair.encode();
    var boxed = FcpCipher.box(encoded, publicKey, keyPair.secretKey);

    return boxed.encode() + "." + btoa(keyPair.publicKey);
  }
}
