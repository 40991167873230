import { EnterpriseSelector } from "./../states/enterprise.state.selector";
import { Injectable } from "@angular/core";
import { OrgHub } from "../hub/org.hub";
import { MessagingSelector } from "../states/messaging.state.selector";
import { ClearUnreadDto, UnreadType } from "../model/unread.state";
import { Emittable, Emitter } from "@ngxs-labs/emitter";
import { MessagingState } from "../states/messaging.state";
import { EnterpriseState } from "../states/enterprise.state";
import { take } from "rxjs/operators";

//obsolete
@Injectable()
export class UnreadsService {
  @Emitter(MessagingState.clearMsgUnread)
  clearMsgUnread: Emittable<ClearUnreadDto>;

  @Emitter(EnterpriseState.clearEnterpriseUnread)
  clearEnterpriseUnread: Emittable<ClearUnreadDto>;

  constructor(
    private orgHub: OrgHub,
    private msgSelector: MessagingSelector,
    private enterpriseSelector: EnterpriseSelector
  ) {}

  async clearUnreads(threadId: string, type: UnreadType) {
    try {
      const hasUnreads = this.checkUnreads(threadId, type);
      console.log("clearUnreads", hasUnreads);
      if (!hasUnreads) return;

      this.clearLocalUnreads(threadId, type);

      if (!this.orgHub.connection) {
        await this.orgHub.onHubConnected$.pipe(take(1)).toPromise();
      }
      
      return this.orgHub.clearUnreads(threadId, type);
    } catch (err) {
      console.error("[unreads.service] clearUnreads, %o", err);
    }
  }

  clearLocalUnreads(threadId: string, type: UnreadType) {
    try {
      let clearUnread = new ClearUnreadDto();
      clearUnread.threadId = threadId;
      clearUnread.type = type;
      clearUnread.orgId = this.enterpriseSelector.getCurrentOrgId();

      if (clearUnread.isRoomUnread) {
        this.clearMsgUnread.emit(clearUnread);
      } else {
        this.clearEnterpriseUnread.emit(clearUnread);
      }
    } catch (err) {
      console.error("[unreads.service] clearLocalUnreads, %o", err);
    }
  }

  checkUnreads(threadId: string, type: UnreadType): boolean {
    switch (type) {
      case UnreadType.CHANNEL:
        return this.enterpriseSelector.isChUnread(threadId);
      case UnreadType.POST:
        return this.enterpriseSelector.isPostUnread(threadId);
      case UnreadType.ROOM:
        return this.msgSelector.isRoomUnread(threadId);
      case UnreadType.SHARED:
        return this.enterpriseSelector.isSharedUnread(threadId);
    }
  }
}
