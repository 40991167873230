import { LocalStorageService } from './../localStorage/local-storage.service';
import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { ContactCardDto } from '../model/contact-card.dto';
import { Contact } from '../model/contact.model';
import { NewOrgDto } from '../model/new-org.dto';

@Injectable()
export class OUService {
    constructor(private api: ApiService, private localDb: LocalStorageService, private authService: AuthService) {
    }

    getUserOU(): Promise<any> {
        return this.api.getAsync<any>('v2/ou/current', null, this.getSelectedOU())
            .then(data => {
                const res: any[] = [];
                if (data) {
                    data.forEach(element => {
                        res.push(element);
                    });
                }
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    getSelectedOU() {
        return this.localDb.getCurrentOU();
    }

    switchOU(ouId): Promise<boolean> {
        const existing = this.localDb.getCurrentOU();
        if (existing !== ouId) {
            this.localDb.setCurrentOU(ouId);
            // this.OnOuSwitched.next(ouId);

            let permission = this.localDb.getPermissions();

            if (permission === null || permission.orgUnitId !== ouId) {
                // get permission
                return this.authService.getPermission(ouId)
                    .then(res => {
                        this.localDb.setPermission(JSON.stringify(res));
                        return Promise.resolve(true);
                    })
                    .catch(res => {
                        console.log(res);
                        return Promise.reject(false);
                    });
            }
        } else {
            return Promise.reject(false);
        }
    }

    getCurrentOU(): Promise<any> {
        return this.getOU(this.localDb.getCurrentOU());
    }

    getOU(ouId: string): Promise<any> {
        return this.api.getAsync('v2/ou/' + ouId, null, this.getSelectedOU());
    }

    getContacts(ouId: string) {
        return this.api.getAsync('v2/ou/' + ouId + '/contacts', null, this.getSelectedOU())
            .then((data: Contact[]) => {
                return Promise.resolve(data);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    getUserContacts(ouId: string, userId: string) {
        return this.api.getAsync('v2/ou/' + ouId + '/user/' + userId + '/contact', null, this.getSelectedOU())
            .then(data => {
                return Promise.resolve(data);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    checkOrgId(code: string): Promise<any> {
        return this.api.postAsync<any>('v2/ou/code', JSON.stringify(code), this.getSelectedOU())
            .then(data => {
                console.log("checkOrgId " + data)
                return Promise.resolve(data);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }
    getIndustry(): Promise<any> {
        return this.api.getAsync<any>('v2/ou/industry')
            .then(data => {
                // sort
                data = data.sort((a, b) => {
                    if (a.counterPrefix < b.counterPrefix) {
                        return -1;
                    }
                    if (a.counterPrefix > b.counterPrefix) {
                        return 1;
                    }
                    return 0;
                });
                return Promise.resolve(data);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    getOUMembersTree(ouId: string): Promise<any> {
        return this.api.getAsync<any>('v2/ou/' + ouId + '/tree', null, this.getSelectedOU())
            .then(data => {
                return Promise.resolve(data);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    getOUMembers(ouId: string): Promise<any> {
        return this.api.getAsync<any>('v2/ou/' + ouId + '/members', null, this.getSelectedOU())
            .then(data => {
                console.log(data);
                return Promise.resolve(data);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }
    getOUMembersWithInvites(ouId: string): Promise<any> {
        return this.api.getAsync<any>('v2/ou/' + ouId + '/members/all', null, this.getSelectedOU())
            .then(data => {
                console.log(data);
                return Promise.resolve(data);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }
    getOUTeams(ouId: string): Promise<any> {
        return this.api.getAsync<any>('v2/ou/' + ouId + '/tree/groups', null, this.getSelectedOU())
            .then(data => {
                return Promise.resolve(data);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    uploadOrgImage(orgUnit: any, imgFile: any): Promise<any> {
        return this.api.postAsyncFile('v2/ou/' + orgUnit + '/logo', imgFile, this.getSelectedOU()).toPromise()
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    update(orgUnit: any): Promise<any> {
        return this.api.putAsync('v2/ou', orgUnit, this.getSelectedOU())
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    deleteOrgUnit(orgUnitId: any): Promise<any> {
        return this.api.deleteAsync('v2/ou/' + orgUnitId, null, this.getSelectedOU())
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    deleteChildOrgUnit(orgUnitId: any): Promise<any> {
        return this.api.deleteAsync('v2/ou/child/' + orgUnitId, null, this.getSelectedOU())
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    newOrgUnit(parentOrgId: string, ouName: string): Promise<any> {
        return this.api.postAsync('v2/ou/' + parentOrgId + '/child', JSON.stringify(ouName), this.getSelectedOU())
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    newOrganization(newOrgDto: NewOrgDto): Promise<any> {
        return this.api.postAsync<any>('v2/ou', newOrgDto, this.getSelectedOU())
            .then(data => {
                return Promise.resolve(data);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    // add contact for user
    createContactForClient(ouId: string, clientId: string, contactCard: ContactCardDto): Promise<any> {
        return this.api.postAsync('v2/ou/' + ouId + '/client/' + clientId + '/contact', contactCard, this.getSelectedOU())
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    // delete user
    deleteUser(orgUnitId: any, userId: any): Promise<any> {
        return this.api.deleteAsync('v2/ou/' + orgUnitId + '/user/' + userId, null, this.getSelectedOU())
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    // restore user
    restoreUser(orgUnitId: any, userId: any): Promise<any> {
        return this.api.postAsync('v2/ou/' + orgUnitId + '/user/' + userId + '/restore', null, this.getSelectedOU())
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }


    // suspend user API

    suspendUsers(ouId: string, userIds: string[]): Promise<any> {
        return this.api.postAsync('v2/ou/' + ouId + '/user/suspend', userIds, this.getSelectedOU())
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    unSuspendUsers(ouId: string, userIds: string[]): Promise<any> {
        return this.api.postAsync('v2/ou/' + ouId + '/user/reactivate', userIds, this.getSelectedOU())
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    // assignment ou API

    getEmployeeList(ouId: string): Promise<any> {
        return this.api.getAsync<any>('v2/ou/' + ouId + '/child/staff', null, this.getSelectedOU())
            .then(data => {
                return Promise.resolve(data);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    getClientList(ouId: string): Promise<any> {
        return this.api.getAsync<any>('v2/ou/' + ouId + '/child/client', null, this.getSelectedOU())
            .then(data => {
                return Promise.resolve(data);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    assignClientToStaff(ouId: string, staffId: string, clientIds: string[]): Promise<any> {
        return this.api.postAsync('v2/ou/' + ouId + '/staff/' + staffId + '/client', clientIds, this.getSelectedOU())
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    unassignClientFromStaff(ouId: string, staffId: string, clientIds: string[]): Promise<any> {
        return this.api.deleteAsync('v2/ou/' + ouId + '/staff/' + staffId + '/client', clientIds, this.getSelectedOU())
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    assignStaffToClient(ouId: string, clientId: string, staffIds: string[]): Promise<any> {
        return this.api.postAsync('v2/ou/' + ouId + '/client/' + clientId + '/staff', staffIds, this.getSelectedOU())
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    unassignStaffFromClient(ouId: string, clientId: string, staffIds: string[]): Promise<any> {
        return this.api.deleteAsync('v2/ou/' + ouId + '/client/' + clientId + '/staff', staffIds, this.getSelectedOU())
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    // promote, demote admin
    promoteAdmin(ouId: string, staffId: string): Promise<any> {
        return this.api.postAsync('v2/ou/' + ouId + '/admin/promote', JSON.stringify(staffId), this.getSelectedOU())
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    demoteAdmin(ouId: string, adminId: string): Promise<any> {
        return this.api.postAsync('v2/ou/' + ouId + '/admin/demote', JSON.stringify(adminId), this.getSelectedOU())
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    // move
    moveUserToOrgUnit(ouId: string, userId: string, newOuId, roleId: string): Promise<any> {
        let params: any = {
            userId: userId,
            orgUnitId: newOuId,
            roleId: roleId
        };
        return this.api.postAsync('v2/ou/' + ouId + '/user/move', params, ouId)
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    // roles
    getRoles(ouId: string): Promise<any> {
        return this.api.getAsync<any>('v2/ou/' + ouId + '/roles', null, this.getSelectedOU())
            .then(data => {
                return Promise.resolve(data);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    changeUserRole(ouId: string, userId: string, roleId: string): Promise<any> {
        return this.api.postAsync('v2/ou/' + ouId + '/user/' + userId + '/role', JSON.stringify(roleId), this.getSelectedOU())
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    // change org type
    updateOrgType(ouId: string, industryId: string): Promise<any> {
        return this.api.postAsync('v2/ou/' + ouId + '/industry', JSON.stringify(industryId), this.getSelectedOU())
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    getUserSystemLabel(ouId: string, userId: string): Promise<any> {
        return this.api.getAsync<any>('v2/ou/' + ouId + '/user/' + userId + '/systemLabel', null, this.getSelectedOU())
            .then(data => {
                console.log(data);
                data = data.filter(x => x.isSystemLabel === true);
                return Promise.resolve(data);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    setFamilyCircle(ouId: string, targetUserId: string, contactId: string, labelId: string): Promise<any> {
        let params: any = {
            userId: targetUserId,
            contactId: contactId,
            labelId: labelId
        };
        return this.api.postAsync('v2/ou/' + ouId + '/user/circle', params, this.getSelectedOU())
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    getPaymentMethod(ouId: string): Promise<any> {
        return this.api.getAsync<any>('v2/ou/' + ouId + '/paymentMethod/', null, this.getSelectedOU())
            .then(data => {
                return Promise.resolve(data);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    addPaymentMethod(ouId: string, token: string): Promise<any> {
        return this.api.postAsync('v2/ou/' + ouId + '/paymentMethod', JSON.stringify(token), this.getSelectedOU())
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    removePaymentMethod(ouId: string, token: string): Promise<any> {
        return this.api.deleteAsync('v2/ou/' + ouId + '/paymentMethod', JSON.stringify(token), this.getSelectedOU())
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    setDefaultPaymentMethod(ouId: string, cardId: string): Promise<any> {
        return this.api.postAsync('v2/ou/' + ouId + '/paymentMethod/default', JSON.stringify(cardId), this.getSelectedOU())
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    cancelSubscription(ouId: string, subId: string): Promise<any> {
        return this.api.postAsync('v2/ou/' + ouId + '/subscription/' + subId + '/cancel', null, this.getSelectedOU())
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    cancelSubscriptionSchedule(ouId: string, subId: string): Promise<any> {
        return this.api.postAsync('v2/ou/' + ouId + '/subscription/' + subId + '/schedule/cancel', null, this.getSelectedOU())
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    reactivateSubscription(ouId: string, subId: string): Promise<any> {
        return this.api.postAsync('v2/ou/' + ouId + '/subscription/' + subId + '/reactivate', null, this.getSelectedOU())
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    newSubscription(ouId: string, planId: string, intQuantity: number, extQuantity: number, paymentToken: string): Promise<any> {
        let params: any = {
            planId: planId,
            internalQuantity: intQuantity,
            externalQuantity: extQuantity,
            paymentToken: paymentToken
        };
        return this.api.postAsync('v2/ou/' + ouId + '/subscription/new', params, this.getSelectedOU())
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    changePlan(ouId: string, planId: string, intQuantity: number, extQuantity: number, paymentToken: string): Promise<any> {
        let params: any = {
            planId: planId,
            internalQuantity: intQuantity,
            externalQuantity: extQuantity,
            paymentToken: paymentToken
        };
        return this.api.postAsync('v2/ou/' + ouId + '/subscription/plan', params, this.getSelectedOU())
            .then(res => {
                return Promise.resolve(res);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    isExistingOrgUser(orgId: string, email: string) {
        return this.api.postAsync(
            "v2/ou/" + orgId + "/user/exist",
            JSON.stringify(email)
        );
    }

    // public _getMembersQuery(orgUnitId: string) {
    //     return this.api.ApiUrl + '/ou/' + orgUnitId + '/members';
    // }
}
