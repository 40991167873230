import { Injectable } from "@angular/core";
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { PhoneNumberUtil } from "google-libphonenumber";

@Injectable({
  providedIn: "root"
})
export class ValidatorService {
  PWD_FORMAT: any;
  constructor() { }

  validatePwdFormat(password: string, min: number = 8, max?: number): any {
    // Password structure with at least 1 uppercase, 1 lowercase, 1 number, 1 special character, and no space
    // default for minimum length is 8, can be override

    // regex pattern
    // below is string literal
    // can also using regex literal, but cannot deals with variable
    // ==> /(?=^.{8,}$)(?=.*\W+)(?!.*(\s))(?=.*\d)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g
    let pattern: any =
      "(?=^.{" +
      min +
      "," +
      (max == undefined || max <= 0 ? "" : max) +
      "}$)" + // minimum character to maximum character
      //+ '(?=.*\\W+)'  // at least a special characters
      "(?!.*(\\s))" + // Not a space
      "(?=.*\\d)" + // at least a number
      "(?![.\\n])" + // not a carriage return
      "(?=.*[A-Z])" + // at least a capital letter
      "(?=.*[a-z])" + // at least a lowecase letter
      ".*$"; // not a multiline and must slot="end" as above condition
    let flag: string = "g"; // g => global flag, i => ignore case, m => multiline

    //return this.regexTest(password, pattern, flag);
    let result: boolean = this._regexTest(password, pattern, flag);
    return {
      success: result,
      error: result
        ? undefined
        : "Password is too weak or contains a space"
    };
  }

  private _validateEmailFormat(email: string): any {
    // Top level domain max length can be 63
    let pattern: any =
      "^[a-zA-Z0-9][a-zA-Z0-9_\\.]+@[a-zA-Z_\\-0-9]+?\\.[a-zA-Z]{2,}(\\.[a-zA-Z]{2,})?$";
    let flag: string = "g";

    let result: boolean = this._regexTest(email, pattern, flag);
    return {
      success: result,
      error: result
        ? undefined
        : "Invalid email: " + email
    };
  }

  private _validateOrgWebFormat(orgWeb: string): any {
    let pattern: any =
      "^((https?|ftp)://)?([a-z]+[.])?[a-z0-9-]+([.][a-z]{1,4}){1,2}(/.*[?].*)?$";
    let flag: string = "g";

    let result: boolean = this._regexTest(orgWeb, pattern, flag);
    return {
      success: result,
      error: result
        ? undefined
        : "Invalid web: " + orgWeb
    };
  }

  private _validateOrgPhoneFormat(orgPhone: string) {
    let pattern: any =
    "^(?:[+]?[0-9]{0,3})[ -]?[0-9]{0,3}[0-9]{3}[- .]?[ ]?[0-9]{3}[- .]?[ ]?[0-9]{4,6}$";
    //'^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$';
    let flag: string = "g";

    let result: boolean = this._regexTest(orgPhone, pattern, flag);
    return {
      success: result,
      error: result
        ? undefined
        : "Invalid phone: " + orgPhone
    };
  }

  private _validateOrgFaxFormat(orgFax: string) {
    let pattern: any =
    "^(?:[+]?[0-9]{0,3})[ -]?[0-9]{0,3}[0-9]{3}[- .]?[ ]?[0-9]{3}[- .]?[ ]?[0-9]{4,6}$";
    //'^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$';
    let flag: string = "g";

    let result: boolean = this._regexTest(orgFax, pattern, flag);
    return {
      success: result,
      error: result
        ? undefined
        : "Invalid fax: " + orgFax
    };
  }

  private _validatePhoneFormat(phone: string) {
    let pattern: any =
      "^[+]?[0-9]{0,1}[ ]?[0-9]{0,3}[(]?[0-9]{3}[)]?[-s.]?[ ]?[0-9]{3}[-s.]?[ ]?[0-9]{4,6}$";
    //'^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$';
    let flag: string = "g";

    let result: boolean = this._regexTest(phone, pattern, flag);
    return {
      success: result,
      error: result
        ? undefined
        : "Invalid phone: " + phone
    };
  }

  private _regexTest(str: string, pattern: any, flag: string = ""): boolean {
    let regex: any = new RegExp(pattern, flag);
    return regex.test(str);
  }

  // column needed need not be undefined. So, initially we need to initialize the data to be empty instead of undefined
  validatePwd(password: string, confirmPassword?: string) {
    // password
    if (!password || password.length < 8) {
      return {
        success: false,
        error: "Please enter a minimum of 8 characters for your password"
      };
    }

    let result: any = this.validatePwdFormat(password);

    if (!result.success) {
      return result;
    }

    if (confirmPassword != undefined && password != confirmPassword) {
      // password matching
      return {
        success: false,
        error: "Password does not match"
      };
    }

    return { success: true };
  }

  validateOrgWeb(orgWeb: string) {
    if (orgWeb != undefined) {
      let result: any = this._validateOrgWebFormat(orgWeb);

      if (!result.success) return result;

      return { success: true };
    }
  }

  validateOrgPhone(orgPhone: string) {
    if (orgPhone != undefined) {
      let result: any = this._validateOrgPhoneFormat(orgPhone);

      if (!result.success) return result;

      // try {
      //   const phoneUtil = PhoneNumberUtil.getInstance();
      //   phoneUtil.parse(orgPhone, ""); //parse to PhoneNumber format
      // } catch {
      //   return { success: false, error: "Please enter country code!" };
      // }

      return { success: true };
    }
  }

  validateOrgFax(orgFax: string) {
    if (orgFax != undefined) {
      let result: any = this._validateOrgFaxFormat(orgFax);

      if (!result.success) return result;

      // try {
      //   const phoneUtil = PhoneNumberUtil.getInstance();
      //   phoneUtil.parse(orgFax, ""); //parse to PhoneNumber format
      // } catch {
      //   return { success: false, error: "Please enter country code!" };
      // }

      return { success: true };
    }
  }

  validatePhone(phone: string) {
    if (phone != undefined) {
      let result: any = this._validatePhoneFormat(phone);

      if (!result.success) return result;

      try {
        const phoneUtil = PhoneNumberUtil.getInstance();
        phoneUtil.parse(phone, ""); //parse to PhoneNumber format
      } catch {
        return { success: false, error: "Please enter country code!" };
      }

      return { success: true };
    }
  }
  validateEmail(email: string) {
    if (email != undefined) {
      let result: any = this._validateEmailFormat(email);

      if (!result.success) return result;

      return { success: true };
    }
    return { success: false };
  }

  validateName(firstName: string, lastName: string): any {
    if (firstName.trim() == "" || lastName.trim() == "") {
      return {
        success: false,
        error: "Please enter personal details"
      };
    } else {
      return { success: true };
    }
  }

  validatePasscode(passcode: string): any {
    if (passcode.trim() == "") {
      return {
        success: false,
        error: "Please enter passcode"
      };
    } else {
      return { success: true };
    }
  }
}

export function exisitngNameValidator(existingNames: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.dirty) return null;
    if (control.value == null) return { required: true };
    let string = control.value;
    if (typeof control.value === "string") {
      string = (control.value as string).trim();
      if (string == null || string == "") return { required: true };
    }

    const forbidden = !!existingNames.find((n) => n && n.trim() == string);
    return forbidden ? { nameExists: true } : null;
  };
}