import { SubSink } from "subsink";
import { Observable } from "rxjs";
import { Select } from "@ngxs/store";
import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { pocolog } from "pocolog";
import { EnterpriseState } from '../states/enterprise.state';
import { OrgPermissionState } from '../model/org.state';
import { UserDataSelector } from '../states/user-data.state.selector';

@Injectable()
export class PermissionService implements OnDestroy {
    private _allPermissions: { [id: string]: boolean };
    permissionListAvailable: Subject<boolean>;
    private _sub: SubSink;

    @Select(EnterpriseState.currentPermissions)
    currentOrgPermissions: Observable<OrgPermissionState>;

    constructor(
        public http: HttpClient,
        private userDataSelector: UserDataSelector
    ) {
        pocolog.trace("Hello PermissionService Service");
        this.permissionListAvailable = new Subject<boolean>();
        this._sub = new SubSink();

        this._sub.sink = this.currentOrgPermissions.subscribe(res => {
            this._allPermissions = {};
            if (res && res.permissions) {
                this._allPermissions = res.permissions;
                this.permissionListAvailable.next(true);
            }
        });
    }

    ngOnDestroy() {
        this._sub.unsubscribe();
    }

    private checkOrgPermission(permissionType: string): boolean {
        if (permissionType == null) return true;
        if (this._allPermissions) {
            //get list of permission types
            var permissionTypes = Object.keys(this._allPermissions);

            //check if input permission type exists
            const existing = permissionTypes.find(
                t => t.toLowerCase() == permissionType.toLowerCase()
            );
            if (existing) {
                return this._allPermissions[existing];
            }
            return true;
        }
        //default = true
        return true;
    }

    private isResourceOwner(resourceOwnerId: string): boolean {
        if (resourceOwnerId) {
            //check if current user is resource owner
            return this.userDataSelector.isCurrentUserId(resourceOwnerId);
        }

        return false;
    }

    hasPermission(resourceOwnerId: string, permissionType: string): boolean {
        if (!this.isResourceOwner(resourceOwnerId)) {
            var hasPermission = this.checkOrgPermission(permissionType);
            // console.log("Permission: " + this.localDb.CurrentOU.id + " " + permissionType + " " + hasPermission);
            return hasPermission;
        } else {
            return true;
        }
    }

    hasPermissionInList(resourceOwnerId: string, permissionList: string[]): boolean {
        if (!this.isResourceOwner(resourceOwnerId)) {
            for (let permission of permissionList) {
                let hasPermission = this.checkOrgPermission(permission);
                if (hasPermission) {
                    return true;
                }
            }
        } else {
            return true;
        }
        return false;
    }

    isOrgPermissionListAvailable() {
        return (
            this._allPermissions && Object.keys(this._allPermissions).length != 0
        );
    }
}
