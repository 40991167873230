/** Data structure for holding ouName name and ouId. */
export class SelectOU {
    ouId: string;
    ouName: string;

    constructor(ouId?: string, ouName?: string) {
        this.ouId = ouId;
        this.ouName = ouName;
    }
}
