import { Flow } from '.';

export class EndPointFlow extends Flow {
    public async execute(input: any = null): Promise<any> {
        // console.log("[EndPointFlow] input: %o", input);
        // console.log("[EndPointFlow] hit end of the flow");

        //just simply return the input as output
        this.output = input;
        // console.log("[EndPointFlow] returning output: %o", this.output);

        return await super.execute(this.output)
    }
}

export class FirstInputFlow extends Flow {
  public async execute(input: any = null): Promise<any> {
    this.output = input[0];

    return await super.execute(this.output);
  }
}

// export class BasicFlow extends Flow {
//   private _callback: (input: any) => any;
//   constructor(callback: (input: any) => any) {
//     super();
//     this._callback = callback;
//   }
//   public async execute(input: any = null): Promise<any> {
//     console.log("[BasicFlow] input: %o", input);
//     this._callback(input);

//     this.output = input;
//     console.log("[BasicFlow] returning output: %o", this.output);

//     return await super.execute(this.output);
//   }
// }
