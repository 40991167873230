import { Contact } from './../model/contact.model';
import { environment } from '../../../environments/environment';

export class Participant {

    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    matrixId: string;
    imageUrl: string;
    isLeaved: boolean;
    status: ParticipantStatus;
    roomMatrixId: string;
    orgUnitId: string;

    storageLink = environment.publicStorageLink;
    storageUserFolder = 'avatars/users/';

    constructor(matrixId: string) {
        this.matrixId = matrixId;
        this.isLeaved = false;
        this.status = ParticipantStatus.Joined;
    }

    static parseList(data: any[]) {
        let result: Participant[] = [];
        if (data) {
            data.forEach(d => {
                let participant = this.parse(d);

                result.push(participant);
            });
        }
        return result;
    }

    static parse(d: any) {
        let participant = new Participant(d.matrixId);
        if (d.firstName) {
            participant.firstName = d.firstName;
        } else {
            participant.firstName = d.matrixId ? d.matrixId.split(".")[1].substring(0, 5) : d.userId.split(".")[1].substring(0, 5);
        }

        if (d.lastName) {
            participant.lastName = d.lastName;
        } else {
            participant.lastName = "";
        }
        participant.userId = d.userId;
        participant.email = d.email;
        participant.matrixId = d.matrixId;
        participant.imageUrl = d.imageUrl;
        participant.status = d.status;
        participant.isLeaved = participant.status === 2 ? true : false;
        participant.roomMatrixId = d.roomMatrixId;
        participant.orgUnitId = d.orgUnitId;

        return participant;
    }

    getDisplayName(_contacts?: Contact[]) {
        // if (_contacts) {
        //     let contact = _contacts.find(c => c.matrixId == this.matrixId);
        //     if (contact) {
        //         return contact.firstName + " " + contact.lastName;
        //     }
        // }
        return this.firstName + " " + this.lastName;
    }

    getFirstName(_contacts?: Contact[]) {
        // if (_contacts) {
        //     let contact = _contacts.find(c => c.matrixId == this.matrixId);
        //     if (contact) {
        //         return contact.firstName;
        //     }
        // }
        return this.firstName;
    }

    getAvatar(_contacts?: Contact[]) {
        // if (_contacts) {
        //     let contact = _contacts.find(c => c.matrixId == this.matrixId);
        //     if (contact) {
        //         return contact.imageUrl ? contact.imageUrl : 'assets/img/avatar_default.png';
        //     }
        // }
        if (this.imageUrl) {
            return this.imageUrl.includes(this.storageLink) ?
                this.imageUrl : this.storageLink + this.storageUserFolder + this.imageUrl;
        } else {
            return null;
        }
    }
}

export enum ParticipantStatus {
    Joined = 1,
    Leaved = 2,
    Pending = 3
}
