//import { Dictionary } from "../util/dictionary";


// export class UnreadState {
//     //orgId: string;
//     chUnreads: Dictionary<string[]>;
//     postUnreads: Dictionary<string[]>;
//     msgUnreads: Dictionary<string[]>;

//     constructor() {
//         //this.orgId = orgId;
//         this.chUnreads = {};
//         this.postUnreads = {};
//         this.msgUnreads = {};
//     }
// }

export class UnreadDto {
    threadId: string;
    type: string;
    unreadIds: string[];

    constructor() {
        this.unreadIds = [];
    }

    get isPostUnread(): boolean {
        return this.type == UnreadType.POST;
    }

    get isChUnread(): boolean {
        return this.type == UnreadType.CHANNEL;
    }

    get isRoomUnread(): boolean {
        return this.type == UnreadType.ROOM;
    }

    get isSharedUnread(): boolean {
        return this.type == UnreadType.SHARED;
    }
}

export class ClearUnreadDto {
    orgId: string;
    threadId: string;
    type: string;

    get isPostUnread(): boolean {
        return this.type == UnreadType.POST;
    }

    get isChUnread(): boolean {
        return this.type == UnreadType.CHANNEL;
    }

    get isRoomUnread(): boolean {
        return this.type == UnreadType.ROOM;
    }

    get isSharedUnread(): boolean {
        return this.type == UnreadType.SHARED;
    }
}

export enum UnreadType {
    POST = "unread-comment",
    CHANNEL = "unread-post-comment",
    ROOM = "unread-msg",
    SHARED = "unread-share"
}