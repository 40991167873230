import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { routerAnimation } from '../../utils/page.animation';
import { AuthService } from '../../core/auth/auth.service';
import { UserService } from '../../core/services/user.service';
import { TurnstileComponent } from '../../core/auth/turnstile/turnstile.component';

@Component({
  selector: 'app-forgot-password-page',
  templateUrl: './forgot-password-page.component.html',
  styleUrls: ['./forgot-password-page.component.scss'],
  animations: [routerAnimation]
})
export class ForgotPasswordPageComponent implements OnInit {
  // Add router animation
  @HostBinding('@routerAnimation') routerAnimation = true;
  @ViewChild('elturnstile') turnstileComponent: TurnstileComponent;
  reqEmail: string;
  showLoading: boolean;
  showError: boolean;
  errorMsg: string;
  showSuccess: boolean;
  successMsg: string;
  constructor(private router: Router, private auth: AuthService, private userService: UserService) { }

  ngOnInit() {

  }

  async submit(event) {
    this.showLoading = true;
    this.showError = false;
    this.errorMsg = '';
    this.showSuccess = false;
    this.successMsg = '';
    var re = new RegExp("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$");

    if (!re.test(this.reqEmail)) {
      this.showLoading = false;
      this.showError = true;
      this.errorMsg = this.reqEmail + " is an invalid email address";
      return;
    }

    event.preventDefault();
    await this.reqResetPassword();

    // this.router.navigate(['/dashboard/+analytics'])
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.submit(event);
    }
  }

  backLogin() {
    this.router.navigateByUrl("/login");
  }

  async reqResetPassword() {
    var token = await this.turnstileComponent.getToken();
    this.userService.reqResetPassword(this.reqEmail, token).then(res => {
      this.showLoading = false;
      if (res.success) {
        this.showSuccess = true;
        this.successMsg = 'Password reset has submitted to the email ' + this.reqEmail + '. Please check your email for further instructions'
      } else {
        this.showError = true;
        this.errorMsg = res.error;
      }
    })
      .catch(error => {
        console.log(error);
        this.showLoading = false;
        this.showError = true;
        this.errorMsg = 'An error has occured.'
      });
  }
}
