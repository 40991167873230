import { LicenseState } from './license.state';
import { Injectable } from "@angular/core";
import { Emitter, Emittable } from "@ngxs-labs/emitter";
import { UserDataState } from "./user-data.state";
import { UIState } from "./ui.state";
import { MessagingState } from "./messaging.state";
import { HubState } from "./hub.state";
import { FileState } from "./file.state";
import { EnterpriseState } from "./enterprise.state";
import { AppState } from "./app.state";
import { AuthState } from "./auth.state";
import { Store } from "@ngxs/store";
import { DecryptedMsgState } from './decrypted-msg.state';
import { InMemMessageState } from './inmem.message.state';
import { InMemPostState } from './inmem.post.state';
import { InMemFeedState } from './inmem.feed.state';
import { FeedState } from './feed.state';

@Injectable({
  providedIn: "root"
})
export class GCStateHelper {
  constructor(private store: Store) { }

  @Emitter(UserDataState.clean)
  private _cleanUserDataState: Emittable<void>;
  @Emitter(UIState.clean)
  private _cleanUIState: Emittable<void>;
  @Emitter(UIState.clearReadyChannels)
  private _clearReadyChannels: Emittable<void>;
  @Emitter(MessagingState.clean)
  private _cleanMessagingState: Emittable<void>;
  @Emitter(HubState.clean)
  private _cleanHubState: Emittable<void>;
  @Emitter(FileState.clean)
  private _cleanFileState: Emittable<void>;
  @Emitter(EnterpriseState.clean)
  private _cleanEnterpriseState: Emittable<void>;
  @Emitter(DecryptedMsgState.clean)
  private _cleanDecrypedMsg: Emittable<void>;
  @Emitter(AuthState.clean)
  private _cleanAuthState: Emittable<void>;
  @Emitter(InMemMessageState.clean)
  private _cleanInMemMsgState: Emittable<void>;
  @Emitter(EnterpriseState.cleanPartial)
  private _cleanPartialEnterpriseState: Emittable<void>
  @Emitter(MessagingState.cleanPartial)
  private _cleanPartialMessagingState: Emittable<void>;
  @Emitter(InMemPostState.clean)
  private _cleanInMemPostState: Emittable<void>;
  @Emitter(LicenseState.clean)
  private _cleanLicenseState: Emittable<void>;
  @Emitter(FeedState.clean)
  private _cleanFeedState: Emittable<void>;
  @Emitter(InMemFeedState.clean)
  private _cleanInMemFeedState: Emittable<void>;

  async cleanUp() {
    await this._cleanUserDataState.emit(null).toPromise();
    await this._cleanUIState.emit(null).toPromise();
    await this._cleanMessagingState.emit(null).toPromise();
    await this._cleanInMemMsgState.emit(null).toPromise();
    await this._cleanHubState.emit(null).toPromise();
    await this._cleanFileState.emit(null).toPromise();
    await this._cleanEnterpriseState.emit(null).toPromise();
    await this._cleanLicenseState.emit(null).toPromise();
    await this._cleanInMemPostState.emit(null).toPromise();
    await this._cleanDecrypedMsg.emit(null).toPromise();
    await this._cleanAuthState.emit(null).toPromise();
    await this._cleanFeedState.emit(null).toPromise();
    await this._cleanInMemFeedState.emit(null).toPromise();
  }

  async switchOrgCleanUp(): Promise<void> {
    
    await this._cleanInMemPostState.emit(null).toPromise();
    await this._cleanInMemMsgState.emit(null).toPromise();
    await this._cleanPartialMessagingState.emit(null).toPromise();
    await this._cleanPartialEnterpriseState.emit(null).toPromise();
    await this._cleanDecrypedMsg.emit(null).toPromise();
    await this._cleanFileState.emit(null).toPromise();
    await this._clearReadyChannels.emit(null).toPromise();
    await this._cleanFeedState.emit(null).toPromise();
    await this._cleanInMemFeedState.emit(null).toPromise();
  }
}
