import { firstValueFrom } from 'rxjs';
import { SystemHub } from "../hub/system.hub";
import { CancellationToken, Flow } from ".";
import { HubConnectionStatus } from "../model/hubConnection.state";

export class SystemHandshakeFlow extends Flow {
  constructor(private systemHub: SystemHub) {
    super();
  }

  public async execute(
    input: any = null,
    token: CancellationToken = null
  ): Promise<any> {
    if (!this.systemHub.isConnected) {
      var sysHubStarted = await new StartSystemHubFlow(this.systemHub).execute(
        null,
        token
      );
      if (sysHubStarted == false) {
        this.systemHub.failedOverHandling();
        return;
      }
    }
    this.systemHub.setNotReady();
    
    // set user's lastLoggedIn
    this.systemHub.updateLastLoggedIn().catch(()=>{});

    var res = await this.systemHub.handshakeMethod();
    this.output = this.systemHub.updateHandshakeData(res);

    var result = await super.execute(this.output, token);

    this.systemHub.setReady();

    return result;
  }
}

export class StartSystemHubFlow extends Flow {
  constructor(private systemHub: SystemHub) {
    super();
  }

  public async execute(
    input: any = null,
    token: CancellationToken = null
  ): Promise<boolean> {
    if (this.systemHub.hubSnapshot.status !== HubConnectionStatus.Connected) {
      if (
        this.systemHub.hubSnapshot.status === HubConnectionStatus.Connecting
      ) {
        this.output = await firstValueFrom(this.systemHub.onHubConnected$);
      } else {
        this.output = await this.systemHub
          .start()
          .toPromise()
          .catch((err) => {
            console.error("[StartSystemHubFlow] %o", err);
            return false;
          });
      }
    } else {
      this.output = true;
    }
    // console.log("[StartSystemHubFlow] produced result: %o", this.output);

    return await super.execute(this.output, token);
  }
}
