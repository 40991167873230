import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { pocolog } from 'pocolog';
import { Observable, timer, Subscription } from 'rxjs';
import { UIService } from '../services/ui.service';
import { Select } from '@ngxs/store';
import { AuthState } from '../states/auth.state';
import { AuthDataState } from '../model/authData.state';
import { AuthStateSelector } from '../states/auth.state.selector';
import { AppState } from '../states/app.state';
import { PermissionService } from '../services/permission.service';
import { UserDataSelector } from '../states/user-data.state.selector';
import { EnterpriseState } from '../states/enterprise.state';
import { OrgPermissionState } from '../model/org.state';
import { OrgType } from '../enum/org-type';
import { EnterpriseSelector } from '../states/enterprise.state.selector';
import { RoleEntity } from '../model/userRole.model';
import { PubSub } from '../services/pubsub.service';
import * as _ from "lodash";
import { PluginManager } from '../../tools/plugin.manager';

@Injectable()
export class AuthGuard implements CanActivate {

    @Select(AuthState.data)
    onAuthDataChanged$: Observable<AuthDataState>;

    @Select(EnterpriseState.currentPermissions)
    currentOrgPermissions$: Observable<OrgPermissionState>;
    timedOut = false;
    RETRY_LIMIT = 1000;
    retryAttempt = 0;
    RETRY_TIMER = 10;

    private _pubSubSink: Subscription;
    private _toolMenu: string[] = [];

    constructor(private router: Router, private auth: AuthService, private idle: Idle,
        private authStateSelector: AuthStateSelector, private appState: AppState,
        private permissionService: PermissionService, private enterpriseSelector: EnterpriseSelector,
        private pubSub: PubSub, private pluginManager: PluginManager) {
        //console.log("[AuthGuard] init");

        this.subscribeExternalToolMenu();
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        //console.log("[AuthGuard] canActivate: %o", route);
        return this.appState.ready()
            .then(() => {
                //console.log("[AuthGuard] canActivate");
                const isVerified = this.authStateSelector.isVerified;
                const isAuthenticated = this.authStateSelector.isAuthenticated;
                // logged in
                //console.log('AuthGuard: isVerified: %s', isVerified);
                if (isVerified) {
                    // email verified
                    //console.log('AuthGuard: isAuthenticated: %s', isAuthenticated);
                    if (isAuthenticated) {
                        //console.log("[AuthGuard] canActivate: true");
                        return Promise.resolve(true);
                    }
                }
                // not logged in so redirect to login page with the return url
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                //console.log("[AuthGuard] canActivate: false");
                return Promise.resolve(false);
            })
            .catch(err => {
                return Promise.reject(err);
            });
    }

    async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        //console.log("[AuthGuard] canActivateChild: %o", route);
        // check permissions
        let dataPermission = route.data["permission"];
        let plugin_app_id = route.data["plugin"];
        //console.log("[AuthGuard] dataPermission: %o", { dataPermission, plugin_app_id });

        if (dataPermission != null) {
            // read orgType
            let orgType: OrgType = dataPermission.orgType;
            if (orgType != null) {
                //console.log("[AuthGuard] orgType permission check");
                // current orgType must match given orgType
                let currentOrgType = this.enterpriseSelector.getCurrentOrgType();
                if (currentOrgType !== orgType) {
                    // return to dashboard
                    this.router.navigate(['/']);
                    //console.log("[AuthGuard] canActivateChild: false");
                    return Promise.resolve(false);
                }
            }

            // read el permissions
            let permissions = dataPermission.elPermissions;
            if (permissions && permissions.length > 0) {
                //console.log("[AuthGuard] elpermission check");
                this.checkPermissions(permissions).then(res => {
                    if (!res) {
                        // return to dashboard
                        this.router.navigate(['/']);
                        //console.log("[AuthGuard] canActivateChild: false");
                        return Promise.resolve(false);
                    }
                }).catch((err) => {
                    return Promise.reject(err);
                })
            }

            // read roles
            let roles = dataPermission.roles;
            if (roles && roles.length > 1) {
                //console.log("[AuthGuard] roles permission check");
                let currentOrgUser = this.enterpriseSelector.getCurrentOrgUser();
                let currentRole = RoleEntity[currentOrgUser.role];
                let isRole = false;
                roles.forEach(role => {
                    if (role === currentRole) {
                        isRole = true;
                    }
                });
                if (!isRole) {
                    this.router.navigate(['/']);
                    //console.log("[AuthGuard] canActivateChild: false");
                    return Promise.resolve(false);
                }
            }
        }

        // read external tool permissions
        if (plugin_app_id) {
            console.log("[AuthGuard] plugin permission check");

            if (!this._toolMenu || this._toolMenu.length == 0) {
                this.router.navigate(['/']);
                console.log("[AuthGuard] canActivateChild: no tool menu");
                return Promise.resolve(false);
            }

            if (!this._toolMenu.some((i) => i === plugin_app_id)) {
                this.router.navigate(['/']);
                console.log("[AuthGuard] canActivateChild: %s not found", plugin_app_id);
                return Promise.resolve(false);
            }
        }
        //console.log("[AuthGuard] canActivateChild: true");
        return Promise.resolve(true);
    }

    checkPermissions(permissions: string[]): Promise<boolean> {
        if (this.permissionService.isOrgPermissionListAvailable()) {
            if (permissions && permissions.length > 0) {
                let hasPermission = this.permissionService.hasPermissionInList(null, permissions);
                return Promise.resolve(hasPermission);
            } else {
                return Promise.resolve(true);
            }
        } else {
            console.error("[AuthGuard] org permission not ready")
            return Promise.resolve(true);
        }
    }



    clearToolMenu() {
        //console.log("[AuthGuard] clearToolMenu");
        this._toolMenu = [];
    }

    private subscribeExternalToolMenu() {
        //console.log("[AuthGuard] subscribe pubsub");
        if (this._pubSubSink) this._pubSubSink.unsubscribe();

        this._pubSubSink = this.pluginManager.onMenuUpdated$
            .subscribe(menus => {
                if (!menus) return;

                if (!this._toolMenu) this._toolMenu = [];
                menus.forEach(menu => {
                    if (menu.isEnabled) {
                        this._toolMenu = _.uniq([...this._toolMenu, menu.code]);
                    }
                });
            });

        // this._pubSubSink = this.pubSub.subscribe<string[]>(
        //     PubSub.MENU_ENABLED,
        //     (val) => {
        //         console.log("[AuthGuard] MENU_ENABLED - received: %o", val);
        //         if (!val) return;
        //         if (!this._toolMenu) this._toolMenu = [];
        //         this._toolMenu = _.uniq([...this._toolMenu, ...val]);
        //     },
        //     (err) => console.error(err)
        // );
    }
}
