import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UIStateSelector } from '../states/ui.state.selector';
import { UIStatus } from '../states/ui.state';
import { AppState } from '../states/app.state';

@Injectable()
export class RouteBlockGuard implements CanActivate {
    constructor(private appState: AppState, private uiStateSelector: UIStateSelector) {
    }

    // block all route to prevent page changes
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.appState.ready()
            .then(() => {
                if (this.uiStateSelector.getStatus() === UIStatus.AwaitingResponse) {
                    console.log("[RouteBlockGuard] UI is awaiting for a response. Do not route to new page")
                    return Promise.resolve(false);
                } else {
                    return Promise.resolve(true);
                }
            })
            .catch(err => {
                return Promise.reject(err);
            });
    }
}
