import { Component, Input, ElementRef, AfterViewInit, OnInit, OnChanges, AfterViewChecked, SimpleChanges, SecurityContext } from '@angular/core';

@Component({
    selector: 'read-more',
    templateUrl: 'read-more.component.html',
    styleUrls: ['./read-more.component.scss']
})
export class ReadMoreComponent implements AfterViewInit, OnChanges {

    // the text that need to be put in the container
    @Input() set text(text: string){
        this.initText(text);
    };

    postText: string;
    
    // maximum height of the container
    @Input() maxHeight = 100;

    //  set these to false to get the height of the expended container 
    public isCollapsed = false;
    public isCollapsable = false;

    hasInit = false;

    constructor(private elementRef: ElementRef) {

    }

    initText(text: string){
        var decodedSting =  this.decodeHtml(text)
        this.postText = decodedSting;
    }
    ngAfterViewInit() {
        //     this.checkCollapseState();
    }


    ngOnChanges(changes: SimpleChanges): void {
        // console.log('changes')
        // console.log(changes);
        if (changes.text) {
            this.checkCollapseState();
        }
    }

    checkCollapseState() {

          setTimeout(() => {
            let currentHeight = this.elementRef.nativeElement.getElementsByTagName('div')[0].offsetHeight;
            // console.log(currentHeight);
            // collapsable only if the contents make container exceed the max height
            if (currentHeight > this.maxHeight) {
                this.isCollapsed = true;
                this.isCollapsable = true;
            }
            this.hasInit = true;
        });
    }

    decodeHtml(html: string) {
        var txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }
}
