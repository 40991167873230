import { FcpService } from "../fcp/fcp.service";
import { CancellationToken, Flow } from ".";

export class FcpSetupFlow extends Flow {
  constructor(private fcpService: FcpService) {
    super();
  }
  public async execute(
    input: any = null,
    token: CancellationToken = null
  ): Promise<any> {
    // console.log("[FcpSetupFlow] input: %o", input);
    // console.log("[FcpSetupFlow] processing...");
    this.output = await this.fcpService.getKeyPair();

    // console.log("[FcpSetupFlow] completed, produced result: %o", this.output);

    return await super.execute(this.output, token);
  }
}
