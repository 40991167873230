import { MembershipReqService } from "./../../../core/services/membership-req.service";
import {
  Component,
  OnInit,
  OnChanges,
  Input,
  ViewChild,
  SimpleChanges,
} from "@angular/core";
import {
  OrgUserState,
  OUState,
  MembershipReqState,
  MembershipReqStatus,
} from "../../../core/model/org.state";
import {
  ITdDataTableColumn,
  TdDataTableSortingOrder,
  TdDataTableService,
  ITdDataTableSortChangeEvent,
} from "@covalent/core/data-table";
import { TdPagingBarComponent, IPageChangeEvent } from '@covalent/core/paging';
import { MatDialog } from "@angular/material/dialog";
import { MatDrawer } from "@angular/material/sidenav";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { DialogDeleteInviteComponent } from "../user-table/dialog/dialog-delete-invite.component";
import { TdSearchBoxComponent } from "@covalent/core/search";
import { UntypedFormControl } from "@angular/forms";

@Component({
  selector: "app-invited-user-table",
  templateUrl: "./invited-user-table.component.html",
  styleUrls: ["./invited-user-table.component.scss"],
})
export class InvitedUserTableComponent implements OnInit, OnChanges {
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  @Input() reqs: MembershipReqState[] = [];
  @Input() currentUser: OrgUserState;
  @Input() ous: OUState[] = [];
  @ViewChild('searchBox') searchBoxsBar: TdSearchBoxComponent;

  // Table columns model
  columns: ITdDataTableColumn[] = [
    {
      name: "name",
      label: "Name",
      sortable: true,
      width: { min: 150, max: 200 },
    },
    {
      name: "email",
      label: "Email",
      sortable: true,
      width: { min: 250, max: 400 },
    },
    { name: "role", label: "Role", sortable: true, width: { min: 125, max: 175 } },
    { name: "statusName", label: "Status", sortable: true, width: { min: 100, max: 125 } },
    {
      name: "ouPath",
      label: "OU",
      sortable: true,
      width: { min: 100, max: 125 },
    },
    { name: "options", label: "", width: 80, sortable: false },
  ];
  // Table data
  public data: InvitedUserTableData[] = [];
  // Table parameters
  filteredData: any[] = this.data;
  filteredTotal: number = this.data.length;
  searchTerm = "";
  fromRow = 1;
  currentPage = 1;
  pageSize = 10;
  sortBy = "name";
  sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;
  selectedRows: any[] = [];
  selectable: any;
  multiple: any;
  orgFilter: string; // org filter
  showDeleted: boolean = false; // show deleted
  showAccepted: boolean = false; // show deleted
  maxpage: number;

  // loaders
  tableLoading = false;

  // #region getters
  get currentUserId(): string {
    return this.currentUser.userId;
  }
  get currentUserRole(): string {
    return this.currentUser.role;
  }

  isPendingInvitation(status: MembershipReqStatus): boolean {
    if (
      status !== MembershipReqStatus.Completed &&
      status !== MembershipReqStatus.Cancelled
    )
      return true;
    return false;
  }

  //#endregion
  @ViewChild("pagingBar", { static: true }) private pagingBar: TdPagingBarComponent;

  constructor(
    private _dataTableService: TdDataTableService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private translateService: TranslateService,
    private membershipReqService: MembershipReqService
  ) {
    this.tableLoading = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.reqs) {
      this.initDataTable();
    }
  }
  initDataTable() {
    this.tableLoading = false;
    let listMembers: InvitedUserTableData[] = this.processMembers();
    this.data = listMembers;
    this.filter();
  }

  private processMembers(): InvitedUserTableData[] {
    return this.reqs.map((req) => {
      let newData: InvitedUserTableData = {
        id: req.id,
        name: req.firstName + " " + req.lastName,
        email: req.email,
        role: req.roleName,
        status: req.status,
        statusName: this.getStatusName(req.status),
        ouId: req.engagedOuId,
        ouPath: this.getOuPath(req.engagedOuId),
        userAvatar: req.userAvatar,
      };
      return newData;
    });
  }

  private getOuPath(ouId): string {
    let ou = this.ous.find(x => x.id === ouId);
    if (ou.isDefault) return "..";
    let parentOu = this.ous.find(x => x.id === ou.parentId);
    let ouPath = "/" + ou.name
    while (parentOu.parentId && parentOu.id !== parentOu.parentId) {
      ouPath = "/" + parentOu.name + ouPath;
      parentOu = this.ous.find(x => x.id === parentOu.parentId);
    }
    ouPath = ".." + ouPath;
    return ouPath;
  }

  ngOnInit() {
    // Subscribe to search input field value changes
    this.searchInputControl.valueChanges.subscribe((query) => {
      this.search(query)
    })
  }

  //#region data table methods
  sort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.sortBy = sortEvent.name;
    this.sortOrder = sortEvent.order;
    this.filter();
  }

  search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    if (searchTerm.length > 50) {
      searchTerm = searchTerm.substring(0,50);
      this.searchBoxsBar.value = searchTerm;
    }
    this.fromRow = 1;
    this.currentPage = 1;
    this.filter();
  }

  page(pagingEvent: IPageChangeEvent): void {
    this.fromRow = pagingEvent.fromRow;
    this.currentPage = pagingEvent.page;
    this.pageSize = pagingEvent.pageSize;
    this.filter();
  }

  applyOrgFilter(orgId) {
    this.orgFilter = orgId;
    this.filter();
  }

  filter(): void {
    let newData: InvitedUserTableData[] = this.data;
    if (this.orgFilter) {
      let orgFilteredData: any[];
      orgFilteredData = newData.filter(
        (data) => data && data.ouId === this.orgFilter
      );
      newData = orgFilteredData;
    }

    if (!this.showDeleted) {
      newData = newData.filter(
        (x) => x.status !== MembershipReqStatus.Cancelled
      );
    }

    if (!this.showAccepted) {
      newData = newData.filter(
        (x) => x.status !== MembershipReqStatus.Completed
      );
    }

    newData = this._dataTableService.filterData(
      newData,
      this.searchTerm,
      true,
      ["id", "ouId", "ouPath"]
    );
    this.filteredTotal = newData.length;
    newData = this._dataTableService.sortData(
      newData,
      this.sortBy,
      this.sortOrder
    );
    newData = this._dataTableService.pageData(
      newData,
      this.fromRow,
      this.currentPage * this.pageSize
    );
    this.filteredData = newData;
  }
  //#endregion

  reactivate(row: InvitedUserTableData) {
    $("#load-" + row["id"]).show();

    this.membershipReqService
      .reissueInvitation(row.id)
      .then((res) => {
        $("#load-" + row["id"]).hide();
        this.openSnackBar(
          this.translateService.instant("FRIEND.SUCC_RESENT_INVITE"),
          this.translateService.instant("REPEAT.OK")
        );
      })
      .catch((err) => {
        $("#load-" + row["id"]).hide();
        this.openSnackBar(err, this.translateService.instant("REPEAT.OK"));
      });
  }

  delete(row: any) {
    const dialogRef = this.dialog.open(DialogDeleteInviteComponent, {
      panelClass: "border-dialog", height: '200px',
      data: {
        email: row["email"],
        id: row["id"],
        ouId: row["ouId"],
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === "success") {
        // refresh if true
        this.openSnackBar(
          this.translateService.instant("FRIEND.SUCC_DELETE_INVITE"),
          this.translateService.instant("REPEAT.OK")
        );
      } else if (res) {
        this.openSnackBar(res, this.translateService.instant("REPEAT.OK"));
      }
    });
  }

  private openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  // showDeletedInvitations(showDeleted: boolean) {
  //   this.showDeleted = showDeleted;
  //   this.filter();
  //   this.goToLatest();
  // }

  // showAcceptedInvitations(showAccepted: boolean) {
  //   this.showAccepted = showAccepted;
  //   this.filter();
  //   this.goToLatest();
  // }

  // private goToLatest(){
  //   if (this.filteredTotal == 0) {
  //     return ;
  //   }
  //   this.maxpage = (this.filteredTotal - this.filteredTotal % this.pageSize) / this.pageSize;
  //   if (this.filteredTotal % this.pageSize > 0) {
  //     this.maxpage = this.maxpage + 1;
  //   }
  //   if (this.showDeleted == false && (this.currentPage > this.maxpage)) {
  //     this.pagingBar.navigateToPage(this.maxpage);
  //     this.currentPage = this.maxpage;
  //   };
  // }

  getStatusName(status: MembershipReqStatus): string {
    switch (status) {
      case MembershipReqStatus.Cancelled:
        return "Rejected";
      case MembershipReqStatus.Completed:
        return "Completed";
      case MembershipReqStatus.PendingForApproval:
        return "Pending For Approval";
      case MembershipReqStatus.PendingToVerify:
        return "Invited";
      default:
        return "";
    }
  }

}
class InvitedUserTableData {
  id: string;
  name: string;
  email: string;
  role: string;
  status: MembershipReqStatus;
  statusName: string;
  ouId: string;
  ouPath: string;
  userAvatar: string;
}
