import { Location } from '@angular/common';
import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from "@angular/core";
import { Login2faType } from "../login-page/login-page.component";
import { AuthService } from "../../core/auth/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { User2faSettingsDto } from '../../core/model/user2faSettings.dto';
import { CountdownComponent, CountdownConfig, CountdownEvent } from 'ngx-countdown';

@Component({
  selector: "app-verify-2fa",
  templateUrl: "./verify-2fa.component.html",
  styleUrls: ["./verify-2fa.component.scss"],
})
export class Verify2faComponent implements OnInit {
  @Output() verifyDoneEvent = new EventEmitter(); 
  showLoading: boolean = false;
  showError: boolean = false;
  showSuccess: boolean = false;
  successMsg: string;
  errorMsg: string;
  email: string;
  // redirectUrl: string;
  // redirectParams: any;

  // countdown timer
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  countdownConfig: CountdownConfig = { leftTime: 360, notify: 1 }; // 2FA code expires in 6 mins

  // 2fa variables
  user2faSettings: User2faSettingsDto;
  login2faType: Login2faType = Login2faType.None;
  email2fa: string;
  phone2fa: string;
  sentTo: string;
  code: string;

  // 2fa flags
  hasEmail2fa = false;
  hasPhone2fa = false;
  show2faVerifyWindow = false;
  showEnter2faCode = false;

  constructor(
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit() {
    // this.activatedRoute.queryParams.subscribe((params: Params) => {
    //   this.redirectUrl = params.redirectUrl;   
    //   this.redirectParams = params.redirectParams;
      this.authService.get2FASettings()
      .then(res => {
        this.user2faSettings = res;
        this.hasEmail2fa = this.user2faSettings.emailConfirmed;
        this.hasPhone2fa = this.user2faSettings.phoneNumberConfirmed;
        this.email2fa = this.user2faSettings.email;
        this.phone2fa = this.user2faSettings.phoneNumber;


        if (!this.hasPhone2fa) {
          // only 1 channel detected, auto select email
          this.request2FAEmail();
        }  
      });
    // });
  }

  backToVerificationMethod() {
    this.showEnter2faCode = false;
  }

  request2FAEmail() {
    this.showEnter2faCode = true;
    this.sentTo = this.email2fa;
    this.login2faType = Login2faType.Email;
    this.authService.get2FAEmail().then((res) => {});
  }
  request2FAPhone() {
    this.showEnter2faCode = true;
    this.sentTo = this.phone2fa;
    this.login2faType = Login2faType.Sms;
    this.authService.get2FASms().then((res) => {});
  }

  requestNewCode() {
    this.showError = false;
    this.showSuccess = false;
    if (this.login2faType === Login2faType.Email) {
      this.authService.get2FAEmail().then((res) => {
        if (res.success === false) {
          this.showError = true;
          this.errorMsg = res.error;
        } else {
          this.countdown.restart();
          this.showSuccess = true;
          this.successMsg = this.translate.instant(
            "USER_PROFILE.DIALOG.RESENT_SUCCESS"
          );
        }
      });
    } else if (this.login2faType === Login2faType.Sms) {
      this.authService.get2FASms().then((res) => {
        if (res.success === false) {
          this.showError = true;
          this.errorMsg = res.error;
        } else {
          this.countdown.restart();
          this.showSuccess = true;
          this.successMsg = this.translate.instant(
            "USER_PROFILE.DIALOG.RESENT_SUCCESS"
          );
        }
      });
    } else if (this.login2faType === Login2faType.None) {
      // send verify email
      this.authService
        .sendAccountVerificationEmail(this.email)
        .then((res) => {
          this.showSuccess = true;
          this.successMsg = this.translate.instant(
            "USER_PROFILE.DIALOG.RESENT_SUCCESS"
          );
        })
        .catch((err) => {
          this.showError = true;
          this.errorMsg = err.statusText;
        });
    }
  }

  verify(event) {
    this.showLoading = true;
    this.showError = false;
    this.showSuccess = false;

    if (this.login2faType === Login2faType.Email) {
      this.authService.verify2FAEmailWithJWTToken(this.code).then((res) => {
        if (res && res.success) {
          this.redirect();
        } else {
          this.showLoading = false;
          if (!res.error) {
            res.error = this.translate.instant("REPEAT.UNEXPECTED_ERROR");
          }
          this.showError = true;
          this.errorMsg = res.error;
        }
      });
    } else if (this.login2faType === Login2faType.Sms) {
      this.authService.verify2FAPhoneWithJWTToken(this.sentTo, this.code).then((res) => {
        if (res && res.success) {
          this.redirect();
        } else {
          this.showLoading = false;
          if (!res.error) {
            res.error = this.translate.instant("REPEAT.UNEXPECTED_ERROR");
          }
          this.showError = true;
          this.errorMsg = res.error;
        }
      });
    } else if (this.login2faType === Login2faType.None) {
      this.authService
        .exchangeTo2FAJWT()
        .then((jwt) => {
          if (jwt && jwt.success) {
            this.authService
              .verify2FAEmailWithJWTToken(this.code)
              .then((res) => {
                if (res && res.success) {
                  this.redirect();
                } else {
                  this.showLoading = false;
                  if (!res.error) {
                    res.error.error = this.translate.instant(
                      "USER_PROFILE.DIALOG.VERIFY_FAIL"
                    );
                  }
                  this.showError = true;
                  this.errorMsg = res.error;
                }
              });
          }
        })
        .catch((err) => {
          this.showLoading = false;
          if (!err.error) {
            err.error.error = this.translate.instant("REPEAT.UNEXPECTED_ERROR");
          }
          this.showError = true;
          this.errorMsg = err.error.error;
        });
    }
  }

  private redirect() {
    this.verifyDoneEvent.emit();
    // if (this.redirectUrl) {
    //   this.router.navigateByUrl(this.redirectUrl, {
    //     queryParams: { params: this.redirectParams },
    //     skipLocationChange: true 
    //   });
    // } else {
    //   this.location.back();
    // }
  }

  countdownEvent(e: CountdownEvent) {
    if (e.action === 'done') {
      window.location.reload(); // refreshes and returns user to login page
    }
  }

  // restrict alphabets from getting entered using the code of the key pressed
  validateNumber(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  onlyNumeric(): boolean {
    if (Number(this.code)) {
      console.log("Its a numeric");
    } else {
      console.log("Not a numeric");
      this.code = "";
    }
    return false;
  }
}
