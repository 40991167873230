import { MessageKey } from "./messageKey";
import { FcpCipher } from "./fcpCipher";
import { Convert } from "./convert";
import * as nacl from "tweetnacl";

export class Envelope {
  private _msgKey: MessageKey;
  private _senderPrivateKey: string;
  private _receivers: { id: string; key: string }[];

  public header: Header;
  public body: string;
  public signature: string;

  constructor() {
    this.header = new Header();
    this._receivers = [];
  }

  public addMessagekey(messageKey: MessageKey): Envelope {
    this._msgKey = messageKey;
    return this;
  }

  public addSender(
    id: string,
    privateKey: string,
    publicKey: string
  ): Envelope {
    this.header.sid = id;
    this.header.spk = publicKey;
    this._senderPrivateKey = privateKey;
    return this;
  }

  public addReceiver(id: string, publicKey: string): Envelope {
    if (this._receivers.findIndex(i => i.id == id) != -1) {
      return;
    }

    this._receivers.push({ id: id, key: publicKey });

    return this;
  }

  private generateSharedKeys() {
    this.header.skl = []; //clear keys
    var msgEncoded = this._msgKey.encode();

    if (msgEncoded == null) return;
    //sender
    this.header.skl.push(
      this.boxSharedKey(msgEncoded, this.header.sid, this.header.spk)
    );

    //receivers
    this._receivers.forEach(r => {
      this.header.skl.push(this.boxSharedKey(msgEncoded, r.id, r.key));
    });
  }

  private boxSharedKey(
    key: string,
    owner: string,
    publicKey: string
  ): SharedKey {
    let block = FcpCipher.box(key, publicKey, this._senderPrivateKey);
    let sharedKey = new SharedKey();
    sharedKey.key = block.secret;
    sharedKey.nonce = block.nonce;
    sharedKey.owner = owner;
    return sharedKey;
  }

  private static hash(source: string): string {
    var encoder = new TextEncoder();
    var uint8 = encoder.encode(source);
    var hashed = nacl.hash(uint8);
    return Convert.toBase64String(hashed);
  }

  public encrypt(plainText: string): Envelope {
    if (this._msgKey == null) return;

    this.body = FcpCipher.encrypt(this._msgKey, plainText);

    return this;
  }

  public decrypt(id: string, secretKey: string): string {
    if (!this.header) return null;
    if (!this.header.skl) return null;
    if (this.header.skl.length == 0) return null;
    if (!this.header.spk) return null;

    let skl = this.header.skl.find(i => i.owner == id);
    if (!skl) return null;

    let sharedKey = FcpCipher.open(
      skl.key,
      skl.nonce,
      this.header.spk,
      secretKey
    );
    if (!sharedKey) return null;

    let msgKey = MessageKey.decode(sharedKey);
    if (!msgKey) return null;

    return FcpCipher.decrypt(msgKey, this.body);
  }

  public encode(): string {
    this.generateSharedKeys();
    let encoder = new TextEncoder();
    //header
    let encodedHeader = this.header.encode();
    //body
    let encodedBody = Convert.toBase64String(encoder.encode(this.body));

    var payload = encodedHeader + "." + encodedBody;
    this.signature = Envelope.hash(payload);

    return payload + "." + this.signature;
  }

  public static decode(source: string): Envelope {
    let arr = source.split(".");
    if (arr.length != 3) return null;

    let payload = arr[0] + "." + arr[1];
    let sign = Envelope.hash(payload);
    if (sign != arr[2]) return null;

    let decoder = new TextDecoder();
    let decodedBody = decoder.decode(Convert.fromBase64String(arr[1]));

    let env = new Envelope();
    env.header = Header.decode(arr[0]);
    env.body = decodedBody;
    env.signature = sign;
    return env;
  }
}

export class Header {
  public iat: string;
  public alg: string;
  public ver: string;
  public sid: string;
  public spk: string;
  public skl: SharedKey[];

  constructor() {
    this.skl = [];
    this.alg = "curve25519";
    this.ver = "1.0";
    this.iat = new Date().getTime().toString();
  }

  public encode(): string {
    var serialized = JSON.stringify(this);
    return btoa(serialized);
  }

  public static decode(source: string): Header {
    var decoded = atob(source);
    var json = JSON.parse(decoded);
    if (json) {
      var header = new Header();
      header.alg = json.alg;
      header.iat = json.iat;
      header.sid = json.sid;
      header.skl = json.skl;
      header.spk = json.spk;
      header.ver = json.ver;
      return header;
    } else {
      return null;
    }
  }
}

export class SharedKey {
  public key: string;
  public owner: string;
  public nonce: string;
  constructor() {}
}
