export enum EntityStatus {
    Inactive = 0,
    Active = 1,
    Deleted = 9
}

enum OrgStatus {
    Pending = 2,
    Suspended = 3
}

export const EntityOrgStatus = { ...EntityStatus, ...OrgStatus };

export type EntityOrgStatus = EntityStatus | OrgStatus;