export class AccessRight {
    claimType: string;
    type: AccessType = AccessType.User;
    identity: string;
    permission: AccessPermission = AccessPermission.Read;
    static SHARED_CLAIM: string = "shared-claim";
    static EXTERNAL_CLAIM: string = "external-claim";

    constructor(identity?: string, permission?: AccessPermission) {
        if (!!identity && !!permission) {
            this.identity = identity;
            this.permission = permission;
            this.claimType = AccessRight.SHARED_CLAIM;
        }
    }

    static parse(dto: any) {
        if (!dto || !dto.claimType) return null;
        if (dto.claimType === AccessRight.EXTERNAL_CLAIM) return ExternalAccessRight.parse(dto);
        
        var obj = new AccessRight();
        obj.claimType = dto.claimType;
        obj.type = dto.type;
        obj.identity = dto.identity;
        obj.permission = dto.permission;
        return obj;
    }

    get isSharedAccess(): boolean {
        return this.claimType == AccessRight.SHARED_CLAIM;
    }

    get isExternalAccess(): boolean {
        return this.claimType == AccessRight.EXTERNAL_CLAIM;
    }

    toUpdateClaimDTO(): { identity: string, type: string, permission: AccessPermission } {
        return { identity: this.identity, type: this.claimType, permission: this.permission };
    }
}

export enum AccessType {
    User = 1,
    Group = 2,
    Email = 3
}

export enum AccessPermission {
    None = 0,
    Create = 1,
    Read = 2,
    Update = 4,
    Delete = 8,
    List = 16,

    //Permission for File Only
    ReadFile = 32, //2^5
    DownloadFile = 64, //2^6
    UploadFile = 128, //2^7
    UpdateFile = 256, //2^8
    DeleteFile = 512, //2^9

    View = Read | List | ReadFile | DownloadFile, 
    Modified = View | Update | UpdateFile ,
    Full = Modified | Create | Delete | UploadFile | DeleteFile,

    ViewFileOnly = ReadFile | DownloadFile,
    ModifiedFileOnly = ViewFileOnly | UpdateFile,
    FullFileAccess = ModifiedFileOnly | UploadFile | DeleteFile,

    FileAccessOnly = Read | List | FullFileAccess, //able to read folder only, but can perform file actions

    // Full = Read | List | Create | Update | Delete,
    // View = Read | List,
    // Modified = Read | List | Update
}

export class UserAccessRole {
    static readonly VIEWER = "Viewer";
    static readonly CONTRIBUTOR = "Contributor";
    static readonly MANAGER = "Manager";

    static get allRoles(): string[] {
        return [UserAccessRole.VIEWER, UserAccessRole.CONTRIBUTOR, UserAccessRole.MANAGER];
    }

    static getUserAccessRole(permission: AccessPermission) {
        switch (permission) {
            case AccessPermission.Full:
                return UserAccessRole.MANAGER;
            case AccessPermission.FileAccessOnly:
                return UserAccessRole.CONTRIBUTOR;
            default:
                return UserAccessRole.VIEWER;
        }
    }

    static getUserPermission(role: string) {
        switch (role) {
            case UserAccessRole.MANAGER:
                return AccessPermission.Full;
            case UserAccessRole.CONTRIBUTOR:
                return AccessPermission.FileAccessOnly;
            default:
                return AccessPermission.View;
        }
    }
  }

export class ExternalAccessRight extends AccessRight {
    roleId: string;
    type: AccessType = AccessType.Email;

    constructor(roleId: string, email: string, permission: AccessPermission) {
        super(email, permission);
        this.roleId = roleId;
        this.type = AccessType.Email;
        this.claimType = AccessRight.EXTERNAL_CLAIM;
    }

    static parse(dto: any) {
        if (!dto) return null;
        var obj = new ExternalAccessRight(dto.roleId, dto.identity, dto.permission);
        obj.claimType = AccessRight.EXTERNAL_CLAIM;
        return obj;
    }

    toUpdateClaimDTO(): { identity: string, type: string, permission: AccessPermission, roleId: string } {
        return { identity: this.identity, type: this.claimType, permission: this.permission, roleId: this.roleId };
    }
}