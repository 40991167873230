import * as _ from "lodash";
import { CloudFile } from "./cloud-file";
import { CloudFolder } from "./cloud-folder";
import { Resource } from "./resource";
import { SharedDriveFolder } from "./shared-drive-folder";
import { SharedFile } from "./shared-file";
import { SharedFolder } from "./shared-folder";
import { SharedResource } from "./shared-resource";
import { AccessPermission } from "./cloud-access-right";
import { FileExtension } from "./file-obj.model";
import { StorageOption } from "../services/storage-location.service";
import { OriginFolder } from "./origin-folder";
import { OriginFile } from "./origin-file";

export abstract class PickResource {
  path: string;
  icon: string;
  createdOn: number;
  modifiedOn: number;
  name: string;
  contentType: string;
  permission: AccessPermission;
  resourceType: string;
  sizeInString: string;
  originPath: string;
  container: string;
  parentPath: string;
  extension: string;
  sizeInBytes: number;
  isRoot: boolean = false;

  get nameWithoutExt(): string {
    return Resource.getNameWithoutExt(this.name);
  }

  get isFile(): boolean {
    return this.resourceType == "file";
  }

  get isFolder(): boolean {
    return this.resourceType == "folder";
  }

  hasPath(path: string) {
    return this.path == path;
  }

  static parse(uri: string, sizeInBytes: number, createdOn: number, modifiedOn: number, type: string){
    let pickResource =
      type == "directory" || (type == "NSFileTypeDirectory")
        ? PickFolder.fromPath(uri)
        : PickFile.fromPath(uri, null, sizeInBytes);
    pickResource.createdOn = createdOn;
    pickResource.modifiedOn = modifiedOn;
    pickResource.sizeInString = (sizeInBytes / 1000000).toString();
    //to let user have create folder rights
    pickResource.permission = AccessPermission.Full;
    return pickResource;
  }

  protected static fromResource(
    resource: Resource,
    userId: string
  ): PickResource {
    let f = resource.isFile ? new PickFile() : new PickFolder();
    f.path = resource.path;
    f.icon = resource.icon;
    f.createdOn = resource.createdOn;
    f.modifiedOn = resource.modifiedOn;
    f.name = resource.name;
    f.contentType = resource.contentType;
    f.resourceType = resource.resourceType;
    f.container = resource.container;
    f.parentPath = resource.parentPath;

    var grantees = resource.grantees;
    if (!grantees || grantees.length == 0) {
      f.permission = AccessPermission.None;
    } else {
      var currentGrantee = grantees.find((g) => g.identity == userId);
      if (!currentGrantee) {
        f.permission = AccessPermission.None;
      } else {
        f.permission = currentGrantee.permission;
      }
    }

    return f;
  }

  protected static fromSharedResource(
    resource: SharedResource,
    userId: string
  ): PickResource {
    let f = this.fromResource(resource, userId);
    f.path = resource.path;
    f.originPath = resource.originPath;

    return f;
  }

  hasPermission(requestedPermission: AccessPermission){
    return (this.permission & requestedPermission) != 0;
  }
}

export class PickFile extends PickResource {
  dummyFile?: SharedFile;

  constructor() {
    super();
    this.resourceType = "file";
  }

  hasPath(path: string) {
    return super.hasPath(path) || (this.dummyFile && this.dummyFile.path == path) || this.originPath == path;
  }

  static fromPath(
    path: string,
    name: string = null,
    sizeInBytes: number = 0
  ): PickFile {
    let file = new PickFile();
    file.path = path;
    let splited = file.path.split("/");
    let ext = _.last(file.path.split("."));

    file.name = name ?? decodeURIComponent(splited[splited.length - 1]);
    file.icon = file.isFile ? Resource.getType(ext) : "folder";
    file.contentType =
      FileExtension.supportedFileExtension[ext] != undefined
        ? FileExtension.supportedFileExtension[ext]["mime"].trim()
        : "application/octet-stream";
    file.extension = ext;
    file.sizeInBytes = sizeInBytes;

    return file;
  }

  static fromCloudFile(file: CloudFile, userId: string): PickFile {
    let f = PickResource.fromResource(file, userId) as PickFile;
    f.name = file.nameWithExtension;
    f.path = file.path;
    f.sizeInString = file.sizeInString;
    f.extension = file.extension;
    f.sizeInBytes = file.sizeInBytes;
    return f;
  }

  static fromSharedFile(file: SharedFile, userId: string): PickFile {
    let f = PickResource.fromSharedResource(file, userId) as PickFile;
    f.name = file.nameWithExtension;
    f.sizeInString = file.sizeInString;
    f.extension = file.extension;
    f.sizeInBytes = file.sizeInBytes;
    return f;
  }

  static fromOriginFile(file: OriginFile, userId: string): PickFile {
    let f = PickFile.fromCloudFile(file, userId) as PickFile;
    f.dummyFile = file.dummyFile;
    return f;
  }
}

export class PickFolder extends PickResource {
  isRoot: boolean;
  folders: PickFolder[];
  dummyFolder?: SharedFolder;

  constructor() {
    super();
    this.icon = "folder";
    this.resourceType = "folder";
    this.folders = [];
  }

  hasPath(path: string) {
    return super.hasPath(path) || (this.dummyFolder && this.dummyFolder.path == path) || this.originPath == path;
  }

  static fromPath(path: string): PickFolder {
    let file = new PickFolder();
    file.path = path;
    let splited = file.path.split("/");
    let name =
      splited.length == 1
        ? file.path
        : file.path.endsWith("/")
        ? splited[splited.length - 2] 
        : splited[splited.length - 1];
    file.name = decodeURIComponent(name);

    return file;
  }

  static fromCloudFolder(folder: CloudFolder, userId: string): PickFolder {
    let f = PickResource.fromResource(folder, userId) as PickFolder;
    f.isRoot = folder.isRoot;
    f.folders = folder.folders.map((f) => this.fromCloudFolder(f, userId));
    return f;
  }

  static fromSharedFolder(folder: SharedFolder, userId: string): PickFolder {
    let f = PickResource.fromSharedResource(folder, userId) as PickFolder;
    f.isRoot = folder.isRoot;
    f.folders = folder.folders.map((f) => this.fromSharedFolder(f, userId));
    return f;
  }

  static fromOriginFolder(folder: OriginFolder, userId: string): PickFolder {
    let f = PickFolder.fromCloudFolder(folder, userId) as PickFolder;
    f.dummyFolder = folder.dummyFolder;
    return f;
  }

  static fromStorageOption(option: StorageOption){
    let f = new PickFolder();
    f.name = option.name;
    f.icon = option.icon;    
    f.isRoot = true;
    return f;
  }
}

export class PickDriveFolder extends PickFolder{
  driveId: string;
  static fromSharedDriveFolder(folder: SharedDriveFolder, userId: string): PickFolder {
    let f = PickResource.fromResource(folder, userId) as PickDriveFolder;
    f.driveId = folder.id;
    f.isRoot = folder.isRoot;
    f.folders = folder.folders.map((s) => this.from(s, userId, f.driveId));
    return f;    
  }

  static from(folder: CloudFolder, userId: string, driveId: string): PickFolder {
    let f = PickResource.fromResource(folder, userId) as PickDriveFolder;
    f.driveId = driveId;
    return f;
  }
}
