
export class SideItemButton {
    action: SideItemButtonAction;
    icon: string; // material icon for button
    args: any;
}

export enum SideItemButtonAction {
    AddChannel
}
