import { GoogleDrivePickerDeleg } from './../model/resource-delegate';

import { Injectable } from "@angular/core";

import { LocalStorageService } from "../localStorage/local-storage.service";
import { environment } from "../../../environments/environment";
import { CloudFileService } from "./cloud-file.service";
import { OrgType } from "../enum/org-type";
import { CloudFile } from "../model/cloud-file";
import { MatSnackBar } from "@angular/material/snack-bar";

declare var gapi:any;
declare var google:any;

@Injectable()
export class GooglePickerService {

  // The Browser API key obtained from the Google API Console.
  // Replace with your own Browser API key, or your own key.
  developerKey: string = environment.googlePicker.developerKey;

  // The Client ID obtained from the Google API Console. Replace with your own Client ID.
  clientId: string = environment.googlePicker.clientId;

  // Replace with your own project number from console.developers.google.com.
  // See "Project number" under "IAM & Admin" > "Settings"
  appIds: string = environment.googlePicker.appIds;

  // Scope to use to access user's Drive items.
  scope = environment.googlePicker.scope;

  pickerApiLoaded: boolean = false;

  folderName: string;
  folderPath:string;
  sharedPath: string;
  orgType: any;
  orgId: string;
  existingNames: string[] = [];

  deleg: GoogleDrivePickerDeleg;


  constructor(
    private localDb: LocalStorageService,
    private cloudFileService: CloudFileService,
    public snackBar: MatSnackBar,
  ) {
    gapi.load('auth2');
    gapi.load('picker');
    gapi.load('client');
    
  }

  loadPickerWithDeleg(deleg: GoogleDrivePickerDeleg) {
    this.deleg = deleg;
    this.onPickerApiLoad();
  }

  loadPicker(name, path, sharedPath, orgType, orgId, existingNames) {
    this.folderName = name;
    this.folderPath = path;
    this.sharedPath = sharedPath;
    this.orgType = orgType;
    this.orgId = orgId;
    this.existingNames = existingNames ? existingNames : [];
    
    this.onPickerApiLoad();
  }

  async onAuthApiLoad() {
    const oauthToken = await this.localDb.getGoogleAccessToken();
    var promptType = oauthToken && oauthToken.trim() ? "none" : "consent";
    gapi.auth2.authorize(
      {
        'client_id': this.clientId,
        'scope': this.scope,
        'immediate': false,
        'access_type': "offline",
        'prompt': promptType
      },
      this.handleAuthResult.bind(this));
  }

  async onPickerApiLoad() {
    const oauthToken = await this.localDb.getGoogleAccessToken();
    const validToken = await this.checkToken(oauthToken);

    if(validToken){
      this.createPicker(oauthToken);
    }
  }

  async handleAuthResult(authResult) {
    if (authResult && !authResult.error) {
      
      await this.localDb.saveGoogleAccessToken(authResult.access_token); 
      // this.oauthToken = authResult.access_token;
      this.createPicker(authResult.access_token);
    }
  }

  async checkToken(access_token) { 
    return (
      await fetch("https://www.googleapis.com/oauth2/v1/tokeninfo", {
        method: "GET",
        headers: {
            'Authorization': 'Bearer ' + access_token
        }
      }).then(response => {
          if (response.status === 200) {
              return true;
          } else {
              console.log('User Token Expired');
              this.onAuthApiLoad();
              return false;
          }
      }).catch((error) => {
          console.error('User Token Check Error:', error);
          return false;
      })
    );    
    
  }

  // Create and render a Picker object for searching images.
  async createPicker(accessToken) {
    if (accessToken) {
      this.pickerApiLoaded = true;
      var mediaView = new google.picker.View(google.picker.ViewId.DOCS_IMAGES_AND_VIDEOS);
      var DocView = new google.picker.View(google.picker.ViewId.DOCUMENTS);
      var SpreadSheetView = new google.picker.View(google.picker.ViewId.SPREADSHEETS);
      var PDFView = new google.picker.View(google.picker.ViewId.PDFS);
      var PresentationView = new google.picker.View(google.picker.ViewId.PRESENTATIONS)
      // view.setMimeTypes("image/png,image/jpeg,image/jpg");
      var picker = new google.picker.PickerBuilder().
          addView(DocView).
          addView(SpreadSheetView).
          addView(PDFView).
          addView(PresentationView).
          addView(mediaView).
          // addViewGroup(
          //   new google.picker.ViewGroup(google.picker.ViewId.DOCS_IMAGES_AND_VIDEOS).
          //     addView(DocView).
          //     addView(SpreadSheetView).
          //     addView(PDFView).
          //     addView(PresentationView).
          //     addView(mediaView).
          //     addLabel("Files")).
          setAppId(this.appIds).
          setOAuthToken(accessToken).
          setDeveloperKey(this.developerKey).
          setCallback(this.pickerCallback.bind(this)).
          build();
      picker.setVisible(true);
    }
  }

  async pickerCallback(data) {
    console.log("pickerCallBack", data);
    
    const oauthToken = await this.localDb.getGoogleAccessToken();
    if (data.action == google.picker.Action.PICKED) {
      if (data.docs && data.docs[0]) {
        // init file size
        try {
          const fileId = data.docs[0].id;
          var response = await gapi.client.request({
            'path': `https://content.googleapis.com/drive/v2/files/${fileId}?fields=fileSize&key=${this.developerKey}`,
            });
          data.docs[0].sizeBytes = response.result.fileSize;
        } catch (error) {
          console.log('Error fetching Google Drive file size: ' + error);
          this.openSnackBar("Failed to get file size from Google Drive.", "OK");
          return;
        }
      }

      if (this.deleg) {
        return this.deleg(data, oauthToken);
      }
      
      if (data.docs && data.docs[0] && this.isDuplicate(data.docs[0].name)) {
        this.openSnackBar("File already exists.", "OK");
        return;
      }
      let promise: Promise<CloudFile>;
      if (this.orgType == OrgType.Personal) {
        promise = this.sharedPath == null ? this.cloudFileService.streamGDrivePersonalFolder(this.folderName, this.folderPath, data, oauthToken)
                    : this.cloudFileService.streamGDrivePersonalSharedFolder(this.folderName, this.folderPath, this.sharedPath, data, oauthToken);
      } else {
        promise = this.sharedPath == null ? this.cloudFileService.streamGDriveUserFolder(this.orgId, this.folderName, this.folderPath, data, oauthToken)
                    : this.cloudFileService.streamGDriveUserSharedFolder(this.orgId, this.folderName, this.folderPath, this.sharedPath, data, oauthToken);
      }

      return promise;
    }
  }

  isDuplicate(name: string): boolean {
    let fname: string = "";
    var split = name.split('.');
    if (split.length == 1) fname = name; //no ext to remove
    else fname = split.slice(0, split.length-1).join('.');
    let exist = this.existingNames.find(n => n == fname);
    return !!exist;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}

export class FeedToRemove {
  orgId: string;
  key: string;  //roomId / channel Id / postId
  feedIds: string[];

  constructor(orgId: string, key: string, feedIds: string[]) {
    this.orgId = orgId;
    this.key = key;
    this.feedIds = feedIds ? feedIds : [];
  }
}
