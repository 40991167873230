import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TeamService } from '../../../../core/services/team.service';
import { TeamState } from '../../../../core/model/org.state';
import { OrgHub } from '../../../../core/hub/org.hub';

@Component({
  selector: "app-dialog-add-channel",
  templateUrl: "./dialog-add-channel.component.html",
  styleUrls: ["./dialog-add-channel.component.scss"]
})
export class DialogAddChannelComponent implements OnInit {
  team: TeamState;
  name: string;

  isLoading = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogAddChannelComponent>,
    private orgHub: OrgHub) { }

  ngOnInit() {
    this.team = this.data.team;
  }

  addChannel() {
    this.isLoading = true;
    this.orgHub.createChannel(this.team.id, this.name).subscribe(res => {
      this.isLoading = false;
      if (!res) {
        this.dialogRef.close(res)
      } else {
        this.dialogRef.close({ status: 'success', response: res });
      }
    },
      err => {
        this.isLoading = false;
        this.dialogRef.close(err);
      });
  }
}
