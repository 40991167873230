import { Component, OnInit, Inject, EventEmitter, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserService } from '../../../../core/services/user.service';
import { OrgTreeComponent } from '../../org-tree/org-tree.component';

@Component({
  selector: 'app-dialog-org-selector',
  templateUrl: './dialog-org-selector.component.html',
  styleUrls: ['./dialog-org-selector.component.scss']
})
export class DialogOrgSelectorComponent implements OnInit {
  isLoading: boolean;
  selectedOU: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private userService: UserService,
    public dialogRef: MatDialogRef<DialogOrgSelectorComponent>) { }

  ngOnInit() {
    this.dialogRef.updateSize('500px', '70vh');
  }


  selectOU(ou: any) {
    if (ou) {
      this.selectedOU = ou;
    } else {
      this.selectedOU = null;
    }
  }

  confirmSelectOU() {
    this.dialogRef.close(this.selectedOU);
  }

}
