
// import { Contact } from './Contact';
import { Participant, ParticipantStatus } from './participant.model';
import { Message, MessageStatus } from './message.model';
import { Post } from './post.model';
import { Contact } from './contact.model';
import { EntityStatus } from '../enum/entity-status.enum';
import { Subject } from 'rxjs';

export class Room {
    roomMatrixId: string;
    creator: string;
    orgUnitId: string;
    participants: Participant[];
    postId: string;
    timeStamp: number;
    createdOn: string;
    type: RoomType;
    messages: Message[];
    imageUrl: string;
    latestMessage: Message;
    name: string;
    nextBatchNumber: string;
    entityStatus: EntityStatus;
    isHistoryLoaded: boolean;
    historyBatchNumber: string;

    topic: string;
    totalUnread: number;
    creatorMatrixId: string;
    // imageUrl: string;
    public onUpdated: Subject<Room>;

    constructor(roomId?: string) {
        this.roomMatrixId = roomId;
        this.participants = [];
        this.messages = [];
        this.totalUnread = 0;
        this.isHistoryLoaded = false;

        this.onUpdated = new Subject<Room>();
    }

    passByValue() {
        var obj: Room = Object.create(this);
        obj.roomMatrixId = this.roomMatrixId;
        obj.name = this.name;
        obj.nextBatchNumber = this.nextBatchNumber;
        obj.timeStamp = this.timeStamp;
        obj.createdOn = this.createdOn;
        obj.orgUnitId = this.orgUnitId;
        obj.postId = this.postId;
        obj.totalUnread = this.totalUnread;
        obj.creatorMatrixId = this.creatorMatrixId;
        obj.creator = this.creator;
        obj.participants = this.participants;
        obj.latestMessage = this.latestMessage;
        obj.type = this.type;
        obj.imageUrl = this.imageUrl;
        obj.topic = this.topic;
        obj.messages = [];
        obj.entityStatus = this.entityStatus;
        obj.isHistoryLoaded = this.isHistoryLoaded;
        obj.historyBatchNumber = this.historyBatchNumber;

        if (this.messages && this.messages.length !== 0) {
            this.messages.forEach(m => {
                var msgObj: Message = m.passByValue();
                msgObj.onStatusUpdateSubject = null;
                obj.messages.push(msgObj);
            });
        }

        obj.latestMessage = obj.messages && obj.messages.length !== 0 ? obj.messages[0] : null;
        return obj;
    }

    static groupByOUId(objArr: any[]): any[] {
        let keyGetter = room => room.orgUnitId;
        const prev: any[] = [];
        for (var i = 0; i < objArr.length; i++) {
            const key = keyGetter(objArr[i]);
            (prev[key] || (prev[key] = [])).push(objArr[i]);
        }
        return prev;
    }

    static parseList(data: any[]) {
        let result: Room[] = [];
        if (data) {
            data.forEach(d => {
                var room = this.parse(d);
                result.push(room);
            });
        }
        return result;
    }

    static parse(d: any) {
        if (d) {
            let room = new Room(d.roomMatrixId);
            room.name = d.name;
            room.nextBatchNumber = d.nextBatchNumber;
            room.timeStamp = d.timeStamp;
            room.createdOn = d.createdOn;
            room.orgUnitId = d.orgUnitId;
            room.postId = d.postId;
            room.totalUnread = d.totalUnread ? d.totalUnread : room.totalUnread;
            room.creatorMatrixId = d.creatorMatrixId;
            room.creator = d.creator ? d.creator : d.participants.find(p => p.matrixId === room.creatorMatrixId);
            room.participants = Participant.parseList(d.participants);
            room.messages = Message.parseList(d.messages, room.participants);
            room.latestMessage = d.latestMessage;
            room.type = d.type;
            room.imageUrl = d.imageUrl;
            room.topic = d.topic;
            room.entityStatus = d.entityStatus;
            room.isHistoryLoaded = d.isHistoryLoaded;
            room.historyBatchNumber = d.historyBatchNumber;
            room.sortMessages();
            room.parseLatestMessage();
            return room;
        }
        return null;
    }

    static sortRooms(rooms: Room[]) {
        rooms.sort((a, b) => {
            if (a.latestMessage.serverTimeStamp < b.latestMessage.serverTimeStamp) {
                return 1;
            }
            if (a.latestMessage.serverTimeStamp > b.latestMessage.serverTimeStamp) {
                return -1;
            }
            return 0;
        });
    }

    sortMessages() {
        this.messages = this.messages.sort(function (a, b) {
            var date1 = new Date(a.serverTimeStamp);
            var date2 = new Date(b.serverTimeStamp);
            if (date1 > date2) { return 1; }
            if (date1 < date2) { return -1; }
            return 0;
        });
    }

    parseLatestMessage() {
        if (this.messages.length === 0) {
            this.latestMessage = this.messages[0];
        } else {
            this.sortMessages();
            this.latestMessage = this.messages[this.messages.length - 1];
        }
    }

    parseRoomName(currentUserMatrixId: string, contacts?: Contact[]) {
        if (this.type === RoomType.Direct) {
            let p = this.participants.filter(pa => pa.matrixId !== currentUserMatrixId);
            if (p && p.length > 0) {
                if (!this.isActive(currentUserMatrixId)) {
                    return p[0].getDisplayName(contacts) + ' (Inactive)';
                } else {
                    return p[0].getDisplayName(contacts);
                }
            }
        } else {
            let p = this.participants.filter(pa => pa.matrixId !== currentUserMatrixId && pa.status !== ParticipantStatus.Leaved);
            if (p.length !== 0) {
                if (!this.name) {
                    let names = p.map(participant => participant.getFirstName(contacts)).sort();
                    return names.join(", ");
                } else {
                    return this.name;
                }
            } else {
                return "Empty Chat";
            }
        }
    }

    parseImageUrl(currentUserMatrixId: string, contacts?: Contact[]) {
        if (this.type === RoomType.Direct) {
            let p = this.participants.filter(pa => pa.matrixId !== currentUserMatrixId);
            if (p && p.length > 0) {
                return p[0].getAvatar(contacts);
            }
        } else {
            if (!this.imageUrl) {
                return '/assets/avatars/group_coworker_default.png';
            } else {
                return this.imageUrl;
            }
        }
    }

    addNewMsgs(msgs: Message[]) {
        msgs.forEach(msg => {
            let existing = this.messages.find(m => m.id === msg.id || m.id === msg.tempId);
            if (!existing) {
                this.messages.push(msg);
            }
        });
        this.sortMessages();
        this.parseLatestMessage();
    }

    deleteMsg(deletedMsg: Message) {
        let msg = this.messages.find(chat => chat.id === deletedMsg.id);
        if (msg) {
            msg.status = MessageStatus.Deleted;
        }
    }

    deleteLocalMsg(deletedMsgId: string) {
        let index = this.messages.findIndex(chat => chat.id === deletedMsgId);
        if (index !== -1) {
            let msg = this.messages.find(chat => chat.id === deletedMsgId);
            if (msg.onResend) {
                msg.onResend.unsubscribe();
            }
            this.messages.splice(index, 1);
            this.parseLatestMessage();
        }
    }

    addParticipants(newParticipants: Participant[]) {
        this.participants = this.participants.concat(newParticipants);
    }

    updateParticipants(updates: Participant[]) {
        // update participant status
        updates.forEach(u => {
            let existingP = this.participants.find(p => p.matrixId === u.matrixId);
            if (existingP.status !== u.status) {
                existingP.status = u.status;
                existingP.isLeaved = u.isLeaved;
                console.log(existingP.matrixId + " status update: " + u.status);
            }
        });
    }

    getActiveParticipants() {
        return this.participants.filter(p => p.status !== ParticipantStatus.Leaved);
    }

    get activeParticipants(): Participant[] {
        var result: Participant[] = [];

        this.participants.filter(p => p.status != ParticipantStatus.Leaved).forEach(p => {
            if (result.findIndex(i => i.matrixId == p.matrixId) == -1) {
                result.push(p);
            }
        });
        return result;
    }

    get creatorParticipant(): Participant {
        return this.participants.find(p => p.matrixId == this.creator);
    }

    // parseToPost(): Post {
    //     let post = new Post(this.roomMatrixId);
    //     if (this.messages.length !== 0) {
    //         this.messages.forEach(m => {
    //             post.chats.push(m.parseToPostChat());
    //         });
    //     }
    //     return post;
    // }

    isActive(currentUserMatrixId: string): boolean {
        let p = this.participants.filter(pa => pa.matrixId !== currentUserMatrixId && pa.status !== ParticipantStatus.Leaved);
        if (p.length === 0) {
            return false;
        } else {
            return true;
        }
    }

    getActiveParticipantCount() {
        return this.participants.filter(p => p.status === ParticipantStatus.Joined).length;
    }
}

export enum RoomType {
    Direct = 1,
    Group = 2,
    PostChat = 3
}
