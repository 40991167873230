import { State, StateContext, Selector } from "@ngxs/store";
import { ImmutableSelector, ImmutableContext } from "@ngxs-labs/immer-adapter";
import { Receiver, EmitterAction } from "@ngxs-labs/emitter";
import * as _ from "lodash";
import {
  PostState,
  PostCommentState,
  PostType,
  CommentType,
} from "../model/org.state";
import { ShortGuid } from "../util/shortGuid";
import { EntityStatus } from "../enum/entity-status.enum";
import { EnterpriseSelector } from "./enterprise.state.selector";
import { Injector, Injectable } from "@angular/core";

export class InMemPostStateModel {
  posts: PostState[];
  comments: PostCommentState[];
  constructor() {
    this.posts = [];
    this.comments = [];
  }
}

@State<InMemPostStateModel>({
  name: "impost",
  defaults: new InMemPostStateModel(),
})
@Injectable()
export class InMemPostState {
  private static enterpriseSelector: EnterpriseSelector;

  constructor(injector: Injector) {
    InMemPostState.enterpriseSelector = injector.get<EnterpriseSelector>(
      EnterpriseSelector
    );
  }

  ngxsAfterBootstrap(ctx: StateContext<InMemPostStateModel>) {
    console.log("[InMemPostStateModel] - ngxsAfterBootstrap");
  }

  @Selector([InMemPostState])
  @ImmutableSelector()
  static posts(state: InMemPostStateModel): PostState[] | null {
    return _.cloneDeep(state.posts);
  }

  @Selector([InMemPostState])
  @ImmutableSelector()
  static mediaPosts(state: InMemPostStateModel): PostState[] | null {
    var result = state.posts.filter(
      (p) => p.type == PostType.File || p.type == PostType.Image
    );
    return _.cloneDeep(result);
  }

  @Selector([InMemPostState])
  @ImmutableSelector()
  static comments(state: InMemPostStateModel): PostCommentState[] | null {
    return _.cloneDeep(state.comments);
  }

  @Selector([InMemPostState])
  @ImmutableSelector()
  static mediaComments(state: InMemPostStateModel): PostCommentState[] | null {
    var result = state.comments.filter(
      (c) => c.type == CommentType.File || c.type == CommentType.Image
    );
    return _.cloneDeep(result);
  }

  @Receiver()
  @ImmutableContext()
  public static addOrUpdatePosts(
    ctx: StateContext<InMemPostStateModel>,
    arg: EmitterAction<PostState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    const payload = arg.payload.filter((p) =>
      this.enterpriseSelector.canSaveState(p.orgId)
    );
    if (payload.length == 0) return;
    console.time("[addOrUpdatePosts]");

    const state = ctx.getState();
    const all: PostState[] = _.clone(state.posts);

    //new
    const newList: PostState[] = _.differenceWith(
      payload,
      all,
      (a: PostState, r: PostState) => r.id === a.id || (a.tempId && r.tempId && a.tempId === r.tempId)
    );

    //existing
    const existingList: PostState[] = _.intersectionWith(
      payload,
      all,
      (a: PostState, r: PostState) => r.id === a.id || (a.tempId && r.tempId && a.tempId === r.tempId)
    );

    //update existing
    existingList.forEach((dto) => {
      let index = _.findIndex(
        all,
        (r) => r.id === dto.id || (dto.tempId && r.tempId && dto.tempId === r.tempId)
      );
      if (index !== -1) {
        all[index] = this.mutatePostState(all[index], dto);
      }
    });

    //add new
    if (newList.length > 0) {
      //const currentUserId = EnterpriseState.userStateSelector.userId;

      state.posts = [...all, ...newList];
      const channelIds = _.uniq(_.map(newList, (i) => i.channelId));
      for (let i = 0; i < channelIds.length; i++) {
        const channelId = channelIds[i];
        const newPosts = newList.filter((i) => i.channelId === channelId);
        const newPostIds = _.map(newPosts, (o) => o.id);

        if (newPosts.length === 0) break;
      }

      state.posts = [...all, ...newList];
    } else {
      state.posts = [...all];
    }

    ctx.setState(state);
    console.timeEnd("[addOrUpdatePosts]");
  }

  private static mutatePostState(state: PostState, dto: PostState): PostState {
    if (!state || !dto) return state;

    state.canCreatePostComment = dto.canCreatePostComment;
    state.id = dto.id;
    state.channelId = dto.channelId;
    state.teamId = dto.teamId;
    state.comments = [...dto.comments];
    state.content = dto.content??"";
    state.fwt = dto.fwt;
    state.status = dto.status;
    state.totalComments = dto.totalComments;
    state.totalFile = dto.totalFile;
    state.sendStatus = dto.sendStatus;
    state.sendAttempt = dto.sendAttempt ? dto.sendAttempt : state.sendAttempt;
    //prevent post already read become unread again
    //if (state.isRead == false) state.isRead = dto.isRead;

    if (!state.mediaId && dto.fwt) {
      state.mediaId = ShortGuid.New();
    }
    state.isCommentHistoryLoaded = dto.isCommentHistoryLoaded;

    return state;
  }

  @Receiver()
  @ImmutableContext()
  public static deletePost(
    ctx: StateContext<InMemPostStateModel>,
    arg: EmitterAction<PostState>
  ) {
    if (arg.payload == null) return;
    if (!this.enterpriseSelector.canSaveState(arg.payload.orgId)) return;
    const postId = arg.payload.id;
    if (!postId) return;

    console.time("[deleteInMemPost]");

    const state = ctx.getState();
    const posts = _.clone(state.posts);
    //remove post
    const post: PostState = _.find(posts, (p) => p.id === postId);
    if (!post) return;

    state.posts = posts.filter((p) => p.id !== postId);

    //remove post comments
    const comments: PostCommentState[] = _.clone(state.comments);
    state.comments = comments.filter((c) => c.postId !== postId);

    ctx.setState(state);
    console.timeEnd("[deleteInMemPost]");
  }

  @Receiver()
  @ImmutableContext()
  public static addOrUpdateComments(
    ctx: StateContext<InMemPostStateModel>,
    arg: EmitterAction<PostCommentState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    const payload = arg.payload.filter((p) =>
      this.enterpriseSelector.canSaveState(p.orgId)
    );
    if (payload.length == 0) return;

    console.time("[addOrUpdateComments]");
    const state = ctx.getState();
    const all: PostCommentState[] = _.clone(state.comments);

    //new
    const newList: PostCommentState[] = _.differenceWith(
      payload,
      all,
      (a: PostCommentState, r: PostCommentState) =>
        r.id === a.id || a.tempId === r.id
    );

    //existing
    const existingList: PostCommentState[] = _.intersectionWith(
      payload,
      all,
      (a: PostCommentState, r: PostCommentState) =>
        r.id === a.id || a.tempId === r.id
    );

    //update existing
    existingList.forEach((dto) => {
      let index = _.findIndex(
        all,
        (r) => r.id === dto.id || dto.tempId === r.id
      );
      if (index !== -1) {
        all[index] = this.mutateCommentState(all[index], dto);
      }
    });

    //calculate total comments and files in post
    const postIds = _.uniq(_.map(existingList, (i) => i.postId));
    const posts = state.posts.filter((i) => _.some(postIds, (o) => o === i.id));
    posts.forEach((post) => {
      post.totalComments = state.comments.filter(
        (r) => r.postId == post.id && r.status == EntityStatus.Active
      ).length;
      post.totalFile = state.comments.filter(
        (r) =>
          r.postId == post.id &&
          r.status == EntityStatus.Active &&
          r.type == CommentType.File
      ).length;
    });

    //add new
    if (newList.length > 0) {
      const postIds = _.uniq(_.map(newList, (i) => i.postId));
      for (let i = 0; i < postIds.length; i++) {
        const postId = postIds[i];
        const newComments = _.filter(
          newList,
          (i) => i.postId === postId && i.status === EntityStatus.Active
        );
        const newCommentIds = _.map(newComments, (o) => o.id);

        if (newComments.length === 0) break;

        let post: PostState = _.find(state.posts, (o) => o.id === postId);
        if (!!post) {
          //add new comments to post
          post.comments = _.uniq([...post.comments, ...newCommentIds]);
          //recalc total comments
          post.totalComments = post.totalComments
            ? post.totalComments + newComments
            : newComments;
          let newFileComments = newComments.filter(
            (i) => i.status == EntityStatus.Active && i.type == CommentType.File
          ).length;
          //recalc total file
          post.totalFile = post.totalFile
            ? post.totalFile + newFileComments
            : newFileComments;
        }
      }

      state.comments = [...all, ...newList];
      //console.timeEnd("[addOrUpdateComments]");
    } else {
      state.comments = [...all];
    }

    ctx.setState(state);
    console.timeEnd("[addOrUpdateComments]");
  }

  @Receiver()
  @ImmutableContext()
  public static deleteComment(
    ctx: StateContext<InMemPostStateModel>,
    arg: EmitterAction<PostCommentState>
  ) {
    if (arg.payload == null) return;
    if (!this.enterpriseSelector.canSaveState(arg.payload.orgId)) return;
    const commentId = arg.payload.id;
    if (!commentId) return;

    console.time("[deleteInMemComment]");
    const state = ctx.getState();
    const comments = _.clone(state.comments);

    const existing: PostCommentState = _.find(
      comments,
      (m) => m.id === commentId
    );
    if (!existing) return;

    state.comments = comments.filter((p) => p.id != commentId);

    //remove post's comments
    // const idx = _.findIndex(state.posts, (c) => c.id === existing.postId);
    // if (idx !== -1) {
    //   const post = state.posts[idx];

    //   state.posts[idx] = {
    //     ...post,
    //     comments: [
    //       ...post.comments.filter(
    //         (p) => p !== commentId && p !== existing.tempId
    //       ),
    //     ],
    //     totalComments: post.totalComments ? post.totalComments-- : 0,
    //     totalFile: state.comments.filter(
    //       (r) =>
    //         r.postId == post.id &&
    //         r.status == EntityStatus.Active &&
    //         r.type == CommentType.File
    //     ).length,
    //   };
    // }

    ctx.setState(state);
    console.timeEnd("[deleteInMemComment]");
  }

  @Receiver()
  @ImmutableContext()
  public static deleteOUPostsAndComments(
    ctx: StateContext<InMemPostStateModel>,
    arg: EmitterAction<string>
  ) {
    if (arg.payload == null) return;
    const ouId = arg.payload;
    
    const state = ctx.getState();
    const posts = _.clone(state.posts);
    state.posts = posts.filter((p) => p.ouId !== ouId);

    const comments = _.clone(state.comments);
    state.comments = comments.filter((c) => c.ouId !== ouId);

    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  public static deleteTeamPostsAndComments(
    ctx: StateContext<InMemPostStateModel>,
    arg: EmitterAction<string>
  ) {
    if (arg.payload == null) return;
    const teamId = arg.payload;
    
    const state = ctx.getState();
    const posts = _.clone(state.posts);
    state.posts = posts.filter((p) => p.teamId !== teamId);

    const comments = _.clone(state.comments);
    state.comments = comments.filter((c) => c.teamId !== teamId);

    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  public static deleteChPostsAndComments(
    ctx: StateContext<InMemPostStateModel>,
    arg: EmitterAction<string>
  ) {
    if (arg.payload == null) return;
    const chId = arg.payload;
    
    const state = ctx.getState();
    const posts = _.clone(state.posts);
    state.posts = posts.filter((p) => p.channelId !== chId);

    const comments = _.clone(state.comments);
    state.comments = comments.filter((c) => c.channelId !== chId);

    ctx.setState(state);
  }

  private static mutateCommentState(
    state: PostCommentState,
    dto: PostCommentState
  ): PostCommentState {
    if (!state || !dto) return state;

    state.content = dto.content;
    state.id = dto.id;
    state.fwt = dto.fwt;
    state.status = dto.status;
    state.type = dto.type;
    state.sendStatus = dto.sendStatus;
    state.sendAttempt = dto.sendAttempt ? dto.sendAttempt : state.sendAttempt;
    if (!state.mediaId && dto.fwt) {
      state.mediaId = ShortGuid.New();
    }
    //prevent comment already read become unread again
    //if (state.isRead == false) state.isRead = dto.isRead;

    state.senderAvatar = dto.senderAvatar;
    state.senderName = dto.senderName;

    return state;
  }

  @Receiver() static clean(ctx: StateContext<InMemPostStateModel>) {
    ctx.setState({ ...new InMemPostStateModel() });
  }
}
