import { CancellationToken, CancellablePromise } from ".";
export class Flow {
  private _successors: any[] = [];
  private _input: any;
  private _output: any;

  public get output(): any {
    return this._output;
  }

  public set output(value: any) {
    this._output = value;
  }

  public get input(): any {
    return this._input;
  }
  constructor() {}

  public static sketch(...flows: any[]): Flow{
    let flow = new Flow();
    flow.sketch(...flows);
    return flow;
  }

  public sketch(...flows: any[]): Flow {
    this._successors.push(...flows);
    //console.log(this._successors);
    return this;
  }

  public async execute(
    input: any = null,
    token: CancellationToken = null
  ): Promise<any> {
    // console.log("[%s] input: %s",  this.constructor.name,input);
    this._input = input;
    this.sketch();
    
    if (this._successors == null || this._successors.length == 0){
      // console.log("[%s] output: %s",  this.constructor.name,this.output?.toString());
      return this.output;
    }

    if (token != null && token.isCancelled) {
      return this.output;
    }

    let bufferOutput: any = null;

    for (var item of this._successors) {
      if (Array.isArray(item)) {
        //parallel processing
        // console.log("[Flow] run parallel...%o", item);
        let promises: Promise<any>[] = [];
        //run parallel
        item
          .filter((i) => (i as Flow) != undefined)
          .forEach((i: Flow) =>
            promises.push(
              CancellablePromise.convert(
                i.execute(bufferOutput || input, token),
                () => bufferOutput,
                token
              )
            )
          );

        bufferOutput = await Promise.all(promises);
      } else {
        //sequential processing
        // console.log("[Flow] run sequential...");
        if ((item as Flow) == undefined) {
          throw Error("Element is not a Flow type");
        }

        bufferOutput = await CancellablePromise.convert(
          (item as Flow).execute(bufferOutput || input, token),
          () => bufferOutput,
          token
        );
      }
    }

    this.output = bufferOutput;
    // console.log(
    //   "[%s] output: %s",
    //   this.constructor.name,
    //   this.output?.toString()
    // );

    return this.output;
  }
}
