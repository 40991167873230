import { Component, OnInit } from "@angular/core";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { LocalStorageService } from "../../../core/localStorage/local-storage.service";
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from "@angular/forms";
import {
  StripeService,
  Elements,
  Element as StripeElement,
  CardDataOptions
} from "ngx-stripe";
import { OUService } from "../../../core/services/ou.service";
import { ELStripeElementOptions } from "../../../core/model/stripe-element.model";
import { OrgState, PaymentMethodState } from "../../../core/model/org.state";
import { EnterpriseSelector } from "../../../core/states/enterprise.state.selector";
import { OrgHub } from "../../../core/hub/org.hub";
import { SubSink } from "subsink";
import { Observable, Subscription } from "rxjs";
import { Select } from "@ngxs/store";
import { EnterpriseState } from "../../../core/states/enterprise.state";
import { LicenseState } from "../../../core/states/license.state";
import { SubscriptionState, SubStatus } from "../../../core/model/subscription.state";
import { MatOptionSelectionChange } from "@angular/material/core";

@Component({
  selector: "app-dialog-upgrade-sub",
  templateUrl: "./dialog-upgrade-sub.component.html",
  styleUrls: ["./dialog-upgrade-sub.component.scss"]
})
export class DialogUpgradeSubComponent implements OnInit {

  @Select(EnterpriseState.currentPaymentMethods)
  currentPaymentMethods$: Observable<PaymentMethodState[]>;

  @Select(LicenseState.subscriptions)
  subscriptions$: Observable<SubscriptionState[]>;

  isLoading = false;
  isInitLoading = true;
  // stripe
  elements: Elements;
  cardNumber: StripeElement;
  cardExpiry: StripeElement;
  cardCVC: StripeElement;

  paymentFormGroup: UntypedFormGroup;
  couponCtrl = new UntypedFormControl();

  // payment cards
  cards: PaymentMethodState[] = []
  newCard = new PaymentMethodState();
  selectedCard: PaymentMethodState;

  showError = false;
  errorMsg: string;

  private _subPaymentMethod: Subscription;
  private _subSubs: Subscription;
  private _subSink: SubSink;
  constructor(
    public dialogRef: MatDialogRef<DialogUpgradeSubComponent>,
    public dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private stripeService: StripeService,
    private enterpriseSelector: EnterpriseSelector,
    private orgHub: OrgHub
  ) {
    this._subSink = new SubSink();
  }

  get hasSelectedCard() {
    if (this.selectedCard && this.selectedCard.id) {
      return true;
    }
    return false;
  }

  ngOnInit() {
    this.initStripeInput();
    this.getPaymentMethods();
  }

  //#region init
  initStripeInput() {
    this.paymentFormGroup = this.fb.group({
      cardName: ['', [Validators.required]],
      cardZip: ['', [Validators.required]],
    });

    this.stripeService
      .elements({
        // Stripe's examples are localized to specific languages, but if
        // you wish to have Elements automatically detect your user's locale,
        // use `locale: 'auto'` instead.
        locale: "auto"
      })
      .subscribe(elements => {
        // Floating labels
        var elementStyles = {
          base: {
            "::placeholder": {
              color: "grey"
            }
          }
        };

        var elementClasses: ELStripeElementOptions = {
          style: elementStyles,
          classes: {
            focus: 'focused',
            empty: 'empty',
            invalid: 'invalid',
          }
        };

        if (!this.cardNumber) {
          this.cardNumber = elements.create('cardNumber', elementClasses);
          this.cardNumber.mount('#payment-card-number');
        }

        if (!this.cardExpiry) {
          this.cardExpiry = elements.create('cardExpiry', elementClasses);
          this.cardExpiry.mount('#payment-card-expiry');
        }
        if (!this.cardCVC) {
          this.cardCVC = elements.create('cardCvc', elementClasses);
          this.cardCVC.mount('#payment-card-cvc');
        }
      });
  }

  getPaymentMethods() {
    // call read paymentMethod manually
    this.isInitLoading = true;
    this.selectedCard = this.newCard;
    this._subPaymentMethod = this.orgHub.readPaymentMethod(this.enterpriseSelector.getCurrentOrgId()).subscribe(async card => {
      if (this._subPaymentMethod) this._subPaymentMethod.unsubscribe();
      console.log("[Upgrade Sub] readPaymentMethod")
      this.processCards(card);
      this.isInitLoading = false;
    });

    this._subSink.sink = this.currentPaymentMethods$.subscribe(
      state => {
        console.log("[Upgrade Sub] CurrentPaymentmethod")
        this.processCards(state);
      },
      err => console.error(err));
  }
  //#endregion

  prepareUpgradeSub() {
    this.isLoading = true;
    if (this.hasSelectedCard) {
      // use existing card
      this.callUpgradeSub(this.selectedCard.id);
    } else {
      // call stripe service to generate token based on input
      let additionalData: CardDataOptions = {
        name: this.paymentFormGroup.get('cardName').value,
        address_zip: this.paymentFormGroup.get('cardZip').value
      }
      this.stripeService
        .createToken(this.cardNumber, additionalData)
        .subscribe(result => {
          if (result.token) {
            this.callUpgradeSub(result.token.id);
          } else if (result.error) {
            this.isLoading = false;
            this.showError = true;
            this.errorMsg = result.error.message;
            // Error creating the token
            console.log(result.error.message);
          }
        });

    }
  }

  private callUpgradeSub(tokenId) {
    const currentOrgId = this.enterpriseSelector.getCurrentOrgId();
    var couponCode = this.couponCtrl.value;
    this.orgHub.subUpgrade(currentOrgId, tokenId, couponCode).subscribe(
      res => {
        this.isLoading = false;
        this.dialogRef.close({ status: "success", response: res });
        console.log("Success")
      },
      err => {
        console.log("failed")
        console.log(err)
        this.isLoading = false;
        this.dialogRef.close({ status: "failed", error: err })
      }
    );
  }

  private processCards(cards: PaymentMethodState[] = []) {
    this.cards = cards;
    this.cards.forEach(card => {
      card.icon = this.getCardIcon(card.brand);
    });
    console.log("[DialogUpgradeSub] Cards %o", this.cards);
  }


  processSubscription() {
    // after org is created, wait for service to return completed subscription with a max wait of 5 seconds
    var waitTimer = setTimeout(() => {
      console.log("[DialogUpgradeSub] Pending org timeout")
      if (this._subSubs) {
        this._subSubs.unsubscribe();
        this.dialogRef.close({ status: 'pending' });
      }
    }, 5000);

    this._subSubs = this.subscriptions$.subscribe(subs => {
      var currentOrg = this.enterpriseSelector.getCurrentOrg();
      if (currentOrg) {
        var orgSub = subs.filter(x => x.orgId === currentOrg.id)[0];
        if (orgSub) {
          console.log("[DialogNewOrg] Sub update %o", currentOrg)
          if (orgSub.status === SubStatus.Active) {
            // clear timer and sub
            if (this._subSubs) this._subSubs.unsubscribe();
            clearTimeout(waitTimer);
            this.dialogRef.close({ status: 'success' });
          } else if (orgSub.status === SubStatus.PaymentFailed) {
            // clear timer and sub
            if (this._subSubs) this._subSubs.unsubscribe();
            clearTimeout(waitTimer);
            // delete created org
            this.dialogRef.close({ status: 'paymentfailed' });
          }
        }
      }
    });
  }

  getCardIcon(brand: string) {
    let cardBrand = brand.toLowerCase();
    if (cardBrand === "american express") {
      return "cc-amex";
    } else if (cardBrand === "diners club") {
      return "cc-diners-club"
    } else if (cardBrand === "unionpay") {
      return "credit-card"
    }
    return "cc-" + cardBrand;
  }


}
