import { UIStateSelector } from './../states/ui.state.selector';
import { PubSub } from './../services/pubsub.service';
import { skip } from "rxjs/operators";
import { GCStateHelper } from "./../states/GC.state";
import {
  CancellationToken,
  Flow,
  ReconnectOrgHubFlow,
  ReconnectRoomHubFlow,
  GetOrgListFlow,
  MainFlow,
  SystemHandshakeFlow,
  LogoutFlow,
  SwitchOrgFlow,
  CancelledError,
  RoomHandshakeFlow,
  GetContactFlow
} from ".";
import { Injectable } from "@angular/core";
import { FcpService } from "../fcp/fcp.service";
import { OrgHub } from "../hub/org.hub";
import { RoomHub } from "../hub/room.hub";
import { SystemHub } from "../hub/system.hub";
import { OrgService } from "../services/org.service";
import * as _ from "lodash";
import { SubSink } from "subsink";
import { BehaviorSubject, Subscription } from "rxjs";
import { HistoryStateSelector } from "../states/history.state.selector";
import { EnterpriseSelector } from "../states/enterprise.state.selector";
import { AuthStateSelector } from '../states/auth.state.selector';
import { PrepareMeetFlow } from './prepare-meet.flow';
import { CloudFileService } from '../services/cloud-file.service';

@Injectable({
  providedIn: "root",
})
export class FlowManager {
  _onMainFlowCompleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  _tokens: CancellationToken[] = [];
  private _hubSubSink: SubSink = new SubSink();
  private _orgHubSub: Subscription = new Subscription();
  private _roomHubSub: Subscription = new Subscription();
  private _sysHubSub: Subscription = new Subscription();

  get _mainFlowName() { return "mainflow"; }
  get _prepareMeetFlowName() { return "prepareMeet"; }
  get _reconnectOrgHubName() { return "reconnectOrgHub"; }
  get _reconnectRoomHubName() { return "reconnectRoomHub"; }
  get _reconnectSysHubName() { return "reconnectSystemHub"; }
  get _switchOrgFlowName() { return "switchOrg"; }
  get _getContactsFlowName() { return "getContacts"; }

  constructor(
    private orgHub: OrgHub,
    private roomHub: RoomHub,
    private fcpService: FcpService,
    private systemHub: SystemHub,
    private orgService: OrgService,
    private enterpriseSelector: EnterpriseSelector,
    private historySelector: HistoryStateSelector,
    private gcState: GCStateHelper,
    private authStateSelector: AuthStateSelector,
    private pubSub: PubSub,
    private uiStateSelector: UIStateSelector,
    private cloudFileService: CloudFileService
  ) {}

  private initHubConnectSub() {
    // check if user is authenticated
    if (!this.authStateSelector.accessToken) {
      return;
    }
    
    if (this._orgHubSub) {
      this._orgHubSub.unsubscribe();
    }

    this._orgHubSub = this.orgHub.onHubConnected$
      .pipe(skip(1))
      .subscribe(() => {
        if (!this._hasSwitchOrgToken) {
          console.log("[Flow Manager] reconnect org hub");
          this._reconnectOrgHub();
        } else {
          console.log("[Flow Manager] running switch org flow, cancel reconnect org hub");
        }
      });

      if (this._roomHubSub) {
        this._roomHubSub.unsubscribe();
      }
  
      this._roomHubSub = this.roomHub.onHubConnected$
      .pipe(skip(1))
      .subscribe(() => {
        if (!this._hasSwitchOrgToken) {
          console.log("[Flow Manager] reconnect room hub");
          this._reconnectRoomHub();
        } else {
          console.log("[Flow Manager] running switch org flow, cancel reconnect room hub");
        }
      });

      if (this._sysHubSub) {
        this._sysHubSub.unsubscribe();
      }
  
      this._sysHubSub = this.systemHub.onHubConnected$
      .pipe(skip(1))
      .subscribe((res) => {
        this._reconnectSysHub();
      });
  }

  private _reconnectOrgHub() {
    this.executeCancellableFlow(
      new ReconnectOrgHubFlow(
        this.orgHub,
        this.enterpriseSelector,
        this.historySelector,
        this.pubSub, 
        this.orgService
      ),
      this._reconnectOrgHubName,
      null
    ).catch((err) => {
      console.error("[FlowManager] ReconnectOrgHubFlow failed " + err);
    });
  }

  private _reconnectRoomHub() {
    this.executeCancellableFlow(
      new ReconnectRoomHubFlow(
        this.roomHub,
        this.enterpriseSelector,
        this.historySelector
      ),
      this._reconnectRoomHubName,
      null
    ).catch((err) => {
      console.error("[FlowManager] ReconnectRoomHubFlow failed " + err);
    });
  }

  private _reconnectSysHub() {
    this.executeCancellableFlow(
      new SystemHandshakeFlow(this.systemHub),
      this._reconnectSysHubName,
      null
    ).catch((err) => {
      console.error("[FlowManager] SystemHandshakeFlow failed " + err);
    });
  }

  private get _hasSwitchOrgToken(): boolean {
    let token = this._getActiveToken(this._switchOrgFlowName);
    return !!token;
  }

  private _addNewToken(token: CancellationToken) {
    //cancel existing tokens
    var existingTokens = this._tokens.filter(
      (t) => t.source != null && t.source == token.source
    );

    existingTokens.forEach((t) => t.cancel());

    this._removeToken(token);

    this._tokens.push(token);
  }

  private _removeToken(token: CancellationToken) {
    _.remove(
      this._tokens,
      (t: CancellationToken) => t.source != null && t.source == token.source
    );
  }

  async executeCancellableFlow(
    flow: Flow,
    flowName: string,
    input?: any,
    token?: CancellationToken
  ) {
    try {
      if (!token) {
        token = new CancellationToken(flowName);
        this._addNewToken(token);
      }

      if ((flow as Flow) == undefined) {
        throw Error("Element is not a Flow type");
      }

      await (flow as Flow).execute(input, token);

      this._removeToken(token);
    } catch (err) {
      if (err instanceof CancelledError) {
        console.info("%s cancelled. ", flowName);
      } else {
        console.error(err);
      }
      return null;
    }
  }

  async executeMain() {
    try {
      this._onMainFlowCompleted.next(false);

      let mainflow = new MainFlow(
        this.orgHub,
        this.roomHub,
        this.fcpService,
        this.systemHub,
        this.orgService,
        this.enterpriseSelector,
        this.historySelector,
        this.pubSub
      );

      await this.executeCancellableFlow(
        mainflow,
        this._mainFlowName,
        null
        ).catch((err) => {
          console.error("[Flow Manager] mainflow failed " + err);
        });
      
      this._onMainFlowCompleted.next(true);

      this.initHubConnectSub();
    } catch (err) {
      console.error("[FlowManager] executeMain: %s", err);
      this._onMainFlowCompleted.next(true);
      this.initHubConnectSub();
    }
  }

  async switchOrg(
    orgId: string,
  ) {
    //cancel reconnect orghub roomhub flow
    this.cancelFlow(this._reconnectOrgHubName);
    this.cancelFlow(this._reconnectRoomHubName);
    
    return new Promise<void>((resolve, reject) => {
      let sub = this._onMainFlowCompleted.subscribe(
        async (isMainFlowCompleted) => {
          if (isMainFlowCompleted) {
            let switchOrgFlow = new SwitchOrgFlow(
              this.orgHub,
              this.roomHub,
              this.systemHub,
              this.gcState,
              this.enterpriseSelector,
              this.pubSub
            );
            await this.executeCancellableFlow(switchOrgFlow, this._switchOrgFlowName, 
              orgId,
            );
            resolve();
            sub.unsubscribe();
          }
        },
        (err) => reject(err)
      );
    });
  }

  getOrgList() {
    return new GetOrgListFlow(this.orgHub, this.orgService).execute();
  }

  callGetContacts() {
    if (this.orgHub.connection && !this.uiStateSelector.isContactReady) {
      let orgId = this.enterpriseSelector.getCurrentOrgId();
      this.getContacts(orgId);
    }
  }

  getContacts(orgId: string) {
    //check for existing token
    var token = this._getActiveToken(this._getContactsFlowName);
    //console.log("[get contacts on demand] token %o", token);
    if (token) return;  //GetContactFlow is being executed, return

    var getContactsFlow = new GetContactFlow(this.orgHub, this.enterpriseSelector);
    return this.executeCancellableFlow(getContactsFlow, this._getContactsFlowName, orgId);
  }

  logout() {
    this.cancelFlow(this._mainFlowName);
    return new LogoutFlow(
      this.roomHub,
      this.orgHub,
      this.systemHub,
      this.fcpService,
      this.gcState,
      this.cloudFileService
    ).execute();
  }

  private _getActiveToken(source: string) {
    return this._tokens.find(
      (t) => t.source != null && t.source == source && t.isCancelled == false
    );
  }

  private cancelFlow(flowName: string) {
    var token = this._getActiveToken(flowName);
    if (token) {
      console.log("[Flow Manager] Flow %s cancelled", flowName);
      token.cancel();
    }
  }

  prepareMeet(): Promise<any> {
    var orgId = this.enterpriseSelector.getCurrentOrgId();

    var result = this.executeCancellableFlow(
      new PrepareMeetFlow(this.roomHub, this.fcpService, this.systemHub),
      this._prepareMeetFlowName,
      orgId
    );

    this.initHubConnectSub();
    return result;
  }

  stopPrepareMeet(): void {
    var token = this._getActiveToken(this._prepareMeetFlowName);
    if (token) {
      token.cancel();
    }
  }
}
