import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Plan, TiersMode, BillingScheme, Interval } from '../../model/plan.model';
import { PlanType } from '../../model/subscription.model';
import { Coupon } from '../../model/coupon.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'ui-product-calc-table',
    templateUrl: './product-calc-table.component.html',
    styleUrls: ['./product-calc-table.component.scss']
})
export class ProductCalcTableComponent implements OnInit, OnChanges {
    TiersMode = TiersMode;
    BillingScheme = BillingScheme;
    @Input() plan: Plan;
    @Input() coupon: Coupon;

    // calculate base of number of users
    @Input() quantity = 0;

    @Output() totalEvent = new EventEmitter<number>();

    displayedColumns: string[] = ['upTo', 'unitAmount', 'flatAmount', 'quantity', 'userCost'];

    discountColumns: string[] = ['discountDesc', 'empty', 'empty', 'empty', 'discountAmount'];
    totalColumns: string[] = ['totalDesc', 'empty', 'empty', 'empty', 'total'];
    dataSource = [];
    subtotalCost = 0;
    totalCost = 0;

    // hide flat amount column if plan tiers does not have a flat amount
    hasFlatAmount = false;

    // volume tier
    prevUpTo: number;

    // strings for UI set according to plantype
    itemType: string;
    quantityHeader: string;
    
    tierTooltip: string;

    constructor(private translateService: TranslateService) { }

    ngOnInit() {
        // set strings default value
        this.quantityHeader = this.translateService.instant("ORGANIZATION.SUBSCRIPTION.SUB_QTY_TABLE_HEADER");
        this.itemType = this.translateService.instant("ORGANIZATION.SUBSCRIPTION.ADDON_DEFAULT_ITEM_TYPE");
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ((changes.quantity && changes.quantity.previousValue !== changes.quantity.currentValue) ||
        (changes.plan && changes.plan.previousValue !== changes.plan.currentValue) ||
        (changes.coupon && changes.coupon.previousValue !== changes.coupon.currentValue)) {
            setTimeout(() => {
                this.preparePlan();
                this.totalEvent.emit(this.totalCost);
            });
        }
        if (changes.plan && changes.plan.previousValue !== changes.plan.currentValue) {
            setTimeout(() => {
                this.prepareTooltip();
            });
        }
    }

    preparePlan() {
        this.totalCost = 0;
        this.subtotalCost = 0;
        this.hasFlatAmount = false;
        this.dataSource = [];
        this.prevUpTo = 0;
        if (this.plan) {
            //#region set UI strings
            if (this.plan.planType === PlanType.ELNet) {
                this.quantityHeader = this.translateService.instant("ORGANIZATION.SUBSCRIPTION.ADDON_ELNET_QTY_TABLE_HEADER");
                this.itemType =
                    this.translateService.instant("ORGANIZATION.SUBSCRIPTION.ADDON_ELNET_ITEM_TYPE");
            } else if (this.plan.planType === PlanType.EZSign) {
                this.quantityHeader = this.translateService.instant("ORGANIZATION.SUBSCRIPTION.ADDON_EZSIGN_QTY_TABLE_HEADER");
                this.itemType =
                    this.translateService.instant("ORGANIZATION.SUBSCRIPTION.ADDON_EZSIGN_ITEM_TYPE");
            } else if (this.plan.planType === PlanType.USTax) {
                this.quantityHeader = this.translateService.instant("ORGANIZATION.SUBSCRIPTION.ADDON_USTAX_QTY_TABLE_HEADER");
                this.itemType =
                    this.translateService.instant("ORGANIZATION.SUBSCRIPTION.ADDON_USTAX_ITEM_TYPE");
            } else {
                // default
                this.quantityHeader = this.translateService.instant("ORGANIZATION.SUBSCRIPTION.SUB_QTY_TABLE_HEADER");
                this.itemType =
                    this.translateService.instant("ORGANIZATION.SUBSCRIPTION.ADDON_DEFAULT_ITEM_TYPE");
            }
            //#endregion

            // tiered calculation
            if (this.plan.tiers) {
                // tiered billing, sort by descending, null last
                let tiers = [...this.plan.tiers.sort((a, b) => {
                    if (a.upTo === null) {
                        return 1;
                    } if (b.upTo === null) {
                        return -1;
                    }
                    return a.upTo < b.upTo ? -1 : 1;
                })];
                // check if tiers have flatAmount
                tiers.every((t) => {
                    if (t.flatAmount && t.flatAmount > 0) {
                        this.hasFlatAmount = true;
                        return false;
                    }
                    return true;
                });

                if (this.plan.tiersMode === TiersMode.Graduated) {
                    // fill user quantity field
                    let quantityLeft = this.quantity;
                    let prevUpTo = 0;
                    // graduated mode
                    tiers.forEach((t) => {
                        if (quantityLeft > 0) {
                            // find current tier quantity to multiply
                            let currentTierQuantity = quantityLeft;
                            if (t.upTo && quantityLeft >= (t.upTo - prevUpTo)) currentTierQuantity = (t.upTo - prevUpTo)
                            quantityLeft -= currentTierQuantity;
                            t.quantity = currentTierQuantity;
                            this.subtotalCost += (t.unitAmount * t.quantity) + t.flatAmount;
                            prevUpTo = t.upTo;
                        } else
                            t.quantity = 0;
                    });

                    this.dataSource = tiers;
                } else if (this.plan.tiersMode === TiersMode.Volume) {
                    // select current tier
                    let selectedTier;
                    this.prevUpTo = 0;
                    tiers.every((t) => {
                        if (t.upTo) {
                            if (t.upTo >= this.quantity) {
                                selectedTier = t;
                                return false;
                            } else {
                                this.prevUpTo = t.upTo;
                                return true;
                            }
                        } else {
                            selectedTier = t;
                            return false;
                        }
                    });

                    // calc
                    let userCostInCents = selectedTier.unitAmount * this.quantity;
                    this.subtotalCost = userCostInCents + selectedTier.flatAmount
                    selectedTier.quantity = this.quantity;
                    this.dataSource = [selectedTier];
                }
            }
            // price per unit calculation
            if (this.plan.billingScheme === BillingScheme.PerUnit) {
                if (this.plan.amount > 0) {
                    // not free plan
                    this.subtotalCost = this.plan.amount * this.quantity;

                    // populate table with custom perUnit data
                    let perUnitData = {
                        unitAmount: this.plan.amount,
                        quantity: this.quantity,
                        flatAmount: 0
                    }
                    this.dataSource = [perUnitData];
                } else {
                    this.subtotalCost = 0;
                }
            }

            // count total (subtotal - discount)
            // apply discount for premium annually plan
            if (this.coupon && this.coupon.isValid) {
                this.totalCost = this.subtotalCost * (100 - this.coupon.percentOff) / 100;
            } else this.totalCost = this.subtotalCost;
        }
    }

    getDisplayedColumns(): string[] {
        if (this.plan) {
            if (this.plan.billingScheme === BillingScheme.Tiered) {
                if (!this.hasFlatAmount) return this.displayedColumns.filter(x => x !== 'flatAmount');
                else return this.displayedColumns;
            } else if (this.plan.billingScheme === BillingScheme.PerUnit) {
                // remove flatAmount and upTo
                if (!this.hasFlatAmount) return this.displayedColumns.filter(x => !(x === 'flatAmount' || x === 'upTo'));
            } else return [];
        }
    }

    getFinalTotalColumns(): string[] {
        let newColumns = [...this.totalColumns];
        return this.updateColumns(newColumns);
    }

    getDiscountColumns(): string[] {
        if (this.coupon && this.coupon.isValid) {
            let newColumns = [...this.discountColumns];
            return this.updateColumns(newColumns);
        } else return null;
    }

    // update columns with correct number of empty columns
    private updateColumns(newColumns): string[] {
        if (this.plan.billingScheme === BillingScheme.Tiered) {
            // remove 1 empty column
            if (!this.hasFlatAmount) newColumns.splice(1, 1);
        } else if (this.plan.billingScheme === BillingScheme.PerUnit) {
            // remove 2 empty column
            if (!this.hasFlatAmount) newColumns.splice(1, 2);
        } else return [];
        return newColumns;
    }

    // prepares tier explination for current selected plan in tooltip
    prepareTooltip() {
        this.tierTooltip = this.translateService.instant("ORGANIZATION.SUBSCRIPTION.TIER_EXPLANATION") + ":";
        for (let i = 0; i < this.plan.tiers.length; i++) {
            this.tierTooltip += "\n "
            if (this.plan.tiersMode === TiersMode.Graduated) {
                if (i === 0 && this.plan.tiers[i].upTo) {
                    this.tierTooltip += "First " + this.plan.tiers[i].upTo + " " + this.itemType;
                }
                else if (i > 0 && this.plan.tiers[i].upTo) {
                    this.tierTooltip += "Next " + this.plan.tiers[i-1].upTo + 1 + "-" + this.plan.tiers[i].upTo + " " + this.itemType;
                }
                else if (!this.plan.tiers[i].upTo) {
                    this.tierTooltip += "For the rest";
                }
            }
            else if (this.plan.tiersMode === TiersMode.Volume) {
                if (i === 0 && this.plan.tiers[i].upTo) {
                    this.tierTooltip += this.plan.tiers[i].flatAmount+1 + "-" + this.plan.tiers[i].upTo + " " + this.itemType;
                }
                else if (i > 0 && this.plan.tiers[i].upTo) {
                    this.tierTooltip += this.plan.tiers[i-1].upTo+1 + "-" + this.plan.tiers[i].upTo + " " + this.itemType;
                }
                else if (i > 0 && !this.plan.tiers[i].upTo) {
                    this.tierTooltip += "More than " + this.plan.tiers[i-1].upTo + " " + this.itemType;
                }
                else {
                    this.tierTooltip += "For all volumes of " + this.itemType;
                }
            }
            else {
                this.tierTooltip += "N/A";
            }
            this.tierTooltip += ".....$" + (this.plan.tiers[i].unitAmount / 100).toFixed(2) + " per unit";
        }
    }
}
