export class UserProfileState {
  userId: string;
  matrixId: string;
  firstName: string;
  lastName: string;
  displayName: string;
  imageUrl: string;
  email: string;
  phoneNumber: string;
  userName: string;
  twoFactorEnabled: boolean;
  emailVerified: boolean;
  cardExpiration: number;
  phoneNumberConfirmed: boolean;
}
