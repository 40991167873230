import { Component, OnInit, Inject, OnDestroy, ChangeDetectorRef, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MessageType } from "../../core/model/message.model";
import { RoomState } from "../../core/model/room.state";
import { ParticipantState } from "../../core/model/participant.state";
import { MessageState } from "../../core/model/message.state";
import { Subscription, Observable } from "rxjs";
import { Select } from "@ngxs/store";
import { MessagingSelector } from "../../core/states/messaging.state.selector";
import { RoomHub } from "../../core/hub/room.hub";
import { FcpService } from "../../core/fcp/fcp.service";
import { SubSink } from "subsink";
import { FileStateSelector } from "../../core/states/file.state.selector";
import { EntityStatus } from "../../core/enum/entity-status.enum";
import { MsgRenderer } from "../../core/renderer/msg.renderer";
import * as _ from "lodash";
import { TdSearchBoxComponent } from "@covalent/core/search";

@Component({
  selector: "app-dialog-forward-message",
  templateUrl: "./dialog-forward-message.component.html",
  styleUrls: ["./dialog-forward-message.component.scss"],
})
export class DialogForwardMessageComponent implements OnInit, OnDestroy {
  @Select(MessagingSelector.currentOrgRooms)
  currentOrgRooms$: Observable<RoomState[]>;
  @Select(MessagingSelector.currentOrgParticipants)
  currentOrgParticipants$: Observable<ParticipantState[]>;
  @ViewChild('search') searchBar: TdSearchBoxComponent;

  isLoading: boolean;

  // load states
  message: MessageState;
  participants: ParticipantState[] = [];
  userMatrixId: string;
  rooms: RoomState[] = [];
  filteredRooms: RoomState[] = [];

  selectedRoom: RoomState;

  subSink: SubSink;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogForwardMessageComponent>,
    private roomHub: RoomHub,
    private fcp: FcpService,
    private fileSelector: FileStateSelector,
    private msgRenderer: MsgRenderer,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.subSink = new SubSink();
  }

  ngOnInit() {
    this.dialogRef.updateSize("500px", "70vh");

    this.message = this.data.message;
    this.userMatrixId = this.data.userMatrixId;

    // get rooms
    if (this.subSink) this.subSink.unsubscribe();
    this.subSink.sink = this.currentOrgRooms$.subscribe((rooms) => {
      if (!rooms) return;
      this.rooms = this.sortRooms(
        rooms.filter((x) => x.status === EntityStatus.Active)
      ).filter((x) => x.id !== this.message.roomId);
      this.filterChatRooms();
    });

    // get participants
    this.subSink.sink = this.currentOrgParticipants$.subscribe((prts) => {
      if (!prts || prts.length === 0) return;
      this.participants = prts;
    });
  }

  private sortRooms(rooms: RoomState[]) {
    return rooms.sort((a, b) => {
      let atime: any = a.lastMsg ? a.lastMsg.serverTimeStamp : a.createdOn;
      let btime: any = b.lastMsg ? b.lastMsg.serverTimeStamp : b.createdOn;
      let aDate = new Date(atime);
      let bDate = new Date(btime);

      if (aDate < bDate) {
        return 1;
      }
      if (aDate > bDate) {
        return -1;
      }
      return 0;
    });
  }

  selectRoom($event) {
    this.selectedRoom = $event;
  }

  async forwardMessage() {
    this.isLoading = true;

    // let plaintext = "";
    // let newContent = "";

    // if (
    //   this.message.content &&
    //   this.message.content.trim() !== "" &&
    //   this.message.isEncrypted
    // ) {
    //   plaintext = this.fcp.decryptMsg(this.message.content);
    //   await this.fcp
    //   .encryptMsg(plaintext, this.selectedRoom.id)
    //   .then((encryptedMsg) => {
    //     newContent = encryptedMsg;
    //   })
    //   .catch((err) => console.error(err));
    // }

    switch (this.message.type) {
      case MessageType.Image:
      case MessageType.File:
      case MessageType.Audio:
        {
          var msg = this.roomHub.createMsg(this.selectedRoom.id);
          msg.content = this.message.content;
          msg.plaintext = this.message.plaintext;
          msg.isEncrypted = this.message.isEncrypted;
          msg.orgId = this.message.orgId;
          msg.roomMatrixId = this.selectedRoom.matrixId;
          msg.type = this.message.type;
          msg.customDuration = this.message.customDuration;
          msg.mediaName = this.message.mediaName;
          msg.mediaUrl = this.message.mediaUrl;
          msg.mimeType = this.message.mimeType;
          msg.isEncrypted = this.message.isEncrypted;

          this.roomHub.forwardMedia(msg).then(
            (res) => {
              //console.log("[FORWARD] %o", res);
              this.isLoading = false;
              this.dialogRef.close({ status: "success" });
            },
            (err) => {
              this.isLoading = false;
              console.error(err);
              this.dialogRef.close({
                error: "Failed to forward",
              });
            }
          );
        }
        break;
      case MessageType.Text:
        {
          this.roomHub
            .sendText(this.selectedRoom.id, this.message.plaintext, false, this.message.isEncrypted)
            .subscribe(
              (res) => {
                this.isLoading = false;
                this.dialogRef.close({ status: "success" });
              },
              (err) => {
                this.isLoading = false;
                this.dialogRef.close({
                  error: "Failed to forward",
                });
              }
            );
        }
        break;
    }
  }

  ngOnDestroy() {
    if (this.subSink) this.subSink.unsubscribe();
  }

  filterChatRooms(search?) {
    let tempData = _.uniq([...this.rooms], o => o.id);
    if (search) {
      search = search.trim();
      if (search.length > 50) {
        search = search.substring(0,50);
        this.searchBar.value = search;
      }
      tempData = tempData.filter((room) =>
        this.msgRenderer
          .renderRoomName(room, this.participants, this.userMatrixId)
          .match(new RegExp(`${search}`, "i"))
      );
    }
    this.filteredRooms = [...tempData]

    this.changeDetectorRef.detectChanges();

  }
}
