
export class NotificationEventType {
  // Core feed event names
  static readonly NEW_MESSAGE = "new-msg";
  static readonly NEW_MEET = "new-meet";
  static readonly NEW_COMMENT = "new-comment";
  static readonly NEW_POST = "new-post";
  static readonly NEW_FEED = "new-feed";
  static readonly JOIN_REQ_APPROVED = "join-req-approved";
  static readonly JOIN_REQ_RECEIVED = "join-req-received";
  static readonly ORG_SUSPENDED = "org-suspended";
  static readonly ORG_REACTIVATED = "org-reactivated";
  static readonly EXPORT_CHAT_HISTORY_SUCCESS = "export-chat-history-success";
  static readonly EXPORT_CHAT_HISTORY_FAILED = "export-chat-history-failed";

  static readonly FEATURE_DISABLED = "feature-disabled";       
  static readonly FEATURE_LIMIT_EXCEEDED = "feature-limit-exceeded";
  static readonly NO_FREE_CREDIT = "no-free-credit";
  static readonly FREE_SUB_LIMIT_REACHING = "free-sub-limit-reaching";
  static readonly NOT_ENOUGH_CREDIT_FOR_ACTION = "not-enough-credit-for-action";

  static readonly NEW_ESIGN = "new-esign";
  static readonly NEW_EXTERNAL = "new-external";
  static readonly NEW_ASSOCIATE = "new-associate";
  static readonly NEW_STAFF = "new-staff";
  static readonly NEW_CONTACT = "new-contact";
  static readonly NEW_GROUPMEMBER = "new-groupmember";

  //storage events
  static readonly CREATE_FOLDER = "create-folder";
  static readonly DELETE_FOLDER = "delete-folder";
  static readonly RENAME_FOLDER = "rename-folder";
  static readonly MOVE_FOLDER = "move-folder";
  static readonly UPLOAD_FILE = "upload-file";
  static readonly DOWNLOAD_FILE = "download-file";
  static readonly DELETE_FILE = "delete-file";
  static readonly RENAME_FILE = "rename-file";
  static readonly MOVE_FILE = "move-file";
  static readonly COPY_FILE = "copy-file";
  static readonly SHARE_FOLDER = "share-folder";
  static readonly UNSHARE_FOLDER = "unshare-folder";
  static readonly SHARE_FILE = "share-file";
  static readonly UNSHARE_FILE = "unshare-file";
  static readonly LEAVE_SHARE = "leave-share-list";
  static readonly UPLOAD_FOLDER_FAILED = "upload-folder-failed";
}

export class EventModule {
  static readonly CHAT = "chat";
  static readonly TEAM = "team";
  static readonly MEMBERSHIP = "membership";
  static readonly ORGANIZATION = "organization";
  static readonly STORAGE = "storage";
  static readonly SUBSCRIPTION = "subscription";
  static readonly EXTERNAL = "external";
  static readonly CONNECTION = "connection";
  static readonly OPERATION = "operation";
}

