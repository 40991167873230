import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Phone, ContactDetailLabel, Address } from '../../../model/contact.model';
import { MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogProfileAddressComponent } from '../../../../user-profile/dialog/dialog-profile-address.component'

@Component({
    selector: 'input-contact-address',
    templateUrl: './input-contact-address.component.html',
    styleUrls: ['./input-contact-address.component.scss']
})
export class InputContactAddressComponent implements OnInit {
    // 2-way binding
    @Output() addressesChange: EventEmitter<Address[]> = new EventEmitter<Address[]>();
    @Input()
    get addresses(): Address[] {
        return this._addresses;
    }
    set addresses(value) {
        this._addresses = value;
        this.addressesChange.emit(this._addresses);
    }
    _addresses: Address[] = [];

    @Input() showIcon = false;

    constructor(public snackBar: MatSnackBar, public dialog: MatDialog) {
    }

    ngOnInit() {
    }


    addNewAddress() {
        this.editAddress(null);
    }

    editAddress(addr: Address) {
        const dialogRef = this.dialog.open(DialogProfileAddressComponent, {
            width: '400px', height: '650px', panelClass: "border-dialog",
            data: {
                address: addr
            }
        });
        dialogRef.afterClosed().subscribe(res => {
            if (res && res.status === 'success') {
                let editedAddress: Address = Object.assign(new Address(), res.address);

                // check if new address and has values
                if (!addr) {
                    if (!editedAddress.isEmpty()) {
                        this._addresses.push(editedAddress);
                    }
                } else {
                    // edit address. compare old and new item
                    if (JSON.stringify(editedAddress).toLowerCase() !== JSON.stringify(addr).toLowerCase()) {

                        addr.street1 = editedAddress.street1;
                        addr.street2 = editedAddress.street2;
                        addr.city = editedAddress.city;
                        addr.postCode = editedAddress.postCode;
                        addr.state = editedAddress.state;
                        addr.country = editedAddress.country;
                        addr.labelName = editedAddress.labelName;

                    } else {
                        console.log('address not changed. ignore')
                    }
                }
                this.addresses = this._addresses;

            } else if (res && res.error) {
                // error
                this.openSnackBar(res.error, 'OK');
            }
        });
    }

    deleteAddress(address) {
        this._addresses.forEach((item, index) => {
            if (item === address) {
                this._addresses.splice(index, 1);
                this.addresses = this._addresses;
            }
        });
    }


    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 3000,
        });
    }
}
