import { Selector, State, StateContext } from "@ngxs/store";
import { Feed, FeedStatus } from "../model/feed";
import { Dictionary, StringDictionary } from "../util/dictionary";
import { Injectable, Injector } from "@angular/core";
import { EnterpriseSelector } from "./enterprise.state.selector";
import { InMemFeedSelector } from "./inmem.feed.selector";
import { AppState } from "./app.state";
import { ImmutableContext, ImmutableSelector } from "@ngxs-labs/immer-adapter";
import * as _ from "lodash";
import { InMemFeedState } from "./inmem.feed.state";
import { EmitterAction, Receiver } from "@ngxs-labs/emitter";
import { NotificationEventType } from "../enum/notification-event-type.enum";
import { OrgState } from "../model/org.state";

export class FeedStateModel {
    feeds: Feed[];
    unreads: Dictionary<string[]>;

    constructor() {
        this.feeds = [];
        this.unreads = {};
    }
}

@State<FeedStateModel>({
    name: "feeds",
    defaults: new FeedStateModel(),
})
@Injectable()
export class FeedState {
    public static readonly PERSONAL_CHANNEL_FEEDS_KEY: string =
    "personal_ch_feeds";
    public static readonly SHARED_FEEDS_KEY: string =
        "shared_feeds";
  
    private static inMemFeedSelector: InMemFeedSelector;
    private static enterpriseSelector: EnterpriseSelector;

    constructor(injector: Injector, private appState: AppState) {
        FeedState.inMemFeedSelector = injector.get<InMemFeedSelector>(
            InMemFeedSelector
        );
        FeedState.enterpriseSelector = injector.get<EnterpriseSelector>(
            EnterpriseSelector
        );
    }

    ngxsAfterBootstrap(ctx: StateContext<FeedStateModel>) {
        this.appState.reportReady("feeds");
        console.log("[FeedState] - ngxsAfterBootstrap");
    }

    @Selector([FeedState])
    static recentFeeds(state: FeedStateModel): Feed[] | null {
        const feeds = [...state.feeds];
        feeds.sort((a, b) => {
            if (!a || !b) return 0;
      
            if (a.timestamp > b.timestamp) {
              return -1;
            }
            if (a.timestamp < b.timestamp) {
              return 1;
            }
            return 0;
          });
        return _.cloneDeep(feeds);
    }

    @Selector([FeedState, InMemFeedState.feeds])
    static allFeeds(state: FeedStateModel, inMemFeeds: Feed[]): Feed[] {
        const offline = [...state.feeds];
        const inmemory = inMemFeeds ? [...inMemFeeds] : [];
        var result = [...offline, ...inmemory];
        return _.cloneDeep(result);
    }

    @Selector()
    static unreads(state: FeedStateModel): StringDictionary | null {
        var result = new StringDictionary({ ...state.unreads });
        return _.cloneDeep(result);
    }

    @Receiver()
    @ImmutableContext()
    public static addOrUpdateFeeds(
        ctx: StateContext<FeedStateModel>,
        arg: EmitterAction<Feed[]>
    ) {
        if (!arg) return;
        if (!arg.payload || arg.payload.length == 0) return;
        const payload = arg.payload.filter((p) =>
        this.enterpriseSelector.canSaveState(p.orgId)
        );
        if (payload.length == 0) return;

        const state = ctx.getState();
        const all = [...state.feeds];

        //new
        const newList = _.differenceBy(payload, all, "id");

        //existing
        const existingList = _.intersectionBy(payload, all, "id");

        existingList.forEach((fd) => {
            let index = _.findIndex(all, (o) => o.id === fd.id);

            if (index !== -1) {
                all[index] = this.mutateFeedState(all[index], fd);
            }
        });

        //add new
        if (newList.length > 0) {
            state.feeds = [...all, ...newList];
        } else {
            state.feeds = [...all];
        }

        ctx.setState(state);
        
    }

    @Receiver()
    @ImmutableContext()
    public static updateFeedStatus(
        ctx: StateContext<FeedStateModel>,
        arg: EmitterAction<string[]>
    ) {
        if (!arg) return;
        if (!arg.payload || arg.payload.length == 0) return;

        const state = ctx.getState();
        const all = [...state.feeds];

        //existing
        const existingList: Feed[] = _.intersectionWith(
            arg.payload,
            all,
            (a: string, r: Feed) =>
              a === r.id
          );

        existingList.forEach((fd) => {
            let index = _.findIndex(all, (o) => o.id === fd.id);

            if (index !== -1) {
                fd.status == FeedStatus.Read;
                all[index] = this.mutateFeedState(all[index], fd);
            }
        });

        //add new
        state.feeds = [...all];

        ctx.setState(state);
        
    }

    private static mutateFeedState(
        existing: Feed,
        state: Feed
    ) {
        existing.body = state.body;
        existing.title = state.title;
        existing.subTitle = state.subTitle;
        existing.timestamp = state.timestamp;
        existing.status = state.status;

        return existing;
    }

    public static getUnreadKey(feed: Feed, personalOrg: OrgState = null) {
        if (personalOrg == null) personalOrg = FeedState.enterpriseSelector.getPersonalOrg();

        if (feed.event == NotificationEventType.SHARE_FOLDER || feed.event == NotificationEventType.SHARE_FILE) return FeedState.SHARED_FEEDS_KEY;
            
        if (feed.event == NotificationEventType.NEW_POST) {
            const isPersonalSpace = FeedState.enterpriseSelector.isSameOrg(personalOrg.id, feed.orgId);
            if (isPersonalSpace) return FeedState.PERSONAL_CHANNEL_FEEDS_KEY;
        }

        return feed.batchId ?? feed.id;
    }

    @Receiver()
    @ImmutableContext()
    public static addOrUpdateUnreads(
        ctx: StateContext<FeedStateModel>,
        arg: EmitterAction<Feed[]>
    ) {
        if (!arg) return;
        if (!arg.payload || arg.payload.length == 0) return;
        const payload = arg.payload.filter((p) =>
        this.enterpriseSelector.canSaveState(p.orgId)
        );
        if (payload.length == 0) return;

        var unreadFeeds = payload.filter(f => f.status == FeedStatus.Unread);

        const state = ctx.getState();
        let unreads = new StringDictionary({ ...state.unreads });

        const personalOrg = FeedState.enterpriseSelector.getPersonalOrg();

        var grps = _.groupBy(unreadFeeds, (d: Feed) => {
            return this.getUnreadKey(d, personalOrg);
        });

        Object.keys(grps).forEach((parent) => {
            if (!parent) return;
            const fds: Feed[] = grps[parent];
            var fdIds = fds.map((f) => f.id);
            unreads.addValues(parent, fdIds);
        });

        state.unreads = unreads.toDictionary();

        ctx.setState(state);
    }

    // @Receiver()
    // @ImmutableContext()
    // public static removeUnreads(
    //     ctx: StateContext<FeedStateModel>,
    //     arg: EmitterAction<string>
    // ) {
    //     if (!arg.payload) return;

    //     const state = ctx.getState();

    //     let unreads = new StringDictionary({...state.unreads});
    //     unreads.remove(arg.payload);
    //     state.unreads = unreads.toDictionary();

    //     ctx.setState(state);
    // }

    @Receiver()
    @ImmutableContext()
    public static updateReadUnreads(
        ctx: StateContext<FeedStateModel>,
        arg: EmitterAction<Feed[]>
    ) {
        if (!arg.payload) return;

        const state = ctx.getState();
        let existingUnreads = new StringDictionary({...state.unreads});

        const personalOrg = FeedState.enterpriseSelector.getPersonalOrg();

        let grps = _.groupBy(arg.payload, (d: Feed) => {
            return this.getUnreadKey(d, personalOrg);
        });
        let currentUnreads = Object.keys(grps);

        let unreadsToRemove: string[] = _.differenceWith(
            existingUnreads.keys(), 
            currentUnreads, 
            (a: string, r: string) =>
                a === r);
        
        if (!unreadsToRemove || unreadsToRemove.length == 0) return;

        unreadsToRemove.forEach((u) => {
            existingUnreads.remove(u);
        })

        state.unreads = existingUnreads.toDictionary();
        
        ctx.setState(state);
    }

    @Receiver()
    @ImmutableContext()
    public static removeUnreads(
        ctx: StateContext<FeedStateModel>,
        arg: EmitterAction<Feed[]>
    ) {
        if (!arg.payload) return;

        const state = ctx.getState();

        let unreads = new StringDictionary({...state.unreads});
        var grps = _.groupBy(arg.payload, (d: Feed) => d.batchId ?? d.id);

        Object.keys(grps).forEach((parent) => {
            if (!parent) return;
            unreads.remove(parent);
        });

        state.unreads = unreads.toDictionary();

        ctx.setState(state);
    }

    @Receiver() static clean(ctx: StateContext<FeedStateModel>) {
        ctx.setState({ ...new FeedStateModel() });
      }

}