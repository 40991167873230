import { CloudFile } from './cloud-file';
import { AccessRight, ExternalAccessRight } from './cloud-access-right';
import { Resource } from './resource';

export class CloudFolder extends Resource {
    isDefault: string;
    // isNew: boolean;
    isRoot: boolean;

    folders: CloudFolder[] = [];
    files: CloudFile[] = [];

    constructor() {
        super();

        this.resourceType = "folder";
        // this.scope = "NORMAL";

        this.name = "";
        this.isDefault = "false";
        // this.isNew = false;
        this.isRoot = false;
        this.description = "Folder";
        this.icon = "folder";

        this.folders = [];
        this.files = [];
        this._contentType = "Folder";
    }

    get isAppDataFolder(): boolean {
        return this.path ? this.path.includes("appdata") : false;
      }

    isSameResource(resource){
        return resource instanceof CloudFolder && super.isSameResource(resource);
    }

    static parse(dto: any) {
        if (!dto) return null;
        var obj = new CloudFolder();
        obj.container = dto.container;
        obj.name = dto.displayName;
        obj.path = dto.path;
        obj.createdOn = dto.createdOn;
        obj.modifiedOn = dto.modifiedOn;
        obj.isDefault = dto.isDefault;
        obj.enableNotifications = dto.enableNotifications;
        obj.ownerId = dto.ownerIdentity;
        // obj.isRoot = dto.isRoot; //isRoot in client side is diff from service side

        if (dto.folders) {
            dto.folders.forEach(f => obj.folders.push(CloudFolder.parse(f)));
        }

        if (dto.files) {
            dto.files.forEach(f => obj.files.push(CloudFile.parse(f)));
        }

        if (dto.grantees) {
            dto.grantees.forEach(f => obj.grantees.push(AccessRight.parse(f)));
        }

        if (dto.externalGrantees) {
            dto.externalGrantees.forEach(f => obj.externalGrantees.push(ExternalAccessRight.parse(f)));
        }

        if (obj.isShared){
            obj.icon = "folder_shared";
        }

        return obj;
    }    
}