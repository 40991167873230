import { MessageType, MessageStatus, MessageSendStatus } from "./message.model";

export class MessageState {
  id: string;
  tempId: string;
  roomId: string;
  roomMatrixId: string;
  orgId: string;

  type: MessageType;
  sendStatus: MessageSendStatus;
  status: MessageStatus;
  senderMatrixId: string;
  serverTimeStamp: number; //time that server receives the msg (from sender)
  //serverDateTime: string;
  //serverDisplayDateTime: string;
  sendAttempt: number;
  replyTo: string;

  content: string;
  plaintext: string;

  //media
  fwt: string;
  mediaId: string;
  mediaUrl: string;
  mediaName: string;
  mediaSize: number;
  mimeType: string;

  //metadata card
  showCard: boolean;

  //audio msg
  customDuration: number;

  //meet
  meetUrl: string;
  meetExp: number; // jwt expiry
  meetIat: number; // jwt issued at
  //e2e
  isEncrypted: boolean;
  //TODO: to rename to decryptStatus
  isDecrypted: DecryptStatus;
  decryptAttempt: number;
  //file
  // file: File;

  //offline support
  isOffline: boolean;

  //flag
  flags: string[];

  //calendar
  startDate: Date;
  endDate: Date;
  recurrence: string;
  meetingTitle: string;

  lastEditedTime: number;
  fileFromCloud: string = null;

  reactions: MsgReaction[];
  
  constructor() {
    this.type = MessageType.Text;
    this.sendStatus = MessageSendStatus.PendingToSent;
    this.status = MessageStatus.Valid;
    this.isEncrypted = false;
    this.isDecrypted = DecryptStatus.NotApplicable;
    this.decryptAttempt = 0;
    this.sendAttempt = 0;
    this.isOffline = true;
    this.flags = [];
    this.plaintext = null;
    this.showCard = false;
    this.reactions = [];
  }
}

export enum DecryptStatus {
  Success,
  Fail,
  Pending,
  //when content is empty, set to NotApplicable to skip the decryption loop
  NotApplicable
}

export class MsgReaction {
  content: string;
  /** Sender matrix id*/
  senders: string[];

  constructor() {
    this.senders = [];
  }

  static getEmoji(content: string): string {
    if (content == null || content.trim() == "") return "";
    if (Object.keys(MsgReactionCode).find((k) => content === k))
      return MsgReactionCode[content];
    return "";
  }
}

export enum MsgReactionCode {
  "elr-like" = "👍",
  "elr-heart" = "💖",
  "elr-laugh" = "😆",
  "elr-surprise" = "😲",
  "elr-sad" = "😢",
  "elr-angry" = "😠",
  "elr-okay" = "👌", 
  "elr-thanks" = "🙏",
  "elr-cool" = "😎", 
  "elr-clap" = "👏", 
  "elr-cheer" = "🎉", 
  "elr-check" = "✅", 
}