import { PubSub } from './../services/pubsub.service';
import { EnterpriseSelector } from "./../states/enterprise.state.selector";
import { Emitter, Emittable } from "@ngxs-labs/emitter";
import {
  Flow,
  OrgHandshakeFlow,
  RoomHandshakeFlow,
  EndPointFlow,
  CancellationToken,
  FirstInputFlow,
} from ".";
import { OrgHub } from "../hub/org.hub";
import { RoomHub } from "../hub/room.hub";
import { EnterpriseState } from "../states/enterprise.state";
import { GCStateHelper } from "../states/GC.state";
import { UIState } from "../states/ui.state";
import { GetContactFlow } from './get-contacts.flow';
import { GetFeedsFlow } from './get-feeds.flow';
import { SystemHub } from '../hub/system.hub';

export class SwitchOrgFlow extends Flow {
  @Emitter(EnterpriseState.switchOrg)
  switchOrg: Emittable<string>;

  @Emitter(UIState.setUISwitchingOrg)
  setUISwitchingOrg: Emittable<void>;

  @Emitter(UIState.setUIReady)
  setUIReady: Emittable<void>;

  constructor(
    private orgHub: OrgHub,
    private roomHub: RoomHub,
    private sysHub: SystemHub,
    private gcState: GCStateHelper,
    private enterpriseSelector: EnterpriseSelector,
    private pubSub: PubSub
  ) {
    super();
  }

  public sketch(): Flow {
    let f1 = new OrgHandshakeFlow(this.orgHub, this.enterpriseSelector, this.pubSub);
    let f2 = new RoomHandshakeFlow(this.roomHub);
    let f3 = new GetFeedsFlow(this.sysHub);
    //let f3 = new GetContactFlow(this.orgHub, this.enterpriseSelector);

    return super.sketch([f1, f2],new FirstInputFlow(), f3, new EndPointFlow());
  }

  public async execute(orgId: string, token: CancellationToken = null) {
    try {
      this.setUISwitchingOrg.emit(null);

      await this.gcState.switchOrgCleanUp();
      //if orgId null, server will decide org when call org handshake
      if (!this.enterpriseSelector.isCurrentOrg(orgId)) {
        await this.switchOrg.emit(orgId).toPromise();
      }

      await super.execute(orgId, token);

      //if (this.output) this.output = this.output[0];
      //for org id that is decide by server
      if (orgId == null && !this.enterpriseSelector.isCurrentOrg(this.output)) {
        await this.switchOrg.emit(this.output).toPromise();
      }

      this.setUIReady.emit(null);

      return this.output;
    } catch (err) {
      console.error(err);

      this.setUIReady.emit(null);
    }
  }
}
