import { InMemPostState } from './inmem.post.state';
import { AssignmentState, BuiltInUser, MembershipReqState, MembershipReqStatus, MembershipReqType } from "./../model/org.state";
import { distinct, contains, notContains } from "../util/array.extension";
import { State, StateContext, Selector, createSelector } from "@ngxs/store";
import { ImmutableContext, ImmutableSelector } from "@ngxs-labs/immer-adapter";
import { Receiver, EmitterAction } from "@ngxs-labs/emitter";
import { Injector, Injectable } from "@angular/core";
import {
  OrgState,
  OUState,
  TeamState,
  TeamMemberState,
  PostState,
  ChannelState,
  PostCommentState,
  OrgUserState,
  ContactState,
  OrgRoleState,
  OrgPermissionState,
  PostType,
  CommentType,
  ContactAddressState,
  ContactType,
  PaymentMethodState,
  TeamMemberType,
  BuiltInUserState,
} from "../model/org.state";
import { UserDataSelector } from "./user-data.state.selector";
import { EntityStatus } from "../enum/entity-status.enum";
import { MessageSendStatus } from "../model/message.model";
import { UserStatus } from "../enum/user-status.enum";
import { OrgType } from "../enum/org-type";
import { UserDataState } from "./user-data.state";
import { RoleEntity } from "../model/userRole.model";
import { ShortGuid } from "../util/shortGuid";
import * as _ from "lodash";
import { AppState } from "./app.state";
import { Dictionary, StringDictionary } from "../util/dictionary";
import { InMemPostSelector } from './inmem.post.selector';
import { EnterpriseSelector } from './enterprise.state.selector';
import { ClearUnreadDto, UnreadDto, UnreadType } from '../model/unread.state';
import { OrgExtTool } from '../model/org-ext-tool';

export class EnterpriseStateModel {
  orgs: OrgState[];
  ous: OUState[];
  teams: TeamState[];
  teamMembers: TeamMemberState[];
  channels: ChannelState[];
  posts: PostState[];
  comments: PostCommentState[];
  users: OrgUserState[];
  assignments: AssignmentState[];
  contacts: ContactState[];
  roles: OrgRoleState[];
  selectedOrg: OrgState;
  permissions: OrgPermissionState[];
  paymentMethods: PaymentMethodState[];
  builtInUsers: BuiltInUserState[];
  membershipReqs: MembershipReqState[];

  chUnreads: Dictionary<string[]>;
  postUnreads: Dictionary<string[]>;
  sharedUnreads: Dictionary<string[]>;

  pendingUnreadsToClear: ClearUnreadDto[];

  constructor() {
    this.orgs = [];
    this.ous = [];
    this.teams = [];
    this.teamMembers = [];
    this.channels = [];
    this.posts = [];
    this.comments = [];
    this.users = [];
    this.assignments = [];
    this.contacts = [];
    this.roles = [];
    this.selectedOrg = null;
    this.permissions = [];
    this.paymentMethods = [];
    this.builtInUsers = [];
    this.membershipReqs = [];
    this.chUnreads = {};
    this.postUnreads = {};
    this.sharedUnreads = {};
    this.pendingUnreadsToClear = [];
    //this.channelUnreads = [];
    //this.unreads = {};
  }
}

@State<EnterpriseStateModel>({
  name: "enterprise",
  defaults: new EnterpriseStateModel(),
})
@Injectable()
export class EnterpriseState {
  private static userSelector: UserDataSelector;
  private static inMemPostSelector: InMemPostSelector;
  private static enterpriseSelector: EnterpriseSelector;
  private static MAX_OFFLINE_RECORDS: number = 300;

  constructor(injector: Injector, private appState: AppState) {
    EnterpriseState.userSelector = injector.get<UserDataSelector>(
      UserDataSelector
    );
    EnterpriseState.inMemPostSelector = injector.get<InMemPostSelector>(
      InMemPostSelector
    );
    EnterpriseState.enterpriseSelector = injector.get<EnterpriseSelector>(
      EnterpriseSelector
    );
  }

  ngxsAfterBootstrap(ctx: StateContext<EnterpriseStateModel>) {
    this.appState.reportReady("enterprise");
    console.log("[EnterpriseState] - ngxsAfterBootstrap");
  }
  //#region  Selectors
  @Selector()
  @ImmutableSelector()
  static orgs(state: EnterpriseStateModel): OrgState[] | null {
    var result = state.orgs.filter((r) => r.status !== EntityStatus.Deleted);
    return _.cloneDeep(result);
  }

  @Selector()
  @ImmutableSelector()
  static ous(state: EnterpriseStateModel): OUState[] | null {
    var result = state.ous.filter((r) => r.status !== EntityStatus.Deleted);
    return _.cloneDeep(result);
  }

  @Selector()
  @ImmutableSelector()
  static teams(state: EnterpriseStateModel): TeamState[] | null {
    var result = state.teams.filter((r) => r.status !== EntityStatus.Deleted);
    return _.cloneDeep(result);
  }

  @Selector()
  @ImmutableSelector()
  static teamMembers(state: EnterpriseStateModel): TeamMemberState[] | null {
    var result = [...state.teamMembers];
    return _.cloneDeep(result);
  }

  @Selector()
  @ImmutableSelector()
  static channels(state: EnterpriseStateModel): ChannelState[] | null {
    var result = state.channels;
    return _.cloneDeep(result);
  }

  @Selector()
  @ImmutableSelector()
  static posts(state: EnterpriseStateModel): PostState[] | null {
    var result = state.posts.filter((r) => r.status !== EntityStatus.Deleted);
    return _.cloneDeep(result);
  }

  @Selector([EnterpriseState, InMemPostState.posts])
  // @ImmutableSelector()
  static allPosts(state: EnterpriseStateModel, inMemPosts: PostState[]): PostState[] {
    const offline = [...state.posts];
    const inmemory = inMemPosts ? [...inMemPosts] : [];
    //console.log("[MessagingState] allMessages, Offline: %s, inmemory", (offline) ? offline.length : "undefined", (inMemMessages) ? inMemMessages.length : "undefined");
    const posts = [...offline, ...inmemory];
    // var result = posts.filter((r) => r.status !== EntityStatus.Deleted); //commented out for delete post update
    return _.cloneDeep(posts);
    // return state.posts;
  }

  @Selector([EnterpriseState, InMemPostState.comments])
  // @ImmutableSelector()
  static allComments(state: EnterpriseStateModel, inMemComments: PostCommentState[]): PostCommentState[] {
    const offline = [...state.comments];
    const inmemory = inMemComments ? [...inMemComments] : [];
    //console.log("[MessagingState] allMessages, Offline: %s, inmemory", (offline) ? offline.length : "undefined", (inMemMessages) ? inMemMessages.length : "undefined");
    const comments = [...offline, ...inmemory];
    var result = comments.filter((r) => r.status !== EntityStatus.Deleted);
    return _.cloneDeep(result);
    // return state.posts;
  }

  @Selector()
  static selectedOrg(state: EnterpriseStateModel): OrgState | null {
    var result = state.selectedOrg ? { ...state.selectedOrg } : null;
    return _.cloneDeep(result);
  }

  @Selector()
  @ImmutableSelector()
  static permissions(state: EnterpriseStateModel): OrgPermissionState[] | null {
    return _.cloneDeep([...state.permissions]);
  }

  @Selector()
  static chUnreads(state: EnterpriseStateModel): StringDictionary | null {
    var result = new StringDictionary({ ...state.chUnreads });
    return _.cloneDeep(result);
  }

  @Selector()
  static postUnreads(state: EnterpriseStateModel): StringDictionary | null {
    var result = new StringDictionary({ ...state.postUnreads });
    return _.cloneDeep(result);
  }

  @Selector()
  static sharedUnreads(state: EnterpriseStateModel): StringDictionary | null {
    var result = new StringDictionary({ ...state.sharedUnreads });
    return _.cloneDeep(result);
  }

  @Selector()
  static pendingUnreadsToClear(state: EnterpriseStateModel): ClearUnreadDto[] | null {
    return _.cloneDeep(state.pendingUnreadsToClear);
  }

  @Selector()
  @ImmutableSelector()
  static paymentMethods(
    state: EnterpriseStateModel
  ): PaymentMethodState[] | null {
    return _.cloneDeep(state.paymentMethods);
  }

  @Selector()
  @ImmutableSelector()
  static builtInUsers(state: EnterpriseStateModel): BuiltInUserState[] | null {
    return _.cloneDeep(state.builtInUsers);
  }

  @Selector()
  @ImmutableSelector()
  static membershipReqs(
    state: EnterpriseStateModel
  ): MembershipReqState[] | null {
    return _.cloneDeep(state.membershipReqs);
  }

  @Selector([EnterpriseState.allPosts])
  @ImmutableSelector()
  static pendingPosts(posts: PostState[]): PostState[] | null {
    const result = posts.filter(
      (r) =>
        r.status !== EntityStatus.Deleted &&
        r.sendStatus === MessageSendStatus.PendingToSent
    );

    var results = result.sort(function (a, b) {
      let date1 = new Date(a.createdOn);
      let date2 = new Date(b.createdOn);
      if (date1 > date2) return 1;
      if (date1 < date2) return -1;
      return 0;
    });

    return _.cloneDeep(results);
  }

  @Selector([EnterpriseState.pendingPosts])
  @ImmutableSelector()
  static pendingTextPosts(posts: PostState[]): PostState[] | null {
    var result = posts.filter((p) => p.type === PostType.Text);
    return _.cloneDeep(result);
  }

  @Selector([EnterpriseState.pendingPosts])
  @ImmutableSelector()
  static pendingMediaPosts(posts: PostState[]): PostState[] | null {
    var result = posts.filter((p) => p.type !== PostType.Text);
    return _.cloneDeep(result);
  }

  @Selector()
  @ImmutableSelector()
  static comments(state: EnterpriseStateModel): PostCommentState[] | null {
    var result = state.comments.filter((r) => r.status !== EntityStatus.Deleted);
    return _.cloneDeep(result);
  }

  @Selector()
  @ImmutableSelector()
  static pendingComments(
    state: EnterpriseStateModel
  ): PostCommentState[] | null {
    const comments = state.comments.filter(
      (r) =>
        r.status !== EntityStatus.Deleted &&
        r.sendStatus === MessageSendStatus.PendingToSent
    );

    var result = comments.sort(function (a, b) {
      let date1 = new Date(a.createdOn);
      let date2 = new Date(b.createdOn);
      if (date1 > date2) return 1;
      if (date1 < date2) return -1;
      return 0;
    });

    return _.cloneDeep(result);
  }

  @Selector([EnterpriseState.pendingComments])
  @ImmutableSelector()
  static pendingTextComments(
    comments: PostCommentState[]
  ): PostCommentState[] | null {
    var result = comments.filter((p) => p.type === CommentType.Text);
    return _.cloneDeep(result);
  }

  @Selector([EnterpriseState.pendingComments])
  @ImmutableSelector()
  static pendingMediaComments(
    comments: PostCommentState[]
  ): PostCommentState[] | null {
    var result = comments.filter((p) => p.type !== CommentType.Text);
    return _.cloneDeep(result);
  }

  @Selector()
  @ImmutableSelector()
  static users(state: EnterpriseStateModel): OrgUserState[] | null {
    return _.cloneDeep(state.users);
  }

  @Selector()
  @ImmutableSelector()
  static assignments(state: EnterpriseStateModel): AssignmentState[] | null {
    return _.cloneDeep(state.assignments);
  }

  @Selector()
  @ImmutableSelector()
  static contacts(state: EnterpriseStateModel): ContactState[] | null {
    const contacts = [...state.contacts];
    contacts.forEach((c) => {
      c.displayName = `${c.firstName} ${c.lastName}`;
      if (c.addresses && c.addresses.length != 0) {
        c.addresses.forEach((a) => {
          a.fullAddress = this.getFullAddress(a);
        });
      }
    });

    return _.cloneDeep(contacts);
  }

  static getFullAddress(addr: ContactAddressState): string {
    var result: string[] = [];

    if (addr.street1 != null && addr.street1.trim() != "") {
      result.push(addr.street1);
    }

    if (addr.street2 != null && addr.street2.trim() != "") {
      result.push(addr.street2);
    }

    if (addr.postCode != null && addr.postCode.trim() != "") {
      result.push(addr.postCode);
    }

    if (addr.city != null && addr.city.trim() != "") {
      result.push(addr.city);
    }

    if (addr.state != null && addr.state.trim() != "") {
      result.push(addr.state);
    }

    if (addr.country != null && addr.country.trim() != "") {
      result.push(addr.country);
    }

    if (result.length == 0) return "";

    return result.join(", ");
  }

  @Selector()
  @ImmutableSelector()
  static roles(state: EnterpriseStateModel): OrgRoleState[] | null {
    return _.cloneDeep(state.roles);
  }

  //Current joined ou
  @Selector([
    EnterpriseState.ous,
    EnterpriseState.users,
    EnterpriseState.selectedOrg,
  ])
  @ImmutableSelector()
  static currentOu(
    ous: OUState[],
    users: OrgUserState[],
    selectedOrg: OrgState
  ): OUState {
    var result = ous.find(
      (ou) =>
        users.findIndex(
          (r) =>
            r.userId == EnterpriseState.userSelector.userId &&
            r.orgId == selectedOrg.id &&
            ou.id == r.ouId
        ) !== -1
    );
    return _.cloneDeep(result);
  }

  //Current selected org posts
  @Selector([EnterpriseState.allPosts, EnterpriseState.selectedOrg])
  @ImmutableSelector()
  static currentPosts(posts: PostState[], selectedOrg: OrgState): PostState[] {
    if (!selectedOrg) return null;

    if (selectedOrg.type == OrgType.Business) {
      var result = posts.filter(
        (r) => r.status !== EntityStatus.Deleted && r.orgId === selectedOrg.id
      );
      return _.cloneDeep(result);
    } else {
      var result = posts.filter(
        (c) =>
          c.status !== EntityStatus.Deleted &&
          EnterpriseState.isSameOrg(selectedOrg, c.orgId)
      );
      return _.cloneDeep(result);
    }
  }

  @Selector([EnterpriseState.allComments, EnterpriseState.selectedOrg])
  @ImmutableSelector()
  static currentComments(
    comments: PostCommentState[],
    selectedOrg: OrgState
  ): PostCommentState[] {
    if (!selectedOrg) return null;

    if (selectedOrg.type == OrgType.Business) {
      var result = comments.filter(
        (r) => r.status !== EntityStatus.Deleted && r.orgId === selectedOrg.id
      );
      return _.cloneDeep(result);
    } else {
      var result = comments.filter(
        (c) =>
          c.status !== EntityStatus.Deleted &&
          EnterpriseState.isSameOrg(selectedOrg, c.orgId)
      );
      return _.cloneDeep(result);
    }
  }

  //Current selected org channels
  @Selector([EnterpriseState.channels, EnterpriseState.selectedOrg])
  @ImmutableSelector()
  static currentChannels(
    channels: ChannelState[],
    selectedOrg: OrgState
  ): ChannelState[] {
    if (!selectedOrg) return [];
    if (selectedOrg.type == OrgType.Business) {
      var result = channels.filter((r) => r.orgId === selectedOrg.id);
      return _.cloneDeep(result);
    } else {
      var result = channels.filter((c) =>
        EnterpriseState.isSameOrg(selectedOrg, c.orgId)
      );
      return _.cloneDeep(result);
    }
  }

  //Current selected org teams
  @Selector([EnterpriseState.teams, EnterpriseState.selectedOrg])
  @ImmutableSelector()
  static currentTeams(teams: TeamState[], selectedOrg: OrgState): TeamState[] {
    if (!selectedOrg) return [];
    if (selectedOrg.type == OrgType.Business) {
      var result = teams.filter((r) => r.orgId === selectedOrg.id);
      return _.cloneDeep(result);
    } else {
      var result = teams.filter((c) =>
        EnterpriseState.isSameOrg(selectedOrg, c.orgId)
      );
      return _.cloneDeep(result);
    }
  }

  //Current selected org team members
  @Selector([EnterpriseState.teamMembers, EnterpriseState.selectedOrg])
  @ImmutableSelector()
  static currentTeamMembers(
    members: TeamMemberState[],
    selectedOrg: OrgState
  ): TeamMemberState[] {
    if (!selectedOrg) return [];
    if (selectedOrg.type == OrgType.Business) {
      var result = members.filter((r) => r.orgId === selectedOrg.id);
      return _.cloneDeep(result);
    } else {
      var result = members.filter((c) =>
        EnterpriseState.isSameOrg(selectedOrg, c.orgId)
      );
      return _.cloneDeep(result);
    }
  }

  //Current selected org users
  @Selector([EnterpriseState.users, EnterpriseState.selectedOrg])
  @ImmutableSelector()
  static currentOrgUsers(
    users: OrgUserState[],
    selectedOrg: OrgState
  ): OrgUserState[] {
    var result = users.filter((u) => EnterpriseState.isSameOrg(selectedOrg, u.orgId));
    return _.cloneDeep(result);
  }

  @Selector([EnterpriseState.currentOrgUsers, EnterpriseState.ous, EnterpriseState.selectedOrg])
  @ImmutableSelector()
  static managedOrgUsers(
    users: OrgUserState[],
    ous: OUState[],
    selectedOrg: OrgState
  ): OrgUserState[] {
    ous = ous.filter((u) => EnterpriseState.isSameOrg(selectedOrg, u.orgId));

    var currentUser = users.find(
      (r) =>
        r.userId == EnterpriseState.userSelector.userId
    );

    var ou = this.getManagedOu(currentUser, ous);
    var result = users.filter((u) => ou.includes(u.ouId));
    return _.cloneDeep(result);
  }

  private static getManagedOu(currentOrgUser: OrgUserState, ous: OUState[]): string[] {
    var managedOUs = [currentOrgUser.ouId];

    if (currentOrgUser.role === RoleEntity[RoleEntity.OWNER]) {
      return _.cloneDeep(ous.map(o => o.id));
    } else if (currentOrgUser.role === RoleEntity[RoleEntity.ADMIN]) {
      let ou = ous.find(x => x.id === currentOrgUser.ouId);
      managedOUs.push(ou.id);
      managedOUs.push(...this.getAllSubOus(ous, ou.id));
    }

    return _.cloneDeep(managedOUs);
  }

  private static getAllSubOus(ous: OUState[], ouId: string, childs: string[] = []): string[] {
    let ou = ous.find((x) => x.id === ouId);
    if (ou && ou.childs.length != 0) {
      childs.push(...ou.childs);
      ou.childs.forEach((c) => {
        this.getAllSubOus(ous, c, childs);
      });
    }

    return childs;
  }

  //Current selected org OUs
  @Selector([EnterpriseState.ous, EnterpriseState.selectedOrg])
  @ImmutableSelector()
  static currentOrgOUs(ous: OUState[], selectedOrg: OrgState): OUState[] {
    var result = ous.filter((u) => EnterpriseState.isSameOrg(selectedOrg, u.orgId));
    return _.cloneDeep(result);
  }

  //current org user
  @Selector([EnterpriseState.users, EnterpriseState.selectedOrg])
  @ImmutableSelector()
  static currentOrgUser(
    users: OrgUserState[],
    selectedOrg: OrgState
  ): OrgUserState {
    const userId = EnterpriseState.userSelector.userId;
    var orgUsers = users.find(
      (r) =>
        r.userId == userId && EnterpriseState.isSameOrg(selectedOrg, r.orgId)
    );
    return _.cloneDeep(orgUsers);
  }

  @Selector([EnterpriseState.roles, EnterpriseState.selectedOrg])
  @ImmutableSelector()
  static currentOrgRoles(
    roles: OrgRoleState[],
    selectedOrg: OrgState
  ): OrgRoleState[] {
    if (!selectedOrg) return null;
    var result = roles.filter((u) => u.orgId === selectedOrg.id);
    return _.cloneDeep(result);
  }

  //Current select org contacts
  @Selector([EnterpriseState.contacts, EnterpriseState.selectedOrg])
  @ImmutableSelector()
  static currentContacts(
    contacts: ContactState[],
    selectedOrg: OrgState
  ): ContactState[] {
    if (!selectedOrg) return [];
    if (selectedOrg.type == OrgType.Business) {
      var result = contacts.filter((r) => r.orgId === selectedOrg.id);
      return _.cloneDeep(result);
    }
    else {
      var result = contacts.filter((c) =>
        EnterpriseState.isSameOrg(selectedOrg, c.orgId)
      );
      return _.cloneDeep(result);
    }
  }

  @Selector([EnterpriseState.currentContacts])
  @ImmutableSelector()
  static currentOnlineContacts(contacts: ContactState[]): ContactState[] {
    if (!contacts) return [];
    var result = contacts.filter((c) => c.type === ContactType.Online);
    return _.cloneDeep(result);
  }

  @Selector([EnterpriseState.currentContacts, UserDataState.userId])
  @ImmutableSelector()
  static currentUserContact(
    contacts: ContactState[],
    userId: string
  ): ContactState {
    if (!contacts) return null;
    var result = contacts.find((r) => r.userId == userId);
    return _.cloneDeep(result);
  }

  //Current select org permissions
  @Selector([EnterpriseState.permissions, EnterpriseState.selectedOrg])
  @ImmutableSelector()
  static currentPermissions(
    permissions: OrgPermissionState[],
    selectedOrg: OrgState
  ): OrgPermissionState {
    if (!selectedOrg) return null;
    var result = permissions.find((r) => r.orgId === selectedOrg.id);
    return _.cloneDeep(result);
  }

  //Current select org payment method
  @Selector([EnterpriseState.paymentMethods, EnterpriseState.selectedOrg])
  @ImmutableSelector()
  static currentPaymentMethods(
    paymentMethods: PaymentMethodState[],
    selectedOrg: OrgState
  ): PaymentMethodState[] {
    if (!selectedOrg) return null;
    var result = paymentMethods.filter((r) => r.orgId === selectedOrg.id);
    return _.cloneDeep(result);
  }

  static orgOus(orgId: string) {
    return createSelector([EnterpriseState.ous], (ous: OUState[]) => {
      var result = ous.filter((o) => o.orgId === orgId);
      return _.cloneDeep(result);
    });
  }

  static orgTeams(orgId: string) {
    return createSelector([EnterpriseState.teams], (teams: TeamState[]) => {
      var result = teams.filter((o) => o.orgId === orgId);
      return _.cloneDeep(result);
    });
  }

  static orgTeamMembers(orgId: string) {
    return createSelector(
      [EnterpriseState.teamMembers],
      (members: TeamMemberState[]) => {
        var result = members.filter((o) => o.orgId === orgId);
        return _.cloneDeep(result);
      }
    );
  }

  static orgChannels(orgId: string) {
    return createSelector(
      [EnterpriseState.channels],
      (channels: ChannelState[]) => {
        var result = channels.filter((o) => o.orgId === orgId);
        return _.cloneDeep(result);
      }
    );
  }

  static orgComments(postId: string) {
    return createSelector(
      [EnterpriseState.allComments],
      (postComments: PostCommentState[]) => {
        var result = postComments.filter((o) => o.postId === postId);
        return _.cloneDeep(result);
      }
    );
  }

  static orgRoles(orgId: string) {
    return createSelector([EnterpriseState.roles], (roles: OrgRoleState[]) => {
      var result = roles.filter((o) => o.orgId === orgId);
      return _.cloneDeep(result);
    });
  }

  static orgPermissions(orgId: string) {
    return createSelector(
      [EnterpriseState.permissions],
      (permissions: OrgPermissionState[]) => {
        var result = permissions.filter((o) => o.orgId === orgId);
        return _.cloneDeep(result);
      }
    );
  }

  //For "My Organizations" listing
  @Selector([EnterpriseState.orgs, EnterpriseState.users])
  static internalOrgs(orgs: OrgState[], users: OrgUserState[]) {
    const currentUserId = EnterpriseState.userSelector.userId;
    var result = orgs.filter((org) =>
      users.some(
        (u) =>
          u.status != UserStatus.Deleted &&
          u.orgId == org.id &&
          u.userId == currentUserId &&
          (u.role === RoleEntity[RoleEntity.OWNER] ||
            u.role === RoleEntity[RoleEntity.ADMIN] ||
            u.role === RoleEntity[RoleEntity.COWORKER])
      )
    );
    return _.cloneDeep(result);
  }

  //For "My External Contacts" listing
  @Selector([EnterpriseState.orgs, EnterpriseState.users])
  static externalOrgs(orgs: OrgState[], users: OrgUserState[]) {
    var result = orgs.filter((org) =>
      users.some(
        (u) =>
          u.status != UserStatus.Deleted &&
          u.orgId == org.id &&
          u.userId == EnterpriseState.userSelector.userId &&
          u.role === RoleEntity[RoleEntity.PARTNER]
      )
    );
    return _.cloneDeep(result);
  }

  //For "My Service Provider" listing
  @Selector([EnterpriseState.orgs, EnterpriseState.users])
  static serviceOrgs(orgs: OrgState[], users: OrgUserState[]) {
    var result = orgs.filter((org) =>
      users.some(
        (u) =>
          u.status != UserStatus.Deleted &&
          u.orgId == org.id &&
          u.userId == EnterpriseState.userSelector.userId &&
          u.role === RoleEntity[RoleEntity.CLIENT]
      )
    );
    return _.cloneDeep(result);
  }

  @Selector([EnterpriseState.membershipReqs, EnterpriseState.selectedOrg])
  static currentPendingJoinReqs(
    reqs: MembershipReqState[],
    selectedOrg: OrgState
  ) {
    var result = reqs.filter(
      (r) =>
        r.type === MembershipReqType.RequestJoin && r.orgId === selectedOrg.id && r.status !== MembershipReqStatus.PendingToVerify
    );
    return _.cloneDeep(result);
  }

  @Selector([EnterpriseState.membershipReqs, EnterpriseState.selectedOrg])
  static currentInvitationReqs(
    reqs: MembershipReqState[],
    selectedOrg: OrgState
  ) {
    var result = reqs.filter(
      (r) =>
        r.type === MembershipReqType.Invitation && r.orgId === selectedOrg.id
    );
    return _.cloneDeep(result);
  }

  //#endregion

  //#region Org State

  @Receiver()
  @ImmutableContext()
  public static addOrUpdateOrgs(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<OrgState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    // console.time("[addOrUpdateOrgs]");
    const state = ctx.getState();
    const orgs = _.clone(state.orgs);

    //new orgs
    var newOrgs = _.differenceBy(arg.payload, orgs, "id");

    // Personal Orgs that do not belong to user should not be added as new orgs
    if (orgs.some(o => o.type == OrgType.Personal) && newOrgs.some(o => o.type == OrgType.Personal)) {
      // user already has a PS org in state
      newOrgs = newOrgs.filter(o => o.type != OrgType.Personal);
    }

    //existing orgs
    const existingOrgs = _.intersectionBy(arg.payload, orgs, "id");

    //update existing
    existingOrgs.forEach((dto) => {
      let index = _.findIndex(orgs, (r) => r.id === dto.id);
      if (index !== -1) {
        orgs[index] = this.mutateOrgState(orgs[index], dto);
      }
    });

    //update selectedOrg
    if (state.selectedOrg) {
      let index = _.findIndex(existingOrgs, (r) => r.id === state.selectedOrg.id);
      if (index !== -1) {
        const selectedOrg = this.mutateOrgState(
          state.selectedOrg,
          existingOrgs[index]
        );
        state.selectedOrg = { ...selectedOrg };
      }
    }

    //add new
    if (newOrgs.length > 0) {
      state.orgs = [...orgs, ...newOrgs];
    } else {
      state.orgs = [...orgs];
    }

    ctx.setState(state);
    // console.timeEnd("[addOrUpdateOrgs]");
  }

  // @Receiver()
  // @ImmutableContext()
  // public static suspendOrg(
  //   ctx: StateContext<EnterpriseStateModel>,
  //   arg: EmitterAction<string>
  // ) {
  //   if (!arg || !arg.payload) return;

  //   console.time("[suspendOrg]");
  //   const orgId = arg.payload;

  //   const state = ctx.getState();

  //   //change org status
  //   const orgIdx = _.findIndex(state.orgs, (i) => i.id === orgId);
  //   if (orgIdx === -1) return;
  //   state.orgs[orgIdx].status = EntityStatus.Inactive; //suspended

  //   //remove ou
  //   const ous = [...state.ous];
  //   state.ous = ous.filter((i) => i.orgId !== orgId);

  //   //remove team
  //   const teams = [...state.teams];
  //   state.teams = teams.filter((t) => t.orgId !== orgId);

  //   //remove channel
  //   const channels = [...state.channels];
  //   state.channels = channels.filter((c) => c.orgId !== orgId);

  //   //remove posts
  //   const posts = [...state.posts];
  //   state.posts = posts.filter((p) => p.orgId !== orgId);

  //   //remove post comments
  //   const comments = [...state.comments];
  //   state.comments = comments.filter((c) => c.orgId !== orgId);

  //   //remove contacts
  //   const contacts = [...state.contacts];
  //   state.contacts = contacts.filter((c) => c.orgId !== orgId);

  //   //suspend all membership
  //   const users = [...state.users];
  //   const update = users.filter((i) => i.orgId === orgId);
  //   update.forEach((user) => {
  //     user.status = UserStatus.Suspended;
  //   });

  //   state.users = [...users];

  //   ctx.setState(state);
  //   console.timeEnd("[suspendOrg]");
  // }

  @Receiver()
  @ImmutableContext()
  public static deleteOrg(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<string>
  ) {
    if (!arg || !arg.payload) return;

    console.time("[deleteOrg]");
    const orgId = arg.payload;
    if (!orgId) return;

    const state = ctx.getState();

    //change org status
    const orgIdx = _.findIndex(state.orgs, (i) => i.id === orgId);
    if (orgIdx === -1) return;
    state.orgs[orgIdx].status = EntityStatus.Deleted; //suspended

    //remove ou
    const ous = [...state.ous];
    state.ous = ous.filter((i) => i.orgId !== orgId);

    //remove team
    const teams = [...state.teams];
    state.teams = teams.filter((t) => t.orgId !== orgId);

    //remove channel
    const channels = [...state.channels];
    state.channels = channels.filter((c) => c.orgId !== orgId);

    //remove posts
    const posts = [...state.posts];
    state.posts = posts.filter((p) => p.orgId !== orgId);

    //remove post comments
    const comments = [...state.comments];
    state.comments = comments.filter((c) => c.orgId !== orgId);

    //remove contacts
    const contacts = [...state.contacts];
    state.contacts = contacts.filter((c) => c.orgId !== orgId);

    //remove all membership
    const users = [...state.users];
    state.users = users.filter((c) => c.orgId !== orgId);

    ctx.setState(state);
    console.timeEnd("[deleteOrg]");
  }


  @Receiver()
  @ImmutableContext()
  public static deleteOrgs(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<OrgState[]>
  ) {
    if (!arg || !arg.payload || arg.payload.length == 0) return;
    const state = ctx.getState();
    const deletedOrgs = arg.payload;

    const orgs = [...state.orgs];
    const orgUsers = [...state.users];

    _.forEach(deletedOrgs, (org: OrgState) => {
      _.remove(
        orgs,
        (o: OrgState) =>
          o.id === org.id
      );

      _.remove(
        orgUsers,
        (u: OrgUserState) =>
          u.orgId === org.id
      )
    });
    state.orgs = orgs;
    state.users = orgUsers;

    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  public static switchOrg(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<string>
  ) {
    if (!arg) return;
    console.time("[switchOrg]");
    const state = ctx.getState();
    if (!arg.payload) {
      state.selectedOrg = null;
    } else {
      let toSwitch = _.find(
        state.orgs,
        (i: OrgState) =>
          i.id === arg.payload
      );

      if (!toSwitch) { //find from connected orgs
        toSwitch = _.find(
          state.orgs,
          (i: OrgState) => _.some(i.connectedOrgs, (c) => c === arg.payload)
        )
      }

      if (!toSwitch) return;

      state.selectedOrg = { ...toSwitch };
    }
    ctx.setState(state);
    console.timeEnd("[switchOrg]");
  }

  @Receiver()
  @ImmutableContext()
  public static updateExtTools(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<OrgExtTool>
  ) {
    if (!arg || !arg.payload) return;

    const state = ctx.getState();
    const dto = arg.payload;
    let orgs = _.clone(state.orgs);

    const idx = _.findIndex(orgs, o => o.id === dto.orgId);
    if (idx != -1) {
      let extTools = _.clone(orgs[idx].extTools);
      if (extTools) {
        extTools[dto.appId] = dto.isEnabled;
        orgs[idx].extTools = extTools;
      }
    }

    if (state.selectedOrg && state.selectedOrg.id === dto.orgId) {
      state.selectedOrg.extTools[dto.appId] = dto.isEnabled;
    }

    state.orgs = [...orgs];

    ctx.setState(state);
  }

  private static mutateOrgState(state: OrgState, dto: OrgState): OrgState {
    if (!state || !dto) return state;
    state.connectedOrgs = [...dto.connectedOrgs];
    state.contacts = [...dto.contacts];
    state.ous = [...dto.ous];
    state.number = dto.number;
    state.defaultOu = dto.defaultOu;
    state.email = dto.email;
    state.imageUrl = dto.imageUrl;
    state.industryId = dto.industryId;
    state.industryName = dto.industryName;
    state.mailingAddress = dto.mailingAddress;
    state.name = dto.name;
    state.phoneNumber = dto.phoneNumber;
    state.postalCode = dto.postalCode;
    state.state = dto.state;
    state.status = dto.status;
    state.type = dto.type;
    state.createdBy = dto.createdBy;
    state.joinedOn = dto.joinedOn;
    state.fax = dto.fax;
    state.website = dto.website;
    state.city = dto.city;
    state.country = dto.country;
    state.autoJoinEnabled = dto.autoJoinEnabled;
    state.retentionPeriod = dto.retentionPeriod;
    state.editMsgEnabled = dto.editMsgEnabled;
    state.deleteMsgEnabled = dto.deleteMsgEnabled;
    state.myDriveEnabled = dto.myDriveEnabled;
    state.myDriveEnabledGroup = dto.myDriveEnabledGroup;

    if (dto.extTools) {
      state.extTools = dto.extTools;
    }


    return state;
  }

  //#endregion

  //#region OU State

  @Receiver()
  @ImmutableContext()
  public static addOrUpdateOUs(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<OUState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    const payload = arg.payload.filter((p) =>
      this.enterpriseSelector.canSaveState(p.orgId)
    );
    if (payload.length == 0) return;

    console.time("[addOrUpdateOUs]");
    const state = ctx.getState();
    const all = _.clone(state.ous);

    //new
    const newList = _.differenceBy(payload, all, "id");
    //existing
    const existingList = _.intersectionBy(payload, all, "id");

    //update existing
    existingList.forEach((dto) => {
      let index = _.findIndex(all, (r) => r.id === dto.id);
      if (index !== -1) {
        all[index] = this.mutateOUState(all[index], dto);
      }
    });

    //add new
    if (newList.length > 0) {
      state.ous = [...all, ...newList];

      //add new ou to org
      const orgIds = _.uniq(_.map(newList, (i) => i.orgId));
      const orgs = state.orgs.filter((i) => _.some(orgIds, (o) => o === i.id));
      orgs.forEach((org) => {
        const newOUIds = _.map(
          newList.filter((i) => i.orgId === org.id),
          (i) => i.id
        );
        org.ous = _.uniq([...org.ous, ...newOUIds]);
      });

      //parent ou add child
      const ouIds = _.uniq(
        _.map(
          newList.filter((i) => i != null),
          (i) => i.parentId
        )
      );
      const parents = all.filter((i) => _.some(ouIds, (o) => o === i.parentId));
      parents.forEach((parent) => {
        parent.childs = _.uniq([
          ...parent.childs,
          ..._.map(
            newList.filter((i) => i.parentId === parent.id),
            (i) => i.id
          ),
        ]);
      });
    } else {
      state.ous = [...all];
    }

    ctx.setState(state);
    console.timeEnd("[addOrUpdateOUs]");
  }

  @Receiver()
  @ImmutableContext()
  public static deleteOU(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<OUState>
  ) {
    if (arg.payload == null) return;
    if (!this.enterpriseSelector.canSaveState(arg.payload.orgId)) return;
    const ouId = arg.payload.id;
    if (!ouId) return;

    console.time("[deleteOU]");
    const state = ctx.getState();

    //remove ou
    const ous = [...state.ous];
    state.ous = ous.filter((i) => i.id !== ouId);

    //remove team
    const teams = [...state.teams];
    state.teams = teams.filter((t) => t.ouId !== ouId);

    //remove channel
    const channels = [...state.channels];
    state.channels = channels.filter((c) => c.ouId !== ouId);

    //remove posts
    const posts = [...state.posts];
    state.posts = posts.filter((p) => p.ouId !== ouId);

    //remove post comments
    const comments = [...state.comments];
    state.comments = comments.filter((c) => c.ouId !== ouId);

    //remove inmem posts and comments
    this.inMemPostSelector.deleteOUPostsAndComments.emit(ouId);

    //remove org's ou
    const idx = _.findIndex(state.orgs, (t) =>
      _.some(t.ous, (c) => c === ouId)
    );
    if (idx !== -1) {
      const org = { ...state.orgs[idx] };
      state.orgs[idx] = {
        ...org,
        ous: org.ous.filter((c) => c !== ouId),
      };
    }

    ctx.setState(state);
    console.timeEnd("[deleteOU]");
  }

  private static mutateOUState(state: OUState, dto: OUState): OUState {
    if (!state || !dto) return state;
    state.childs = [...dto.childs];
    state.imageUrl = dto.imageUrl;
    state.name = dto.name;
    state.status = dto.status;
    state.createdBy = dto.createdBy;
    state.teams = [...dto.teams];
    return state;
  }
  //#endregion

  //#region Team state
  @Receiver()
  @ImmutableContext()
  public static addOrUpdateTeams(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<TeamState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    const payload = arg.payload.filter((p) =>
      this.enterpriseSelector.canSaveState(p.orgId)
    );
    if (payload.length == 0) return;

    console.time("[addOrUpdateTeams]");
    const state = ctx.getState();
    const all = _.clone(state.teams);

    //new
    const newList: TeamState[] = _.differenceBy(payload, all, "id");
    //existing
    const existingList: TeamState[] = _.intersectionBy(payload, all, "id");

    //update existing
    existingList.forEach((dto) => {
      let index = _.findIndex(all, (r) => r.id === dto.id);
      if (index !== -1) {
        all[index] = this.mutateTeamState(all[index], dto);
      }

      let channels = state.channels.filter(
        (c) =>
          c.teamId === dto.id &&
          (c.canCreatePost != dto.canCreatePost ||
            c.canCreatePostComment != dto.canCreatePostComment)
      );
      channels.forEach((c) => {
        c.canCreatePost = dto.canCreatePost;
        c.canCreatePostComment = dto.canCreatePostComment;
      });
      let posts = state.posts.filter(
        (c) =>
          c.teamId === dto.id &&
          c.canCreatePostComment != dto.canCreatePostComment
      );

      posts.forEach((p) => (p.canCreatePostComment = dto.canCreatePostComment));
    });

    //add new
    if (newList.length > 0) {
      var currentOrgUser = state.users.filter(
        (u) => u.orgId == state.selectedOrg.id
      );
      //add implicit team members
      // newList.forEach((team) => {
      //   if (team.implicitMembers && team.implicitMembers.length > 0) {
      //     var memberIds: string[] = _.uniq(
      //       _.flatten(
      //         team.implicitMembers.map((m) =>
      //           this.getTeamMembersFromImplicit(
      //             m,
      //             state.ous.find(o => o.id == team.ouId),
      //             currentOrgUser,
      //             state.selectedOrg.type == OrgType.Personal
      //           ).map((o) => o.userId)
      //         )
      //       )
      //     );

      //     if (memberIds && memberIds.length != 0) {
      //       var implicitMemberIds = memberIds.filter(
      //         (id) =>
      //           !team.members.includes(id) &&
      //           state.teamMembers.findIndex(
      //             (m) => m.teamId == team.id && m.userId == id
      //           ) == -1
      //       );
      //       var implicitMembers = implicitMemberIds.map((id) => {
      //         var teamMember = new TeamMemberState();
      //         teamMember.orgId = team.orgId;
      //         teamMember.ouId = team.ouId;
      //         teamMember.teamId = team.id;
      //         teamMember.type = TeamMemberType.Implicit;
      //         teamMember.userId = id;
      //         return teamMember;
      //       });
      //       team.members = [...team.members, ...implicitMemberIds];
      //       state.teamMembers = [...state.teamMembers, ...implicitMembers];

      //     }
      //   }
      // });
      state.teams = [...all, ...newList];

      const ouIds = _.uniq(_.map(newList, (i) => i.ouId));
      const ous = state.ous.filter((i) => _.some(ouIds, (o) => o === i.id));
      //add new team to ou
      ous.forEach((ou) => {
        const newTeamIds = _.map(
          newList.filter((i) => i.ouId === ou.id),
          (i) => i.id
        );
        ou.teams = _.uniq([...ou.teams, ...newTeamIds]);
      });
    } else {
      state.teams = [...all];
    }

    ctx.setState(state);
    console.timeEnd("[addOrUpdateTeams]");
  }

  private static getTeamMembersFromImplicit(implicitType: string, ou: OUState, currentOrgUsers: OrgUserState[], isPersonal: boolean = false): OrgUserState[] {
    if (ou == null && !isPersonal) return [];
    if (currentOrgUsers == null || currentOrgUsers.length == 0) return [];
    var currentOuUsers = isPersonal
      ? currentOrgUsers
      : currentOrgUsers.filter((o) => o.ouId == ou.id);

    switch (implicitType) {
      case BuiltInUser[BuiltInUser.CLIENTS]:
        return currentOuUsers.filter(
          (o) => o.role == RoleEntity[RoleEntity.CLIENT]
        );
      case BuiltInUser[BuiltInUser.PARTNERS]:
        return currentOuUsers.filter(
          (o) => o.role == RoleEntity[RoleEntity.PARTNER]
        );
      case BuiltInUser[BuiltInUser.COWORKERS]:
        return currentOuUsers.filter(
          (o) =>
            o.role != RoleEntity[RoleEntity.CLIENT] &&
            o.role != RoleEntity[RoleEntity.PARTNER]
        );
      case BuiltInUser[BuiltInUser.MEMBERS]:
        return currentOuUsers;
      case BuiltInUser[BuiltInUser.SUBOUMEMBERS]: {
        if (isPersonal) return currentOrgUsers;
        return currentOrgUsers.filter(
          (o) => ou.childs.includes(o.ouId) || o.ouId == ou.id
        );
      }
      default:
        return [];
    }
  }

  @Receiver()
  @ImmutableContext()
  public static deleteTeam(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<TeamState>
  ) {
    if (arg.payload == null) return;
    if (!this.enterpriseSelector.canSaveState(arg.payload.orgId)) return;
    const teamId = arg.payload.id;
    if (!teamId) return;

    console.time("[deleteTeam]");
    const state = ctx.getState();

    //remove team
    const teams = [...state.teams];
    state.teams = teams.filter((t) => t.id !== teamId);

    //remove channel
    const channels = [...state.channels];
    state.channels = channels.filter((c) => c.teamId !== teamId);

    //remove posts
    const posts = [...state.posts];
    state.posts = posts.filter((p) => p.teamId !== teamId);

    //remove post comments
    const comments = [...state.comments];
    state.comments = comments.filter((c) => c.teamId !== teamId);

    //remove team members
    const members = [...state.teamMembers];
    state.teamMembers = members.filter((c) => c.teamId !== teamId);

    //remove in mem posts and comments
    this.inMemPostSelector.deleteTeamPostsAndComments.emit(teamId);

    //remove ou's team
    const idx = _.findIndex(state.ous, (t) =>
      _.some(t.teams, (c) => c === teamId)
    );
    if (idx !== -1) {
      const ou = { ...state.ous[idx] };
      state.ous[idx] = {
        ...ou,
        teams: ou.teams.filter((c) => c !== teamId),
      };
    }

    ctx.setState(state);
    console.timeEnd("[deleteTeam]");
  }

  private static mutateTeamState(state: TeamState, dto: TeamState): TeamState {
    if (!state || !dto) return state;

    state.canCreateChannel = dto.canCreateChannel;
    state.canCreatePost = dto.canCreatePost;
    state.canCreatePostComment = dto.canCreatePostComment;
    state.imageUrl = dto.imageUrl;
    state.isDefault = dto.isDefault;
    state.name = dto.name;
    state.status = dto.status;
    state.type = dto.type;
    state.memberVisibility = dto.memberVisibility;
    state.enableImplicitOwner = dto.enableImplicitOwner;
    state.enableImplicitAdmins = dto.enableImplicitAdmins;
    state.enableImplicitCoworkers = dto.enableImplicitCoworkers;
    state.enableImplicitClients = dto.enableImplicitClients;
    state.enableImplicitPartners = dto.enableImplicitPartners;
    state.enableImplicitSubOuMembers = dto.enableImplicitSubOuMembers;

    return state;
  }

  //#endregion

  //#region  Team Member State
  @Receiver()
  @ImmutableContext()
  public static addOrUpdateTeamMembers(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<TeamMemberState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    const payload = arg.payload.filter((p) =>
      this.enterpriseSelector.canSaveState(p.orgId)
    );
    if (payload.length == 0) return;

    console.time("[addOrUpdateTeamMembers]");
    const state = ctx.getState();
    const all: TeamMemberState[] = _.clone(state.teamMembers);
    const membersPayload = payload;

    //new
    const newList: TeamMemberState[] = _.differenceWith(
      membersPayload,
      all,
      (a: TeamMemberState, r: TeamMemberState) =>
        r.teamId === a.teamId && r.userId === a.userId
    );

    //existing
    const existingList: TeamMemberState[] = _.intersectionWith(
      membersPayload,
      all,
      (a: TeamMemberState, r: TeamMemberState) =>
        r.teamId === a.teamId && r.userId === a.userId
    );

    //update existing
    existingList.forEach((dto) => {
      let index = _.findIndex(
        all,
        (r) => r.teamId === dto.teamId && r.userId === dto.userId
      );
      if (index !== -1) {
        all[index] = this.mutateTeamMemberState(all[index], dto);
      }
    });

    //add new
    if (newList.length > 0) {
      state.teamMembers = [...all, ...newList];

      const teamIds = _.uniq(_.map(newList, (i) => i.teamId));
      const teams = state.teams.filter((i) =>
        _.some(teamIds, (o) => o === i.id)
      );
      //add new ou to org
      teams.forEach((team) => {
        team.members = _.uniq([
          ...team.members,
          ..._.map(
            newList.filter((i) => i.teamId === team.id),
            (i) => i.userId
          ),
        ]);
      });
    } else {
      state.teamMembers = [...all];
    }

    ctx.setState(state);
    console.timeEnd("[addOrUpdateTeamMembers]");
  }

  private static mutateTeamMemberState(
    state: TeamMemberState,
    dto: TeamMemberState
  ): TeamMemberState {
    if (!state || !dto) return state;

    state.role = dto.role;
    state.isImplicit = dto.isImplicit;
    state.implicitRole = dto.implicitRole;
    //state.type = dto.type;

    return state;
  }

  @Receiver()
  @ImmutableContext()
  public static deleteTeamMembers(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<TeamMemberState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    const payload = arg.payload.filter((p) =>
      this.enterpriseSelector.canSaveState(p.orgId)
    );
    if (payload.length == 0) return;

    console.time("[deleteTeamMember]");
    const state = ctx.getState();
    const membersPayload = payload;

    //remove team members
    const members = [...state.teamMembers];
    _.forEach(membersPayload, (member: TeamMemberState) => {
      _.remove(
        members,
        (t: TeamMemberState) =>
          t.teamId === member.teamId && t.userId === member.userId
      );
    });
    state.teamMembers = members;

    //remove team's member
    const teamIds = _.uniq(_.map(membersPayload, (i) => i.teamId));
    const teams = state.teams.filter((i) => _.some(teamIds, (o) => o === i.id));
    //add new ou to org
    teams.forEach((team) => {
      team.members = _.uniq([
        ...team.members,
        ..._.map(
          members.filter((i) => i.teamId === team.id),
          (i) => i.userId
        ),
      ]);
    });

    ctx.setState(state);
    console.timeEnd("[deleteTeamMember]");
  }
  //#endregion

  //#region Channel state

  @Receiver()
  @ImmutableContext()
  public static addOrUpdateChannels(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<ChannelState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    const payload = arg.payload.filter((p) =>
      this.enterpriseSelector.canSaveState(p.orgId)
    );
    if (payload.length == 0) return;

    console.time("[addOrUpdateChannels]");
    const state = ctx.getState();
    const all = _.clone(state.channels);

    //new
    const newList: ChannelState[] = _.differenceBy(payload, all, "id");
    //existing
    const existingList: ChannelState[] = _.intersectionBy(
      payload,
      all,
      "id"
    );

    //update existing
    existingList.forEach((dto) => {
      let index = _.findIndex(all, (r) => r.id === dto.id);
      if (index !== -1) {
        all[index] = this.mutateChannelState(all[index], dto);
      }
    });

    //add new
    if (newList.length > 0) {
      state.channels = [...state.channels, ...newList];
      const teamIds = _.uniq(_.map(newList, (i) => i.teamId));
      const teams = state.teams.filter((i) =>
        _.some(teamIds, (o) => o === i.id)
      );
      //add new ch to team
      teams.forEach((team) => {
        let channelIds = _.uniq([
          ...team.channels,
          ..._.map(
            newList.filter((i) => i.teamId === team.id),
            (i) => i.id
          ),
        ]);
        team.channels = [...channelIds];
      });
    } else {
      state.channels = [...all];
    }

    ctx.setState(state);
    console.timeEnd("[addOrUpdateChannels]");
  }

  @Receiver()
  @ImmutableContext()
  public static deleteChannel(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<ChannelState>
  ) {
    if (arg.payload == null) return;
    if (!this.enterpriseSelector.canSaveState(arg.payload.orgId)) return;
    const chId = arg.payload.id;
    if (!chId) return;
    console.time("[deleteChannel]");
    const state = ctx.getState();

    //remove channel
    const channels = _.clone(state.channels);
    state.channels = channels.filter((c) => c.id !== chId);

    //remove posts
    const posts = _.clone(state.posts);
    state.posts = posts.filter((p) => p.channelId !== chId);

    //remove post comments
    const comments = _.clone(state.comments);
    state.comments = comments.filter((c) => c.channelId !== chId);

    //remove in mem posts and comments
    this.inMemPostSelector.deleteChPostsAndComments.emit(chId);

    //remove team's channel
    const teamIdx = _.findIndex(state.teams, (t) =>
      _.some(t.channels, (c) => c === chId)
    );
    if (teamIdx !== -1) {
      const team = { ...state.teams[teamIdx] };
      state.teams[teamIdx] = {
        ...team,
        channels: team.channels.filter((c) => c !== chId),
      };
    }

    //remove channel unread
    // let unreads = new StringDictionary({ ...state.unreads });
    // unreads.remove(chId);
    // state.unreads = unreads.toDictionary();

    ctx.setState(state);
    console.timeEnd("[deleteChannel]");
  }

  @Receiver()
  @ImmutableContext()
  public static addEnterpriseUnreads(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<UnreadDto[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;

    const allUnreads = arg.payload;
    const state = ctx.getState();

    let chUnreads = new StringDictionary({ ...state.chUnreads });
    let postUnreads = new StringDictionary({ ...state.postUnreads });
    let sharedUnreads = new StringDictionary({ ...state.sharedUnreads });

    allUnreads.forEach(unread => {
      switch (unread.type) {
        case UnreadType.CHANNEL:
          chUnreads.addValues(unread.threadId, unread.unreadIds);
          break;
        case UnreadType.POST:
          postUnreads.addValues(unread.threadId, unread.unreadIds);
          break;
        case UnreadType.SHARED:
          sharedUnreads.addValues(unread.threadId, unread.unreadIds);
          break;

      }
    });

    state.chUnreads = chUnreads.toDictionary();
    state.postUnreads = postUnreads.toDictionary();
    state.sharedUnreads = sharedUnreads.toDictionary();

    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  public static addEnterpriseUnread(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<UnreadDto>
  ) {
    if (!arg.payload) return;

    const unreadDto = arg.payload;
    const state = ctx.getState();

    switch (unreadDto.type) {
      case UnreadType.CHANNEL:
        let chUnreads = new StringDictionary({ ...state.chUnreads });
        chUnreads.addValues(unreadDto.threadId, unreadDto.unreadIds);
        state.chUnreads = chUnreads.toDictionary();
        break;
      case UnreadType.POST:
        let postUnreads = new StringDictionary({ ...state.postUnreads });
        postUnreads.addValues(unreadDto.threadId, unreadDto.unreadIds);
        state.postUnreads = postUnreads.toDictionary();
        break;
      case UnreadType.SHARED:
        let sharedUnreads = new StringDictionary({ ...state.sharedUnreads });
        sharedUnreads.addValues(unreadDto.threadId, unreadDto.unreadIds);
        state.sharedUnreads = sharedUnreads.toDictionary();
        break;
    }

    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  public static clearEnterpriseUnread(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<ClearUnreadDto>
  ) {
    if (!arg.payload) return;

    const unreadDto = arg.payload;
    const state = ctx.getState();

    switch (unreadDto.type) {
      case UnreadType.CHANNEL:
        let chUnreads = new StringDictionary({ ...state.chUnreads });
        chUnreads.remove(unreadDto.threadId);
        state.chUnreads = chUnreads.toDictionary();
        break;
      case UnreadType.POST:
        let postUnreads = new StringDictionary({ ...state.postUnreads });
        postUnreads.remove(unreadDto.threadId);
        state.postUnreads = postUnreads.toDictionary();
        break;
      case UnreadType.SHARED:
        let sharedUnreads = new StringDictionary({ ...state.sharedUnreads });
        sharedUnreads.remove(unreadDto.threadId);
        state.sharedUnreads = sharedUnreads.toDictionary();
        break;
    }

    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  public static addPendingUnreadsToClear(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<ClearUnreadDto>
  ) {
    if (!arg.payload) return;

    const unreadDto = arg.payload;
    const state = ctx.getState();

    state.pendingUnreadsToClear = _.uniq([...state.pendingUnreadsToClear, unreadDto]);

    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  public static removePendingUnreadsToClear(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<null>
  ) {
    const state = ctx.getState();

    state.pendingUnreadsToClear = [];

    ctx.setState(state);
  }


  private static mutateChannelState(
    state: ChannelState,
    dto: ChannelState
  ): ChannelState {
    if (!state || !dto) return state;

    state.canCreatePost = dto.canCreatePost;
    state.canCreatePostComment = dto.canCreatePostComment;
    state.name = dto.name;
    state.participants = _.uniq([...dto.participants]);
    state.totalPosts = dto.totalPosts ? dto.totalPosts : state.totalPosts;
    state.totalFiles = dto.totalFiles ? dto.totalFiles : state.totalFiles;
    state.isPostHistoryLoaded = dto.isPostHistoryLoaded;

    return state;
  }
  //#endregion

  //#region Post state
  @Receiver()
  @ImmutableContext()
  public static addOrUpdatePosts(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<PostState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    const payload = arg.payload.filter((p) =>
      this.enterpriseSelector.canSaveState(p.orgId)
    );
    if (payload.length == 0) return;
    console.time("[addOrUpdatePosts in state]");
    const state = ctx.getState();
    const all: PostState[] = _.clone(state.posts);

    const updatedPosts = payload;

    //new
    const newList: PostState[] = _.differenceWith(
      updatedPosts,
      all,
      (a: PostState, r: PostState) => r.id === a.id || (a.tempId && r.tempId && a.tempId === r.tempId)
    );

    //existing
    const existingList: PostState[] = _.intersectionWith(
      updatedPosts,
      all,
      (a: PostState, r: PostState) => r.id === a.id || (a.tempId && r.tempId && a.tempId === r.tempId)
    );

    //update existing
    existingList.forEach((dto) => {
      let index = _.findIndex(
        all,
        (r) => r.id === dto.id || (dto.tempId && r.tempId && dto.tempId === r.tempId)
      );
      if (index !== -1) {
        all[index] = this.mutatePostState(all[index], dto);
      }
    });

    //add new
    if (newList.length > 0) {
      state.posts = EnterpriseState.sliceLatestPosts([...all, ...newList]);
      // ctx.setState(state);
    } else {
      state.posts = EnterpriseState.sliceLatestPosts([...all]);
      // ctx.setState(state);
    }

    const channelIds = _.uniq(_.map(updatedPosts, (i) => i.channelId));
    this.updateChannelLastActivities(state, channelIds);
    ctx.setState(state);


    // //update post to in-memory state
    // this.inMemPostSelector.addOrUpdatePosts.emit(arg.payload);

    console.timeEnd("[addOrUpdatePosts in state]");
  }

  @Receiver()
  @ImmutableContext()
  public static deletePost(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<PostState>
  ) {
    if (arg.payload == null) return;
    if (!this.enterpriseSelector.canSaveState(arg.payload.orgId)) return;
    const postId = arg.payload.id;
    if (!postId) return;

    console.time("[deletePost]");

    const state = ctx.getState();
    //remove post
    const posts = EnterpriseState.getAllPosts(state);
    const post: PostState = _.find(posts, (p) => p.id === postId);
    if (!post) return;

    // let unreads = new StringDictionary({ ...state.unreads });
    // unreads.remove(postId);

    if (post.isOffline) {
      console.log("delete from post state");
      const offlinePosts = _.clone(state.posts);
      state.posts = offlinePosts.filter((p) => p.id != postId);
    } else {
      //update post to in-memory state
      console.log("delete from in mem post");
      this.inMemPostSelector.deletePost.emit(arg.payload);
    }

    state.posts = posts.filter((p) => p.id !== postId);

    //remove post comments
    const comments = EnterpriseState.getAllComments(state);
    state.comments = EnterpriseState.sliceLatestPostComments(comments.filter((c) => c.postId !== postId));
    //remove channel posts

    // if (unreads.containsKey(post.channelId)) {
    //   unreads.removeValue(post.channelId, postId);
    //   const commentIds: string[] = _.map(
    //     comments.filter((c) => c.postId === postId),
    //     (c) => c.id
    //   );
    //   unreads.removeValues(post.channelId, commentIds);
    // }

    // state.unreads = unreads.toDictionary();
    ctx.setState(state);

    //update post to in-memory state
    //this.inMemPostSelector.deletePost.emit(arg.payload);

    console.timeEnd("[deletePost]");
  }

  private static mutatePostState(state: PostState, dto: PostState): PostState {
    if (!state || !dto) return state;

    state.canCreatePostComment = dto.canCreatePostComment;
    state.id = dto.id;
    state.channelId = dto.channelId;
    state.teamId = dto.teamId;
    state.comments = [...dto.comments];
    state.content = dto.content ?? "";
    state.fwt = dto.fwt;
    state.status = dto.status;
    state.totalComments = dto.totalComments;
    state.totalFile = dto.totalFile;
    state.sendStatus = dto.sendStatus;
    state.sendAttempt = dto.sendAttempt ? dto.sendAttempt : state.sendAttempt;

    if (!state.mediaId && dto.fwt) {
      state.mediaId = ShortGuid.New();
    }
    state.mediaName = dto.mediaName;

    state.isImage = dto.isImage;
    state.isFile = dto.isFile;
    state.type = dto.type;

    state.isCommentHistoryLoaded = dto.isCommentHistoryLoaded;

    return state;
  }

  // Slice the latest N elements and save the older to in-memory state
  private static sliceLatestPosts(posts: PostState[]) {
    var sorted = _.orderBy(posts, ["createdOn"], ["desc"]);
    var latestRecords: PostState[] = _.take(sorted, EnterpriseState.MAX_OFFLINE_RECORDS);

    var slicedOlderRecords: PostState[] = _.slice(sorted, EnterpriseState.MAX_OFFLINE_RECORDS);

    if (slicedOlderRecords && slicedOlderRecords.length > 0) {
      this.inMemPostSelector.addOrUpdatePosts.emit(slicedOlderRecords);
    }

    return latestRecords;
  }

  private static sliceLatestPostComments(comments: PostCommentState[]) {
    var sorted = _.orderBy(comments, ["createdOn"], ["desc"]);
    var latestRecords: PostCommentState[] = _.take(sorted, EnterpriseState.MAX_OFFLINE_RECORDS);

    var slicedOlderRecords: PostCommentState[] = _.slice(sorted, EnterpriseState.MAX_OFFLINE_RECORDS);

    if (slicedOlderRecords && slicedOlderRecords.length > 0) {
      this.inMemPostSelector.addOrUpdateComments.emit(slicedOlderRecords);
    }

    return latestRecords;
  }

  private static getAllPosts(state: EnterpriseStateModel): PostState[] {
    const offline: PostState[] = [...state.posts];

    offline.forEach(post => {
      post.isOffline = true;
    });

    const inmem: PostState[] = this.inMemPostSelector.getAllPosts();

    inmem.forEach(post => {
      post.isOffline = false;
    });

    return [...(offline ? offline : []), ...(inmem ? inmem : [])];
  }

  private static getAllComments(state: EnterpriseStateModel): PostCommentState[] {
    const offline: PostCommentState[] = [...state.comments];
    offline.forEach(comment => {
      comment.isOffline = true;
    });

    const inmem: PostCommentState[] = this.inMemPostSelector.getAllComments();
    inmem.forEach(comment => {
      comment.isOffline = false;
    });

    return [...(offline ? offline : []), ...(inmem ? inmem : [])];
  }

  //#endregion

  //#region Post comment state

  @Receiver()
  @ImmutableContext()
  public static addOrUpdateComments(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<PostCommentState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    const payload = arg.payload.filter((p) =>
      this.enterpriseSelector.canSaveState(p.orgId)
    );
    if (payload.length == 0) return;
    console.time("[addOrUpdateComments]");
    const state = ctx.getState();
    const all = _.clone(state.comments);

    //new
    const newList: PostCommentState[] = _.differenceWith(
      payload,
      all,
      (a: PostCommentState, r: PostCommentState) =>
        r.id === a.id || (a.tempId && r.tempId && a.tempId === r.tempId)
    );

    //existing
    const existingList: PostCommentState[] = _.intersectionWith(
      payload,
      all,
      (a: PostCommentState, r: PostCommentState) =>
        r.id === a.id || (a.tempId && r.tempId && a.tempId === r.tempId)
    );

    //update existing
    existingList.forEach((dto) => {
      let index = _.findIndex(
        all,
        (r) => r.id === dto.id || (dto.tempId && r.tempId && dto.tempId === r.tempId)
      );
      if (index !== -1) {
        all[index] = this.mutateCommentState(all[index], dto);
      }
    });

    //calculate total comments and files in post

    const postIds = _.uniq(_.map(existingList, (i) => i.postId));
    const posts = state.posts.filter((i) =>
      contains(postIds, (o) => o === i.id)
    );
    posts.forEach((post) => {
      post.totalComments = state.comments.filter(
        (r) => r.postId == post.id && r.status == EntityStatus.Active
      ).length;
      post.totalFile = state.comments.filter(
        (r) =>
          r.postId == post.id &&
          r.status == EntityStatus.Active &&
          (r.type == CommentType.File || r.type == CommentType.Image)
      ).length;

      //if post have media then include 1 file
      if (!!post.mediaId) {
        post.totalFile = post.totalFile ? post.totalFile++ : 1;
      }
    });

    //add new
    const currentUserId = EnterpriseState.userSelector.userId;
    if (newList.length > 0) {
      const postIds = _.uniq(_.map(newList, (i) => i.postId));
      const posts = state.posts.filter((i) =>
        _.some(postIds, (o) => o === i.id)
      );
      //add new comments to post
      posts.forEach((post) => {
        post.comments = _.uniq([
          ...post.comments,
          ..._.map(
            newList.filter((i) => i.postId === post.id),
            (i) => i.id
          ),
        ]);

        let newComments = newList.filter(
          (i) => i.postId === post.id && i.status == EntityStatus.Active
        ).length;

        //recalc total comments
        post.totalComments = post.totalComments
          ? post.totalComments + newComments
          : newComments;
        let newFileComments = newList.filter(
          (i) =>
            i.postId === post.id &&
            i.status == EntityStatus.Active &&
            i.type == CommentType.File
        ).length;
        //recalc total file
        post.totalFile = post.totalFile
          ? post.totalFile + newFileComments
          : newFileComments;
      })
      state.comments = EnterpriseState.sliceLatestPostComments([...all, ...newList]);;
      // ctx.setState(state);
    } else {
      state.comments = EnterpriseState.sliceLatestPostComments([...all]);
      // ctx.setState(state);
    }

    //recalculate channel files
    const channelIds = _.uniq(_.map(arg.payload, (i) => i.channelId));
    this.updateChannelLastActivities(state, channelIds);

    ctx.setState(state);

    // //update comment to in-memory state
    // this.inMemPostSelector.addOrUpdateComments.emit(payload);

    console.timeEnd("[addOrUpdateComments]");
  }

  @Receiver()
  @ImmutableContext()
  public static deleteComment(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<PostCommentState>
  ) {
    if (arg.payload == null) return;
    if (!this.enterpriseSelector.canSaveState(arg.payload.orgId)) return;
    const commentId = arg.payload.id;
    if (!commentId) return;

    console.time("[deleteComment]");
    const state = ctx.getState();
    const comments = EnterpriseState.getAllComments(state);
    const comment: PostCommentState = _.find(
      comments,
      (c) => c.id === commentId
    );
    if (!comment) return;

    if (comment.isOffline) {
      console.log("delete offline comment");
      const offlineComments = _.clone(state.comments);
      state.comments = offlineComments.filter((p) => p.id != commentId);
    } else {
      //update comment to in-memory state
      console.log("delete in mem comment");
      this.inMemPostSelector.deleteComment.emit(comment);
    }

    //remove post's comments
    const allPosts = EnterpriseState.getAllPosts(state)
    const idx = _.findIndex(allPosts, (t) =>
      _.some(t.comments, (p) => p === commentId)
    );
    if (idx !== -1) {
      const post = allPosts[idx];
      post.comments = post.comments.filter((p) => p !== commentId);
      post.totalComments = post.totalComments ? post.totalComments-- : 0;

      if (
        comment &&
        (comment.type == CommentType.File || comment.type == CommentType.Image)
      ) {
        post.totalFile = post.totalFile ? post.totalFile-- : 0;
        let channel: ChannelState = _.find(
          state.channels,
          (c) => c.id === post.channelId
        );
      }

      if (post.isOffline) {
        const offlinePosts = _.clone(state.posts);
        const idxPost = _.findIndex(offlinePosts, (c) => c.id === comment.postId);
        if (idxPost !== -1) state.posts[idxPost] = post;
      } else {
        this.inMemPostSelector.addOrUpdatePosts.emit([post]);
      }
    }

    // let unreads = new StringDictionary({ ...state.unreads });

    // unreads.removeValue(comment.postId, commentId);
    // unreads.removeValue(comment.channelId, commentId);

    // state.unreads = unreads.toDictionary();

    ctx.setState(state);

    //update comment to in-memory state
    // this.inMemPostSelector.deleteComment.emit(arg.payload);

    console.timeEnd("[deleteComment]");
  }

  private static mutateCommentState(
    state: PostCommentState,
    dto: PostCommentState
  ): PostCommentState {
    if (!state || !dto) return state;

    state.content = dto.content;
    state.id = dto.id;
    state.fwt = dto.fwt;
    state.status = dto.status;
    state.type = dto.type;
    state.sendStatus = dto.sendStatus;
    state.sendAttempt = dto.sendAttempt ? dto.sendAttempt : state.sendAttempt;
    if (!state.mediaId && dto.fwt) {
      state.mediaId = ShortGuid.New();
    }

    state.senderAvatar = dto.senderAvatar;
    state.senderName = dto.senderName;
    state.postId = dto.postId;

    return state;
  }

  //#endregion

  //#region Contact state
  @Receiver()
  @ImmutableContext()
  public static addOrUpdateContacts(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<ContactState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    const payload = arg.payload.filter((p) =>
      this.enterpriseSelector.canSaveState(p.orgId)
    );
    if (payload.length == 0) return;

    console.time("[addOrUpdateContacts]");
    const state = ctx.getState();
    const all = _.clone(state.contacts);

    //new
    const newList = _.differenceBy(payload, all, "id");

    //existing
    const existingList = _.intersectionBy(payload, all, "id");

    //update existing
    existingList.forEach((dto) => {
      let index = _.findIndex(all, (r) => r.id === dto.id);
      if (index !== -1) {
        all[index] = this.mutateContactState(all[index], dto);
      }
    });

    //add new
    if (newList.length > 0) {
      state.contacts = [...all, ...newList];
      const orgIds = _.uniq(_.map(newList, (i) => i.orgId));
      const orgs = state.orgs.filter((i) =>
        contains(orgIds, (o) => o === i.id)
      );
      //add new contacts to org
      orgs.forEach((org) => {
        org.contacts = _.uniq([
          ...org.contacts,
          ..._.map(
            newList.filter((i) => i.orgId === org.id),
            (i) => i.id
          ),
        ]);
      });
    } else {
      state.contacts = [...all];
    }

    ctx.setState(state);
    console.timeEnd("[addOrUpdateContacts]");
  }

  @Receiver()
  @ImmutableContext()
  public static addOrUpdateRoles(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<OrgRoleState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    const payload = arg.payload.filter((p) =>
      this.enterpriseSelector.canSaveState(p.orgId)
    );
    if (payload.length == 0) return;

    console.time("[addOrUpdateRoles]");
    const state = ctx.getState();
    const all = _.clone(state.roles);

    //new
    const newList = _.differenceWith(
      payload,
      all,
      (a: OrgRoleState, r: OrgRoleState) => r.orgId === a.orgId && r.id === a.id
    );

    //existing
    const existingList = _.intersectionWith(
      payload,
      all,
      (a: OrgRoleState, r: OrgRoleState) => r.orgId === a.orgId && r.id === a.id
    );

    //update existing
    existingList.forEach((dto) => {
      let index = _.findIndex(
        all,
        (r) => r.id === dto.id && r.orgId === dto.orgId
      );
      if (index !== -1) {
        all[index].label = dto.label;
      }
    });

    //add new
    if (newList.length > 0) {
      state.roles = [...all, ...newList];
    } else {
      state.roles = [...all];
    }

    ctx.setState(state);
    console.timeEnd("[addOrUpdateRoles]");
  }

  @Receiver()
  @ImmutableContext()
  public static addOrUpdatePermissions(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<OrgPermissionState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    console.time("[addOrUpdatePermissions]");
    const state = ctx.getState();
    const all: OrgPermissionState[] = _.clone(state.permissions);

    //new
    const newList: OrgPermissionState[] = _.differenceWith(
      arg.payload,
      all,
      (a: OrgPermissionState, r: OrgPermissionState) =>
        r.orgId === a.orgId
    );

    //existing
    const existingList: OrgPermissionState[] = _.intersectionWith(
      arg.payload,
      all,
      (a: OrgPermissionState, r: OrgPermissionState) =>
        r.orgId === a.orgId
    );

    //update existing
    existingList.forEach((dto) => {
      let index = all.findIndex(
        (r) => r.orgId === dto.orgId
      );
      if (index !== -1) {
        all[index].permissions = dto.permissions;
      }
    });

    //add new
    if (newList.length > 0) {
      state.permissions = [...all, ...newList];
    } else {
      state.permissions = [...all];
    }

    ctx.setState(state);
    console.timeEnd("[addOrUpdatePermissions]");
  }

  @Receiver()
  @ImmutableContext()
  public static deleteContact(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<ContactState>
  ) {
    if (arg.payload == null) return;
    if (!this.enterpriseSelector.canSaveState(arg.payload.orgId)) return;
    const contactId = arg.payload.id;
    if (!contactId) return;
    console.time("[deleteContact]");
    const state = ctx.getState();
    const contacts: ContactState[] = _.clone(state.contacts);

    state.contacts = contacts.filter((p) => p.id != contactId);

    //remove org's contacts
    const idx = _.findIndex(state.orgs, (c) =>
      _.some(c.contacts, (p) => p === contactId)
    );
    if (idx !== -1) {
      const org = state.orgs[idx];

      state.orgs[idx] = {
        ...org,
        contacts: [...org.contacts.filter((p) => p !== contactId)],
      };
    }

    ctx.setState(state);
    console.timeEnd("[deleteContact]");
  }

  @Receiver()
  @ImmutableContext()
  public static deleteContacts(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<ContactState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    const payload = arg.payload.filter((p) =>
      this.enterpriseSelector.canSaveState(p.orgId)
    );
    if (payload.length == 0) return;

    console.time("[deleteContacts]");
    const state = ctx.getState();
    const contacts: ContactState[] = _.clone(state.contacts);
    state.contacts = contacts.filter((c) => _.some(payload, (p: ContactState) => p.id !== c.id));
    //remove org's contacts
    const idx = _.findIndex(state.orgs, (c: OrgState) =>
      c.id == payload[0].orgId
    );
    if (idx !== -1) {
      const org = state.orgs[idx];
      state.orgs[idx] = {
        ...org,
        contacts: [...org.contacts.filter((c) => _.some(payload, (p: ContactState) => p.id !== c))],
      };
    }
    ctx.setState(state);
    console.timeEnd("[deleteContacts]");
  }

  private static mutateContactState(
    state: ContactState,
    dto: ContactState
  ): ContactState {
    if (!state || !dto) return state;

    state.addresses = [...dto.addresses];
    state.emails = [...dto.emails];
    state.firstName = dto.firstName;
    state.imageUrl = dto.imageUrl;
    state.lastName = dto.lastName;
    state.ownerId = dto.ownerId;
    state.phones = [...dto.phones];
    state.position = dto.position;
    state.orgName = dto.orgName;
    state.status = dto.status;
    state.type = dto.type;
    state.userId = dto.userId;
    state.note = dto.note;

    return state;
  }

  //#endregion

  //#region Payment Methods

  @Receiver()
  @ImmutableContext()
  public static addOrUpdatePaymentMethods(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<PaymentMethodState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    console.time("[addOrUpdatePaymentMethods]");
    const state = ctx.getState();
    const all: PaymentMethodState[] = _.clone(state.paymentMethods);

    //new
    const newList: PaymentMethodState[] = _.differenceWith(
      arg.payload,
      all,
      (a: PaymentMethodState, r: PaymentMethodState) =>
        r.orgId === a.orgId && r.id === a.id
    );

    //existing
    const existingList: PaymentMethodState[] = _.intersectionWith(
      arg.payload,
      all,
      (a: PaymentMethodState, r: PaymentMethodState) =>
        r.orgId === a.orgId && r.id === a.id
    );

    //update existing
    existingList.forEach((dto) => {
      let index = _.findIndex(
        all,
        (r) => r.orgId === dto.orgId && r.id === dto.id
      );
      if (index !== -1) {
        all[index] = { ...dto };
      }
    });

    //add new
    if (newList.length > 0) {
      state.paymentMethods = [...all, ...newList];

      const orgIds = _.uniq(_.map(newList, (i) => i.orgId));
      const orgs = state.orgs.filter((i) => _.some(orgIds, (o) => o === i.id));
      //add new ch to team
      orgs.forEach((org) => {
        org.paymentMethods = _.uniq([
          ...org.paymentMethods,
          ..._.map(
            newList.filter((i) => i.orgId === org.id),
            (i) => i.id
          ),
        ]);
      });
    } else {
      state.paymentMethods = [...all];
    }

    ctx.setState(state);
    console.timeEnd("[addOrUpdatePaymentMethods]");
  }

  @Receiver()
  @ImmutableContext()
  public static deletePaymentMethod(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<string>
  ) {
    const id = arg.payload;
    if (!id) return;
    console.time("[deletePaymentMethod]");

    const state = ctx.getState();
    const all: PaymentMethodState[] = _.clone(state.paymentMethods);

    state.paymentMethods = all.filter((p) => p.id != id);

    //remove org's payment methods

    const idx = _.findIndex(state.orgs, (t) =>
      _.some(t.paymentMethods, (p) => p === id)
    );
    if (idx !== -1) {
      const org = state.orgs[idx];

      state.orgs[idx] = {
        ...org,
        paymentMethods: [...org.paymentMethods.filter((p) => p !== id)],
      };
    }

    ctx.setState(state);
    console.timeEnd("[deletePaymentMethod]");
  }
  //#endregion

  //#region Org Member state

  @Receiver()
  @ImmutableContext()
  public static replaceOrgUsers(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<OrgUserState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;

    console.time("[replaceOrgUsers]");
    const state = ctx.getState();

    const orgIds = arg.payload.map(i => i.orgId);
    const distinctOrgId = orgIds.filter((n, i) => orgIds.indexOf(n) === i);

    var othersOrgUsers = state.users.filter(i => distinctOrgId.indexOf(i.orgId) === -1);

    state.users = [...othersOrgUsers, ...arg.payload];
    ctx.setState(state);
    console.timeEnd("[replaceOrgUsers]");
  }

  @Receiver()
  @ImmutableContext()
  public static addOrUpdateOrgUser(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<OrgUserState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    console.time("[addOrUpdateOrgUser]");
    const state = ctx.getState();
    const all: OrgUserState[] = _.clone(state.users);

    //new
    const newList: OrgUserState[] = _.differenceWith(
      arg.payload,
      all,
      (a: OrgUserState, r: OrgUserState) =>
        r.userId === a.userId && r.orgId === a.orgId
    );

    //existing
    const existingList: OrgUserState[] = _.intersectionWith(
      arg.payload,
      all,
      (a: OrgUserState, r: OrgUserState) =>
        r.userId === a.userId && r.orgId === a.orgId
    );

    //update existing
    existingList.forEach((dto) => {
      let index = _.findIndex(
        all,
        (r) => r.userId === dto.userId && r.orgId === dto.orgId
      );
      if (index !== -1) {
        all[index] = this.mutateOrgUserState(all[index], dto);
      }
    });

    //add new
    if (newList.length > 0) {
      state.users = [...all, ...newList];
      const orgIds = _.uniq(_.map(newList, (i) => i.orgId));
      const orgs = state.orgs.filter((i) => _.some(orgIds, (o) => o === i.id));
      //add new orgusers to org
      orgs.forEach((org) => {
        org.users = _.uniq([
          ...org.users,
          ..._.map(
            newList.filter((i) => i.orgId === org.id),
            (i) => i.userId
          ),
        ]);
      });
    } else {
      state.users = [...all];
    }

    ctx.setState(state);
    console.timeEnd("[addOrUpdateOrgUser]");
  }

  // @Receiver()
  // @ImmutableContext()
  // public static deleteOrgUser(
  //   ctx: StateContext<EnterpriseStateModel>,
  //   arg: EmitterAction<{ userId: string; orgId: string }>
  // ) {
  //   const keys = arg.payload;
  //   if (!keys) return;
  //   console.time("[deleteOrgUser]");
  //   const state = ctx.getState();
  //   const users = [...state.users];

  //   state.users = users.filter(
  //     (p) => p.userId != keys.userId && p.orgId != keys.orgId
  //   );

  //   //remove org's contacts
  //   const idx = _.findIndex(
  //     state.orgs,
  //     (c) => c.id === keys.orgId && c.users.some((p) => p === keys.userId)
  //   );
  //   if (idx !== -1) {
  //     const org = state.orgs[idx];

  //     state.orgs[idx] = {
  //       ...org,
  //       users: [...org.users.filter((p) => p !== keys.userId)],
  //     };
  //   }

  //   ctx.setState(state);
  //   console.timeEnd("[deleteOrgUser]");
  // }

  // @Receiver()
  // @ImmutableContext()
  // public static suspendUser(
  //   ctx: StateContext<EnterpriseStateModel>,
  //   arg: EmitterAction<{ userId: string; orgId: string }>
  // ) {
  //   const keys = arg.payload;
  //   if (!keys) return;
  //   console.time("[suspendUser]");
  //   const state = ctx.getState();
  //   const userId = keys.userId;
  //   const orgId = keys.orgId;

  //   //suspend membership
  //   const userIdx = _.findIndex(
  //     state.users,
  //     (i) => i.userId === userId && i.orgId === orgId
  //   );

  //   if (userIdx === -1) return;

  //   state.users[userIdx].status = UserStatus.Suspended;

  //   //check if is current user
  //   const currentUserId = EnterpriseState.userSelector.userId;
  //   if (currentUserId === userId) {
  //     //perform clean up
  //     //remove ou
  //     const ous = [...state.ous];
  //     state.ous = ous.filter((i) => i.orgId !== orgId);

  //     //remove team
  //     const teams = [...state.teams];
  //     state.teams = teams.filter((t) => t.orgId !== orgId);

  //     //remove channel
  //     const channels = [...state.channels];
  //     state.channels = channels.filter((c) => c.orgId !== orgId);

  //     //remove posts
  //     const posts = [...state.posts];
  //     state.posts = posts.filter((p) => p.orgId !== orgId);

  //     //remove post comments
  //     const comments = [...state.comments];
  //     state.comments = comments.filter((c) => c.orgId !== orgId);

  //     //remove contacts
  //     const contacts = [...state.contacts];
  //     state.contacts = contacts.filter((c) => c.orgId !== orgId);
  //   }

  //   ctx.setState(state);
  //   console.timeEnd("[suspendUser]");
  // }

  // @Receiver()
  // @ImmutableContext()
  // public static updateMemberRole(
  //   ctx: StateContext<EnterpriseStateModel>,
  //   arg: EmitterAction<OrgUserState>
  // ) {
  //   if (!arg) return;
  //   if (!arg.payload) return;
  //   console.time("[updateMemberRole]");
  //   const state = ctx.getState();
  //   const idx = _.findIndex(
  //     state.users,
  //     (i) => i.orgId === arg.payload.orgId && i.userId === arg.payload.userId
  //   );

  //   if (idx === -1) return;

  //   state.users[idx].role = arg.payload.role;

  //   ctx.setState(state);
  //   console.timeEnd("[updateMemberRole]");
  // }

  private static mutateOrgUserState(
    state: OrgUserState,
    dto: OrgUserState
  ): OrgUserState {
    if (!state || !dto) return state;

    state.firstName = dto.firstName;
    state.imageUrl = dto.imageUrl;
    state.lastName = dto.lastName;
    state.status = dto.status;
    state.matrixId = dto.matrixId;
    state.publicKey = dto.publicKey;
    state.contactId = dto.contactId;
    state.role = dto.role;
    state.roleTypeCode = dto.roleTypeCode;
    state.roleTypeName = dto.roleTypeName;
    state.email = dto.email;
    state.ouId = dto.ouId;

    return state;
  }

  //#endregion

  //#region Client-Staff Assignment State

  @Receiver()
  @ImmutableContext()
  public static addOrUpdateAssignments(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<AssignmentState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;

    console.time("[addOrUpdateAssignments]");
    const state = ctx.getState();
    const all = _.clone(state.assignments);
    const payload = _.clone(arg.payload);

    // new
    const newList = _.differenceWith(
      payload,
      all,
      (p: AssignmentState, a: AssignmentState) =>
        p.ouId === a.ouId && p.clientId === a.clientId && p.staffId === a.staffId);

    // add new
    if (newList.length > 0) {
      state.assignments = [...all, ...newList];

      ctx.setState(state);
    }

    console.timeEnd("[addOrUpdateAssignments]");
  }

  @Receiver()
  @ImmutableContext()
  public static resetAssignments(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<AssignmentState[]>
  ) {
    if (arg.payload == null) return;

    console.time("[resetAssignments]");

    const state = ctx.getState();
    const payload = _.clone(arg.payload);
    state.assignments = payload;
    ctx.setState(state);

    console.timeEnd("[resetAssignments]");
  }
  //#endregion

  //#region Built-In Users

  @Receiver()
  @ImmutableContext()
  public static addOrUpdateBuiltInUsers(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<BuiltInUserState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    console.time("[addOrUpdateBuiltInUser]");
    const state = ctx.getState();
    if (!state.builtInUsers) state.builtInUsers = [];
    const all: BuiltInUserState[] = _.clone(state.builtInUsers);

    //new
    const newList: BuiltInUserState[] = _.differenceBy(arg.payload, all, "id");

    //existing
    const existingList: BuiltInUserState[] = _.intersectionBy(
      arg.payload,
      all,
      "id"
    );

    //update existing
    existingList.forEach((dto) => {
      let index = _.findIndex(all, (r) => r.id === dto.id);
      if (index !== -1) {
        all[index] = { ...dto };
      }
    });

    if (newList.length > 0) {
      state.builtInUsers = [...all, ...newList];
    } else {
      state.builtInUsers = [...all];
    }

    ctx.setState(state);
    console.timeEnd("[addOrUpdateBuiltInUser]");
  }
  //#endregion

  //#region MembershipReq
  @Receiver()
  @ImmutableContext()
  public static addOrUpdateMembershipReq(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<MembershipReqState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    console.time("[addOrUpdateMembershipReq]");
    const state = ctx.getState();
    if (!state.membershipReqs) state.membershipReqs = [];
    const all: MembershipReqState[] = _.clone(state.membershipReqs);

    //new
    const newList: MembershipReqState[] = _.differenceBy(
      arg.payload,
      all,
      "id"
    );

    //existing
    const existingList: MembershipReqState[] = _.intersectionBy(
      arg.payload,
      all,
      "id"
    );

    //update existing
    existingList.forEach((dto) => {
      let index = _.findIndex(all, (r) => r.id === dto.id);
      if (index !== -1) {
        all[index] = this.mutateMembershipReq(all[index], dto);
      }
    });

    if (newList.length > 0) {
      state.membershipReqs = [...all, ...newList];
    } else {
      state.membershipReqs = [...all];
    }

    ctx.setState(state);
    console.timeEnd("[addOrUpdateMembershipReq]");
  }

  @Receiver()
  @ImmutableContext()
  public static deleteMembershipReqs(
    ctx: StateContext<EnterpriseStateModel>,
    arg: EmitterAction<MembershipReqState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    var reqToDelete = arg.payload;

    console.time("[deleteMembershipReqs]");
    const state = ctx.getState();
    const existingReqs: MembershipReqState[] = _.clone(state.membershipReqs);

    _.forEach(reqToDelete, (req: MembershipReqState) => {
      _.remove(
        existingReqs,
        (t: MembershipReqState) =>
          t.id === req.id
      );
    });
    state.membershipReqs = existingReqs;

    ctx.setState(state);
    console.timeEnd("[deleteMembershipReqs]");
  }

  private static mutateMembershipReq(
    state: MembershipReqState,
    dto: MembershipReqState
  ): MembershipReqState {
    if (!state || !dto) return state;

    state.status = dto.status;
    state.reqStatusName = dto.reqStatusName;
    state.userAvatar = dto.userAvatar != null ? dto.userAvatar : null;
    state.roleName = dto.roleName != null ? dto.roleName : null;
    state.createdOn = dto.createdOn;
    state.email = dto.email;

    return state;
  }
  //#endregion

  @Receiver() static clean(ctx: StateContext<EnterpriseStateModel>) {
    ctx.setState({ ...new EnterpriseStateModel() });
  }

  @Receiver()
  @ImmutableContext()
  static cleanPartial(ctx: StateContext<EnterpriseStateModel>) {
    const state = ctx.getState();
    state.contacts = [];
    state.assignments = [];
    state.roles = [];
    state.comments = [];
    state.posts = [];
    state.channels = [];
    state.teamMembers = [];
    state.teams = [];
    state.ous = [];
    state.chUnreads = {};
    state.postUnreads = {};
    state.sharedUnreads = {};
    ctx.setState(state);
  }

  static isSameOrg(org: OrgState, orgId: string) {
    if (!org || !orgId) return false;
    return orgId === org.id || (org.connectedOrgs && org.connectedOrgs.some((s) => s == orgId));
  }

  static updateChannelLastActivities(state: EnterpriseStateModel, channelIds: string[]) {
    for (let i = 0; i < channelIds.length; i++) {
      let channelId = channelIds[i];
      let channelPosts = _.map(state.posts.filter((i) => i.channelId === channelId && i.status !== EntityStatus.Deleted), (m) => { return { createdOn: m.createdOn, createdBy: m.createdBy } });
      let channelComments = _.map(state.comments.filter((i) => i.channelId === channelId && i.status !== EntityStatus.Deleted), (m) => { return { createdOn: m.createdOn, createdBy: m.createdBy } });
      let combined = _.concat(channelPosts, channelComments);
      if (combined.length === 0) continue;
      const last3Activities = _.take(
        _.orderBy(combined, ["createdOn"], ["desc"]),
        3
      );

      let channel: ChannelState = _.find(
        state.channels,
        (o) => o.id === channelId
      );

      if (channel) {
        if (last3Activities.length) {
          channel.lastActivity = last3Activities[0].createdOn;
          channel.lastActivityUsers = _.map(last3Activities, "createdBy");
        }
      }
    }
  }
}
