import { distinctUntilChanged } from 'rxjs/operators';
import { MessageState } from "./../../../core/model/message.state";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { Subject, Subscription } from "rxjs";
import { ParticipantState } from "../../../core/model/participant.state";
import { UserMentions } from "../../../core/model/user-mentions";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { MediaService } from "../../../core/services/media.service";
import { environment } from "../../../../environments/environment";
import { MessageType } from "../../../core/model/message.model";
import { AttachmentType, FileDownloadStatus, FileObjState, FileType } from "../../../core/model/file-obj.state";
import { Emitter, Emittable } from "@ngxs-labs/emitter";
import { FileState } from "../../../core/states/file.state";
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { CloudFileExplorerComponent } from '../../../core/ui/cloud-file-explorer/cloud-file-explorer.component';
import { MatDialog } from '@angular/material/dialog';
import { ReplyToPipe } from '../../../core/pipe/reply-to.pipe';

@Component({
  selector: "app-composer",
  templateUrl: "./composer.component.html",
  styleUrls: ["./composer.component.scss"],
  providers: [ReplyToPipe]
})
export class ComposerComponent implements OnInit {
  @ViewChild("messageInput", { static: true }) private messageInput: ElementRef;
  @ViewChild("fileInput", { static: true }) fileInput;

  @Emitter(FileState.addOrUpdateFile)
  addOrUpdateFile: Emittable<FileObjState>;

  //#region Input
  private _droppedFile;
  isCloudFile: boolean;
  @Input()
  get droppedFile() {
    return this._droppedFile;
  }
  set droppedFile(data) {
    this._droppedFile = data;
    this._detectDropFileChanged.next(data);
  }

  @Input() isMeetEnabled: boolean = true;
  @Input() isMentionsEnabled: boolean = true;
  @Input() participants: ParticipantState[] = [];
  @Input() userMatrixId: string;
  @Input() message: MessageState;
  @Input() showSendBtn: boolean = true;
  @Input() replyMsgId: string;
  @Input() replyMsgContent: string;
  
  @Output() onSend = new EventEmitter();
  @Output() onResize = new EventEmitter();
  @Output() onCancelReply = new EventEmitter();

  @HostListener('document:click', ['$event'])
  clickout(event) {
    let emojiPanel = document.getElementById("emoji-panel"); // this is emoji panel
    let buttonEmoji = document.getElementById("button-emoji"); // this is button to trigger emoji panel
    let editorComponent = document.getElementById("editorComponent"); // edit message component
    let path = event.path || (event.composedPath && event.composedPath());
    if(emojiPanel && buttonEmoji){
      if(!path.includes(buttonEmoji) && !path.includes(emojiPanel) && !path.includes(editorComponent)){
        this.showEmojiPicker = false;
      }
    }
  }
  //#endregion
  private _detectDropFileChanged: Subject<any> = new Subject<any>();
  private _sub: Subscription;

  isLoading = false;
  file: any; // actual file
  imagePreview: any;
  newMeet = false;
  newMeetCalendar = false;
  private _isMediaLoaded = false;
  mimeType: string;
  showEmojiPicker: boolean = false;

  //#region Mentions
  mentionedUsers: UserMentions[] = [];
  contentToSend: string = ""; //from user mention directive
  onSendSub: Subject<void> = new Subject<void>();
  //#endregion

  //#region Calendar Meeting
  meetingTitle: string;
  frmCtrlStartDate: UntypedFormControl;
  frmCtrlEndDate: UntypedFormControl;
  selectedRecurringType: string = "";
  //#endregion

  // image blob
  safeBlobUrl: SafeUrl;

  //#region Getter
  get userMentions(): UserMentions[] {
    if (!this.isMentionsEnabled) return [];
    return this.participants
      .filter((p) => p.matrixId !== this.userMatrixId && p.status == 1)
      .map((p) => {
        return new UserMentions(p.userId, `${p.firstName} ${p.lastName}`);
      });
  }

  get fullMediaUrl(): string {
    if (!this.message) return null;
    if (!this.message.mediaUrl) return null;
    if (!this.message.fwt) return null;
    var sourceUri =
      environment.apiLink + "/media/download?fwt=" + this.message.fwt;
    return sourceUri;
  }

  get isMediaLoaded(): boolean {
    return this._isMediaLoaded;
  }

  get isFileType(): boolean {
    if (!this.message) {
      if(this.isCloudFile){
        return true;
      }
      return this.file
        ? !this.file.type.startsWith("image/")
        : false;
    }
    return this.message.type === MessageType.File;
  }

  get isMeetType(): boolean {
    if (!this.message) return this.newMeet;
    return this.message.type === MessageType.Meet;
  }
  get isCalendarType(): boolean {
    if (!this.message) return this.newMeetCalendar;
    return this.message.type === MessageType.Calendar;
  }
  get isImageType(): boolean {
    if (!this.message) {
      if(this.isCloudFile){
        return false;
      }
      return this.file
        ? this.file.type.startsWith("image/")
        : false;
    }
    return this.message.type === MessageType.Image;
  }
  //#endregion

  constructor(
    public snackBar: MatSnackBar,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private media: MediaService,
    private dialog: MatDialog,
    private replyToPipe: ReplyToPipe
  ) { }

  ngOnInit(): void {
    this.initMeeting();

    if (this._sub) this._sub.unsubscribe();
    this._sub = this._detectDropFileChanged
      .pipe(
        distinctUntilChanged((prev, curr) => {
          if (curr == null) return true;
          if (prev == null && curr != null) return false;
          return JSON.stringify(prev) === JSON.stringify(curr);
        })
      )
      .subscribe((res) => {
        if (res) {
          this.dropped(res);
        }
      });

    if (this.message) {
      this.messageInput.nativeElement.value = this.message.plaintext;
      if (this.message.replyTo) {
        this.replyMsgContent = this.replyToPipe.transform(this.message.plaintext);
        this.replyMsgId = this.message.replyTo;
      }
      if (this.isFileType || this.isImageType) {
        this.file = {
          id: this.message.mediaId,
          name: this.message.mediaName,
          type: this.message.mimeType,
          size: this.message.mediaSize,
          url: this.message.mediaUrl
        };
        if (this.message.mediaUrl && this.message.type === MessageType.Image) {
          // get blobUrl
          this.media.getBlobUrl(this.message.id).then((blobUrl) => {
            this.safeBlobUrl = this.sanitizer.bypassSecurityTrustUrl(blobUrl);
          });
        }

      } else if (this.isMeetType && this.message.meetUrl) {
        this.newMeet = true;
      } else if (this.isCalendarType && this.message.meetingTitle) {
        this.newMeetCalendar = true;
        this.meetingTitle = this.message.meetingTitle;
        this.frmCtrlStartDate = new UntypedFormControl(this.message.startDate);
        this.frmCtrlEndDate = new UntypedFormControl(this.message.endDate);
        this.selectedRecurringType = this.message.recurrence;
      }
    }

    this.toggleMsgInfoState(true);
  }

  ngOnDestroy() {
    if (this._sub) this._sub.unsubscribe();
  }

  startDateChangedEvent(event: MatDatepickerInputEvent<Date>) {
    //check previous value
    // var timeDiff = 0;

    // if (this.frmCtrlStartDate && this.frmCtrlEndDate) {
    //   timeDiff = (this.frmCtrlEndDate.value as Date).getTime() - (this.frmCtrlStartDate.value as Date).getTime();
    //   //console.log("found diff: %s", timeDiff / (1000 * 3600 * 24));
    // }

    event.value.setSeconds(0);

    this.frmCtrlStartDate = new UntypedFormControl(new Date(event.value));
    this.frmCtrlEndDate = new UntypedFormControl(
      new Date(event.value.getTime() + 30 * 60 * 1000)
    );

    // if (timeDiff <= 0) { //same
    //   this.frmCtrlEndDate = new FormControl(new Date(event.value));
    // }
  }

  endDateChangedEvent(event: MatDatepickerInputEvent<Date>) {
    event.value.setSeconds(0);

    var timeDiff = 0;

    if (this.frmCtrlStartDate && this.frmCtrlEndDate) {
      timeDiff =
        (this.frmCtrlEndDate.value as Date).getTime() -
        (this.frmCtrlStartDate.value as Date).getTime();
      //console.log("found diff: %s", timeDiff / (1000 * 3600 * 24));
    }

    this.frmCtrlEndDate = new UntypedFormControl(new Date(event.value));

    if (timeDiff <= 0) {
      //same
      this.frmCtrlStartDate = new UntypedFormControl(new Date(event.value));
    }
  }

  toggleMsgInfoState(enabled: boolean) {
    //resize chat container and composer
    setTimeout(() => {
      this.onResize.emit(enabled);
    }, 300);
  }

  keyCheck(e) {
    if (e.keyCode === 32 && this.messageInput.nativeElement.value.length <= 0) {
      e.preventDefault();
    }
  }

  textEnterEvent(e) {
    // do nothing if loading
    if (!this.isLoading) {
      // Enter was pressed without shift key
      if (e.keyCode === 13 && !e.shiftKey) {
        // prevent default behavior
        e.preventDefault();
        this.sendMessage();
        // "" instead of null prevent ie bug
        this.messageInput.nativeElement.value = "";
      }
    }
  }

  onPaste(event) {
    const items = event.clipboardData.items;
    let blob = null;
    for (const item of items) {
      if (item.type.indexOf("image") === 0) {
        blob = item.getAsFile();
      }
    }
    // load image if there is a pasted image
    if (blob !== null) {
      let imageFile = new File([blob], "image.png", { type: "image/png" });
      this.initFile(imageFile);
    }
  }

  clearMsgInfo() {
    this.toggleMsgInfoState(false);
    this.newMeet = false;
    this.newMeetCalendar = false;
    this.file = null;
    this.imagePreview = null;
    this.safeBlobUrl = null;

    if (this.message) {
      //remove ori msg file
      this.message.mediaName = null;
      this.message.mediaUrl = null;
      this.message.mediaId = null;
      this.message.fwt = null;
      //remove ori msg meet
      this.message.meetUrl = null;
      //remove ori msg calendar
      this.message.meetingTitle = null;
      this.message.recurrence = null;
      this.message.startDate = null;
      this.message.endDate = null;
    }
  }

  //#region Show Preview
  onImageLoad() {
    this._isMediaLoaded = true;
  }

  onMetadata() {
    this._isMediaLoaded = true;
  }

  onFileChanged($event) {
    if (
      $event &&
      $event.target &&
      $event.target.files &&
      $event.target.files[0]
    ) {
      let files = $event.target.files;
      this.isCloudFile = false;
      if (files[0]) {
        this.initFile(files[0]);
      }
    }
  }

  attachmentButton() {
    // clear input
    this.fileInput.nativeElement.value = "";
    // click file upload
    this.fileInput.nativeElement.click();
  }

  initFile(file: any) {
    if (file == null) {
      this.openSnackBar(
        "File not found",
        this.translateService.instant("REPEAT.OK")
      );
      return;
    }
    if (!this.isCloudFile && !this.media.isFileSizeWithinUploadLimit(file.size)) {
      this.fileInput.nativeElement.value = null;
      this.openSnackBar(
        this.translateService.instant("REPEAT.FILE_SIZE_LIMIT", {mblimit: this.media.uploadLimit}),
        this.translateService.instant("REPEAT.OK")
      );
      this.toggleMsgInfoState(false);
      return;
    }
    
    this.clearMsgInfo();
    this.file = file;
    if(this.isCloudFile){
      if (this.message) this.message.type = MessageType.File;
    } else if (file.type.startsWith("image/")) {
      this.imagePreview = this.sanitizer.bypassSecurityTrustResourceUrl(
        window.URL.createObjectURL(file)
      );
      if (this.message) this.message.type = MessageType.Image;
    } else {
      if (this.message) this.message.type = MessageType.File;
    }
    this.toggleMsgInfoState(true);
  }

  cloudFileButton() {
    console.log("cloudFileButton clicked");
    
    const dialogRef = this.dialog.open(CloudFileExplorerComponent, {
      width: "70vw",
      height: "70vh",
      panelClass: "cloudFile-dialog",
    });

    dialogRef.afterClosed().subscribe((res: FileObjState) => {
      if (res) {
        console.log("cloudFileButton", res);

        res.downloadStatus = FileDownloadStatus.ReadyToDownload;
        this.isCloudFile = true;
        this.initFile(res);
      } 
    });
  }

  public dropped(event) {
    if (event.type !== "") {
      // check for file object (not sure if this is the best way)
      this.isCloudFile = false;
      this.initFile(event);
    }
  }

  prepareMeet() {
    this.clearMsgInfo();
    this.newMeet = true;
    this.toggleMsgInfoState(true);
    this.isCloudFile = false;
  }

  prepareMeetCalendar() {
    this.clearMsgInfo();
    this.initMeeting();
    this.newMeetCalendar = true;
    this.toggleMsgInfoState(true);
    this.isCloudFile = false;
  }

  private initMeeting() {
    this.meetingTitle = "";
    this.selectedRecurringType = "";
    this.frmCtrlStartDate = new UntypedFormControl(
      new Date(new Date().getTime() - (new Date().getTime() % 60000))
    );
    this.frmCtrlEndDate = new UntypedFormControl(
      new Date(
        new Date().getTime() - (new Date().getTime() % 60000) + 30 * 60 * 1000
      )
    );
  }
  //#endregion

  addEmoji($event) {
    this.messageInput.nativeElement.value += $event.emoji.native;
  }

  toggleEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  async sendMessage() {
    this.isLoading = true;
    var message =
      this.messageInput && this.messageInput.nativeElement
        ? this.messageInput.nativeElement.value
        : "";
    //empty msg
    if (
      !this.file &&
      !this.newMeet &&
      !this.newMeetCalendar &&
      (!message || message.trim() == "")
    ) {
      this.openSnackBar(
        "Enter text to send message",
        this.translateService.instant("REPEAT.OK")
      );
      this.isLoading = false;
      return;
    }

    this.onSendSub.next();
    this.showEmojiPicker = false;

    // update message content
    var isEncrypted = this.message ? this.message.isEncrypted : false;
    var updatedContent = this.message ? this.message.plaintext : "";
    if (message && message.trim() != "") {
      updatedContent =
        this.contentToSend && this.contentToSend.trim() != ""
          ? this.contentToSend
          : message;

      isEncrypted = true;
    } else {
      updatedContent = "";
      isEncrypted = false;
    }

    //append reply content to msg content
    if (this.replyMsgContent != null && this.replyMsgContent.trim() != "") {
      updatedContent = this.replyMsgContent + updatedContent;
    }

    var data: {
      content: string;
      isEncrypted: boolean;
      msgType: MessageType;
      file?: FileObjState;
      meetingTitle?: string;
      startDate?: Date;
      endDate?: Date;
      recurrence?: string;
      replyTo?: string;
    } = {
      content: updatedContent,
      isEncrypted: isEncrypted,
      msgType: this.message ? this.message.type : MessageType.Text,
      replyTo: this.replyMsgId
    };

    if (this.file || (this.message && this.message.mediaId)) {
      if (
        (this.message && this.file.id !== this.message.mediaId) ||
        (!this.message && this.file)
      ) {
        //changes in file
        let fileObj = null;
        if(this.isCloudFile){
          fileObj = this.file;
        }else{
          fileObj = FileObjState.parse(this.file);
          fileObj.url = this.media.getObjUrl(this.file);
        }

        this.file = fileObj;
      }
      data.file = this.file;

      if (this.file.type === FileType.Image) {
        data.msgType = MessageType.Image;
      } else if (this.file.type === FileType.File) {
        data.msgType = MessageType.File;
      }
    } else if (this.newMeet) {
      data.msgType = MessageType.Meet;
    } else if (this.newMeetCalendar) {
      if (this.meetingTitle == null || this.meetingTitle.trim() == "") {
        this.isLoading = false;
        this.openSnackBar(
          "Enter meeting title",
          this.translateService.instant("REPEAT.OK")
        );
        this.isLoading = false;
        return;
      }
      data.msgType = MessageType.Calendar;
      data.meetingTitle = this.meetingTitle;
      data.startDate = this.frmCtrlStartDate.value;
      data.endDate = this.frmCtrlEndDate.value;
      data.recurrence = this.selectedRecurringType;
    } else {
      data.msgType = MessageType.Text;
    }
    this.onSend.emit(data);

    this.clearMsgInfo();
    this.removeReply();
    this.isLoading = false;
  }

  removeReply(){
    this.replyMsgContent = null;
    this.replyMsgId = null;
    this.onCancelReply.emit();
  }
}
