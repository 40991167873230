import { Resource } from "./resource";

export abstract class SharedResource extends Resource {
    originPath: string;
    originContainer: string;

    sharedBy: string; // init on client side. Search contacts for owner display name

    hasPath(path: string): boolean {
        return super.hasPath(path) || this.originPath == path;
    }

    isSameResource(resource){
        return (
          resource.path == this.path &&
          resource.originPath == this.originPath
        );
      }

    get accessPath(): string {
        return this.originPath;
        // return this.isSharedScope
        //   ? this.originPath
        //   : this.path;
    }

    get sharedAccessPath(): string {
        return this.path;
        // return this.isSharedScope
        //   ? this.path
        //   : this.originPath;
    }
    
    get sharedParentPath(): string {
        return Resource.getDirectoryName(this.path);
    }

    get parentPath(): string {
        return Resource.getDirectoryName(this.originPath);
    }

    constructor() {
        super();
        this.originPath = "";
        this.originContainer = "";
    }
}