import { OrgType } from "../enum/org-type";
import { EntityOrgStatus, EntityStatus } from "../enum/entity-status.enum";
import { UserStatus } from "../enum/user-status.enum";
import { MessageSendStatus } from "./message.model";
import { ContactDetailLabel } from "./contact.model";
import { PlanType } from './subscription.model';
import { SubscriptionStatus } from '../enum/subscription-status.enum';
import { Dictionary } from "../util/dictionary";

export class OrgState {
  id: string;
  name: string;
  type: OrgType;
  number: number;

  industryId: string;
  industryName: string;

  joinedOn: number;
  imageUrl: string;
  mailingAddress: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
  phoneNumber: string;
  fax: string;
  email: string;
  website: string;
  contacts: string[];
  ous: string[];
  users: string[];
  //for personal workspace
  connectedOrgs: string[];

  defaultOu: string;
  status: EntityOrgStatus;
  autoJoinEnabled: boolean;
  retentionPeriod: string;
  editMsgEnabled: boolean;
  deleteMsgEnabled: boolean;
  myDriveEnabled: boolean;
  myDriveEnabledGroup: string[];

  //for e2e
  publicKey: string;
  createdBy: string;

  //for stripe
  paymentMethods: string[];

  //active subscription fields
  subscriptionPlanType: PlanType;
  subscriptionStatus: SubscriptionStatus;

  //tools
  extTools: Dictionary<boolean>;

  constructor() {
    this.contacts = [];
    this.ous = [];
    this.users = [];
    this.connectedOrgs = [];
    this.paymentMethods = [];
    this.status = EntityOrgStatus.Active;
    this.extTools = {};
    this.autoJoinEnabled = false;
    this.editMsgEnabled = true;
    this.deleteMsgEnabled = true;
    this.myDriveEnabled = false;
    this.myDriveEnabledGroup = [];
  }
}

export class OrgUserState {
  orgId: string;
  userId: string;
  matrixId: string;
  firstName: string;
  lastName: string;
  imageUrl: string;
  role: string;
  roleTypeCode: string;
  roleTypeName: string;
  status: UserStatus;
  publicKey: string; //for e2e
  ouId: string;
  contactId: string;
  email: string;

  constructor() {
    this.status = UserStatus.Active;
  }
}

export class AssignmentState {
  ouId: string;
  staffId: string;
  clientId: string;
}

export class MembershipReqState {
  id: string;
  createdOn: number;
  firstName: string;
  lastName: string;
  roleId: string;
  roleName: string;
  orgId: string;
  engagedOuId: string;
  email: string;
  userId?: string; //optional, only existing EL user have userId
  type: MembershipReqType;
  status: MembershipReqStatus;
  reqStatusName: string;
  userAvatar?: string;//optional, only existing EL user have avatar

  constructor() {
    this.status = MembershipReqStatus.PendingToVerify;
  }
}

export enum MembershipReqType {
  SignUp = 1,
  Invitation = 2,
  RequestJoin = 3,
}

export enum MembershipReqStatus {
  Completed = 1,
  PendingToVerify = 2,
  PendingForApproval = 3, //only for RequestToJoin type
  Cancelled = 9,
}

export class OUState {
  id: string;
  orgId: string;
  parentId: string;
  name: string;
  imageUrl: string;
  isDefault: boolean;
  status: EntityStatus;
  createdBy: string;
  teams: string[];
  childs: string[];

  constructor() {
    this.teams = [];
    this.status = EntityStatus.Active;
    this.childs = [];
  }
}

export class TeamState {
  id: string;
  name: string;
  ouId: string;
  orgId: string;
  imageUrl: string;
  type: TeamType;
  isDefault: boolean;
  status: EntityStatus;
  canCreateChannel: boolean;
  canCreatePost: boolean;
  canCreatePostComment: boolean;
  memberVisibility: boolean;
  //enableImplicitMembers: boolean;
  enableImplicitOwner: boolean;
  enableImplicitAdmins: boolean;
  enableImplicitSubOuMembers: boolean;
  enableImplicitCoworkers: boolean;
  enableImplicitClients: boolean;
  enableImplicitPartners: boolean;

  //implicitMembers: string[];
  members: string[];
  channels: string[];

  constructor() {
    this.channels = [];
    this.members = [];
    //this.implicitMembers = [];
    this.type = TeamType.Private;
    this.status = EntityStatus.Active;
    this.canCreateChannel = false;
    this.canCreatePost = false;
    this.canCreatePostComment = false;
    this.memberVisibility = false;
    this.enableImplicitOwner = false;
    this.enableImplicitAdmins = false;
    this.enableImplicitSubOuMembers = false;
    this.enableImplicitCoworkers = false;
    this.enableImplicitClients = false;
    this.enableImplicitPartners = false;
  }
}

export class TeamMemberState {
  userId: string;
  teamId: string;
  ouId: string;
  orgId: string;
  role: TeamRole;
  //type: TeamMemberType;
  isImplicit: boolean;
  implicitRole: string;

  constructor() {
    this.role = TeamRole.Member;
    this.isImplicit = false;
    //this.type = TeamMemberType.Actual;
  }
}

export class ChannelState {
  id: string;
  ouId: string;
  orgId: string;
  name: string;
  teamId: string;
  isDefault: boolean;
  participants: string[];
  canCreatePostComment: boolean;
  canCreatePost: boolean;

  //new
  totalFiles: number; //total files in channel (include post and comment files)
  lastActivity: number; //lastest post's or post comment's createdon timestamp
  totalPosts: number;
  lastActivityUsers: string[]; //last 3 users activity

  isPostHistoryLoaded: boolean;

  constructor() {
    this.isDefault = false;
    this.canCreatePostComment = false;
    this.canCreatePost = false;
    this.participants = [];
    this.lastActivity = 0;
    this.totalFiles = 0;
    this.totalPosts = 0;
    this.lastActivity = new Date().getTime();
    this.isPostHistoryLoaded = false;
  }
}

export class PostState {
  id: string;
  tempId: string;
  channelId: string;
  teamId: string;
  ouId: string;
  orgId: string;

  content: string="";
  createdOn: number;
  createdBy: string;

  canCreatePostComment: boolean;
  fwt: string;
  mediaId: string; //refer to file.state
  type: PostType;
  mediaName: string;
  mediaUrl: string;
  isFile: boolean;
  isImage: boolean;

  comments: string[];
  totalComments: number;
  totalFile: number;
  status: EntityStatus;
  sendStatus: MessageSendStatus;
  sendAttempt: number;

  isCommentHistoryLoaded: boolean;
  isOffline: boolean; //differentiate between post and in-mem post
  fileFromCloud: string = null;


  constructor() {
    this.createdOn = new Date().getTime();
    this.id = this.createdOn.toString();
    this.tempId = this.createdOn.toString();
    this.type = PostType.Text;
    this.comments = [];
    this.status = EntityStatus.Active;
    this.totalComments = 0;
    this.totalFile = 0;
    this.sendStatus = MessageSendStatus.PendingToSent;
    this.canCreatePostComment = false;
    this.sendAttempt = 0;
    this.isCommentHistoryLoaded = false;
    this.isOffline = true;
  }

}

export class PostCommentState {
  id: string;
  tempId: string;
  postId: string;
  channelId: string;
  teamId: string;
  ouId: string;
  orgId: string;

  createdBy: string;
  createdOn: number;

  senderName: string;
  senderAvatar: string;

  content: string;
  status: EntityStatus;
  fwt: string;
  mediaId: string; //refer to file.state
  type: CommentType;
  mediaName: string;

  //need to change once the comment opened
  sendStatus: MessageSendStatus;
  sendAttempt: number;

  isOffline: boolean; //differentiate between post and in-mem post
  fileFromCloud: string = null;

  constructor() {
    this.createdOn = new Date().getTime();
    this.id = this.createdOn.toString();
    this.tempId = this.createdOn.toString();
    this.type = CommentType.Text;
    this.status = EntityStatus.Active;
    this.sendStatus = MessageSendStatus.PendingToSent;
    this.status = EntityStatus.Active;
    this.sendAttempt = 0;
    this.isOffline = true;
  }
}

export class ContactState {
  id: string;
  userId: string; //null if offline contact
  ownerId: string;
  orgId: string;

  firstName: string; //use if offline contact
  lastName: string; //use if offline contact
  displayName: string;
  imageUrl: string; //use if offline contact
  position: string;
  orgName: string; //company name, different from joined organization name

  phones: ContactPhoneState[];
  addresses: ContactAddressState[];
  emails: ContactEmailState[];

  status: EntityStatus;
  type: ContactType;

  note: string

  constructor() {
    this.firstName = "";
    this.lastName = "";
    this.status = EntityStatus.Active;
    this.phones = [];
    this.addresses = [];
    this.emails = [];
    this.type = ContactType.Offline;
    this.note = "";
  }
}

export class ContactPhoneState {
  contactId: string;
  label: ContactDetailLabel;
  labelName: string;
  phoneNumber: string;

  constructor() {
    this.label = ContactDetailLabel.Personal;
    this.phoneNumber = "";
  }
}

export class ContactEmailState {
  contactId: string;
  label: ContactDetailLabel;
  labelName: string;
  email: string;

  constructor() {
    this.label = ContactDetailLabel.Personal;
    this.email = "";
  }
}

export class ContactAddressState {
  contactId: string;
  label: ContactDetailLabel;
  labelName: string;
  street1: string;
  street2: string;
  postCode: string;
  city: string;
  state: string;
  country: string;
  fullAddress: string;

  constructor() {
    this.label = ContactDetailLabel.Personal;
  }
}
export class OrgPermissionState {
  orgId: string;
  roleId: string;
  permissions: { [id: string]: boolean };
}

export class OrgRoleState {
  id: string;
  name: string;
  normalizedName: string;
  label: string;
  orgId: string;
}

export class PaymentMethodState {
  orgId: string;
  id: string;
  brand: string;
  last4: string;
  name: string;
  expMonth: number;
  expYear: number;
  isDefault: boolean;
  // web app icon
  icon: string;
}

export class BuiltInUserState {
  id: string;
  firstName: string;
  lastName: string;
  displayName: string;
  email: string;
  imageUrl: string; //full image url
  matrixId: string;
}

/**
 * Keep the literal as capital letter.
 * Use to match with team.implicitMembers.
 * Current typescript version that we used does not support string litaral enum
 * so use `BuiltInUser[BuiltInUser.PARTNERS]` to get the string value from enum literal name.
 */
export enum BuiltInUser {
  PARTNERS,
  CLIENTS,
  SUBOUMEMBERS,
  MEMBERS,
  COWORKERS,
  OWNER,
  ADMINS
}

export enum PostType {
  Text,
  Image,
  File
}

export enum CommentType {
  Text,
  Image,
  File
}

export enum TeamRole {
  Owner = 1,
  Admin = 2,
  Member = 9
}

export enum TeamType {
  Public = 1,
  Private = 2
}

export enum TeamMemberType {
  Actual = 1,
  Implicit = 2,
  BuiltIn = 9
}

export enum ContactType {
  Offline = 0,
  Online = 1
}
