import { ApiService } from '../api/api.service';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class TeamService {
    OnOuSwitched: Subject<string>;
    constructor(private api: ApiService) {
        this.OnOuSwitched = new Subject<string>();
    }

    getTeam(teamId: string): Promise<any> {
        return this.api.getAsync('v2/group/' + teamId, null, teamId)
            .then(res => {
                return res;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    updateTeam(team: any): Promise<any> {
        return this.api.putAsync('v2/group', team, team.groupId)
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }
    deleteTeam(teamId: any): Promise<any> {
        return this.api.deleteAsync('v2/group/' + teamId, null, teamId)
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }
    addTeamMembers(teamId: any, teamMemberIds: string[]): Promise<any> {
        return this.api.putAsync('v2/group/' + teamId + '/members', teamMemberIds, teamId)
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }
    deleteTeamMembers(teamId: any, teamMemberIds: string[]): Promise<any> {
        return this.api.deleteAsync('v2/group/' + teamId + '/members', teamMemberIds, teamId)
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    addTeam(orgId: string, teamUserIds: string[], teamName: string, teamType: number, memberVisibility: boolean,
        canCreateChannel: boolean, canCreatePost: boolean, canCreatePostComment: boolean): Promise<any> {
        return this.api.postAsync('v2/group/' + orgId + '/add', {
            groupUserIds: teamUserIds,
            name: teamName,
            type: teamType,
            memberVisibility: memberVisibility,
            canCreatePost: canCreatePost,
            canCreateChannel: canCreateChannel,
            canCreatePostComment: canCreatePostComment
        }, orgId)
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    addChannel(teamId: string, channelName: string): Promise<any> {
        return this.api.postAsync('v2/group/' + teamId + '/channel', JSON.stringify(channelName), teamId)
            .then(res => {
                return res
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    // promote to owner
    setTeamOwner(teamId: string, userId: string): Promise<any> {
        return this.api.postAsync('v2/group/' + teamId + '/owner/change', JSON.stringify(userId), teamId)
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    // promote/demote admin
    promoteAdmin(teamId: string, userId: string): Promise<any> {
        return this.api.postAsync('v2/group/' + teamId + '/admin/promote', JSON.stringify(userId), teamId)
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    demoteAdmin(teamId: string, userId: string): Promise<any> {
        return this.api.postAsync('v2/group/' + teamId + '/admin/demote', JSON.stringify(userId), teamId)
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

}
