import { Component, OnInit, ViewChild } from "@angular/core";
import { MembershipReqService } from "../../core/services/membership-req.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { DialogEnterEmailComponent } from "../request-to-join/dialog/dialog-enter-email/dialog-enter-email.component";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { CountdownComponent, CountdownConfig, CountdownEvent } from "ngx-countdown";
import { TurnstileComponent } from "../../core/auth/turnstile/turnstile.component";

declare let gtag:Function;
@Component({
  selector: "app-self-sign-up",
  templateUrl: "./self-sign-up.component.html",
  styleUrls: ["./self-sign-up.component.scss"],
})
export class SelfSignUpComponent implements OnInit {
  @ViewChild('elturnstile') turnstileComponent: TurnstileComponent;
  showError: boolean = false;
  errorMsg: string;
  showLoading: boolean = false;
  isEmailSent: boolean = false;
  firstName: string;
  lastName: string;
  email: string;
  code: string;

  // countdown timer
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  countdownConfig: CountdownConfig = { leftTime: 300, notify: 1 }; // registration code expires after 5 mins

  constructor(
    private membershipReqService: MembershipReqService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.email = params && params.data ? params.data : "";
      let status: string = params.status;
      if (status === "invite-sharelink-redirect-signup") {
        this.showError = true;
        this.errorMsg = this.translate.instant("SIGN_UP.SHARE_LINK_REDIRECT");
      }
    });
  }

  redirectToLogin() {
    this.router.navigate(["/login"]);
  }

  async register() {
    this.showLoading = true;
    this.showError = false;
    //Removes the leading and trailing white space
    this.firstName = this.firstName.trim();
    this.lastName = this.lastName.trim();
    var token = await this.turnstileComponent.getToken();
    this.membershipReqService
      .reqSignUpIssueCode(this.email, this.firstName, this.lastName,token)
      .then((res) => {
        this.handleSuccessRegistration(res);
      })
      .catch((err) => {
        console.error(err);
        this.handleErrorMsg(err);
      });
    gtag('event', 'conversion', {'send_to': 'AW-640313908/P9hwCMHBqbgDELTUqbEC'});
  }

  verifyCode(event) {
    this.membershipReqService.verifySignUpCode(this.email, this.code)
    .then((req) => {
      if (req) {
        var base64token = btoa(req.id);
        this.router.navigate(["/activate/" + base64token]);
      }
    })
    .catch((err) => {
      console.error(err);
      this.handleErrorMsg(err);
    });
  }

   async resendCode() {
    var token = await this.turnstileComponent.getToken();
    if (this.email) {
      this.membershipReqService.resendSignUpEmail(this.email, true, token).then((res) => {
        this.handleSuccessCodeResent();
      });
    } else {
        // failed to get existing membership request, need to enter email again
        this.openEnterEmailDialog();
    }
  }

  private handleSuccessRegistration(req: any) {
    this.showLoading = false;
    this.isEmailSent = true;
  }

  private handleSuccessCodeResent() {
    this.openSnackBar(
      this.translate.instant("REPEAT.EMAIL_RESEND_SUCCESS"),
      "OK"
    );
    this.isEmailSent = true;
    this.countdown.restart();
  }

  private handleErrorMsg(err: any) {
    this.showLoading = false;
    this.showError = true;
    if (err.error) {
      this.errorMsg = err.error;
    } else {
      this.errorMsg = this.translate.instant("REPEAT.UNEXPECTED_ERROR");
    }
  }

  private async openEnterEmailDialog(): Promise<void> {
    const dialogRef = this.dialog.open(DialogEnterEmailComponent, {
      width: "250px",
    });
    var token = await this.turnstileComponent.getToken();
    dialogRef.afterClosed().subscribe((result) => {
      if (result)
        this.membershipReqService.resendSignUpEmail(result, true,token).then((res) => {
          this.handleSuccessCodeResent();
        });
    });
  }

  private openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  //#region Countdown events
  countdownEvent(e: CountdownEvent) {
    if (e.action === 'done') {
      window.location.reload(); // returns user to signup page
    }
  }
  //#endregion
}
