import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TeamService } from "../../../../core/services/team.service";
import { OrgHub } from "../../../../core/hub/org.hub";
import { TeamState } from "../../../../core/model/org.state";
import { EnterpriseSelector } from "../../../../core/states/enterprise.state.selector";

@Component({
  selector: "app-dialog-delete-team",
  templateUrl: "./dialog-delete-team.component.html",
  styleUrls: ["./dialog-delete-team.component.scss"]
})
export class DialogDeleteTeamComponent implements OnInit {
  id: any;
  ouId: any;
  isLoading = false;
  team: TeamState;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private orgHub: OrgHub,
    private enterpriseSelector: EnterpriseSelector,
    //private teamService: TeamService,
    public dialogRef: MatDialogRef<DialogDeleteTeamComponent>
  ) { }

  ngOnInit() {
    this.team = this.data.currentTeam;
  }

  deleteTeam() {
    if (!this.team) return;
    this.isLoading = true;

    this.orgHub.deleteTeam(this.team.orgId, this.team.id).subscribe(
      res => {
        this.isLoading = false;
        if (!res) {
          this.dialogRef.close("failed");
        } else {
          this.dialogRef.close("success");
        }
      },
      err => {
        this.isLoading = false;
        this.dialogRef.close(err);
      }
    );
    // this.teamService
    //   .deleteTeam(this.id)
    //   .then(res => {
    //     this.isLoading = false;
    //     if (res.error) {
    //       this.dialogRef.close(res);
    //     } else {
    //       this.dialogRef.close("success");
    //     }
    //   })
    //   .catch(err => {
    //     this.isLoading = false;
    //     this.dialogRef.close(err);
    //   });
  }
}
