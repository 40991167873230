import { Component, Input, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-dialog-welcome-new-org",
  templateUrl: "./dialog-welcome-new-org.component.html",
  styleUrls: ["./dialog-welcome-new-org.component.scss"],
})
export class DialogWelcomeNewOrgComponent implements OnInit {
  orgName: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogWelcomeNewOrgComponent>,
    private router: Router, private translateService: TranslateService,) { }

  ngOnInit() {
    this.orgName = this.data.orgName;
  }

  setupOrg() {
    this.router.navigate(["/main/org/ou"]);
    this.dialogRef.close();
  }

  inviteUserDialog() {
    this.dialogRef.close({ open: "invite" });
  }

  setupTeam() {
    this.router.navigate(["/main/teams"]);
    this.dialogRef.close();
  }

  learnMore() {
    window.open("https://www.everleagues.com", "_blank");
  }
}
