import { NotificationService } from './notify.service';
import { UserService } from './user.service';
import { Injectable } from "@angular/core";
import { AngularFireMessaging } from "@angular/fire/compat/messaging";
import {
  Subject,
  Subscription,
} from "rxjs";
import { pocolog } from "pocolog";
import { Router, NavigationEnd } from "@angular/router";
import { DialogSwitchOUComponent } from "../ui/dialog/switch-ou/dialog-switch-ou.component";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "./../../../environments/environment";
import { EnterpriseSelector } from "../states/enterprise.state.selector";
import { Emitter, Emittable } from "@ngxs-labs/emitter";
import { UserDataState } from "../states/user-data.state";
import { UserDataSelector } from "../states/user-data.state.selector";
import { FeedService } from './feed.service';
import { NotificationEventType } from '../enum/notification-event-type.enum';

@Injectable()
export class MessagingService {
  currentMessage = new Subject<any>();
  firebaseMessagingSub: Subscription;
  requestTokenSub: Subscription;
  receiveMessageSub: Subscription;
  deleteTokenSub: Subscription;
  serviceWorkerLink = "/firebase-messaging-sw.js";

  @Emitter(UserDataState.addOrUpdateFcmToken)
  public addOrUpdateFcmToken: Emittable<string>;

  constructor(
    private userService: UserService,
    private angularFireMessaging: AngularFireMessaging,
    private router: Router,
    public dialog: MatDialog,
    public enterpriseSelector: EnterpriseSelector,
    public userDataSelector: UserDataSelector,
    private notifyService: NotificationService,
    private feedService: FeedService
  ) {

    let routerSub = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // dont run if is meet page
        if (!event.url.includes("/meet")) {
          this.runServiceWorker();
        }
        // run only once
        if (routerSub) routerSub.unsubscribe();
      }
    });
  }

  runServiceWorker() {
    // if (!environment.production) {
    //   // pass mode to service worker
    //   this.serviceWorkerLink += "?mode=test";
    // }
    if (this.firebaseMessagingSub) {
      this.firebaseMessagingSub.unsubscribe();
    }
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .getRegistrations()
        .then(function (registrations) {
          for (let registration of registrations) {
            // console.log("#registration")
            // console.log(registration)
            registration.update();
            // registration.unregister()
          }
        })
        .then(() => {
          navigator.serviceWorker
            .register(this.serviceWorkerLink, { scope: "/" })
            .then(registration => {
              console.log("registering sw");
              //this.angularFireMessaging.useServiceWorker(registration);
            }).catch(err => {
              console.error("Error in register sw")
              console.error(err);
            });
        });

      // ensure service worker is ready
      navigator.serviceWorker.ready.then(reg => {
        if (reg && reg.active) {
          let firebaseConfig = environment.firebase;
          console.log("post sw firebase config");
          reg.active.postMessage({ firebaseConfig: firebaseConfig });
        }
        // PING to service worker, later we will use this ping to identify our client.
        if (navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage("ping");
        }
        // listening for messages from service worker
        navigator.serviceWorker.addEventListener("message", (
          event: MessageEvent
        ) => {

          // ping to sync with service worker
          navigator.serviceWorker.controller.postMessage("ping");
          try {
            console.log("[Firebase Service] Message received")
            console.log(event);

            const feedId = event.data && event.data.feed_id ? event.data.feed_id : null;

            if (!!feedId) {
              this.feedService.clearFeeds(event.data.orgId, null, [feedId]);
            }

            if (event.data && event.data.firebaseMessagingData) {
              console.log("[Firebase Service] Foreground event detected. Service ignoring")
            } else {
              // var payloadData = event;
              // var notification = NotificationData.parseFromFirebase(payloadData);
              // this.notifyService.handleNotificationHubEvent(notification);

              var eventType = event.data.event_type;
              var body = event.data.body;
              var title = event.data.title;
              var orgId = event.data.orgId;
              var data = event.data;
              this.notifyService.notificationRedirection(eventType, body, title, orgId, data, false);
            }
          } catch (ex) {
            console.log(ex);
            pocolog.error(ex);
          }
        });
      });
    }
  }

  pingServiceWorker() {
    // ping service worker to sync
    navigator.serviceWorker.ready.then(reg => {
      if (navigator.serviceWorker.controller) {
        navigator.serviceWorker.controller.postMessage("ping");
      }
    });
  }

  /**
   * request permission for notification from firebase cloud messaging
   */
  requestPermission() {
    if (this.requestTokenSub) {
      this.requestTokenSub.unsubscribe();
    }
    this.requestTokenSub = this.angularFireMessaging.requestToken.subscribe({
      next: (token) => {
        console.log(token);
        this.addOrUpdateFcmToken.emit(token);
      },
      error: (err) => {
        console.log(err);
        pocolog.error("Unable to get permission to notify.", err);
      }}
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage() {
    if (this.receiveMessageSub) {
      this.receiveMessageSub.unsubscribe();
    }
    this.receiveMessageSub = this.angularFireMessaging.messages.subscribe(
      payload => {
        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      }
    );
  }

  manageNotificationEventClick(payloadData, eventType) {
    console.log(payloadData)
    const defaultOu = this.enterpriseSelector.getCurrentOrgDefaultOu();
    const payloadOrgId = payloadData.orgUnitId
      ? payloadData.orgUnitId
      : payloadData.orgId;
    console.log(payloadOrgId)
    if (!this.enterpriseSelector.isCurrentOrg(payloadOrgId)) {
      // if (payloadOrgId !== defaultOu.id) {
      //   const org = this.enterpriseSelector.getOrgByOu(
      //     payloadOrgId
      //   );
      const dialogRef = this.dialog.open(DialogSwitchOUComponent, {
        width: "400px",
        data: {
          orgId: payloadOrgId,
          disableRedirect: true
        }
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res && res.status === "success") {
          this.routeToEvent(payloadData, eventType);
        }
      });
    } else {
      // user in correct org
      this.routeToEvent(payloadData, eventType);
    }
  }

  private routeToEvent(payloadData, eventType: NotificationEventType) {
    if (eventType === NotificationEventType.NEW_MESSAGE || eventType === NotificationEventType.NEW_MEET) {
      this.router.navigate(["/main/chat/" + payloadData.roomMatrixId], { queryParams: { msgId: payloadData.msgId } });
      // } else if (eventType === EventType.JOIN_MEETING) {
      //   if (payloadData.meetUrl) {
      //     payloadData.meetUrl = this.meetService.forceHttps(
      //       payloadData.meetUrl
      //     );
      //     this.meetService.joinMeet(payloadData.meetUrl).then((res) => {
      //       let url = this.meetService.getHostName(res.url);
      //       const jwt = res.accessToken;
      //       const room = res.room;
      //       const roomId = payloadData.roomMatrixId;

      //       var w = window.open("/meet", "_blank");
      //       w["meetReq"] = { url: url, accessToken: jwt, room: room, roomId: roomId };
      //     });
      //   }
    } else if (eventType === NotificationEventType.NEW_POST || eventType === NotificationEventType.NEW_COMMENT) {
      this.router.navigate([
        "/main/teams/" +
        payloadData.channelId +
        "/post/" +
        payloadData.postId,
      ]);
    }
  }
}
