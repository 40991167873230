import { AccessPermission, AccessRight } from "./cloud-access-right";
import { CloudFile } from "./cloud-file";
import { SharedFile } from "./shared-file";
import { SharedFolder } from "./shared-folder";

export interface OriginResource {
    sharedDummyFolderPath?: string;
}

export class OriginFile extends CloudFile implements OriginResource {
    dummyFile?: SharedFile;
    parentDummyFolder?: SharedFolder;

    get sharedDummyFolderPath(): string {
        return this.parentDummyFolder?.path ?? "";
    }

    hasPath(path: string): boolean {
        return super.hasPath(path) || (this.dummyFile && this.dummyFile.path == path);
    }

    isSameResource(resource){
      return (
        resource instanceof OriginFile &&
        resource.path == this.path &&
        ((resource.dummyFile == null && this.dummyFile == null) ||
          (resource.dummyFile &&
            this.dummyFile &&
            resource.dummyFile?.path == this.dummyFile?.path))
      );
    }

    hasPermission(userId: string, permission: AccessPermission, folderContentOnly: boolean = false, sharePermission: boolean = false){
        var grantees = this.grantees;
        if (!!this.parentDummyFolder && !!this.parentDummyFolder.grantees && this.parentDummyFolder.grantees.length !== 0) grantees = grantees.concat(this.parentDummyFolder.grantees);
        if (!grantees || grantees.length == 0) return false;
        var currentGrantee = grantees
          .filter((g) => !sharePermission || g.isSharedAccess)
          .find((g) => g.identity == userId);
        if (!currentGrantee) return false;
        return (currentGrantee.permission & permission) != 0 || currentGrantee.permission == permission;
    }

    /** DTO is OriginFileDto from file server. */
    public static parse(dto: any) {
        if (!dto) return null;
        var obj = new OriginFile();

        obj.path = dto.path;
        obj.container = dto.container;
        obj.name = dto.displayName;
        obj.createdOn = dto.createdOn;
        obj.modifiedOn = dto.modifiedOn;

        obj.contentType = dto.contentType;
        obj.sizeInBytes = dto.sizeInBytes;
        obj.sizeInString = dto.sizeInString;
        obj.extension = dto.extension;
        obj.enableNotifications = dto.enableNotifications;
        obj.ownerId = dto.ownerIdentity;

        if (dto.grantees) {
            dto.grantees.forEach(f => obj.grantees.push(AccessRight.parse(f)));
        }

        if (dto.fromSharedDummyFile) {
            obj.dummyFile = SharedFile.parse(dto.fromSharedDummyFile);
        }
        
        if (dto.fromParentSharedDummyFolder) {
            obj.parentDummyFolder = SharedFolder.parse(dto.fromParentSharedDummyFolder);
        }

        return obj;
    }
}

