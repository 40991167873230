import { MessageState } from "../model/message.state";
import { State, StateContext, Selector } from '@ngxs/store';
import { ImmutableSelector, ImmutableContext } from '@ngxs-labs/immer-adapter';
import { Receiver, EmitterAction } from '@ngxs-labs/emitter';
import * as _ from "lodash";
import { MessageStatus } from '../model/message.model';
import { EnterpriseSelector } from "./enterprise.state.selector";
import { Injector, Injectable } from "@angular/core";
import { Dictionary } from "../util/dictionary";
import { MsgCursor } from "../model/msgCursor";

export class InMemMessageStateModel {
    messages: MessageState[];
    cursors: Dictionary<MsgCursor>;
    constructor() {
        this.messages = [];
        this.cursors = {};
    }
}

@State<InMemMessageStateModel>({
    name: "immsg",
    defaults: new InMemMessageStateModel(),
})
@Injectable()
export class InMemMessageState {
    private static enterpriseSelector: EnterpriseSelector;

    constructor(injector: Injector) {
        InMemMessageState.enterpriseSelector = injector.get<EnterpriseSelector>(
            EnterpriseSelector
        );
    }

    ngxsAfterBootstrap(ctx: StateContext<InMemMessageStateModel>) {
        console.log("[InMemMessageState] - ngxsAfterBootstrap");
    }

    @Selector([InMemMessageState])
    @ImmutableSelector()
    static messages(state: InMemMessageStateModel): MessageState[] | null {
        // if (!state) return [];
        // if (!state.messages) return [];
        return _.cloneDeep(state.messages);
    }

    @Selector()
    static cursors(state: InMemMessageStateModel): Dictionary<MsgCursor> {
        return state.cursors;
    }

    @Receiver()
    @ImmutableContext()
    public static updateSendStatus(ctx: StateContext<InMemMessageStateModel>, arg: EmitterAction<MessageState>) {
        console.time("[im.msg.state] - updateSendStatus");
        try {
            if (!arg.payload) return;

            const state = ctx.getState();
            const allInMemMsgs = [...state.messages];
            var msg: MessageState = allInMemMsgs.find(f => f.id == arg.payload.id);
            if (!msg) {
                msg = state.messages.find(f => f.tempId == arg.payload.tempId);
            }

            if (!msg) {
                console.error("[im.msg.state] intend to change send status but msg was not found. payload: %o", arg.payload);
                return;
            }

            if (msg.sendStatus != arg.payload.sendStatus || msg.sendAttempt != arg.payload.sendAttempt) {
                var idx = allInMemMsgs.findIndex(m => m.id ? m.id == arg.payload.id : m.tempId == arg.payload.tempId);
                if (idx != -1) {
                    allInMemMsgs[idx].sendStatus = arg.payload.sendStatus;
                    allInMemMsgs[idx].sendAttempt = arg.payload.sendAttempt;
                    state.messages = [...allInMemMsgs];
                    ctx.setState(state);
                }               
            }
        } catch (e) {
            console.error("[im.msg.state] %o", e);
        } finally {
            console.timeEnd("[im.msg.state] - updateSendStatus");
        }
    }

    @Receiver()
    @ImmutableContext()
    public static addOrUpdateMessages(
        ctx: StateContext<InMemMessageStateModel>,
        arg: EmitterAction<MessageState[]>
    ) {
        if (!arg) return;
        if (!arg.payload || arg.payload.length == 0) return;
        const payload = arg.payload.filter((p) =>
            this.enterpriseSelector.canSaveState(p.orgId)
        );
        if (payload.length == 0) return;
        console.time("[addOrUpdateMessages]");

        const state = ctx.getState();
        const all = [...state.messages];

        const newList = _.differenceBy(payload, all, "id");
        const existingList = _.intersectionBy(payload, all, "id");

        existingList.forEach((msg) => {
            let index = _.findIndex(all, (o) => o.id === msg.id);
            if (index == -1) {
                index = _.findIndex(all, (o) => o.id === msg.tempId);
            }

            if (index !== -1) {
                all[index] = this.mutateMessageState(all[index], msg);
            }
        });

        //add new
        if (newList.length > 0) {
            newList.forEach(msg => {
                msg.isOffline = false;
            });
            state.messages = [...all, ...newList];
        } else {
            state.messages = [...all];
        }
        ctx.setState(state);

        console.timeEnd("[addOrUpdateMessages]");
    }

    @Receiver()
    @ImmutableContext()
    public static deleteMessage(
        ctx: StateContext<InMemMessageStateModel>,
        arg: EmitterAction<MessageState>
    ) {
        if (!arg.payload) return;
        if (!this.enterpriseSelector.canSaveState(arg.payload.orgId)) return;

        console.time("[deleteMessage]");
        var msg = arg.payload;
        const state = ctx.getState();
        const all = [...state.messages];

        const existing: MessageState = _.find(all, (m: MessageState) => m.id === msg.id);
        if (!existing) return;

        existing.status = MessageStatus.Deleted;
        ctx.setState(state);

        console.timeEnd("[deleteMessage]");
    }

    @Receiver()
    @ImmutableContext()
    public static setMsgCursor(
        ctx: StateContext<InMemMessageStateModel>,
        arg: EmitterAction<{ roomMatrixId: string, cursor: MsgCursor }>
    ) {
        if (!arg.payload) return;

        const key = arg.payload.roomMatrixId;
        const msgCursor = arg.payload.cursor;
        const state = ctx.getState();

        let roomCursors = state.cursors;
        
        if (!key) return;

        roomCursors[key] = msgCursor;

        state.cursors = roomCursors;

        ctx.setState(state);
    }

    @Receiver()
    @ImmutableContext()
    public static setForwardCursor(
        ctx: StateContext<InMemMessageStateModel>,
        arg: EmitterAction<{ roomMatrixId: string, cursor: string }>
    ) {
        if (!arg.payload) return;

        const key = arg.payload.roomMatrixId;
        const fCursor = arg.payload.cursor;
        const state = ctx.getState();

        let roomCursors = state.cursors;
          
        if (!key) return;

        if (roomCursors[key]) {
            roomCursors[key].forward = fCursor;
        } else {
            roomCursors[key] = new MsgCursor(null, fCursor);
        }

        state.cursors = roomCursors;

        ctx.setState(state);
    }

    @Receiver()
    @ImmutableContext()
    public static setBackwardCursor(
        ctx: StateContext<InMemMessageStateModel>,
        arg: EmitterAction<{ roomMatrixId: string, cursor: string }>
    ) {
        if (!arg.payload) return;

        const key = arg.payload.roomMatrixId;
        const bCursor = arg.payload.cursor;
        const state = ctx.getState();

        let roomCursors = state.cursors;
        
        if (!key) return;

        if (roomCursors[key]) {
            roomCursors[key].backward = bCursor;
        } else {
            roomCursors[key] = new MsgCursor(bCursor, null);
        }

        state.cursors = roomCursors;

        ctx.setState(state);
    }

    private static mutateMessageState(
        existing: MessageState,
        state: MessageState
    ) {
        if (!existing.id && state.id) {
            existing.id = state.id;
        }
        existing.content = state.content;
        existing.plaintext = state.plaintext;
        existing.isDecrypted = state.isDecrypted;
        existing.decryptAttempt = state.decryptAttempt;
        existing.mediaId = state.mediaId;
        existing.sendStatus = state.sendStatus;
        existing.sendAttempt = state.sendAttempt;
        existing.status = state.status;
        existing.fwt = state.fwt;
        existing.mediaUrl = state.mediaUrl;
        existing.serverTimeStamp = state.serverTimeStamp;
        existing.type = state.type;
    
        existing.mediaName = state.mediaName;
        existing.mediaSize = state.mediaSize;
        existing.mimeType = state.mimeType;
        existing.showCard = state.showCard;
        existing.meetUrl = state.meetUrl;
        existing.meetExp = state.meetExp;
        existing.meetIat = state.meetIat;
        existing.isEncrypted = state.isEncrypted;
        existing.isOffline = state.isOffline;
        existing.flags = state.flags;
    
        existing.startDate = state.startDate;
        existing.endDate = state.endDate;
        existing.recurrence = state.recurrence;
        existing.meetingTitle = state.meetingTitle;
        
        existing.lastEditedTime = state.lastEditedTime;
        existing.reactions = state.reactions;
        existing.isOffline = false;
        return existing;
    }

    @Receiver() static clean(ctx: StateContext<InMemMessageStateModel>) {
        ctx.setState({ ...new InMemMessageStateModel() });
    }
}