import { Injectable } from "@angular/core";
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: "root"
})
export class SystemService {

  constructor(private api: ApiService
  ) {
  }

  getLatestWebVersion(): Promise<any> {
    return this.api.getAsync<any>('sys/web/version/latest')
      .then(data => {
        return Promise.resolve(data);
      }).catch(res => {
        this.api.handleError(res);
        return Promise.reject(res.error);
      });
  }

  getTimezones(): Promise<any> {
    return this.api.getAsync<any>('sys/systimezones')
      .then(data => {
        return Promise.resolve(data);
      }).catch(res => {
        this.api.handleError(res);
        return Promise.reject(res.error);
      })
  }
}
