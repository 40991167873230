import { InMemMessageSelector } from './../../core/states/inmem.message.selector';
import { MessagingState } from './../../core/states/messaging.state';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { RoomType } from "../../core/model/room.model";
import { MatButton } from "@angular/material/button";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PerfectScrollbarComponent } from "ngx-perfect-scrollbar";
import { BehaviorSubject, Subject, Observable, Subscription, ReplaySubject } from "rxjs";
import { DatePipe } from "@angular/common";
import { RelativeDatePipe } from "../../core/pipe/relative-date.pipe";
import { UserMentions } from "../../core/model/user-mentions";
import { RoomState } from "../../core/model/room.state";
import { DecryptStatus, MessageState, MsgReaction } from "../../core/model/message.state";
import { MsgRenderer } from "../../core/renderer/msg.renderer";
import { ParticipantState } from "../../core/model/participant.state";
import { RoomHub } from "../../core/hub/room.hub";
import { FileObjState, FileType } from "../../core/model/file-obj.state";
import { StringDictionary } from '../../core/util/dictionary';
//import ResizeObserver from 'resize-observer-polyfill';
import { ResizeObserver } from '@juggle/resize-observer';
import { HubStateSelector } from '../../core/states/hub.state.selector';
import { HubHandshakeStatus } from '../../core/model/hubConnection.state';
import { Select } from '@ngxs/store';
import { distinctUntilChanged, filter, skip, take } from 'rxjs/operators';
import * as _ from "lodash";
import { SubSink } from 'subsink';
import {  MessageSendStatus, MessageStatus, MessageType } from '../../core/model/message.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogSelectReactionComponent } from '../dialog/dialog-select-reaction.component';
import { MessagingSelector } from '../../core/states/messaging.state.selector';

@Component({
  selector: "app-chat-room",
  templateUrl: "./chat-room.component.html",
  styleUrls: ["./chat-room.component.scss"],
  providers: [DatePipe, RelativeDatePipe],
  animations: [
    trigger('slideUp', [
      state('show', style({
        top: '-34px', // refer to css
      })),
      state('hide', style({
        top: '0'
      })),
      transition('show => hide', [
        animate('0.35s')
      ]),
      transition('hide => show', [
        animate('0.35s')
      ]),
    ]),

    // trigger("toggleMsgInfo", [
    //   // state('show', style({
    //   //   height: '170px',
    //   // })),
    //   // state('hide', style({
    //   //   height: '90px'
    //   // })),
    //   // transition('show => hide', animate('400ms ease-in-out')),
    //   // transition('hide => show', animate('400ms ease-in-out'))
    // ]),
  ],

})
export class ChatRoomComponent
  implements OnInit, OnDestroy {
  @Input() userMatrixId: string;
  @Input() participants: ParticipantState[] = [];
  @Input() isMsgEditEnabled: boolean = true;
  @Input() isDeleteMsgEnabled: boolean = true;

  private _room: RoomState = null;
  @Input()
  get room(): RoomState {
    return this._room;
  }
  set room(data: RoomState) {
    this._room = data;

    this._detectRoomChanged.next(data);
  }

  // this contains all messages from room
  private _messages: MessageState[] = [];

  @Input()
  get messages(): MessageState[] {
    return this._messages;
  }
  set messages(data: MessageState[]) {
    this._messages = data;
    this._detectMessagesChanged.next(data);
  }

  @Input() flags: StringDictionary;
  @Input() highlightMsgId: string;
  @Input() compact = false;
  @Input() isLoading = false; //skeleton text

  //#region chat elements
  @ViewChild("chat", { static: true }) private chatContainer: ElementRef;
  @ViewChild("chatScrollbar", { static: true }) private chatScrollbar: PerfectScrollbarComponent;
  @ViewChild('addArea', { static: true }) addAreaContainer: ElementRef;
  @ViewChild('composerDiv', { static: true }) composerContainer: ElementRef;
  @ViewChild('buttonNextPage', { static: false }) buttonNextPage: MatButton;
  @ViewChild("chatMessagesContainer", { static: true }) chatMessagesContainer: ElementRef
  //#endregion

  @Select(HubStateSelector.roomHubHandshakeStatus)
  roomHubHandshakeStatus$: Observable<HubHandshakeStatus>;

  // full message buffer in chatroom
  fullMessages: MessageState[] = [];
  chatMessages: ChatMessage[] = []; // grouped message to show

  //file drop
  file: any;

  // Flag for group messages
  isGroupMessage = false;

  // msg info state for animation. set 'show' or 'hide'
  // msgInfoState = "show";
  resizeObserver: ResizeObserver;

  //#region mentions
  mentionedUsers: UserMentions[] = [];
  //#endregion

  //#region buffer variables
  bufferSize = 20;
  currentPage = 1;
  bufferLoading = new BehaviorSubject(false);
  bufferLoading$: Observable<boolean>;
  showNextPageButton = new BehaviorSubject(true);
  showNextPageButton$: Observable<boolean>;
  //#endregion

  //#region init variables
  isPSReady = new BehaviorSubject(false);
  isPSReady$: Observable<boolean>;  // check if perfect-scrollbar is ready to be scrolled
  psReadySubscription: Subscription;

  private _roomStatusSub: Subscription;
  isHubReady = new BehaviorSubject(false);
  isHubReady$: Observable<boolean>;
  isHubReadySub: Subscription;

  // init room complete
  isRoomReady = new BehaviorSubject(false);
  isRoomReady$: Observable<boolean>;
  isRoomReadySub: Subscription;

  // nextpage
  loadNextPage$ = new BehaviorSubject<string>(null); // pass last message timestamp to anchor UI
  //#endregion

  private _detectRoomChanged: ReplaySubject<RoomState> = new ReplaySubject<RoomState>();
  private _detectMessagesChanged: Subject<MessageState[]> = new Subject<MessageState[]>();
  private _sub: SubSink = new SubSink();

  chatRoomFirstLoad = true; // has the chatroom loaded a room before?

  showNewMessagesAlert = false; // show new messages button

  isGettingLatestMsgs = true; //to show an indicator on UI that background process is currently getting latest msgs from server

  pipeRefresher: number;

  //reply-to
  replyMsgId: string;
  replyMsgContent: string;

  constructor(
    private roomHub: RoomHub,
    public snackBar: MatSnackBar,
    private changeDetectorRef: ChangeDetectorRef,
    private inMemMsgSelector: InMemMessageSelector,
    private msgSelector: MessagingSelector,
    private dialog: MatDialog,
  ) {
    this._roomStatusSub = new Subscription();
    this.isPSReady$ = this.isPSReady.pipe(filter(res => res))
    this.psReadySubscription = new Subscription();
    this.isHubReady$ = this.isHubReady.pipe(filter(res => res));

    if (this._sub) {
      this._sub.unsubscribe();
    }

    this._sub.sink = this._detectRoomChanged.pipe(
      distinctUntilChanged((prev, curr) => {
        if (curr == null) return true;
        if (prev == null && curr != null) return false;
        if (prev.matrixId === curr.matrixId) return true;
        return JSON.stringify(prev) === JSON.stringify(curr);
      })
    )
      .subscribe((rooms) => {
        console.log("[ChatRoom] _detectRoomChanged");
        this.onRoomChangedHandler(rooms)
      });

    this._sub.sink = this._detectMessagesChanged.pipe(
      distinctUntilChanged((prev, curr) => {
        if (curr == null) return true;
        if (prev == null && curr != null) return false;
        var same = JSON.stringify(prev) === JSON.stringify(curr);
        if (same == false) return false;
        let reactionChanges = curr.some((c) => {
          let old = prev.find((p) => p.id == c.id);
          return old.reactions != c.reactions;
        });

        return !reactionChanges;
      })
    )
      .subscribe((messages) => {
        console.log("[ChatRoom] _detectMessagesChanged %o", messages);
        this.onMessageChangedHandler(messages);
      });

    // ensure roomhub is connected first before initializing anything
    this._roomStatusSub = this.roomHubHandshakeStatus$.subscribe(async (status) => {
      if (status === HubHandshakeStatus.Completed) {
        this.isHubReady.next(true);
        // run only once
        //this._roomStatusSub.unsubscribe();
      }
    });
  }

  //#region getters
  get isMeetEnabled(): boolean {
    return this.getActiveParticipantCount() <= 30;
  }

  get isEnableMentions(): boolean {
    return this.room && this.room.type === RoomType.Group;
  }

  isHighlighted(msg: MessageState): boolean {
    if (this.highlightMsgId == null || msg == null || msg.id == null)
      return false;
    return msg.id === this.highlightMsgId;
  }
  // test code
  // get totalChat() {
  //   let totalMessage = 0;
  //   this.chatMessages.forEach((x) => {
  //     totalMessage += x.messages.length;
  //   });
  //   return totalMessage;
  // }
  //#endregion  

  private redrawRoom() {
    console.log("Drawing room")
    // room has not been initialized, redraw the entire chatroom
    this.initRedraw();
  }

  private async onRoomChangedHandler(rooms: any) {
    console.log("onRoomChangedHandler %o", rooms)
    // trigger whenever a new room is selected
    this.redrawRoom();

    if (this.room) {
      if (this.room.type === RoomType.Direct) {
        this.isGroupMessage = false;
      } else if (this.room.type === RoomType.Group) {
        this.isGroupMessage = true;
      }
    }
  }

  private async onMessageChangedHandler(messages: MessageState[]) {
      console.log("[ChatRoom] incoming new messages %s", this.messages.length)
      // incoming new messages, insert to buffer
      let newMessages: MessageState[] = _.differenceWith(messages, this.fullMessages, this.isEqual);
      const existingList = _.intersectionWith(
        messages,
        this.fullMessages,
        this.isEqual
      );

      // add message to current room message
      // this.fullMessages = _.uniq([...existingList, ...newMessages], m => m.id ? m.id : m.tempId);
      var latestMessageTimeStamp;

      // get latestMessageTimestamp
      if (this.room && this.room.lastMsg) {
        latestMessageTimeStamp = this.room.lastMsg.serverTimeStamp
      } else if (this.fullMessages.length > 0) {
        latestMessageTimeStamp = this.fullMessages[this.fullMessages.length - 1].serverTimeStamp;
      }

      newMessages.forEach(x => { this.fullMessages.push(x); });

      this.sortMessages(this.fullMessages);

      // check for newer messages, if exist then configure scrolling
      let scrollToBottom = false;

      let newerMessages = newMessages.filter(
        (x) =>
          x.serverTimeStamp >= latestMessageTimeStamp &&
          this.fullMessages.findIndex((m) => m.id != x.id)
      );

      if (this.room && this.room.lastMsg) {
        newerMessages = newMessages.filter(
          (x) => x.serverTimeStamp >= this.room.lastMsg.serverTimeStamp
        );
      }
      //#region scroll configuration
      if (newerMessages.length > 0) {

        /* Requirements: scroll to bottom if
        1) latest message is own message
        2) or current view is at latest message
        3) else show a "new messages" button that tells user that they can scroll down for the new message
      */
        // check if latest message is own message
        if (newerMessages[newerMessages.length - 1].senderMatrixId === this.userMatrixId) {
          scrollToBottom = true;
        }
        // check if current view is at bottom
        else if (this.chatScrollbar.directiveRef.position().y === 'end') {
          scrollToBottom = true;
        } else {
          // console.log("current view is not at the bottom. Showing new message")
          this.showNewMessagesAlert = true;
        }

      } else {
        // if buffer size is bigger than full message, assume its an inactive room
        if (this.fullMessages.every((m) => m.roomId != this.room.id))
          // if (this.fullMessages.length <= this.bufferSize)
          scrollToBottom = true;
      }
      //#endregion

      // this.insertMessagesToBuffer(newMessages, scrollToBottom);

      // check if next page is loading;
      var isLoadingNextPage = this.loadNextPage$.value;

      if (isLoadingNextPage) {
        console.log("Loading next page");
        await this.loadNextPage().then(() => {
          this.anchorToMessage(this.loadNextPage$.value);
          this.loadNextPage$.next(null);
          this.bufferLoading.next(false);
        });
      } else if (newMessages.length > 0){
        // insert message
        this.insertMessagesToBuffer(newMessages, scrollToBottom);
      }
      // update next page button
      // this.updateNextPageButton(newerMessages.length > 0);

      existingList.forEach((msg) => {
        this.updateMessageFromBuffer(msg);
      });

      if (scrollToBottom) {
        this.scrollToBottom();
      }
  }

  updateNextPageButton() {
    if (this.room && this.room.isHistoryLoaded) {
      if (this.fullMessages.length <= (this.bufferSize * this.currentPage)) {
        this.showNextPageButton.next(false);
      } else {
        this.showNextPageButton.next(true);
      }
    } else {
      this.showNextPageButton.next(true);
    }
  }

  private initRedraw() {
    if (this.isHubReadySub) this.isHubReadySub.unsubscribe();
    this.isHubReadySub = this.isHubReady$.subscribe(async () => {
      this.isLoading = true;
      await this.initRoom()
      this.isLoading = false;
      await this._detectMessagesChanged.pipe(filter(res=> res.length > 0), take(1)).toPromise();
      await this.initMessages();
      if (this.chatRoomFirstLoad) {
        await this.initScrollPosition();
        this.chatRoomFirstLoad = false;
      } else {
        console.log("[ChatRoomComponent] Hub ready not first load")
        this.scrollToBottom();
      }
      //this.isHubReadySub.unsubscribe();
    });
  }

  ngOnInit() {
    // console.time("[ChatRoomComponent] ChatRoomComponent ngOnInit");


    // this.toggleMsgInfoState(false);
    this.showNextPageButton$ = this.showNextPageButton.asObservable();
    this.bufferLoading$ = this.bufferLoading.asObservable();
    this.isRoomReady$ = this.isRoomReady.asObservable();
    // console.timeEnd("[ChatRoomComponent] ChatRoomComponent ngOnInit");
  }

  ngAfterViewInit() {
    this.messageComposerResizeObserver();
  }

  onResize(show: boolean) {
    // this.msgInfoState = show?"show":"hide";
    // this.changeDetectorRef.detectChanges();
    this.adjustAddArea();
  }

  //#region Observers
  messageComposerResizeObserver() {
    this.resizeObserver = new ResizeObserver((entries, observer) => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        // console.log('Element:', entry.target);
        // console.log(`Element size: ${cr.width}px x ${cr.height}px`);
        // console.log(`Element padding: ${cr.top}px ; ${cr.left}px`);
        // console.log($event);
        this.adjustAddArea();
        this.isPSReady.next(true);
      }
    });

    if (this.addAreaContainer) {
      // Element for which to observe height and width
      this.resizeObserver.observe(this.addAreaContainer.nativeElement);
    }

    if (this.composerContainer) {
      this.resizeObserver.observe(this.composerContainer.nativeElement);
    }
  }
  //#endregion


  adjustAddArea() {
    const mainContainer = document.getElementById("mainContainer");
    const chatToolbar = document.getElementById("chatToolbar");
    const msgComposer = document.getElementById("msgComposer");
    // console.log(mainContainer)
    // console.log(chatToolbar)
    // console.log(msgComposer)

    if (mainContainer && chatToolbar && msgComposer && this.chatContainer) {
      const calHeight =
        mainContainer.clientHeight -
        chatToolbar.clientHeight -
        msgComposer.clientHeight;
      //resize chat container when composer resize
      this.chatContainer.nativeElement.style.height =
        calHeight.toString() + "px";

      this.changeDetectorRef.detectChanges();
    }

  }

  onMsgEdited() {
    this.adjustAddArea();
  }

  onMsgReplied(content: string, msg: MessageState) {
    this.replyMsgId = msg.id;
    this.replyMsgContent = content;
    this.changeDetectorRef.detectChanges();
  }

  onReplyClicked($event){
    this.highlightMsgId = $event;
    this.initScrollPosition();
  }

  async initRoom() {
    // console.time("[ChatRoomComponent] initRoom");
    if (!this.room) return Promise.resolve();
    // init
    this.isLoading = true;
    this.bufferLoading.next(false);
    this.showNextPageButton.next(false);

    if (this.room.type === RoomType.Direct) {
      this.isGroupMessage = false;
    } else if (this.room.type === RoomType.Group) {
      this.isGroupMessage = true;
    }

    //filter previous room msgs
    this.chatMessages = this.chatMessages.filter(
      (c) => c.roomId == this.room.id
    );

    const ttlMsgs = this.messages ? this.messages.length : 0;
    await this.sortMessages(this.messages);
    const oldestMsgTimestamp = this.messages && this.messages.length > 0 ? this.messages[0].serverTimeStamp : null;

    var promise = new Promise<void>((resolve, reject) => {
      this.isGettingLatestMsgs = true;
      if (this.highlightMsgId) {
        this.bufferLoading.next(true);
        this.roomHub.getLatestMsg(
          this.room.matrixId,
          this.room.isHistoryLoaded,
          oldestMsgTimestamp,
          ttlMsgs
        ).then(res => {
          this.isGettingLatestMsgs = false;
          this.updateNextPageButton()
          this.bufferLoading.next(false);
          resolve();
        }).catch((err) => {
          console.error("Error getting room history: %s", err);
        });

        return this.findMsgHistoryAsync(this.highlightMsgId)
          .then(__ => {
            this.isLoading = false;
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      } else {
        if (this.messages.length == 1 && !this.room.isHistoryLoaded
          && this.room.lastMsg.id == this.messages[0].id) {
          // this.bufferLoading.next(true);
        } else if (this.messages.length > 0) {
          this.isLoading = false;
          resolve();
        }

        return this.roomHub.getLatestMsg(
          this.room.matrixId,
          this.room.isHistoryLoaded,
          oldestMsgTimestamp,
          ttlMsgs
        ).then(res => {
          this.isGettingLatestMsgs = false;
          this.room.isHistoryLoaded = res.room.isHistoryLoaded;
          // this.updateNextPageButton()
          this.isLoading = false;
          this.bufferLoading.next(false);
          this.scrollToBottom();
          resolve();
        }).catch((err) => {
          console.error("Error getting room history: %s", err);
        });

        // if (scrollToBottom) this.scrollToBottom();
        // this.isLoading = false;

      }
      // else {
      //   this.isLoading = false;
      //   resolve();
      // }
    });

    // console.timeEnd("[ChatRoomComponent] initRoom");
    return promise;
  }

  async findMsgHistoryAsync(msgId: string) {
    let msg = this.messages.find(x => x.id === msgId);
    let cursor = this.inMemMsgSelector.getBackwardCursor(this.room.matrixId);
    return new Promise<void>(async (resolve, reject) => {
      while (!msg && !this.room.isHistoryLoaded) {
        await this.roomHub.getRoomHistory(
          this.room.matrixId,
          cursor
        ).then((roomHistory) => {
          msg = this.messages.find(x => x.id === msgId);
        }).catch(err => {
          console.error(err);
          reject();
        });
      }
      resolve();
    });

  }

  // initMessages() {
  //   this.fullMessages = [...this.messages]
  //   let newMessages = this.fullMessages.slice(-this.bufferSize);

  //   this.loadMessages(newMessages);
  // }
  async initMessages() {

    this.currentPage = 1; // reset page
    this.fullMessages = [];
    this.chatMessages = [];

    return new Promise<void>((resolve, reject) => {
      try {
        let filteredFullMessages = this.messages.filter(x => !this.checkMsgIsLastMsgAndIsDecrypted(x));
        this.fullMessages = [...filteredFullMessages];
        let newMessages = this.fullMessages.slice(-this.bufferSize);
        this.loadMessages(newMessages);
        this.updateNextPageButton();

        resolve();
      } catch (err) {
        reject(err);
      }
    });
  }

  // init where to scroll message on first load room
  async initScrollPosition() {
    this.psReadySubscription = await this.isPSReady$.subscribe(async res => {
      if (this.highlightMsgId) {
        let msg = this.fullMessages.find(x => x.id === this.highlightMsgId);
        if (!msg) {
          console.error("[ChatRoom] initScrollPosition - cannot find message")
          this.scrollToBottom();
        } else {
          this.scrollToMessage(msg.serverTimeStamp, 0, 0); // use offset 0,speed 0 to use scrollIntoView which is more reliable for init
        }
      } else this.scrollToBottom();
      // run only once
      this.psReadySubscription.unsubscribe();
    });
  }

  //#region scrolling
  scrollToMessage(messageTimeStamp, offset = 0, speed = 500) {
    let elem: Element = document.getElementById("msgid-" + messageTimeStamp);
    console.log(elem)
    if (!elem) {
      let index = this.fullMessages.findIndex(x => x.serverTimeStamp === messageTimeStamp);
      // find the page number of the message
      if (index !== -1) {
        // fullmessage index is reversed, get messageNumber
        let messageNumber = this.fullMessages.length - index;
        let pageNumber = (Math.floor(messageNumber / this.bufferSize)) + 1
        this.loadPageNumber(pageNumber, false);
        elem = document.getElementById("msgid-" + messageTimeStamp);
      }
    }
    if (speed === 0 && offset === 0) {
      elem.scrollIntoView();
    } else {
      let querySelector = "#chatRoomScrollbar #msgid-" + messageTimeStamp;
      this.chatScrollbar.directiveRef.scrollToElement(
        querySelector,
        offset,
        speed
      );
    }
    this.bufferLoading.next(false);
  }

  scrollToBottom() {
    this.chatScrollbar.directiveRef.scrollToBottom(0, 0);
  }

  // anchor to a msg after adding messages to UI
  anchorToMessage(msgTimeStamp) {
    if (msgTimeStamp) {
      let csGeo = this.chatScrollbar.directiveRef.geometry();
      this.scrollToMessage(msgTimeStamp, -(90 - csGeo.y), 0);
    } else {
      console.error("[ChatRoom] Cannot anchor UI. Last message not detected")
    }
  }

  // PS Event
  async psYReachStart($event) {
    // console.log("[psYReachStart] %o", $event);
    if (this.buttonNextPage) this.buttonNextPage._elementRef.nativeElement.click();
  }

  async psYReachEnd($event) {
    // console.log("[psYReachEnd] %o", $event);
    this.showNewMessagesAlert = false;
  }
  //#endregion

  loadMessages(messages: MessageState[]) {
    if (messages.length == 0) {
      this.chatMessages = [];
      return;
    }

    let groupsByDay: ChatMessage[] = [];
    for (let i = 0; i < messages.length; i++) {
      var msg = this.messages.filter(x => (messages[i].id && x.id === messages[i].id) ||
      (messages[i].tempId && x.tempId === messages[i].tempId))[0];
      // last group
      let lastGroup = groupsByDay[groupsByDay.length - 1];
      let messageDate = new Date(messages[i].serverTimeStamp); // serverTimeStamp in unix epoch, js auto converts to local
      if (lastGroup && lastGroup.date === messageDate.toLocaleDateString()) {
        lastGroup.messages.push(msg);
      } else {
        let day: ChatMessage = new ChatMessage();

        //    day.date = messageDate.toLocaleDateString();
        day.messages = [msg];

        groupsByDay.push(day);
      }
    }

    this.chatMessages = groupsByDay;

    this.changeDetectorRef.detectChanges();
  }

  insertMessagesToBuffer(
    messages: MessageState[],
    scrollToBottom = true
  ) {
    // filter out msg obj with same temp Id
    const filtered: MessageState[] = _.uniqBy(messages, m => [m.id, m.tempId].join());
    console.log("Inserting Message to buffer %o", filtered)
    filtered.forEach((message) => {
      var msg = this.fullMessages.filter(x => (message.id && x.id === message.id) ||
      (message.tempId && x.tempId === message.tempId))[0];
      // insert to existing group in chatMessages
      let messageDate = new Date(msg.serverTimeStamp);
      let existingGroup = this.chatMessages.filter(
        (x) => x.date === messageDate.toLocaleDateString()
      )[0];
      if (
        existingGroup &&
        existingGroup.date === messageDate.toLocaleDateString()
      ) {
        // check if message exists
        let existingMsg: MessageState;
        if (msg.tempId) {
          existingMsg = existingGroup.messages.filter(
            (x) => x.tempId === msg.tempId
          )[0];
        } else if (msg.id) {
          existingMsg = existingGroup.messages.filter(
            (x) => x.id === msg.id
          )[0];
        }
        if (existingMsg) {
          MessagingState.mutateMessageState(existingMsg, msg);
          // existingMsg.sendStatus = message.sendStatus;
          // existingMsg = message;
          // update fullMessages
          // let index = this.fullMessages.findIndex(x => x.id === existingMsg.id)[0];
          // this.fullMessages[index] = message;
        } else {
          existingGroup.messages.push(msg);
        }
      } else {
        let day: ChatMessage = new ChatMessage();
        //   day.date = messageDate.toLocaleDateString();
        day.messages.push(msg);

        this.chatMessages.push(day);
      }
    });


    this.sortChatMessages();

    // fullMessages must always be sorted
    this.sortMessages(this.fullMessages);

    this.changeDetectorRef.detectChanges();

    if (scrollToBottom) {
      this.scrollToBottom();
    }

    // refresh pipes by changing a value passed into a pipe
    this.pipeRefresher = Math.random();

  }

  private updateMessageFromBuffer(message: MessageState) {
    let index = this.messages.findIndex(
      (x) =>
        (message.id && x.id === message.id) ||
        (message.tempId && x.tempId === message.tempId)
    );
    if (index != -1) {
      MessagingState.mutateMessageState(
        this.messages[index],
        message
      );
    }

    let messageDate = new Date(message.serverTimeStamp);
    let chatGroup = this.chatMessages.find(
      (x) => x.date === messageDate.toLocaleDateString()
    );
    if (chatGroup) {
      let msgIndex = chatGroup.messages.findIndex(
        (x) =>
          (message.id && x.id === message.id) ||
          (message.tempId && x.tempId === message.tempId)
      );
      if (msgIndex != -1) {
        MessagingState.mutateMessageState(
          chatGroup.messages[msgIndex],
          message
        );
      }
    }

    this.changeDetectorRef.detectChanges();
  }

  //#region sorting
  sortChatMessages() {
    // move messages if needed, this is needed when timestamp is updated with server time and message moves to the next day
    //#region move messages
    this.chatMessages.forEach((cm) => {
      for (var i = cm.messages.length - 1; i > -1; i--) { // reverse loop for removing element

        let messageDate = new Date(cm.messages[i].serverTimeStamp);
        if (cm.date !== messageDate.toLocaleDateString()) {
          // message date does not match
          let existingGroup = this.chatMessages.filter(
            (x) => x.date === messageDate.toLocaleDateString()
          )[0];
          if (existingGroup) {
            existingGroup.messages.push(cm.messages[i])
            // remove message from this group
            cm.messages.splice(i, 1);
          }
        }
      }
    });
    //#endregion

    // merge groups if needed
    //#region merge groups
    var seen = {}; // date, false if seen, true if duplicate
    this.chatMessages.forEach((cm) => {
      if (seen.hasOwnProperty(cm.date)) {
        // duplicate, merge
        let firstCM = this.chatMessages.find(x => x.date === cm.date);
        firstCM.messages = firstCM.messages.concat(cm.messages);
        // empty current chatmessage to delete later;
        cm.messages = [];
        return;
      }
      // Current name is being seen for the first time
      seen[cm.date] = false
    });

    // delete cm with no messages
    this.chatMessages = [...this.chatMessages.filter(x => x.messages.length > 0)];
    //#endregion

    // sort message
    this.chatMessages.forEach((chat) => {
      if (chat.messages) {
        this.sortMessages(chat.messages);
      }
    });

    // sort group
    this.chatMessages.sort((a, b) => {
      if (a.messages && b.messages) {
        var date1 = a.dateServerTimeStamp;
        var date2 = b.dateServerTimeStamp;
        if (date1 > date2) return 1;
        if (date1 < date2) return -1;
        return 0;
      }
    });
  }

  sortMessages(messages: MessageState[]) {
    MsgRenderer.sortMessages(messages);
  }

  // when comparing MessageStates, use this equality func to avoid accidentally not comparing by tempId.
  private isEqual(x: MessageState, y: MessageState): boolean {
    if (x.id && y.id) return x.id === y.id;
    if (x.tempId && y.tempId) return x.tempId === y.tempId;
    return false; // given msg states cannot be compared
  }
  //#endregion

  //#region buffer
  // increase buffer size
  async nextPage() {
    this.bufferLoading.next(true);
    let cursor = this.inMemMsgSelector.getBackwardCursor(this.room.matrixId);
    //if end of buffer, check if there is more message
    console.log("ishistoryloaded " + this.room.isHistoryLoaded)
    if (!this.room.isHistoryLoaded) {
      if (this.fullMessages.length < this.bufferSize * (this.currentPage + 1)) {
        // if fullMessages does not contain the next page, attempt to get next batch of messages from server
        await this.roomHub.getRoomHistory(
          this.room.matrixId,
          cursor
        ).then(async (roomHistory) => {
          console.log(this.room.lastBatchNumber)
          console.log(roomHistory.room.lastBatchNumber)
          let msgs: MessageState[] = roomHistory.msgs;

          let newMessages = msgs.filter(e => !this.fullMessages.find(a => e.id === a.id));
          console.log(newMessages)
          // this.fullMessages.push(...newMessages);
          // await this.sortMessages(this.fullMessages);
          // await this.loadNextPage();

          // if there are new messages, load next page whenever the next page messages is loaded into memory
          if (newMessages.length > 0) {
            var lastMsg = this.chatMessages[0].messages[0];
            console.log(lastMsg)
            console.log(roomHistory)
            this.loadNextPage$.next(lastMsg.serverTimeStamp.toString());
          } else {
            this.bufferLoading.next(false);
          }
        });
      } else {
        // load next page if next page exists in fullMessages
        await this.loadNextPage();

        this.bufferLoading.next(false);
      }
    } else {

      let lastMessage = this.chatMessages[0].messages[0];
      // load next page
      await this.loadNextPage();

      this.bufferLoading.next(false);
    }
  }

  loadPageNumber(pageNumber, anchorToLastPage = true) {
    this.currentPage = pageNumber - 1;
    this.loadNextPage(anchorToLastPage);
  }

  async loadNextPage(anchorToLastPage = true) {
    // console.log("[ChatRoom] LoadNext Page")
    let lastMessage = this.chatMessages[0].messages[0];
    // ensure there are messages to be added to the buffer
    if (this.fullMessages.length >= this.bufferSize * (this.currentPage)) {
      this.currentPage++;
      // get list of messages
      let newMessageList = this.fullMessages.slice(
        -(this.bufferSize * this.currentPage)
      );

      // remove messages that exist in buffer
      let newMessages = newMessageList.slice(
        -this.bufferSize * this.currentPage,
        -this.bufferSize * (this.currentPage - 1)
      );
      // add newMessages to buffer
      this.insertMessagesToBuffer(newMessages, false);
      if (anchorToLastPage) {
        // anchor chat message window to previous last message
        this.anchorToMessage(lastMessage.serverTimeStamp);
      }
    }

    this.updateNextPageButton();
  }
  //#endregion

  dropped(event) {
    if (event.type !== "") {
      // check for file object (not sure if this is the best way)
      this.file = event;
    }
  }


  getTime(timestamp) {
    return new Date(timestamp)
  }

  /**
   * Creates new message
   * @param message
   */
  async onMsgSend(data: {
    content: string;
    isEncrypted: boolean;
    msgType: MessageType;
    file?: FileObjState;
    meetingTitle?: string;
    startDate?: Date;
    endDate?: Date;
    recurrence?: string;
    replyTo?: string;
  }) {
    this.highlightMsgId = null;
    if (data.msgType == MessageType.Image || data.msgType == MessageType.File) {
      this.sendAttachment(data.file, data.content, data.isEncrypted, data.replyTo);
    } else if (data.msgType == MessageType.Meet) {
      this.roomHub
        .sendMeet(this.room.id, data.content, data.isEncrypted, data.replyTo)
        .subscribe(
          (tempId) => {
            console.log("[sendMeet] success: %s", tempId);
          },
          (error) => {
            console.log("[sendMeet] failed: %s", error);
          }
        );
    } else if (data.msgType == MessageType.Calendar) {
      this.roomHub
        .sendCalendarMeet(
          this.room.id,
          data.content,
          data.meetingTitle,
          data.isEncrypted,
          data.startDate,
          data.endDate,
          data.recurrence,
          data.replyTo
        )
        .subscribe(
          (tempId) => {
            console.log(
              "[MsgComposer] sendCalendarMeeting to msg state: %s",
              tempId
            );
          },
          (error) => {
            console.error(
              "[MsgComposer] sendCalendarMeeting failed: %s",
              error
            );
          }
        );
    } else if (data.content) {
      // send normal text
      this.roomHub
        .sendText(this.room.id, data.content, false, data.isEncrypted, data.replyTo)
        .subscribe(
          (tempId) => {
            console.log("[sendText] success: %s", tempId);
          },
          (error) => {
            console.log("[sendText] failed: %s", error);
          }
        );
    }
  }

  //#region attachment methods
  async sendAttachment(fileObj: FileObjState, message: string, isEncrypted: boolean, replyTo?: string) {
    if (fileObj.type === FileType.Image) {
      this.roomHub.sendImage(this.room.id, message, fileObj, isEncrypted, replyTo).subscribe(
        (tempId) => {
          console.log("[add to sendImage queue] success: %s", tempId);
        },
        (error) => {
          console.log("[add to sendImage queue] failed: %s", error);
        }
      );
    } else if (fileObj.type === FileType.File) {
      this.roomHub.sendFile(this.room.id, message, fileObj, isEncrypted, replyTo).subscribe(
        (tempId) => {
          console.log("[add to sendFile queue] success: %s", tempId);
        },
        (error) => {
          console.log("[add to sendFile queue] failed: %s", error);
        }
      );
    }
  }
  //#endregion

  // trackMsg(index, item: MessageState) {
  //   return item ? item.status + item.plaintext : undefined;
  // }

  // Necessary parts of the trackBy id:
  // plaintext: to update msg when text is edited
  // tempId: to distinguish between msgs with the same status + plaintext
  trackMsg(index, item: MessageState) {
    return item ? item.status + item.plaintext + item.tempId : undefined;
  }

  trackCM(index, item: ChatMessage) {
    return item ? item.messages.length : undefined;
  }

  ngOnDestroy() {
    this.changeDetectorRef.detach();
    if (this._roomStatusSub) this._roomStatusSub.unsubscribe();

    if (this._sub) this._sub.unsubscribe();
    if (this.resizeObserver) this.resizeObserver.disconnect();
  }

  getActiveParticipantCount() {
    return (this.room && this.room.participants) ? this.room.participants.length : 0;
  }

  checkMsgIsLastMsgAndIsDecrypted(msg: MessageState) {
    var isLastMsg = false
    if (this.room.lastMsg.id) {
      isLastMsg = this.room.lastMsg.id == msg.id
    }
    return isLastMsg && msg.isDecrypted === DecryptStatus.Fail;
  }

  getTotalMessagesOnDisplay() {
    var count = 0;
    this.chatMessages.forEach(cm => {
      count += cm.messages.length;
    });
    return count;
  }

  onCancelReply($event){
    this.replyMsgContent = null;
    this.replyMsgId = null;
  }

  isMessageReactable(message: MessageState){
    return (
      message.status == MessageStatus.Valid &&
      message.sendStatus === MessageSendStatus.Sent
    );
  }

  onSmileyClicked(evt: any, message: MessageState){    
    let el = new ElementRef(evt.currentTarget);
    const rect = el.nativeElement.getBoundingClientRect();

    let existing = message.reactions.find((r) =>
      r.senders.some((s) => s === this.userMatrixId)
    );
    const dialogRef = this.dialog.open(DialogSelectReactionComponent, {
      width: "245px",
      height: "80px",
      backdropClass: "invisible-backdrop",
      panelClass: "no-padding-rounded-dialog",
      position: { left: `${rect.left}px`, top: `${rect.bottom - 50}px` },
      data: {
        selected: existing ? existing.content : null,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == null) return;
      let msg = this.msgSelector.getMessage(message.id);
      if (msg == null) {
        this.showSnackBar("Message not found");
        return;
      }

      let reactions = _.clone(msg.reactions);
      //select new 
      if (res.selected) {
        let index = reactions.findIndex(
          (r) => r.content === res.selected
        );
        if (index == -1) {
          //remove user existing reaction if any
          reactions = this.removeExistingReactions(reactions);
          let react = new MsgReaction();
          react.content = res.selected;
          react.senders.push(this.userMatrixId);
          reactions.push(react);
        } else {
          let existing = reactions[index];
          if (!existing.senders.find((s) => s == this.userMatrixId)) {
            //remove user existing reaction if any
            reactions = this.removeExistingReactions(reactions);

            existing.senders.push(this.userMatrixId);
          } else {
            return;
          }
        }
      } else if (res.unselected) {//unselect
        let index = reactions.findIndex(
          (r) => r.content === res.unselected
        );
        if (index == -1) return;
        let existing = reactions[index];
        let senderIndex = existing.senders.findIndex(
          (s) => s == this.userMatrixId
        );
        if (senderIndex == -1) return;
        existing.senders.splice(senderIndex, 1);
        reactions = reactions.filter((r) => r.senders.length > 0);
      }
      this.roomHub
        .reactMsg(this.room.matrixId, message.id, reactions)
        .then((res) => {
          message.reactions = res;
        })
        .catch((err) => {
          this.showSnackBar(err);
        });
    });
  }

  private removeExistingReactions(reactions: MsgReaction[]){
    reactions.forEach((r) => {
      let toRemove = r.senders.findIndex((s) => s === this.userMatrixId);
      if (toRemove != -1) {
        r.senders.splice(toRemove, 1);
      }
    });

    return reactions.filter((r) => r.senders.length > 0);
  }

  private showSnackBar(msg: string){
    this.snackBar.open(msg, "OK", {
      duration: 3000,
    });
  }
  
}

export class ChatMessage {
  get roomId(): string {
    if (this.messages && this.messages.length > 0) {
      return this.messages[0].roomId;
    } else return null;
  }
  get date(): string {
    if (this.messages && this.messages.length > 0) {
      return new Date(this.messages[0].serverTimeStamp).toLocaleDateString()
    } else return null;
  }
  get dateServerTimeStamp() {
    if (this.messages && this.messages.length > 0) {
      return this.messages[0].serverTimeStamp
    } else return null;
  }
  messages: MessageState[] = [];
}
