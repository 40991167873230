import { Injectable, Injector } from "@angular/core";
import { Store, Selector, createSelector } from "@ngxs/store";

import { MessagingStateModel, MessagingState } from "./messaging.state";
import { Observable } from "rxjs";
import { ImmutableSelector } from "@ngxs-labs/immer-adapter";
import { contains } from "../util/array.extension";
import { filter, map, flatMap } from "rxjs/operators";
import { RoomState } from "../model/room.state";
import { MessageState, DecryptStatus } from "../model/message.state";
import { RoomType } from "../model/room.model";
import { ParticipantState } from "../model/participant.state";
import { OrgState, OrgUserState } from "../model/org.state";
import { MessageSendStatus } from "../model/message.model";
import { EntityStatus } from "../enum/entity-status.enum";
import { ParticipantStatus } from "../model/participant.model";
import { EnterpriseState } from "./enterprise.state";
import { UserDataState } from "./user-data.state";
import { UserDataSelector } from "./user-data.state.selector";
import { EnterpriseSelector } from "./enterprise.state.selector";
import { OrgType } from "../enum/org-type";
import { StringDictionary } from '../util/dictionary';
import * as _ from "lodash";

@Injectable({
  providedIn: "root"
})
export class MessagingSelector {
  private static userStateSelector: UserDataSelector;
  private static enterpriseStateSelector: EnterpriseSelector;
  constructor(public store: Store, injector: Injector) {
    MessagingSelector.userStateSelector = injector.get<UserDataSelector>(
      UserDataSelector
    );
    MessagingSelector.enterpriseStateSelector = injector.get<
      EnterpriseSelector
    >(EnterpriseSelector);
  }

  //#region  snapshot
  getRooms() {
    const rooms = this.store.selectSnapshot<RoomState[]>(
      state => state.messaging.rooms
    );
    return [...rooms];
  }

  getRoom(roomId: string): RoomState | null {
    const room = this.getRooms().find(r => r.id === roomId);
    return room;
  }

  getRoomByMatrixId(matrixId: string): RoomState | null {
    const room = this.getRooms().find(r => r.matrixId === matrixId);
    return room;
  }

  getMessages(roomId: string): MessageState[] | null {
    const messages = this.store.selectSnapshot(MessagingState.allMessages);
    return messages.filter(m => m.roomId === roomId);
  }

  getMessage(msgId: string): MessageState | null {
    const msgs = this.store.selectSnapshot(MessagingState.allMessages);
    const msg = _.find(msgs, (m) => (m.id && m.id === msgId) || (m.tempId && m.tempId === msgId));
    return msg ? _.clone(msg) : null;
  }

  getMessage$(msgId: string): Observable<MessageState> {
    return this.store.select(MessagingSelector.message(msgId));
  }

  static message(msgId: string) {
    return createSelector(
      [MessagingState.allMessages],
      (msgs: MessageState[]) => {
        const msg = _.find(msgs, (m) => m.id === msgId || m.tempId === msgId);
        return msg ? _.cloneDeep(msg) : null;
      }
    );
  }

  getRoomsByType(type: RoomType) {
    const rooms = this.store.selectSnapshot<RoomState[]>(
      state => state.messaging.rooms
    );
    return rooms.filter(i => i.type === type);
  }

  getCurrentOrgRooms() {
    const rooms = this.store.selectSnapshot(MessagingSelector.currentOrgRooms);
    return rooms.filter((x) => x.status === EntityStatus.Active);
  }

  getParticipants() {
    const participants = this.store.selectSnapshot<ParticipantState[]>(
      state => state.messaging.participants
    );
    return [...participants];
  }

  getRoomParticipants(roomId: string) {
    const participants = this.store.selectSnapshot<ParticipantState[]>(
      state => state.messaging.participants
    );
    return participants.filter(p => p.roomId === roomId);
  }

  getRoomParticipant(roomId: string, userId: string): ParticipantState {
    return this.getRoomParticipants(roomId).find(p => p.userId === userId);
  }

  getMessageFlags(msgId: string): string[] {
    const found = this.store.selectSnapshot(MessagingSelector.messageFlags(msgId));

    return found ? [...found] : [];
  }

  getFlags(): StringDictionary {
    const found = this.store.selectSnapshot(MessagingState.flags);

    return found;
  }

  getUnreads(): StringDictionary {
    const dict = this.store.selectSnapshot(MessagingState.unreads);
    return dict;
  }

  isRoomUnread(thread: string): boolean {
    const dict = this.getUnreads();
    return dict.containsKey(thread);
  }

  //#endregion

  //#region Selector

  @Selector([MessagingState.allMessages, UserDataState.publicKey])
  @ImmutableSelector()
  static encryptedMessages(
    messages: MessageState[],
    publicKey: string
  ): MessageState[] {
    if (!publicKey) return [];
    var result = messages.filter((m) =>
      m.isEncrypted && m.isDecrypted === DecryptStatus.Pending && m.sendStatus === MessageSendStatus.Sent
    );
    return _.cloneDeep(result);
  }

  @Selector([MessagingState.rooms, UserDataState.publicKey])
  @ImmutableSelector()
  static encryptedMsgPreviews(
    rooms: RoomState[],
    publicKey: string
  ): MessageState[] {
    if (!publicKey) return [];
    let result: MessageState[] = [];
    rooms.forEach((room) => {
      if (room.lastMsg && room.lastMsg.isEncrypted && room.lastMsg.isDecrypted === DecryptStatus.Pending) {
        result.push(room.lastMsg);
      }
    })
    return _.cloneDeep(result);
  }

  @Selector([MessagingState.rooms, EnterpriseState.selectedOrg])
  @ImmutableSelector()
  static currentOrgRooms(
    rooms: RoomState[],
    selectedOrg: OrgState
  ): RoomState[] {
    if (!selectedOrg) return [];

    if (selectedOrg.type == OrgType.Business) {
      var result = rooms.filter(r => !!selectedOrg && r.orgId === selectedOrg.id);
      return _.cloneDeep(result);
    } else {
      var result = rooms.filter(r => EnterpriseState.isSameOrg(selectedOrg, r.orgId));
      return _.cloneDeep(result);
    }
  }

  @Selector([MessagingState.allMessages, EnterpriseState.selectedOrg]) //
  @ImmutableSelector()
  static currentOrgMessages(
    msgs: MessageState[],
    selectedOrg: OrgState
  ): MessageState[] {
    //return [...msgs];
    if (!selectedOrg) return [];
    if (selectedOrg.type === OrgType.Personal) {
      var result = msgs.filter(m => EnterpriseState.isSameOrg(selectedOrg, m.orgId));
      return _.cloneDeep(result);
    }
    var result = msgs.filter(r => r.orgId === selectedOrg.id);
    return _.cloneDeep(result);
  }

  @Selector([MessagingState.participants, MessagingSelector.currentOrgRooms])
  @ImmutableSelector()
  static currentOrgParticipants(
    participants: ParticipantState[],
    rooms: RoomState[]
  ): ParticipantState[] {
    const roomIds = rooms.map(r => r.id);
    var result = participants.filter(p => contains(roomIds, r => r === p.roomId));
    return _.cloneDeep(result);
  }

  @Selector([MessagingState.participants, UserDataState.userId])
  @ImmutableSelector()
  static currentUserParticipants(
    participants: ParticipantState[],
    userId: string
  ): ParticipantState[] {
    if (!userId) return [];
    var result = participants.filter(m => m.userId === userId);
    return _.cloneDeep(result);
  }

  @Selector([MessagingSelector.currentOrgRooms, MessagingState.unreads]) //
  @ImmutableSelector()
  static currentMsgUnreadCount(rooms: RoomState[], unreads: StringDictionary): number {

    if (!rooms || rooms.length == 0) return 0;
    if (!unreads) return 0;

    const arr: number[] = _.map(rooms, (c: RoomState) => unreads.count(c.id));

    var result = _.reduce(
      arr,
      (sum, c) => sum + c,
      0
    );
    return _.cloneDeep(result);
  }

  room(roomId: string): Observable<RoomState> {
    return this.store.select(MessagingSelector.room(roomId));
  }

  static room(roomId: string) {
    return createSelector([MessagingState.rooms], (rooms: RoomState[]) => {
      const room = rooms.find(o => o.id === roomId);
      return room ? _.cloneDeep(room) : null;
    });
  }

  roomMessages(roomId: string): Observable<MessageState[]> {
    return this.store.select(MessagingSelector.roomMessages(roomId));
  }

  static roomMessages(roomId: string) {
    return createSelector(
      [MessagingState.allMessages],
      (messages: MessageState[]) => {
        let msgs = messages.filter(o => o.roomId === roomId);
        return _.cloneDeep(msgs);
      }
    );
  }

  static pendingMessages() {
    return createSelector(
      [MessagingState.allMessages],
      (messages: MessageState[]) => {
        var result = messages.filter(
          o => o.sendStatus === MessageSendStatus.PendingToSent
        );
        return _.cloneDeep(result);
      }
    );
  }

  roomParticipants(roomId: string): Observable<ParticipantState[]> {
    return this.store.select(MessagingSelector.roomParticipants(roomId));
  }

  static roomParticipants(roomId: string) {
    return createSelector(
      [MessagingState.participants],
      (participants: ParticipantState[]) => {
        var result = participants.filter(o => o.roomId === roomId);
        return _.cloneDeep(result);
      }
    );
  }

  roomUnreadCount(roomId?: string): Observable<number> {
    if (roomId) {
      this.store.select(MessagingState.unreads)
        .pipe(
          map(unread => {
            return unread.count(roomId);
          })
        );
    } else {
      return this.store.select(MessagingSelector.currentMsgUnreadCount);
    }
  }

  getDirectRoom(
    senderId: string,
    receiverId: string,
    currentOrgId: string
  ): RoomState {
    const existingRooms = this.getRoomsByType(RoomType.Direct).filter(
      r =>
        r.status !== EntityStatus.Deleted &&
        r.participants.indexOf(senderId) > -1 &&
        r.participants.indexOf(receiverId) > -1
    );

    //find active direct room
    return this.getParticipants()
      .filter(
        p =>
          p.status != ParticipantStatus.Leaved &&
          existingRooms.findIndex(r => r.id === p.roomId) > -1
      )
      .map(p => existingRooms.find(r => r.id === p.roomId))
      .find(r => r.status === EntityStatus.Active && r.orgId === currentOrgId);
  }

  getCurrentOrgUserInRoom(roomId: string): OrgUserState[] {
    const users = MessagingSelector.enterpriseStateSelector.getCurrentOrgUsers();
    return this.getRoomParticipants(roomId)
      .filter(p => p.status != ParticipantStatus.Leaved)
      .map(p => users.find(u => u.userId === p.userId));
  }

  messageFlags(msgId: string): Observable<string[]> {
    return this.store.select(MessagingSelector.messageFlags(msgId));
  }

  static messageFlags(msgId: string) {
    return createSelector(
      [MessagingState.flags],
      (flags: StringDictionary) => {
        const founds = flags.findKeys(msgId);
        return founds ? _.cloneDeep(founds) : [];
      }
    );
  }

  //#endregion
}
