export class Coupon {
    couponId: string;
    name: string;
    isValid: boolean;
    percentOff: number;


    constructor() {
        this.couponId = "";
        this.isValid = false;
    }
}
