import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgxTurnstileComponent } from "ngx-turnstile";
import { filter, firstValueFrom } from "rxjs";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "el-turnstile",
  templateUrl: "./turnstile.component.html",
  styleUrls: ["./turnstile.component.scss"],
})
export class TurnstileComponent implements OnInit {
  turnstileEnabled: boolean;
  siteKey: string;
  @ViewChild("turnstile") turnstileComponent: NgxTurnstileComponent;

  @Output()
  captcha = new EventEmitter<string>();

  private _token: string;

  constructor() { }

  ngOnInit(): void {
    this.siteKey = environment.turnstileSiteKey;
    this.turnstileEnabled = environment.turnstileEnabled;
   }

  async getToken(): Promise<string> {
    if (!this.turnstileEnabled) return Promise.resolve(null);
    if (this._token) {
      const token = this._token;
      this._token = null;
      return Promise.resolve(token);
    }

    let token = await this._reset();
    this._token = null;
    return token;
  }

  sendCaptchaResponse(captchaResponse: string) {
    if (captchaResponse != null) {
      // console.log(
      //   `[el-turnstile] Resolved captcha with response: ${captchaResponse}`
      // );
      this._token = captchaResponse;
      this.captcha.emit(captchaResponse);
    }
  }

  private _reset() {
    if (this.turnstileComponent != null) {
      var promise: Promise<string> = firstValueFrom(
        this.turnstileComponent.resolved.pipe(filter((r) => r != null))
      );
      this.turnstileComponent.reset();

      return promise;
    }

    return Promise.reject("No captcha component initialized");
  }
}
