export class FilenameToMimeType {
    static Convert(fileName): string {
        if (!fileName) return null;
        var ext = fileName.split(".").pop();
        switch (ext) {
            case "jpg":
            case "jfif":
            case "jfi":
                return "image/jpeg";
            case "jpeg":
            case "png":
            case "PNG":
            case "bmp":
            case "tif":
            case "tiff":
            case "webp":
            case "gif":
                return "image/" + ext;
            case "wav":
            case "aac":
            case "midi":
            case "opus":
                return "audio/" + ext;
            case "mid":
                return "audio/midi";
            case "weba":
                return "audio/webm";
            case "mp3":
                return "audio/mpeg";
            case "oga":
                return "audio/ogg";
            case "pdf":
                return "pdf";
            default:
                return null;
        }
    }
}