import { StorageModule } from './../storage/storage.module';
import { SelfSignUpComponent } from './../pages/self-sign-up/self-sign-up.component';
import { AuthGuard } from '../core/auth/auth.guard'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { MainPageComponent } from '../pages/main-page/main-page.component';
import { DashboardPageComponent } from '../pages/dashboard-page/dashboard-page.component';
import { NgModule } from '@angular/core';
import { PageNotFoundComponent } from '../pages/page-not-found/page-not-found.component';
import { LoginPageComponent } from '../pages/login-page/login-page.component';
import { MeetPageComponent } from '../pages/meet-page/meet-page.component';
import { SignUpPageComponent } from '../pages/sign-up-page/sign-up-page.component';
import { InvitePageComponent } from '../pages/invite-page/invite-page.component';
import { ForgotPasswordPageComponent } from '../pages/forgot-password-page/forgot-password-page.component';
import { ForgotResetComponent } from '../pages/forgot-password-page/forgot-reset/forgot-reset.component';
import { VerifyMembershipComponent } from '../pages/verify-membership/verify-membership.component';
import { RequestToJoin2Component } from '../pages/request-to-join/request-to-join-2.component';
import { SimpleSignUpComponent } from '../pages/simple-sign-up/simple-sign-up.component';
import { BlankPageComponent } from '../pages/blank-page/blank-page.component';
import { StorageComponent } from '../storage/storage.component';
import { OrgType } from '../core/enum/org-type';
import { RoleEntity } from '../core/model/userRole.model';
import { BrowserSupportComponent } from '../pages/browser-support/browser-support.component';
import { JoinMeetPageComponent } from '../pages/join-meet-page/join-meet-page.component';
import { RedirectPageComponent } from '../pages/redirect-page/redirect-page.component';
import { RouteBlockGuard } from '../core/ui/route-block.guard';
import { InitialDataResolver } from 'app/app.resolvers';
import { ActivityPageComponent } from '../pages/activity-page/activity-page.component';

const APP_ROUTES: Routes = [

  {
    path: 'main', canActivate: [AuthGuard, RouteBlockGuard], resolve: { initialData: InitialDataResolver }, canActivateChild: [AuthGuard], component: MainPageComponent, children: [
  // {
  //   path: 'main', canActivate: [AuthGuard, RouteBlockGuard], canActivateChild: [AuthGuard], component: MainPageComponent, children: [
      { path: 'dashboard', component: DashboardPageComponent,        
        data: {
          pageTitle: 'Dashboard'
        } ,
        loadChildren: () => import('../pages/dashboard-page/dashboard.module').then(m => m.DashboardModule)
      },
      { path: 'activity', component: ActivityPageComponent,        
        data: {
          pageTitle: 'Activity'
        } ,
        loadChildren: () => import('../pages/activity-page/activity.module').then(m => m.ActivityModule)
      },
      { path: 'profile', loadChildren: () => import('../user-profile/user-profile.module').then(m => m.UserProfileModule) },
      {
        path: 'org', loadChildren: () => import('../organization/organization.module').then(m => m.OrganizationModule),
        data: {
          permission: {
            elPermissions: ['OU_UPDATE', 'OU_READ', 'OU_GROUP_UPDATE', 'OU_USER_READ'],
            orgType: OrgType.Business
          }
        }
      },
      {
        path: 'operation', loadChildren: () => import('../operation/operation.module').then(m => m.OperationModule),
        data: {
          permission: {
            elPermissions: ['OU_DELEGATECLIENT_CREATE'],
            orgType: OrgType.Business
          }
        }
      },
      {
        path: 'directory', loadChildren: () => import('../directory/directory.module').then(m => m.DirectoryModule)
      },
      {
        path: 'teams', loadChildren: () => import('../teams/teams.module').then(m => m.TeamsModule)
      },
      {
        path: 'feed', loadChildren: () => import('../teams/teams.module').then(m => m.TeamsModule),
        data: {
          permission: {
            orgType: OrgType.Personal
          }
        }
      },
      {
        path: 'chat', loadChildren: () => import('../chat/chat.module').then(m => m.ChatModule)
      },
      {
        path: 'tools',
        loadChildren: () => import('../tools/tools.module').then(m => m.ToolsModule)
      },
      {
        path: 'tools', loadChildren: () => import('../tools/tools.module').then(m => m.ToolsModule),
      },
      {
        path: 'friends', loadChildren: () => import('../friends/friends.module').then(m => m.FriendsModule),
        data: {
          permission: {
            orgType: OrgType.Personal
          }
        }
      },
      {
        path: 'storage', loadChildren: () => import('../storage/storage.module').then(m => m.StorageModule),
      },
      {
        path: 'help', loadChildren: () => import('../help/help.module').then(m => m.HelpModule)
      },
      {
        path: 'openmeet', loadChildren: () => import('../open-meet/open-meet.module').then(m => m.OpenMeetModule),
        data: {
          permission: {
            roles: [RoleEntity.ADMIN, RoleEntity.COWORKER, RoleEntity.OWNER],
            orgType: OrgType.Business
          }
        }
      },
      //{ path: '', redirectTo: 'dashboard', pathMatch: 'prefix' },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'prefix' }
    ]
  },
  { path: 'browser-support', component: BrowserSupportComponent },
  { path: '404', component: PageNotFoundComponent },
  { path: 'login', component: LoginPageComponent },
  { path: 'login/:reqJoinToken', component: LoginPageComponent },
  { path: 'meet', component: MeetPageComponent },
  { path: 'meet/join/:room', component: JoinMeetPageComponent },
  { path: 'register', component: SimpleSignUpComponent },
  { path: 'signup', component: SelfSignUpComponent },
  { path: 'register/sub/:type', component: SignUpPageComponent },
  { path: 'register/:token', component: VerifyMembershipComponent },
  { path: 'forgot-password', component: ForgotPasswordPageComponent },
  { path: 'forgot-password/reset', component: ForgotResetComponent },
  { path: 'activate/:token', component: VerifyMembershipComponent },
  { path: 'join/:token', component: RequestToJoin2Component },
  { path: 'multitab', component: BlankPageComponent },
  { path: 'redirect/:token', component: RedirectPageComponent },
  { path: 'storage', component: RedirectPageComponent },
  { path: 'guestezsign', loadChildren: () => import('../pages/guestezsign/guestezsign.module').then(m => m.GuestEzsignModule) },
  { path: 'guestrp', loadChildren: () => import('../pages/reproduction/guestrp.module').then(m => m.GuestRPModule) },
  { path: '', redirectTo: '/main/dashboard', pathMatch: 'prefix' },
  { path: '**', redirectTo: '/main/dashboard', pathMatch: 'prefix' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' }),
  ]
})
export class AppRoutesModule {
}
