import { CipherBlock } from "./cipherBlock";
import { FcpCipher } from "./fcpCipher";
import { KeyPair } from "./keyPair";
import { Injectable } from "@angular/core";
import { pocolog } from "pocolog";
import { Envelope } from "./envelope";
import { ApiService } from "../api/api.service";
import { LocalStorageService } from "../localStorage/local-storage.service";
import { UserDataSelector } from "../states/user-data.state.selector";
import { Emitter, Emittable } from "@ngxs-labs/emitter";
import * as _ from "lodash";
import { UserDataState } from '../states/user-data.state';

@Injectable({
  providedIn: "root",
})
export class FcpService {
  private _keyPair: KeyPair;
  private _envelope: Envelope;

  private MAX_DECRYPT_ATTEMPT: number = 3;

  @Emitter(UserDataState.setPublicKey)
  setPublicKey: Emittable<string>;

  constructor(
    private api: ApiService,
    private localDb: LocalStorageService,
    private userSelector: UserDataSelector,
  ) { }

  async getKeyPair(): Promise<KeyPair> {
    const existing = await this.getKeys();
    if (!existing) {
      const serverExists = await this.checkServerExistKeyPair();
      this._keyPair = serverExists
        ? await this.restoreKeys()
        : await this.initKeys();
      await this.saveKeys(this._keyPair);
    } else {
      const keys = new KeyPair();
      keys.publicKey = existing.publicKey;
      keys.secretKey = existing.secretKey;
      this._keyPair = keys;
    }
    this.setPublicKey.emit(this._keyPair.publicKey);
    return this._keyPair;
  }

  private async getKeys() {
    const keyJson = await this.localDb.getKeys();
    return keyJson ? JSON.parse(keyJson) : null;
  }

  private async saveKeys(keys: KeyPair): Promise<void> {
    const keyJson = JSON.stringify(keys);
    await this.localDb.saveKeys(keyJson); //to save keys when ionic serve to browser
  }

  deleteKeys() {
    return this.localDb.removeKeys();
  }

  private checkServerExistKeyPair(): Promise<boolean> {
    return this.api.getAsync("v2/auth/fcp/check");
  }

  private generateKeyPair(): KeyPair {
    return FcpCipher.generateKeyPair();
  }

  private async getSystemKey(): Promise<string> {
    return this.api
      .getAsync("v2/auth/fcp/sys/pub")
      .then((systemKeyBlock: string) => {
        return Promise.resolve(atob(systemKeyBlock));
      })
      .catch((err) => Promise.reject(err));
  }

  private async initKeys(): Promise<KeyPair> {
    //new key pair
    const newKeys: KeyPair = this.generateKeyPair();

    //get system public key from server
    const systemKey: string = await this.getSystemKey();

    var payload = FcpCipher.initPayload(newKeys, systemKey);

    await this.serverInitKeys(payload);
    return newKeys;

    // return this.serverInitKeys(payload)
    //   .then(() => {
    //     return newKeys;
    //   })
    //   .catch(err => {
    //     pocolog.fatal(err.error);
    //     return null;
    //   });
  }

  private serverInitKeys(payload: string): Promise<string> {
    return this.api.postAsync("v2/auth/fcp", JSON.stringify(payload));
  }

  private serverRestoreKeys(encodedPublicKey: string): Promise<string> {
    return this.api.postAsync(
      "v2/auth/fcp/restore",
      JSON.stringify(encodedPublicKey)
    );
  }

  private async restoreKeys(): Promise<KeyPair> {
    //temp key pair
    const tempKeys: KeyPair = this.generateKeyPair();

    const encodedPublicKey = btoa(tempKeys.publicKey);
    const encodedBlock = await this.serverRestoreKeys(encodedPublicKey);

    const systemKey: string = await this.getSystemKey();

    const block = CipherBlock.decode(encodedBlock);
    const encodedKeys = FcpCipher.open(
      block.secret,
      block.nonce,
      systemKey,
      tempKeys.secretKey
    );
    const result = JSON.parse(atob(encodedKeys));
    var keys = new KeyPair();
    keys.secretKey = result.SecretKey;
    keys.publicKey = result.PublicKey;

    return keys;
  }

  private async getEnvelope(receivers: any[]): Promise<Envelope> {
    let msgKey = FcpCipher.getMessageKey();

    if (!this._keyPair) {
      await this.getKeyPair();
    }

    var env = new Envelope();
    env.addMessagekey(msgKey);
    env.addSender(
      this.userSelector.userId,
      this._keyPair.secretKey,
      this._keyPair.publicKey
    );

    receivers
      .filter((r) => r.userId !== this.userSelector.userId)
      .filter((r) => r.publicKey)
      .forEach((user) => {
        env.addReceiver(user.userId, user.publicKey);
      });

    return Promise.resolve(env);
  }

  public async encryptMsg(text: string, receivers: any[]): Promise<string> {
    return await this.getEnvelope(receivers)
      .then(env => {
        env.encrypt(text);
        //console.log("msg is encrypted from this plaintext: " + text);
        return env.encode();
      })
      .catch(err => {
        pocolog.info(err);
        return Promise.reject(err);
      });
  }

  public decryptMsg(encrypted: string): string {
    try {
      if (!this._keyPair) {
        console.error("Error decrypting. Keypair not found")
        return "";
      }
      this._envelope = Envelope.decode(encrypted);
      if (!this._envelope) return null;

      return this._envelope.decrypt(
        this.userSelector.userId,
        this._keyPair.secretKey
      );
    } catch (err) {
      pocolog.error(err);
      return "";
    }
  }

  // getPlainText(encryptedMsg: string, msgId: string, tempId: string): string {
  //   const decrypted = this.decryptMsg(encryptedMsg);
  //   if (decrypted && decrypted.trim() != "") {
  //     let plaintext = new PlaintextState();
  //     plaintext.msgId = msgId;
  //     plaintext.tempId = tempId;
  //     plaintext.plaintext = decrypted;
  //     this.addPlaintext.emit(plaintext);
  //   }
  //   return decrypted;
  // }

  // getPlaintexts(msgs: MessageState[]) {
  //   msgs.forEach((m) => {
  //     if (m.content && m.content.trim() != "") {
  //       const decrypted = this.decryptMsg(m.content);
  //       if (decrypted && decrypted.trim() != "") {
  //         m.content = decrypted;
  //         m.isDecrypted = DecryptStatus.Success;
  //       } else {
  //         if (!m.decryptAttempt) m.decryptAttempt = 0;
  //         m.decryptAttempt++;
  //         if (m.decryptAttempt >= this.MAX_DECRYPT_ATTEMPT) {
  //           m.isDecrypted = DecryptStatus.Fail;
  //         }
  //       }
  //     } else {
  //       m.isDecrypted = DecryptStatus.NotApplicable;
  //     }
  //   });

  //   return msgs;
  // }
}
