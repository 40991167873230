import { Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { AuthStateSelector } from '../states/auth.state.selector';
import { SubSink } from 'subsink';
@Injectable()
export class IdleService {

  private _subs: SubSink;

  constructor(
    private idle: Idle,
    private auth: AuthService,
    private router: Router,
    private authStateSelector: AuthStateSelector
  ) {
    this._subs = new SubSink();
  }

  initIdle() {
    this.stopIdle();
    this._subs = new SubSink();
    this.idle.setIdle(5);
    this.idle.setTimeout(10800); // 3 hours
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // this.idle.onIdleEnd.subscribe(() => console.log('No longer idle.'));
    this._subs.sink = this.idle.onTimeout.subscribe(async () => {
      console.log('Timed out!')
      this.stopIdle();
      this.auth.logout();
      this.router.navigateByUrl('/login?status=timeout');
    });
    this._subs.sink = this.idle.onIdleStart.subscribe(res => {
      console.log('Idle start')
      // stop timer if not logged in
      if (!this.authStateSelector.isAuthenticated || !this.authStateSelector.isVerified) {
        this.stopIdle();
      }
    });
    // this.idle.onTimeoutWarning.subscribe((countdown) => console.log('You will time out in ' + countdown + ' seconds!'));

    this.idle.watch();
  }

  stopIdle() {
    this.idle.stop();
    this._subs.unsubscribe();
  }
}
