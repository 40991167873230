export class Contract {
    id: string;
    name: string;
    orgName: string;
    createdOn: Date;
    effectiveDate: Date;
    expiredDate: Date;
    appliedSubscriptionId: string;
    remark: string;
    priceInCents: number;
    contractStatus: ContractStatus;
    items: ContractItem[];
    terminatedOn: Date;
    salesperson: string;
    externalId: string;

    constructor() {
        this.items = [];
    }
}

export class ContractItem {
    contractId: string;
    productCode: string;
    productName: string;
    reservedVolume: number;
    unit: string;
    recurrence: RecurrencePeriod;
    resetInterval: ResetInterval;
    usageLevel: UsageLevel;
}

export enum ContractStatus {
    Draft = 0,
    Active = 1,
    Expired = 2,
    Terminated = 3
}

export enum RecurrencePeriod {
    AdHoc = 0,
    Hourly = 1,
    Daily = 2,
    Monthly = 3,
    Yearly = 4
}
export enum ResetInterval {
    None = 0,
    Hourly = 1,
    Daily = 2,
    Monthly = 3,
    Annual = 4
}

export enum UsageLevel {
    Organization = 1,
    Individual = 2
}