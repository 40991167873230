import { Injectable } from "@angular/core";
import { Subscription, of, fromEvent, merge, Observable } from "rxjs";
import { mapTo } from "rxjs/operators";

@Injectable()
export class NetworkService {
  private _isOnline: boolean = false;
  private _sub: Subscription;
  constructor() {
    this._sub = this.onNetworkChanged.subscribe(isOnline => {
      this._isOnline = isOnline;
    });
  }

  get isOnline(): boolean {
    return this._isOnline;
  }

  get onNetworkChanged(): Observable<boolean> {
    return merge(
      of(navigator.onLine),
      fromEvent(window, "online").pipe(mapTo(true)),
      fromEvent(window, "offline").pipe(mapTo(false))
    );
  }
}
