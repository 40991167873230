export function distinct(arr: any[]): any[] {
  return [...Array.from(new Set(arr))];
}

export function contains(
  arr: any[],
  predicate: (value: any, index: number, obj: any[]) => any
): boolean {
  return arr.findIndex(predicate) !== -1;
}

export function notContains(
  arr: any[],
  predicate: (value: any, index: number, obj: any[]) => any
): boolean {
  return arr.findIndex(predicate) === -1;
}

export function flatten(arr: any[]) {
  return arr.reduce((accumulator, value) => accumulator.concat(value), []);
}
