import { PipeTransform, Pipe, NgModule } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
/*
 * Shows date as Time + Today, Yesterday or locale date
*/
@Pipe({ name: 'lastActivity' })
export class LastActivityPipe implements PipeTransform {
  constructor(private translateService: TranslateService, private datepipe: DatePipe){ }
  transform(timeStamp: number): string {

    // nullcheck
    if (!timeStamp) {
      return null;
    }

    let givenTime = new Date(timeStamp);
    let time_string = this.datepipe.transform(givenTime, 'h:mma');
    let date_string = this.datepipe.transform(givenTime, 'MMM-d yyyy');
    
    // Get today's date
    var todaysDate = new Date();
    var yesterdaysDate = new Date(todaysDate);
    yesterdaysDate.setDate(yesterdaysDate.getDate() - 1);


    // call setHours to take the time out of the comparison
    if (givenTime.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
      date_string = 'Today';
    }

    if (givenTime.setHours(0, 0, 0, 0) === yesterdaysDate.setHours(0, 0, 0, 0)) {
      date_string = 'Yesterday';
    }

    // default return
    return `${this.translateService.instant('CHANNEL.LAST_ACTIVITY_AT')} ${time_string} ${date_string}`;
  }

}
