import { NetworkService } from './../services/network.service';
import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { RegistrationDto } from './registration.dto';
import { pocolog } from 'pocolog';
import { MatDialog } from '@angular/material/dialog';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { AuthState } from '../states/auth.state';
import { AuthDataState } from '../model/authData.state';
import { UserProfileState } from '../model/user-profile.state';
import { UserDataState } from '../states/user-data.state';
import { AuthStateSelector } from '../states/auth.state.selector';
import { UserService } from '../services/user.service';
import { UserDataSelector } from '../states/user-data.state.selector';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { DialogOfflineLogoutComponent } from '../ui/dialog/dialog-offline-logout/dialog-offline-logout.component';
import { AppStateSelector } from '../states/app.state.selector';
@Injectable()
export class AuthService {

  @Select(AuthState.isRefreshingToken)
  isRefreshingToken$: Observable<boolean>;

  @Emitter(AuthState.save)
  public saveAuthState: Emittable<AuthDataState>;

  @Emitter(AuthState.clean)
  public cleanAuthState: Emittable<AuthDataState>;

  @Emitter(UserDataState.setUserProfile)
  public setUserProfile: Emittable<UserProfileState>;

  @Emitter(UserDataState.setLoggedIn)
  public setLoggedIn: Emittable<boolean>;

  @Emitter(AuthState.setIsRefreshingToken)
  public setIsRefreshingToken: Emittable<boolean>;

  AUTH_TYPE_2FA = "2fa-auth";
  AUTH_TYPE_DEFAULT = "default-auth";
  REFRESH_TOKEN = "refreshToken";
  EL_PLC = 'https://www.everleagues.com/jwt/claims/plc'; // el policy
  EL_2FA = 'https://www.everleagues.com/jwt/claims/2fa'; // 2fa enabled
  EL_EMV = 'https://www.everleagues.com/jwt/claims/emv'; // email verified
  EL_MID = 'https://www.everleagues.com/jwt/claims/mid'; // matrixId
  private _2faJwt: string

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  tokenRefresherTimer; // setTimeout for refresh token
  get userId(): string {
    return this.userDataSelector.userId;
  }

  // circular dependency resolver
  messagingService: any;
  constructor(private api: ApiService,
    private dialogRef: MatDialog,
    private userService: UserService,
    private networkService: NetworkService,
    private authStateSelector: AuthStateSelector,
    private appSelector: AppStateSelector,
    private userDataSelector: UserDataSelector) {
    this.initAuthService();
  }
  setMessagingService(messagingService: any): void {
    this.messagingService = messagingService;
  }

  getMessagingService(): any {
    return this.messagingService;
  }

  initAuthService() {
    this.authStateSelector.onAccessTokenChanged().subscribe((accessToken) => {
      console.log("[AuthService] Access token changed, resetting timer")
      if (this.tokenRefresherTimer) clearTimeout(this.tokenRefresherTimer);

      this.setTokenRefresher();
    });
  }

  hasSubscription(): Promise<boolean> {
    let email = this.authStateSelector.data.userName;
    if (email) {
      return this.checkSubscription(email).then(res => {
        return Promise.resolve(res);
      }).catch((res: any) => {
        this.api.handleError(res);
        return Promise.reject(false);
      });
    } else {
      Promise.resolve(false)
    }
  }

  parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
  };

  login(email: string, password: string, captcha: string = null, token?: string): Promise<any> {
    var deviceId = this.appSelector.initDeviceId();
    return this.api.postWithCaptchaTokenAsync<any>('v2/auth'
      , { username: email, password: password, deviceId: deviceId, RecaptchaToken: captcha }, token, null, null, null, true)
      .then(data => {
        let token = this.parseJwt(data.accessToken);
        if (token) {
          if (token[this.EL_PLC] === this.AUTH_TYPE_2FA || !token[this.EL_EMV]) {
            this._2faJwt = data.accessToken;
            // return this.saveAccessTokenDetails(data).then(() => {
            return Promise.resolve({
              success: true,
              error: null,
              errorCode: null,
              twoFactorEnabled: data.twoFactorEnabled,
              emailVerified: data.emailVerified,
              recognizedDevice: data.recognizedDevice,
            });
            // });
            //handle in verify2faComponent
            // // call 2fa settings to check for 2fa channel type
            // return this.get2FASettings().then(res => {

            // });
          } else {
            return this.successLogin(data);
          }
        } else {
          this.api.handleError(data);
          return Promise.resolve({
            success: false, error: data.error.error, errorCode: data.error.code
          });
        }
      }).catch((res: any) => {
        this.api.handleError(res);
        pocolog.error(res);
        return Promise.resolve({ success: false, error: res.error.error, errorCode: res.error.code, showCaptcha: res.error.showRecaptcha });
      });
  }

  async logout(offlineLogout: boolean = true): Promise<void> {

    this.dialogRef.closeAll();
    // let fcmToken = this.localStorage.getFcmToken();
    // if (fcmToken) {
    //   this.messagingService.deleteFcmToken(fcmToken);
    // } else {
    //   this.localStorage.clear();
    // }
    if (!this.networkService.isOnline && !offlineLogout) {
      this._offlineLogoutDialog();
      return;
    }

    if (this.authStateSelector.isAuthenticated) {
      //delete fcm token api requires jwt, need to delete before clear jwt
      await this.userService
        .deleteFcmToken()
        .catch((err) => console.error(err));

      await this.revokeToken()
        .catch((err) => console.error(err));
    }
    await this.setLoggedIn.emit(false);

    await this.cleanAuthState.emit(null).toPromise();
    // this.fcp.deleteKeys();
  }

  private _offlineLogoutDialog() {
    const dialog = this.dialogRef.open(DialogOfflineLogoutComponent, {
      width: "500px",
    });

    dialog.afterClosed().subscribe((res) => {
      if (res && res == "retry") {
        this.logout(false);
      } else if (res && res == "proceed") {
        this.logout();
      }
    });
  }

  passcodeCheck(email: string, code: string) {
    return this.api.postAsync<any>('v2/account/passcode/verify', { email: email, code: code })
      .then(res => {
        return Promise.resolve(res);
      }).catch((res: any) => {
        let error = this.api.handleError(res);
        return Promise.resolve({ success: false, error: error, errorCode: res.error.errorCode });
      });
  }

  getPermission(ouId: string) {
    return this.api.getAsync<any>('auth/permission/' + ouId)
      .then(res => {
        return Promise.resolve(res);
      }).catch((res: any) => {
        let error = this.api.handleError(res);
        return Promise.reject({ success: false, error: error, errorCode: res.error.errorCode });
      });
  }


  get2FASettings() {
    return this.api.getAsyncWithJwtToken<any>('v2/auth/2fa/settings', this._2faJwt, this.userId)
      .then(res => {
        return Promise.resolve(res);
      }).catch((res: any) => {
        console.log(res);
        this.api.handleError(res);
        return Promise.resolve({ success: false, error: res.statusText, errorCode: res.status });
      });
  }

  get2FASms() {
    return this.api.getAsyncWithJwtToken<any>('v2/auth/2fa/sms', this._2faJwt, this.userId)
      .then(res => {
        return Promise.resolve(res);
      }).catch((res: any) => {
        console.log(res);
        let error = this.api.handleError(res);
        return Promise.resolve({ success: false, error: error, errorCode: res.status });
      });
  }

  get2FAEmail() {
    return this.api.getAsyncWithJwtToken<any>('v2/auth/2fa/email', this._2faJwt, this.userId)
      .then(res => {
        return Promise.resolve(res);
      }).catch((res: any) => {
        console.log(res);
        let error = this.api.handleError(res);
        return Promise.resolve({ success: false, error: error, errorCode: res.status });
      });
  }

  sendAccountVerificationEmail(email): Promise<AuthStatus> {
    return this.api.postAsync<any>('v2/auth/2fa/signup/email', JSON.stringify(email), this.userId)
      .then(res => {
        return Promise.resolve(res);
      }).catch((res: any) => {
        this.api.handleError(res);
        return Promise.reject(res);
      });
  }

  // send2FASmsWithJWTToken(phone: string, addNewPhone?: boolean): Promise<AuthStatus> {
  //   return this.api.postAsyncWithJwtToken<any>('v2/auth/2fa/sms', this._2faJwt, JSON.stringify(phone), this.userId)
  //     .then(res => {
  //       if (addNewPhone) {
  //         return Promise.resolve({ success: true});;
  //       } else {
  //         return this.successLogin(res);
  //       }
  //     }).catch((res: any) => {
  //       this.api.handleError(res);
  //       return Promise.resolve({ success: false, error: res.error, errorCode: res.status });
  //     });
  // }

  send2FASmsWithELJWTToken(phone: string, addNewPhone?: boolean): Promise<AuthStatus> {
    return this.api.postAsyncWithCredentials<any>('v2/auth/2fa/sms', JSON.stringify(phone), this.userId)
      .then(res => {
        if (addNewPhone) {
          return Promise.resolve({ success: true });;
        } else {
          return this.successLogin(res);
        }
      }).catch((res: any) => {
        this.api.handleError(res);
        return Promise.resolve({ success: false, error: res.error, errorCode: res.status });
      });
  }

  verify2FAPhoneWithELJWTToken(phoneNumber: string, code: string, verifyNewPhone?: boolean): Promise<AuthStatus> {
    let phone = {
      Code: code,
      PhoneNumber: phoneNumber
    }
    return this.api.postAsyncWithJwtToken<any>('v2/auth/2fa/sms/verify', this.authStateSelector.accessToken, phone, this.userId)
      .then(res => {
        if (verifyNewPhone) {
          return Promise.resolve({
            success: true, res: res
          });
        } else {
          return this.successLogin(res);
        }
      }).catch((res: any) => {
        this.api.handleError(res);
        return Promise.resolve({ success: false, error: res.error, errorCode: res.status });
      });
  }

  verify2FAPhoneWithJWTToken(phoneNumber: string, code: string, verifyNewPhone?: boolean): Promise<AuthStatus> {
    let phone = {
      Code: code,
      PhoneNumber: phoneNumber
    }
    return this.api.postAsyncWithJwtToken<any>('v2/auth/2fa/sms/verify', this._2faJwt, phone, this.userId)
      .then(res => {
        if (verifyNewPhone) {
          return Promise.resolve({
            success: true, res: res
          });
        } else {
          return this.successLogin(res);
        }
      }).catch((res: any) => {
        this.api.handleError(res);
        return Promise.resolve({ success: false, error: res.error, errorCode: res.status });
      });
  }


  verify2FAEmailWithJWTToken(code: string): Promise<AuthStatus> {
    return this.api.postAsyncWithJwtToken<any>('v2/auth/2fa/email/verify', this._2faJwt, JSON.stringify(code), this.userId)
      .then(res => {
        return this.successLogin(res, false);
      }).catch((res: any) => {
        this.api.handleError(res);
        return Promise.resolve({ success: false, error: res.error, errorCode: res.status });
      });
  }


  exchangeTo2FAJWT(): Promise<AuthStatus> {
    return this.api.postAsync<any>('v2/auth/2fa/exchange', null, this.userId)
      .then(res => {
        if (res && res.authData) this._2faJwt = res.authData.accessToken;
        return Promise.resolve({ success: true, error: null, errorCode: null, authData: res });
      }).catch((res: any) => {
        this.api.handleError(res);
        console.log(res);
        return Promise.reject({ success: false, error: res.error, errorCode: res.status });
      });
  }

  checkSubscription(email) {
    return this.api.getAsync<any>('v2/auth/subscription', JSON.stringify(email), this.userId)
      .then(res => {
        return Promise.resolve(res);
      }).catch((res: any) => {
        this.api.handleError(res);
        return Promise.reject(res);
      });
  }

  register(data: RegistrationDto): Promise<AuthStatus> {
    {
      return this.api.postAsync<any>('account', data)
        .then(res => {
          //  this.localStorage.setAuth(res);
          return this.successLogin(res, true, false);
          //   return Promise.resolve(res);
        }).catch((res: any) => {
          this.api.handleError(res);
          return Promise.reject(res);
        });
    }
  }

  checkEmail(email: string): Promise<any> {
    return this.api.postAsync<any>('v2/account/email', JSON.stringify(email))
      .then(data => {
        return Promise.resolve(data);
      }).catch(res => {
        this.api.handleError(res);
        return Promise.reject(res.error);
      });
  }

  checkCoupon(planId: string, couponId: string, orgId: string): Promise<any> {
    return this.api.postAsync<any>('payment/coupon/validate', {
      couponId: couponId,
      planId: planId,
      orgId: orgId
    })
      .then(data => {
        return Promise.resolve(data);
      }).catch(res => {
        this.api.handleError(res);
        return Promise.reject(res.error);
      });
  }


  successLogin(authData, clearCache = true, emailVerified = true): Promise<AuthStatus> {
    // if (clearCache) {
    //   this.cleanAuthState.emit(null);
    //   // this.localStorage.clear();
    //   // this.esignauth.clearEsignCache();
    // }
    return this.saveAccessTokenDetails(authData).then(() => {
      let token = this.parseJwt(authData.accessToken);
      console.log(token);
      authData.matrixId = token[this.EL_MID];
      this.setLoggedIn.emit(true);

      // this.localStorage.setAuth(authData);
      // set current app ver
      // this.localStorage.setVersion(environment.version);
      return Promise.resolve({
        success: true,
        twoFactorEnabled: false,
        recognizedDevice: true,
        emailVerified: emailVerified,
        authData: authData,
        showCaptcha: false,
      });
    });
    // return this.fcp.getKeyPair()
    //   .then(() => {
    // return this.ouService.getUserOU();

    // read matrixId from jwt then set authData.matrixId

    //      })
  }

  private saveAccessTokenDetails(data) {
    if (data) {
      // some page requires userId before hub connections
      let profile = this.userDataSelector.userProfile;
      profile.userId = data.id;
      this.setUserProfile.emit(profile);

      let auth = new AuthDataState();
      auth.accessToken = data.accessToken;
      auth.expiredIn = data.expiredIn;
      auth.userName = data.userName;
      return this.saveAuthState.emit(auth).toPromise();
    }
  }

  refreshAccessToken(): Promise<string> {
    let isRefreshingToken = this.authStateSelector.isRefreshingToken;

    if (isRefreshingToken) {
      // Token is refreshing. Duplicated call detected. Waiting for call to complete
      return new Promise((resolve) => {
        let refreshingSub = this.isRefreshingToken$.subscribe(res => {
          if (!res) {
            // Token refresh successfully
            let accessToken = this.authStateSelector.accessToken;
            refreshingSub.unsubscribe();
            resolve(accessToken);
          }
        });
      });
    }
    this.setIsRefreshingToken.emit(true);

    return this.api.postAsyncWithCredentials('v2/auth/refreshToken', null, this.userId)
      .then(res => {
        if (!res) return Promise.reject("refresh token error");
        console.log("refresh success");

        this.saveAccessTokenDetails(res);
        this.setIsRefreshingToken.emit(false);
        return Promise.resolve(res.accessToken);
      }).catch((res: any) => {
        this.setIsRefreshingToken.emit(false);
        return Promise.reject(res.error);
      });
  }

  revokeToken() {
    return this.api.postAsyncWithCredentials<any>('v2/auth/revokeToken', null, this.userId)
      .then(res => {
        return Promise.resolve(res);
      }).catch((res: any) => {
        let error = this.api.handleError(res);
        return Promise.reject(res.error);
      });
  }

  private setTokenRefresher() {
    // set timer to refresh token before jwt expires
    var authData = this.authStateSelector.data;
    if (authData && authData.accessToken) {
      var expiryTimerSeconds = 60 * 20; // default 20mins
      if (authData.expiredIn)
        expiryTimerSeconds = (authData.expiredIn - (60 * 5)) // 5minutes before expiry
      if (expiryTimerSeconds < (60 * 1)) //set min 1 mins
        expiryTimerSeconds = 60 * 1;
      console.log("[AuthService] Token will refresh in %o seconds", expiryTimerSeconds)
      this.tokenRefresherTimer = setTimeout(() => {
        this.refreshAccessToken();
      }, expiryTimerSeconds * 1000);
    }
  }
}

export class AuthStatus {
  success: boolean;
  error?: any;
  errorCode?: any;
  twoFactorEnabled?: boolean;
  emailVerified?: boolean;
  authData?: any;
  user2faSettings?: any;
  showCaptcha?: boolean;
  recognizedDevice?: boolean;
}
