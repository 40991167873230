export class MenuItem {
    code: string;
    icon: string;
    localization: string;
    path: string;
    pathParams: string[];
    isEnabled: boolean;
    showBadge: boolean;

    subMenus: MenuItem[];

    constructor(code: string) {
        this.code = code;
        this.showBadge = false;
        this.isEnabled = true;
        this.subMenus = [];
        this.pathParams = [];
    }

    static create(code: string, path: string, iconUrl: string, localization: string) {
        let item: MenuItem = new MenuItem(code);
        item.path = path;
        item.icon = iconUrl;
        item.localization = localization;
        return item;
    }

    isExtTools(): boolean {
        return MenuItem.isExtTools(this.code);
    }

    static isExtTools(code: string): boolean {
        return code.startsWith("ext-");
    }
}