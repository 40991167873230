import { Subject } from "rxjs";


export class CancellablePromise<T> extends Promise<T> {
  public onCancel: () => void;
  token: CancellationToken;

  constructor(
    executor: (
      resolve: (value?: any) => void,
      reject: (reason?: any) => void
    ) => void,
    onCancel?: () => void,
    token: CancellationToken = null
  ) {
    super(executor);
    this.onCancel = onCancel;

    if (token) {
      this.token = token;
      let sub = this.token.onCancelled.subscribe(() => {
        this.cancel();
        if (sub) sub.unsubscribe();
      });
      this.finally(() => {
        if (sub) sub.unsubscribe();
      });
    }
  }

  static convert<T>(
    promise: Promise<T>,
    onCancel?: () => void,
    token?: CancellationToken
  ): CancellablePromise<T> {
    return new CancellablePromise(
      (resolve, reject) => {
        promise
          .then((res) => {
            if (token && token.isCancelled) {
              return onCancel();
              // throw new CancelledError(this.name);
            } else {
              resolve(res);
            }
          })
          .catch((err) => {
            reject(err);
          });
      },
      onCancel,
      token
    );
  }

  //cancel the operation
  public cancel() {
    if (this.onCancel) {
      this.onCancel();
    }
  }
}

export class CancellationToken {
    token: string;
    status: TokenStatus;
    onCancelled: Subject<void>;
    source: string;
  
    constructor(methodName?: string) {
      //timestamp as token
      this.token = new Date().getTime().toString();
      this.status = TokenStatus.Valid;
      this.onCancelled = new Subject();
      this.source = methodName;
      // console.info("[test]new: %s | %s", this.token, this.source);

    }
  
    cancel() {
      // console.info("[test]cancel: %s | %s", this.token, this.source);
      this.status = TokenStatus.Cancelled;
      this.onCancelled.next();
    }
  
    get isCancelled(): boolean {
      return this.status === TokenStatus.Cancelled;
    }
  }
  
  enum TokenStatus {
    Valid,
    Cancelled,
  }
  
  export class CancelledError extends Error {
    constructor(m?: string) {
      super("Excecution cancelled: " + m);
      this.name = "CancelledException";
  
      // Set the prototype explicitly.
      Object.setPrototypeOf(this, CancelledError.prototype);
    }
  }
  