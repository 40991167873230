import { lastValueFrom } from 'rxjs';
import { Injectable } from "@angular/core";
import * as _ from "lodash";
import { OrgType } from "../enum/org-type";
import { AccessPermission } from "../model/cloud-access-right";
import { CloudFile } from "../model/cloud-file";
import { CloudFolder } from "../model/cloud-folder";
import {
  PickResource,
  PickFile,
  PickFolder,
  PickDriveFolder,
} from "../model/pick-resource.model";
import { Resource } from "../model/resource";
import {
  CheckReadAccessDeleg,
  CreateFolderDeleg,
  DeleteFileDeleg,
  DownloadAsArrayBufferDeleg,
  GotoFolderDeleg,
  PathDeleg,
  SaveArrayBufferToLocationDeleg,
  StorageInitDeleg,
} from "../model/resource-delegate";
import { SharedDriveFolder } from "../model/shared-drive-folder";
import { StorageLocationType } from "../states/storage-location-type.enum";
import { StorageLocationState } from "../states/storage-location.state";
import { CloudFileService } from "./cloud-file.service";
import { OriginFolder } from '../model/origin-folder';
import { SharedFolder } from '../model/shared-folder';
type StorageOptionDelegate = (
  orgId: string,
  orgType: OrgType,
  userId: string,
  data: any
) => Promise<StorageOption>;

@Injectable()
export class StorageLocationService {
  private readonly _cloudUserFolderName: string = "My Drive";
  private readonly _cloudShareFolderName: string = "Shared With Me";
  private readonly _cloudEnterpriseFolderName: string = "Shared Drive";
  private readonly _localFolderName: string = "Local";
  private readonly _defaultLocation = StorageLocationType.CloudUserFolder;
  private readonly _fallbackLocation = StorageLocationType.CloudUserFolder;
  private readonly _storageLocationOptionList: Map<
    StorageLocationType,
    StorageOptionDelegate
  >;
  constructor(private cloudService: CloudFileService) {
    this._storageLocationOptionList = new Map<
      StorageLocationType,
      StorageOptionDelegate
    >([
      [
        StorageLocationType.CloudUserFolder,
        this._prepareCloudUserFolder.bind(this),
      ],
      [
        StorageLocationType.CloudShareFolder,
        this._prepareCloudShareFolder.bind(this),
      ],
      [
        StorageLocationType.CloudEnterprise,
        this._prepareCloudEnterpriseContainer.bind(this),
      ],
    ]);
  }

  async getDefaultChatExportOption(
    orgId: string,
    orgType: OrgType,
    userId: string
  ): Promise<StorageOption> {
    let options = await this.getOptions(orgId, orgType, userId);
    if (options == null || options.length == 0) return null;

    let list = Array.from(this._storageLocationOptionList);
    let option: [StorageLocationType, StorageOptionDelegate];
    option = list.find((o) => o[0] == StorageLocationType.CloudUserFolder);
    if (option == null)
      option = list.find((o) => o[0] == StorageLocationType.CloudEnterprise);
    if (option == null) option = list[0];
    if (option == null) return null;

    let op = await option[1](orgId, orgType, userId, null);
    return op;
  }

  async getOptions(
    orgId: string,
    orgType: OrgType,
    userId: string,
    optionsToInclude?: Set<StorageLocationType>
  ): Promise<StorageOption[]> {
    // let localOption = this._prepareLocal();

    let options: StorageOptionDelegate[] = [];
    let promises = [];
    if (optionsToInclude == null) {
      this._storageLocationOptionList.forEach((e) => {
        options.push(e);
      });
    } else {
      Array.from(this._storageLocationOptionList)
        .filter(
          (l) => Array.from(optionsToInclude).findIndex((i) => i == l[0]) != -1
        )
        .forEach((e) => {
          options.push(e[1]);
        });
    }

    options.forEach((m) => {
      promises.push(m(orgId, orgType, userId, null));
    });

    return Promise.all(promises).then((res) => {
      return [...res].filter((i) => i != null);
    });
  }

  async getCurrentOption(
    type: StorageLocationType,
    orgId: string,
    orgType: OrgType,
    userId: string,
    data: any
  ): Promise<StorageOption> {
    switch (type) {
      case StorageLocationType.CloudShareFolder:
        return await this._prepareCloudShareFolder(orgId, orgType, userId);
      default:
      case StorageLocationType.CloudUserFolder: {
        return await this._prepareCloudUserFolder(orgId, orgType, userId);
      }
      // case StorageLocationType.Local:
      //   return this._prepareLocal();
      case StorageLocationType.CloudEnterprise: {
        return await this._prepareCloudEnterpriseContainer(
          orgId,
          orgType,
          userId,
          data
        );
      }
    }
  }

  // private _prepareLocal(): StorageOption {
  //   let op = new StorageOption(StorageLocationType.Local);
  //   op.name = this._localFolderName;
  //   op.icon = "phone-portrait-outline";
  //   op.checkAuthDeleg = () => Promise.resolve(true);
  //   op.initDeleg = async () => {
  //     let pickFolder = new PickFolder();
  //     pickFolder.isRoot = true;
  //     pickFolder.name = op.name;
  //     //to let user have create folder rights
  //     pickFolder.permission = AccessPermission.Full;
  //     let result = await this.fileService.readDir("");
  //     let resources = result.map((res) =>
  //       PickResource.parse(
  //         res.uri,
  //         res.size,
  //         res.ctime,
  //         res.mtime,
  //         res.type
  //       )
  //     );

  //     return { resource: pickFolder, resources };
  //   };
  //   op.goToFolderDeleg = async (folder: PickFolder) => {
  //     let result = await this.fileService.readDir(folder.path);
  //     let resources = result.map((res) =>
  //       PickResource.parse(
  //         res.uri,
  //         res.size,
  //         res.ctime,
  //         res.mtime,
  //         res.type
  //       )
  //     );

  //     return Promise.resolve(resources);
  //   };
  //   op.createFolderDeleg = async (
  //     resources: PickResource[],
  //     folder: PickFolder,
  //     filename: string
  //   ) => {
  //     let newPath =
  //       folder.path == null
  //         ? filename
  //         : folder.path.endsWith("/")
  //         ? folder.path + filename
  //         : `${folder.path}/${filename}`;
  //     let dir = folder.path == null ? Directory.Documents : null;

  //     await this.fileService.createDir(dir, decodeURIComponent(newPath));
  //     let result = PickFolder.fromPath(newPath);
  //     result.createdOn = new Date().getTime();
  //     return Promise.resolve(result);
  //   };
  //   op.getDataDeleg = (folder: PickFolder) => null;
  //   op.downloadAsArrayBufferDeleg = (
  //     path: string,
  //     accessPath: string,
  //     filename: string,
  //     contentType: string
  //   ) => {
  //     return this.fileService
  //       .readAsBlob(path, contentType)
  //       .then(async (blob) => {
  //         if (blob) {
  //           let arrayBuffer = await blob.arrayBuffer();
  //           return arrayBuffer;
  //         } else {
  //           return Promise.reject("Failed to download file");
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         return Promise.reject(err);
  //       });
  //   };
  //   op.deleteDeleg = (path: string, _) => {
  //     return this.fileService
  //       .deleteFile(null, path)
  //       .then(() => {
  //         return true;
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //         return false;
  //       });
  //   };
  //   // op.saveDeleg = (
  //   //   path: string,
  //   //   folderPath: string,
  //   //   accessPath: string,
  //   //   filename: string,
  //   //   contentType: string
  //   // ) => {
  //   //   let newPath =
  //   //     folderPath == null
  //   //       ? filename
  //   //       : folderPath.endsWith("/")
  //   //       ? folderPath + filename
  //   //       : `${folderPath}/${filename}`;
  //   //   let dir = folderPath == null ? Directory.Documents : null;

  //   //   return this.fileService
  //   //     .writeFile(path, filename, folderPath, dir)
  //   //     .then((res) => {
  //   //       return res != null;
  //   //     });
  //   // };
  //   op.saveDeleg = (
  //     path: string,
  //     folderPath: string,
  //     accessPath: string,
  //     filename: string,
  //     contentType: string,
  //     arrayBuffer: ArrayBuffer
  //   ) => {
  //     return this.fileService.writeFile(arrayBuffer, filename, path, Directory.Documents);
  //   };
  //   return op;
  // }

  private async _prepareCloudUserFolder(
    orgId: string,
    orgType: OrgType,
    userId: string
  ): Promise<StorageOption> {
    let op = new StorageOption(StorageLocationType.CloudUserFolder);
    op.name = this._cloudUserFolderName;
    op.icon = "md-cloud";
    op.checkAuthDeleg = () => this.cloudService.checkUserFolder(orgId);
    let isAuth = await op.checkAuthDeleg().catch((err) => {
      return null;
    });
    if (isAuth == false) return null;
    op.initDeleg = async () => {
      let resource =
        orgType == OrgType.Personal
          ? await this.cloudService.readPersonalFolder()
          : await this.cloudService.readUserFolder(orgId);
      let pickFolder = PickFolder.fromCloudFolder(resource, userId);
      pickFolder.name = op.name;
      pickFolder.isRoot = true;
      //to let user have create folder rights
      pickFolder.permission = AccessPermission.Full;
      let folders = resource.folders.map((res) =>
        PickFolder.fromCloudFolder(res, userId)
      );
      let files = resource.files.map((res) =>
        PickFile.fromCloudFile(res, userId)
      );

      return { resource: pickFolder, resources: files.concat(folders) };
    };
    op.goToFolderDeleg = (folder: PickFolder) => {
      let promise =
        orgType == OrgType.Personal
          ? this.cloudService.readPersonalFolder(folder.path)
          : this.cloudService.readUserFolder(orgId, folder.path);
      return promise.then((res) => {
        let resources: PickResource[] = res.folders.map((res) =>
          PickFolder.fromCloudFolder(res, userId)
        );
        return resources.concat(
          res.files.map((res) => PickFile.fromCloudFile(res, userId))
        );
      });
    };
    op.createFolderDeleg = (
      resources: PickResource[],
      folder: PickFolder,
      name: string
    ) => {
      if (!name || name.trim() == "")
        return Promise.reject("Invalid folder name");
      if (this.cloudService.isNameLengthExceed(name)) {
        return Promise.reject(
          `Name exceeds ${this.cloudService.NAME_MAX_LENGTH} characters. Please shorten the name`
        );
      }
      if (
        resources.find(
          (f) => f.nameWithoutExt == Resource.getNameWithoutExt(name)
        )
      ) {
        return Promise.reject(
          "Name already exists. Please enter another name."
        );
      }

      let promise =
        orgType == OrgType.Personal
          ? this.cloudService.createPersonalFolder(name, folder.path)
          : this.cloudService.createUserFolder(orgId, name, folder.path);

      return promise.then((res) => {
        return PickFolder.fromCloudFolder(res, userId);
      });
    };
    op.getDataDeleg = (folder: PickFolder) => null;
    op.downloadAsArrayBufferDeleg = (
      path: string,
      accessPath: string,
      filename: string,
      contentType: string,
      size: number
    ) => {
      let promise =
        orgType == OrgType.Personal
          ? this.cloudService.downloadPersonalFileAsync(filename, path, size)
          : this.cloudService.downloadUserResourceAsync(
              orgId,
              filename,
              path,
              size
            );

      return promise
        .then((res) => {
          return (
            res.blob.arrayBuffer() ?? Promise.reject("Failed to download file")
          );
        })
        .catch((err) => {
          console.log(err);
          return Promise.reject(err);
        });
    };
    op.deleteDeleg = (path: string) => {
      return orgType == OrgType.Personal
        ? this.cloudService.deletePersonalFolder(path)
        : this.cloudService.deleteUserResource(orgId, path).toPromise();
    };
    op.saveDeleg = async (
      path: string,
      folderPath: string,
      accessPath: string,
      filename: string,
      contentType: string,
      files: File[]
    ) => {
      if (path == null) {
        let resource = OrgType.Personal
          ? await this.cloudService.readPersonalFolder()
          : await this.cloudService.readUserFolder(orgId);
        path = resource.path;
      }

      return this.cloudService
        .streamUserFile(orgId, path, filename, files)
        .then((res) => {
          if (!res) return "";
          return res.name;
        });
    };
    return op;
  }

  private async _prepareCloudShareFolder(
    orgId: string,
    orgType: OrgType,
    userId: string
  ): Promise<StorageOption> {
    let op = new StorageOption(StorageLocationType.CloudShareFolder);
    op.name = this._cloudShareFolderName;
    op.icon = "user-folder";
    op.createInRoot = false;
    op.checkAuthDeleg = () => this.cloudService.checkUserSharedFolder(orgId);
    let isAuth = await op.checkAuthDeleg().catch((err) => {
      return null;
    });
    if (isAuth == false) return null;
    op.initDeleg = async () => { // TODO integrate with shared w me drive file server changes
      let promise =
        orgType == OrgType.Personal
          ? this.cloudService.listPersonalSharedFolder()
          : this.cloudService.listUserSharedFolder(orgId);
      let sharedFolder = await promise;
      let resource = new PickFolder();
      resource.isRoot = true;
      resource.name = op.name;
      let resources: PickResource[] = [];
      if (sharedFolder.files)
        resources = resources.concat(
          sharedFolder.files.map((r) => PickFile.fromSharedFile(r, userId))
        );
      if (sharedFolder.folders)
        resources = resources.concat(
          sharedFolder.folders.map((r) =>
            PickFolder.fromSharedFolder(r, userId)
          )
        );
      return { resource, resources };
    };
    op.goToFolderDeleg = (folder: PickFolder) => { // TODO integrate with shared w me drive file server changes
      if (folder.isRoot) {
        let promise: Promise<SharedFolder> =
          orgType == OrgType.Personal
            ? this.cloudService.listPersonalSharedFolder()
            : this.cloudService.listUserSharedFolder(orgId);

        return promise.then((f) => {
          let res = [];
          if (f.files)
            res = res.concat(
              f.files.map((r) => PickFile.fromSharedFile(r, userId))
            );
          if (f.folders)
            res = res.concat(
              f.folders.map((r) => PickFolder.fromSharedFolder(r, userId))
            );

          return res;
        });
      } else if (folder.dummyFolder) {
        let promise: Promise<OriginFolder> =
          orgType == OrgType.Personal
            ? this.cloudService.readPersonalSharedFolder(folder.path)
            : this.cloudService.readUserSharedOriginFolder(
                orgId,
                folder.dummyFolder.path,
                folder.path
              );
        return promise.then((f) => {
          let res: PickResource[] = [];
          if (f.files)
            res = res.concat(
              f.files.map((r) => PickFile.fromOriginFile(r, userId))
            );
          if (f.folders)
            res = res.concat(
              f.folders.map((r) => PickFolder.fromOriginFolder(r, userId))
            );

          return res;
        });
      } else {
        let promise =
          orgType == OrgType.Personal
            ? this.cloudService.readPersonalSharedFolder(folder.path)
            : this.cloudService.readUserSharedFolder(orgId, folder.path);

        return promise.then((f) => {
          let res: PickResource[] = [];
          if (f.files)
            res = res.concat(
              f.files.map((r) => PickFile.fromOriginFile(r, userId))
            );
          if (f.folders)
            res = res.concat(
              f.folders.map((r) => PickFolder.fromOriginFolder(r, userId))
            );

          return res;
        });
      }
    };
    op.createFolderDeleg = (
      resources: PickResource[],
      folder: PickFolder,
      name: string
    ) => {
      if (!name || name.trim() == "")
        return Promise.reject("Invalid folder name");
      if (this.cloudService.isNameLengthExceed(name)) {
        return Promise.reject(
          `Name exceeds ${this.cloudService.NAME_MAX_LENGTH} characters. Please shorten the name`
        );
      }
      if (
        resources.find(
          (f) => f.nameWithoutExt == Resource.getNameWithoutExt(name)
        )
      ) {
        return Promise.reject(
          "Name already exists. Please enter another name."
        );
      }

      return this.cloudService
        .createUserShareFolder(
          orgId,
          name,
          folder.path,
          folder.originPath
        ).then((res) => {
          return PickFolder.fromCloudFolder(res, userId);
        });
    };
    op.getDataDeleg = (folder: PickFolder) => folder.originPath;
    op.downloadAsArrayBufferDeleg = (
      path: string,
      accessPath: string,
      filename: string,
      contentType: string,
      size: number
    ) => {
      return this.cloudService
        .downloadUserSharedFileAsync(orgId, filename, path, accessPath, size)
        .then((res) => {
          return (
            res.blob.arrayBuffer() ?? Promise.reject("Failed to download file")
          );
        })
        .catch((err) => {
          console.log(err);
          return Promise.reject(err);
        });
    };
    op.deleteDeleg = (path: string, accessPath: string) => {
      return this.cloudService
        .deleteUserSharedItem(orgId, path, accessPath);
    };
    op.saveDeleg = (
      path: string,
      folderPath: string,
      accessPath: string,
      filename: string,
      contentType: string,
      files: File[]
    ) => {
      return this.cloudService
        .streamUserShareFile(orgId, filename, path, accessPath, files)
        .then((res) => {
          if (!res) return "";
          return res.name;
        });
    };
    return op;
  }

  private async _prepareCloudEnterpriseContainer(
    orgId: string,
    orgType: OrgType,
    userId: string,
    driveId: string
  ): Promise<StorageOption> {
    let op = new StorageOption(StorageLocationType.CloudEnterprise);
    op.name = this._cloudEnterpriseFolderName;
    op.icon = "storage";
    op.createInRoot = false;
    let initRoot = async () => {
      let resource = await this.cloudService.listSharedDrives(orgId);
      let pickFolder = PickDriveFolder.fromSharedDriveFolder(resource, userId);
      pickFolder.name = op.name;
      pickFolder.isRoot = true;
      //to let user have create folder rights
      pickFolder.permission = AccessPermission.Read;
      let folders = resource.folders.map((res) =>
        PickDriveFolder.fromSharedDriveFolder(res as SharedDriveFolder, userId)
      );

      return { resource: pickFolder, resources: folders };
    };
    op.checkAuthDeleg = () => this.cloudService.checkSharedDrives(orgId);
    let isAuth = await op.checkAuthDeleg().catch((err) => {
      return null;
    });
    if (isAuth == false) return null;
    op.initDeleg = initRoot;
    op.goToFolderDeleg = (folder: PickDriveFolder) => {
      if (folder.isRoot) {
        return initRoot().then((res) => {
          return res.resources;
        });
      }
      return this.cloudService
        .readDriveFolder(orgId, folder.driveId, folder?.path)
        .then((res) => {
          let resources: PickResource[] = res.folders.map((res) =>
            PickDriveFolder.from(res, userId, folder.driveId)
          );
          return resources.concat(
            res.files.map((res) => PickFile.fromCloudFile(res, userId))
          );
        });
    };
    op.createFolderDeleg = (
      resources: PickResource[],
      folder: PickDriveFolder,
      name: string
    ) => {
      if (!name || name.trim() == "")
        return Promise.reject("Invalid folder name");
      if (this.cloudService.isNameLengthExceed(name)) {
        return Promise.reject(
          `Name exceeds ${this.cloudService.NAME_MAX_LENGTH} characters. Please shorten the name`
        );
      }
      if (
        resources.find(
          (f) => f.nameWithoutExt == Resource.getNameWithoutExt(name)
        )
      ) {
        return Promise.reject(
          "Name already exists. Please enter another name."
        );
      }

      return this.cloudService
        .createDriveFolder(orgId, folder.driveId, name, folder.path)
        .then((res) => {
          return PickDriveFolder.from(res, userId, folder.driveId);
        });
    };
    op.getDataDeleg = (folder: PickDriveFolder) => folder.driveId;
    op.downloadAsArrayBufferDeleg = (
      path: string,
      accessPath: string,
      filename: string,
      contentType: string,
      size: number
    ) => {
      return this.cloudService
        .downloadDriveFileAsync(orgId, driveId, filename, path, size)
        .then((res) => {
          return res.blob.arrayBuffer();
        })
        .catch((err) => {
          console.log(err);
          return null;
        });
    };
    op.deleteDeleg = (path: string) => {
      return this.cloudService.deleteSharedDrive(orgId, path);
    };
    op.deleteDeleg = (path: string) => {
      return this.cloudService
        .deleteDriveFolder(orgId, driveId, path);
    };
    op.saveDeleg = async (
      path: string,
      folderPath: string,
      accessPath: string,
      filename: string,
      contentType: string,
      files: File[]
    ) => {
      if (path == null) {
        let resource = await this.cloudService
          .readDriveFolder(orgId, driveId);
        path = resource.path;
      }

      return this.cloudService
        .streamDriveFile(orgId, driveId, filename, path, files)
        .then((res) => {
          if (!res) return "";
          return res.name;
        });
    };
    return op;
  }
}

export class StorageOption {
  name: string;
  icon: string;
  createInRoot: boolean;
  type: StorageLocationType;
  goToFolderDeleg: GotoFolderDeleg;
  createFolderDeleg: CreateFolderDeleg;
  getDataDeleg: PathDeleg;
  initDeleg: StorageInitDeleg;
  downloadAsArrayBufferDeleg: DownloadAsArrayBufferDeleg;
  deleteDeleg: DeleteFileDeleg;
  saveDeleg: SaveArrayBufferToLocationDeleg;
  checkAuthDeleg: CheckReadAccessDeleg;

  getDefaultLocation(orgId: string, orgType: OrgType): StorageLocationState {
    return new StorageLocationState(
      orgId,
      orgType,
      this.type,
      null,
      this.name,
      true
    );
  }

  constructor(type: StorageLocationType) {
    this.createInRoot = true;
    this.type = type;
  }
}
