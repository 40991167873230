import { Selector, State, StateContext } from "@ngxs/store";
import { Feed } from "../model/feed";
import { Injectable, Injector } from "@angular/core";
import { EnterpriseSelector } from "./enterprise.state.selector";
import { ImmutableContext, ImmutableSelector } from "@ngxs-labs/immer-adapter";
import * as _ from "lodash";
import { EmitterAction, Receiver } from "@ngxs-labs/emitter";

export class InMemFeedStateModel {
    feeds: Feed[];

    constructor() {
        this.feeds = [];
    }
}

@State<InMemFeedStateModel>({
    name: "imfeed",
    defaults: new InMemFeedStateModel(),
})
@Injectable()
export class InMemFeedState {
    private static enterpriseSelector: EnterpriseSelector;

    constructor(injector: Injector) {
        InMemFeedState.enterpriseSelector = injector.get<EnterpriseSelector>(
            EnterpriseSelector
        );
    }

    ngxsAfterBootstrap(ctx: StateContext<InMemFeedStateModel>) {
        console.log("[InMemFeedState] - ngxsAfterBootstrap");
    }

    @Selector([InMemFeedState])
    @ImmutableSelector()
    static feeds(state: InMemFeedStateModel): Feed[] | null {
        const feeds = [...state.feeds];
        feeds.sort((a, b) => {
            if (!a || !b) return 0;
      
            if (a.timestamp > b.timestamp) {
              return -1;
            }
            if (a.timestamp < b.timestamp) {
              return 1;
            }
            return 0;
          });
        return _.cloneDeep(feeds);
    }

    @Receiver()
    @ImmutableContext()
    public static addOrUpdateFeeds(
        ctx: StateContext<InMemFeedStateModel>,
        arg: EmitterAction<Feed[]>
    ) {
        if (!arg) return;
        if (!arg.payload || arg.payload.length == 0) return;
        const payload = arg.payload.filter((p) =>
            this.enterpriseSelector.canSaveState(p.orgId));
        if (payload.length == 0) return;

        const state = ctx.getState();
        const all = [...state.feeds];

        const newList = _.differenceBy(payload, all, "id");
        const existingList = _.intersectionBy(payload, all, "id");

        existingList.forEach((fd) => {
            let index = _.findIndex(all, (o) => o.id === fd.id);

            if (index !== -1) {
                all[index] = this.mutateFeedState(all[index], fd);
            }
        });

        //add new
        if (newList.length > 0) {
            state.feeds = [...all, ...newList];
        } else {
            state.feeds = [...all];
        }
        ctx.setState(state);
    }

    private static mutateFeedState(
        existing: Feed,
        state: Feed
    ) {
        existing.body = state.body;
        existing.title = state.title;
        existing.subTitle = state.subTitle;
        existing.timestamp = state.timestamp;
        existing.status = state.status;
        
        return existing;
    }

    @Receiver() static clean(ctx: StateContext<InMemFeedStateModel>) {
        ctx.setState({ ...new InMemFeedStateModel() });
    }
}