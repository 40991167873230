import { PubSub } from './../services/pubsub.service';
import { AppStateSelector } from './../states/app.state.selector';
import {
  HubConnection,
  HubConnectionBuilder,
  HttpTransportType,
} from "@microsoft/signalr";
import { Observable, from, Subject, Subscription, throwError } from "rxjs";
import { HubEvent } from "./hub.event";
import { filter, map, distinctUntilChanged } from "rxjs/operators";
import { MessagingSelector } from "../states/messaging.state.selector";
import { AuthStateSelector } from "../states/auth.state.selector";
import { NetworkService } from "../services/network.service";
import { Emittable } from "@ngxs-labs/emitter";
import {
  HubConnectionStatus,
  HubConnectionState,
  HubHandshakeStatus,
} from "../model/hubConnection.state";
import * as _ from "lodash";
import { UserDataSelector } from '../states/user-data.state.selector';
import { TokenProvider } from '../services/jwt-token.provider';
import { Select } from '@ngxs/store';
import { AppState } from '../states/app.state';
import { NotificationData } from '../model/notification-data.model';

export abstract class BaseHub {

  @Select(AppState.background)
  background$: Observable<boolean>;

  //Hub listener events
  private NOTIFICATION: string = "notification";

  private CONNECTION_ID: string = "connection-id";
  private _connection: HubConnection;
  private _channels: string[] = [];
  private _networkSub: Subscription;
  private _hubStatusSub: Subscription;
  private _reconnectSub: Subscription;
  private _backgroundSub: Subscription;
  private _notificationSub: Subscription;
  private _onHubEvent$: Subject<{ channel: string; event: HubEvent }>;
  private SET_ONLINE_METHOD: string = "set-online";

  constructor(
    public name: string,
    public url: string,
    public msgSelector: MessagingSelector,
    public appStateSelector: AppStateSelector,
    public authSelector: AuthStateSelector,
    public networkService: NetworkService, //public store: Store
    public pubSub: PubSub,
    public userDataSelector: UserDataSelector,
    public tokenProvider: TokenProvider
  ) {
    //console.log("[MessagingStateSnapshot] %o", msgSnapshot);
    this._onHubEvent$ = new Subject<{ channel: string; event: HubEvent }>();
    this.init();
  }

  abstract get setHubConnectionStatus(): Emittable<HubConnectionState>;
  abstract get hub$(): Observable<HubConnectionState>;
  abstract get hubSnapshot(): HubConnectionState;
  abstract get setHandshakeStatus(): Emittable<HubHandshakeStatus>;

  get onHubConnected$(): Observable<any> {
    return this.hub$.pipe(
      filter(h => h !== null && h !== undefined),
      distinctUntilChanged((prev, curr) => prev.status === curr.status),
      filter(h => h.status === HubConnectionStatus.Connected)
    );
  }

  get onHubDisconnected$(): Observable<any> {
    return this.hub$.pipe(
      filter(h => h !== null && h !== undefined),
      distinctUntilChanged((prev, curr) => prev.status === curr.status),
      filter(h => h.status === HubConnectionStatus.Offline)
    );
  }

  get onHubConnectionStatusChanged(): Observable<HubConnectionState> {
    return this.hub$.pipe(
      filter(h => h !== null && h !== undefined),
      distinctUntilChanged((prev, curr) => prev.status === curr.status)
    );
  }

  get connection(): HubConnection {
    return this._connection;
  }

  get connectionId(): string {
    return this.hubSnapshot ? this.hubSnapshot.id : null;
  }

  set connection(value: HubConnection) {
    this._connection = value;
  }

  get onHubEvent$(): Observable<{ channel: string; event: HubEvent }> {
    return this._onHubEvent$;
  }

  get isConnecting(): boolean { 
    return (
      this.hubSnapshot &&
      this.hubSnapshot.status == HubConnectionStatus.Connecting
    );
  }

  get isConnected(): boolean { 
    return (
      this.hubSnapshot &&
      this.hubSnapshot.status == HubConnectionStatus.Connected
    );
  }

  get isOffline(): boolean { 
    return (
      this.hubSnapshot &&
      this.hubSnapshot.status == HubConnectionStatus.Offline
    );
  }

  channelEvent$(channel: string): Observable<HubEvent> {
    return this.onHubEvent$.pipe(
      //tap(dto => console.log(dto)),
      filter(dto => dto.channel === channel),
      //tap(dto => console.log("received channel: %s, %s", channel, dto.event.dto.id)),
      map(dto => dto.event)
    );
  }

  getConnectionId(): Promise<any> {
    return this.connection.invoke(this.CONNECTION_ID);
  }

  start(): Observable<boolean> {
    if (!this.authSelector.isAuthenticated)
      return throwError("No authorized");

    const accessToken = this.authSelector.accessToken;
    if (!accessToken) return throwError("No access token");

    var stopPromise = Promise.resolve();
    if (this._connection) {
      stopPromise = this._connection.stop();
    }
    //console.log("[basehub] build connection");
    let conn = this.createConnection();
    conn.serverTimeoutInMilliseconds = 40000;

    if (!this.isConnecting) {
      this.onConnectionConnecting();
    }


    console.info("[basehub] %s - start connecting", this.name);
    var promise = stopPromise
      .then(() => {
        return conn.start();
      })
      .then(() => {
        this.setConnection(conn);
        return this.getConnectionId();
      })
      .then((connId) => {
        console.info("%c [basehub] %s - hub connected: %s", 'background:black; color: #19EC62', this.name, connId);
        this.onConnectionConnected(connId);
        return Promise.resolve(true);
      })
      .catch(async err => {
        console.error("[basehub] %s - hub error: %o", this.name, err);
        this.onConnectionClosed();
        await delay(5000);
        this.reconnect();
        return Promise.resolve(false);
      });
    return from(promise);
  }

  stop(): Observable<void> {
    if (!this.connection) {
      console.info("[basehub] %s - stop, connection null, return", this.name);
      return from(Promise.resolve());
    }

    if (this._channels.length > 0) {
      this._channels.forEach(channel => this.connection.off(channel));
      this._channels = [];
    }

    var promise = this.connection
      .stop()
      .then(() => {
        console.info("[basehub] %s - stop, connection stopped", this.name);
        this.onConnectionClosed();
        return Promise.resolve();
      })
      .catch(err => {
        console.error(err);
        return Promise.resolve();
      });

    return from(promise);
  }

  private subReconnectSetup() {
    // handle reconnection. Do not rely on automatic reconnect.
    if (this._backgroundSub) this._backgroundSub.unsubscribe();

    this._backgroundSub = this.background$.subscribe((isBackground) => {
      if (!isBackground) {
        console.log("[%s] Foreground trigger. Check hub connection", this.name);
        if (this.userDataSelector.isLoggedIn) {
          this.reconnect();
        } else {
          console.log("[%s] User is not logged in", this.name);
          if (this._backgroundSub) this._backgroundSub.unsubscribe();
        }
      }
    });
  }

  private setOnline() {
    return this.invoke(
      this.SET_ONLINE_METHOD,
      this.appStateSelector.getDeviceId(),
      this.userDataSelector.getFcmToken()
    );
  }

  invoke$(method: string, ...args: any[]): Observable<HubEvent> {
    return from(this.invoke(method, ...args));
  }

  invoke(method: string, ...args: any[]): Promise<HubEvent> {
    try {
      // if (!this.networkService.connected) {
      //   this.notify.showOkToast("No internet connection");
      //   return Promise.reject("No internet connection");
      // }
      if (this.connection == null) {
        //this.notify.showOkToast("Hub not connected");
        return Promise.reject("Hub not connected, invoking method " + method);
      }
      //pocolog.info(`[${this.name}] invoking: %s`, method);

      return this.connection
        .invoke(method, ...args)
        .then((res: HubEvent) => {
          if (res == null || !res.isSuccess) return Promise.reject(res.error);
          //pocolog.info(`[${this.name}] invoke %s success: `, method);
          //pocolog.info(res.dto);
          return Promise.resolve(res);
        })
        .catch(err => {
          console.error(err);
          console.error("Method %s | args: %s", method, ...args);
          return Promise.reject(err);
        });
    } catch (err) {
      console.error(err);
      console.error("Method %s | args: %s", method, ...args);
      return Promise.reject(err);
    }
  }

  register(channel: string) {
    var index = this._channels.findIndex(s => s == channel);
    if (index > -1) {
      this.connection.off(this._channels[index]);
      this._channels = this._channels.filter(s => s !== channel);
    }

    this._channels.push(channel);
    this.connection.on(channel, (res: HubEvent) => {
      this._onHubEvent$.next({ channel, event: res });
    });

    //return this._onHubEvent$.pipe(filter(event => event.name === channel));
  }

  private createConnection() {
    return new HubConnectionBuilder()
      .withUrl(this.url, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => {
          // console.log("[%s] Access Token factory", this.name);
          return new Promise<string>(async (resolve, reject) => {
            await this.tokenProvider.getAccessToken().then(token => {
              resolve(token);
            }).catch((err) => {
              reject(err);
            });
            // console.log("[%s] Access Token factory access token %s", this.name, token);
          });
        }
      })
      // retry 5 times and stop after that
      .withAutomaticReconnect([0, 2000, 5000, 10000, 10000, null])
      .build();
  }

  private onConnectionClosed() {
    if (this.setHubConnectionStatus) {
      this._connection = null;
      this.setHubConnectionStatus.emit(HubConnectionState.offline());
      console.info("[basehub] %s - setHubConnectionStatus emitted - offline", this.name);
    } else {
      console.error("[basehub] %s - onConnectionClosed error", this.name);
    }
  }

  private onConnectionConnected(id: string) {
    try {
      this.setHubConnectionStatus.emit(HubConnectionState.online(id));
      this.subReconnectSetup();
      console.info("[basehub] %s - setHubConnectionStatus emitted - online: %s", this.name, id);
    } catch (err) {
      console.error("[basehub] %s - onConnectionConnected, %o", this.name, err);
    }
  }

  private onConnectionConnecting() {
    this.setHubConnectionStatus.emit(HubConnectionState.connecting());
    console.info("[basehub] %s - setHubConnectionStatus emitted - connecting", this.name);
  }

  setReady() {
    return this.setHandshakeStatus.emit(HubHandshakeStatus.Completed);
  }

  setNotReady() {
    return this.setHandshakeStatus.emit(HubHandshakeStatus.Pending);
  }

  private init() {
    if (this._networkSub) {
      this._networkSub.unsubscribe();
    }
    if (this._hubStatusSub) {
      this._hubStatusSub.unsubscribe();
    }
    if (this._notificationSub) this._notificationSub.unsubscribe();

    this._hubStatusSub = this.onHubConnected$.subscribe(() => {
      this.setOnline();
      this.register(this.NOTIFICATION);
    });

    console.log("[%s]-register-notification", this.name);
    this._notificationSub = this.channelEvent$(this.NOTIFICATION).subscribe(
      (event) => {
        console.log("[%s]-onHubEvent-notification, %o", this.name, event);
        this.onNotificationEventReceived(event);
      }
    );
    //console.log("[${this.name}] subscribe onNetworkChanged");
    // this._networkSub = this.networkService.onNetworkChanged.subscribe(
    //   connected => {
    //     //trigger reconnect
    //     //console.log("[onNetworkChanged] %o", this.msgSnapshot);
    //     //this.reconnect(this.msgSnapshot.hub);
    //     // if (connected == false) {
    //     //   this.onConnectionClosed();
    //     // }
    //   }
    // );

    //console.log("[${this.name}] subscribe onHubConnectionStatusChanged");
    // this._hubStatusSub = this.onHubConnectionStatusChanged.subscribe(state => {
    //   //console.log("[${this.name}] onHubConnectionStatusChanged trigger: %o", state);
    //   if (!state) return;
    //   //console.log("[${this.name}] state status changed: %o", state);

    //   if (state.status === HubConnectionStatus.Connected) {
    //     if (this._reconnectSub) this._reconnectSub.unsubscribe();
    //     //this._hubConnected.next();
    //   } else if (
    //     state.status === HubConnectionStatus.Offline &&
    //     this.authSelector.isAuthenticated
    //   ) {
    //     console.info("[${this.name}] hub offline, start reconnecting...");
    //     this.startReconnectInterval();
    //   }
    // );

    //this.lastBatchKey$.subscribe(key => console.log("[${this.name}] lastBatchKey triggered: %s", key));
  }

  onNotificationEventReceived(event: HubEvent) {
    if (!event) return;
    if (!event.dto) return;

    var notification = NotificationData.parse(event.dto);
    console.log("[%s]-onHubEvent-notification, %o", this.name, notification);
    this.pubSub.next<NotificationData>(PubSub.ON_HUB_NOTIFICATION_RECEIVED, notification);
  }

  private setConnection(conn: HubConnection) {
    if (this._connection) {
      this._connection = null;
    }
    this._connection = conn;
    this._connection.onreconnecting(() => {
      console.info("%c [basehub] %s - reconnecting...", 'background:black; color: #19EC62', this.name);
      this.onConnectionConnecting();
    });

    this._connection.onreconnected(async connectionId => {
      if (connectionId) {
        console.info("%c [basehub] %s - reconnected: %s", 'background:black; color: #19EC62', this.name, connectionId);
        this.onConnectionConnected(connectionId);
      } else {
        let connId = await this.getConnectionId();
        console.info("%c [basehub] %s - reconnected: %s", 'background:black; color: #19EC62', this.name, connId);
        this.onConnectionConnected(connId);
      }
    });

    this._connection.onclose((err) => {
      console.info("%c [basehub] %s - connection closed: %o", 'background:red; color: #19EC62', this.name, err);
      this.onConnectionClosed();
      if (this.userDataSelector.isLoggedIn) {
        //perform start again after 3s
        console.info("%c [basehub] %s - failover begin", 'background:orange; color: #19EC62', this.name);
        this.failedOverHandling();
      } else {
        console.info("%c [basehub] %s - User logged out. ", 'background:orange; color: #19EC62', this.name);
      }
    });
  }

  failedOverHandling() {
    // if(this._hubStatusSub) this._hubStatusSub.unsubscribe();

    // this._hubStatusSub = this.onHubConnectionStatusChanged.subscribe(state => {
    //   if (!state) return;
    //   if (state.status === HubConnectionStatus.Connected) {
    //     if(this._hubStatusSub) this._hubStatusSub.unsubscribe();
    //     if (this._reconnectSub) this._reconnectSub.unsubscribe();
    //   } else if (
    //     state.status === HubConnectionStatus.Offline &&
    //     this.authSelector.isAuthenticated
    //   ) {
    //     console.info("[basehub] hub offline, start reconnecting...");
    //     this.startReconnectInterval();
    //   }
    // });
    this.reconnectOnNetworkOnline();
    console.log("[basehub] %s done subscribed failedOverHandling", this.name);
  }

  private reconnectOnNetworkOnline() {
    if (this._reconnectSub) this._reconnectSub.unsubscribe();
    this._reconnectSub = this.networkService.onNetworkChanged
      .subscribe(async isOnline => {
        try {
          if (isOnline) {
            console.info("%c [basehub] %s - network online, start hub again...", 'background:orange; color: #19EC62', this.name);
            this.reconnect();
            if (this._reconnectSub) this._reconnectSub.unsubscribe();
          } else {
            console.info("%c [basehub] %s - network is offline...", 'background:orange; color: #19EC62', this.name);
          }
        } catch (err) {
          console.error(err);
        }

      });
  }
  // private startReconnectInterval() {
  //   if (this._reconnectSub) {
  //     this._reconnectSub.unsubscribe();
  //   }
  //   this._reconnectSub = timer(
  //     this.SYNC_DELAY_SECONDS * 1000,
  //     this.SYNC_INTERVAL_SECONDS * 1000
  //   ).subscribe(() => {
  //     this.reconnect(this.hubSnapshot);
  //   });
  // }

  private reconnect() {
    if (!this.hubSnapshot) return;
    console.info(
      "[%s]-reconnect, id: %s, status: %s",
      this.name,
      this.hubSnapshot.id,
      this.hubSnapshot.status
    );

    // if (!this.networkService.connected) {
    //   console.info("[%s]-reconnect, network is offline, will retry when network is on", this.name);
    //   this.reconnectOnNetworkOnline();
    //   return;
    // }
    //console.log("[basehub]-reconnect, stop interval, perform restart");
    // if (this._reconnectSub) this._reconnectSub.unsubscribe();
    // if (this._hubStatusSub) this._hubStatusSub.unsubscribe();


    if (!this.isOffline) {
      console.info("[%s]-reconnect, already connected, return...", this.name);
      return;
    }
    console.log("[%s]-reconnect, call stop()...", this.name);
    this.stop().subscribe(() => {
      console.log("[%s]-stop done, call start()...", this.name);
      //console.log("[basehub]-reconnect, call start()...");
      this.start().toPromise().then(success => {
        if (!success) {
          console.log("[%s]-start failed. reconnect on network online...", this.name);
          this.reconnectOnNetworkOnline();
        }
      });
    });
  }

  public parseList<T>(list: any[], parseFunc: (dto: any) => T): T[] {
    return list.map((d) => parseFunc(d));
  }

  public parseSet<T>(instance: { new(): T }, list: any[]): T[] {
    return list.map((d) => this.parse<T>(instance, d));
  }

  public parse<T>(instance: { new(): T }, dto: any): T {
    if (dto == null) return null;

    let state: T = new instance();
    _.assign(state, dto);

    return state;
  }
}

// delay util
const delay = ms => new Promise(res => setTimeout(res, ms));