import { AuthService } from './../../../core/auth/auth.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-force-logout',
  templateUrl: './dialog-force-logout.component.html',
  styleUrls: ['./dialog-force-logout.component.scss']
})
export class DialogForceLogoutComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<DialogForceLogoutComponent>, private authService: AuthService) { }

  ngOnInit() {
  }

  logout(){
    this.authService.logout(); //app.component will handle
    this.dialogRef.close();
  }

}
