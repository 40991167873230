import { Component, Input, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: "app-dialog-prompt",
  templateUrl: "./dialog-prompt.component.html",
  styleUrls: ["./dialog-prompt.component.scss"],
})
export class DialogPromptComponent implements OnInit {
  title: string;
  content: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogPromptComponent>) { }

  ngOnInit() {
    this.title = this.data.title;
    this.content = this.data.content;
  }

  confirm() {
    this.dialogRef.close(true);
  }
}
