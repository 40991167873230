import { Injector, Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { EnterpriseSelector } from '../states/enterprise.state.selector';
import { ParticipantState } from '../model/participant.state';
import { RoomState } from '../model/room.state';
import { RoomType } from '../model/room.model';
import { ParticipantStatus } from '../model/participant.model';
import { MessageState, DecryptStatus } from '../model/message.state';
import { MessageStatus } from '../model/message.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: "root"
})
export class MsgRenderer {
  constructor(private enterpriseSnapshot: EnterpriseSelector, private translate: TranslateService) { }

  renderParticipantName(participant: ParticipantState) {
    if (!participant) return "";
    return participant.firstName + " " + participant.lastName;
  }

  renderParticipantAvatar(orgId: string, participant: ParticipantState) {
    if (!participant) return null;
    const user = this.enterpriseSnapshot.getOrgMembership(
      orgId,
      participant.userId
    );
    if (!!user && !!user.imageUrl)
      return user.imageUrl;
    return null;

  }
  sortRooms(rooms: RoomState[]) {
    rooms.sort((a, b) => {
      let atime: any = a.lastMsg ? a.lastMsg.serverTimeStamp : a.createdOn;
      let btime: any = b.lastMsg ? b.lastMsg.serverTimeStamp : b.createdOn;
      let aDate = new Date(atime);
      let bDate = new Date(btime);

      if (aDate < bDate) {
        return 1;
      }
      if (aDate > bDate) {
        return -1;
      }
      return 0;
    });
  }

  renderRoomName(
    room: RoomState,
    participants: ParticipantState[],
    currentMatrixId: string
  ): string {
    if (!room) return "";
    if (!participants) return "";
    if (!currentMatrixId) return "";

    let others = participants.filter(
      p => p.matrixId != currentMatrixId && p.roomId == room.id
    );
    if (others.length == 0) return "(empty room)";

    if (room.type === RoomType.Direct) {
      if (others.some(i => i.status != ParticipantStatus.Leaved)) {
        return `${others[0].firstName} ${others[0].lastName}`;
      } else {
        return `${others[0].firstName} ${others[0].lastName} (Inactive)`;
      }
    } else {
      let randomName = others
        .filter(i => i.status != ParticipantStatus.Leaved)
        .map(i => i.firstName)
        .sort()
        .join(", ");
      if (others.some(i => i.status != ParticipantStatus.Leaved)) {
        return room.name || randomName;
      } else {
        randomName = others
          .map(i => i.firstName)
          .sort()
          .join(", ");
        return `Deleted Chat (${room.name || randomName})`;
      }
    }
  }

  renderRoomAvatar(
    room: RoomState,
    participants: ParticipantState[],
    currentMatrixId: string
  ): string {
    if (!room) return "";
    if (!participants) return "";
    if (!currentMatrixId) return "";

    if (room.type === RoomType.Group) return room.imageUrl;

    let others = participants.filter(
      p => p.matrixId != currentMatrixId && p.roomId == room.id
    );
    if (others.length == 0) return ""; //TODO: return default

    const user = this.enterpriseSnapshot.getOrgMembership(
      room.orgId,
      others[0].userId
    );
    if (!user) return ""; //TODO: return default
    return user.imageUrl;
  }

  static sortMessages(messages: MessageState[]): MessageState[] {
    return messages.sort(function (a, b) {
      var date1 = a.serverTimeStamp;
      var date2 = b.serverTimeStamp;
      // var date1 = new Date(a.serverTimeStamp);
      // var date2 = new Date(b.serverTimeStamp);
      if (date1 > date2) return 1;
      if (date1 < date2) return -1;
      return 0;
    });
  }

  renderPlaintext(msg: MessageState): string {
    if (!msg) return "";
    if (msg.status === MessageStatus.Deleted) return "";

    if (!msg.isEncrypted) return msg.content;

    if (msg.isDecrypted == DecryptStatus.Fail) {
      return this.translate.instant("CHAT_MESSAGES_UNABLE_DECRYPT");
    } else if (msg.isDecrypted == DecryptStatus.NotApplicable) {
      return msg.content; //only not applicable when content is empty
    } if (msg.isDecrypted == DecryptStatus.Pending) {
      return this.translate.instant("CHAT_MESSAGES_DECRYPTING");
    }
    else if (msg.isDecrypted == DecryptStatus.Success) {
      if (msg.plaintext && msg.plaintext.trim() != "") {
        return msg.plaintext;
      } else {
        console.error("Unknow error: the plaintext should not be empty since decrypt status shows success. msg: %o", msg);
        return "Unknown error"; //to be take note
      }
    } else {
      return "Unknown error"; //to be take note
    }
  }
}
