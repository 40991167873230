import { UserDataSelector } from "./../../core/states/user-data.state.selector";
import { EnterpriseSelector } from "./../../core/states/enterprise.state.selector";
import { ParticipantState } from "./../../core/model/participant.state";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MsgReaction } from "../../core/model/message.state";

@Component({
  selector: "app-dialog-msg-reactions",
  templateUrl: "./dialog-msg-reactions.component.html",
  styleUrls: ["./dialog-msg-reactions.component.scss"],
})
export class DialogMsgReactionsComponent implements OnInit {
  participants: ParticipantState[];
  reactions: {
    code: string;
    content: string;
    senders: ParticipantState[];
    isAll: boolean;
  }[] = [{ code: "", content: "All", senders: [], isAll: true }];
  currentUserMatrixId: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogMsgReactionsComponent>,
    private enterpriseSelector: EnterpriseSelector
  ) {}

  ngOnInit(): void {
    this.currentUserMatrixId = this.data.userMatrixId;

    if (this.data == null) return;

    if (this.data.participants != null) {
      this.participants = this.data.participants;
    }
    if (this.data.reactions != null) {
      let input: MsgReaction[] = this.data.reactions;
      this.reactions = this.reactions.concat(
        input.map((r) => {
          return {
            code: r.content,
            content: MsgReaction.getEmoji(r.content),
            senders: r.senders
              .map((s) => this.participants.find((p) => p.matrixId == s))
              .filter((r) => r),
            isAll: false,
          };
        })
      );
      let all = this.reactions.find((r) => r.content == "All");
      if (all) {
        all.senders = this.reactions
          .map((r) => r.senders)
          .reduce((a, b) => a.concat(b));
      }
    }
  }

  getSelectedEmoji(participant: ParticipantState): string {
    if (participant == null) return "";
    let reaction = this.reactions.find(
      (r) =>
        r.isAll == false &&
        r.senders.some((s) => s.matrixId === participant.matrixId)
    );
    return reaction ? reaction.content : "";
  }

  getImageUrl(participant: ParticipantState) {
    if (!participant) return null;
    const user = this.enterpriseSelector.getOrgMembership(
      this.enterpriseSelector.getCurrentOrgId(),
      participant.userId
    );
    if (!!user && !!user.imageUrl) return user.imageUrl;
    return null;
  }

  remove(participant: ParticipantState) {
    //does not support remove other particpant's reactions
    if (participant.matrixId !== this.currentUserMatrixId) return;
    let reaction = this.reactions.find(
      (r) =>
        r.isAll == false &&
        r.senders.some((s) => s.matrixId === participant.matrixId)
    );
    if (reaction == null) return;
    this.dialogRef.close({ remove: reaction });
  }
}
