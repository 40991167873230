import { LicenseStateSelector } from './../../../../core/states/license.state.selector';
import { Component, OnInit, Inject, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { LocalStorageService } from './../../../../core/localStorage/local-storage.service';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from "@angular/material/snack-bar";
import { InvitationService } from './../../../../core/services/invitation.service'
import { SelectOU } from '../../../../core/model/select-ou.model';
import { OUService } from '../../../../core/services/ou.service';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { empty, forkJoin, Observable } from 'rxjs';
import { EntityStatus } from '../../../../core/enum/entity-status.enum';
import { Subscription, PlanType } from '../../../../core/model/subscription.model';
import { SubscriptionStatus } from '../../../../core/enum/subscription-status.enum';
import { ProductType } from '../../../../core/model/plan.model';
import { OrgHub } from '../../../../core/hub/org.hub';
import { OrgUserState, OUState, OrgRoleState, TeamState, ContactState } from '../../../../core/model/org.state';
import { UserDataSelector } from '../../../../core/states/user-data.state.selector';
import { EnterpriseSelector } from '../../../../core/states/enterprise.state.selector';
import { Select } from '@ngxs/store';
import { EnterpriseState } from '../../../../core/states/enterprise.state';
import { SubSink } from 'subsink';
import * as _ from "lodash";
import { RoleEntity } from '../../../../core/model/userRole.model';
import { MembershipReqService } from '../../../../core/services/membership-req.service';
import { UserStatus } from '../../../../core/enum/user-status.enum';
import { DialogLicenseLimitExceedComponent } from './dialog-license-limit-exceed.component';
import { ResourceCode } from '../../../../core/model/feature.state';

@Component({
  selector: 'app-dialog-add-user',
  templateUrl: './dialog-add-user.component.html',
  styleUrls: ['./dialog-add-user.component.scss']
})
export class DialogAddUserComponent implements OnInit, OnDestroy {
  ProductType = ProductType;
  firstName: any;
  lastName: any;
  email: any;
  role: any;
  selectedRoleType: string;
  selectOU: SelectOU = new SelectOU();
  isLoading = false;

  // preassigned members
  selectedClients = [];
  searchClients: string;
  listClients = [];
  selectedEmployees = [];
  searchEmployees: string;
  listEmployees = [];
  selectedTeams = [];
  searchTeams: string;
  listTeams = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];
  //mat autocomplete
  @ViewChild(MatAutocompleteTrigger) autoEmp: MatAutocompleteTrigger;
  @ViewChild(MatAutocompleteTrigger) autoClient: MatAutocompleteTrigger;
  @ViewChild(MatAutocompleteTrigger) autoTeams: MatAutocompleteTrigger;
  
  @ViewChild('inputSearchEmployees') inputSearchEmployees: ElementRef<HTMLInputElement>;
  @ViewChild('inputSearchClients') inputSearchClients: ElementRef<HTMLInputElement>;
  @ViewChild('inputSearchTeams') inputSearchTeams: ElementRef<HTMLInputElement>;

  // saved values
  fullEmployees: any;
  fullClients: any;
  fullTeams: any;

  // preassigned values
  TEAM = "Team";
  CLIENT = "Client";
  COWORKER = "CoWorker";

  // error load
  limitError = false;
  errorMsg: string;

  // current members
  currentMembers = [];
  memberType: ProductType = ProductType.Internal;

  // ngxs states
  orgTeams: TeamState[] = [];
  orgUsers: OrgUserState[] = [];
  selectedOu: OUState;
  currentUser: OrgUserState;
  ous: OUState[] = [];
  roles: OrgRoleState[] = [];

  // offline contact
  contactId: string;

  @Select(EnterpriseState.currentTeams)
  currentTeams$: Observable<TeamState[]>;

  private _subSink: SubSink;

  get orgCoWorkers(): OrgUserState[] {
    return this.orgUsers.filter(x => RoleEntity[x.role] === RoleEntity.ADMIN ||
      RoleEntity[x.role] === RoleEntity.OWNER ||
      RoleEntity[x.role] === RoleEntity.COWORKER);
  };

  get orgClients(): OrgUserState[] {
    return this.orgUsers.filter(x => RoleEntity[x.role] === RoleEntity.CLIENT);
  };

  get isOfflineContact(): boolean {
    if (this.contactId) return true;
    else return false;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogAddUserComponent>, public dialog: MatDialog,
    //  private inviteService: InvitationService, private localStorage: LocalStorageService, private ouService: OUService,
    private enterpriseSelector: EnterpriseSelector, private userDataSelector: UserDataSelector, private membershipReqService: MembershipReqService,
    private orgHub: OrgHub,
    private licenseSelector: LicenseStateSelector,
    public snackBar: MatSnackBar) {

    this._subSink = new SubSink();
  }

  ngOnInit() {
    this.orgHub.getTeamsOnDemand();

    this._subSink.sink = this.orgHub.onHubConnected$.subscribe(() => {
      if (this.orgHub.connection) this.orgHub.getTeamsOnDemand();
    });

    this.orgTeams = this.enterpriseSelector.getCurrentTeams();
    this._subSink.sink = this.currentTeams$.subscribe(state => {
      this.orgTeams = state;
    });
    this.orgUsers = this.data.orgUsers;
    this.currentUser = this.data.currentUser;
    this.ous = this.data.ous;
    this.selectedOu = this.data.selectedOu;

    this.roles = this.enterpriseSelector.getOrgRoles(this.selectedOu.orgId).filter(x =>
      RoleEntity[x.normalizedName] !== RoleEntity.OWNER && RoleEntity[x.normalizedName] !== RoleEntity.ADMIN);

    if (this.selectedOu) {
      this.selectOU.ouId = this.selectedOu.id;
      this.selectOU.ouName = this.selectedOu.name;
      this.initOU(this.selectedOu.id);
    }

    // set invited contact details
    let contact: ContactState = this.data.invitedContact;
    if (contact) {
      this.firstName = contact.firstName;
      this.lastName = contact.lastName;
      this.email = contact.emails[0].email;
      this.contactId = contact.id
    }
  }


  // add(event: MatChipInputEvent): void {
  //   console.log(event);
  //   const input = event.input;
  //   const value = event.value;

  //   // Add our fruit
  //   if ((value || '').trim()) {
  //     this.selectedMembers.push({ name: value.trim() });
  //   }

  //   // Reset the input value
  //   if (input) {
  //     input.value = '';
  //   }
  // }

  //#region emp event
  onSearchEmp($event) {
    let searchString = $event.target.value;
    this.filterEmp(searchString);
  }

  removeEmp(emp) {
    this.selectedEmployees = this.selectedEmployees.filter(m => m.id !== emp.id);

    // update autocomplete
    this.filterEmp();

  }

  selectEmp(event: MatAutocompleteSelectedEvent): void {
    let selectedMember = this.fullEmployees.filter(x => x.id === event.option.value)[0];
    this.selectedEmployees.push(selectedMember);

    // Reset the input value
    this.searchEmployees = '';
    this.inputSearchEmployees.nativeElement.value = ''
    this.filterEmp();

    // force autocomplete to open
    setTimeout(() => {
      this.autoEmp.openPanel();
    });
  }

  filterEmp(search?) {
    let tempData = _.cloneDeep(this.fullEmployees);
    if (this.selectedEmployees) {
      let selectedIds = this.selectedEmployees.map((x) => x.id);
      tempData = tempData.filter((x) => !selectedIds.includes(x.id));
    }

    if (search) {
      search = search.trim();
      tempData = tempData.filter((selectedMember) =>
      selectedMember.name.match(new RegExp(`${search}`, "i"))
      );
    }

    if (tempData) {
      this.listEmployees = tempData;
    }
  }
  //#endregion

  //#region client event
  onSearchClient($event) {
    let searchString = $event.target.value;
    this.filterClient(searchString);
  }

  selectClient(event: MatAutocompleteSelectedEvent): void {
    let selectedMember = this.fullClients.filter(x => x.id === event.option.value)[0];
    this.selectedClients.push(selectedMember);

    // Reset the input value
    this.searchClients = "";
    this.inputSearchClients.nativeElement.value = ''
    this.filterClient();

    // force autocomplete to open
    setTimeout(() => {
      this.autoClient.openPanel();
    });
  }

  removeClient(client) {
    this.selectedClients = this.selectedClients.filter(m => m.id !== client.id);

    // update autocomplete
    this.filterClient();
  }

  filterClient(search?) {
    let tempData = _.cloneDeep(this.fullClients);
    if (this.selectedClients) {
      let selectedIds = this.selectedClients.map((x) => x.id);
      tempData = tempData.filter((x) => !selectedIds.includes(x.id));
    }

    if (search) {
      search = search.trim();
      tempData = tempData.filter((selectedMember) =>
      selectedMember.name.match(new RegExp(`${search}`, "i"))
      );
    }

    if (tempData) {
      this.listClients = tempData;
    }
  }
  //#endregion

  //#region teams event
  onSearchTeams($event) {
    let searchString = $event.target.value;
    this.filterTeams(searchString);
  }

  selectTeam(event: MatAutocompleteSelectedEvent): void {
    let selectedTeam = this.fullTeams.filter(x => x.id === event.option.value)[0];
    this.selectedTeams.push(selectedTeam);

    // Reset the input value
    this.searchTeams = "";
    this.inputSearchTeams.nativeElement.value = ''
    this.filterTeams();
    
    // force autocomplete to open
    setTimeout(() => {
      this.autoTeams.openPanel();
    });
  }

  removeTeam(team) {
    this.selectedTeams = this.selectedTeams.filter(m => m.id !== team.id);

    // update autocomplete
    this.filterTeams();
  }

  filterTeams(search?) {
    let tempData = _.cloneDeep(this.fullTeams);
    if (this.selectedTeams) {
      let selectedIds = this.selectedTeams.map((x) => x.id);
      tempData = tempData.filter((x) => !selectedIds.includes(x.id));
    }

    if (search) {
      search = search.trim();
      tempData = tempData.filter((selectedTeam) =>
      selectedTeam.name.match(new RegExp(`${search}`, "i"))
      );
    }

    if (tempData) {
      this.listTeams = tempData;
    }
  }
  //#endregion

  onRoleChange($event) {
    let selectedRole = this.roles.filter((x) => x.id === $event.value)[0];
    this.selectedRoleType = selectedRole.normalizedName.toUpperCase();

    if (selectedRole.normalizedName.toUpperCase() ===RoleEntity[RoleEntity.CLIENT] || selectedRole.normalizedName.toUpperCase() ===RoleEntity[RoleEntity.PARTNER])
      this.memberType = ProductType.External;
    else this.memberType = ProductType.Internal;

    this.limitError = false;
  }

  private async isValidLicense(): Promise<boolean>{
    var isExternalRole =
      this.selectedRoleType === RoleEntity[RoleEntity.CLIENT] ||
      this.selectedRoleType === RoleEntity[RoleEntity.PARTNER];

      var resource = isExternalRole
      ? ResourceCode.EXT_LICENSE
      : ResourceCode.INT_LICENSE;
      let feature = await this.orgHub.getFeature(
        this.enterpriseSelector.getCurrentOrgId(),
        resource
      );

    var isValidLicense = this.licenseSelector.isEnabled(feature);
    if (!isValidLicense) {
      const dialogRef = this.dialog.open(DialogLicenseLimitExceedComponent, {
        width: "450px",
        data: {
          feature: feature,
        },
      });
    }

    return isValidLicense;
  }

  initOU(ouId) {
    this.fullEmployees = this.sortArray(this.processUsers(this.orgCoWorkers.filter(x => x.ouId === ouId)));
    this.fullClients = this.sortArray(this.processUsers(this.orgClients.filter(x => x.ouId === ouId)));
    this.fullTeams = this.sortArray(this.processTeams(this.orgTeams.filter(x => x.ouId === ouId && !x.isDefault)));

    // clone list
    this.listEmployees = this.fullEmployees.map(x => ({ ...x }));
    this.listClients = this.fullClients.map(x => ({ ...x }));
    this.listTeams = this.fullTeams.map(x => ({ ...x }));

    // clear selected preassign settings
    this.selectedClients = [];
    this.selectedEmployees = [];
    this.selectedTeams = [];
  }

  private processUsers(userData: OrgUserState[]) {
    let newUserList = [];
    userData.forEach((user) => {
      if (user.status == UserStatus.Active) {
        let newUser = {
          id: user.userId,
          name: user.firstName + " " + user.lastName,
          imageUrl: user.imageUrl,
          email: user.email
        }
        newUserList.push(newUser);
      }
    });
    return newUserList;

  }

  private processTeams(teams: TeamState[]) {
    let teamArray = [];
    teams.forEach((m) => {
      let team = {
        name: m.name,
        ouId: m.ouId,
        id: m.id
      }
      teamArray.push(team);
    });
    return teamArray;
  }

  // array must have name value
  private sortArray(array) {
    var array = array.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      return 0;
    });

    return array;
  }

  changeOU($event) {
    // console.log($event);
    this.initOU($event.ouId);
  }

  sendEmail() {
    this.isLoading = true;

    if (!this.isValidLicense()) {
      this.isLoading = false;
      return;
    }
    this.addMember();
  }

  addMember() {
    //Removes the leading and trailing white space
    this.firstName = this.firstName.trim();
    this.lastName = this.lastName.trim();
    // create presettings
    let preassignSettings = [];
    if (this.selectedRoleType === RoleEntity[RoleEntity.COWORKER]) {
      if (this.selectedClients && this.selectedClients.length > 0) {
        preassignSettings.push({ name: 'Client' });
        let ids = [];
        this.selectedClients.forEach((m) => {
          ids.push(m.id);
        })
        preassignSettings[0].ids = ids;
      }
    } else if (this.selectedRoleType === RoleEntity[RoleEntity.CLIENT]) {
      if (this.selectedEmployees && this.selectedEmployees.length > 0) {
        let ids = [];
        preassignSettings.push({ name: 'CoWorker' });
        this.selectedEmployees.forEach((m) => {
          ids.push(m.id);
        })
        preassignSettings[0].ids = ids;
      }
    } else if (this.selectedRoleType === RoleEntity[RoleEntity.PARTNER]) {
      if (this.selectedTeams && this.selectedTeams.length > 0) {
        let ids = [];
        preassignSettings.push({ name: 'Team' });
        this.selectedTeams.forEach((m) => {
          ids.push(m.id);
        })
        preassignSettings[0].ids = ids;
      }
    }

    this.isLoading = true;
    this.membershipReqService
      .issueInvitation(
        this.email,
        this.firstName,
        this.lastName,
        this.role,
        this.selectedOu.orgId,
        this.selectOU.ouId,
        preassignSettings,
        this.contactId
      )
      .then((res) => {
        this.isLoading = false;
        if (!res) {
          this.dialogRef.close(res);
        } else {
          this.dialogRef.close({status:"success", reqId: res.id});
        }
      })
      .catch((err) => {
        this.isLoading = false;
        this.dialogRef.close();
        this.openSnackBar(err && err.error ? err.error : err, "OK");
      });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 10000
    });
  }

  ngOnDestroy() {
    if (this._subSink) this._subSink.unsubscribe();
  }
}