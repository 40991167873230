import { Component, OnInit, Output, Input, EventEmitter, ChangeDetectorRef, AfterViewInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageStatus } from '../../core/model/message.model';
import { RoomState } from '../../core/model/room.state';
import { ParticipantState } from '../../core/model/participant.state';
import { MessageState, DecryptStatus } from '../../core/model/message.state';
import { MsgRenderer } from '../../core/renderer/msg.renderer';
import { FcpService } from '../../core/fcp/fcp.service';
import { StringDictionary } from '../../core/util/dictionary';
import { MessagingSelector } from '../../core/states/messaging.state.selector';
import * as _ from "lodash";
import { ReplaySubject, Subject } from 'rxjs';
import { SubSink } from 'subsink';
import { distinctUntilChanged } from 'rxjs/operators';
@Component({
  selector: 'app-chat-room-list',
  templateUrl: './chat-room-list.component.html',
  styleUrls: ['./chat-room-list.component.scss']
})

export class ChatRoomListComponent implements OnInit {

  @Input() selectedRoom: RoomState; // 2 way bind
  @Output() selectedRoomChanged = new EventEmitter<RoomState>(true);

  get renderRooms() {
    return this._renderRooms;
  }
  private _renderRooms: any[] = [];
  private _detectChangesSub: ReplaySubject<RoomState[]> = new ReplaySubject<RoomState[]>();
  private _data: RoomState[] = [];
  private _sub: SubSink = new SubSink();

  private _dataUnreads: StringDictionary;
  private _detectChangesUnreadsSub: Subject<StringDictionary> = new Subject<StringDictionary>();
  private _dataPtcp: ParticipantState[] = [];
  private _detectChangesPtcpSub: Subject<ParticipantState[]> = new Subject<ParticipantState[]>();



  @Input()
  set unreads(unreads: StringDictionary) {
    this._dataUnreads = unreads;
    this._detectChangesUnreadsSub.next(unreads);
  }

  get unreads() {
    return this._dataUnreads;
  }

  @Input()
  set rooms(rooms: RoomState[]) {
    //console.log("[ChatRoomComponent] ChatRoomList pass in rooms %o", rooms);
    this._data = rooms;
    this._detectChangesSub.next(rooms);
  }

  get rooms() {
    return this._data;
  }

  @Input()
  set participants(ptcp: ParticipantState[]) {
    this._dataPtcp = ptcp;
    this._detectChangesPtcpSub.next(ptcp);
  }

  get participants() {
    return this._dataPtcp;
  }

  //@Input() rooms: RoomState[];
  // @Input() showNewChatLoading: false;
  @Input() showNotification: false;
  // @Input() participants: ParticipantState[];
  @Input() userMatrixId: string;
  @Input() isLoading = false;
  // @Input() unreads: StringDictionary;

  @Input() showRoomNameOnly = false; // show list without lastmsg, date, unread

  // loading
  contactLoading = false;

  constructor(public snackBar: MatSnackBar, private msgRenderer: MsgRenderer, private fcp: FcpService,
    private msgSelector: MessagingSelector) {
  }

  ngOnInit() {
    //console.log("[ChatRoomComponent] ChatRoomList ngOnInit");
    if (this._sub) {
      this._sub.unsubscribe();
    }

    this._sub.sink = this._detectChangesSub
      // .pipe(
      //   distinctUntilChanged((prev, curr) => {
      //     console.log("[ChatRoomComponent] %o", { prev, curr });
      //     // console.log("[ChatRoomComponent]", JSON.parse(JSON.stringify(prev[0])), JSON.parse(JSON.stringify(curr[0])));
      //     return JSON.stringify(prev) === JSON.stringify(curr);
      //   })
      // )
      .subscribe((rooms) => {
        //console.log("[ChatRoomComponent] ChatRoomList _detectChangesSub %o", rooms);
        this.buildRenderRoom();
      });

    this._sub.sink = this._detectChangesUnreadsSub.pipe(
      distinctUntilChanged((prev, curr) => {
        //console.log("[ChatRoomComponent] %o", { prev, curr });
        return JSON.stringify(prev) === JSON.stringify(curr);
      })
    )
      .subscribe(() => {
        console.log("[ChatRoomComponent] ChatRoomList _detectChangesUnreadsSub");
        this.buildRenderRoom();
      });

    this._sub.sink = this._detectChangesPtcpSub.pipe(
      distinctUntilChanged((prev, curr) => {
        return JSON.stringify(prev) === JSON.stringify(curr);
      })
    )
      .subscribe(() => {
        console.log("[ChatRoomComponent] ChatRoomList _detectChangesPtcpSub");
        this.buildRenderRoom();
      });
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (!changes && !changes.rooms && !changes.unreads) return;

  //   if (changes.rooms.isFirstChange()) {
  //     // init
  //     console.log("[ChatRoomComponent] ChatRoomList ngOnChanges - isFirstChange");
  //     this.buildRenderRoom();
  //   }
  //   // if different rooms, init again
  //   if (changes.rooms.previousValue && changes.rooms.currentValue) {
  //     if (changes.rooms.previousValue !== changes.rooms.currentValue) {
  //       console.log("[ChatRoomComponent] ChatRoomList ngOnChanges - Different");
  //       this.buildRenderRoom();
  //     }
  //   }

  //   if (changes.unreads && changes.unreads.previousValue && changes.unreads.currentValue) {
  //     if (changes.unreads.previousValue !== changes.unreads.currentValue) {
  //       console.log("[ChatRoomComponent] ChatRoomList ngOnChanges - unreads");
  //       this.buildRenderRoom();
  //     }
  //   }
  // }

  getRoomUnread(roomId: string) {
    if (!this.unreads) return 0;
    return this.unreads.count(roomId);
  }

  buildRenderRoom() {
    console.time("[ChatRoomComponent] ChatRoomList buildRenderRoom");
    if (!this.rooms) return;

    var data = this.rooms.map(v => {
      return {
        ...v,
        displayName: this.displayRoomName(v),
        displayAvatar: this.displayRoomAvatar(v),
        displayLastMsgDateTime: this.displayLastMessageDateTime(v),
        displayLatestMsgContent: this.displayLatestMsgContent(v),
        unreadCount: this.getRoomUnread(v.id)
      }
    });

    this._renderRooms = _.uniq(data, o => o.id);
    if (this._renderRooms && this._renderRooms.length > 0) {
      this.isLoading = false;
    }
    console.timeEnd("[ChatRoomComponent] ChatRoomList buildRenderRoom");

  }

  selectRoom(id) {
    console.time("[ChatRoomComponent] ChatRoomList selectRoom");
    const room = this.rooms.find(r => r.id === id);
    if (room) {
      if (!this.selectedRoom || room.matrixId !== this.selectedRoom.matrixId) {
        this.selectedRoom = room;
        this.selectedRoomChanged.emit(room);
      }
    } else {
      // no room
      this.selectedRoom = null;
      this.selectedRoomChanged.emit(null);
    }
    console.timeEnd("[ChatRoomComponent] ChatRoomList selectRoom");
  }


  //#region Rendering
  displayRoomName(room: RoomState): string {
    // console.log("[ChatRoomList] displayRoomName: %s", room.id);
    if (!room || !this.userMatrixId || this.participants.length == 0) return "";

    return this.msgRenderer.renderRoomName(
      room,
      this.participants,
      this.userMatrixId
    );
  }

  displayRoomAvatar(room: RoomState): string {
    // console.log("displayRoomAvatar");
    if (!room || !this.userMatrixId || this.participants.length == 0) return "";

    return this.msgRenderer.renderRoomAvatar(
      room,
      this.participants,
      this.userMatrixId
    );
  }

  displayLastMessageDateTime(room: RoomState) {
    if (!room) return null;

    let time: any = room.lastMsg
      ? room.lastMsg.serverTimeStamp
      : room.createdOn;
    return new Date(time);
  }

  displayLatestMsgContent(room: RoomState) {
    if (!room) return "";

    let msg: MessageState = room.lastMsg;
    if (msg) {
      if (msg.status == MessageStatus.Deleted) {
        if (msg.senderMatrixId == this.userMatrixId) {
          return "Deleted Message";
          // return this.translate.instant("CHAT_MESSAGES_YOU_DELETED");
        } else {
          // return this.translate.instant("CHAT_MESSAGES_DELETED");
          return "Deleted Message";
        }
      } else {
        switch (msg.type) {
          case 0:
            // return msg.content;
            if (!msg.isEncrypted) return msg.content;
            else {
              //get msg object with decrypted content
              const msgId = room.lastMsg.id ? room.lastMsg.id : room.lastMsg.tempId;
              //let message = this.msgSelector.getMessage(msgId);

              if (msg && msg.isDecrypted === DecryptStatus.Success) return msg.plaintext;
              else return "";
            }
          case 1:
            // return this.translate.instant("CHAT_MESSAGES_IMAGES");
            return "Image";
          case 2:
            return msg.mediaName;
          case 3:
            // return this.translate.instant("CHAT_MESSAGES_VOICE_MSG");
            return "Voice Message";
          case 4: case 5:
            // return this.translate.instant("CHAT_MESSAGES_VIDEO_MEETING");
            return "Meeting"
          default:
            return "";
        }
      }
    } else {
      // return this.translate.instant("CHAT_MESSAGES_NO_MESSAGES");
      return "No chat messages"
    }
  }
  //#endregion
}
