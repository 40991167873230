export class UserRole {
    id: string;
    name: string;
    normalizedName: string;
    label: string;
  
    static getRoleName(roles: UserRole[], role:string) {
      var result = roles.find(r => r.normalizedName == role);
      if (result)
          return result.label;
  }
  }
  
  // keep the literal as capital letter
  // so can used to match with role.normalizedName
  // as current typescript version that we used does not support string litaral enum
  // so use RoleEntity[RoleEntity.OWNER] to get the string value from enum literal name
  export enum RoleEntity {
    OWNER,
    ADMIN,
    COWORKER,
    CLIENT,
    PARTNER
  }

export class RoleType {
  id: string;
  name: string;
  code: string;
  industryId: string;
  roleId: string;
}
  