import { Component, Inject, Input, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-dialog-confirm",
  templateUrl: "./dialog-confirm.component.html",
  styleUrls: ["./dialog-confirm.component.scss"],
})
export class DialogConfirmComponent implements OnInit {
  @Input() title: string;
  @Input() content: string;
  @Input() buttonText: string;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  public dialogRef: MatDialogRef<DialogConfirmComponent>, 
  public translateService: TranslateService) {
  }

  ngOnInit() {
    this.title = this.data.title;
    this.content = this.data.content;
    this.buttonText = this.data.buttonText;
  }

  get actionText(): string {
    return this.buttonText ? this.buttonText : this.translateService.instant('REPEAT.CONFIRM');
  }
}
