import { Message } from './../model/message.model';
import { MembershipReqService } from './membership-req.service';
import { CloudFileService } from './cloud-file.service';
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Select } from "@ngxs/store";
import { Observable, Subscription } from "rxjs";
import { AuthService } from "../auth/auth.service";
import { RoomHub } from "../hub/room.hub";
import { EnterpriseSelector } from "../states/enterprise.state.selector";
import { UserDataState } from "../states/user-data.state";
import { UserDataSelector } from "../states/user-data.state.selector";
import { DialogPromptComponent } from "../ui/dialog/dialog-prompt/dialog-prompt.component";
import { DialogSwitchOUComponent } from "../ui/dialog/switch-ou/dialog-switch-ou.component";
import { NotificationService } from "./notify.service";
import { AuthStateSelector } from '../states/auth.state.selector';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: "root",
})
export class RedirectService {
  @Select(UserDataState.matrixId)
  public matrixId$: Observable<string>;

  private _alertMailData: AlertMailData;
  private _meetRoom: string;
  private _meetJwt: string;
  private _roomHubConnSub: Subscription;
  private _usrMtrxIdSub: Subscription;
  private _shareLinkData: StorageLinkData = null;

  get isStorageShareLinkRedirect() {return !!this._shareLinkData;} 

  constructor(
    private userDataSelector: UserDataSelector,
    private authStateSelector: AuthStateSelector,
    private notifyService: NotificationService,
    private authService: AuthService,
    private cloudFileService: CloudFileService,
    private membershipReqService: MembershipReqService,
    private dialog: MatDialog,
    private router: Router,
    private translateService: TranslateService,
    private roomHub: RoomHub,
    private enterpriseSelector: EnterpriseSelector,
    private snackBar: MatSnackBar,
  ) {}

  //#region Alert Mail
  alertMailHandler(data: any) {
    if (!data) return;
    this._alertMailData = AlertMailData.parse(data);

    this.isLoggedIn(RedirectType.AlertMail);
  }

  private isAlertMailUser() {
    if (this.userDataSelector.isCurrentUserId(this._alertMailData.userId)) {
      this.notifyService.notificationRedirection(
        this._alertMailData.eventType,
        this._alertMailData.body,
        this._alertMailData.title,
        this._alertMailData.orgId,
        this._alertMailData.data,
        false
      );
      this._alertMailData = null;
    } else {
      this.promptLogoutDialog(RedirectType.AlertMail);
    }
  }
  //#endregion

  //#region Join Meet
  joinMeetHandler(room: string, jwt: string) {
    if (!room || !jwt) return;
    this._meetJwt = jwt;
    this._meetRoom = room;

    this.isLoggedIn(RedirectType.JoinMeet);
  }

  private isJoinMeetPtcpt() {
    if (this._roomHubConnSub) this._roomHubConnSub.unsubscribe();

    this._roomHubConnSub = this.roomHub.onHubConnected$.subscribe(() => {
      if (this._roomHubConnSub) this._roomHubConnSub.unsubscribe();
      this.roomHub
        .getRoomByRoomMatrixID(this._meetRoom)
        .then((room) => {         
          if (this._usrMtrxIdSub) this._usrMtrxIdSub.unsubscribe();
          this._usrMtrxIdSub = this.matrixId$.subscribe((matrixId) => {
            if (matrixId) {
              if (this._usrMtrxIdSub) this._usrMtrxIdSub.unsubscribe();
              const idx = room.participants.findIndex((p) => p == matrixId);
              if (idx == -1) {
                this.promptLogoutDialog(RedirectType.JoinMeet);
              } else {
                this.redirectToJoinMeet();
              }
            }
            
          });
        })
        .catch((err) => {
          if (this._roomHubConnSub) this._roomHubConnSub.unsubscribe();
          if (this._usrMtrxIdSub) this._usrMtrxIdSub.unsubscribe();
          console.error(err);
          this.redirectToMainPage();
        });
    });
  }

  private redirectToJoinMeet() {
    this.router.navigate([`meet/join/${this._meetRoom}`], {
      queryParams: { jwt: this._meetJwt, isUser: true },
    });
  }

  //#endregion

  //#region Storage Link
  initStorageLinkData(orgId: string, resourcePath: string, isFolder: boolean, storageType: string, ownerId: string, email: string = null, driveId: string = null) {
    if (!orgId || !resourcePath || !storageType) return;
    this._shareLinkData = new StorageLinkData();
    this._shareLinkData.containerId = orgId;
    this._shareLinkData.originPath = resourcePath;
    this._shareLinkData.isFolder = isFolder;
    this._shareLinkData.storageType = storageType;
    this._shareLinkData.ownerId = ownerId;
    this._shareLinkData.email = email;
    this._shareLinkData.driveId = driveId;
  }

  handleAllStorageLink() {
    if (this._shareLinkData.email) {
      // handle external invite storage share link
      this.handleStorageInviteLink();
    } else {
      // handle generic storage share link
      this.isLoggedIn(RedirectType.StorageShareLink);
    }
  }

  private async handleAccountCreation(email: string) {
    let isExisting = await this.authService.checkEmail(email);
    if (isExisting) this.redirectToLoginPage(RedirectType.StorageInviteLink);
    else this.redirectToSelfSignUpPage(RedirectType.StorageInviteLink);
  }

  private async handleStorageInviteLink() {
    if (this.userDataSelector.isLoggedIn && this.authStateSelector.data.userName === this._shareLinkData.email) {
      this.cloudFileService.acceptShareInvite(this._shareLinkData.containerId, this._shareLinkData.originPath, this._shareLinkData.isFolder, this._shareLinkData.ownerId)
      .then((res) => {
        this.redirectToCloudStorage();
      })
      .catch((res) => {
        console.error(res);
        this.snackBar.open("Unable to view item: " + res.error.Message ?? res.error, "OK", {
          duration: 5000,
        });
        this._shareLinkData = null;
      });
    } else if (this.userDataSelector.isLoggedIn && this.authStateSelector.data.userName !== this._shareLinkData.email) {
      // share link is intended for another user
      this.promptLogoutDialog(RedirectType.StorageInviteLink);
    } else {
      this.handleAccountCreation(this._shareLinkData.email);
    }
  }

  private async redirectToCloudStorage() {
    const url = "/main/storage";

    const originPath = this._shareLinkData.originPath;
    const originContainer = this._shareLinkData.containerId;
    const isFolder = this._shareLinkData.isFolder;
    const ownerId = this._shareLinkData.ownerId;
    const name = "";
    const storageType = this._shareLinkData.storageType;

    this._shareLinkData = null; // reset data
    

    if (storageType == "enterprise-drive") {
      var claims = await this.cloudFileService.getDriveResourceInfo(originContainer, originPath, this._shareLinkData.driveId);
      if (!claims || claims.length == 0) {
        this.snackBar.open("Unable to view item: ", "OK", {
          duration: 5000,
        });
      }
      var claim = claims.find(c => this.userDataSelector.isCurrentUserId(c.identity));
      if (!claim) {
        this.snackBar.open("You do not have permission to view this item: ", "OK", {
          duration: 5000,
        });
      }

      if (claim.isSharedAccess || claim.isExternalAccess) {
        return this.router.navigate([url + "/shared"], { queryParams: { redirectOriginPath: originPath, redirectOriginContainer: originContainer, redirectPath: originPath, isFolder: isFolder, itemName: name  } });
      } else {
        const folderPath = isFolder ? originPath : originPath.substring(0, originPath.lastIndexOf('/'));
        return this.router.navigate([url + "/drive"], { queryParams: { folderPath: folderPath, redirectOriginPath: originPath, redirectOriginContainer: originContainer, redirectPath: originPath, isFolder: isFolder, itemName: name  } });
      }
    }

    if (this.userDataSelector.isCurrentUserId(ownerId)) {
      // user owns this resource, navigate to local storage
      const folderPath = isFolder ? originPath : originPath.substring(0, originPath.lastIndexOf('/'));
      return this.router.navigate([url + "/local"], { queryParams: { folderPath: folderPath, redirectOriginPath: originPath, redirectOriginContainer: originContainer, redirectPath: originPath, isFolder: isFolder, itemName: name  } });
    } else {
      return this.router.navigate([url + "/shared"], { queryParams: { redirectOriginPath: originPath, redirectOriginContainer: originContainer, redirectPath: originPath, isFolder: isFolder, itemName: name  } });
    }
  }
  //#endregion

  private isLoggedIn(type: RedirectType) {
    if (!this.userDataSelector.isLoggedIn) {
      this.redirectToLoginPage(type);
    } else {
      this.redirectHandler(type);
    }
  }

  redirectHandler(type: RedirectType) {
    switch (type) {
      case RedirectType.AlertMail:
        return !this._alertMailData
          ? this.redirectToMainPage()
          : this.isAlertMailUser();
      case RedirectType.JoinMeet:
        return !this._meetJwt && !this._meetRoom
          ? this.redirectToMainPage()
          : this.redirectToJoinMeet();
      case RedirectType.StorageShareLink:
        return !this._shareLinkData 
          ? this.redirectToMainPage()
          : this.redirectToCloudStorage()
      case RedirectType.StorageInviteLink:
        return !this._shareLinkData 
          ? this.redirectToMainPage()
          : this.handleStorageInviteLink()
      default:
        return this.redirectToMainPage();
    }
  }

  private promptLogoutDialog(type: RedirectType) {
    const dialogRef = this.dialog.open(DialogPromptComponent, {
      width: "450px",
      height: "200px",
      data: {
        content: this.getReLoginDialogMsg(type),
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.authService.logout();
        this.router.navigate(["/login"], {
          queryParams: {
            status: this.getReloginRedirectStatus(type),
            redirectType: type,
          },
        });
      } else {
        this.redirectToMainPage();
      }
    });
  }

  private redirectToLoginPage(type: RedirectType) {
    this.router.navigate(["/login"], {
      queryParams: {
        status: this.getLoginRedirectStatus(type),
        redirectType: type,
      },
    });
  
  }
  private redirectToSelfSignUpPage(type: RedirectType) {
    this.router.navigate(["/signup"], {
      queryParams: {
        data: this.getSelfSignUpPrefilledData(type),
        status: this.getSelfSignUpRedirectStatus(type),
      },
    });
  }

  private redirectToMainPage() {
    this.router.navigateByUrl("/main");
  }

  private showSwitchOrg(orgId: string): Promise<void> {
    // if orgId is connected org, get PS orgId
    let org = this.enterpriseSelector.getOrg(orgId);
    if (!org)
      return;

    const dialogRef = this.dialog.open(DialogSwitchOUComponent, {
      width: "400px",
      data: {
        orgId: org.id,
        disableRedirect: true,
      },
    });

    return dialogRef.afterClosed().toPromise()
      .then((info) => {
        if (info && info.status && info.status == "success") {
          return Promise.resolve();
        } else {
          throw new Error("Switch Org ignored");
        }
      }).catch((err) => {
        throw new Error(err);
      });
  }

  private getSelfSignUpPrefilledData(type: RedirectType): any {
    switch (type) {
      case RedirectType.StorageInviteLink:
        return this._shareLinkData.email;
      default:
        return null;
    }
  }

  private getSelfSignUpRedirectStatus(type: RedirectType): string {
    switch (type) {
      case RedirectType.StorageInviteLink:
        return "invite-sharelink-redirect-signup";
      default:
        return "";
    }
  }

  private getLoginRedirectStatus(type: RedirectType): string {
    switch (type) {
      case RedirectType.AlertMail:
        return "alert-redirect-login";
      case RedirectType.JoinMeet:
        return "meet-redirect-login";
      case RedirectType.StorageShareLink:
        return "sharelink-redirect-login";
      case RedirectType.StorageInviteLink:
        return "invite-sharelink-redirect-login";
      default:
        return "";
    }
  }

  private getReloginRedirectStatus(type: RedirectType): string {
    switch (type) {
      case RedirectType.AlertMail:
        return "alert-redirect-relogin";
      case RedirectType.JoinMeet:
        return "meet-redirect-relogin";
      case RedirectType.StorageInviteLink:
        return "invite-sharelink-relogin"
      default:
        return "";
    }
  }

  private getReLoginDialogMsg(type: RedirectType): string {
    switch (type) {
      case RedirectType.JoinMeet:
        return this.translateService.instant("REDIRECT.MEET_DIFF_USER");
      case RedirectType.StorageInviteLink:
        return this.translateService.instant("REDIRECT.SHARE_LINK_DIFF_USER");
      case RedirectType.AlertMail:
      default:
        return this.translateService.instant("REDIRECT.ITEM_DIFF_USER");
    }
  }
}

export class StorageLinkData {
  containerId: string;
  originPath: string;
  isFolder: boolean;
  storageType: string;
  ownerId: string;
  email: string;
  driveId?: string;
}

export class AlertMailData {
  title: string;
  body: string;
  orgId: string;
  userId: string;
  eventType: string; // must not be enum to support ext tools. Use constants NotificationEventType for core events.
  data: any;

  constructor() {
    this.data = {};
  }

  static parse(d: any): AlertMailData {
    try {
      var amd = new AlertMailData();
      amd.title = d.title;
      amd.body = d.body;
      amd.eventType = d.eventName ? d.eventName : d.event_type;
      amd.orgId = d.orgId ? d.orgId : d.orgUnitId;
      amd.userId = d.userId;
      amd.data = d.data;

      return amd;
    } catch (err) {
      console.error(err);
      return null;
    }
  }
}

export enum RedirectType {
  AlertMail,
  JoinMeet,
  StorageShareLink,
  StorageInviteLink,
}
