import { MyDrivePicker, SharedDrive, SharedDriveCollection, SharedDriveCollectionPicker, SharedDriveResourceAction, SharedWithMeDrivePicker } from "./../model/explorer.model";
import { SharedFolder } from "./../model/shared-folder";
import { TranslateService } from "@ngx-translate/core";
import {
  SaveGranteesDeleg,
  GetShareLinkDeleg,
  ListFoldersDeleg,
  MoveResourcesDeleg,
  SharedDriveSaveGranteesDeleg,
  SharedDriveGetShareLinkDeleg,
  BaseResourceDeleg,
  SharedDriveListFoldersDeleg,
  ShareListFoldersDeleg,
} from "./../model/resource-delegate";
import { FlowManager } from "./../workflow/flow-manager";
import { EnterpriseSelector } from "./../states/enterprise.state.selector";
import { OrgType } from "./../enum/org-type";
import { Injectable } from "@angular/core";
import { CloudFile } from "../model/cloud-file";
import { CloudFolder } from "../model/cloud-folder";
import {
  AccessPermissionSetting,
  Explorer,
  MyDrive,
  OrgExplorer,
  PersonalExplorer,
  ResourceAction,
  SharedResourceAction,
  SharedWithMeDrive,
} from "../model/explorer.model";
import { Resource } from "../model/resource";
import { SharedResource } from "../model/shared-resource";
import { CloudFileService } from "./cloud-file.service";
import { SharedFile } from "../model/shared-file";
import { AccessPermission, AccessRight } from "../model/cloud-access-right";
import { SharedDriveFolder } from "../model/shared-drive-folder";
import { SharedDriveFile } from "../model/shared-drive-file";
import * as _ from "lodash";
import { FeedState } from "../states/feed.state";
import { FeedService } from "./feed.service";
import { lastValueFrom } from "rxjs";
import { PromiseAllSettled } from "../util/promise-all-settled";
import { OriginFolder } from "../model/origin-folder";
import { OriginFile, OriginResource } from "../model/origin-file";

@Injectable()
export class ExplorerService {
  constructor(
    private cloudFileService: CloudFileService,
    private feedService: FeedService,
    private flowManager: FlowManager,
    private enterpriseSelector: EnterpriseSelector,
    private translateService: TranslateService
  ) { }

  build(orgId: string, orgType: OrgType, userId: string): Explorer {
    if (!orgId || !orgType) return null;
    return orgType == OrgType.Business
      ? this._buildOrgExplorer(orgId, userId)
      : this._buildPersonalExplorer(orgId, userId);
  }

  /**
   * Returns cloud storage explorer with limited functionality.
   * Intended to be used for selecting a file only.
   * @param orgId 
   * @param orgType 
   * @param userId 
   * @returns `OrgExplorer` or `PersonalExplorer`, based on `orgType`.
   */
  buildPickers(orgId: string, orgType: OrgType, userId: string): Explorer {
    if (!orgId || !orgType) return null;
    return orgType == OrgType.Business
      ? this._buildOrgExplorerPicker(orgId, userId)
      : this._buildPersonalExplorerPicker(orgId, userId);
  }

  private _buildPersonalExplorer(orgId: string, userId: string) {
    let myDrive = this._buildPersonalMyDrive(orgId);
    let sharedWithMeDrive = this._buildPersonalSharedWithMeDrive(orgId, userId);
    // let enterpriseDrive = this._buildSharedDrive(orgId);

    let explorer = new PersonalExplorer(
      orgId,
      myDrive,
      sharedWithMeDrive,
      null
    );

    this._buildActions(userId, myDrive, sharedWithMeDrive, null);

    return explorer;
  }

  private _buildOrgExplorer(orgId: string, userId: string): OrgExplorer {
    let myDrive = this._buildMyDrive(orgId);
    let sharedWithMeDrive = this._buildSharedWithMeDrive(orgId, userId);
    let sharedDriveCollection = this._buildSharedDriveCollection(orgId, userId);

    let explorer = new OrgExplorer(
      orgId,
      myDrive,
      sharedWithMeDrive,
      sharedDriveCollection
    );

    this._buildActions(
      userId,
      myDrive,
      sharedWithMeDrive,
      sharedDriveCollection
    );

    return explorer;
  }

  private _buildActions(
    userId: string,
    myDrive: MyDrive,
    sharedWithMeDrive: SharedWithMeDrive,
    sharedDriveCollection: SharedDriveCollection
  ) {
    //#region My Drive Actions
    let openFileAction = new ResourceAction(
      "Open",
      "open_with",
      myDrive.openFileDeleg
    );
    let openFolderAction = new ResourceAction(
      "Open",
      "open_with",
      myDrive.openFolderDeleg
    );
    let downloadAction = new ResourceAction(
      "Download",
      "cloud_download",
      myDrive.downloadDeleg
    );
    let multiDownloadAction = new ResourceAction(
      "Download",
      "cloud_download",
      myDrive.multiDownloadDeleg
    );
    let multiDeleteAction = new ResourceAction(
      "Delete",
      "delete",
      myDrive.multiDeleteDeleg
    );
    let shareAction = new ResourceAction(
      "Share",
      "person_add",
      myDrive.shareDeleg
    );
    let renameAction = new ResourceAction<Resource, any, any>(
      "Rename",
      "drive_file_rename_outline",
      myDrive.renameDeleg
    );
    let deleteAction = new ResourceAction(
      "Delete",
      "delete",
      myDrive.deleteDeleg
    );
    let newFolderAction = new ResourceAction<CloudFolder, any, any>(
      "New Folder",
      "create_new_folder",
      myDrive.newFolderDeleg
    );
    let uploadFileAction = new ResourceAction<CloudFolder, any, any>(
      "Upload Local File",
      "cloud_upload",
      myDrive.uploadDeleg,
      null,
      (resource) => myDrive.enableUploadDeleg(resource as CloudFolder, userId)
    );
    let uploadFolderAction = new ResourceAction<CloudFolder, any, any>(
      "Upload Local Folder",
      "drive_folder_upload",
      myDrive.uploadFolderDeleg,
      null,
      (resource) => myDrive.enableUploadDeleg(resource as CloudFolder, userId)
    );
    let uploadFromGoogleAction = new ResourceAction<CloudFolder, any, any>(
      "Upload Google Drive File",
      "google_drive",
      myDrive.uploadFromGoogleDeleg
    );
    let moveAction = new ResourceAction(
      this.translateService.instant("STORAGE.MOVE_TO"),
      "drive_file_move",
      myDrive.moveDeleg
    );
    let copyAction = new ResourceAction(
      this.translateService.instant("STORAGE.DUPLICATE"),
      "content_copy",
      myDrive.copyDeleg
    );

    myDrive.fileActions = [
      openFileAction,
      downloadAction,
      shareAction,
      renameAction,
      moveAction,
      copyAction,
      deleteAction,
    ];

    myDrive.folderActions = [
      openFolderAction,
      downloadAction,
      shareAction,
      renameAction,
      moveAction,
      deleteAction,
    ];

    myDrive.withinFolderActions = [
      newFolderAction,
      uploadFileAction,
      uploadFolderAction,
      uploadFromGoogleAction,
    ];
    myDrive.uploadActions = [
      uploadFileAction,
      uploadFolderAction,
      uploadFromGoogleAction,
    ];
    myDrive.multiResourcesActions = [
      multiDownloadAction,
      moveAction,
      copyAction,
      multiDeleteAction
    ];

    //#endregion

    //#region Shared With Me Actions
    let sharedOpenFileAction = new ResourceAction(
      openFileAction.name,
      openFileAction.icon,
      sharedWithMeDrive.openFileDeleg
    );
    let sharedOpenFolderAction = new ResourceAction(
      openFolderAction.name,
      openFolderAction.icon,
      sharedWithMeDrive.openFolderDeleg
    );
    let sharedDownloadFileAction = new ResourceAction(
      downloadAction.name,
      downloadAction.icon,
      sharedWithMeDrive.downloadDeleg,
      new AccessPermissionSetting(AccessPermission.DownloadFile)
    );
    let sharedDownloadFolderAction = new ResourceAction(
      downloadAction.name,
      downloadAction.icon,
      sharedWithMeDrive.downloadDeleg,
      new AccessPermissionSetting(AccessPermission.Read)
    );
    let sharedMoveFolderAction = new SharedResourceAction(
      moveAction.name,
      moveAction.icon,
      sharedWithMeDrive.moveDeleg,
      new AccessPermissionSetting(AccessPermission.Update, true)
    );
    let sharedMoveFileAction = new SharedResourceAction(
      moveAction.name,
      moveAction.icon,
      sharedWithMeDrive.moveDeleg,
      new AccessPermissionSetting(AccessPermission.UpdateFile, true)
    );
    let sharedMoveMultiAction = new SharedResourceAction(
      moveAction.name,
      moveAction.icon,
      sharedWithMeDrive.moveDeleg,
      null,
      (resource, userId) => {
        if (resource instanceof Resource) {
          return resource.isFile
            ? resource.hasPermission(userId, AccessPermission.UpdateFile, true)
            : resource.hasPermission(userId, AccessPermission.Update, true);
        } else if (Array.isArray(resource)) {
          return resource.every((r) =>
            r.isFile
              ? r.hasPermission(userId, AccessPermission.UpdateFile, true)
              : r.hasPermission(userId, AccessPermission.Update, true)
          );
        }

        return false;
      }
    );
    let sharedDeleteFolderAction = new SharedResourceAction(
      deleteAction.name,
      deleteAction.icon,
      sharedWithMeDrive.deleteDeleg,
      new AccessPermissionSetting(AccessPermission.Delete)
    );
    let sharedDeleteFileAction = new SharedResourceAction(
      deleteAction.name,
      deleteAction.icon,
      sharedWithMeDrive.deleteDeleg,
      new AccessPermissionSetting(AccessPermission.DeleteFile)
    );
    let sharedRenameFolderAction = new SharedResourceAction(
      renameAction.name,
      renameAction.icon,
      sharedWithMeDrive.renameDeleg,
      new AccessPermissionSetting(AccessPermission.Update, true)
    );
    let sharedRenameFileAction = new SharedResourceAction(
      renameAction.name,
      renameAction.icon,
      sharedWithMeDrive.renameDeleg,
      new AccessPermissionSetting(AccessPermission.UpdateFile, true)
    );
    let sharedNewFolderAction = new SharedResourceAction(
      newFolderAction.name,
      newFolderAction.icon,
      sharedWithMeDrive.newFolderDeleg,
      new AccessPermissionSetting(AccessPermission.Create)
    );
    let sharedUploadFileAction = new SharedResourceAction(
      uploadFileAction.name,
      uploadFileAction.icon,
      sharedWithMeDrive.uploadDeleg,
      new AccessPermissionSetting(AccessPermission.UploadFile)
    );
    let sharedUploadFolderAction = new SharedResourceAction(
      uploadFolderAction.name,
      uploadFolderAction.icon,
      sharedWithMeDrive.uploadFolderDeleg,
      new AccessPermissionSetting(AccessPermission.Create)
    );
    let sharedUploadFromGoogleAction = new SharedResourceAction(
      uploadFromGoogleAction.name,
      uploadFromGoogleAction.icon,
      sharedWithMeDrive.uploadFromGoogleDeleg,
      new AccessPermissionSetting(AccessPermission.UploadFile)
    );
    let sharedMultiDownloadAction = new SharedResourceAction(
      multiDownloadAction.name,
      multiDownloadAction.icon,
      sharedWithMeDrive.multiDownloadDeleg,
      new AccessPermissionSetting(AccessPermission.View)
    );
    let sharedMultiDeleteAction = new SharedResourceAction(
      multiDeleteAction.name,
      multiDeleteAction.icon,
      sharedWithMeDrive.multiDeleteDeleg,
      null,
      (resource, userId) => {
        if (resource instanceof Resource) {
          return resource.isFile
            ? resource.hasPermission(userId, AccessPermission.DeleteFile, true)
            : resource.hasPermission(userId, AccessPermission.Delete, true);
        } else if (Array.isArray(resource)) {
          return resource.every((r) => {
            r.isFile ? r.hasPermission(userId, AccessPermission.DeleteFile, true)
              : r.hasPermission(userId, AccessPermission.Delete, true);
          })
        }
      }
    );
    let sharedCopyAction = new SharedResourceAction(
      copyAction.name,
      copyAction.icon,
      sharedWithMeDrive.copyDeleg,
      new AccessPermissionSetting(AccessPermission.UploadFile, true)
    );

    sharedWithMeDrive.fileActions = [
      sharedOpenFileAction,
      sharedDownloadFileAction,
      sharedRenameFileAction,
      sharedMoveFileAction,
      sharedCopyAction,
      sharedDeleteFileAction,
    ];

    sharedWithMeDrive.folderActions = [
      sharedOpenFolderAction,
      sharedDownloadFolderAction,
      sharedRenameFolderAction,
      sharedMoveFolderAction,
      sharedDeleteFolderAction,
    ];
    sharedWithMeDrive.withinFolderActions = [
      sharedNewFolderAction,
      sharedUploadFileAction,
      sharedUploadFolderAction,
      sharedUploadFromGoogleAction,
    ];
    sharedWithMeDrive.uploadActions = [
      sharedUploadFileAction,
      sharedUploadFolderAction,
      sharedUploadFromGoogleAction,
    ];
    sharedWithMeDrive.multiResourcesActions = [
      sharedMultiDownloadAction,
      sharedMoveMultiAction,
      sharedCopyAction,
      sharedMultiDeleteAction
    ];
    //#endregion

    //#region Shared Drive Collection Actions
    if (sharedDriveCollection != null) {
      let driveCollectionRenameAction = new ResourceAction<
        Resource,
        any,
        any
      >(
        "Rename",
        "drive_file_rename_outline",
        sharedDriveCollection.renameDeleg,
        new AccessPermissionSetting(AccessPermission.Update)
      );
      let driveCollectionDeleteAction = new ResourceAction(
        "Delete",
        "delete",
        sharedDriveCollection.deleteDeleg,
        new AccessPermissionSetting(AccessPermission.Delete)
      );
      let driveCollectionShareAction = new ResourceAction(
        this.translateService.instant("STORAGE.MANAGE_MEMBERS"),
        "person_add",
        sharedDriveCollection.shareDeleg,
        new AccessPermissionSetting(AccessPermission.Update)
      );
      let driveCollectionNewFolderAction = new ResourceAction<
        CloudFolder,
        any,
        any
      >(
        "New Drive",
        "create_new_folder",
        sharedDriveCollection.newFolderDeleg,
        new AccessPermissionSetting(AccessPermission.Create)
      );

      sharedDriveCollection.folderActions = [
        driveCollectionShareAction,
        driveCollectionRenameAction,
        driveCollectionDeleteAction,
      ];
      sharedDriveCollection.withinFolderActions = [
        driveCollectionNewFolderAction,
      ];

      if (sharedDriveCollection.drives.length > 0) {
        sharedDriveCollection.drives.forEach((sharedDrive) => { });
      }
    }
    //#endregion
  }

  private _getContacts(orgId: string) {
    return async () => {
      var contacts = this.enterpriseSelector.getContacts();
      if (contacts == null || contacts.length == 0) {
        await this.flowManager.getContacts(orgId);
        return this.enterpriseSelector.getContacts();
      }

      return contacts;
    };
  }

  private _buildMyDrive(orgId: string): MyDrive {
    let myDriveGetFoldersSizeDeleg = (resources: Resource[]) => {
      var folders = resources.filter((r) => !r.isFile);
      if (folders.length == 0) return Promise.resolve(0);

      var paths = folders.map((f) => f.path);
      return this.cloudFileService.getTotalUserFoldersSize(orgId, paths);
    };
    let readDeleg = (resource) =>
      this.cloudFileService
        .readUserFolder(orgId, resource?.path)
        .then((res) => {
          if (res.isRoot) {
            res.name = "My Drive";
            res.icon = "hard_drive";
          }
          return res;
        });
    return new MyDrive({
      checkReadAccessDeleg: () => this.cloudFileService.checkUserFolder(orgId),
      shareDeleg: () => {
        let saveGranteesDeleg: SaveGranteesDeleg = (resource, data) => {
          if (data == null) return;

          let promises: Promise<any>[] = [];

          let saveGranteesPromise = this.cloudFileService.updateUserResourceShareClaims(
            orgId,
            resource.path,
            data.accessRights,
            data.toNotifyUser
          );
          promises.push(saveGranteesPromise);

          let changedNotifPromise = this.cloudFileService.changeUserNotificationSettings(orgId, resource.path, data.enableNotifications, resource.isFile);
          promises.push(changedNotifPromise);

          return PromiseAllSettled.result(promises).then((res) => {
            if (res[0].status == "rejected" && res[1].status == "rejected") {
              throw new Error(this.translateService.instant("STORAGE.SAVE_SHARE_ACCESS_FAILED_1"));
            } else if (res[0].status == "rejected" && res[1].status == "fulfilled") {
              throw new Error(this.translateService.instant("STORAGE.SAVE_SHARE_ACCESS_FAILED_2"));
            } else if (res[0].status == "fulfilled" && res[1].status == "rejected") {
              return Promise.reject({ error: this.translateService.instant("STORAGE.SAVE_SHARE_ACCESS_FAILED_3"), value: res[0].value });
            } else {
              return Promise.resolve(res[0].value);
            }
          });
        };
        let getShareLinkDeleg: GetShareLinkDeleg = (resource) => {
          return this.cloudFileService.getFileOrFolderShareLink(
            resource.path,
            !resource.isFile,
            false,
            orgId
          );
        };
        return {
          saveGranteesDeleg,
          getShareLinkDeleg,
        };
      },
      getContactsDeleg: this._getContacts(orgId),
      enableUploadDeleg: (folder) => true,
      getProgressDeleg: () => this.cloudFileService.pendingUserUploads(orgId),
      listDeleg: readDeleg,
      downloadDeleg: async (resource) => {
        if (resource.isFile) {
          let file = resource as CloudFile;

          return this.cloudFileService
            .downloadUserResourceAsync(
              orgId,
              file.name,
              file.path,
              file.sizeInBytes
            );
        } else {
          let size = await this.cloudFileService.getUserFolderSize(orgId, resource.path);

          return this.cloudFileService
            .downloadMultiUserResourcesAsync(
              orgId,
              [],
              [resource.path],
              size,
              resource.name
            );
        }
      },
      openFileDeleg: (file) => {
        return this.cloudFileService
          .downloadUserResourceAsync(
            orgId,
            file.name,
            file.path,
            file.sizeInBytes,
            false
          );
      },
      multiDownloadDeleg: async (resources) => {
        if (!resources) return;
        if (resources.length == 1 && resources[0].isFile) {
          let file = resources[0] as CloudFile;
          return this.cloudFileService
            .downloadUserResourceAsync(
              orgId,
              file.name,
              file.path,
              file.sizeInBytes
            );
        }
        let filePaths: string[] = [];
        let folderPaths: string[] = [];
        let totalSize: number = 0;

        let folders = [];
        resources.forEach((d) => {
          if (d.isFile) {
            let file = d as CloudFile;
            filePaths.push(file.path);
            totalSize += file.sizeInBytes;
          } else {
            folderPaths.push(d.path);
            folders.push(d);
          }
        });

        if (folders.length > 0) {
          try {
            let size = await myDriveGetFoldersSizeDeleg(folders);
            totalSize += size;
            // console.log("download multi files, total size: " + totalSize);
          } catch (ex) {
            console.log(
              "Error getting folder size, continuing without folder size: " + ex
            );
          }
        }

        return this.cloudFileService
          .downloadMultiUserResourcesAsync(
            orgId,
            filePaths,
            folderPaths,
            totalSize,
            folderPaths.length == 1 && filePaths.length == 0
              ? folders[0].name
              : ""
          );
      },
      multiDeleteDeleg: (resources) => {
        if (resources == null) return;
        var foldersToDelete = resources
          .filter((f) => f.isFolder)
          .map((r) => r.path);
        var filesToDelete = resources
          .filter((f) => f.isFile)
          .map((r) => r.path);
        return this.cloudFileService
          .deleteMultipleUserResources(orgId, foldersToDelete, filesToDelete)
          .toPromise();
      },
      deleteDeleg: (resource) => {
        return this.cloudFileService
          .deleteUserResource(orgId, resource.path)
          .toPromise();
      },
      moveDeleg: (resources, data) => {
        let listFoldersDeleg: ListFoldersDeleg = (resource) => {
          return readDeleg(resource).then((res) => {
            return res.folders;
          });
        };
        let moveDeleg: MoveResourcesDeleg = (resources, data) => {
          var foldersToMove = resources
            .filter((f) => f.isFolder)
            .map((r) => r.path);
          var filesToMove = resources
            .filter((f) => f.isFile)
            .map((r) => r.path);
          var path = data && data.origin ? data.origin.path : "";
          var destPath = data && data.dest ? data.dest.path : "";
          var name = data && data.dest ? data.dest.name : "";

          return this.cloudFileService
            .moveUserItems(
              orgId,
              path,
              destPath,
              name,
              filesToMove,
              foldersToMove
            )
            .toPromise();
        };
        let deleg = (folders) => folders;
        return {
          listFoldersDeleg,
          moveDeleg,
          movableParentFoldersDeleg: deleg,
        };
      },
      copyDeleg: (resources, data) => {
        var foldersToCopy = resources
          .filter((f) => f.isFolder)
          .map((r) => r.path);
        var filesToCopy = resources
          .filter((f) => f.isFile)
          .map((r) => r.path);
        var path = data && data.origin ? data.origin.path : "";
        var destPath = data && data.dest ? data.dest.path : "";
        var name = data && data.dest ? data.dest.name : "";
        return this.cloudFileService
          .copyUserItems(
            orgId,
            path,
            destPath,
            name,
            filesToCopy,
            foldersToCopy
          )
          .toPromise();
      },
      renameDeleg: (resource, data) => {
        return this.cloudFileService
          .renameUserItem(orgId, data?.name, resource.path)
          .toPromise();
      },

      uploadDeleg: (resource, data) =>
        this.cloudFileService
          .streamUserFile(
            orgId,
            resource?.name ?? "",
            resource?.path ?? "",
            data?.files
          ),
      uploadFolderDeleg: (resource, data) => {
        return this.cloudFileService.streamUserFolder(
          orgId,
          resource?.name ?? "",
          data?.files,
          data?.size,
          data?.name,
          resource?.path ?? "",

        ).then((res) => {
          return res;
        })
      }
      ,
      uploadFromGoogleDeleg: (resource, data) =>
        this.cloudFileService
          .streamGDriveUserFolder(
            orgId,
            resource.name,
            resource.path,
            data?.googleData,
            data?.oauthToken
          ),
      getFolderSizeDeleg: myDriveGetFoldersSizeDeleg,
      newFolderDeleg: (resource, data) =>
        this.cloudFileService
          .createUserFolder(orgId, data?.name ?? "", resource.path),
    });
  }

  private _buildSharedWithMeDrive(
    orgId: string,
    userId: string
  ): SharedWithMeDrive {
    let shareWithMeGetFoldersSizeDeleg = (
      resources: Resource[],
      sharedPath: string
    ) => {
      var folders = resources.filter((r) => !r.isFile);
      if (folders.length == 0) return Promise.resolve(0);

      var paths = folders.map((f) => f.path);
      return this.cloudFileService
        .getUserSharedFoldersSize(orgId, paths, sharedPath);
    };
    let listDeleg = (folder: OriginFolder | SharedFolder) => {
      if (!folder || folder.isRoot) return this.cloudFileService.listUserSharedFolder(orgId);
      if (folder instanceof SharedFolder) return this.cloudFileService.readUserSharedFolder(orgId, folder.path);
      if (folder instanceof OriginFolder && !!folder.dummyFolder) return this.cloudFileService.readUserSharedOriginFolder(orgId, folder.dummyFolder.path, folder.path);
      return Promise.reject("Unable to read folder.");
    };

    let downloadDeleg = async (resource: Resource) => {
      
      if (resource instanceof SharedResource) {
        
        let size = (resource instanceof SharedFile) ? resource.originSizeInBytes 
        : await shareWithMeGetFoldersSizeDeleg(
          [resource],
          resource.path
        );
        return this.cloudFileService.downloadUserSharedFileAtRootAsync(
          orgId,
          resource.name,
          resource.path,
          size
        );
        
      } else {
        
        let size = (resource instanceof OriginFile) ? resource.sizeInBytes 
        : await shareWithMeGetFoldersSizeDeleg(
          [resource],
          (resource as OriginResource).sharedDummyFolderPath
        );
        return this.cloudFileService
        .downloadUserSharedFileAsync(
          orgId,
          resource.name,
          resource.path,
          (resource as OriginResource).sharedDummyFolderPath,
          size
          );

      }
    }

    return new SharedWithMeDrive({
      checkReadAccessDeleg: () => this.cloudFileService.checkUserSharedFolder(orgId),
      clearNotificationDeleg: () => {
        this.feedService.updateFeedStatusByKey(FeedState.SHARED_FEEDS_KEY);
      },
      uploadDeleg: (resource, data, sharedPath) =>
      this.cloudFileService
      .streamUserShareFile(
          orgId,
          resource?.name ?? "",
          resource instanceof SharedFolder
            ? resource.originPath
            : resource?.path ?? "",
            resource instanceof SharedFolder
            ? resource.path
            : resource?.sharedDummyFolderPath,
          data?.files
         ),
      uploadFolderDeleg: (resource, data, parentPath) =>
        this.cloudFileService.streamUserShareFolder(
          orgId,
          resource?.name ?? "",
          resource instanceof SharedFolder
            ? resource.originPath
            : resource?.path ?? "",
            resource instanceof SharedFolder
            ? resource.path
            : resource?.sharedDummyFolderPath,
          data?.files,
          data?.size,
          data?.name

        ),
      uploadFromGoogleDeleg: (resource, data, sharedPath) =>
        this.cloudFileService
          .streamGDriveUserSharedFolder(
            orgId,
            resource.name,
            resource instanceof SharedFolder
            ? resource.originPath
            : resource?.path ?? "",
            resource instanceof SharedFolder
            ? resource.path
            : resource?.sharedDummyFolderPath,
            data?.googleData,
            data?.oauthToken
          ),
      getContactsDeleg: this._getContacts(orgId),
      deleteDeleg: (resource: SharedResource | OriginFile | OriginFolder, data, sharedPath) => {
        if (resource instanceof SharedResource) {
          return this.cloudFileService
            .removeUserSharedAccess(
              orgId,
              resource.originPath,
              sharedPath,
              resource.isFile
            );
        } else {
          return this.cloudFileService
            .deleteUserSharedItem(
              orgId,
              resource.path,
              sharedPath,
            );
        }
      },
      enableUploadDeleg: (folder) =>
        folder.hasPermission(userId, AccessPermission.UploadFile, false, true),
      getProgressDeleg: () => this.cloudFileService.pendingUserUploads(orgId),
      listDeleg: listDeleg,
      downloadDeleg: downloadDeleg,
      openFileDeleg: downloadDeleg,
      multiDownloadDeleg: async (resources, data, parentPath) => {
        if (!resources) return;
        if (resources.length == 1 && resources[0].isFile) {
          let fileSize = resources[0] instanceof SharedFile ?
            (resources[0] as SharedFile).originSizeInBytes
            : (resources[0] as OriginFile).sizeInBytes;

          return this.cloudFileService
            .downloadUserSharedFileAsync(
              orgId,
              resources[0].name,
              resources[0].path,
              resources[0] instanceof SharedFile ? 
              (resources[0] as SharedFile).parentPath
              : (resources[0] as OriginFile).sharedDummyFolderPath,
              fileSize
            );
        }
        let filePaths: string[] = [];
        let folderPaths: string[] = [];
        let totalSize: number = 0;

        let folders = [];
        resources.forEach((d) => {
          if (d.isFile) {
            let file = d as OriginFile;
            filePaths.push(file.path);
            totalSize += file.sizeInBytes;
          } else {
            folderPaths.push(d.path);
            folders.push(d);
          }
        });

        let sharedPath = (resources[0] as OriginResource).sharedDummyFolderPath;

        if (folders.length > 0) {
          try {
            let size = await shareWithMeGetFoldersSizeDeleg(
              folders,
              sharedPath
            );
            totalSize += size;
            // console.log("download multi files, total size: " + totalSize);
          } catch (ex) {
            // console.log(
            //   "Error getting folder size, continuing without folder size: " + ex
            // );
          }
        }

        return this.cloudFileService
          .downloadMultiUserSharedFileAsync(
            orgId,
            filePaths,
            folderPaths,
            totalSize,
            sharedPath,
            folderPaths.length == 1 && filePaths.length == 0
              ? folders[0].name
              : ""
          );
      },
      multiDeleteDeleg: (resources: Resource[]) => {
        if (resources == null) return;
        var foldersToDelete = resources
          .filter((f) => f.isFolder)
          .map((r) => r.path);
        var filesToDelete = resources
          .filter((f) => f.isFile)
          .map((r) => r.path);

          // multi-deletion is only enabled inside of the Shared With Me folders
          let res = resources[0];
          let sharedPath = (res as OriginResource).sharedDummyFolderPath;
          return this.cloudFileService
          .deleteMultiUserSharedItem(orgId, foldersToDelete, filesToDelete, sharedPath);
      },
      moveDeleg: (resources, data, sharedPath) => {
        let listFoldersDeleg: ShareListFoldersDeleg = (resource) =>
          listDeleg(resource).then((res) => {
            return res.folders;
          });
        let moveDeleg: MoveResourcesDeleg = (resources, data, parentPath) => {
          var foldersToMove = resources
            .filter((f) => f.isFolder)
            .map((r) => r.path);
          var filesToMove = resources
            .filter((f) => f.isFile)
            .map((r) => r.path);
            var path =
              data && data.origin
                ? data.origin instanceof SharedResource
                  ? data.origin.originPath
                  : data.origin?.path
                : "";
            var destPath =
              data && data.dest
                ? data.dest instanceof SharedResource
                  ? data.dest.originPath
                  : data.dest?.path
                : "";
            var name = data && data.dest ? data.dest.name : "";

          return this.cloudFileService
            .moveUserSharedItem(
              orgId,
              path,
              parentPath ?? sharedPath,
              destPath,
              name,
              filesToMove,
              foldersToMove
            );
        };
        let deleg = (folders) => folders.filter((f) => !f.isRoot);
        return {
          listFoldersDeleg,
          moveDeleg,
          movableParentFoldersDeleg: deleg,
        };
      },
      copyDeleg: (resources, data, sharedPath) => {
        var foldersToCopy = resources
          .filter((f) => f.isFolder)
          .map((r) => r.path);
        var filesToCopy = resources
          .filter((f) => f.isFile)
          .map((r) => r.path);
        var path =
          data && data.origin ?
          data.origin instanceof SharedResource
          ? data.origin.originPath
          : data.origin?.path 
          : "";
        var destPath =
          data && data.dest ? 
          data.dest instanceof SharedResource
          ? data.dest.originPath
          : data.dest?.path
          : "";
        var name = data && data.dest ? data.dest.name : "";
        return this.cloudFileService
          .copyUserSharedItem(
            orgId,
            path,
            sharedPath,
            destPath,
            name,
            filesToCopy,
            foldersToCopy
          );
      },
      renameDeleg: (resource, data, sharedPath) =>
        this.cloudFileService
          .renameUserSharedItem(
            orgId,
            data?.name,
            resource.path,
            sharedPath
          ),
      getFolderSizeDeleg: shareWithMeGetFoldersSizeDeleg,
      newFolderDeleg: (resource, data, sharedPath) =>
        this.cloudFileService
          .createUserShareFolder(
            orgId,
            data?.name ?? "",
            resource instanceof SharedFolder
            ? resource.originPath
            : resource?.path ?? "",
            sharedPath
          ),
    });
  }

  private _buildSharedDriveCollection(
    orgId: string,
    userId: string
  ): SharedDriveCollection {
    let collection = new SharedDriveCollection({
      checkReadAccessDeleg: () => this.cloudFileService.checkSharedDrives(orgId),
      deleteDeleg: (r: SharedDriveFolder) =>
        this.cloudFileService.deleteSharedDrive(orgId, r.id),
      getContactsDeleg: this._getContacts(orgId),
      buildSharedDriveDeleg: (driveId, name, claims) =>
        this._buildSharedDrive(orgId, driveId, name, claims, userId),
      listDeleg: () => this.cloudFileService.listSharedDrives(orgId),
      renameDeleg: (res, data) => {
        var driveResource = res as SharedDriveFolder;
        return this.cloudFileService
          .renameSharedDrive(orgId, driveResource.id, data.name)
          .then((res) => true)
      },
      newFolderDeleg: (res, data) =>
        this.cloudFileService.createSharedDrive(orgId, data.name),
      shareDeleg: () => {
        let saveGranteesDeleg: SharedDriveSaveGranteesDeleg = (
          resource,
          data
        ) => {
          if (data == null) return;
          return this.cloudFileService.updateSharedDriveClaims(
            orgId,
            resource.isFolder && (resource as SharedDriveFolder).id
              ? (resource as SharedDriveFolder).id
              : resource.driveId,
            data.accessRights
          );
        };
        let getShareLinkDeleg: SharedDriveGetShareLinkDeleg = (resource) => {
          return this.cloudFileService.getDriveOrFileShareLink(
            orgId,
            resource.isFolder && (resource as SharedDriveFolder).id
              ? (resource as SharedDriveFolder).id
              : resource.driveId,
            resource.path,
            true
          );
        };
        return {
          saveGranteesDeleg,
          getShareLinkDeleg,
        };
      },
    });
    return collection;
  }

  private _buildSharedDrive(
    orgId: string,
    driveId: string,
    name: string,
    claims: AccessRight[],
    userId: string
  ): SharedDrive {
    let getFoldersSizeDeleg = (resources: Resource[]) => {
      var folders = resources.filter((r) => !r.isFile);
      if (folders.length == 0) return Promise.resolve(0);

      var paths = folders.map((f) => f.path);
      return this.cloudFileService
        .getDriveFoldersSize(orgId, driveId, paths);
    };
    let readDeleg: BaseResourceDeleg<
      SharedDriveFolder,
      any,
      Promise<SharedDriveFolder>
    > = (resource) =>
        this.cloudFileService
          .readDriveFolder(
            orgId,
            resource.isFolder && (resource as SharedDriveFolder).id
              ? (resource as SharedDriveFolder).id
              : resource.driveId,
            resource?.path
          );
    let sharedDrive = new SharedDrive(driveId, name, claims, {
      checkReadAccessDeleg: () => this.cloudFileService.checkDriveFolder(orgId, driveId),
      shareDeleg: () => {
        let saveGranteesDeleg: SaveGranteesDeleg = async (resource, data) => {
          if (data == null) return;

          let promises: Promise<any>[] = [];

          let saveGranteesPromise = this.cloudFileService.saveDriveResourceClaims(
            orgId,
            driveId,
            resource.path,
            data.accessRights,
            data.toNotifyUser
          );
          promises.push(saveGranteesPromise);

          let changedNotifPromise = this.cloudFileService.changeDriveNotificationSettings(orgId, driveId, resource.path, data.enableNotifications, resource.isFile);
          promises.push(changedNotifPromise);

          return PromiseAllSettled.result(promises).then((res) => {
            if (res[0].status == "rejected" && res[1].status == "rejected") {
              throw new Error(this.translateService.instant("STORAGE.SAVE_SHARE_ACCESS_FAILED_1"));
            } else if (res[0].status == "rejected" && res[1].status == "fulfilled") {
              throw new Error(this.translateService.instant("STORAGE.SAVE_SHARE_ACCESS_FAILED_2"));
            } else if (res[0].status == "fulfilled" && res[1].status == "rejected") {
              return Promise.reject({ error: this.translateService.instant("STORAGE.SAVE_SHARE_ACCESS_FAILED_3"), value: res[0].value });
            } else {
              return Promise.resolve(res[0].value);
            }
          });

        };
        let getShareLinkDeleg: GetShareLinkDeleg = (resource) => {
          return this.cloudFileService.getDriveOrFileShareLink(
            orgId,
            resource.isFile
              ? (resource as SharedDriveFile).driveId
              : (resource as SharedDriveFolder).driveId,
            resource.path,
            !resource.isFile
          );
        };
        return {
          saveGranteesDeleg,
          getShareLinkDeleg,
        };
      },
      getContactsDeleg: this._getContacts(orgId),
      enableUploadDeleg: (folder) => (folder.isRoot ? false : true),
      getProgressDeleg: () => this.cloudFileService.pendingUserUploads(orgId),
      listDeleg: readDeleg,
      downloadDeleg: async (resource) => {
        if (resource.isFile) {
          let file = resource as CloudFile;

          return this.cloudFileService
            .downloadDriveFileAsync(
              orgId,
              driveId,
              file.name,
              file.path,
              file.sizeInBytes
            );
        } else {
          let size = await lastValueFrom(this.cloudFileService.getDriveFolderSize(orgId, driveId, resource.path));

          return this.cloudFileService
            .downloadMultiDriveFileAsync(
              orgId,
              driveId,
              [],
              [resource.path],
              size,
              resource.name
            );
        }
      },
      openFileDeleg: (file) => {
        return this.cloudFileService
          .downloadDriveFileAsync(
            orgId,
            driveId,
            file.name,
            file.path,
            file.sizeInBytes,
            false
          );
      },
      multiDownloadDeleg: async (resources) => {
        if (!resources) return;
        if (resources.length == 1 && resources[0].isFile) {
          let file = resources[0] as CloudFile;
          return this.cloudFileService
            .downloadDriveFileAsync(
              orgId,
              driveId,
              file.name,
              file.path,
              file.sizeInBytes
            );
        }
        let filePaths: string[] = [];
        let folderPaths: string[] = [];
        let totalSize: number = 0;

        let folders = [];
        resources.forEach((d) => {
          if (d.isFile) {
            let file = d as CloudFile;
            filePaths.push(file.path);
            totalSize += file.sizeInBytes;
          } else {
            folderPaths.push(d.path);
            folders.push(d);
          }
        });

        if (folders.length > 0) {
          try {
            let size = await getFoldersSizeDeleg(folders);
            totalSize += size;
            // console.log("download multi files, total size: " + totalSize);
          } catch (ex) {
            console.log(
              "Error getting folder size, continuing without folder size: " + ex
            );
          }
        }

        return this.cloudFileService
          .downloadMultiDriveFileAsync(
            orgId,
            driveId,
            filePaths,
            folderPaths,
            totalSize,
            folderPaths.length == 1 && filePaths.length == 0
              ? folders[0].name
              : ""
          );
      },
      multiDeleteDeleg: (resources) => {
        if (resources == null) return;
        var foldersToDelete = resources
          .filter((f) => f.isFolder)
          .map((r) => r.path);
        var filesToDelete = resources
          .filter((f) => f.isFile)
          .map((r) => r.path);
        return this.cloudFileService
          .deleteMultiDriveResources(orgId, driveId, foldersToDelete, filesToDelete);
      },
      deleteDeleg: (resource) => {
        return this.cloudFileService
          .deleteDriveFolder(orgId, driveId, resource.path);
      },
      moveDeleg: (resources, data) => {
        let listFoldersDeleg: SharedDriveListFoldersDeleg = (resource) => {
          return readDeleg(resource).then((res) => {
            return res.folders;
          });
        };
        let moveDeleg: MoveResourcesDeleg = (resources, data) => {
          var foldersToMove = resources
            .filter((f) => f.isFolder)
            .map((r) => r.path);
          var filesToMove = resources
            .filter((f) => f.isFile)
            .map((r) => r.path);
          var path = data && data.origin ? data.origin.path : "";
          var destPath = data && data.dest ? data.dest.path : "";
          var name = data && data.dest ? data.dest.name : "";

          return this.cloudFileService
            .moveDriveItems(
              orgId,
              driveId,
              path,
              destPath,
              name,
              filesToMove,
              foldersToMove
            );
        };
        let deleg = (folders: Resource[]) => {
          //filter out other drives so that move only available within current drive
          let index = _.findLastIndex(
            folders,
            (f) =>
              f instanceof SharedDriveFolder && (f as SharedDriveFolder).id,
            folders.length - 1
          );
          if (index == -1 || index == 0) return folders;
          folders.splice(index - 1, index);
          return folders;
        };
        return {
          listFoldersDeleg,
          moveDeleg,
          movableParentFoldersDeleg: deleg,
        };
      },
      copyDeleg: (resources, data) => {
        var foldersToCopy = resources
          .filter((f) => f.isFolder)
          .map((r) => r.path);
        var filesToCopy = resources
          .filter((f) => f.isFile)
          .map((r) => r.path);
        var path = data && data.origin ? data.origin.path : "";
        var destPath = data && data.dest ? data.dest.path : "";
        var name = data && data.dest ? data.dest.name : "";
        return this.cloudFileService
          .copyDriveItems(
            orgId,
            driveId,
            path,
            destPath,
            name,
            filesToCopy,
            foldersToCopy
          );
      },
      renameDeleg: (resource, data) => {
        return this.cloudFileService
          .renameDriveItem(orgId, driveId, data?.name, resource.path);
      },

      uploadDeleg: (resource, data) =>
        this.cloudFileService
          .streamDriveFile(
            orgId,
            driveId,
            resource?.name ?? "",
            resource?.path ?? "",
            data?.files
          ),
      uploadFolderDeleg: (resource, data) =>
        this.cloudFileService.streamDriveFolder(
          orgId,
          driveId,
          resource?.name ?? "",
          resource?.path ?? "",
          data?.size,
          data?.files,
          data?.name
        ),
      uploadFromGoogleDeleg: (resource, data) =>
        this.cloudFileService
          .streamGDriveDriveFolder(
            orgId,
            driveId,
            resource.name,
            resource.path,
            data?.googleData,
            data?.oauthToken
          ),
      getFolderSizeDeleg: getFoldersSizeDeleg,
      newFolderDeleg: (resource, data) =>
        this.cloudFileService
          .createDriveFolder(orgId, driveId, data?.name ?? "", resource.path),
    });

    if (sharedDrive != null) {
      let getDriveClaimsDeleg = (driveId) => claims;
      let driveOpenFileAction = new SharedDriveResourceAction(
        "Open",
        "open_with",
        getDriveClaimsDeleg,
        sharedDrive.openFileDeleg,
        new AccessPermissionSetting(AccessPermission.ReadFile)
      );
      let driveOpenFolderAction = new SharedDriveResourceAction(
        "Open",
        "open_with",
        getDriveClaimsDeleg,
        sharedDrive.openFolderDeleg,
        new AccessPermissionSetting(AccessPermission.Read)
      );
      let driveDownloadAction = new SharedDriveResourceAction(
        "Download",
        "cloud_download",
        getDriveClaimsDeleg,
        sharedDrive.downloadDeleg,
        new AccessPermissionSetting(AccessPermission.ReadFile)
      );
      let driveMultiDownloadAction = new SharedDriveResourceAction(
        "Download",
        "cloud_download",
        getDriveClaimsDeleg,
        sharedDrive.multiDownloadDeleg,
        new AccessPermissionSetting(AccessPermission.ReadFile)
      );
      let driveShareFolderAction = new SharedDriveResourceAction(
        "Share",
        "person_add",
        getDriveClaimsDeleg,
        sharedDrive.shareDeleg,
        new AccessPermissionSetting(AccessPermission.Update)
      );
      let driveShareFileAction = new SharedDriveResourceAction(
        "Share",
        "person_add",
        getDriveClaimsDeleg,
        sharedDrive.shareDeleg,
        new AccessPermissionSetting(AccessPermission.UpdateFile)
      );
      let driveRenameFolderAction = new SharedDriveResourceAction(
        "Rename",
        "drive_file_rename_outline",
        getDriveClaimsDeleg,
        sharedDrive.renameDeleg,
        new AccessPermissionSetting(AccessPermission.Update)
      );
      let driveRenameFileAction = new SharedDriveResourceAction(
        "Rename",
        "drive_file_rename_outline",
        getDriveClaimsDeleg,
        sharedDrive.renameDeleg,
        new AccessPermissionSetting(AccessPermission.UpdateFile)
      );
      let driveDeleteFolderAction = new SharedDriveResourceAction(
        "Delete",
        "delete",
        getDriveClaimsDeleg,
        sharedDrive.deleteDeleg,
        new AccessPermissionSetting(AccessPermission.Delete)
      );
      let driveDeleteFileAction = new SharedDriveResourceAction(
        "Delete",
        "delete",
        getDriveClaimsDeleg,
        sharedDrive.deleteDeleg,
        new AccessPermissionSetting(AccessPermission.DeleteFile)
      );
      let driveMultiDeleteAction = new SharedDriveResourceAction(
        "Delete",
        "delete",
        getDriveClaimsDeleg,
        sharedDrive.multiDeleteDeleg,
        null,
        (resource, userId) => {
          let checkPermission = (r) =>
            r.isFile
              ? (r as SharedDriveFile).hasDrivePermission(
                userId,
                AccessPermission.DeleteFile,
                getDriveClaimsDeleg(driveId)
              )
              : (r as SharedDriveFolder).hasDrivePermission(
                userId,
                AccessPermission.Delete,
                getDriveClaimsDeleg(driveId)
              );
          if (resource instanceof Resource) {
            return checkPermission(resource);
          } else if (Array.isArray(resource)) {
            return resource.every((r) => checkPermission(r));
          }

          return false;
        }
      );
      let driveNewFolderAction = new SharedDriveResourceAction<
        any,
        any
      >(
        "New Folder",
        "create_new_folder",
        getDriveClaimsDeleg,
        sharedDrive.newFolderDeleg,
        new AccessPermissionSetting(AccessPermission.Create)
      );
      let driveUploadFileAction = new SharedDriveResourceAction(
        "Upload Local File",
        "cloud_upload",
        getDriveClaimsDeleg,
        sharedDrive.uploadDeleg,
        new AccessPermissionSetting(AccessPermission.UploadFile)
      );
      let driveUploadFolderAction = new SharedDriveResourceAction(
        "Upload Local Folder",
        "drive_folder_upload",
        getDriveClaimsDeleg,
        sharedDrive.uploadFolderDeleg,
        new AccessPermissionSetting(AccessPermission.UploadFile)
      );
      let driveUploadFromGoogleAction = new SharedDriveResourceAction(
        "Upload Google Drive File",
        "google_drive",
        getDriveClaimsDeleg,
        sharedDrive.uploadFromGoogleDeleg,
        new AccessPermissionSetting(AccessPermission.UploadFile)
      );
      let driveMoveFolderAction = new SharedDriveResourceAction(
        this.translateService.instant("STORAGE.MOVE_TO"),
        "drive_file_move",
        getDriveClaimsDeleg,
        sharedDrive.moveDeleg,
        new AccessPermissionSetting(AccessPermission.Update, true)
      );
      let driveMoveFileAction = new SharedDriveResourceAction(
        this.translateService.instant("STORAGE.MOVE_TO"),
        "drive_file_move",
        getDriveClaimsDeleg,
        sharedDrive.moveDeleg,
        new AccessPermissionSetting(AccessPermission.UpdateFile, true)
      );
      let driveMoveMultiAction = new SharedDriveResourceAction(
        this.translateService.instant("STORAGE.MOVE_TO"),
        "drive_file_move",
        getDriveClaimsDeleg,
        sharedDrive.moveDeleg,
        null,
        (resource, userId) => {
          let checkPermission = (r) =>
            r.isFile
              ? (r as SharedDriveFile).hasDrivePermission(
                userId,
                AccessPermission.UpdateFile,
                getDriveClaimsDeleg(driveId)
              )
              : (r as SharedDriveFolder).hasDrivePermission(
                userId,
                AccessPermission.Update,
                getDriveClaimsDeleg(driveId)
              );
          if (resource instanceof Resource) {
            return checkPermission(resource);
          } else if (Array.isArray(resource)) {
            return resource.every((r) => checkPermission(r));
          }

          return false;
        }
      );
      let driveCopyAction = new SharedDriveResourceAction(
        this.translateService.instant("STORAGE.DUPLICATE"),
        "content_copy",
        getDriveClaimsDeleg,
        sharedDrive.copyDeleg,
        new AccessPermissionSetting(AccessPermission.UpdateFile)
      );
      let driveMultiCopyAction = new SharedDriveResourceAction(
        this.translateService.instant("STORAGE.DUPLICATE"),
        "content_copy",
        getDriveClaimsDeleg,
        sharedDrive.copyDeleg,
        null,
        (resource, userId) => {
          let checkPermission = (r) =>
            r.isFile
              ? (r as SharedDriveFile).hasDrivePermission(
                userId,
                AccessPermission.UpdateFile,
                getDriveClaimsDeleg(driveId)
              )
              : (r as SharedDriveFolder).hasDrivePermission(
                userId,
                AccessPermission.Update,
                getDriveClaimsDeleg(driveId)
              );
          if (resource instanceof Resource) {
            return checkPermission(resource);
          } else if (Array.isArray(resource)) {
            return resource.every((r) => checkPermission(r));
          }

          return false;
        });
      sharedDrive.fileActions = [
        driveOpenFileAction,
        driveDownloadAction,
        driveShareFileAction,
        driveRenameFileAction,
        driveMoveFileAction,
        driveCopyAction,
        driveDeleteFileAction,
      ];

      sharedDrive.folderActions = [
        driveOpenFolderAction,
        driveDownloadAction,
        driveShareFolderAction,
        driveRenameFolderAction,
        driveMoveFolderAction,
        driveDeleteFolderAction,
      ];

      sharedDrive.withinFolderActions = [
        driveNewFolderAction,
        driveUploadFileAction,
        driveUploadFolderAction,
        driveUploadFromGoogleAction,
      ];
      sharedDrive.uploadActions = [
        driveUploadFileAction,
        driveUploadFolderAction,
        driveUploadFromGoogleAction,
      ];
      sharedDrive.multiResourcesActions = [
        driveMultiDownloadAction,
        driveMoveMultiAction,
        driveMultiCopyAction,
        driveMultiDeleteAction
      ];
    }

    return sharedDrive;
  }

  private _buildPersonalMyDrive(orgId: string) {
    let myDriveGetFoldersSizeDeleg = (resources: Resource[]) => {
      var folders = resources.filter((r) => !r.isFile);
      if (folders.length == 0) return Promise.resolve(0);

      var paths = folders.map((f) => f.path);
      return this.cloudFileService.getTotalPersonalFoldersSize(paths);
    };
    let readDeleg = (resource) =>
      this.cloudFileService
        .readPersonalFolder(resource?.path)
        .then((res) => {
          if (res.isRoot) {
            res.name = "My Drive";
            res.icon = "hard_drive";
          }
          return res;
        });
    return new MyDrive({
      checkReadAccessDeleg: () => this.cloudFileService.checkPersonalFolder(),
      shareDeleg: () => {
        let saveGranteesDeleg: SaveGranteesDeleg = (resource, data) => {
          if (data == null) return;

          let promises: Promise<any>[] = [];

          let saveGranteesPromise = this.cloudFileService.savePersonalResourceClaims(resource.path, data.accessRights, data.toNotifyUser, resource.isFile);
          promises.push(saveGranteesPromise);

          let changedNotifPromise = this.cloudFileService.changePersonalNotificationSettings(resource.path, data.enableNotifications, resource.isFile);
          promises.push(changedNotifPromise);

          return PromiseAllSettled.result(promises).then((res) => {
            if (res[0].status == "rejected" && res[1].status == "rejected") {
              throw new Error(this.translateService.instant("STORAGE.SAVE_SHARE_ACCESS_FAILED_1"));
            } else if (res[0].status == "rejected" && res[1].status == "fulfilled") {
              throw new Error(this.translateService.instant("STORAGE.SAVE_SHARE_ACCESS_FAILED_2"));
            } else if (res[0].status == "fulfilled" && res[1].status == "rejected") {
              return Promise.reject({ error: this.translateService.instant("STORAGE.SAVE_SHARE_ACCESS_FAILED_3"), value: res[0].value });
            } else {
              return Promise.resolve(res[0].value);
            }
          });
        };
        let getShareLinkDeleg: GetShareLinkDeleg = (resource) => {
          return this.cloudFileService.getPersonalFolderOrFileShareLink(
            resource.path,
            !resource.isFile
          );
        };
        return {
          saveGranteesDeleg,
          getShareLinkDeleg,
        };
      },
      getContactsDeleg: this._getContacts(orgId),
      enableUploadDeleg: () => true,
      getProgressDeleg: () => this.cloudFileService.pendingPersonalUploads(),
      listDeleg: (resource) =>
        this.cloudFileService.readPersonalFolder(resource?.path),
      downloadDeleg: async (resource) => {
        if (resource.isFile) {
          let file = resource as CloudFile;

          return this.cloudFileService
            .downloadPersonalFileAsync(
              file.name,
              file.path,
              file.sizeInBytes
            );
        } else {
          let size = await this.cloudFileService.getPersonalFolderSize(resource.path);

          return this.cloudFileService
            .downloadMultiPersonalFileAsync(
              [],
              [resource.path],
              size,
              resource.name
            );
        }
      },
      openFileDeleg: (file) => {
        return this.cloudFileService
          .downloadPersonalFileAsync(file.name, file.path, file.sizeInBytes);
      },
      multiDownloadDeleg: async (resources) => {
        if (!resources) return;
        if (resources.length == 1 && resources[0].isFile) {
          let file = resources[0] as CloudFile;
          return this.cloudFileService
            .downloadPersonalFileAsync(
              file.name,
              file.path,
              file.sizeInBytes
            );
        }
        let filePaths: string[] = [];
        let folderPaths: string[] = [];
        let totalSize: number = 0;

        let folders = [];
        resources.forEach((d) => {
          if (d.isFile) {
            let file = d as CloudFile;
            filePaths.push(file.path);
            totalSize += file.sizeInBytes;
          } else {
            folderPaths.push(d.path);
            folders.push(d);
          }
        });

        if (folders.length > 0) {
          try {
            let size = await myDriveGetFoldersSizeDeleg(folders);
            totalSize += size;
            // console.log("download multi files, total size: " + totalSize);
          } catch (ex) {
            console.log(
              "Error getting folder size, continuing without folder size: " + ex
            );
          }
        }

        return this.cloudFileService
          .downloadMultiPersonalFileAsync(
            filePaths,
            folderPaths,
            totalSize,
            folderPaths.length == 1 && filePaths.length == 0
              ? folders[0].name
              : ""
          );
      },
      multiDeleteDeleg: (resources) => {
        if (resources == null) return;
        var foldersToDelete = resources
          .filter((f) => f.isFolder)
          .map((r) => r.path);
        var filesToDelete = resources
          .filter((f) => f.isFile)
          .map((r) => r.path);
        return this.cloudFileService
          .deleteMultiPersonalFolder(foldersToDelete, filesToDelete);
      },
      deleteDeleg: (resource) => {
        return this.cloudFileService
          .deletePersonalFolder(resource.path);
      },
      moveDeleg: (resources, data) => {
        let listFoldersDeleg: ListFoldersDeleg = (resource) => {
          return readDeleg(resource).then((res) => {
            return res.folders;
          });
        };

        // let listFoldersDeleg: ListFoldersDeleg = (resource) => {
        //   return this.cloudFileService
        //     .readPersonalFolder(resource?.path)
        //     .toPromise()
        //     .then((res) => {
        //       return res.folders;
        //     });
        // };
        let moveDeleg: MoveResourcesDeleg = (resources, data) => {
          var foldersToMove = resources
            .filter((f) => f.isFolder)
            .map((r) => r.path);
          var filesToMove = resources
            .filter((f) => f.isFile)
            .map((r) => r.path);
          var path = data && data.origin ? data.origin.path : "";
          var destPath = data && data.dest ? data.dest.path : "";
          var name = data && data.dest ? data.dest.name : "";

          return this.cloudFileService
            .movePersonalItems(path, destPath, name, filesToMove, foldersToMove);
        };
        let deleg = (folders) => folders;
        return {
          listFoldersDeleg,
          moveDeleg,
          movableParentFoldersDeleg: deleg,
        };
      },
      copyDeleg: (resources, data) => {
        var foldersToCopy = resources
          .filter((f) => f.isFolder)
          .map((r) => r.path);
        var filesToCopy = resources
          .filter((f) => f.isFile)
          .map((r) => r.path);
        var path = data && data.origin ? data.origin.path : "";
        var destPath = data && data.dest ? data.dest.path : "";
        var name = data && data.dest ? data.dest.name : "";
        return this.cloudFileService
          .copyPersonalItems(path, destPath, name, filesToCopy, foldersToCopy);
      },
      renameDeleg: (resource, data) => {
        return this.cloudFileService
          .renamePersonalItem(data?.name, resource.path, resource.isFile);
      },

      uploadDeleg: (resource, data) =>
        this.cloudFileService
          .streamPersonalFile(
            resource?.name ?? "",
            resource?.path ?? "",
            data?.files
          ),
      uploadFolderDeleg: (resource, data) =>
        this.cloudFileService.streamPersonalFolder(
          resource?.name ?? "",
          resource?.path ?? "",
          data?.size,
          data?.files,
          data?.name
        ),
      uploadFromGoogleDeleg: (resource, data) =>
          this.cloudFileService
            .streamGDrivePersonalFolder(
              resource.name,
              resource.path,
              data?.googleData,
              data?.oauthToken
            
        ),
      getFolderSizeDeleg: myDriveGetFoldersSizeDeleg,
      newFolderDeleg: (resource, data) =>
        this.cloudFileService
          .createPersonalFolder(data?.name ?? "", resource.path),
    });
  }

  private _buildPersonalSharedWithMeDrive(
    orgId: string,
    userId: string
  ): SharedWithMeDrive {
    let shareWithMeGetFoldersSizeDeleg = (
      resources: Resource[],
      parentPath: string
    ) => {
      var folders = resources.filter((r) => !r.isFile);
      if (folders.length == 0) return Promise.resolve(0);

      var paths = folders.map((f) => f.path);
      return this.cloudFileService
        .getPersonalSharedFoldersSize(paths, parentPath);
    };
    let listDeleg = (folder) => {
      if (!folder || folder.isRoot) return this.cloudFileService.listPersonalSharedFolder();
      if (folder instanceof SharedFolder) return this.cloudFileService.readPersonalSharedFolder(folder.path);
      if (folder instanceof OriginFolder && !!folder.dummyFolder) return this.cloudFileService.readPersonalSharedOriginFolder(folder.dummyFolder.path, folder.path);
      return Promise.reject("Unable to read folder.");
    };

    let downloadDeleg = async (resource: Resource, data: any, sharedPath: string) => {
      
      if (resource instanceof SharedResource) {
        
        let size = (resource instanceof SharedFile) ? resource.originSizeInBytes 
        : await this.cloudFileService.getPersonalSharedFolderOriginSize(resource.originPath, sharedPath);
        return this.cloudFileService.downloadPersonalSharedFileAtRootAsync(
          resource.name,
          sharedPath,
          size
        );
        
      } else {
        
        let size = (resource instanceof OriginFile) ? resource.sizeInBytes 
        : await shareWithMeGetFoldersSizeDeleg(
          [resource],
          sharedPath
        );
        return this.cloudFileService
        .downloadPersonalSharedFileAsync(
          resource.name,
          resource.path,
          sharedPath,
          size
          );

      }
    }

    return new SharedWithMeDrive({
      checkReadAccessDeleg: () => this.cloudFileService.checkPersonalSharedFolder(),
      clearNotificationDeleg: () => {
        this.feedService.updateFeedStatusByKey(FeedState.SHARED_FEEDS_KEY);
      },
      uploadDeleg: (resource, data, parentPath) =>
        this.cloudFileService
          .streamPersonalShareFile(
            resource?.name ?? "",
            resource instanceof SharedFolder
            ? resource.originPath
            : resource?.path ?? "",
            parentPath,
            data?.files
          ),
      uploadFolderDeleg: (resource, data, parentPath) =>
        this.cloudFileService.streamPersonalShareFolder(
          resource?.name ?? "",
          resource instanceof SharedFolder
            ? resource.originPath
            : resource?.path ?? "",
          parentPath,
          data?.files,
          data?.size,
          data?.name
        ),
      uploadFromGoogleDeleg: (resource, data) =>
      this.cloudFileService
          .streamGDrivePersonalSharedFolder(
            resource.name,
            resource instanceof SharedFolder
            ? resource.originPath
            : resource?.path ?? "",
            resource instanceof SharedFolder
            ? resource.path
            : resource?.sharedDummyFolderPath ?? "",
            data?.googleData,
            data?.oauthToken
          ),
      getContactsDeleg: this._getContacts(orgId),
      deleteDeleg: (resource: SharedResource | OriginFile | OriginFolder, data, parentPath) => {
        if (resource instanceof SharedResource) {
          return this.cloudFileService
            .removePersonalSharedAccess(
              resource.originPath,
              parentPath,
              resource.isFile
            );
        } else {
        return this.cloudFileService
          .deletePersonalSharedItem(
            resource.path,
            parentPath,
          );
        }
      },
      enableUploadDeleg: (folder) =>
        folder.hasPermission(userId, AccessPermission.UploadFile, false, true),
      getProgressDeleg: () => this.cloudFileService.pendingPersonalUploads(),
      listDeleg: listDeleg,
      downloadDeleg: downloadDeleg,
      openFileDeleg: downloadDeleg,
      multiDownloadDeleg: async (resources, data, sharedPath) => {
        if (!resources) return;
        if (resources.length == 1 && resources[0].isFile) {
          let file = resources[0] as SharedFile;

          return this.cloudFileService
            .downloadPersonalSharedFileAsync(
              file.name,
              file.accessPath,
              sharedPath,
              file.originSizeInBytes,
            )
        }
        let filePaths: string[] = [];
        let folderPaths: string[] = [];
        let totalSize: number = 0;

        let folders = [];
        resources.forEach((d) => {
          if (d.isFile) {
            let file = d as SharedFile;
            filePaths.push(file.path);
            totalSize += file.sizeInBytes;
          } else {
            folderPaths.push(d.path);
            folders.push(d);
          }
        });

        if (folders.length > 0) {
          try {
            let size = await shareWithMeGetFoldersSizeDeleg(
              folders,
              sharedPath
            );
            totalSize += size;
            // console.log("download multi files, total size: " + totalSize);
          } catch (ex) {
            // console.log(
            //   "Error getting folder size, continuing without folder size: " + ex
            // );
          }
        }

        return this.cloudFileService
          .downloadMultiPersonalSharedFileAsync(
            filePaths,
            folderPaths,
            totalSize,
            sharedPath,
            folderPaths.length == 1 && filePaths.length == 0
              ? folders[0].name
              : ""
          );
      },
      multiDeleteDeleg: (resources, data, parentPath) => {
        if (resources == null) return;
        var foldersToDelete = resources
          .filter((f) => f.isFolder)
          .map((r) => r.path);
        var filesToDelete = resources
          .filter((f) => f.isFile)
          .map((r) => r.path);
        return this.cloudFileService
          .deleteMultiPersonalSharedItem(foldersToDelete, filesToDelete, parentPath);
      },
      moveDeleg: (resources, data, sharedPath) => {
        let listFoldersDeleg: ListFoldersDeleg = (resource) =>
          listDeleg(resource).then((res) => {
            return res.folders;
          });
        let moveDeleg: MoveResourcesDeleg = (resources, data, parentPath) => {
          var foldersToMove = resources
            .filter((f) => f.isFolder)
            .map((r) => r.path);
          var filesToMove = resources
            .filter((f) => f.isFile)
            .map((r) => r.path);
            var path =
              data && data.origin
                ? data.origin instanceof SharedResource
                  ? data.origin.originPath
                  : data.origin?.path
                : "";
            var destPath =
              data && data.dest
                ? data.dest instanceof SharedResource
                  ? data.dest.originPath
                  : data.dest?.path
                : "";
            var name = data && data.dest ? data.dest.name : "";

          return this.cloudFileService
            .movePersonalSharedItem(
              path,
              parentPath ?? sharedPath,
              destPath,
              name,
              filesToMove,
              foldersToMove
            );
        };
        let deleg = (folders: SharedFolder[]) =>
          folders.filter((f) => !f.isRoot);
        return {
          listFoldersDeleg,
          moveDeleg,
          movableParentFoldersDeleg: deleg,
        };
      },
      copyDeleg: (resources, data, sharedPath) => {
        var foldersToCopy = resources
          .filter((f) => f.isFolder)
          .map((r) => r.path);
        var filesToCopy = resources
          .filter((f) => f.isFile)
          .map((r) => r.path);
          var path =
          data && data.origin ?
          data.origin instanceof SharedResource
          ? data.origin.originPath
          : data.origin?.path 
          : "";
        var destPath =
          data && data.dest ? 
          data.dest instanceof SharedResource
          ? data.dest.originPath
          : data.dest?.path
          : "";
          var name = data && data.dest ? data.dest.name : "";

        return this.cloudFileService
          .copyPersonalSharedItem(
            path,
            sharedPath,
            destPath,
            name,
            filesToCopy,
            foldersToCopy
          );
      },
      renameDeleg: (resource, data, parentPath) =>
        this.cloudFileService
          .renamePersonalSharedItem(
            data?.name,
            resource.path,
            parentPath,
          ),
      getFolderSizeDeleg: shareWithMeGetFoldersSizeDeleg,
      newFolderDeleg: (resource, data, parentPath) =>
        this.cloudFileService
          .createPersonalShareFolder(
            data?.name ?? "",
            resource instanceof SharedFolder
            ? resource.originPath
            : resource?.path ?? "",
            parentPath
          ),
    });
  }

  //#region build picker drives for composers
  private _buildPersonalExplorerPicker(orgId: string, userId: string) {
    let myDrive = this._buildPersonalMyDrivePicker(orgId);
    let sharedWithMeDrive = this._buildPersonalSharedWithMeDrivePicker(orgId, userId);

    let explorer = new PersonalExplorer(
      orgId,
      myDrive,
      sharedWithMeDrive,
      null
    );

    return explorer;
  }

  private _buildOrgExplorerPicker(orgId: string, userId: string): OrgExplorer {
    let myDrive = this._buildMyDrivePicker(orgId);
    let sharedWithMeDrive = this._buildSharedWithMeDrivePicker(orgId, userId);
    let sharedDriveCollection = this._buildSharedDriveCollectionPicker(orgId, userId);


    let explorer = new OrgExplorer(
      orgId,
      myDrive,
      sharedWithMeDrive,
      sharedDriveCollection
    );

    return explorer;
  }

  private _buildPersonalMyDrivePicker(orgId: string) {
    let myDriveGetFoldersSizeDeleg = (resources: Resource[]) => {
      var folders = resources.filter((r) => !r.isFile);
      if (folders.length == 0) return Promise.resolve(0);

      var paths = folders.map((f) => f.path);
      return this.cloudFileService.getTotalPersonalFoldersSize(paths);
    };
    return new MyDrive({
      checkReadAccessDeleg: () => this.cloudFileService.checkPersonalFolder(),
      shareDeleg: null,
      getContactsDeleg: this._getContacts(orgId),
      enableUploadDeleg: () => false,
      getProgressDeleg: null,
      listDeleg: (resource) =>
        this.cloudFileService.readPersonalFolder(resource?.path),
      downloadDeleg: null,
      openFileDeleg: null,
      multiDownloadDeleg: null,
      multiDeleteDeleg: null,
      deleteDeleg: null,
      moveDeleg: null,
      copyDeleg: null,
      renameDeleg: null,
      uploadDeleg: null,
      uploadFolderDeleg: null,
      uploadFromGoogleDeleg: null,
      getFolderSizeDeleg: myDriveGetFoldersSizeDeleg,
      newFolderDeleg: null,
    });
  }

  private _buildSharedDriveCollectionPicker(
    orgId: string,
    userId: string
  ): SharedDriveCollection {
    let collection = new SharedDriveCollectionPicker({
      checkReadAccessDeleg: () => this.cloudFileService.checkSharedDrives(orgId),
      deleteDeleg: null,
      getContactsDeleg: this._getContacts(orgId),
      buildSharedDriveDeleg: (driveId, name, claims) =>
        this._buildSharedDrive(orgId, driveId, name, claims, userId),
      listDeleg: () => this.cloudFileService.listSharedDrives(orgId),
      renameDeleg: null,
      newFolderDeleg: null,
      shareDeleg: null,
    });
    return collection;
  }

  private _buildPersonalSharedWithMeDrivePicker(
    orgId: string,
    userId: string
  ): SharedWithMeDrive {
    let shareWithMeGetFoldersSizeDeleg = (
      resources: SharedResource[],
      parentPath: string
    ) => {
      var folders = resources.filter((r) => !r.isFile);
      if (folders.length == 0) return Promise.resolve(0);

      var paths = folders.map((f) => f.accessPath);
      return this.cloudFileService
        .getPersonalSharedFoldersSize(paths, parentPath);
    };
    let listDeleg = (folder) => {
      if (!folder || folder.isRoot) return this.cloudFileService.listPersonalSharedFolder();
      if (folder instanceof SharedFolder) return this.cloudFileService.readPersonalSharedFolder(folder.path);
      if (folder instanceof OriginFolder && !!folder.dummyFolder) return this.cloudFileService.readPersonalSharedOriginFolder(folder.dummyFolder.path, folder.path);
      return Promise.reject("Unable to read folder.");
    };
    return new SharedWithMeDrive({
      checkReadAccessDeleg: () => this.cloudFileService.checkPersonalSharedFolder(),
      clearNotificationDeleg: null,
      uploadDeleg: null,
      uploadFolderDeleg: null,
      uploadFromGoogleDeleg: null,
      getContactsDeleg: this._getContacts(orgId),
      deleteDeleg: null,
      enableUploadDeleg: () => false,
      getProgressDeleg: null,
      listDeleg: listDeleg,
      downloadDeleg: null,
      openFileDeleg: null,
      multiDownloadDeleg: null,
      multiDeleteDeleg: null,
      moveDeleg: null,
      copyDeleg: null,
      renameDeleg: null,
      getFolderSizeDeleg: shareWithMeGetFoldersSizeDeleg,
      newFolderDeleg: null,
    });
  }

  private _buildMyDrivePicker(orgId: string): MyDrive {
    let myDriveGetFoldersSizeDeleg = (resources: Resource[]) => {
      var folders = resources.filter((r) => !r.isFile);
      if (folders.length == 0) return Promise.resolve(0);

      var paths = folders.map((f) => f.path);
      return this.cloudFileService.getTotalUserFoldersSize(orgId, paths);
    };
    let readDeleg = (resource) =>
      this.cloudFileService
        .readUserFolder(orgId, resource?.path)
        .then((res) => {
          if (res.isRoot) {
            res.name = "My Drive";
            res.icon = "hard_drive";
          }
          return res;
        });
    return new MyDrivePicker({
      checkReadAccessDeleg: () => this.cloudFileService.checkUserFolder(orgId),
      shareDeleg: null,
      getContactsDeleg: this._getContacts(orgId),
      enableUploadDeleg: () => false,
      getProgressDeleg: () => this.cloudFileService.pendingUserUploads(orgId),
      listDeleg: readDeleg,
      downloadDeleg: null,
      openFileDeleg: null,
      multiDownloadDeleg: null,
      multiDeleteDeleg: null,
      deleteDeleg: null,
      moveDeleg: null,
      copyDeleg: null,
      renameDeleg: null,
      uploadDeleg: null,
      uploadFolderDeleg: null,
      uploadFromGoogleDeleg: null,
      getFolderSizeDeleg: myDriveGetFoldersSizeDeleg,
      newFolderDeleg: null,
    });
  }

  private _buildSharedWithMeDrivePicker(
    orgId: string,
    userId: string
  ): SharedWithMeDrivePicker {
    let shareWithMeGetFoldersSizeDeleg = (
      resources: Resource[],
      sharedPath: string
    ) => {
      var folders = resources.filter((r) => !r.isFile);
      if (folders.length == 0) return Promise.resolve(0);

      var paths = folders.map((f) => f.path);
      return this.cloudFileService
        .getUserSharedFoldersSize(orgId, paths, sharedPath);
    };

    let listDeleg = (folder: CloudFolder | SharedFolder, parentSharedPath?: string) => {
      if (!folder) return this.cloudFileService.listUserSharedFolder(orgId);
      if (folder instanceof SharedFolder) return this.cloudFileService.readUserSharedFolder(orgId, folder.path);
      if (folder instanceof CloudFolder) return this.cloudFileService.readUserSharedOriginFolder(orgId, parentSharedPath, folder.path);
      return Promise.reject("Unable to read folder.");
    };

    return new SharedWithMeDrivePicker({
      checkReadAccessDeleg: () => this.cloudFileService.checkUserSharedFolder(orgId),
      clearNotificationDeleg: null,
      uploadDeleg: null,
      uploadFolderDeleg: null,
      uploadFromGoogleDeleg: null,
      getContactsDeleg: this._getContacts(orgId),
      deleteDeleg: null,
      enableUploadDeleg: () => false,
      getProgressDeleg: null,
      listDeleg: listDeleg,
      downloadDeleg: null,
      openFileDeleg: null,
      multiDownloadDeleg: null,
      multiDeleteDeleg: null,
      moveDeleg: null,
      copyDeleg: null,
      renameDeleg: null,
      getFolderSizeDeleg: shareWithMeGetFoldersSizeDeleg,
      newFolderDeleg: null,
    });
  }
  //#endregion
}
