import { Store } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserProfileState } from "../model/user-profile.state";
import { UserDataState } from "./user-data.state";
import { ContactLabelState } from "../model/contact-label.state";
import { distinctUntilChanged, skip } from 'rxjs/operators';
import { Card } from '../model/card.model';

@Injectable({
  providedIn: "root"
})
export class UserDataSelector {
  constructor(private store: Store) { }

  get userProfile(): UserProfileState {
    const profile = this.store.selectSnapshot(UserDataState.userProfile);
    return { ...profile };
  }

  get userId() {
    const userId = this.store.selectSnapshot(UserDataState.userId);
    return userId;
  }

  get language() {
    const language = this.store.selectSnapshot(UserDataState.language);
    return language;
  }

  get timezone() {
    const timezone = this.store.selectSnapshot(UserDataState.timezone);
    return timezone;
  }

  get emailFrequency() {
    const freq = this.store.selectSnapshot(UserDataState.emailFrequency);
    return freq;
  }

  get matrixId() {
    const matrixId = this.store.selectSnapshot(UserDataState.matrixId);
    return matrixId;
  }

  get publicKey() {
    const publicKey = this.store.selectSnapshot(UserDataState.publicKey);
    return publicKey;
  }

  get cardExpiration() {
    const exp = this.store.selectSnapshot(UserDataState.cardExpiration);
    return exp;
  }

  getFcmToken() {
    const token = this.store.selectSnapshot(UserDataState.fcmToken);
    return token;
  }

  getContactLabels(): ContactLabelState[] {
    const labels = this.store.selectSnapshot(UserDataState.contactLabels);
    return [...labels];
  }

  getLabelsByContact(contactId: string): ContactLabelState[] {
    const labels = this.store.selectSnapshot(
      UserDataState.contactsLabel(contactId)
    );
    return [...labels];
  }

  contactLabel(labelId: string): ContactLabelState {
    const label = this.store.selectSnapshot(
      UserDataState.contactLabel(labelId)
    );
    return { ...label };
  }

  isCurrentUserId(userId: string): boolean {
    return userId === this.userId;
  }

  contactLabels(contactId: string): Observable<ContactLabelState[]> {
    return this.store.select(UserDataState.contactsLabel(contactId));
  }

  get hasTrial() {
    const hasTrial = this.store.selectSnapshot(UserDataState.hasTrial);
    return hasTrial;
  }

  get feedUnreads() {
    return this.store.selectSnapshot(UserDataState.feedUnreads);
  }

  get pendingFeedsToClear() {
    return this.store.selectSnapshot(UserDataState.pendingFeedClear);
  }

  getThreadUnreads(key: string): string[] {
    const unreads = this.feedUnreads;
    const ids = unreads.get(key);
    return ids && ids.length > 0 ? ids : [];
  }

  get isLoggedIn() {
    const bool = this.store.selectSnapshot(UserDataState.isLoggedIn);
    return bool;
  }

  getCards() {
    const cards = this.store.selectSnapshot<Card[]>(
      state => state.userData.cards
    );
    return [...cards];
  }

  getCard(cardId: string): Card | null {
    const card = this.getCards().find(r => r.cardId === cardId);
    return card;
  }

  onLoggedInChanged(): Observable<boolean> {
    return this.store.select(UserDataState.isLoggedIn).pipe(
      skip(1),
      distinctUntilChanged((prev, curr) => prev !== null && prev === curr)
    );
  }
}
