import { Observable } from "rxjs";
import { Store, createSelector } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { FileObjState } from "../model/file-obj.state";
import { FileState } from "./file.state";
import * as _ from "lodash";

@Injectable({
  providedIn: "root"
})
export class FileStateSelector {
  constructor(private store: Store) {}

  getFiles(): FileObjState[] {
    const files = this.store.selectSnapshot(FileState.files);
    return [...files];
  }

  getChannelFiles(channelId: string): FileObjState[] {
    const files = this.store.selectSnapshot(FileState.files);
    return files.filter(s => channelId.includes(s.chnlID));
  }

  getRoomFiles(roomId: string): FileObjState[] {
    const files = this.store.selectSnapshot(FileState.roomFiles(roomId));
    return [...files];
  }

  getFile(id: string): FileObjState {
    const files = this.store.selectSnapshot(FileState.files);
    return files.find(f => f.id === id);
  }

  getAudio(id: string): FileObjState {
    const files = this.store.selectSnapshot(FileState.audio);
    return files.find(f => f.id === id);
  }

  file(mediaId: string): Observable<FileObjState> {
    return this.store.select(FileState.file(mediaId));
  }

  files(mediaIds: string[]): Observable<FileObjState[]> {
    return this.store.select(
      createSelector([FileState.files], (states: FileObjState[]) =>
        _.cloneDeep(states.filter(s => mediaIds.includes(s.id)))
      )
    );
  }

  roomFiles(roomId: string): Observable<FileObjState[]> {
    return this.store.select(FileState.roomFiles(roomId));
  }

  commentFiles(postId: string): Observable<FileObjState[]> {
    return this.store.select(FileState.commentFiles(postId));
  }
}
