import { Component, OnInit, Input } from "@angular/core";
@Component({
  selector: "ui-global-loader",
  templateUrl: "./global-loader.component.html",
  styleUrls: ["./global-loader.component.scss"]
})
export class GlobalLoaderComponent implements OnInit {

  @Input() loadingText: string;
  constructor() {
  }
  ngOnInit() {
  }
}
