import { LocalStorageService } from './../localStorage/local-storage.service';
import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';
import { MessageType } from '../model/message.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class MeetService {
    constructor(private api: ApiService, private localDb: LocalStorageService) {
    }

    createMeet(roomId: string, tempId: string, hubConnectionId: string, content: string, isEncrypted: boolean = false): Promise<any> {
        return this.api.postAsync('meet', {
            content: content,
            roomId: roomId,
            tempId: tempId,
            hubConnectionId: hubConnectionId,
            type: MessageType.Meet,
            isEncrypted: isEncrypted
        }, this.localDb.getUserId())
            .then(res => {
                console.log(res);
                return Promise.resolve(res);
            }).catch(res => {
                console.log(res);
                return Promise.resolve(res.error);
            });
    }

    joinMeet(url: string): Promise<any> {
        return this.api.getUrl<any>(url).toPromise()
            .then(data => {
                console.log(data);
                return Promise.resolve(data);
            }).catch(res => {
                return Promise.reject(res);
            });
    }

    joinMeetWithoutUrl(room: string, jwt: string): Promise<any> {
        var url = environment.apiLink + "/meet/" + room + "?jwt=" + jwt;
        return this.joinMeet(url);
    }

    createOpenMeet(users: any, meetingTitle: string, orgId: string,
        calendarEnabled: boolean = false,
        startDate: Date = null,
        endDate: Date = null,
        selectedRecurringType: string = null): Promise<any> {
        return this.api.postAsync('meet/open', {
            users: users,
            meetingTitle: meetingTitle,
            orgId: orgId,
            calendarEnabled: calendarEnabled,
            startDate: startDate,
            endDate: endDate,
            recurrance: selectedRecurringType
        }, this.localDb.getUserId())
            .then(res => {
                console.log(res);
                return Promise.resolve(res);
            }).catch(res => {
                console.log(res);
                return Promise.resolve(res.error);
            });
    }


    // Helper method
    getHostName(url) {
        var hostname;
        // find & remove protocol (http, ftp, etc.) and get hostname

        if (url.indexOf("//") > -1) {
            hostname = url.split('/')[2];
        } else {
            hostname = url.split('/')[0];
        }

        // find & remove port number
        hostname = hostname.split(':')[0];
        // find & remove "?"
        hostname = hostname.split('?')[0];

        return hostname;
    }

    forceHttps(url) {
        return url.replace(/(^\w+:|^)\/\//, 'https://');
    }
}
