import { EnterpriseSelector } from './../states/enterprise.state.selector';
import { Emittable, Emitter } from '@ngxs-labs/emitter';
import { FileState } from './../states/file.state';
import { tap, concatMap, map } from "rxjs/operators";
import { FileObjState } from "./../model/file-obj.state";
import { AuthStateSelector } from "./../states/auth.state.selector";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { FileUploader, FileUploaderOptions, Headers } from "ng2-file-upload";
import { Subject, Observable, from } from "rxjs";
import { ApiService } from '../api/api.service';
import { UserDataSelector } from '../states/user-data.state.selector';
import { HttpEventType } from '@angular/common/http';
import { MessagingSelector } from '../states/messaging.state.selector';
import { DownloadService } from './download.service';
import { FilenameToMimeType } from '../util/filename-to-mimetype';

@Injectable()
export class MediaService {
  private _apiUrl = environment.apiLink;
  private _storageLink = environment.storageLink;
  private readonly HEADER_DOMAIN_IDENTITY: string = "EL-Domain-Identity";
  private readonly HEADER_RESOUCE_IDENTITY: string = "EL-Resource-Identity";
  private readonly _httpText: string = "http://";
  private readonly _httpsText: string = "https://";

  public readonly uploadLimit: number = 300; // 300 MB limit

  private _uploader: FileUploader;
  private _fileUploads: FileUpload[] = [];

  @Emitter(FileState.removeFile)
  removeFile: Emittable<string>;

  private blobObjCache: BlobObjCache[] = [];

  constructor(
    private authStateSelector: AuthStateSelector,
    private enterpriseSelector: EnterpriseSelector,
    private userDataSelector: UserDataSelector,
    private api: ApiService,
    private msgSelector: MessagingSelector,
    private downloadService: DownloadService
  ) {}

  isFileSizeWithinUploadLimit(size: number): boolean {
    return size <= 1024 * (1024 * this.uploadLimit);
  }

  onFileUploadProgress(fileId: string): Observable<number> {
    const upload = this._fileUploads.find(u => u.fileId == fileId);
    if (!upload) return null;
    return upload.onProgress;
  }

  uploadFile(
    endPoint: string,
    body: any,
    fileObj: FileObjState,
    resourceId?: string
  ): Observable<any> {
    return from(this.upload(endPoint, body, fileObj, resourceId)).pipe(
      concatMap(res => res.onComplete),
      tap(() => {
        //release obj url
        this.revokeObjURL(fileObj.url);
        //remove fileObj from fileState
        this.removeFile.emit(fileObj.id);
        //remove file upload
        let index = this._fileUploads.findIndex(u => u.fileId == fileObj.id);
        if (index !== -1) {
          this._fileUploads.splice(index);
        }
      })
    );
  }

  streamFile(endPoint: string, body: any, fileObj: FileObjState): Promise<any> {
    var fileoptions = {
      fileName: fileObj.name,
      fileKey: "mediaFile",
      chunkedMode: false,
      mimeType: fileObj.mimeType,
      reportProgress: true,
      observe: 'events'
    };

    return new Promise((resolve, reject) => {
      this.api.postAsyncFile(
        endPoint,
        body,
        this.userDataSelector.userId,
        fileoptions
      ).subscribe(event => {
        // progress
        if (event.type === HttpEventType.UploadProgress) {
          const percentage = (event.loaded / event.total) * 100;
          let file = this._fileUploads.find(u => u.fileId == fileObj.id);
          if (file) {
            file.onProgress.next(percentage);
          }
        }

        // finished
        if (event.type === HttpEventType.Response) {
          //release obj url
          this.revokeObjURL(fileObj.url);
          //remove fileObj from fileState
          this.removeFile.emit(fileObj.id);
          //remove file upload
          let index = this._fileUploads.findIndex(u => u.fileId == fileObj.id);
          if (index !== -1) {
            this._fileUploads.splice(index);
          }
          resolve(event.body);
        }
      }, (err) => reject(err));
    }).catch((err) => {
      return Promise.reject(err);
    });
  }

  initFileUploadProgress(fileId) {
    var fileUpload = new FileUpload(fileId);
    this._fileUploads.push(fileUpload);
  }

  getObjUrl(file: File): string {
    return window.URL.createObjectURL(file);
  }

  private revokeObjURL(url: string): void {
    //release obj url
    window.URL.revokeObjectURL(url);
  }

  convertObjUrlToFile(
    objectUrl: string,
    name: string,
    type: string
  ): Promise<File> {
    return fetch(objectUrl)
      .then(r => {
        return r.blob();
      })
      .then(blob => {
        return new File([blob], name, { type: type });
      })
      .catch(res => {
        return Promise.reject(res.error);
      });
  }

  private getUploader(): FileUploader {
    if (!this._uploader) {
      const uploader = new FileUploader({});
      uploader.onAfterAddingFile = item => {
        item.withCredentials = false;
        item.alias = "mediaFile";
      };

      this._uploader = uploader;
    }
    return this._uploader;
  }

  private upload<T>(
    endpoint: string,
    body: any,
    fileObj: FileObjState,
    resourceId?: string
  ): Promise<FileUpload> {
    try {
      var fileUpload = new FileUpload(fileObj.id);
      this._fileUploads.push(fileUpload);

      return this.convertObjUrlToFile(
        fileObj.url,
        fileObj.name,
        fileObj.mimeType
      ).then(file => {
        let uploader = this.getUploader();
        if (!uploader) return null;

        var options: FileUploaderOptions = {
          url: this._apiUrl + "/" + endpoint,
          method: "POST",
          authToken: "Bearer " + this.authStateSelector.accessToken,
          additionalParameter: body,
          removeAfterUpload: true,
          headers: this.buildJwtAuthHeader(resourceId)
        };
        uploader.setOptions(options);
        uploader.addToQueue([file]);

        var item = uploader.queue.find(
          q =>
            q._file &&
            q._file.name == file.name &&
            q._file.lastModified == file.lastModified
        );
        item.onProgress = (progress: number) => {
          console.log(`Uploading ${item.file.name}: ${progress}`);
          fileUpload.onProgress.next(progress);
        };

        item.onError = (response: any, status: any, headers: any) => {
          console.log(status);
          console.log(response);
          uploader.cancelItem(item);
          fileUpload.onComplete.error(response != null && response != "" ? JSON.parse(response) : "Error upload file");
          fileUpload.onProgress.next(null);
          fileUpload.onProgress.complete();
        };

        item.onSuccess = (response: any, status: any, headers: any) => {
          fileUpload.onComplete.next(JSON.parse(response));
          fileUpload.onProgress.complete();
        };

        uploader.uploadItem(item);

        return fileUpload;
      });
    } catch (err) {
      console.log(err);
    }
  }

  //#region image blob
  saveBlobFile(blobUrl: string, messageId: string) {
    console.log("[MediaService] Saving blob file to cache %s %s", blobUrl, messageId);
    var blobRef = new BlobObjCache(blobUrl, messageId);
    this.blobObjCache.push(blobRef);
  }

  async removeBlobUrl(messageId: string): Promise<boolean> {
    console.log("[MediaService] Removing blob url for message %s", messageId);
    var blobIndex = this.blobObjCache.findIndex(x => x.messageId === messageId);
    if (blobIndex != -1) {
      this.blobObjCache.splice(blobIndex, 1);
      return Promise.resolve(true);
    } else return Promise.resolve(false);
  }


  async getBlobUrl(messageId: string, msgType: ElMediaSource = ElMediaSource.Message): Promise<string> {
    // console.log("[MediaService] Getting blob url");
    return new Promise(async (resolve, reject) => {
      // check in cache
      var blobObjRef = this.blobObjCache.filter(x => x.messageId === messageId)[0];

      if (blobObjRef) {
        // console.log("[MediaService] Blob in cache " + blobObjRef.blobUrl);
        resolve(blobObjRef.blobUrl)
      } else {
        console.log("[MediaService] blob doesn't exist in cache. Downloading image ");
        // download image from service
        if (msgType === ElMediaSource.Message) {
          var message = this.msgSelector.getMessage(messageId)

          if (!message.mimeType) message.mimeType = this.getMimeTypeWithFileName(message.mediaName);
          if (message) {
            var url = this.prepareUrl(message.fwt);
            await this.downloadMedia(url, message.mimeType).then((blobUrl) => {
              this.saveBlobFile(blobUrl, messageId)
              return resolve(blobUrl);
            });
          }
        } else if (msgType === ElMediaSource.Card) {
          var card = this.userDataSelector.getCard(messageId);
          if (card) {
            if (!card.fwtEncoded) return resolve(card.mediaUrl);

            let url = this.prepareUrl(card.fwt);
            await this.downloadMedia(url, this.getMimeTypeWithFileName(card.mediaName)).then((blobUrl) => {
              this.saveBlobFile(blobUrl, messageId)
              return resolve(blobUrl);
            });
          }
        } else if (msgType === ElMediaSource.Post) {
          var post = this.enterpriseSelector.getPost(messageId);
          if (post) {
            var url = this.prepareUrl(post.fwt);
            await this.downloadMedia(url, this.getMimeTypeWithFileName(post.mediaName)).then((blobUrl) => {
              this.saveBlobFile(blobUrl, messageId)
              return resolve(blobUrl);
            });
          }
        } else if (msgType === ElMediaSource.PostComment) {
          var postComment = this.enterpriseSelector.getPostComment(messageId);
          if (postComment) {
            var url = this.prepareUrl(postComment.fwt);
            await this.downloadMedia(url, this.getMimeTypeWithFileName(postComment.mediaName)).then((blobUrl) => {
              this.saveBlobFile(blobUrl, messageId)
              return resolve(blobUrl);
            });
          }
        }
        reject("error getting url");

      }
    });
  }

  private prepareUrl(fwt) {
    return environment.apiLink + "/media/download?fwt=" + fwt + "&type=thumbnail";
  }

  private async downloadMedia(src, mimeType): Promise<any> {
    let fileBlob = await this.downloadService.downloadFile(src);
    return new Promise((resolve, reject) => {
      console.log(fileBlob)

      let blob = new Blob([fileBlob], { type: mimeType });
      let url = window.URL.createObjectURL(blob);

      resolve(url)
    })
  }
  //#endregion

  private buildJwtAuthHeader(resourceId?: string): Headers[] {
    let orgId: string = this.enterpriseSelector.getCurrentOrgId();

    let header: Headers[] = [];
    // header.push({ name: "Content-Type", value: "application/json" });
    // header.push({ name: "Accept", value: "application/json" });

    if (orgId) {
      header.push({ name: this.HEADER_DOMAIN_IDENTITY, value: orgId });
    }

    if (resourceId) {
      header.push({ name: this.HEADER_RESOUCE_IDENTITY, value: resourceId });
    }
    return header;
  }

  getMimeTypeWithFileName(fileName): string {
    return FilenameToMimeType.Convert(fileName);
  }

  public generateOrgImageUrl(orgId: string, imageUrl: string): string {
    if (!imageUrl) return null;
    if (!environment.publicStorageLink) return imageUrl;
    if (!this.shouldAddStorageLink(imageUrl)) return imageUrl;
    //if (imageUrl.includes(environment.publicStorageLink)) return imageUrl;

    return (
      environment.publicStorageLink +
      "avatars/orgs/" +
      orgId +
      "_logo_compressed?ts=" +
      imageUrl
    );
  }

  public generateOrgUserImageUrl(imageUrl: string): string {
    if (!imageUrl) return null;
    if (!environment.publicStorageLink) return imageUrl;
    if (!this.shouldAddStorageLink(imageUrl)) return imageUrl;

    return environment.publicStorageLink + "avatars/users/" + imageUrl;
  }

  public generateContactImageUrl(imageUrl: string): string {
    if (!imageUrl) return null;
    if (!environment.publicStorageLink) return imageUrl;
    if (!this.shouldAddStorageLink(imageUrl)) return imageUrl;

    return environment.publicStorageLink + "avatars/contacts/" + imageUrl;
  }

  //#region check url
  public shouldAddStorageLink(imageUrl: string): boolean {
    if (!imageUrl) return false;
    if (!environment.publicStorageLink) return false;
    if (imageUrl.includes(environment.publicStorageLink)) return false;
    //if image url is a link
    if (imageUrl.includes(this._httpText) || imageUrl.includes(this._httpsText)) return false;

    return true;
  }

  //#endregion
}

export class FileUpload {
  onComplete: Subject<any>;
  onProgress: Subject<number>;
  fileId: string;

  constructor(fileId: string) {
    this.onComplete = new Subject<any>();
    this.onProgress = new Subject<number>();
    this.fileId = fileId;
  }
}

export class BlobObjCache {
  blobUrl: string;
  messageId: string;
  constructor(blobUrl: string, messageId: string) {
    this.blobUrl = blobUrl;
    this.messageId = messageId;
  }
}

export enum ElMediaSource {
  Message = 1, Post = 2, PostComment = 3, Card = 4
}
