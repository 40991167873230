// this service handle Esign service security etc
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AbstractStateSelector } from "../../core/states/abstract.state.selector";
import { environment } from '../../../environments/environment';
import { PubSub } from '../../core/services/pubsub.service';
import { ToolsLocalStateSelector } from './tools-localstate.selector';
//import { SPALocalStateSelector } from '../../core/states/spa-local-state.selector';

// import { EventEmitter } from '@angular/core';
@Injectable()
export class ToolsAuthService {
  public baseurl = environment.apiEsignLink;
  KEY_ELTOKEN = 'ELACCESSTOKEN';
  KEY_ESign = 'ESIGNTT';
  USER_OU_NAME = 'UserOUName';
  private _org: BehaviorSubject<any> = new BehaviorSubject((null));
  public readonly cur_org: Observable<any> = this._org.asObservable();

  // 040420 - Menu PubSub refactoring - start
  selected_org: any;
  onOrgSwitchedSub: any;
  industryId: string;
  enabledMenus: string[] = [];
  // 040420 - Menu PubSub refactoring - end

  constructor(private http: HttpClient, public elstate: AbstractStateSelector,
    private pubSub: PubSub, public toolsLocalState: ToolsLocalStateSelector) {
    console.log('tool auth service constructor');
    console.log(elstate);
    // this.pubSubForELToolsMenuSecurity();
  }


  clearToolsCache() {
    // localStorage.removeItem(this.KEY_ESign);
    this.toolsLocalState.clearData();
  }
  isElToolsAuth(): boolean {
    // const auth = localStorage.getItem(this.esign_key);
    let res=this.toolsLocalState.getElToolsAccessToken();
    if(res){
      if(res.accessToken){
        console.log("found access token");
        if (res.tokenUserId === this.getUserID()) {
          console.log("user Matched");
          return true;
        } else {
          console.log("user unmatched");
        }
      }
    }
    return false;
  }

  getElToolsToken(): string {
    // return JSON.parse(localStorage.getItem(this.esign_key));
    // const v = localStorage.getItem(this.KEY_ESign);
    const v = this.toolsLocalState.getElToolsAccessToken();
    if (v) {
      return v.accessToken;
    } else {
      return null;
    }
  }
  getOrgUnitName(): string {
    // const v = localStorage.getItem(this.USER_OU_NAME);
    const v = this.elstate.getCurrentOrg();
    if (v) {
      return v.name;
    } else {
      return null;
    }
  }

  setElToolsToken(token: string,userId) {
    // console.log('setting esign token:', token);
    // localStorage.setItem(this.esign_key, JSON.stringify(token));
    // this.esignauth = token;
    // localStorage.setItem(this.KEY_ESign, token);
    this.toolsLocalState.setElToolsAccessToken(token,userId);
  }

  runElToolsAuth() {
    const url = this.baseurl + '/ELToolsAuth/';
    // console.log(this.getELOptions());
    console.log('get auth data');
    console.log(this.elstate.getAuthData());
    return this.http.post(url, { 'ElAccessToken': this.elstate.getAuthData().accessToken }, this.getELOptions());
  }
  getELOptions() {
    const authdata = this.elstate.getAuthData();
    console.log("tools auth data:-----");
    console.log(authdata);
    let header = new HttpHeaders();
    header = header.append('Content-Type', 'application/json');
    header = header.append('Accept', 'application/json');
    header = header.append('Authorization', 'Bearer ' + authdata.accessToken);
    //console.log(header);
    return { 'headers': header };
  }

  getELUploadOptions() {
    const authdata = this.elstate.getAuthData();
    let header = new HttpHeaders();
    header = header.append('Authorization', 'Bearer ' + authdata.accessToken);
    //console.log(header);
    return { 'headers': header };
  }
  getOrgUnitID(): string {
    console.log(this.elstate);
    return this.elstate.getCurrentOrg().id;
  }
  getUserID(): string {
    console.log(this.elstate);
    return this.elstate.getCurrentUser().userId;
  }
  getUserFirstName(): string {
    return this.elstate.getCurrentUser().firstName;
  }

  getUserLastName(): string {
    return this.elstate.getCurrentUser().lastName;
  }

  getUserEmail(): string {
    return this.elstate.getCurrentUser().email;
  }
  getUserRole(): string {
  //  console.log('get user role inside esign auth');
  //  console.log(this.elToolsState.getCurrentOrgUser());
    if (this.elstate.getCurrentOrgUser()) {
      return this.elstate.getCurrentOrgUser().role;
    }
  }

  getElToolsOptions() {
    const token: any = this.getElToolsToken();
    // console.log( token);
    let header = new HttpHeaders();
    header = header.append('Content-Type', 'application/json');
    header = header.append('Accept', 'application/json');
    header = header.append('Authorization', 'Bearer ' + token); // tools access token
    // console.log('el token:' + this.elstore.getAuth().accessToken);
    header = header.append('ElToken', this.elstate.getAuthData().accessToken); // app access token
    // console.log(header);
    // console.log('step 2.2');
    return { 'headers': header };
  }

  getElToolsOptionswithoutElToken() {
    const token: any = this.getElToolsToken();
    // console.log( token.esignAccessToken );
    let header = new HttpHeaders();
    header = header.append('Authorization', 'Bearer ' + token);
    // console.log(header);
    return { 'headers': header };
  }

  getToolsCommonOptions() {
    const token: any = this.getElToolsToken();
    let header = new HttpHeaders();
    header = header.append('Authorization', 'Bearer ' + token);
    header = header.append('ElToken', this.elstate.getAuthData().accessToken);
    return { 'headers': header };
  }
  

  getToolsUploadOptions() {
    const authdata: any = this.elstate.getAuthData();
    let header = new HttpHeaders();
    header = header.append('Authorization', 'Bearer ' + authdata.accessToken);
   // console.log(header);
    return { 'headers': header };
  }

  getToolsEDReproductiveEnvironment(tenant) {
    const urlstr = environment.apirpLink + "Form2/tenant/" + tenant + "/form/eggdonor";
    console.log(urlstr);
    return this.http.get(urlstr, this.getELOptions());
  }
  getToolsGCReproductiveEnvironment(tenant) {
    const urlstr = environment.apirpLink + "Form2/tenant/" + tenant + "/form/surrogate";
   return this.http.get(urlstr, this.getELOptions());
  }
}
