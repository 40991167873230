import { EnterpriseSelector } from './../../states/enterprise.state.selector';
import { UserMentions } from '../../model/user-mentions';

import { LocalStorageService } from "./../../localStorage/local-storage.service";
import { Component, OnInit, Input } from "@angular/core";

import { Router } from "@angular/router";

@Component({
  selector: "el-chat-mentions-others",
  templateUrl: "./chat-mentions-others.component.html",
  styleUrls: ["./chat-mentions-others.component.scss"]
})
export class ChatMentionsOthersComponent implements OnInit {
  @Input() user: UserMentions;
  @Input() isMsgBubble: boolean = false;

  constructor(private enterpriseSelector: EnterpriseSelector, private router: Router) {}

  ngOnInit() {}

  async goToContact() {
    if (!this.isMsgBubble) return;
    var contacts =  this.enterpriseSelector.getOrgMembership(this.enterpriseSelector.getCurrentOrgId(), this.user.id);
    if(contacts.userId == this.user.id){
      this.router.navigateByUrl(
        "/main/directory/connections/" + contacts.contactId
      );
    }
  }
}
