export class Convert {
  static toBase64String(inArray: Uint8Array): string {
    return btoa(String.fromCharCode.apply(null, inArray));
    //return String.fromCharCode(...new Uint8Array(inArray));
  }

  static bytesToAscii(bytes) {
    var result : number [] =[...Uint8Array.from(bytes)];
    result = result.filter(r=>r!=0);
    return String.fromCharCode(...result);
  }

  static fromBase64String(s: string): Uint8Array {
    // var data = [];
    // for (var i = 0; i < s.length; i++) {
    //   data.push(s.charCodeAt(i));
    // }
    // return new Uint8Array(Uint8Array.from(data).buffer);
    return new Uint8Array(
      atob(s)
        .split("")
        .map(function(c) {
          return c.charCodeAt(0);
        })
    );
  }
}
