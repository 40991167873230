import { Component, Input, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserDataSelector } from '../../../../core/states/user-data.state.selector';

@Component({
  selector: "app-dialog-welcome-new-account",
  templateUrl: "./dialog-welcome-new-account.component.html",
  styleUrls: ["./dialog-welcome-new-account.component.scss"],
})
export class DialogWelcomeNewAccountComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogWelcomeNewAccountComponent>,
  private userDataSelector: UserDataSelector) { }

  user: any;

  ngOnInit() {
    this.user = this.userId
  }

  createOrgDialog() {
    this.dialogRef.close({ open: "createOrg" });
  }

  inviteUserDialog() {
    this.dialogRef.close({ open: "invite" });
  }

  get userId(): string {
    return this.userDataSelector.userId;
  }
}
