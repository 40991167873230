import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { HistoryState } from "./history.state";

@Injectable({
  providedIn: "root"
})
export class HistoryStateSelector {
  constructor(private store: Store) { }

  get userLastLogin(): string {
    const lastLogin = this.store.selectSnapshot(HistoryState.userLastLogin);
    if (lastLogin) return lastLogin.orgId;
    else return null;
  }
}
