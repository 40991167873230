import { Component, ViewChild, ElementRef, AfterViewInit, TemplateRef, OnInit, ViewChildren } from '@angular/core';
import { MatMenu } from '@angular/material/menu';

@Component({
    selector: 'table-button-list',
    templateUrl: 'table-button-list.component.html',
    styleUrls: ['./table-button-list.component.scss']
})
export class TableButtonListComponent {
    buttonLimit = 3
}
