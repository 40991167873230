import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MessageType } from "../../../core/model/message.model";

@Component({
  selector: "app-chat-replyto",
  templateUrl: "./chat-replyto.component.html",
  styleUrls: ["./chat-replyto.component.scss"],
})
export class ChatReplyToComponent {
  @Input()
  type: MessageType;
  @Input()
  senderName: string;
  @Input()
  fileName: string;
  @Input()
  meetingTitle: string;
  @Input()
  sendTime: number;
  @Input()
  msgId: string;
  @Input()
  content: string;
  @Output()
  onClick = new EventEmitter<string>();

  //#region getter
  get isTextType(): boolean {
    return this.type === MessageType.Text;
  }
  get isFileType(): boolean {
    return this.type === MessageType.File;
  }
  get isMeetType(): boolean {
    return this.type === MessageType.Meet;
  }
  get isCalendarType(): boolean {
    return this.type === MessageType.Calendar;
  }
  get isImageType(): boolean {
    return this.type === MessageType.Image;
  }
  get isAudioType(): boolean {
    return this.type === MessageType.Audio;
  }
  //#endregion

  constructor() {}

  onAction() {
    this.onClick.emit(this.msgId);
  }
}
