import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { MessageState } from '../model/message.state';
import { Emitter, Emittable, Receiver } from '@ngxs-labs/emitter';
import { InMemMessageState } from './inmem.message.state';

@Injectable({
    providedIn: "root",
})
export class InMemMessageSelector {
    constructor(private store: Store) { }

    @Emitter(InMemMessageState.addOrUpdateMessages)
    public addOrUpdateMessages: Emittable<MessageState[]>;

    @Emitter(InMemMessageState.deleteMessage)
    public deleteMessage: Emittable<MessageState>;

    @Emitter(InMemMessageState.updateSendStatus)
    public updateSendStatus: Emittable<MessageState>;

    getAllMessages() {
        const messages = this.store.selectSnapshot(InMemMessageState.messages)
        if (!messages) return [];
        return [...messages];
    }

    getMessagesByRoomId(roomId: string) {
        const messages = this.getAllMessages();
        if (!messages) return [];
        return messages.filter((m) => m.roomId === roomId);
    }

    getMessagesByRoomMatrixId(roomMatrixId: string) {
        const messages = this.getAllMessages();
        if (!messages) return [];
        return messages.filter((m) => m.roomMatrixId === roomMatrixId);
    }

    getCursor(roomMatrixId: string) {
        const cursors = this.store.selectSnapshot(InMemMessageState.cursors);
        return cursors[roomMatrixId];
    }

    getBackwardCursor(roomMatrixId: string) {
        const cursors = this.store.selectSnapshot(InMemMessageState.cursors);
        return cursors[roomMatrixId] ? cursors[roomMatrixId].backward : null;
    }
}