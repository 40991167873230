import { EventModule, NotificationEventType } from '../enum/notification-event-type.enum';

export class Feed {
    id: string;
    creatorId: string;
    orgId: string;
    title: string;
    subTitle: string;
    body: string;
    module: string;
    event: string; // must not be enum for external feeds handling. use NotificationEventType constants
    timestamp: number;
    data: any;
    status: FeedStatus;
    batchId: string;
    isSystem: boolean = false;

    //will be obsolete in new feed implementation
    get unreadKey() {
        if (!this.data) return null;

        switch (this.event) {
            case NotificationEventType.NEW_POST:
                return this.data.channelId ? this.data.channelId : null;
            case NotificationEventType.NEW_COMMENT:
                return this.data.postId ? this.data.postId : null;
            case NotificationEventType.NEW_MESSAGE:
                return this.data.roomId ? this.data.roomId : null;
            default:
                return this.id;
        }
    }

    get isPost() {
        return this.event == NotificationEventType.NEW_POST;
    }

    get isComment() {
        return this.event == NotificationEventType.NEW_COMMENT;
    }

    get isAllMsgType() {
        return this.event == NotificationEventType.NEW_MESSAGE || NotificationEventType.NEW_MEET;
    }

    get isMeet() {
        return this.event == NotificationEventType.NEW_MEET;
    }

    get isMsg() {
        return this.event == NotificationEventType.NEW_MESSAGE;
    }

    get isJoinReqApproved() {
        return this.event == NotificationEventType.JOIN_REQ_APPROVED;
    }

    get isNewShared() {
        return this.event == NotificationEventType.SHARE_FOLDER || this.event == NotificationEventType.SHARE_FILE;
    }

    get isStorageOperation() {
        return this.module == EventModule.STORAGE 
        && !this.isNewShared 
        && this.event !== NotificationEventType.DELETE_FILE 
        && this.event !== NotificationEventType.DELETE_FOLDER;
    }
    
    get isJoinReqReceived() {
        return this.event == NotificationEventType.JOIN_REQ_RECEIVED;
    }
}

export enum FeedStatus {
    Unread = 0,
    Read = 1,
    Ignored = 9
}