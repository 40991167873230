import { Store } from "@ngxs/store";
import { UIState, UIStatus } from "./ui.state";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { skip, distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: "root",
})
export class UIStateSelector {
  constructor(private store: Store) { }

  get activeRoom() {
    const activeRoom = this.store.selectSnapshot(UIState.activeRoom);
    return activeRoom;
  }

  get activeChannel() {
    const activeChannel = this.store.selectSnapshot(UIState.activeChannel);
    return activeChannel;
  }

  get isRedirectOnSwitchOrg() {
    const bool = this.store.selectSnapshot(UIState.isRedirectOnSwitchOrg);
    return bool;
  }

  get isContactReady() {
    const isReady = this.store.selectSnapshot(UIState.isContactReady);
    return isReady;
  }

  get isAssignmentsReady() {
    const isReady = this.store.selectSnapshot(UIState.isAssignmentsReady);
    return isReady;
  }

  getAreChannelPostsReady(channelId: string): boolean {
    const channels = this.store.selectSnapshot(UIState.readyChannels);
    return channels.includes(channelId);
  }

  getStatus() {
    const status = this.store.selectSnapshot(UIState.status);
    return status;
  }


  onUIStatusChanged(): Observable<UIStatus> {
    return this.store.select(UIState.status).pipe(
      skip(1),
      distinctUntilChanged((prev, curr) => prev !== null && prev === curr)
    );
  }
}
