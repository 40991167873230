import { RoleEntity } from './../../core/model/userRole.model';
import { LicenseState } from './../../core/states/license.state';
import { OrgState, OrgUserState } from "./../../core/model/org.state";
import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { routerAnimation } from "../../utils/page.animation";
import { Router } from "@angular/router";
import { pocolog } from "pocolog";
import { CardState } from "../../core/model/card.state";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { UserDataState } from "../../core/states/user-data.state";
import { distinctUntilChanged } from "rxjs/operators";
import { SystemHub } from "../../core/hub/system.hub";
import { EnterpriseState } from "../../core/states/enterprise.state";
import { SubSink } from "subsink";
import { OrgType } from '../../core/enum/org-type';
import { EnterpriseSelector } from '../../core/states/enterprise.state.selector';
import { OrgUserComponent } from '../../organization/user/user.component';
import { FriendsComponent } from '../../friends/friends.component';
import { SubscriptionState, SubStatus } from '../../core/model/subscription.state';
import { LicenseStateSelector } from '../../core/states/license.state.selector';
import { MatDialog } from '@angular/material/dialog';
import { OrgHub } from '../../core/hub/org.hub';
import { FlowManager } from '../../core/workflow';
import { LoginHistoryState } from '../../core/model/login-history.state';
import { UserDataSelector } from '../../core/states/user-data.state.selector';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { HistoryState } from '../../core/states/history.state';
import { UIService } from '../../core/services/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogUpdatePaymentComponent } from '../../organization/subscription/dialog/dialog-update-payment.component';
import { EntityOrgStatus } from '../../core/enum/entity-status.enum';
import { UserStatus } from '../../core/enum/user-status.enum';
import { PubSub } from '../../core/services/pubsub.service';
import { Feed, FeedStatus } from '../../core/model/feed';
import { Dictionary } from '../../core/util/dictionary';
import { ActivityDisplay } from '../../core/model/activity.state';
import { NotificationEventType } from '../../core/enum/notification-event-type.enum';
import * as _ from "lodash";
import { FeedState } from '../../core/states/feed.state';

enum DashboardState {
  Active,
  Pending,
  Inactive,
  PaymentFailed,
  SubscriptionFailed,
  Unknown
}
@Component({
  selector: "app-dashboard-page",
  templateUrl: "./dashboard-page.component.html",
  styleUrls: ["./dashboard-page.component.scss"],
  animations: [routerAnimation],
})
export class DashboardPageComponent implements OnInit, OnDestroy {

  @Emitter(HistoryState.save)
  saveLoginHistory: Emittable<LoginHistoryState>;

  // Add router animation
  @HostBinding("@routerAnimation") routerAnimation = true;
  sideNavOpened = false;
  private _currentOrg: OrgState;
  private _currentOrgUser: OrgUserState;
  private _currentOrgSub: SubscriptionState;
  dashboardState: DashboardState = DashboardState.Unknown;

  currentOrg: OrgState;
  ouName: any;

  // card data
  cardTable = [];

  isCardLoading = true;
  isFeedLoading = true;
  isLoading = false;

  cards: CardState[] = [];

  activityDisplay: ActivityDisplay[] = [];

  private _subSink: SubSink;

  @Select(LicenseState.currentOrgSubscription)
  currentOrgSubscription$: Observable<SubscriptionState>;

  @Select(UserDataState.cards)
  cards$: Observable<CardState[]>;

  @Select(FeedState.recentFeeds)
  feeds$: Observable<Feed[]>;

  @Select(EnterpriseState.selectedOrg)
  selectedOrg$: Observable<OrgState>;

  @Select(EnterpriseState.currentOrgUser)
  currentOrgUser$: Observable<OrgUserState>;

  get isDashboardActive(): boolean {
    if (this.dashboardState === DashboardState.Active)
      return true;
    return false;
  }

  get isDashboardPending(): boolean {
    if (this.dashboardState === DashboardState.Pending)
      return true;
    return false;
  }

  get isDashboardInactive(): boolean {
    if (this.dashboardState === DashboardState.Inactive)
      return true;
    return false;
  }

  get isDashboardPaymentFailed(): boolean {
    if (this.dashboardState === DashboardState.PaymentFailed)
      return true;
    return false;
  }

  get isOrgUserSuspendedSubReason(): boolean {
    if (this.dashboardState === DashboardState.SubscriptionFailed) return true;
    return false;
  }

  get isBusinessOrg(): boolean {
    if (!this.currentOrg) return false;

    return this.currentOrg.type === OrgType.Business;
  }

  get isDashboardReady(): boolean {
    return !this.isCardLoading && !this.isFeedLoading;
  }

  constructor(
    private systemHub: SystemHub,
    private enterpriseSelector: EnterpriseSelector,
    private router: Router,
    private orgUserComponent: OrgUserComponent,
    private friendsComponent: FriendsComponent,
    private licenseSelector: LicenseStateSelector,
    public dialog: MatDialog,
    public orgHub: OrgHub,
    private flowManager: FlowManager,
    private userSelector: UserDataSelector,
    private uiService: UIService,
    private translateService: TranslateService,
    private pubSub: PubSub,
  ) {
    this._subSink = new SubSink();

    // dashboard is main suspended page. Show suspended message if user is suspended

  }

  ngOnInit(): void {
    this.currentOrg = this.enterpriseSelector.getCurrentOrg();
    this._currentOrgUser = this.enterpriseSelector.getCurrentOrgUser();
    if (this.currentOrg) {
      this._currentOrgSub = this.licenseSelector.getOrgSubscription(this.currentOrg.id);
      this.updateDashboardState();
    }

    this._subscribeOrg();
  }

  ngOnDestroy(): void {
    if (this._subSink) this._subSink.unsubscribe();
  }

  //#region Subscription
  private _subscribeOrg() {
    this._subSink.sink = this.selectedOrg$.subscribe((res) => {
      this.currentOrg = res;
      if (this.currentOrg) {
        this.cards$
          .pipe(
            distinctUntilChanged((prev, curr) => prev.length === curr.length)
          )
          .subscribe((cards) => {
            if (this.currentOrg) {
              if (this.currentOrg.type === OrgType.Personal) {
                this.cards = cards.filter(x => this.enterpriseSelector.isSameOrg(this.currentOrg.id, x.orgId) || x.isSystem)
              } else {
                this.cards = cards.filter(x => x.orgId === this.currentOrg.id || x.isSystem)
              }
              this.isCardLoading = false;
            }
          });
        
        this.feeds$
          .subscribe((feeds) => {
            this._buildActivityList(feeds);
            this.isFeedLoading = false;
          })

        // set dashboard state
        this._subSink.sink = this.currentOrgSubscription$.pipe(
          distinctUntilChanged((prev, curr) => {
            if (curr == null) return true;
            if (prev == null && curr != null) return false;
            return prev.id === curr.id && prev.status === curr.status && prev.type === curr.type;
          })
        ).subscribe((sub) => {
          if (sub) {
            if (sub.status == SubStatus.PaymentFailed) {
              this._currentOrgSub = sub;
              this.updateDashboardState();
            } else {
              this._currentOrgSub = sub;
              this.updateDashboardState();
            }
          }
        });
      }
    });

    this._subSink.sink = this.selectedOrg$.pipe(
      distinctUntilChanged((prev, curr) => {
        if (curr == null) return true;
        if (prev == null && curr != null) return false;
        return prev.id === curr.id && prev.name === curr.name && prev.status === curr.status
      })
    ).subscribe(async (res) => {
      if (!res) {
        this._currentOrg = null;
      } else {
        this._currentOrg = res;
      }
      this.updateDashboardState();
    });

    this._subSink.sink = this.currentOrgUser$.subscribe(
      (orgUser: OrgUserState) => {
        if (orgUser != null) {
          this._currentOrgUser = orgUser;
          this.updateDashboardState();
        }
      }
    );
  }

  //#end region

  openAddUserDialog() {
    // console.log("adduser")
    this.router.navigateByUrl('/main/org/user').then(res => {
      this.orgUserComponent.ngOnInit();
      this.orgUserComponent.invite();
    });
  }

  openAddFriendDialog() {
    // console.log("addfrien")
    this.router.navigateByUrl('/main/friends').then(res => {
      this.friendsComponent.ngOnInit();
      this.friendsComponent.addUser();
    });
  }

  switchToPersonalSpace() {
    var personalOrg = this.enterpriseSelector.getPersonalOrg();
    this.flowManager.switchOrg(personalOrg.id).catch(() => {
      this.uiService.openSnackBar("Failed to prepare org", "OK");
    });

    this.router.navigateByUrl("/main/dashboard");

    // save login history
    let history: LoginHistoryState = { userId: this.userSelector.userId, orgId: personalOrg.id }
    this.saveLoginHistory.emit(history);
  }

  goToSubscriptionPage() {
    this.router.navigateByUrl("main/org/subscription");
  }

  abortOrg() {
    this.isLoading = true;
    this.orgHub.deleteOrg(this.currentOrg.id).subscribe(() => {
      this.isLoading = false;
      this.flowManager.switchOrg(null)
        .then(res => {
          this.router.navigateByUrl("/");
          this.uiService.openSnackBar("Creating organization aborted",
            this.translateService.instant('REPEAT.OK'));
        }).catch(err => {
          console.error(err);
          this.uiService.openSnackBar(this.translateService.instant('ORGANIZATION.SETTING.FAIL_SWITCH_ORG'),
            this.translateService.instant('REPEAT.OK'));
        });
    })
  }

  updatePayment() {
    const dialogRef = this.dialog.open(DialogUpdatePaymentComponent, {
      width: "500px"
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        if (res.status === "failed") {
          // error
          this.uiService.openSnackBar(res.response, "OK");
        }
      }
    });
  }

  goToActivityPage() {
    this.router.navigate(['/main/activity']);
  }

  dismissEvent(cardId: string) {
    this.systemHub.dismissCard(cardId).subscribe(
      (res) => {
        if (res) {
          pocolog.info("Card dismissed from server");
        }
      },
      (err) => {
        pocolog.error(err);
      }
    );
  }

  private updateDashboardState() {
    if (this._currentOrg) {
      switch (this._currentOrg.status) {
        case EntityOrgStatus.Active:
          var subState = this._currentOrgSub.status;
          if (subState == SubStatus.PaymentFailed) {
            //payment fail when charge invoice
            if (this._currentOrgUser.role == RoleEntity[RoleEntity.OWNER]) {
              this.dashboardState = DashboardState.SubscriptionFailed;
            } else {
              this.dashboardState = DashboardState.Inactive;
            }
            break;
          }
          if (this._currentOrgUser) {
            if (
              this._currentOrgUser.status == UserStatus.SubscriptionLimitExceed
            ) {
              if (this._currentOrgUser.role == RoleEntity[RoleEntity.OWNER]) {
                this.dashboardState = DashboardState.SubscriptionFailed;
              } else {
                this.dashboardState = DashboardState.Inactive;
              }
              break;
            }
          }
          this.dashboardState = DashboardState.Active;
          break;
        case EntityOrgStatus.Inactive:
        case EntityOrgStatus.Suspended:
          this.dashboardState = DashboardState.Inactive;
          break;
        case EntityOrgStatus.Pending:
          var subState = this._currentOrgSub.status;
          if (subState == SubStatus.PaymentFailed) {//payment fail when create org
            this.dashboardState = DashboardState.PaymentFailed;
          } else {
            this.dashboardState = DashboardState.Pending;
          }
          break;
      }
    }
    else { this.dashboardState = DashboardState.Unknown }
  }

  //#region Feed/Recent Activity
  private _buildActivityList(fds: Feed[]) {
    this.activityDisplay = [];
    if (!fds) return;

    this.activityDisplay = ActivityDisplay.buildActivity(fds);

    // let dict = this._groupActivities(orgFeeds);

    // for (var key in dict) {
    //   let feeds = dict[key];
    //   //this._sortFeeds(feeds);

    //   this.activityDisplay = ActivityDisplay.buildActivity(feeds);
    // }
    
  }

  // private _groupActivities(feeds: Feed[]) {
  //   const personalOrg = this.enterpriseSelector.getPersonalOrg();

  //   let dict: Dictionary<Feed[]> = {};

  //   feeds.forEach((feed: Feed) => {
  //     let res = this.enterpriseSelector.isSameOrg(feed.orgId, personalOrg.id);
  //     if (res == true) {
  //       if (dict[personalOrg.id]) {
  //         dict[personalOrg.id] = [...dict[personalOrg.id], feed];
  //       } else {
  //         dict[personalOrg.id] = [feed];
  //       }
  //     } else {
  //       if (dict[feed.orgId]) {
  //         dict[feed.orgId] = [...dict[feed.orgId], feed];
  //       } else {
  //         dict[feed.orgId] = [feed];
  //       }
  //     }
  //   });

  //   return dict;
  // }

  //#end region

  
}