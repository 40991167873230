import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-dialog-multi-tab',
  templateUrl: './dialog-multi-tab.component.html',
  styleUrls: ['./dialog-multi-tab.component.scss']
})
export class DialogMultiTabComponent implements OnInit, OnDestroy {
  isMeetTab = false;
  _subSink = new SubSink();
  constructor(public dialogRef: MatDialogRef<DialogMultiTabComponent>, private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {
    this._subSink.sink = this.activatedRoute.queryParams.subscribe(qParams => {
      let mode = qParams["mode"];
      if (mode === "meet") {
        this.isMeetTab = true;
      }
    });
  }

  useThisTab() {
    if (this.isMeetTab) {
      this.dialogRef.close('use-meet-tab');
    } else {
      this.dialogRef.close('use-tab');
    }
  }

  redirectToMeet() {
    // this.dialogRef.close('use-here');
    // this.router.navigateByUrl("/meet");
  }

  close() {
    window.location.href = "http://www.everleagues.com";
  }

  ngOnDestroy() {
    if (this._subSink) this._subSink.unsubscribe();
  }
}
