import { OrgService } from "./../services/org.service";
import { SystemHub } from "./../hub/system.hub";
import { FcpService } from "./../fcp/fcp.service";
import { RoomHub } from "./../hub/room.hub";
import { OrgHub } from "./../hub/org.hub";
import {
  CancellationToken,
  EndPointFlow,
  FcpSetupFlow,
  OrgHandshakeFlow,
  RoomHandshakeFlow,
  SystemHandshakeFlow,
  Flow,
  FirstInputFlow,
} from ".";
import { Emitter, Emittable } from "@ngxs-labs/emitter";
import { OrgState } from "../model/org.state";
import { EnterpriseState } from "../states/enterprise.state";
import { EnterpriseSelector } from "../states/enterprise.state.selector";
import { HistoryStateSelector } from "../states/history.state.selector";
import { PubSub } from "../services/pubsub.service";
import { GetContactFlow } from "./get-contacts.flow";
import { GetFeedsFlow } from "./get-feeds.flow";

export class MainFlow extends Flow {
  constructor(
    private orgHub: OrgHub,
    private roomHub: RoomHub,
    private fcpService: FcpService,
    private systemHub: SystemHub,
    private orgService: OrgService,
    private enterpriseSelector: EnterpriseSelector,
    private historySelector: HistoryStateSelector,
    private pubSub: PubSub
  ) {
    super();
  }
  public sketch(): Flow {
    let getOrgListFlow = new GetOrgListFlow(this.orgHub, this.orgService);
    let prepareCurrentOrgFlow = new PrepareCurrentOrgFlow(
      this.orgHub,
      this.roomHub,
      this.systemHub,
      this.enterpriseSelector,
      this.historySelector,
      this.pubSub
    );
    let fcpSetupFlow = new FcpSetupFlow(this.fcpService);
    let systemHandshakeFlow = new SystemHandshakeFlow(this.systemHub);

    return super.sketch(
      [
        getOrgListFlow,
        prepareCurrentOrgFlow,
        fcpSetupFlow,
        systemHandshakeFlow,
      ],
      new EndPointFlow()
    );
  }
}

export class GetOrgListFlow extends Flow {
  constructor(private orgHub: OrgHub, private orgService: OrgService) {
    super();
  }

  public async execute(
    input: any = null,
    token: CancellationToken = null
  ): Promise<OrgState[]> {
    var data = await this.orgService.getOrgs();
    if (token != null && token.isCancelled) return null;

    var orgList = await this.orgHub.updateGetOrgs(data);
    this.output = orgList;
    return await super.execute(orgList, token);
  }
}

export class PrepareCurrentOrgFlow extends Flow {
  @Emitter(EnterpriseState.switchOrg)
  switchOrg: Emittable<string>;

  constructor(
    private orgHub: OrgHub,
    private roomHub: RoomHub,
    private sysHub: SystemHub,
    private enterpriseSelector: EnterpriseSelector,
    private historySelector: HistoryStateSelector,
    private pubSub: PubSub
  ) {
    super();
  }

  public sketch(): Flow {
    let f1 = new OrgHandshakeFlow(this.orgHub, this.enterpriseSelector, this.pubSub);
    let f2 = new RoomHandshakeFlow(this.roomHub);
    let f3 = new GetFeedsFlow(this.sysHub);
    //let f3 = new GetContactFlow(this.orgHub, this.enterpriseSelector);

    return super.sketch([f1, f2], new FirstInputFlow(), f3);
  }

  public async execute(token: CancellationToken = null): Promise<any> {
    // console.log("[PrepareCurrentOrgFlow] PrepareCurrentOrgFlow Started");

    var orgId = this.enterpriseSelector.getCurrentOrgId();
    if (!orgId) {
      orgId = this.historySelector.userLastLogin;
    }

    await super.execute(orgId, token);

    if (
      this.output != orgId ||
      !this.enterpriseSelector.isCurrentOrg(this.output)
    ) {
      await this.switchOrg.emit(this.output).toPromise();
    }

    // console.log("[PrepareCurrentOrgFlow] produced result: %o", this.output);
    return this.output;
  }
}
