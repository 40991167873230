import { ExplorerService } from './services/explorer.service';
import { UnreadsService } from './services/unreads.service';
import { MembershipReqService } from './services/membership-req.service';
import { CardService } from './services/card.service';
import { MeetService } from './services/meet.service';
import { OUService } from './services/ou.service';
import { UserService } from './services/user.service';
import { TeamService } from './services/team.service';
import { ContactService } from './services/contact.service';
import { InvitationService } from './services/invitation.service';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { LocalStorageService } from './localStorage/local-storage.service';
import { ApiService } from './api/api.service';
import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalErrorHandler } from './error-handlers/global.error.handler';
import { MessagingService } from './services/firebase-messaging.service';
import { DownloadService } from './services/download.service';
import { PermissionService } from './services/permission.service';
import { MediaService } from './services/media.service';
import { ValidatorService } from './services/validator.service';
import { FeedService } from './services/feed.service';
import { GooglePickerService } from './services/googlePicker.service';
// import { HttpErrorInterceptor } from './error-handlers/http-error.interceptor';

import { HttpTokenInterceptor } from './auth/token-interceptor'
import { IdleService } from './services/idle.service';
import { RouteBlockGuard } from './ui/route-block.guard';

import { IconsModule } from 'app/core/icons/icons.module';
import { TranslocoCoreModule } from 'app/core/transloco/transloco.module';
import { FileServerService } from './api/file-server.service';

@NgModule({
  imports: [
    CommonModule,
    IconsModule,
    TranslocoCoreModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiService,
    FileServerService,
    LocalStorageService,
    AuthService,
    CardService,
    MeetService,
    OUService,
    UserService,
    TeamService,
    ContactService,
    InvitationService,
    GooglePickerService,
    AuthGuard,
    RouteBlockGuard,
    MessagingService,
    DownloadService,
    PermissionService,
    MediaService,
    ExplorerService,
    ValidatorService,
    MembershipReqService,
    FeedService,
    UnreadsService,
    DatePipe,
    IdleService
    ,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {

  }
}
