import { Injectable } from '@angular/core';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthStateSelector } from '../states/auth.state.selector';
import { Observable } from 'rxjs';

@Injectable()
export class DownloadService {
    constructor(public localStorage: LocalStorageService, public http: HttpClient, private authselector: AuthStateSelector) { }

    downloadFile(src: string, isBlob: boolean = true): Promise<any> {
        let token: any = this.authselector.accessToken;
        const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
        if (isBlob) {
            return this.http.get(src, { headers, responseType: 'blob' }).toPromise();
        } else {
            return this.http.get(src, { headers, responseType: 'arraybuffer' }).toPromise();
        }
    }

    downloadFileWithProgress(src: string, isBlob: boolean = true): Observable<any> {
        let token: any = this.authselector.accessToken;
        const headers = new HttpHeaders({ 'Authorization': `Bearer ${token}` });
        if (isBlob) {
            return this.http.get(src, { headers, responseType: 'blob', observe: "events", reportProgress: true });
        } else {
            return this.http.get(src, { headers, responseType: 'arraybuffer', observe: "events", reportProgress: true });
        }
    }
}
