import { PipeTransform, Pipe } from '@angular/core';
import { DatePipe } from '@angular/common';

/*
 * Shows date as Today, Yesterday or locale date
*/
@Pipe({ name: 'DateTime' })
export class DateTimePipe implements PipeTransform {
  constructor(private datepipe: DatePipe) { }
  transform(timeStamp: number, refreshValue?: any): string {
    // nullcheck
    if (!timeStamp) {
      return null;
    }

    let givenTime = new Date(timeStamp * 1000);
    var formattedTime = givenTime.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    // Get today's date
    var todaysDate = new Date();   
    // call setHours to take the time out of the comparison
    //if same day, return in time format
    if (givenTime.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
      return formattedTime;
    }

    var year = givenTime.getFullYear();
    var currentYear = todaysDate.getFullYear();
    //if same year, return in date format without year
    if (year == currentYear) {
      return this.datepipe.transform(givenTime, 'M/d');
    }

    // default return
    return this.datepipe.transform(givenTime, 'M/d/yyyy');
  }

}

