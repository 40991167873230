import { State, StateContext, Selector, Action } from "@ngxs/store";
import { ImmutableContext } from "@ngxs-labs/immer-adapter";
import { Receiver, EmitterAction } from "@ngxs-labs/emitter";
import { AuthDataState } from "../model/authData.state";
import { AppState } from './app.state';
import { Injectable } from '@angular/core';
import * as _ from "lodash";

export class AuthStateModel {
  accessToken: string;
  expiredIn: number;
  userName: string;
  isRefreshingToken: boolean; // prevent token refresh called multiple times
  hasInitToken: boolean; // true if refresh token is completed on app start

  constructor() {
    this.accessToken = "";
    this.expiredIn = -1;
    this.userName = "";
    this.isRefreshingToken = false;
    this.hasInitToken = false;
  }
}
@State<AuthStateModel>({
  name: "AuthState",
  defaults: new AuthStateModel()
})
@Injectable()
export class AuthState {
  constructor(private appState: AppState) {

  }
  ngxsAfterBootstrap(ctx: StateContext<AuthStateModel>) {
    this.appState.reportReady("AuthState");
    console.log("[AuthState] - ngxsAfterBootstrap");
  }

  @Selector()
  static accessToken(state: AuthStateModel): string | null {
    return state.accessToken;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    return !!state.accessToken;
  }

  @Selector()
  static data(state: AuthStateModel): AuthDataState | null {
    return _.cloneDeep(state);
  }

  @Selector()
  static isRefreshingToken(state: AuthStateModel): boolean {
    return state.isRefreshingToken;
  }

  @Selector()
  static hasInitToken(state: AuthStateModel): boolean {
    return state.hasInitToken;
  }

  @Receiver()
  //@ImmutableContext()
  static saveToken(
    ctx: StateContext<AuthStateModel>,
    arg: EmitterAction<string>
  ) {
    if (!arg || !arg.payload) return;
    const state = ctx.getState();
    let auth = { ...state };
    auth.accessToken = arg.payload;

    ctx.setState({ ...auth });
  }

  @Receiver()
  //@ImmutableContext()
  static save(
    ctx: StateContext<AuthStateModel>,
    arg: EmitterAction<AuthDataState>
  ) {
    if (!arg || !arg.payload) return;
    const state = ctx.getState();
    let auth = { ...state };
    auth.accessToken = arg.payload.accessToken;
    auth.expiredIn = arg.payload.expiredIn;
    auth.userName = arg.payload.userName;

    ctx.setState({ ...auth });
  }

  @Receiver()
  @ImmutableContext()
  static setIsRefreshingToken(
    ctx: StateContext<AuthStateModel>,
    arg: EmitterAction<boolean>
  ) {
    const state = ctx.getState();
    state.isRefreshingToken = arg.payload;
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  static setHasInitToken(
    ctx: StateContext<AuthStateModel>,
    arg: EmitterAction<boolean>
  ) {
    if (!arg) return;
    const state = ctx.getState();
    if (state.hasInitToken != arg.payload) {
      state.hasInitToken = arg.payload;
      ctx.setState(state);
    }
  }


  /**
   * Warning: Do not call this method to logout.
  * To perform logout, call AuthService.logout() to clear FCM token before logout
  */
  @Receiver()
  static clean(ctx: StateContext<AuthStateModel>) {
    ctx.setState({ ...new AuthStateModel() });
  }
}
