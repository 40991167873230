import {
  Directive,
  ElementRef,
  OnInit,
  OnDestroy,
  Renderer2,
  AfterViewInit,
  Input,
  ChangeDetectorRef,
  ViewChild
} from "@angular/core";
import { MatButton } from '@angular/material/button';
import { MatMenu, MatMenuItem } from '@angular/material/menu';

/* This directive creates a menu list if the number of children in this element exceeds the limit
   Use attribute action-menu-desc on the children to give a description to the action

  Mat-menu should contain the same buttons. Refer to table-button-list.component
   */
@Directive({
  selector: "[el-action-menu]"
})

export class ActionMenuDirective implements OnInit, OnDestroy, AfterViewInit {
  @Input() buttonLimit = 0; // how many buttons to show before collapsing to a list. list button is considered 1 button
  @Input() matMenuButton: MatButton;
  @Input() matMenu: MatMenu; // mat-menu
  @Input() matMenuItems: ElementRef
  el: ElementRef;
  constructor(el: ElementRef, private renderer: Renderer2) {
    this.el = el;
  }

  ngOnInit() {


  }
  ngAfterViewInit() {
    let elem = this.el.nativeElement;
    let children = this.el.nativeElement.children;
    if (children.length > this.buttonLimit) {
      // turn excess buttons into a list
      // children is a live list, reverse the loop to ensure loop iterates properly
      for (var i = children.length - 1; i >= 0; --i) {

        // don't alter the menus
        let isMenuButton = children[i].attributes["action-menu-button"];
        let isMenu = children[i].attributes["action-menu"];

        if (!isMenuButton && !isMenu) {
          // reserve 1 button for the list button
          if (i > this.buttonLimit - 2) {
            // remove button from button-list
            let attr = children[i].attributes["action-menu-desc"];

            // alter element to look like mat-menu-item
            let button: HTMLElement = children.item(i);
            button.innerHTML = "";
            if (attr.value) button.innerHTML = attr.value;
            button.classList.add("mat-menu-item");
            button.classList.remove("mat-icon-button");
            // only from main elem, and add to matmenu
            elem.removeChild(children.item(i))
            this.renderer.appendChild(this.matMenuItems, button)
          }
        }
      }

    } else {
      // remove mat-menu
      this.matMenuButton._elementRef.nativeElement.remove();
    }

  }

  ngOnDestroy() {
  }
}

