export class BrowserCheck {
    static checkBrowserSupport(): boolean {
        let isBrowserSupported = false;
        if (navigator.userAgent.indexOf(' OPR/') >= 0) { // Opera
            isBrowserSupported = false;
        } else if (navigator.userAgent.indexOf("Safari") != -1 && navigator.userAgent.indexOf("Chrome") == -1) {  // Safari
            isBrowserSupported = false;
        } else if (navigator.userAgent.indexOf("Chrome") != -1) {  // Chrome
            isBrowserSupported = true;
        } else if (navigator.userAgent.indexOf("Firefox") != -1) {  // Firefox
            isBrowserSupported = true;
        } else if (!!window.styleMedia) { // Edge
            isBrowserSupported = true;
        }
        return isBrowserSupported;
    }

    static isIOS(): boolean {
        let isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))

        return isIOS;
    }
}
