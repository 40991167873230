import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CloudFolder } from '../../../core/model/cloud-folder';
import { Resource } from '../../../core/model/resource';
import { ListFoldersDeleg, MoveResourcesDeleg } from '../../../core/model/resource-delegate';
import { SharedFolder } from '../../../core/model/shared-folder';

@Component({
  selector: 'app-dialog-move-resource',
  templateUrl: './dialog-move-resource.component.html',
  styleUrls: ['./dialog-move-resource.component.scss']
})
export class DialogMoveResourceComponent implements OnInit {
  // orgId: string;
  // isPersonalOrg: boolean = false;
  // isLocalStorage: boolean = true;
  // storage: StorageType = StorageType.Local;
  parentFolder: CloudFolder | SharedFolder;  // folder where the resources-to-move are currently at
  currentFolder: CloudFolder | SharedFolder; // current folder shown in this dialog
  navigatedFolders: Resource[] = [];  //list of navigated folders
  destinationFolder: CloudFolder | SharedFolder; //folder to move the resources to
  selectedFolderPaths: string[] = []; //folders to move
  // selectedFilePaths: string[] = []; //files to move
  folderDisplays: FolderDisplay[] = [];  //list of folders to display in move dialog
  isLoadingFolders: boolean = true;

  listFoldersDeleg: ListFoldersDeleg;
  moveDeleg: MoveResourcesDeleg;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogMoveResourceComponent>,
    public snackBar: MatSnackBar,
    private translateService: TranslateService) { }

  get currentFolderTitle(): string {
    if (!this.currentFolder) return "";
    // if (this.isLocalStorage) {
    //   let folder = this.currentFolder as CloudFolder;
    //   if (folder.isAppDataFolder) return folder.name; //workaround because appdata subfolders isRoot is true
    //   return folder.isRoot ? FileExplorerComponent.CLOUD_FILE : folder.name;
    // } else {
      return this.currentFolder.name;
    // }
  }

  get isBtnDisabled(): boolean {
    // if (this.isLocalStorage) {
      
    //   let folder = this.destinationFolder as CloudFolder;
    //   let parentFolder = this.parentFolder as CloudFolder;
    //   // if (folder.isAppDataFolder) return true;
    //   //workaround if parent folder is my drive
    //   //unable to compare path because parent folder's path is empty while destination folder's path is users/userId
    //   if (folder.isRoot && parentFolder.isRoot) return true;
    // }
    //TODO check root

    if (this.destinationFolder && this.parentFolder && this.destinationFolder.path === this.parentFolder.path)
      return true;
    return false;
  }

  get showBackBtn(): boolean {
    // if (this.isLocalStorage) {
      // return this.navigatedFolders.length > 0;
    // } else {
      return this.navigatedFolders.length > 1; 
    // }

  }

  get moveBtnText(): boolean {
    return this.destinationFolder && this.currentFolder && this.destinationFolder.path == this.currentFolder.path ?
      this.translateService.instant("STORAGE.MOVE_HERE") : this.translateService.instant("STORAGE.MOVE");
  }

  get isFolderEmpty(): boolean {
    return this.folderDisplays ? this.folderDisplays.length == 0 : true;
  }

  ngOnInit(): void {
    this.parentFolder = this.data.currentFolder;
    // this.storage = this.data.storage;
    // this.orgId = this.data.orgId;
    // this.isPersonalOrg = this.data.isPersonalOrg;
    // this.isLocalStorage = this.data.isLocalStorage;
    this.selectedFolderPaths = this.data.selectedFolderPaths ? this.data.selectedFolderPaths : [];
    // this.selectedFilePaths = this.data.selectedFilePaths ? this.data.selectedFilePaths : [];
    this.navigatedFolders = [...this.data.navigatedFolders] ?? [];
    this.listFoldersDeleg = this.data.listFoldersDeleg;
    this.moveDeleg = this.data.moveDeleg;
    this.getFolders(this.parentFolder, false);
  }

  async getFolders(folder: SharedFolder | CloudFolder, addToNavigatedFolders: boolean = true) {
    this.isLoadingFolders = true;
    this.currentFolder = folder;
    this.destinationFolder = folder;
    this.folderDisplays = [];

    if (addToNavigatedFolders) this.navigatedFolders.push(folder);

    let result = await this.listFoldersDeleg(folder);
    if (result && result.length != 0) {
      this.folderDisplays = result.map((f) => {
        let chosenFolder = this.selectedFolderPaths.find((sf) => sf == f.path);
        return new FolderDisplay(f as CloudFolder | SharedFolder, chosenFolder != null);
      });
    }


    // if (!this.isLocalStorage) {
    //   let sharedFolder = folder as SharedFolder;
    //   let fullPath: string = sharedFolder.isSharedOriginScope ? sharedFolder.originPath : sharedFolder.path;
    //   let sharedPath: string = sharedFolder.isSharedOriginScope ? sharedFolder.path : sharedFolder.originPath;
    //   await this.getSharedFolder(fullPath, sharedPath);

      
    // } else {
    //   let localFolder = folder as CloudFolder;
    //   await this.getLocalFolder(localFolder.path);
    // }

    this.isLoadingFolders = false;

  }

  // async getLocalFolder(path: string) {

  //   let folder = await this.listFoldersDeleg(this.orgId, path, this.isPersonalOrg);
  //   if (folder && folder.folders) {
  //     this.folderDisplays = folder.folders.map((f) => {
  //       if (f.isAppDataFolder) return new FolderDisplay(f, true);
        
  //       let chosenFolder = this.selectedFolderPaths.find(sf => sf == f.path);
  //       return new FolderDisplay(SharedFolder.parse(f), chosenFolder != null) ;
  //     });
  //   }

  //   return folder;
  // }

  // async getSharedFolder(path: string, originPath: string) {
  //   let allResources = await this.cloudFileService.readSharedFolder(originPath, path, this.orgId, this.isPersonalOrg).toPromise();
  //   if (allResources != null && allResources.length > 0) {
  //     allResources.forEach((c) => {
  //       if (!c.originContentType) { //if not file
  //         let chosenFolder = this.selectedFolderPaths.find(sf => sf == c.path);
  //         let fd = new FolderDisplay(SharedFolder.parse(c), chosenFolder != null) ;
  //         this.folderDisplays.push(fd);
  //       }
  //     });
  //   }

  //   return true;

  // }

  goToFolder(item: FolderDisplay) {
    if (item.isDisabled) return;
    this.getFolders(item.folder);
  }

  selectDestinationFolder(event: any, item: FolderDisplay) {
    if (item.isDisabled) return;
    if (this.destinationFolder && item && this.destinationFolder.path == item.path) {
      //unselect folder
      this.destinationFolder = this.currentFolder;
    } else {
      this.destinationFolder = item.folder;
    }
    
  }

  async move() {    
    // if (this.isLocalStorage) {
    //   var destinationFolderName = !this.destinationFolder.name ? FileExplorerComponent.CLOUD_FILE : this.destinationFolder.name;
    //   if (this.isPersonalOrg) {
    //     this.cloudFileService.movePersonalItems(this.parentFolder.path, this.destinationFolder.path, destinationFolderName, this.selectedFilePaths, this.selectedFolderPaths).toPromise();
    //   } else {
    //     this.cloudFileService.moveUserItems(this.orgId, this.parentFolder.path, this.destinationFolder.path, destinationFolderName, this.selectedFilePaths, this.selectedFolderPaths).toPromise();
    //   }
    // } else {
    //   let sharedFolder = this.parentFolder as SharedFolder;
    //   let destinationFolder = this.destinationFolder as SharedFolder;
    //   let originPath = sharedFolder.scope == "SHARED-ORIGIN" ? sharedFolder.path : sharedFolder.originPath;
    //   let sharedPath = sharedFolder.scope == "SHARED-ORIGIN" ? sharedFolder.originPath : sharedFolder.path;
    //   let destinationPath = destinationFolder.scope == "SHARED-ORIGIN" ? destinationFolder.path : destinationFolder.originPath;

    //   if (this.isPersonalOrg) {
    //     this.cloudFileService.movePersonalSharedItem(originPath, sharedPath, destinationPath, destinationFolder.name, this.selectedFilePaths, this.selectedFolderPaths).toPromise();
    //   } else {
    //     this.cloudFileService.moveUserSharedItem(this.orgId, originPath, sharedPath, destinationPath,  destinationFolder.name, this.selectedFilePaths, this.selectedFolderPaths).toPromise();
    //   }
    // }

    this.moveDeleg([this.parentFolder], {origin: this.parentFolder, dest: this.destinationFolder});
    this.dialogRef.close(true);
  }

  async back() {
    this.isLoadingFolders = true;
    this.folderDisplays = [];
    //console.log("onback this.navigatedFolders: %o", this.navigatedFolders);
    this.navigatedFolders.pop();
    var navigateTo = this.navigatedFolders && this.navigatedFolders.length > 0 ? 
      this.navigatedFolders[this.navigatedFolders.length - 1] : new CloudFolder();

    this.getFolders(navigateTo as CloudFolder | SharedFolder, false);

    // if (!this.isLocalStorage) {
    //   let sharedFolder = this.currentFolder as SharedFolder;
    //   let prevPath = this.getPrevPath(sharedFolder.path);
    //   let prevOriginPath = this.getPrevPath(sharedFolder.originPath);
    //   await this.getSharedFolder(prevPath, prevOriginPath);
    //   //this.currentFolder = folder; 
    //   //this.destinationFolder = folder;
    //   //this.navigatedFolders.push(folder);
    // } else {
    //   let localFolder = this.currentFolder as CloudFolder;
    //   let prevPath = this.getPrevPath(localFolder.path);
    //   let folder = await this.getLocalFolder(prevPath);
    //   this.currentFolder = folder;
    //   this.destinationFolder = folder;
    //   this.navigatedFolders.push(folder);
    // }

    // this.isLoadingFolders = false;

  }

  getPrevPath(path: string) {
    let splt = path.split("/");
    return splt.slice(0, splt.length - 1).join("/");
  }

  close() {
    this.dialogRef.close();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 9000,
    });
  }
}

export enum StorageType {
  Local,
  SharedWithMe
}

export class FolderDisplay {
  isDisabled: boolean;
  path: string;
  folder: CloudFolder | SharedFolder;

  constructor(folder: CloudFolder | SharedFolder, isDisabled: boolean = false) {
    this.path = folder.path;
    this.folder = folder;
    this.isDisabled = isDisabled;
  }
}