import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { DownloadService } from '../services/download.service';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
    name: 'fileAuth'
})
export class FileAuthPipe implements PipeTransform {
    constructor(private http: HttpClient, private localStorage: LocalStorageService, private downloadService: DownloadService,
        private sanitizer: DomSanitizer) { }

    async transform(src: string, mimeType?: string): Promise<any> {
        if (!mimeType) mimeType = "image/png" // temp codes until mimeType bug on orgHub handshake is fixed

        try {
            let fileBlob = await this.downloadService.downloadFile(src);
            const reader = new FileReader();
            return new Promise((resolve, reject) => {
                reader.readAsDataURL(fileBlob);

                reader.onloadend = () => {
                    let blob = reader.result as string;
                    blob = blob.replace("application/octet-stream", mimeType);
                    resolve(
                        this.sanitizer.bypassSecurityTrustUrl(blob)
                    );
                };
                // reader.readAsDataURL(fileBlob);
            })
        } catch {
            return '';
        }
    }
}
