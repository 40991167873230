import { EmitterAction } from "@ngxs-labs/emitter";
import { ImmutableSelector, ImmutableContext } from "@ngxs-labs/immer-adapter";
import { State, Selector, createSelector, StateContext } from "@ngxs/store";
import { Receiver } from "@ngxs-labs/emitter";
import { Injector, Injectable } from "@angular/core";
import {
  FileObjState,
  FileType,
  FileDownloadStatus
} from "../model/file-obj.state";
import { EnterpriseState } from "./enterprise.state";
import { PostState, PostCommentState } from "../model/org.state";
import { MessagingSelector } from "./messaging.state.selector";
import { MessageState } from "../model/message.state";
import { contains } from "../util/array.extension";
import { EnterpriseSelector } from './enterprise.state.selector';
import * as _ from "lodash";

export class FileStateModel {
  files: FileObjState[];
  constructor() {
    this.files = [];
  }
}

@State<FileStateModel>({
  name: "file",
  defaults: new FileStateModel()
})
@Injectable()
export class FileState {
  constructor() { }

  ngxsAfterBootstrap(ctx: StateContext<FileStateModel>) {
    console.log("[FileState] - ngxsAfterBootstrap");
  }

  @Selector()
  @ImmutableSelector()
  static files(state: FileStateModel): FileObjState[] {
    var result = state.files.filter(f => f.type != FileType.Audio);
    return _.cloneDeep(result);
  }

  @Selector()
  @ImmutableSelector()
  static audio(state: FileStateModel): FileObjState[] {
    var result = state.files.filter(f => f.type == FileType.Audio);
    return _.cloneDeep(result);
  }

  @Selector()
  @ImmutableSelector()
  static pendingDownloadFiles(state: FileStateModel): FileObjState[] {
    const files = [...state.files];
    const result = files.filter(
      d => d.downloadStatus == FileDownloadStatus.PendingToDownload
    );

    var results = result.sort((a, b) => {
      let aDate = new Date(a.timeStamp);
      let bDate = new Date(b.timeStamp);

      if (aDate < bDate) {
        return 1;
      }
      if (aDate > bDate) {
        return -1;
      }
      return 0;
    });
    return _.cloneDeep(results);
  }

  //current posts and files
  @Selector([EnterpriseState.currentPosts, FileState.files])
  @ImmutableSelector()
  static currentPostFiles(
    currentPosts: PostState[],
    files: FileObjState[]
  ): FileObjState[] {
    var result = currentPosts
      .filter(p => p.mediaId)
      .map(i => files.find(f => f.id === i.mediaId))
      .filter(i => i != null);
    return _.cloneDeep(result);
  }

  //current msgs and files
  @Selector([MessagingSelector.currentOrgMessages, FileState.files])
  @ImmutableSelector()
  static currentMsgFiles(
    currentMsgs: MessageState[],
    files: FileObjState[]
  ): FileObjState[] {
    var result = currentMsgs
      .filter(m => m.mediaId)
      .map(i => files.find(f => f.id === i.mediaId))
      .filter(i => i != null);
    return _.cloneDeep(result);
  }

  static file(mediaId: string) {
    return createSelector([FileState.files], (states: FileObjState[]) => {
      const file = states.find(s => s.id == mediaId);
      return file ? _.cloneDeep(file) : null;
    });
  }

  static roomFiles(roomId: string) {
    return createSelector(
      [FileState.files, MessagingSelector.roomMessages(roomId)],
      (files: FileObjState[], msgs: MessageState[]) => {
        const mediaIds = msgs.map(i => i.mediaId);
        var result = files.filter(s => contains(mediaIds, m => m === s.id));
        return _.cloneDeep(result);
      }
    );
  }

  static commentFiles(postId: string) {
    return createSelector(
      [FileState.files, EnterpriseSelector.postComments(postId)],
      (files: FileObjState[], comments: PostCommentState[]) => {
        const mediaIds = comments.map(i => i.mediaId);
        var result = files.filter(s => contains(mediaIds, m => m === s.id));
        return _.cloneDeep(result);
      }
    )
  }

  @Receiver()
  @ImmutableContext()
  static addOrUpdateFile(
    ctx: StateContext<FileStateModel>,
    arg: EmitterAction<FileObjState>
  ) {
    if (!arg) return;

    const state = ctx.getState();
    const files = [...state.files];

    let index = files.findIndex(file => file.id === arg.payload.id);
    if (index == -1) {
      files.push(arg.payload);
    } else {
      files[index] = this.mutateFileObjState(files[index], arg.payload);
    }

    state.files = [...files];
    ctx.setState(state);
  }

  private static mutateFileObjState(
    existing: FileObjState,
    file: FileObjState
  ) {
    if (!existing || !file) return existing;

    existing.id = file.id;
    existing.orgId = file.orgId;
    existing.name = file.name;
    existing.url = file.url;
    existing.fwt = file.fwt;
    existing.fwtEncoded = file.fwtEncoded;
    existing.downloadStatus = file.downloadStatus;
    existing.downloadedPath = file.downloadedPath;
    if (file.size && file.size != 0) existing.size = file.size;

    return existing;
  }

  @Receiver()
  @ImmutableContext()
  static removeFile(
    ctx: StateContext<FileStateModel>,
    arg: EmitterAction<string>
  ) {
    if (!arg) return;

    const state = ctx.getState();
    const files = [...state.files];

    var index = files.findIndex(file => file.id === arg.payload);
    if (index !== -1) {
      files.splice(index, 1);
    }

    state.files = [...files];
    ctx.setState(state);
  }

  @Receiver()
  static clean(ctx: StateContext<FileStateModel>) {
    ctx.setState({ ...new FileStateModel() });
  }
}
