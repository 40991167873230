import {
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
  EventEmitter,
  Output,
  Input,
  ChangeDetectorRef
} from "@angular/core";
import linkifyStr from "linkifyjs/string";
import { ReplyToPipe } from "../pipe/reply-to.pipe";

@Directive({
  selector: "[el-text-link]"
})
export class TextLinkDirective {
  private _event_handler_name: string = "el-text-link-handler";
  constructor(
    private elemRef: ElementRef,
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef,
    private replyToPipe: ReplyToPipe
  ) {}
  private _content: any;
  private _onContentChanged: any = false;

  @Input()
  set onContentChanged(data: boolean) {
    this._onContentChanged = data;
  }

  // @Output() finishRender:EventEmitter<Boolean> = new EventEmitter<Boolean>();

  ngAfterViewInit() {
    this.renderer.setProperty(this.elemRef.nativeElement, 'innerHTML', this.transform(this.elemRef.nativeElement.textContent));
    // this.finishRender.emit(true);
    this._content = this.elemRef.nativeElement.textContent;
  }

  ngAfterViewChecked() {
    if (
      this._content &&
      this._content != this.elemRef.nativeElement.textContent &&
      this._onContentChanged
    ) {
      this.renderer.setProperty(this.elemRef.nativeElement, 'innerHTML', this.transform(this.elemRef.nativeElement.textContent));
      // this.finishRender.emit(true);
      this.cdRef.detectChanges();
    }
  }

  private transform(originalHtml?: string) {
    if (originalHtml.trim() == "") return "";
    var content: string = this.replyToPipe.undoTransform(originalHtml);
    return linkifyStr(content);

    // let frag = document.createElement("span");
    // let el: HTMLElement = this.elemRef.nativeElement;
    // let urlRE = new RegExp(
    //   "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_])?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?([^ ])+"
    // );
    // console.log(el.innerText);
    // console.log(el);
    // console.log(this.elemRef.nativeElement.textContent);
    // let lastnode = el.lastChild;
    // let firstnode = el.firstChild;
    // if (firstnode.nodeType == 3) {
    //   if (firstnode.nodeValue.match(urlRE)) {
    //     frag.innerHTML = linkifyStr(firstnode.nodeValue);
    //     el.replaceChild(frag, firstnode);
    //   }
    // } else {
    //   if (lastnode.nodeValue.match(urlRE)) {
    //     frag.innerHTML = linkifyStr(lastnode.nodeValue);
    //     el.replaceChild(frag, lastnode);
    //   }
    // }
  }

  @HostListener("click", ["$event"])
  onclick(e) {
    if (
      e.target.tagName.toLowerCase() == "a" ||
      e.target.classList.contains(this._event_handler_name)
    ) {
      let url: string = e.target.href;

      event.preventDefault();
      event.stopPropagation();

      window.open(url, "_system", "location=yes");
    }
  }
}
