import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogSwitchOUComponent } from '../switch-ou/dialog-switch-ou.component';
import { Plan, ProductType } from '../../../model/plan.model';
import { DatePipe } from '@angular/common';
import { SubType } from '../../../enum/sub-type';
@Component({
  selector: 'app-dialog-order-confirmation',
  templateUrl: './dialog-order-confirmation.component.html',
  styleUrls: ['./dialog-order-confirmation.component.scss'],
  providers: [DatePipe]
})
export class DialogOrderConfirmationComponent implements OnInit {

  subType: SubType;
  card: any;
  isLoading = false;

  // getters

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogSwitchOUComponent>) {
  }

  ngOnInit() {
    this.card = this.data.card;
    this.subType = this.data.subType;
    if (this.card) this.card.icon = this.getCardIcon(this.card.brand);
  }

  getCardIcon(brand: string) {
    let cardBrand = brand.toLowerCase();
    if (cardBrand === "american express") {
      return "cc-amex";
    } else if (cardBrand === "diners club") {
      return "cc-diners-club"
    } else if (cardBrand === "unionpay") {
      return "credit-card"
    }
    return "cc-" + cardBrand;
  }

  confirmOrder() {
    this.dialogRef.close({ status: 'success' });
  }
}

export class OrderConfirmationData {
  subType: SubType;
  card: any;
}
