import { ContactLabelState } from "../model/contact-label.state";
import { ImmutableContext, ImmutableSelector } from "@ngxs-labs/immer-adapter";
import { Receiver, EmitterAction } from "@ngxs-labs/emitter";
import { State, Selector, StateContext, createSelector } from "@ngxs/store";
import { CardState } from "../model/card.state";
import { UserProfileState } from "../model/user-profile.state";
import * as _ from "lodash";
import { Dictionary, StringDictionary } from "../util/dictionary";
import { Feed } from "../model/feed";
import { EnterpriseSelector } from "./enterprise.state.selector";
import { Injector, Injectable } from "@angular/core";
import { Frequency } from "../enum/frequency.enum";

export class UserDataStateModel {
  contactLabels: ContactLabelState[];
  cards: CardState[];
  userProfile: UserProfileState;
  language: string;
  fcmToken: string;
  publicKey: string;
  hasTrial: boolean;
  feedUnreads: Dictionary<string[]>;
  pendingFeedClear: string[]; //if feeds fail to clear, append to this
  isLoggedIn: boolean;
  timezone: string;
  emailFrequency: Frequency;

  constructor() {
    this.cards = [];
    this.contactLabels = [];
    this.language = "en";
    this.userProfile = null;
    this.fcmToken = "";
    this.publicKey = null;
    this.hasTrial = false;
    this.feedUnreads = {};
    this.pendingFeedClear = [];
    this.isLoggedIn = false;
    this.timezone = null;
    this.emailFrequency = null;
  }
}

@State<UserDataStateModel>({
  name: "userData",
  defaults: new UserDataStateModel(),
})
@Injectable()
export class UserDataState {

  private static enterpriseSelector: EnterpriseSelector;
  public static readonly PERSONAL_CHANNEL_FEEDS_KEY: string =
    "personal_ch_feeds";
  public static readonly PERSONAL_SHARED_FEEDS_KEY: string =
    "personal_shared_feeds";


  constructor(injector: Injector) {
    UserDataState.enterpriseSelector = injector.get<EnterpriseSelector>(
      EnterpriseSelector
    );

  }

  ngxsAfterBootstrap(ctx: StateContext<UserDataStateModel>) {
    console.log("[UserDataState] - ngxsAfterBootstrap");
  }

  @Selector()
  @ImmutableSelector()
  static cards(state: UserDataStateModel): CardState[] {
    const cards = [...state.cards];
    cards.sort((a, b) => {
      if (a.createdOn > b.createdOn) {
        return -1;
      }
      if (a.createdOn < b.createdOn) {
        return 1;
      }
      return 0;
    });
    return _.cloneDeep(cards);
  }

  @Selector()
  @ImmutableSelector()
  //all user custom labels
  static contactLabels(state: UserDataStateModel): ContactLabelState[] {
    return [..._.cloneDeep(state.contactLabels)];
  }

  @Selector()
  //@ImmutableSelector()
  static language(state: UserDataStateModel): string | null {
    return _.cloneDeep(state.language);
  }

  @Selector()
  static timezone(state: UserDataStateModel): string | null {
    return _.cloneDeep(state.timezone);
  }

  @Selector()
  static emailFrequency(state: UserDataStateModel): Frequency | null {
    return _.cloneDeep(state.emailFrequency);
  }

  @Selector()
  //@ImmutableSelector()
  static fcmToken(state: UserDataStateModel): string | null {
    return _.cloneDeep(state.fcmToken);
  }

  @Selector()
  @ImmutableSelector()
  static publicKey(state: UserDataStateModel): string | null {
    return _.cloneDeep(state.publicKey);
  }

  @Selector()
  //@ImmutableSelector()
  static hasTrial(state: UserDataStateModel): boolean | null {
    return _.cloneDeep(state.hasTrial);
  }

  @Selector()
  static feedUnreads(state: UserDataStateModel): StringDictionary | null {
    var result = new StringDictionary({ ...state.feedUnreads });
    return _.cloneDeep(result);
  }

  @Selector()
  static pendingFeedClear(state: UserDataStateModel): string[] | null {
    return _.cloneDeep(state.pendingFeedClear);
  }

  @Selector()
  static isLoggedIn(state: UserDataStateModel): boolean | null {
    return state.isLoggedIn;
  }

  @Selector()
  //@ImmutableSelector()
  static userProfile(state: UserDataStateModel): UserProfileState | null {
    const userProfile = state.userProfile;
    return { ..._.cloneDeep(userProfile) };
  }

  @Selector([UserDataState.userProfile])
  //@ImmutableSelector()
  static matrixId(userProfile: UserProfileState): string | null {
    return _.cloneDeep(userProfile.matrixId);
  }

  @Selector([UserDataState.userProfile])
  //@ImmutableSelector()
  static userId(userProfile: UserProfileState): string | null {
    return _.cloneDeep(userProfile.userId);
  }

  @Selector([UserDataState.userProfile])
  @ImmutableSelector()
  static cardExpiration(userProfile: UserProfileState): number {
    return _.cloneDeep(userProfile.cardExpiration);
  }

  //return list of labels associate to contact
  static contactsLabel(contactId: string) {
    return createSelector(
      [UserDataState.contactLabels],
      (state: ContactLabelState[]) => {
        var result = state.filter(
          (c) => c.contactIds && c.contactIds.includes(contactId)
        );
        return _.cloneDeep(result);
      }
    );
  }

  static contactLabel(labelId: string) {
    return createSelector(
      [UserDataState.contactLabels],
      (state: ContactLabelState[]) => {
        const label = state.find((c) => c.labelId == labelId);
        return label ? _.cloneDeep(label) : null;
      }
    );
  }

  @Receiver()
  @ImmutableContext()
  static addOrUpdateFcmToken(
    ctx: StateContext<UserDataStateModel>,
    arg: EmitterAction<string>
  ) {
    if (!arg) return;

    const state = ctx.getState();
    const token = state.fcmToken;

    if (token !== arg.payload) {
      state.fcmToken = arg.payload;
      ctx.setState(state);
    }
  }

  @Receiver()
  @ImmutableContext()
  static removeFcmToken(
    ctx: StateContext<UserDataStateModel>,
    arg: EmitterAction<void>
  ) {
    if (!arg) return;

    const state = ctx.getState();

    state.fcmToken = null;
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  static addOrUpdateCards(
    ctx: StateContext<UserDataStateModel>,
    arg: EmitterAction<CardState[]>
  ) {
    if (!arg || !arg.payload) return;

    const state = ctx.getState();
    const cards = [...state.cards];

    const newCards = arg.payload.filter(
      (card) => cards.findIndex((exist) => exist.cardId == card.cardId) === -1
    );

    if (newCards && newCards.length != 0) {
      cards.push(...newCards);
    }

    state.cards = [...cards];
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  static removeCard(
    ctx: StateContext<UserDataStateModel>,
    arg: EmitterAction<string>
  ) {
    if (!arg) return;

    const state = ctx.getState();
    const cards = [...state.cards];

    var index = cards.findIndex((c) => c.cardId == arg.payload);
    if (index !== -1) {
      cards.splice(index, 1);
    }

    state.cards = [...cards];
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  static addOrUpdateContactLabels(
    ctx: StateContext<UserDataStateModel>,
    arg: EmitterAction<ContactLabelState[]>
  ) {
    if (!arg) return;
    const state = ctx.getState();
    const labels = [...state.contactLabels];

    const newLabels = arg.payload.filter(
      (l) => labels.findIndex((labels) => labels.labelId == l.labelId) === -1
    );
    const existing = arg.payload.filter(
      (l) => labels.findIndex((labels) => labels.labelId == l.labelId) !== -1
    );

    //add new labels
    if (newLabels && newLabels.length != 0) {
      labels.push(...newLabels);
    }

    //update existing labels
    existing.forEach((label) => {
      let index = labels.findIndex((l) => l.labelId === label.labelId);
      if (index !== -1) {
        labels[index] = this.mutateContactLabelState(labels[index], label);
      }
    });

    state.contactLabels = [...labels];
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  static assignLabelsToContact(
    ctx: StateContext<UserDataStateModel>,
    arg: EmitterAction<{ contactId: string; labels: ContactLabelState[] }>
  ) {
    if (!arg || !arg.payload) return;
    const contactId = arg.payload.contactId;
    const labels = arg.payload.labels;
    if (!contactId) return;
    if (!labels) return;

    const state = ctx.getState();
    const all = [...state.contactLabels];

    //if empty labels, remove all labels with contactId
    if (labels.length == 0) {
      all.forEach((label) => {
        label.contactIds = label.contactIds.filter((i) => i !== contactId);
      });
    } else {
      //new orgs
      const newList = _.differenceBy(labels, all, "labelId");
      //existing orgs
      const existingList = _.intersectionBy(labels, all, "labelId");

      //update existing
      existingList.forEach((label) => {
        let index = all.findIndex((l) => l.labelId === label.labelId);
        if (index !== -1) {
          all[index] = this.mutateContactLabelState(all[index], label);
        }
      });

      if (newList.length > 0) {
        state.contactLabels = [...all, ...newList];
      } else {
        state.contactLabels = [...all];
      }
    }

    ctx.setState(state);
  }

  private static mutateContactLabelState(
    existing: ContactLabelState,
    label: ContactLabelState
  ) {
    existing.name = label.name;
    existing.contactIds = [...label.contactIds];
    return existing;
  }

  @Receiver()
  @ImmutableContext()
  static removeLabel(
    ctx: StateContext<UserDataStateModel>,
    arg: EmitterAction<string>
  ) {
    if (!arg) return;
    const state = ctx.getState();
    const labels = [...state.contactLabels];

    const index = labels.findIndex((labels) => labels.labelId == arg.payload);
    if (index !== -1) {
      labels.splice(index, 1);
    }

    state.contactLabels = [...labels];
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  static setLanguage(
    ctx: StateContext<UserDataStateModel>,
    arg: EmitterAction<string>
  ) {
    const state = ctx.getState();
    state.language = arg.payload;
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  static setTimezone(
    ctx: StateContext<UserDataStateModel>,
    arg: EmitterAction<string>
  ) {
    const state = ctx.getState();
    state.timezone = arg.payload;
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  static setEmailFrequency(
    ctx: StateContext<UserDataStateModel>,
    arg: EmitterAction<Frequency>
  ) {
    if (!arg || arg.payload == null) return;
    const state = ctx.getState();
    state.emailFrequency = arg.payload;
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  static setPublicKey(
    ctx: StateContext<UserDataStateModel>,
    arg: EmitterAction<string>
  ) {
    const state = ctx.getState();
    state.publicKey = arg.payload;
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  static setUserProfile(
    ctx: StateContext<UserDataStateModel>,
    arg: EmitterAction<UserProfileState>
  ) {
    const state = ctx.getState();
    state.userProfile = arg.payload;
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  static updateUserProfile(
    ctx: StateContext<UserDataStateModel>,
    arg: EmitterAction<UserProfileState>
  ) {
    const state = ctx.getState();
    const profile = this.mutateUserProfileState(state.userProfile, arg.payload);

    state.userProfile = { ...profile };
    ctx.setState(state);
  }

  private static mutateUserProfileState(
    existing: UserProfileState,
    profile: UserProfileState
  ) {
    existing.firstName = profile.firstName;
    existing.lastName = profile.lastName;
    existing.imageUrl = profile.imageUrl;
    existing.phoneNumber = profile.phoneNumber;
    existing.twoFactorEnabled = profile.twoFactorEnabled;
    existing.email = profile.email;
    existing.emailVerified = profile.emailVerified;
    existing.cardExpiration = profile.cardExpiration;

    return existing;
  }

  @Receiver()
  @ImmutableContext()
  static addOrUpdateTrialFlag(
    ctx: StateContext<UserDataStateModel>,
    arg: EmitterAction<boolean>
  ) {
    if (!arg) return;

    const state = ctx.getState();
    const hasTrial = state.hasTrial;

    if (hasTrial !== arg.payload) {
      state.hasTrial = arg.payload;
      ctx.setState(state);
    }
  }

  @Receiver()
  @ImmutableContext()
  static addFeedUnreads(
    ctx: StateContext<UserDataStateModel>,
    arg: EmitterAction<Feed[]>
  ) {
    const state = ctx.getState();
    const feeds: Feed[] = arg.payload;

    let unreads = new StringDictionary({ ...state.feedUnreads });

    const personalOrg = UserDataState.enterpriseSelector.getPersonalOrg();

    const grp = _.groupBy(feeds, (o: Feed) => {
      //console.log("addFeedUnreads ", o);
      if (o.isNewShared) {
        const isPersonalSpace = UserDataState.enterpriseSelector.isSameOrg(personalOrg.id, o.orgId);
        return isPersonalSpace ? UserDataState.PERSONAL_SHARED_FEEDS_KEY : o.orgId + "-sharedStorage";
      }

      if (o.isPost || o.isComment) {
        const isPersonalSpace = UserDataState.enterpriseSelector.isSameOrg(personalOrg.id, o.orgId);
        return isPersonalSpace ? UserDataState.PERSONAL_CHANNEL_FEEDS_KEY : o.data.channelId;
      }
      if (o.isAllMsgType) return o.data ? o.data.roomId : o.id;
    });

    Object.keys(grp).forEach((parent) => {
      if (!parent || parent == "undefined") return;
      const ids = grp[parent];
      const listIds = ids.map(c => c.id);
      unreads.addValues(parent, listIds);
    });

    state.feedUnreads = unreads.toDictionary();
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  public static clearFeedUnread(
    ctx: StateContext<UserDataStateModel>,
    arg: EmitterAction<string>
  ) {
    if (!arg || arg.payload == null) return;

    const state = ctx.getState();
    let feedUnreads = new StringDictionary({ ...state.feedUnreads });

    feedUnreads.remove(arg.payload);

    state.feedUnreads = feedUnreads.toDictionary();
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  public static clearAllFeedUnread(
    ctx: StateContext<UserDataStateModel>,
    arg: EmitterAction<null>
  ) {
    const state = ctx.getState();
    state.feedUnreads = {};
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  static addPendingClearFeed(
    ctx: StateContext<UserDataStateModel>,
    arg: EmitterAction<string[]>
  ) {
    const state = ctx.getState();
    state.pendingFeedClear = _.uniq([...state.pendingFeedClear, ...arg.payload]);
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  static removePendingClearFeed(
    ctx: StateContext<UserDataStateModel>,
    arg: EmitterAction<null>
  ) {
    const state = ctx.getState();
    state.pendingFeedClear = [];
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  static setLoggedIn(
    ctx: StateContext<UserDataStateModel>,
    arg: EmitterAction<boolean>
  ) {
    const state = ctx.getState();
    state.isLoggedIn = arg.payload;
    ctx.setState(state);
  }

  @Receiver()
  static clean(ctx: StateContext<UserDataStateModel>) {
    ctx.setState({ ...new UserDataStateModel() });
  }
}
