import { UserMentions } from '../../model/user-mentions';
import { Component, OnInit, Input, EventEmitter, Output, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-chat-mentions-list',
  templateUrl: './chat-mentions-list.component.html',
  styleUrls: ['./chat-mentions-list.component.scss']
})
export class ChatMentionsListComponent implements OnInit {

  @Input() users: UserMentions[] = [];

  @Output() selectedUser: EventEmitter<UserMentions> = new EventEmitter<UserMentions>();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ChatMentionsListComponent>) { }
  // @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ChatMentionsListComponent>
  ngOnInit() { 
    this.users = this.data.users;
  }

  selectUser(participant: UserMentions) {
    // this.selectedUser.emit(participant);
    this.dialogRef.close(participant);
  }

}
