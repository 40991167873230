import { FeatureDailyUsage } from "./feature-daily-usage.state";


export class SubRealtimeData {
  subId: string;
  cost: number;
  usages: FeatureDailyUsage[];
  constructor() {
    this.usages = [];
  }
}
