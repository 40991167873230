import { ReplyToPipe } from './../pipe/reply-to.pipe';
import { UserMentions } from '../model/user-mentions';
import {
  Directive,
  ElementRef,
  ViewContainerRef,
  ComponentFactoryResolver,
  Renderer2,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
  HostListener,
  ComponentRef,
  EmbeddedViewRef,
  IterableDiffers,
  DefaultIterableDiffer
} from "@angular/core";
import { ChatMentionsListComponent } from "../ui/chat-mentions-list/chat-mentions-list.component";
import { ChatMentionsOthersComponent } from "../ui/chat-mentions-others/chat-mentions-others.component";
import { MatDialog } from "@angular/material/dialog";
import { Subscription, Subject } from "rxjs";
import { UserDataSelector } from '../states/user-data.state.selector';

@Directive({
  selector: "[el-chat-mentions]",
  providers: [ReplyToPipe]
})
export class ChatMentionsDirective {
  private _chatListComponent: ComponentRef<ChatMentionsListComponent>;
  private _selectedMentions: UserMentions[] = [];
  private _differ: any;
  private _caretPosition: number = 0;
  private _onSendSubscription: Subscription;
  private _oldContent: string ="";


  @Input() enable: boolean = true;
  @Input() users: UserMentions[];
  @Input() onSendSub: Subject<void>;
  @Input() isMsgBubble: boolean = false;

  @Output() contentToSend: EventEmitter<string> = new EventEmitter<string>();

  @HostListener("keyup", ["$event"])
  keyEvent(event: any) {
    if (this.enable) {
      this._caretPosition = event.target.selectionStart;
      this.updateExistingMentions();
      this.detectMentionsTag();
    }
  }

  constructor(
    private elemRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef,
    private differ: IterableDiffers,
    private userDataSelector: UserDataSelector,
    private dialog: MatDialog,
    private replyToPipe: ReplyToPipe
  ) {
    this._differ = this.differ.find([]).create(null);
    this.onSendSub = new Subject<void>();
  }

  ngOnInit() {
    this._onSendSubscription = this.onSendSub.subscribe(() => {
      var content: string = this.elemRef.nativeElement.value;
      //start from last, keep position unchanged before replace value
      this._selectedMentions
        .sort((a, b) => {
          if (a.startIndex > b.startIndex) {
            return -1;
          }
          if (a.startIndex < b.startIndex) {
            return 1;
          }
          return 0;
        })
        .forEach(async u => {
          //include self describe tag in msg content to send to server
          var sub = content.substring(u.startIndex, u.endIndex + 1);
          if (sub == u.displayName) {
            var replacement: string = `@{${u.displayName},${u.id}}`;
            content =
              content.substring(0, u.startIndex - 1) +
              replacement +
              content.substring(u.endIndex + 1);
          }
        });
      this.contentToSend.emit(content);
      this._selectedMentions = [];
    });
  }

  ngOnDestroy() {
    this._onSendSubscription.unsubscribe();
    this.hideUsersList();
  }

  ngAfterViewInit() {
    if (this.enable) {
      this.transform();
    }
    this.changeDetectorRef.detectChanges();
  }

  ngDoCheck() {
    if (this.users) {
      const changes: DefaultIterableDiffer<UserMentions> = this._differ.diff(
        this.users
      );

      if (changes) {
        if (this._chatListComponent) {
          this.hideUsersList();
          this.showUsersList();
        }
        this._differ = Object.assign(this._differ, changes);

        this.users = Object.assign([], changes.collection);
      }
    }
    //workaround for e2e, wait for decryption then transform
    var el: HTMLElement = this.elemRef.nativeElement;
    var content: string = el.innerHTML;
    if (this.enable && this._oldContent != content) {
      this.transform();
      this._oldContent = content;
    }
  }

  private updateExistingMentions() {
    var text: string = this.elemRef.nativeElement.value;
    //clear all
    if (text != null && text.trim() === "") {
      this._selectedMentions = [];
    }

    if (this._selectedMentions && this._selectedMentions.length != 0) {
      this._selectedMentions.forEach((m, i) => {
        if (this._caretPosition > m.startIndex) {
          if (this._caretPosition <= m.endIndex) {
            //change of text in user mention name, no longer valid name, remove
            this._selectedMentions.splice(i);
          }
        } else {
          //update position of UserMentions
          m.updateStartIndex(text);
        }
      });
    }
  }

  //#region Tag user
  private detectMentionsTag() {
    var text: string = this.elemRef.nativeElement.value;
    //last character of string is @
    if (text[this._caretPosition - 1] == "@") {
      //show user list when there is space and next line in front of @ or when @ is the first character of the string
      if (/\s/.test(text[this._caretPosition - 2]) || text.length == 1) {
        this.showUsersList();
      }
    } else {
      this.hideUsersList();
    }
  }

  private showUsersList() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      ChatMentionsListComponent
    );
    var existing = document.querySelector(componentFactory.selector);
    if (!existing) {
      var componentRef = this.viewContainerRef.createComponent(
        componentFactory
      );
      var chatMentionsList = (componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;
      var el: HTMLElement = this.elemRef.nativeElement;
      //insert list of users before textarea
      
      // el.parentElement.insertBefore(chatMentionsList, el);
      this._chatListComponent = componentRef;
      // (<ChatMentionsListComponent>componentRef.instance).users = this.users;
      // (<ChatMentionsListComponent>componentRef.instance).selectedUser.subscribe(this.selectUser.bind(this));
      const dialogRef = this.dialog.open(ChatMentionsListComponent, {
        width: "400px",
        height: "auto",
        maxHeight: "300px",
        data: {
          users: this.users
        }
      });

      dialogRef.afterClosed().subscribe((res: UserMentions) => {
        this.selectUser(res);
      });
    }
  }

  private hideUsersList() {
    if (this._chatListComponent) {
      this._chatListComponent.destroy();
      this._chatListComponent = null;
    }
  }

  selectUser = (res: UserMentions) => {
    this.hideUsersList();
    var content: string = this.elemRef.nativeElement.value;
    var index: number = this._caretPosition - 1;
    if (index >= 0 && content[index] == "@") {
      //insert selected user name to content, behind @
      let replacement: string =
        res && res.displayName ? `@${res.displayName} ` : `@${res.id} `;
      content =
        content.substr(0, index) + replacement + content.substr(index + 1);

      //update existing mentions position
      if (this._selectedMentions && this._selectedMentions.length != 0) {
        this._selectedMentions.forEach((m, i) => {
          if (this._caretPosition < m.startIndex) {
            m.moveToRight(res.displayName.length - 1);
          }
        });
      }

      //store position of name in content in obj
      var selected = new UserMentions(res.id, res.displayName);
      selected.startIndex = index + 1;
      this._selectedMentions.push(selected);
      this.elemRef.nativeElement.value = content;
    }
  };

  //#endregion

  //#region Massage and show formatted tag
  transform(): void {
    var el: HTMLElement = this.elemRef.nativeElement;
    if (el.tagName.toLowerCase() == "textarea") {
      return this.reverse();
    }
    var content: string = this.replyToPipe.undoTransform(el.innerHTML);
    const userId = this.userDataSelector.userId;

    if (content && content.trim() != "") {
      const regExp = new RegExp("@{[^}]*}", "gm"); //regExp to match and replace all occurence
      var result = content.match(regExp);
      if (result && result.length != 0) {
        result
          .filter((v, i, a) => a.indexOf(v) === i)
          .forEach(m => {
            var mention = UserMentions.constructFromString(m);
            var formattedTag: string;

            //style user mentions according to userid
            if (mention.id == userId) {
              //bold and set font red
              formattedTag = `<b style="color: red">${mention.displayName}</b>`;
            } else {
              //temporary add div as flag
              formattedTag = `<div class="mentions">${m}</div>`;
            }

            content = content.replace(RegExp(m, "g"), formattedTag);
          });

        this.renderer.setProperty(el, "innerHTML", content);

        //replace ion-text with ChatMentionsOthersComponent
        this.addComponent();
      }
    }
  }

  //for editing text area to show user friendly text
  reverse(){
    var content: string = this.elemRef.nativeElement.value;

    if (content && content.trim() != "") {
      const regExp = new RegExp("@{[^}]*}", "gm"); //regExp to match and replace all occurence
      var result = content.match(regExp);
      if (result && result.length != 0) {
        result.forEach((m) => {
          var mention = UserMentions.constructFromString(m);
          var sameUserMention = this._selectedMentions.filter(
            (i) => i.displayName === mention.displayName && i.id === mention.id
          );
          var lastPosition =
            sameUserMention.length == 0
              ? 0
              : Math.max(...sameUserMention.map((i) => i.endIndex));
          mention.startIndex = content.indexOf(m, lastPosition) + 1;
          this._selectedMentions.push(mention);

          var formattedTag: string = `@${mention.displayName}`;

          content = content.replace(RegExp(m, "g"), formattedTag);
        });

        this.renderer.setProperty(this.elemRef.nativeElement, "value", content);
      }
    }
  }

  addComponent() {
    var el: HTMLElement = this.elemRef.nativeElement;

    var results = el.getElementsByClassName("mentions");
    if (results.length != 0) {
      //results will update once the item is removed, should do it reverse
      //https://stackoverflow.com/questions/13220520/javascript-replace-child-loop-issue
      for (var i = results.length - 1; i > -1; i--) {
        var item = results.item(i);

        var mention = UserMentions.constructFromString(item.innerHTML);
        if (mention) {
          var componentFactory = this.componentFactoryResolver.resolveComponentFactory(
            ChatMentionsOthersComponent
          );
          var componentRef = this.viewContainerRef.createComponent(
            componentFactory
          );
          (<ChatMentionsOthersComponent>componentRef.instance).user = mention;
          (<ChatMentionsOthersComponent>componentRef.instance).isMsgBubble = this.isMsgBubble;

          var otherUserComponent = (componentRef.hostView as EmbeddedViewRef<
            any
          >).rootNodes[0] as HTMLElement;
          el.replaceChild(otherUserComponent, item);
        }
      }
    }
  }
  //#endregion
}

