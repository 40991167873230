import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../../core/services/user.service';
import { FormsModule } from '@angular/forms';
import { TurnstileComponent } from '../../../core/auth/turnstile/turnstile.component';

@Component({
  selector: 'app-forgot-reset',
  templateUrl: './forgot-reset.component.html',
  styleUrls: ['./forgot-reset.component.scss'],
})
export class ForgotResetComponent implements OnInit {
  @ViewChild('elturnstile') turnstileComponent: TurnstileComponent;
  passcode: string;
  password: string = "";
  confirmPassword: string;
  showLoading: boolean;
  showError: boolean;
  errorMsg: string;
  showSuccess: boolean;
  successMsg: string;
  isValidateSuccess: boolean = true;
  public resetPasswordValidationOptions: any;

  showNewPasswordType: string = "password";
  showConfirmPasswordType: string = "password";

  passwordMatch = true;
  confirmpassword: any;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      let token: string = params.token;
      if (token) {
        this.passcode = token;
        this.validateResetPassword(token);
      }
    });
  }

  checkPasswordMatch() {
    if (this.password != null && this.confirmpassword != null) {
      if (this.password.toLowerCase() === this.confirmpassword.toLowerCase()) {
        this.passwordMatch = true;
      } else {
        this.passwordMatch = false;
      }
    }
  }

  initFormRequest() {
    this.showLoading = true;
    this.showError = false;
    this.errorMsg = '';
    this.showSuccess = false;
    this.successMsg = '';
  }

  validateResetPassword(token: string) {
    this.initFormRequest();

    this.userService.validateResetPassword(token).then(res => {
      this.showLoading = false;
      if (res.success) {
        this.isValidateSuccess = true;
      } else {
        this.router.navigate(['/login'], { queryParams: { status: 'expired' } });
        this.showError = true;
        this.errorMsg = res.error;
      }
    })
      .catch(error => {
        console.log(error);
        this.showLoading = false;
        this.showError = true;
        this.errorMsg = error;
      });
  }

  async confirmResetPassword() {
    this.initFormRequest();
    var token = await this.turnstileComponent.getToken();
    this.userService.resetPassword(this.password, this.passcode, token).then(res => {
      this.showLoading = false;
      if (res.success) {
        this.router.navigate(['/login'], { queryParams: { status: 'resetSuccess' } });
      } else {
        this.isValidateSuccess = false;
        this.showError = true;
        this.errorMsg = res.error;
      }
    })
      .catch(error => {
        console.log(error);
        this.showLoading = false;
        this.showError = true;
        this.errorMsg = error;
      });

  }

  toggleNewPassword() {
    if (this.showNewPasswordType == "password") {
      this.showNewPasswordType = "text";
    } else {
      this.showNewPasswordType = "password";
    }
  }

  toggleConfirmPassword() {
    if (this.showConfirmPasswordType == "password") {
      this.showConfirmPasswordType = "text";
    } else {
      this.showConfirmPasswordType = "password";
    }
  }
}
