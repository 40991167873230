import { ComposerComponent } from "./../composer/composer.component";
import { MessageState } from "./../../../core/model/message.state";
import {
  Component,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ParticipantState } from "../../../core/model/participant.state";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { RoomHub } from "../../../core/hub/room.hub";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MessageType } from "../../../core/model/message.model";
import { FileObjState } from "../../../core/model/file-obj.state";
import { Emitter, Emittable } from "@ngxs-labs/emitter";
import { FileState } from "../../../core/states/file.state";
import { MediaService } from '../../../core/services/media.service';

@Component({
  selector: "app-editor",
  templateUrl: "./editor.component.html",
  styleUrls: ["./editor.component.scss"],
})
export class EditorComponent implements OnInit {
  @ViewChild(ComposerComponent)
  private composerComponent: ComposerComponent;

  @Emitter(FileState.addOrUpdateFile)
  addOrUpdateFile: Emittable<FileObjState>;

  //#region Input
  isLoading = false;
  isMeetEnabled: boolean = true;
  isMentionsEnabled: boolean = true;
  participants: ParticipantState[] = [];
  userMatrixId: string;
  message: MessageState;
  //#endregion

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditorComponent>,
    private roomHub: RoomHub,
    public snackBar: MatSnackBar,
    private translateService: TranslateService,
    private mediaService: MediaService
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.message = { ...this.data.message };
      this.userMatrixId = this.data.userMatrixId;
      this.isMeetEnabled = this.data.isMeetEnabled;
      this.isMentionsEnabled = this.data.isMentionsEnabled;
      this.participants = this.data.participants;
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  sendMessage() {
    if (this.composerComponent) {
      this.composerComponent.sendMessage();
    }
  }

  async onMsgSend(data: {
    content: string;
    isEncrypted: boolean;
    msgType: MessageType;
    file?: FileObjState;
    meetingTitle?: string;
    startDate?: Date;
    endDate?: Date;
    recurrence?: string;
  }) {
    this.isLoading = true;

    var msg = { ...this.message };
    // update message content
    msg.type = data.msgType;
    //msg.content = data.content;
    msg.isEncrypted = data.isEncrypted;
    msg.fileFromCloud = data.file?.cloudContainerName;
    // if (msg.plaintext !== data.content) {
    //   msg.plaintext = null;
    // }
    msg.plaintext = data.content;

    if (msg.type == MessageType.Image || data.msgType == MessageType.File) {
      if (
        (data.file || msg.mediaId) &&
        data.file &&
        data.file.id !== msg.mediaId
      ) {
        //changes in file
        msg.mediaName = data.file.name;
        msg.mediaSize = data.file.size;
        msg.mimeType = data.file.mimeType;
        msg.mediaId = data.file.id;
        msg.mediaUrl = data.file.url;

        this.addOrUpdateFile.emit(data.file);
      }
    } else if (msg.type == MessageType.Calendar) {
      msg.meetingTitle = data.meetingTitle;

      msg.startDate = data.startDate;
      msg.endDate = data.endDate;
      msg.recurrence = data.recurrence;
    }

    this.roomHub
      .editMessage(msg)
      .then(() => {
        // this.clearMsgInfo();

        if (this.message.type === MessageType.Image) {
          // delete blob url
          this.mediaService.removeBlobUrl(this.message.id).then(() => {
            this.isLoading = false;
            this.dialogRef.close(true);
          });
        } else {
          this.isLoading = false;
          this.dialogRef.close(true);
        }
      })
      .catch((err) => {
        this.isLoading = false;
        this.openSnackBar(err, this.translateService.instant("REPEAT.OK"));
      });
  }
}
