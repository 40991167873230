import { AsyncStorageEngine } from "@ngxs-labs/async-storage-plugin";
import { Observable, from } from "rxjs";
import { Injectable } from "@angular/core";
import * as localForage from "localforage";

@Injectable({
  providedIn: "root"
})
export class IFStorageEngine implements AsyncStorageEngine {
  constructor() {
    localForage.config({
      name: "__everleaguesdb"
    });
  }

  length(): Observable<number> {
    return from(localForage.length());
  }

  getItem(key: any): Observable<any> {
    //console.error("[IFStorageEngine] getItem: key: %s", key);
    return from(localForage.getItem(key));
  }

  setItem(key: any, val: any): void {
    try {
      //console.error("[IFStorageEngine] setItem: key: %s, val: %o", key, val);
      localForage.setItem(key, val);
    } catch (e) {
      console.error("[IFStorageEngine] %o", e);
    }
  }

  removeItem(key: any): void {
    //console.error("[IFStorageEngine] removeItem: key: %s", key);
    localForage.removeItem(key);
  }

  clear(): void {
    localForage.clear();
  }

  key(val: number): Observable<string> {
    return from(localForage.keys().then(keys => keys[val]));
  }
}
