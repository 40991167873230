import { AuthDataState } from "../model/authData.state";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { AuthState } from "./auth.state";
import { distinctUntilChanged, skip } from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class AuthStateSelector {
  AUTH_TYPE_2FA = "2fa-auth";
  AUTH_TYPE_DEFAULT = "default-auth";
  EL_PLC = 'https://www.everleagues.com/jwt/claims/plc'; // el policy
  EL_2FA = 'https://www.everleagues.com/jwt/claims/2fa'; // 2fa enabled
  EL_EMV = 'https://www.everleagues.com/jwt/claims/emv'; // email verified
  EL_MID = 'https://www.everleagues.com/jwt/claims/mid'; // matrixId
  constructor(public store: Store) { }

  get data(): AuthDataState | null {
    const authData = this.store.selectSnapshot(AuthState.data);
    return authData;
  }

  get accessToken(): string | null {
    const accessToken = this.store.selectSnapshot(AuthState.accessToken);
    return accessToken;
  }

  get isAuthenticated(): boolean {
    let token = this.accessToken;
    // check for token type
    if (token) {
      let tokenDecode = this.parseJwt(token);
      if (tokenDecode && tokenDecode[this.EL_PLC]) {
        let authType = tokenDecode[this.EL_PLC];

        if (authType === this.AUTH_TYPE_DEFAULT) {
          return true;
        } else {
          console.log('AuthType ' + authType + ' detected.');
        }
      }
    }
    return false;
  }

  get isVerified(): boolean {
    let auth = this.isAuthenticated;
    if (auth) {
      let tokenDecode = this.parseJwt(this.data.accessToken);
      if (tokenDecode && tokenDecode[this.EL_EMV]) {
        let isEmailVerified = this.stringToBoolean(tokenDecode[this.EL_EMV]);

        if (isEmailVerified) {
          return true;
        } else {
          console.log('Email not verified');
        }
      }
    }
    return false;
  }

  get isRefreshingToken() {
    const isRefresh = this.store.selectSnapshot(AuthState.isRefreshingToken);
    return isRefresh;
  }

  get hasInitToken() {
    return this.store.selectSnapshot(AuthState.hasInitToken);
  }

  private parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
  };

  private stringToBoolean(str) {
    if (str) {
      if (typeof str === "boolean") {
        return str
      }
      switch (str.toLowerCase().trim()) {
        case "true": case "yes": case "1": return true;
        case "false": case "no": case "0": case null: return false;
        default: return Boolean(str);
      }
    }
    return false;
  }

  onAccessTokenChanged(): Observable<string> {
    return this.store.select(AuthState.accessToken).pipe(
      skip(1),
      distinctUntilChanged((prev, curr) => prev !== null && prev === curr)
    );
  }
}
