import { AuthService } from './../../core/auth/auth.service';
import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { routerAnimation } from '../../utils/page.animation';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { DialogMultiTabComponent } from './dialog/dialog-multi-tab.component';
import { Router } from '@angular/router';
import { BroadcastChannel as BC } from 'broadcast-channel';
import { PluginManager } from '../../tools/plugin.manager';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { UIState } from '../../core/states/ui.state';

@Component({
  selector: 'app-blank-page',
  templateUrl: './blank-page.component.html',
  styleUrls: ['./blank-page.component.scss'],
  animations: [routerAnimation]
})
export class BlankPageComponent implements OnInit, OnDestroy {

  @Emitter(UIState.setUIReady)
  setUIReady: Emittable<void>;

  @Emitter(UIState.setUIAwaitingResponse)
  setUIAwaitingResponse: Emittable<void>;

  // Add router animation
  @HostBinding('@routerAnimation') routerAnimation = true;
  channel = new BC('elWebApp');
  meetChannel = new BC('elMeet');
  constructor(private dialog: MatDialog, private router: Router,
    private authService: AuthService, private pluginManager: PluginManager) { }

  ngOnInit() {
    this.setUIAwaitingResponse.emit(null);

    this.stopPluginManager();
    this.initBroadcastChannel();
    this.openMultiTabDialog();
  }

  stopPluginManager() {
    this.pluginManager.stop();
  }

  openMultiTabDialog() {
    const dialogRef = this.dialog.open(DialogMultiTabComponent, {
      disableClose: true,
      width: '400px'
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === "use-tab") {
        this.channel.postMessage('block'); // block the active tab
        this.pluginManager.load();
        this.setUIReady.emit(null);
        this.router.navigateByUrl("/main");
      } else if (res === 'use-meet-tab') {
        this.meetChannel.postMessage('block');
        this.pluginManager.load();
        this.setUIReady.emit(null);
        this.router.navigateByUrl('/meet');
      }
    })
  }

  private initBroadcastChannel() {
    // communicated with other tab
    this.channel.addEventListener('message', (msg) => {
      if (msg === 'logout') {
        // if other tabs logged out, log out all tabs
        this.authService.logout();
        this.dialog.closeAll();
      }
    });
  }

  ngOnDestroy() {
    this.channel.close();
    this.meetChannel.close();
  }
}
