import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { CountdownComponent, CountdownConfig, CountdownEvent } from "ngx-countdown";

@Component({
  selector: "app-dialog-countdown",
  templateUrl: "./dialog-countdown.component.html",
  styleUrls: ["./dialog-countdown.component.scss"],
})
export class DialogCountdownComponent implements OnInit {
  // This dialog closes itself after the timer runs out or the user selects the button.
  // You can listen for the dialogRef.afterClosed() and run an action.
  // See openCountdownDialog() for example.
  @Input() title: string;
  @Input() content: string;
  @Input() countdownDescription: string; // e.g.: "You will be redirected in: "
  @Input() action: string; // text on button
  @Input() secondsLeft: number; // set timer
  
  // countdown timer
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  countdownConfig: CountdownConfig = { leftTime: 360, notify: 1 };

  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogCountdownComponent>) {
    dialogRef.disableClose = true; // stops user from dismissing the dialog via esc key or clicking outside the dialog
  }

  ngOnInit() {
    this.title = this.data.title;
    this.content = this.data.content;
    this.countdownDescription = this.data.countdownDescription;
    this.action = this.data.action;
    this.secondsLeft = this.data.secondsLeft;
    // configure timer
    this.countdownConfig.leftTime = this.secondsLeft;
    this.countdownConfig.notify = 1;
  }

  countdownEvent(e: CountdownEvent) {
    if (e.action === 'done') {
      this.dialogRef.close(true);
    }
  }
}
