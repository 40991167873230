import { DialogCsvInviteComponent } from './user-table/dialog/dialog-csv-invite/dialog-csv-invite.component';
import { PendingJoinUserTableComponent } from './pending-join-user-table/pending-join-user-table.component';
import { InvitedUserTableComponent } from './invited-user-table/invited-user-table.component';
import { MembershipReqState, OrgRoleState } from './../../core/model/org.state';
import { Component, OnInit, HostBinding, ViewChild, OnDestroy, Injectable } from '@angular/core';
import { routerAnimation } from '../../utils/page.animation';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { UserTableComponent } from './user-table/user-table.component';
import { DialogAddUserComponent } from './user-table/dialog/dialog-add-user.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { EnterpriseState } from '../../core/states/enterprise.state';
import { Observable } from 'rxjs';
import { OrgUserState, OUState } from '../../core/model/org.state';
import { SubSink } from 'subsink';
import { UserDataSelector } from '../../core/states/user-data.state.selector';
import { RoleEntity } from '../../core/model/userRole.model';
import { TranslateService } from '@ngx-translate/core';
import { DialogJoinReqLinkComponent } from './pending-join-user-table/dialog/dialog-join-req-link.component';
import { OrgHub } from '../../core/hub/org.hub';
import { DialogInvitationMethodComponent } from './user-table/dialog/dialog-invitation-method/dialog-invitation-method.component';

declare var $: any;
@Component({
  selector: "app-org-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
  animations: [routerAnimation],
})
@Injectable({
  providedIn: "root",
})
export class OrgUserComponent implements OnInit, OnDestroy {
  // Add router animation
  @HostBinding("@routerAnimation") routerAnimation = true;
  @ViewChild("userTable", { static: true }) userTable: UserTableComponent;
  @ViewChild("invitedUserTable", { static: true })
  invitedUserTable: InvitedUserTableComponent;
  @ViewChild("pendingJoinUserTable", { static: true })
  pendingJoinUserTable: PendingJoinUserTableComponent;

  //#region ngxs
  @Select(EnterpriseState.managedOrgUsers)
  currentOrgUsers$: Observable<OrgUserState[]>;

  @Select(EnterpriseState.currentOrgOUs)
  currentOrgOUs$: Observable<OUState[]>;

  @Select(EnterpriseState.currentPendingJoinReqs)
  currentPendingJoinReqs$: Observable<MembershipReqState[]>;

  @Select(EnterpriseState.currentInvitationReqs)
  currentInvitationReqs$: Observable<MembershipReqState[]>;

  @Select(EnterpriseState.currentOrgRoles)
  currentRoles$: Observable<OrgRoleState[]>;

  //#endregion

  //#region states
  users: OrgUserState[] = [];
  currentOus: OUState[] = [];
  userId: string;
  pendingJoinReqs: MembershipReqState[] = [];
  invitedReqs: MembershipReqState[] = [];
  roleList: OrgRoleState[] = [];
  //#endregion

  // selected filtered org
  selectedOrgId: string;

  selectedIndex: number = 0;

  private _subSink: SubSink;

  get currentUser(): OrgUserState {
    return this.users.find((x) => x.userId === this.userId);
  }

  get isAdmin(): boolean {
    if (!this.currentUser) return false;
    if (
      this.currentUser.role === RoleEntity[RoleEntity.OWNER] ||
      this.currentUser.role === RoleEntity[RoleEntity.ADMIN]
    )
      return true;
    else return false;
  }

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private router: Router,
    private userDataSelector: UserDataSelector,
    private translateService: TranslateService,
    private orgHub: OrgHub,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this._subSink = new SubSink();
    this.userId = this.userDataSelector.userId;

    this._subSink.sink = this.currentOrgOUs$.subscribe((ous) => {
      this.currentOus = ous;
    });

    //subscribe to get all roles
    this._subSink.sink = this.currentRoles$.subscribe((res) => {
      this.roleList = res;
    });

    this._subSink.sink = this.currentOrgUsers$.subscribe((users) => {
      this.users = users;
    });

    this._subSink.sink = this.currentPendingJoinReqs$.subscribe((reqs) => {
      this.pendingJoinReqs = reqs;
    });

    this._subSink.sink = this.currentInvitationReqs$.subscribe((reqs) => {
      this.invitedReqs = reqs;
    });

    this._subSink.sink = this.route.queryParams.subscribe((qParams) => {
      let reqId = qParams["reqId"];
      if (reqId) this.selectedIndex = 2;
    });

    this.orgHub.getMembershipReq();
  }

  invite() {
    const dialogRef = this.dialog.open(DialogInvitationMethodComponent, {
      width: "450px",
      panelClass: "border-dialog",
    });

    this.router.events.subscribe(() => {
      dialogRef.close();
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res === "single-invite") {
        this.addUser();
      } else if (res === "csv-invite") {
        this.inviteFromCSVFile();
      }
    });
  }

  addUser() {
    // set defaultOU selection in add dialog
    let currentSelectedOu = this.currentOus.find(
      (x) => x.id === this.selectedOrgId
    );
    if (!currentSelectedOu) {
      currentSelectedOu = this.currentOus.find((x) => x.isDefault);
    }

    const dialogRef = this.dialog.open(DialogAddUserComponent, {
      width: '400px', height: '680px',
      panelClass: "add-user-border-dialog",
      disableClose: true,
      autoFocus: false,
      data: {
        selectedOu: currentSelectedOu,
        orgUsers: this.users,
        currentUser: this.currentUser,
        ous: this.currentOus,
      },
    });
    this.router.events.subscribe(() => {
      dialogRef.close();
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.status === 'success') {
        this.openSnackBar(
          this.translateService.instant("FRIEND.SUCC_INVITED_USER"),
          this.translateService.instant("REPEAT.OK")
        );
      } else if (res) {
        // error
        this.openSnackBar(res, this.translateService.instant("REPEAT.OK"));
      }
    });
  }

  inviteFromCSVFile() {
    const dialogRef = this.dialog.open(DialogCsvInviteComponent, {
      width: "450px",
      panelClass: "border-dialog"
    })
  }

  selectOU(ou: any) {
    if (ou) {
      let ouId: string = ou.ouId;
      this.selectedOrgId = ouId;

      this.userTable.applyOrgFilter(ouId);
    } else {
      this.userTable.applyOrgFilter(null);
    }
  }

  showDeletedUser($event) {
    this.userTable.showDeletedUser($event.checked);
  }

  redirectOrgUnit() {
    this.router.navigate(["./main/org/ou"]);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  ngOnDestroy() {
    if (this._subSink) this._subSink.unsubscribe();
  }

  showInvitationLink() {
    const dialogRef = this.dialog.open(DialogJoinReqLinkComponent, {
      width: "400px", height: '430px',
      panelClass: "border-dialog",
      // data: {
      //   ou: this.ous.find(x => x.id === row.ouId),
      //   selectedUser: selectedUser
      // }
    });
  }

  downloadCsvFromUserTable() {
    this.userTable.prepareAndExportCsv();
  }
}
