import { EntityStatus } from '../enum/entity-status.enum';
import { SubscriptionStatus } from '../enum/subscription-status.enum';
import { Plan } from './plan.model';

export class Subscription {
    entityStatus: EntityStatus;
    isValid: boolean;
    members: any[];
    organizationId: string;
    statusName: string;
    subscriptionId: string;
    planType: PlanType;
    planTypeName: string;
    totalAvailableInternalSubs: number;
    totalAvailableExternalSubs: number;
    internalQuantity: number;
    externalQuantity: number;
    validUntil: Date;
    currentPeriodStart: Date;
    cancelAtPeriodEnd: boolean;
    invoices: any[];
    status: SubscriptionStatus;
    internalUsers: any[] = [];
    externalUsers: any[] = [];
    plans: Plan[];
}

export enum PlanType {
    Free = 0,
    Standard = 1,
    Premium = 2,
    ELNet = 3,
    Basic = 4,
    EZSign = 5,
    USTax = 6,
    Unknown = 9
}
