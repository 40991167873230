import { AccessPermission, AccessRight, ExternalAccessRight } from "./cloud-access-right";
export abstract class Resource {
    // static readonly SHARED_SCOPE: string = 'SHARED';
    // static readonly SHARED_ORIGIN_SCOPE: string = 'SHARED-ORIGIN';
    container: string;
    path: string;
    description: string;
    enableNotifications: boolean = false;
    ownerId: string;
    private _name: string;
    private _icon: string;
    get name(): string {
        this._name = this._name.replace(/&amp;/g, "&");
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }
    grantees: AccessRight[];
    externalGrantees: ExternalAccessRight[] = [];

    createdOn: number;
    modifiedOn: number;
    resourceType: string; // 'file' or 'folder'
    // scope: string;

    get icon(): string {
        return this._icon;
    }

    set icon(value: string) {
        this._icon = value;
    }

    constructor() {
        this.container = "";
        this.path = "";
        this.description = "";
        this.grantees = [];
    }

    get isFile(): boolean {
        return this.resourceType === "file";
    }

    get isFolder(): boolean {
        return this.resourceType === "folder";
    }

    get isShared(): boolean {
        return this.grantees && this.grantees.filter(g=>g.isSharedAccess).length > 0;
        // if (this.isFile && !this.path.startsWith("_default")) return this.grantees && this.grantees.length > 1; //exluding file owner
        // return this.grantees && this.grantees.length > 2; //exluding file owner and org grantee, for personal org, both file and folder has file owner and org grantee
    }
    protected _contentType: string;
    get contentType(): string {
        return this._contentType;
    }

    set contentType(value: string) {
        this._contentType = value;
    }
    get nameWithoutExt(): string{
        return Resource.getNameWithoutExt(this.name);
    }

    // get isSharedScope(): boolean {
    //     return this.scope == Resource.SHARED_SCOPE;
    // }

    // get isSharedOriginScope(): boolean {
    //     return this.scope == Resource.SHARED_ORIGIN_SCOPE;
    // }

    get parentPath(): string{
        return Resource.getDirectoryName(this.path);
    }
    // get isShared(): boolean {
    //     return this.scope == "SHARED";
    // }
    hasPermission(userId: string, permission: AccessPermission, folderContentOnly: boolean = false, sharePermission: boolean = false){
        var grantees = this.grantees;
        if (!grantees || grantees.length == 0) return false;
        var currentGrantee = grantees
          .filter((g) => !sharePermission || g.isSharedAccess)
          .find((g) => g.identity == userId);
        if (!currentGrantee) return false;
        return (
          (!folderContentOnly && (currentGrantee.permission & permission) != 0) 
          || currentGrantee.permission == permission
        );
    }

    hasPath(path: string, exactPath: boolean = false){
        return this.path == path;
    }

    hasExactPath(path: string): boolean {
        return this.hasPath(path);
    }
    
    isSameResource(resource: Resource){
        return this.hasPath(resource.path);
    }

    static isFolder(dto: any): boolean {
        return !!dto && dto.resourceType === 'folder'
    }

    static isFile(dto: any): boolean {
        return !!dto && dto.resourceType === 'file'
    }
    
    static getNameWithoutExt(name: string): string{
        if (name == null || name.trim() == "") return "";
        var split = name.split(".");
        if (split.length == 1) return name; //no ext to remove
        return split.slice(0, split.length - 1).join(".");    
    }

    static getDirectoryName(filePath: string): string {
        let lastSlashIndex = filePath.lastIndexOf("/");
        if (lastSlashIndex === -1) {
          // No slash found, return the original string
          return null;
        } else {
          // Slice the string to remove the filename and return the directory name
          return filePath.slice(0, lastSlashIndex);
        }
      }
      
    protected readableContentType = {
        "application/pdf": "PDF Document",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "Microsoft Word Document",
        "application/msword": "Microsoft Word Document",
        "application/vnd.ms-excel": "Microsoft Excel Worksheet",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "Microsoft Excel Worksheet",
        "application/vnd.ms-powerpoint": "Microsoft PowerPoint Presentation",
        "application/vnd.openxmlformats-officedocument.presentationml.presentation": "Microsoft PowerPoint Presentation",
        "text/csv": "CSV File",
        "text/plain": "Text Document",
        "image/jpeg": "JPEG File",
        "image/gif": "GIF File",
        "image/png": "PNG File",
        "image/bmp": "BMP File",
        "video/mp4": "MP4 File",
        "video/x-msvideo": "AVI File",
        "audio/mpeg": "MP3 File",
        "application/vnd.americandynamics.acc": "ACC File",
        "application/x-rar-compressed": "RAR File",
        "application/zip": "Compressed (zipped) Folder",
        "application/vnd.google-apps.document": "Microsoft Word Document",
        "application/vnd.google-apps.presentation": "Microsoft PowerPoint Presentation",
        "application/vnd.google-apps.spreadsheet": "Microsoft Excel Worksheet",

    };

    protected capitalize(s: string): string {
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    static getType(ext: string): string {
        if (ext == null) return "file-o";
        var extArr = ext.toLocaleUpperCase().split('.');
        switch (extArr[extArr.length - 1]) {
            case "PDF":
                return "file-pdf-o";
            case "DOC":
            case "DOCX":
                return "file-word-o";
            case "XLS":
            case "XLSX":
            case "CSV":
                return "file-excel-o";
            case "TXT":
                return "file-text-o";
            case "JPG":
            case "JPEG":
            case "GIF":
            case "PNG":
            case "BMP":
                return "file-photo-o";
            case "MP4":
            case "MOV":
            case "AVI":
                return "file-movie-o";
            case "MP3":
            case "ACC":
                return "file-sound-o";
            case "RAR":
            case "ZIP":
                return "file-zip-o";
            default:
                return "file-o";
        }
    }
}