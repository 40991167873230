import { Injectable, Injector } from "@angular/core";
import { IFeedRedirectHandler } from "./IFeedRedirectHandler";
import { EventModule, NotificationEventType } from "../enum/notification-event-type.enum";
import { Feed } from "../model/feed";
import { EnterpriseSelector } from "../states/enterprise.state.selector";
import { Router } from "@angular/router";
import { FeedService } from "../services/feed.service";

@Injectable()
export class ELRedirectHandler implements IFeedRedirectHandler {
    private enterpriseSelector: EnterpriseSelector;
    private router: Router;
    private feedService: FeedService;

    constructor(private injector: Injector) {
        if (!this.router) {
            this.router = this.injector.get(Router);
        }

        if (!this.enterpriseSelector) {
            this.enterpriseSelector = this.injector.get(EnterpriseSelector);
        }

        if (!this.feedService) {
          this.feedService = this.injector.get(FeedService);
        }
    }

    handle(feed: Feed): Promise<boolean> {
        this.feedService.updateFeedStatus(feed);
        const orgId = feed.orgId;

        if (feed.event === NotificationEventType.NEW_POST ||
            feed.event === NotificationEventType.NEW_COMMENT) {
            const channelId = feed.data.channelId;
            const postId = feed.data.postId;
            let url = "/main/teams/" + channelId + "/post/" + postId;
            if (feed.title === "Personal Space") {
              url = "/main/teams/feed/" + postId;
            }
            if (this.enterpriseSelector.isCurrentOrg(orgId)) {
              this.router.navigate([url]);
            }
          } else if (feed.event === NotificationEventType.NEW_MESSAGE) {
            console.log("REDRIECT TO RROOM %o", feed);
            const roomMatrixId = feed.data.roomMatrixId;
            const msgId = feed.data.msgId;
            const url = "/main/chat/" + roomMatrixId;
      
            if (this.enterpriseSelector.isCurrentOrg(orgId)) {
              this.router.navigate([url], { queryParams: { msgId: msgId } });
            }
          } else if (feed.event === NotificationEventType.SHARE_FOLDER || feed.event === NotificationEventType.SHARE_FILE) {
            const url = "/main/storage/";
            const source = feed.data.storageSource;
            const storageType = source === "shared-with-me" ? "shared" : source === "my-drive" ? "local" : "drive";

            // const item  = feed.data.item;
            const isFolder = feed.data.isFolder;
            
            const originPath = feed.data.originPath; //_default/
            const originContainer = feed.data.originContainer;
            const path = feed.data.path; //_shared/
            const name = feed.data.itemName; 
      
            console.log("/main/storage", feed.data)
    
            
            if (this.enterpriseSelector.isCurrentOrg(orgId)) {
              this.router.navigate([url + storageType], { queryParams: { redirectOriginPath: originPath, redirectOriginContainer: originContainer, redirectPath: path, isFolder: isFolder, itemName: name } });
            } 
          } else if (feed.event == NotificationEventType.EXPORT_CHAT_HISTORY_SUCCESS
            || (feed.module == EventModule.STORAGE 
            && !(feed.event === NotificationEventType.SHARE_FOLDER || 
              feed.event === NotificationEventType.SHARE_FILE || 
              feed.event === NotificationEventType.DELETE_FILE || 
              feed.event === NotificationEventType.DELETE_FOLDER ||
              feed.event === NotificationEventType.UPLOAD_FOLDER_FAILED))) {
            // isStorageOperation not evaluating, so manually do so here
              const url = "/main/storage/";
            const source = feed.data.storageSource;
            const storageType = source === "shared-with-me" ? "shared" : source === "my-drive" ? "local" : "drive";

            const originPath = feed.data.resPath
            const originContainer = feed.data.resContainer;
            const path = feed.data.resPath;
            const name = feed.data.itemName;
            const isFolder = feed.data.isResFolder;

            if (this.enterpriseSelector.isCurrentOrg(orgId)) {
              const folderPath = isFolder ? originPath : originPath.substring(0, originPath.lastIndexOf('/'));
              this.router.navigate([url + storageType], { queryParams: { folderPath: folderPath, redirectOriginPath: originPath, redirectOriginContainer: originContainer, redirectPath: path, isFolder: isFolder, itemName: name } });
            } 

          } else if (feed.event === NotificationEventType.NEW_MEET) {
            const roomMatrixId = feed.data.roomMatrixId;
            const meetUrl = feed.data.meetUrl;
            const url = "/main/chat/" + roomMatrixId + "/meet/" + encodeURIComponent(meetUrl);
      
            if (this.enterpriseSelector.isCurrentOrg(orgId)) {
              this.router.navigate([url]);
            } 
          } else if (feed.event === NotificationEventType.JOIN_REQ_APPROVED) {
            const url = "/main/dashboard/";
      
            if (this.enterpriseSelector.isCurrentOrg(orgId)) {
              console.log("[MainPage] going dashboard from JOIN_REQ_APPROVED");
              this.router.navigate([url]);
            } 
          } else if (feed.event === NotificationEventType.JOIN_REQ_RECEIVED) {
            const url = feed.data.isPersonalSpace == "True" ? "main/friends" : "main/org/user";
            let qParams = { queryParams: { reqId: feed.data.reqId } };
      
            if (this.enterpriseSelector.isCurrentOrg(orgId)) {        
              this.router.navigate([url], qParams);
            }
          } else if (feed.event === NotificationEventType.NEW_ASSOCIATE
                    || feed.event === NotificationEventType.NEW_CONTACT
                    || feed.event === NotificationEventType.NEW_GROUPMEMBER
                    || feed.event === NotificationEventType.NEW_STAFF){
            const contact = feed.data.contactId;
            const url = "main/directory/connections/" + contact;

            if (this.enterpriseSelector.isCurrentOrg(orgId)) {
              this.router.navigate([url]);
            } 
          }

          

          return Promise.resolve(true);
    }
    
}