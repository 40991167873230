import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Location } from '@angular/common';
import { CloseService } from '../close.service';
import { Subscription } from 'rxjs';
import { AfterContentInit, Component, ContentChildren, Input, OnDestroy, OnInit, QueryList, Output } from '@angular/core';
import { SideItemButton, SideItemButtonAction } from '../side-item-button';
import { DialogAddChannelComponent } from '../../organization/team/team-table/dialog/dialog-add-channel.component';
import { UIService } from '../../core/services/ui.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'px-gdn-sideitem',
  templateUrl: './sideitem.component.html',
  styleUrls: ['./sideitem.component.scss'],
  animations: [
    trigger('closeState', [
      state('close', style({
        height: '0'
      })),
      state('open', style({
        height: '*'
      })),
      transition('close => open', animate('250ms ease-in')),
      transition('open => close', animate('250ms ease-out'))
    ])]
})
export class SideitemComponent implements OnInit, OnDestroy, AfterContentInit {

  @Input() title: string;
  @Input() closeState = 'close';
  @Input() routerLinkUrl: string;
  @Input() loadingIcon: false;
  @Input() level = -1; // manually set menuLevel if dynamic async content is used
  @Input() button: SideItemButton;
  @Input() teamChannels: string[] = [];

  isActive: boolean = false;
  menuLevel = -1;
  @ContentChildren(SideitemComponent, { descendants: true }) contentChildren: QueryList<SideitemComponent>;

  constructor(private closeServiceService: CloseService, public location: Location,
    public dialog: MatDialog, public snackBar: MatSnackBar, private uiService: UIService, private router: Router) {
  }

  ngOnInit() {
  }

  ngAfterContentInit(): void {
    if (this.level === -1) {
      this.contentChildren.forEach((item) => {
        item.incrementMenuLEvel();
      });
    }
    this.resetTeamChannelActiveIndicator();
    this.router.events.subscribe((event) => {
      this.resetTeamChannelActiveIndicator();
    });
  }


  ngOnDestroy(): void {
  }

  resetTeamChannelActiveIndicator(): void {
    if (this.teamChannels.length > 0 &&
      this.router.url.startsWith("/main/teams/")) {
      let channelId = this.router.url.split("/")[3];
      if (channelId && this.teamChannels.includes(channelId)) {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    } else {
      this.isActive = false;
    }
  }

  toggleState(hasChildren: boolean) {
    if (hasChildren) {
      if (this.closeState === 'close') {
        this.closeServiceService.inform(this.menuLevel);
      }
      this.closeState = this.closeState === 'close' ? 'open' : 'close';
    }
  }

  incrementMenuLEvel() {
    this.menuLevel++;
  }

  clickButton() {
    if (this.button.action === SideItemButtonAction.AddChannel) {
      // open Add Channel Dialog
      if (this.button.args) {
        const dialogRef = this.dialog.open(DialogAddChannelComponent, {
          width: "25vw", height: '380px',
          panelClass: "border-dialog",
          data: {
            team: this.button.args.team
          }
        });
        dialogRef.afterClosed().subscribe(res => {
          if (res && res.status === 'success') {
            this.uiService.onChannelChanged.next();
            this.openSnackBar('Successfully added channel to team', 'OK');
            // refresh if true
          } else if (res && res.error) {
            this.openSnackBar(res.error, 'OK');
          }
        });
      }
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000
    });
  }
}
