import { Component, OnInit, Inject } from '@angular/core';
import { LocalStorageService } from '../../core/localStorage/local-storage.service';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InvitationService } from '../../core/services/invitation.service'
import { SelectOU } from '../../core/model/select-ou.model';
import { OUService } from '../../core/services/ou.service';
import { forkJoin } from 'rxjs';
import { EnterpriseSelector } from '../../core/states/enterprise.state.selector';
import { OrgRoleState, OUState, OrgState } from '../../core/model/org.state';
import { RoleEntity } from '../../core/model/userRole.model';
import { MembershipReqService } from '../../core/services/membership-req.service';
import { UserDataSelector } from '../../core/states/user-data.state.selector';

@Component({
  selector: 'app-dialog-add-friend',
  templateUrl: './dialog-add-friend.component.html',
  styleUrls: ['./dialog-add-friend.component.scss']
})
export class DialogAddFriendComponent implements OnInit {
  firstName: any;
  lastName: any;
  email: any;
  role: any;
  selectOU: SelectOU = new SelectOU();
  isLoading = false;

  // saved values
  roles: OrgRoleState[];
  personalOrg: OrgState;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogAddFriendComponent>, public dialog: MatDialog,
    private enterpriseSelector: EnterpriseSelector, private membershipReqService: MembershipReqService) { }

  ngOnInit() {
    this.personalOrg = this.data.personalOrg;

    this.roles = this.enterpriseSelector.getOrgRoles(this.personalOrg.id).filter(x =>
      RoleEntity[x.normalizedName] !== RoleEntity.OWNER && RoleEntity[x.normalizedName] !== RoleEntity.ADMIN);
    this.role = this.roles[0]

    if (this.personalOrg) {
      this.selectOU.ouId = this.personalOrg.id;
      this.selectOU.ouName = this.personalOrg.name;
    }
  }


  addMember() {
    this.isLoading = true;
    this.membershipReqService
      .issueInvitation(
        this.email,
        this.firstName,
        this.lastName,
        this.role.id,
        this.personalOrg.id,
        this.personalOrg.id,
        [],
      )
      .then((res) => {
        this.isLoading = false;
        if (!res) {
          this.dialogRef.close(res);
        } else {
          this.dialogRef.close("success");
        }
      })
      .catch((err) => {
        this.isLoading = false;
        this.dialogRef.close(err);
      });
  }
}
