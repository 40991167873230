import { DialogConfirmComponent } from "./../ui/dialog/dialog-confirm/dialog-confirm.component";
import { AppStateSelector } from "./../states/app.state.selector";
import { MeetService } from "./meet.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UIStateSelector } from "./../states/ui.state.selector";
import { EnterpriseSelector } from "./../states/enterprise.state.selector";
import { DialogSwitchOUComponent } from "../ui/dialog/switch-ou/dialog-switch-ou.component";
import { Router } from "@angular/router";
import { UserMentions } from "../model/user-mentions";
import { NotificationData } from "../model/notification-data.model";
import { Injectable } from "@angular/core";
import { NotificationEventType } from "../enum/notification-event-type.enum";
import { PluginManager } from "../../tools/plugin.manager";
import * as _ from "lodash";
import { TranslateService } from "@ngx-translate/core";
import { HttpErrorResponse } from "@angular/common/http";
import { EnterpriseState } from "../states/enterprise.state";
@Injectable()
export class NotificationService {

  //temporary solution for sys redirection fix which redirects to dashboard pg due to onLaunchRedirect
  isRedirecting: boolean = false;  

  constructor(
    private enterpriseSelector: EnterpriseSelector,
    private uiStateSelector: UIStateSelector,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
    private meetService: MeetService,
    private translateService: TranslateService,
    private appSelector: AppStateSelector,
    private pluginManager: PluginManager,
  ) { }

  handleNotificationHubEvent(notification: NotificationData) {
    // if (
    //   notification.data.isEncrypted &&
    //   notification.data.type === MessageType.Text
    // ) {
    //   const decrypted = this.fcp.decryptMsg(notification.body);
    //   notification.body = this.transformChatMention(decrypted);
    // }

    if (this.appSelector.isBackground) {
      //display system notification
      var systemNotify = new Notification(notification.title, {
        body: notification.body,
        data: notification,
      });

      systemNotify.addEventListener("click", () => {
        console.log("on click", notification);
        this.notificationRedirection(
          notification.eventType,
          notification.body,
          notification.title,
          notification.orgId,
          notification.data,
          false
        );
        window.focus();
      });
    } else {
      console.log("on foreground");
      this.showToastNotification(
        notification.eventType,
        notification.body,
        notification.title,
        notification.orgId,
        notification.data
      );
      // this.notificationRedirection(
      //   notification.eventType,
      //   notification.body,
      //   notification.title,
      //   notification.orgId,
      //   notification.data
      // );
    }
  }

  async showToastNotification(
    eventType: string,
    body: string,
    title: string,
    orgId: string,
    data: any
  ) {
    if (!data) return;
    if (body === null) return;

    if (eventType == NotificationEventType.JOIN_REQ_APPROVED) {
      let currOrg = this.enterpriseSelector.getCurrentOrg();
      if (EnterpriseState.isSameOrg(currOrg, orgId)) {
        await this.showSnackBar(body, "OK");
      } 
    }
    
  }

  async notificationRedirection(
    eventType: string,
    body: string,
    title: string,
    orgId: string,
    data: any,
    showSnackBar: boolean = true
  ) {
    if (!data) return;
    if (body === null) return;
    //console.log("showSnackBar %o", true);
    //console.log(eventType, data);


    let roomMatrixId: string = data.roomMatrixId,
      meetUrl: string = data.meetUrl,
      msgId: string = data.msgId,
      channelId: string = data.channelId,
      postId: string = data.postId;

    try {
      switch (eventType) {
        case NotificationEventType.NEW_MEET:
          {
            if (!roomMatrixId) return;
            // dont run if user is currently in room
            if (roomMatrixId !== this.uiStateSelector.activeRoom) {
              // if (showSnackBar) {
              //   await this.showSnackBar(body, "JOIN");
              // }
              if (!this.enterpriseSelector.isCurrentOrg(orgId)) {
                await this.showSwitchOrg(orgId);
              }
              if (meetUrl) {
                meetUrl = this.meetService.forceHttps(meetUrl);
                this.meetService.joinMeet(meetUrl).then((res) => {
                  console.log("join meet");
                  console.log(res);
                  let url = this.meetService.getHostName(res.url);
                  const jwt = res.accessToken;
                  const room = res.room;
                  const roomId = roomMatrixId;

                  var w = window.open("/meet", "_blank");
                  w["meetReq"] = {
                    url: url,
                    accessToken: jwt,
                    room: room,
                    roomId: roomId,
                  };
                }, 
                (res) => { this.joinMeetOnRejected(res); });
              }
            }
          }
          break;

        case NotificationEventType.NEW_MESSAGE:
          {
            if (!roomMatrixId) return;
            // dont run if user is currently in room
            if (roomMatrixId !== this.uiStateSelector.activeRoom) {
              // if (showSnackBar) {
              //   await this.showSnackBar(title + ": " + body);
              // }
              if (!this.enterpriseSelector.isCurrentOrg(orgId)) {
                await this.showSwitchOrg(orgId);
              }
              this.router.navigate(["/main/chat/" + roomMatrixId], {
                queryParams: { msgId: msgId },
              });
            }
          }
          break;
        case NotificationEventType.ORG_SUSPENDED:
          {
            // if (showSnackBar) {
            //   await this.showSnackBar(title + ": " + body);
            // }
            const dialogRef = this.dialog.open(DialogConfirmComponent, {
              width: "400px",
              data: {
                title: title,
                content: body,
              },
            });
          }
          break;
        case NotificationEventType.ORG_REACTIVATED:
          {
            // if (showSnackBar) {
            //   await this.showSnackBar(title + ": " + body);
            // }
            const dialogRef = this.dialog.open(DialogConfirmComponent, {
              width: "400px",
              data: {
                title: title,
                content: body,
              },
            });
          }
          break;
        case NotificationEventType.NEW_POST:
        case NotificationEventType.NEW_COMMENT:
          {
            if (!channelId || !postId) return;
            // dont run if user is currently in channel/post
            if (channelId !== this.uiStateSelector.activeChannel) {
              // if (showSnackBar) {
              //   await this.showSnackBar(title + ": " + body);
              // }
              if (!this.enterpriseSelector.isCurrentOrg(orgId)) {
                await this.showSwitchOrg(orgId);
              }
              this.router.navigateByUrl(
                "/main/teams/" + channelId + "/post/" + postId
              );
            }
          }
          break;
      case NotificationEventType.JOIN_REQ_APPROVED:
        {
          if (!this.enterpriseSelector.isCurrentOrg(orgId)) {
            if (showSnackBar) {
              await this.showSnackBar(body, "OPEN");
            }
            await this.showSwitchOrg(orgId);
          }
        }
        break;
      case NotificationEventType.SHARE_FOLDER:
      case NotificationEventType.SHARE_FILE:
        {
          console.log("case NotificationEventType.NEW_SHARED:")
          const url = "/main/storage/";
          const source = data.storageSource;
          const storageType = source === "shared-with-me" ? "shared" : source === "my-drive" ? "local" : "drive";

          const originPath = data.originPath;
          const originContainer = data.originContainer;
          const path = data.path;
          const isFolder = data.isFolder;
          const name = data.itemName;

          if (showSnackBar) {
            await this.showSnackBar(title + ": " + body);
          }
          // if (!this.enterpriseSelector.isCurrentOrg(orgId)) {
          //   await this.showSwitchOrg(orgId);
          // }
          this.router.navigate([url + storageType], { queryParams: { redirectOriginPath: originPath, redirectOriginContainer: originContainer, redirectPath: path, isFolder: isFolder, itemName: name  } });
        }
        break;
      case NotificationEventType.JOIN_REQ_RECEIVED:
        {
          if (!this.enterpriseSelector.isCurrentOrg(orgId)) {
            await this.showSwitchOrg(orgId);
          }
          console.log("[test redirect] notif data %o", data);
          const url = data.isPersonalSpace == "True" ? "main/friends" : "main/org/user";
          this.router.navigate([url], { queryParams: { reqId: data.reqId } });
          
        }
        break;
      default:
        {
          if (_.startsWith(eventType, PluginManager.EXT_PREFIX)) {
            if (!this.enterpriseSelector.isToolEnabled(orgId, eventType)) {
              let msg = this.translateService.instant("ORGANIZATION.TOOLS.REDIRECT_DISABLED_TOOLS_ERR");
              let action = this.translateService.instant("OK");
              this.showSnackBar(msg, action);
              
              return;
            }
            var plugin = this.pluginManager.get(eventType);
            if (plugin && plugin.tapRedirectPath) {
              //redirect to tool page 
              this.router.navigate(["main", "tools", ...plugin.tapRedirectPath.split("/").filter(i => i)], { state: data });
            }
          }
        }
      }
    } catch (err) {
      // console.log(err);
    }
  }

  private joinMeetOnRejected(res: HttpErrorResponse) {
    if (res.status == 400) { // Bad request with custom response code
      this.showConfirmDialog(
        this.translateService.instant("CHAT.JOIN_MEETING_ERROR"), 
        res.error.error
        );
    }
    else if (res.status == 401) { // Unauthorized
      this.showConfirmDialog(
        this.translateService.instant("DASHBOARD.UNAUTHORIZED.TITLE"),
        this.translateService.instant("DASHBOARD.UNAUTHORIZED.DESC_3")
        );
    }
    else {
      this.showConfirmDialog(
        res.statusText,
        this.translateService.instant("CHAT.MEETING_ERROR")
        );
    }
  }

  private showConfirmDialog(title: string, content: string) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      width: "400px",
      data: {
        title: title,
        content: content,
      },
    });
  }

  private showSnackBar(
    content: string,
    buttonName: string = "SHOW"
  ): Promise<void> {
    let sb = this.snackBar.open(content, buttonName, {
      duration: 5000,
    });

    return sb
      .afterDismissed()
      .toPromise()
      .then((info) => {
        if (info.dismissedByAction === true) {
          return Promise.resolve();
        } else {
          throw new Error("Notification toast ignored");
        }
      }).catch((err) => {
        throw new Error(err);
      });
  }

  private showSwitchOrg(orgId: string): Promise<void> {
    // if orgId is connected org, get PS orgId
    let org = this.enterpriseSelector.getOrg(orgId);
    if (!org)
      return;

    const dialogRef = this.dialog.open(DialogSwitchOUComponent, {
      width: "400px",
      data: {
        orgId: org.id,
        disableRedirect: true,
      },
    });

    return dialogRef.afterClosed().toPromise()
      .then((info) => {
        if (info && info.status && info.status == "success") {
          this.isRedirecting = true;
          return Promise.resolve();
        } else {
          throw new Error("Notification toast switch org ignored");
        }
      }).catch((err) => {
        throw new Error(err);
      });
  }

  private transformChatMention(content: string) {
    if (content && content.trim() != "") {
      const regExp = new RegExp("@{[^}]*}", "gm"); //regExp to match and replace all occurence
      var result = content.match(regExp);
      if (result && result.length != 0) {
        result
          .filter((v, i, a) => a.indexOf(v) === i)
          .forEach((m) => {
            var mention = UserMentions.constructFromString(m);

            content = content.replace(RegExp(m, "g"), mention.displayName);
          });
        return content;
      }
    }
    return content;
  }
}

