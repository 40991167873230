import { MessageType } from "./../model/message.model";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "replyTo",
  pure: true,
})
export class ReplyToPipe implements PipeTransform {
  //only keep reply to element from start tag to close tag
  transform(replyTo: string): string {
    let replyToEndTag = "</el-reply-to>";
    let index = replyTo.indexOf(replyToEndTag);
    if (index == -1) return;
    if (index + replyToEndTag.length != replyTo.length) {
      replyTo = replyTo.substring(0, index + replyToEndTag.length);
    }

    return replyTo;
  }

  //remove reply to element
  undoTransform(replyTo: string) {
    if (
      replyTo == null ||
      replyTo.trim() == "" ||
      !replyTo.includes("el-reply-to")
    )
      return replyTo;

    let replyToEndTag = "</el-reply-to>";

    replyTo = replyTo.replace(/&gt;/g, ">");
    replyTo = replyTo.replace(/&lt;/g, "<");
    let index = replyTo.indexOf(replyToEndTag);
    if (index == -1) {
      let replyToStartTag = "<el-reply-to";
      let startIndex = replyTo.indexOf(replyToStartTag);
      if (startIndex == -1) return replyTo;
      let endIndex = replyTo.indexOf(">");
      if (endIndex == -1) return replyTo;
      return replyTo.substring(endIndex + 1, replyTo.length);
    }
    return replyTo.substring(index + replyToEndTag.length, replyTo.length);
  }

  construct(
    senderName: string,
    timestamp: number,
    messageType: MessageType,
    fileName: string,
    meetingTitle: string,
    content: string,
    msgId: string
  ) {
    content = this.undoTransform(content); //prevent another reply to element
    return `<el-reply-to sender="${senderName ?? ""}" ts="${
      timestamp ?? ""
    }" eventId="${msgId ?? ""}" type="${MessageType[messageType]}" fileName="${
      fileName ?? ""
    }" meetingTitle="${meetingTitle ?? ""}">${content ?? ""}</el-reply-to>`;
  }
}
