import { OUService } from "./../../services/ou.service";
import {
  Component,
  OnInit,
  ViewChild,
  EventEmitter,
  Output,
  Input,
  OnDestroy
} from "@angular/core";
import { TreeComponent } from "@circlon/angular-tree-component";
import { LocalStorageService } from "../../localStorage/local-storage.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { OUState } from "../../model/org.state";
import { SubSink } from "subsink";
import { EnterpriseSelector } from "../../states/enterprise.state.selector";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { EnterpriseState } from "../../states/enterprise.state";

@Component({
  selector: "ui-org-tree",
  templateUrl: "./org-tree.component.html",
  styleUrls: ["./org-tree.component.scss"]
})
export class OrgTreeComponent implements OnInit, OnDestroy {
  @Output() selectOUEvent = new EventEmitter();
  @Input() disableSelectAll = false;

  //for radio button selection
  selectedOption = "all";

  // Model of tree nodes
  nodes: TreeItem[]; //TreeItem type

  // Tree parameters
  customTemplateStringOptions = {
    idField: "id",
    displayField: "name"
  };

  // loaders
  treeLoading = false;

  private _subSink: SubSink;
  private _currentOus: OUState[];

  @Select(EnterpriseState.currentOrgOUs)
  currentOrgOUs$: Observable<OUState[]>;

  constructor(
    public snackBar: MatSnackBar,
    private enterpriseSelector: EnterpriseSelector
  ) {
    this._subSink = new SubSink();
  }

  ngOnDestroy(): void {
    if (this._subSink) {
      this._subSink.unsubscribe();
    }
  }

  ngOnInit() {
    this._currentOus = this.enterpriseSelector.getCurrentOrgOUs();
    this.generateOUTree();

    this._subSink.sink = this.currentOrgOUs$.subscribe(ous => {
      this._currentOus = ous;
      this.generateOUTree();
    });

    if (this.disableSelectAll) {
      this.selectedOption = "selected";
    }
  }

  private generateOUTree() {
    this.nodes = [];
    //const defaultOU = this._currentOus.find(i => i.isDefault);
    const currentOrgUser = this.enterpriseSelector.getCurrentOrgUser();
    const currentOU = this._currentOus.find(i => i.id == currentOrgUser.ouId);

    if (!currentOU) return;

    const treeItem: TreeItem = {
      id: currentOU.id,
      name: currentOU.name,
      path: currentOU.name,
      expanded: true,
      children: this.populateChildren(currentOU.childs, currentOU.name),
      hasChildren: currentOU.childs.length > 0
    };
    this.nodes.push(treeItem);
  }

  private populateChildren(childs: string[], parentPath: string) {
    if (!childs || childs.length <= 0) return null;

    const children: TreeItem[] = [];
    childs.forEach(id => {
      const ou = this._currentOus.find(i => i.id === id);
      const path = parentPath + " > " + ou.name;
      const child: TreeItem = {
        id: ou.id,
        name: ou.name || "null",
        path: path,
        expanded: true,
        children: this.populateChildren(ou.childs, path),
        hasChildren: ou.childs.length > 0
      };
      children.push(child);
    });
    return children;
  }

  onActivate($event) {
    this.selectOUEvent.emit({
      ouId: $event.node.data.id,
      ouPath: $event.node.data.path
    });
  }
  activateAll() {
    this.selectOUEvent.emit(null);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

  // fix tree not expanding bug
  onUpdateData(treeComponent: TreeComponent, $event) {
    treeComponent.treeModel.expandAll();
  }
}

interface TreeItem {
  id: string;
  name: string;
  path: string;
  expanded: boolean;
  children: TreeItem[];
  hasChildren: boolean;
}
