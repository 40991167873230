import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ToolsAuthService } from './tools-auth.service';

@Injectable()
export class ToolsGuard implements CanActivate {
    constructor(private router: Router, private service: ToolsAuthService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // here capture navigation state
        const navi=this.router.getCurrentNavigation();
        if(navi.extras && navi.extras.state){
            this.service.toolsLocalState.setNaviState(navi.extras.state);
            console.log("set navi extras state --->",navi.extras.state);
        }
        console.log('Tool AuthGuard: isAuthenticated: ' + this.service.isElToolsAuth());
        if (this.service.isElToolsAuth()) {
            // logged in so return true
            return true;
        }
        else{
            const userId=this.service.getUserID();
            this.service.runElToolsAuth().subscribe(resp => {
                const obj: any = <any>resp;
                this.service.setElToolsToken(obj.esignAccessToken,userId);
            //    console.log(obj);
                setTimeout(() => this.router.navigate([state.url]));
            });
            return false;
        }
        // not logged in so redirect to login page with the return url
       
    }
}

