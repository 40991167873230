import { UserRole } from './userRole.model';

export interface Contact {
    contactId: string;
    name: string;
    firstName: string;
    lastName: string;
    type: string;
    label: string[];
    group: string[];
    imageUrl: string;
    hasImg: boolean;
    isMember: boolean;
    userId: string;

    // old vars
    homePhone?: string;
    officePhone?: string;
    phoneNumber?: string;
    homeAddress?: string;
    officeAddress?: string;
    email: string; // still used?

    phones?: Phone[];
    addresses?: Address[];
    emails?: Email[];
    matrixId: string;

    commonGroupId: string[];
    commonGroupName: string[];
    orgUnitId: string;
    orgUnitName: string;
    ownerId: string;
    orgName: string;
    userRole: UserRole;
    position: string;
}

export class Address {
    street1: string;
    street2: string;
    postCode: string;
    city: string;
    state: string;
    country: string;
    contactId: string;
    label: ContactDetailLabel;
    labelName: string;

    isEmpty() {
        if (this.street1 || this.street2 || this.postCode || this.city || this.state || this.country) {
            return false;
        } else {
            return true;
        }
    }
}

export class Phone {
    phoneNumber?: string | number;
    contactId?: string;
    label?: ContactDetailLabel;
    labelName?: string;
    countryCode?: string;
    fullPhoneNumber?: string;
};

export class Email {
    email?: string;
    contactId?: string;
    label?: ContactDetailLabel;
    labelName?: string;
}


export enum ContactDetailLabel {
    Others = 0,
    Personal = 1,
    Work = 2,
    Mobile = 3
}
