import { timer } from 'rxjs';
import { Directive, Input } from '@angular/core';
import { NG_ASYNC_VALIDATORS, AsyncValidator, AbstractControl, UntypedFormControl } from '@angular/forms';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Directive({
    selector: '[el-passcode-check]',
    providers: [{
        provide: NG_ASYNC_VALIDATORS, useExisting: PasscodeValidatorDirective, multi:
            true
    }]
})
export class PasscodeValidatorDirective implements AsyncValidator {
    @Input() inputEmail: UntypedFormControl;
    constructor(public authService: AuthService) {
    }

    validate(control: AbstractControl) {
        // debounce timer
        return timer(500).pipe(
            switchMap(() => {
                console.log(this.inputEmail.value)
                console.log(control.value)
                return this.authService.passcodeCheck(this.inputEmail.value, control.value).then(res => {
                    if (!res) {
                        return { 'passcodeInvalid': true };
                    } else {
                        return null
                    }
                }).catch(err => {
                    return { 'serverError': true };
                });
            })
        )
    }
}
