import { AppStateSelector } from './core/states/app.state.selector';
import { FlowManager } from './core/workflow/flow-manager';
import { NotificationService } from './core/services/notify.service';
import { HistoryStateSelector } from './core/states/history.state.selector';
import { EnterpriseSelector } from './core/states/enterprise.state.selector';
import { skip } from 'rxjs/operators';
import { UserService } from './core/services/user.service';
import { PubSub } from './core/services/pubsub.service';
import { Component, HostBinding } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { NavigationEnd, Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../environments/environment";
import { AuthService } from "./core/auth/auth.service";
import { MessagingService } from "./core/services/firebase-messaging.service";
import { Select } from "@ngxs/store";
import { AppState } from "./core/states/app.state";
import { Observable } from "rxjs";
import { SubSink } from "subsink";
import { UserDataState } from "./core/states/user-data.state";
import { Emittable, Emitter } from "@ngxs-labs/emitter";
import { UserDataSelector } from "./core/states/user-data.state.selector";
import { AuthStateSelector } from "./core/states/auth.state.selector";
import { AuthState } from "./core/states/auth.state";
import { AuthDataState } from "./core/model/authData.state";
import { FcpService } from "./core/fcp/fcp.service";
import { GCStateHelper } from "./core/states/GC.state";
import { UIStatus, UIState } from './core/states/ui.state';
import { UIStateSelector } from './core/states/ui.state.selector';
import { interval } from 'rxjs';
import { pocolog } from 'pocolog';
import { SystemService } from './core/services/system.service';
import { DomSanitizer } from '@angular/platform-browser';
import { EnterpriseState } from './core/states/enterprise.state';
import { PluginManager } from './tools/plugin.manager';
declare let ga: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  @HostBinding("class.loading") loading = true;
  semver = require("semver");

  globalLoader = true; // show global loader
  globalLoaderText: string;
  private _subSink: SubSink;
  private _logoutLoader: any;

  //#region Selector
  @Select(AppState.version)
  onVersionChanged$: Observable<string>;

  @Select(UserDataState.language)
  onLangChanged$: Observable<string>;

  @Select(AuthState.data)
  onAuthDataChanged$: Observable<AuthDataState>;

  @Select(AppState.background)
  onBackground$: Observable<boolean>;

  @Select(UserDataState.fcmToken)
  fcmToken$: Observable<string>;
  //#endregion

  //#region Emitter
  @Emitter(UserDataState.setLanguage)
  public setLanguage: Emittable<string>;
  @Emitter(AppState.setVersion)
  public setAppVersion: Emittable<string>;
  @Emitter(UIState.setUIUpdatingVersion)
  setUIUpdatingVersion: Emittable<void>;
  @Emitter(EnterpriseState.switchOrg)
  switchOrg: Emittable<string>;
  @Emitter(AuthState.saveToken)
  public saveToken: Emittable<string>;

  //#endregion

  constructor(
    mdIconRegistry: MatIconRegistry,
    public router: Router,
    private translateService: TranslateService,
    private authService: AuthService,
    private userDataSelector: UserDataSelector,
    private authStateSelector: AuthStateSelector,
    private appStateSelector: AppStateSelector,
    private messagingService: MessagingService,
    private gcStateHelper: GCStateHelper,
    private fcp: FcpService,
    private appState: AppState,
    private uiStateSelector: UIStateSelector,
    private systemService: SystemService,
    private pubSub: PubSub,
    private activatedRoute: ActivatedRoute,
    sanitizer: DomSanitizer,
    private userService: UserService,
    private enterpriseSelector: EnterpriseSelector,
    private historySelector: HistoryStateSelector,
    private flowManager: FlowManager,
    private notifyService: NotificationService,
    private pluginManager: PluginManager
  ) {
    // window.onerror("TestError: Hello world", window.location.href)
    
    this._subSink = new SubSink();
    this.loading = false;

    mdIconRegistry.registerFontClassAlias("fontawesome", "fa");
    this._subSink.sink = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga("set", "page", event.urlAfterRedirects);
        ga("send", "pageview");
      }
    });

    mdIconRegistry.addSvgIcon(
      'open_meet', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/open-meeting.svg'));

    mdIconRegistry.addSvgIcon(
      'md-eye', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/md-eye.svg'));

    mdIconRegistry.addSvgIcon(
      'md-eye-off', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/md-eye-off.svg'));

    mdIconRegistry.addSvgIcon(
      'pending-actions', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/pending-actions.svg'));
    
    mdIconRegistry.addSvgIcon(
      'resend-email', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/resend-email.svg'));
    
    mdIconRegistry.addSvgIcon(
      'person-remove', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/person-remove.svg'));
    
    mdIconRegistry.addSvgIcon(
      'person-add', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/md-person-add.svg'));

    mdIconRegistry.addSvgIcon(
      'cancel-invitation', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/cancel-invitation.svg'));

    mdIconRegistry.addSvgIcon(
      'drive_file_move', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/drive-file-move.svg'));

      mdIconRegistry.addSvgIcon(
        'hard_drive', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/hard-drive.svg'));
  
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang("en");

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translateService.use("en");


    // let appVer = this.localStorage.getVersion();
    // if (appVer) {
    //   // let currentVer = appVer.split(".", 3).map(Number);
    //   let supportedVer = environment.lastSupportedVersion;

    //   try {
    //     if (!this.semver.gte(appVer, supportedVer)) {
    //       // outdated
    //       this.localStorage.clear();
    //     }
    //   } catch (ex) {
    //     console.log("Version compare error, logging out");
    //     this.localStorage.clear();
    //   }
    // } else {
    //   // app outdated/ not logged in before. clear all local storage
    //   this.localStorage.clear();
    // }

    // resolve circular dependency
    this.authService.setMessagingService(messagingService);
    //this.initApp();

    // clear storage. do not rely on local storage anymore
    //this.localStorage.clear();
  }

  ngOnInit() {
    console.log("[AppComponent] ngOnInit");
    this.appState.ready(false)
      .then(async () => {
        var isLoggedIn = this.userDataSelector.isLoggedIn;
        console.log("[AppComponent] Is logged in " + isLoggedIn);
        if (isLoggedIn) {
          // refresh jwt before doing anything else
          console.log("[AppComponent] refreshing token");
          await this.authService.refreshAccessToken().catch((err) => {
            console.error("[AppComponent] Failed refreshing token");
          })
        }

        this.appState.initTokenDone();
        this.initApp();
        this.initGlobalLoader();
        this.globalLoader = false;
      });

  }



  initGlobalLoader() {
    try {
      this._subSink.sink = this.uiStateSelector.onUIStatusChanged().subscribe(state => {
        console.info("[Global loader] UIStatus change " + state)
        this.globalLoaderText = null;
        if (state === undefined || state === UIStatus.Ready) {
          this.globalLoader = false;
        } else {
          this.globalLoader = true;

          // set global loader text for version update
          if (state === UIStatus.UpdatingVersion) {
            this.globalLoaderText = this.translateService.instant('UI.LOADING_UPDATE_VER');
          }
        }
      })
    } catch (err) {
      console.error("[AppComponent] initGlobalLoader unexpectedly failed. %o", err)
    }
  }

  initApp() {
    try {
      console.info("[AppComponent] initApp");
      
      this.appStateSelector.initDeviceId();

      //plugin manager
      this.pluginManager.load();

      // this.checkBrowserSupport();

      this._subSink.sink = this.onVersionChanged$.subscribe(version => {
        this.handleMinVersion(version);
      });

      this._subSink.sink = this.pubSub.subscribe(PubSub.FORCE_LOGOUT, async res => {
        if (res) {
          await this.authService.logout();
          this.router.navigate(['/login'], { queryParams: { status: res } });
        }
      });

      this._subSink.sink = this.pubSub.subscribe(
        PubSub.GET_CONTACTS,
        () => {
          this.flowManager
          .getContacts(this.enterpriseSelector.getCurrentOrgId())
          .catch((err) => {
              console.error(err);
            });
        },
        null,
        null,
        true
      );

      this._subSink.sink = this.pubSub.subscribe(
        PubSub.ON_HUB_NOTIFICATION_RECEIVED,
        (res) => {
          this.notifyService.handleNotificationHubEvent(res);
        },
        null,
        null,
        true
      );

      this.userDataSelector.onLoggedInChanged().subscribe(async (isLoggedIn) => {
        if (!isLoggedIn) {
          this.flowManager.logout();

          this.router.navigate(["/login"], {
            queryParams: this.activatedRoute.snapshot.queryParams,
          });
        } else {
          // this.router.navigateByUrl('/main');
          this.handleSignalrHubs("onAuthDataChanged");
        }
      });


      if (this.authStateSelector.isAuthenticated) {
        this.handleSignalrHubs("onAuthDataChanged");
        // this.authService.checkTokenExpiry().then(async (token) => {
        //   if (!token) {
        //     await this.authService.logout();
        //     this.router.navigate(['/login'], { queryParams: { status: 'jwtexpired' } });
        //   } else {
        //     //returns a new jwt token with 4 hrs expiry
        //     this.saveToken.emit(token);
        //     //this.fcp.getKeyPair();
        //     //this.handleSignalrHubs("onAuthDataChanged");
        //   }

        // })
      }

      //######## default language
      this._subSink.sink = this.onLangChanged$.subscribe(res => {
        this.translateService.setDefaultLang(res);
        this.translateService.use(res);
      });
      //set default language to english if no preference
      if (!this.userDataSelector.language) {
        this.setLanguage.emit("en");
      }

      // update version
      this.setAppVersion.emit(environment.version);
      // run version check polling
      const interval$ = interval(10000); // 10 second
      this._subSink.sink = interval$.subscribe(val => {
        this.checkVersionUpdate(environment.version);
      });

      this._subSink.sink = this.onBackground$
        .pipe(skip(1))
        .subscribe((isBackground) => {
          if (this.authStateSelector.isAuthenticated) {
            this.userService.updateUiState(isBackground);
          }
        });

      this._subSink.sink = this.fcmToken$.subscribe((res) => {
        console.log("[AppComponent] fcmToken");
        if (res) {
          this.userService.updateFcmToken(res).catch((err) => { })
        }
      });
    } catch (err) {
      console.error("[AppComponent] InitApp unexpectedly failed. %o", err)
    }
  }

  private handleSignalrHubs(source?: string) {
    console.log("[AppComponent] (start) from %s", source);
    if (!this.authStateSelector.isAuthenticated) {
      console.warn("[AppComponent] Not authorized. Skip hub restart");
      return;
    }

    try {
      var url = this.router.url;
      // start room hub only in meet
      // if (!url.includes("/meet")) {
      this.flowManager.executeMain();
      // } else {
      // this.flowManager.prepareMeet();
      // }
    } catch (err) {
      this.flowManager.executeMain();
    }
    // let routerSub = this.router.events.subscribe(
    //   (event) => {
    //     console.log("event");
    //     if (event instanceof NavigationEnd) {
    //       console.log("in event");

    //       // start room hub only in meet
    //       if (!event.url.includes("/meet")) {
    //         this.appWorkflowManager.prepare(orgId).then((res) => {
    //           this.switchOrg.emit(res);
    //         });
    //       } else {
    //         console.log("init meet");
    //         this.appWorkflowManager.prepareMeet(orgId);
    //       }
    //       // run only once
    //       if (routerSub) routerSub.unsubscribe();
    //     }
    //   },
    //   (err) => {
    //     console.error(err);
    //     this.appWorkflowManager.prepare(orgId).then((res) => {
    //       this.switchOrg.emit(res);
    //     });
    //   }
    // );
  }

  private handleMinVersion(version: any) {
    try {
      if (version) {
        let supportedVer = environment.lastSupportedVersion;

        if (!this.semver.gte(version, supportedVer)) {
          // outdated
          //clear storage
        }
      } else {
        //clear storage
        return;
      }
    } catch (err) {
      console.log("Version compare error, logging out");
      //this.localStorage.clear();
    }
  }

  private checkVersionUpdate(version: string) {
    try {
      if (version) {
        this.systemService.getLatestWebVersion().then(latestVer => {
          if (!this.semver.gte(version, latestVer)) {
            pocolog.info("updating version")
            // change UI to updating version
            this.setUIUpdatingVersion.emit(null).toPromise();
            location.reload();
          }
        }).catch(err => {
          pocolog.error("Error calling Version API")
          console.info(err)
        });
      }
    } catch (err) {
      pocolog.error("Error comparing version")
      //this.localStorage.clear();
    }
  }
}
