import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CloudObjState, CloudObjStatus, CloudObjType } from '../../model/cloud-obj.state';

@Component({
  selector: 'app-dialog-explorer-progress',
  templateUrl: './dialog-explorer-progress.component.html',
  styleUrls: ['./dialog-explorer-progress.component.scss']
})
export class DialogExplorerProgressComponent implements OnInit {
  @Input()
  uploadAndDownloads: CloudObjState[] = [];

  @Output()
  remove = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

}
