import { HistoryStateSelector } from "./../states/history.state.selector";
import { EnterpriseSelector } from "./../states/enterprise.state.selector";
import { RoomHub } from "../hub/room.hub";
import { CancellationToken, Flow } from ".";
import { HubConnectionStatus } from "../model/hubConnection.state";

export class RoomHandshakeFlow extends Flow {
  constructor(private roomHub: RoomHub) {
    super();
  }

  public async execute(
    orgId: any = null,
    token: CancellationToken = null
  ): Promise<any> {
    if (!this.roomHub.isConnected) {
      var sysHubStarted = await new StartRoomHubFlow(this.roomHub).execute(
        null,
        token
      );
      if (sysHubStarted == false) {
        this.roomHub.failedOverHandling();
        return;
      }
    }

    this.roomHub.setNotReady();

    var res = await this.roomHub.handshakeMethod(orgId);
    if (token != null && token.isCancelled) return null;

    this.output = await this.roomHub.updateHandshakeData(res);

    this.roomHub.setReady();

    return await super.execute(this.output, token);
  }
}

export class StartRoomHubFlow extends Flow {
  constructor(private roomHub: RoomHub) {
    super();
  }

  public async execute(
    input: any = null,
    token: CancellationToken = null
  ): Promise<boolean> {
    if (this.roomHub.hubSnapshot.status !== HubConnectionStatus.Connected) {
      this.output = await this.roomHub
        .start()
        .toPromise()
        .catch((err) => {
          console.error("[StartRoomHubFlow] %o", err);
          return false;
        });
    } else {
      this.output = true;
    }
    // console.log("[StartRoomHubFlow] produced result: %o", this.output);

    return await super.execute(this.output, token);
  }
}

export class ReconnectRoomHubFlow extends Flow {
  constructor(
    private roomHub: RoomHub,
    private enterpriseSelector: EnterpriseSelector,
    private historySelector: HistoryStateSelector
  ) {
    super();
  }

  public sketch(): Flow {
    return super.sketch(new RoomHandshakeFlow(this.roomHub));
  }

  public async execute(
    input: any = null,
    token: CancellationToken = null
  ): Promise<boolean> {
    var orgId = this.enterpriseSelector.getCurrentOrgId();
    if (!orgId) {
      orgId = this.historySelector.userLastLogin;
    }

    return await super.execute(orgId, token);
  }
}
