import { RedirectService } from './../../core/services/redirect.service';
import { UserService } from './../../core/services/user.service';
import { FlowManager } from './../../core/workflow/flow-manager';
import { MediaService } from './../../core/services/media.service';
import { DialogInviteUsersComponent } from "../../core/ui/dialog/invite-users/dialog-invite-users.component";
import { RoleEntity } from "./../../core/model/userRole.model";
import { EnterpriseSelector } from "./../../core/states/enterprise.state.selector";
import { AppState } from "./../../core/states/app.state";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subscription } from "rxjs";
import { EnterpriseState } from "./../../core/states/enterprise.state";
import { Emitter, Emittable } from "@ngxs-labs/emitter";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { UserDataSelector } from "./../../core/states/user-data.state.selector";
import { MembershipReqService } from "./../../core/services/membership-req.service";
import { MembershipReq } from "./../../core/model/membershipReq.model";
import { AuthService } from "./../../core/auth/auth.service";
import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import {
  ActivatedRoute,
  Params,
  Router,
} from "@angular/router";
import { AuthStateSelector } from "../../core/states/auth.state.selector";
import { DialogRequestSuccessComponent } from "../request-to-join/dialog/dialog-success/dialog-success.component";
import {
  MembershipReqStatus,
  MembershipReqType,
  OrgState,
} from "../../core/model/org.state";
import { Select } from "@ngxs/store";
import { pocolog } from "pocolog";
import { DialogForceLogoutComponent } from "./dialog/dialog-force-logout.component";
import { DialogNewOrgComponent } from "../../core/ui/dialog/new-org/dialog-new-org.component";
import { DialogSuccessMsgComponent } from "./dialog/dialog-success-msg/dialog-success-msg.component";
import { DialogPromptComponent } from '../../core/ui/dialog/dialog-prompt/dialog-prompt.component';
import { TurnstileComponent } from '../../core/auth/turnstile/turnstile.component';
declare let gtag: Function;
@Component({
  selector: "app-verify-membership",
  templateUrl: "./verify-membership.component.html",
  styleUrls: ["./verify-membership.component.scss"],
})
export class VerifyMembershipComponent implements OnInit {
  @ViewChild("agreeCheckbox", { read: ElementRef, static: false }) agreeCheckbox: ElementRef;
  @ViewChild('elturnstile') turnstileComponent: TurnstileComponent;
  @Select(EnterpriseState.orgs)
  orgs$: Observable<OrgState[]>;

  private _token: string;
  membershipReq: MembershipReq;
  password: string = "";
  checkPassword: string = "";
  confirmPasswordError: boolean = false;
  company: string;
  showLoading: boolean = false;
  showError: boolean;
  showSuccess: boolean = false;
  successMsg: string;
  errorMsg: string;
  isAuthenticated: boolean = false;
  isMember: boolean;
  isInit: boolean = false;
  desc: string;
  show2faVerifyWindow = false;
  isUnrecognizedDevice2fa = false;
  orgAvatar: string;

  firstName: string;
  lastName: string;

  showNewPasswordType: string = "password";
  showConfirmPasswordType: string = "password";

  constructor(
    private membershipReqService: MembershipReqService,
    private activatedRoute: ActivatedRoute,
    private authSelector: AuthStateSelector,
    private authService: AuthService,
    private userService: UserService,
    private userDataSelector: UserDataSelector,
    private router: Router,
    private dialog: MatDialog,
    private translate: TranslateService,
    private appState: AppState,
    private snackBar: MatSnackBar,
    private enterpriseSelector: EnterpriseSelector,
    private flowManager: FlowManager,
    private mediaService: MediaService,
    private redirectService: RedirectService
  ) { }

  //#region Getter
  get reqStatus(): MembershipReqStatus {
    if (!this.membershipReq) return null;
    return this.membershipReq.status;
  }

  get reqType(): MembershipReqType {
    if (!this.membershipReq) return null;
    return this.membershipReq.type;
  }

  get orgName() {
    if (!this.membershipReq) return null;
    return this.membershipReq.orgName;
  }

  get roleName() {
    if (!this.membershipReq) return null;
    return this.membershipReq.roleName;
  }

  get isPersonalOrg() {
    if (!this.membershipReq) return null;
    return this.membershipReq.isPersonalOrg
      ? this.membershipReq.isPersonalOrg
      : false;
  }

  get email() {
    if (!this.membershipReq) return null;
    return this.membershipReq.email;
  }

  get isSignUp() {
    return this.reqType && this.reqType == MembershipReqType.SignUp;
  }

  get isInvitation() {
    return this.reqType && this.reqType == MembershipReqType.Invitation;
  }

  get isCoworker() {
    if (!this.membershipReq) return false;
    return (
      this.membershipReq.roleNormalizedName == RoleEntity[RoleEntity.COWORKER]
    );
  }
  //#endregion

  ngOnInit() {
    this.isInit = true;
    this.appState.ready().then(() => {
      this.isAuthenticated = this.authSelector.isAuthenticated;
      this.activatedRoute.params.subscribe((params: Params) => {
        this._token = params.token;
        if (this._token) {
          this.validateToken();
        }
      });
    });
  }

  checkPasswordMatch() {
    if (this.password && this.checkPassword) {
      this.confirmPasswordError = this.password !== this.checkPassword;
    } else {
      this.confirmPasswordError = false;
    }
  }

  private validateToken() {
    this.isInit = true;
    this.showLoading = true;

    return this.membershipReqService
      .validateToken(this._token)
      .then((res) => {
        this.showLoading = false;
        this.membershipReq = res;
        this.firstName = this.membershipReq.firstName;
        this.lastName = this.membershipReq.lastName;
        if (this.membershipReq) {
          this.isMember = !!this.membershipReq.userId;
          if (this.membershipReq.orgAvatar) {
            let fullImageUrl = this.mediaService.generateOrgImageUrl(
              res.orgId,
              this.membershipReq.orgAvatar
            );
            this.orgAvatar = fullImageUrl;
          }
          this.getDesc();
        }
        this.isInit = false;

        switch (this.membershipReq.status) {
          case MembershipReqStatus.Cancelled:
            this.router.navigate(['/login'], { queryParams: { status: 'membership-req-cancel' } });
            return;
          case MembershipReqStatus.Completed:
            this.router.navigate(['/login'], { queryParams: { status: 'membership-req-complete', orgName: this.membershipReq.orgName } });
            return;
          case MembershipReqStatus.PendingForApproval:
            this.showLoading = false;
            this.showError = true;
            this.errorMsg = this.translate.instant(
              "VERIFY_MEMBERSHIP.INVALID_REQ"
            );
            return;
          default: // MembershipReqStatus.PendingToVerify is only for RequestToJoin
        }

        if (
          this.authSelector.isAuthenticated &&
          this.userDataSelector.userId &&
          this.membershipReq.userId !== this.userDataSelector.userId
        ) {
          //request user to logout
          const dialogRef = this.dialog.open(DialogForceLogoutComponent, {
            width: "400px",
          });
          dialogRef.afterClosed().subscribe((res) => {
            this.router.navigate(["/main/dashboard"]);
          });
        }
      })
      .catch((error) => {
        this.isInit = false;
        this.showLoading = false;
        this.showError = true;
        this.errorMsg = error.error;
      });
  }

  hideOrShowCheckBox() {
    return !this.isMember ? "show" : "hide";
  }

  private getDesc() {
    if (this.isInvitation) {
      //get username concat "Personal space" if is personal space inv
      const orgName = this.isPersonalOrg
        ? this.translate.instant("VERIFY_MEMBERSHIP.PERSONAL_SPACE_DESC3") + this.orgName +
        this.translate.instant("VERIFY_MEMBERSHIP.PERSONAL_SPACE_DESC2")
        : this.orgName;
      this.desc =
        this.firstName + ' ' + this.lastName +
        this.translate.instant("VERIFY_MEMBERSHIP.INV_DESC") +
        orgName +
        ". ";
      if (this.isMember) {
        if (!this.isAuthenticated)
          //xxx, you are invited to join xxx's personal space. Please enter your password to accept the invitation.
          this.desc =
            this.desc +
            this.translate.instant("VERIFY_MEMBERSHIP.ACCEPT_INV_ENTER_PWD");
      } else {
        //xxx, you are invited to join xxx's personal space. Please set password for your new account so that we can link you up.
        this.desc =
          this.desc +
          this.translate.instant("VERIFY_MEMBERSHIP.ACCEPT_INV_SET_PWD");
      }
    } else {
      this.desc =
        this.firstName + ' ' + this.lastName +
        this.translate.instant("VERIFY_MEMBERSHIP.SIGNUP_DESC");
    }
  }

  private async signUp() {
    var token = await this.turnstileComponent.getToken();
    return this.membershipReqService
      .registerWithoutBizOrg(
        this.email,
        this.firstName,
        this.lastName,
        this.password,
        token
      )
      .then((res) => {
        if (res && res.success) {
          return Promise.resolve();
        } else {
          return Promise.reject(res);
        }
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  private async login() {
    var token = await this.turnstileComponent.getToken();
    return this.authService
      .login(this.email, this.password, null, token)
      .then((res) => {
        if (res && res.success) {
          if (!res.emailVerified) {
            pocolog.trace("email not verified");
            this.showLoading = false;

            // show verify UI
            this.goTo2faPage();
            return Promise.reject();
          } else {
            if (res.twoFactorEnabled || !res.recognizedDevice) {
              // 2fa login
              pocolog.trace("2fa detected");
              this.showLoading = false;
              this.isUnrecognizedDevice2fa = !res.recognizedDevice;
              if (!res.twoFactorEnabled && !res.recognizedDevice) { // cause of 2FA is unrecognized device
                const dialogRef = this.dialog.open(DialogPromptComponent, {
                  width: "500px",
                  disableClose: true,
                  data: {
                    title: this.translate.instant('LOGIN.UNRECOGNIZED_DEVICE'),
                    content: this.translate.instant('LOGIN.UNRECOGNIZED_DEVICE_REASON'),
                  },
                });
                dialogRef.afterClosed().subscribe((result) => {
                  if (result) {
                    this.goTo2faPage();
                  }
                });
              } else {
                // cause of 2FA is user enabled 2FA
                this.goTo2faPage();
              }
              return Promise.reject();
            }
          }
          return Promise.resolve();
        } else {
          return Promise.reject(res);
        }
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  private goTo2faPage() {
    this.show2faVerifyWindow = true;
    // this.router.navigate(["/acc/verify"], {
    //   queryParams: {
    //     redirectUrl: `/activate/${encodeURIComponent(this._token)}`,
    //   },
    //   skipLocationChange: true,
    // });
  }

  verified2fa() {
    this.show2faVerifyWindow = false;
    if (this.isUnrecognizedDevice2fa) {
      this.isUnrecognizedDevice2fa = false;
      this.userService.updateSkip2FA(true);
    }
    this.verifyMembership();
  }

  private async authenticate(): Promise<any> {
    if (!this.authSelector.isAuthenticated && this.membershipReq.userId) {
      return await this.login();
    } else if (!this.membershipReq.userId) {
      return await this.signUp();
    } else {
      return Promise.resolve();
    }
  }

  gtag_report_conversion(url) {
    var callback = function () {
      if (typeof (url) != 'undefined') {
        window.location = url;
      }
    };
    gtag('event', 'conversion', {
      'send_to': 'AW-640313908/QkMGCI3v2scDELTUqbEC',
      'event_callback': callback
    });
    return false;
  }

  async verifyMembership() {
    this.showLoading = true;

    this.showError = false;
    this.errorMsg = null;

    //Removes the leading and trailing white space
    this.firstName = this.firstName.trim();
    this.lastName = this.lastName.trim();

    return this.authenticate()
      .then(() => {
        if (this.membershipReq.type == MembershipReqType.Invitation) {
          return this.membershipReqService.acceptInvitation(
            this.membershipReq.id,
            this.company
          );
        } else {
          return this.membershipReqService.verifyMembership(this.membershipReq);
        }
      })
      .then((res: MembershipReq) => {
        this.membershipReq = res;
        if (
          this.reqType == MembershipReqType.RequestJoin &&
          this.reqStatus == MembershipReqStatus.PendingForApproval
        ) {
          //request to join successful, pending for approval
          this.router.navigate(["/main/dashboard"]);
          // this.openSuccessDialog(
          //   this.translate.instant("JOIN_REQ.AWAIT_APPROVE")
          // );
          if (res.isPersonalOrg) {
            this.openSnackBar(
              `${this.translate.instant("JOIN_REQ.PS_REQ_SUCCESS")} ${res.orgName
              }`,
              this.translate.instant("REPEAT.OK")
            );
          }
        } else if (
          this.membershipReq.type == MembershipReqType.Invitation ||
          //auto approve join req
          //switch org after done
          (this.reqType == MembershipReqType.RequestJoin &&
            this.reqStatus == MembershipReqStatus.Completed)
        ) {
          var orgs = this.enterpriseSelector.getActiveOrgs();
          if (orgs && orgs.length != 0) {
            var org = orgs.find((r) =>
              EnterpriseState.isSameOrg(r, this.membershipReq.orgId)
            );
            if (org) {
              //accept invitation successful, switch to invitation org
              this.goToOrgDashboard(org.id);
            } else {
              //if cant find org, get org on demand
              this.flowManager.getOrgList().then(() => {
                orgs = this.enterpriseSelector.getActiveOrgs();
                var org = orgs.find((r) =>
                  EnterpriseState.isSameOrg(r, this.membershipReq.orgId)
                );
                if (org) {
                  this.goToOrgDashboard(org.id);
                } else {
                  //if org not in on demand query, show error
                  this.openSnackBar("Failed to retrieve org", "OK");
                  if (orgs == null || orgs.length == 0) {
                    //go back to login/dashboard
                    this.reject();
                  } else {
                    //go to default org
                    var org = orgs[0];
                    this.goToOrgDashboard(org.id);
                  }
                }
              });
            }
          }
        } else if (this.redirectService.isStorageShareLinkRedirect) {
          this.redirectService.handleAllStorageLink();
        } else if (this.isSignUp) {
          this.router.navigate(["/main/dashboard"], { queryParams: { status: 'gettingStarted' } });
        }
      })
      .catch((err) => {
        this.showLoading = false;
        this.showError = true;
        this.errorMsg = err.error;
      });
  }

  private openSuccessMsg(title: string, msg: string): Promise<void> {
    const dialogRef = this.dialog.open(DialogSuccessMsgComponent, {
      width: "350px",
      data: {
        title: title,
        message: msg,
      },
      disableClose: true,
    });
    return dialogRef.afterClosed().toPromise();
  }
  private goToOrgDashboard(orgId: string) {
    this.flowManager.switchOrg(orgId).catch(() => {
      this.openSnackBar("Failed to prepare org", "OK");
    });
    this.router.navigate(["/main/dashboard"]);
  }

  private openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  private openSuccessDialog(msg: string): void {
    const dialogRef = this.dialog.open(DialogRequestSuccessComponent, {
      width: "350px",
      data: { message: msg },
    });
  }

  reject() {
    if (this.isAuthenticated) {
      this.router.navigate(["/main/dashboard"]);
    } else {
      this.router.navigate(["/login"]);
    }
  }

  toggleNewPassword() {
    if (this.showNewPasswordType == "password") {
      this.showNewPasswordType = "text";
    } else {
      this.showNewPasswordType = "password";
    }
  }

  toggleConfirmPassword() {
    if (this.showConfirmPasswordType == "password") {
      this.showConfirmPasswordType = "text";
    } else {
      this.showConfirmPasswordType = "password";
    }
  }
}
