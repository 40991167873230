import { OUService } from "./../../../services/ou.service";
import { MembershipReqService } from "./../../../services/membership-req.service";
import { EnterpriseSelector } from "../../../states/enterprise.state.selector";
import { UserRole, RoleEntity } from "../../../model/userRole.model";
import { Component, OnInit, Inject } from "@angular/core";
import { OrgRoleState } from "../../../model/org.state";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "dialog-invite-users",
  templateUrl: "./dialog-invite-users.component.html",
  styleUrls: ["./dialog-invite-users.component.scss"],
})
export class DialogInviteUsersComponent implements OnInit {
  users: User[] = [];
  roles: OrgRoleState[] = [];
  isLoading = false;
  orgId: string;
  customDomainID: string;
  idxCount: number = 0;

  showAddNewUserButton = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ouService: OUService,
    public dialogRef: MatDialogRef<DialogInviteUsersComponent>,
    private membershipReqService: MembershipReqService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.addDummyFields();
    if (this.data) {
      this.orgId = this.data.orgId;
      this.customDomainID = this.data.customDomainID;
    } else {
      this.isLoading = true;
      this.dialogRef.close({
        status: "failed",
        response: "Invalid organization id. ",
      });
      return;
    }

    this.ouService
      .getRoles(this.data.orgId)
      .then((res) => {
        this.roles = res.filter(
          (x: UserRole) =>
            RoleEntity[x.normalizedName] !== RoleEntity.OWNER &&
            RoleEntity[x.normalizedName] !== RoleEntity.ADMIN
        );
      })
      .catch((err) => {
        this.openSnackBar(err && err.error ? err.error : err, "OK");
      });
  }

  private openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  addDummyFields() {
    // add user dummy
    if (this.users.length <= 0) {
      let dummy = new User(this.idxCount);
      this.idxCount++;
      this.users.push(dummy);
    }
  }

  deleteUser(guest) {
    this.users.forEach((item, index) => {
      if (item === guest) {
        this.users.splice(index, 1);
      }
    });

    if (this.users.length <= 0) {
      this.addDummyFields();
    }
  }

  addNewUser() {
    let hasDummy = this.users.find(
      (x) => !x.email && !x.firstName && !x.lastName
    );
    // dont add if there is blank field
    if (!hasDummy) {
      let dummy = new User(this.idxCount);
      this.idxCount++;
      this.users.push(dummy);
      // this.showAddNewGuestButton = false;
    }
  }

  resetFields() {
    this.users = [];
    this.addDummyFields();
    this.showAddNewUserButton = true;
  }

  inviteUsers() {
    this.isLoading = true;
    const users = this.users.filter(
      (x) =>
        x.email &&
        x.email.match(
          "([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})"
        ) &&
        x.firstName &&
        x.lastName &&
        x.roleId
    );

    if (users && users.length == 0) {
      this.openSnackBar(
        "Please enter user's first name, last name, valid email and role to invite. ",
        "OK"
      );
      this.isLoading = false;
      return;
    }

    this.membershipReqService
      .issueInvitations(this.users, this.data.orgId, this.data.orgId, this.data.customDomainID)
      .then((res) => {
        this.isLoading = false;
        this.dialogRef.close({ status: "success", response: res });
      })
      .catch((err) => {
        this.isLoading = false;
        this.openSnackBar(err && err.error ? err.error : err, "OK");
      });
  }
}

export class User {
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
  idx: number;

  constructor(idx: number) {
    this.idx = idx;
  }
}
