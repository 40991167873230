import { UserService } from './../../core/services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, HostBinding, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { routerAnimation } from "../../utils/page.animation";
import { AuthService, AuthStatus } from "../../core/auth/auth.service";
import { pocolog } from "pocolog";
import { environment as ENV } from "./../../../environments/environment";
import { Emitter, Emittable } from "@ngxs-labs/emitter";
import { AuthState } from "../../core/states/auth.state";
import { UserDataState } from "../../core/states/user-data.state";
import { AuthDataState } from "../../core/model/authData.state";
import { UserProfileState } from "../../core/model/user-profile.state";
import { AuthStateSelector } from "../../core/states/auth.state.selector";
import { SubSink } from 'subsink';
import { UserDataSelector } from '../../core/states/user-data.state.selector';
import { RedirectService } from "../../core/services/redirect.service";
import { MatDialog } from '@angular/material/dialog';
import { DialogPromptComponent } from '../../core/ui/dialog/dialog-prompt/dialog-prompt.component';
import { TurnstileComponent } from '../../core/auth/turnstile/turnstile.component';

@Component({
  selector: "app-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"],
  animations: [routerAnimation]
})
export class LoginPageComponent implements OnInit, OnDestroy {
  // Add router animation
  @HostBinding("@routerAnimation") routerAnimation = true;
  @ViewChild('elturnstile') turnstileComponent: TurnstileComponent;
  email: string;
  password: string;
  showError: boolean;
  errorMsg: string;
  showSuccess: boolean;
  successMsg: string;
  showLoading: boolean;

  // 2fa variables
  login2faType: Login2faType = Login2faType.None;
  email2fa: string;
  phone2fa: string;
  sentTo: string;
  code: string;

  // 2fa flags
  hasEmail2fa = false;
  hasPhone2fa = false;
  show2faVerifyWindow = false;
  isUnrecognizedDevice2fa = false;
  showEnter2faCode = false;

  //show password
  showPassword: boolean = false;

  // recaptcha
  showCaptcha: boolean = false;
  private _captchaRes: string;
  readonly recaptchaSiteKey: string = ENV.recaptchaSiteKey;

  //alert mail/join meet redirection
  private _redirectType: string;

  //req to join token
  private _reqJoinToken: string;

  @Emitter(AuthState.save)
  public saveAuthState: Emittable<AuthDataState>;

  // @Emitter(AppState.setDeviceId)
  // public setDeviceId: Emittable<string>;

  @Emitter(UserDataState.setUserProfile)
  public setUserProfile: Emittable<UserProfileState>;

  private _subSink: SubSink;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private auth: AuthService,
    private userService: UserService,
    private authStateSelector: AuthStateSelector,
    private userStateSelector: UserDataSelector,
    private redirectService: RedirectService,
    private translateService: TranslateService,
    private dialog: MatDialog,
  ) {
    this._subSink = new SubSink();
  }

  ngOnInit() {
    console.log("[LoginPageComponent] ngOnInit");
    this.email = "";
    this.password = "";
    this.userStateSelector.onLoggedInChanged().subscribe((isLoggedIn) => {
      console.log("[LoginPageComponent] isLoggedIn %o", isLoggedIn)
      // redirect to main if logged in
      if (isLoggedIn) this.router.navigateByUrl('/main')
    });

    this._subSink.sink = this.activatedRoute.queryParams.subscribe((params: Params) => {
      this._reqJoinToken = params && params.reqJoinToken ? params.reqJoinToken : null;
      let status: string = params.status;
      this._redirectType = params.redirectType ? params.redirectType : null;
      if (status === "resetSuccess") {
        this.showSuccess = true;
        this.successMsg =
          "Password successfully reset. Login with your new password.";
      } else if (status === "verify") {
        this.show2faVerifyWindow = true;
        this.showEnter2faCode = true;
        this.sentTo = this.authStateSelector.data.userName;
      } else if (status === "timeout") {
        this.showError = true;
        this.errorMsg =
          "You have been logged out due to inactivity. Please login again.";
      } else if (status === "expired") {
        this.showError = true;
        this.errorMsg =
          "This link has expired. Please request a new link.";
      } else if (status === "subexpired") {
        this.showError = true;
        this.errorMsg =
          "You have been force logged out by the system because your subscription is invalid or expired." +
          " Please check with system administator to proceed.";
      } else if (status === "rolechanged") {
        this.showError = true;
        this.errorMsg = "Your organization role was changed, please re-login.";
      } else if (status === "subfailed") {
        this.showError = true;
        this.errorMsg = "Error connecting to server";
      } else if (status === "ouchanged") {
        this.showError = true;
        this.errorMsg =
          "You have been moved to another organization unit, please re-login.";
      } else if (status === "orguserdeleted") {
        this.showError = true;
        this.errorMsg =
          "You have been deleted from the organization, please re-login.";
      } else if (status === "userdeleted") {
        this.showError = true;
        this.errorMsg =
          "Your account has been deleted by the administrator.";
      }else if (status === "jwtexpired") {
        this.showError = true;
        this.errorMsg = "Your session has expired. Please re-login.";
      } else if (status === "alert-redirect-login" || status === "sharelink-redirect-login") {
        this.showError = true;
        this.translateService.get('REDIRECT.NOT_LOGGED_IN').subscribe(translated => {
          this.errorMsg = translated;
        });
        // this.errorMsg = this.translateService.instant('REDIRECT.NOT_LOGGED_IN');
      } else if (status === "alert-redirect-relogin") {
        this.showError = true;
        this.errorMsg = this.translateService.instant('REDIRECT.RELOGIN');
      } else if (status === "meet-redirect-login") {
        this.showError = true;
        this.errorMsg = this.translateService.instant('REDIRECT.MEET_NOT_LOGGED_IN');
      } else if (status === "meet-redirect-relogin") {
        this.showError = true;
        this.errorMsg = this.translateService.instant('REDIRECT.MEET_RELOGIN');
      } else if (this._reqJoinToken) {
        this.showError = true;
        this.errorMsg = "Please login first before completing the request";
      } else if (status === "invite-sharelink-relogin" || status === "invite-sharelink-redirect-login") {
        this.showError = true;
        this.errorMsg = this.translateService.instant('REDIRECT.SHARE_LINK_RELOGIN');
      } else if (status === "membership-req-cancel") {
        this.showError = true;
        this.translateService.get('VERIFY_MEMBERSHIP.INVALID_REQ_CANCEL').subscribe(translated => {
          this.errorMsg = translated;
        });
      } else if (status === "membership-req-complete") {
        this.showError = true;
        this.translateService.get('VERIFY_MEMBERSHIP.INVALID_REQ_COMPLETE', { orgName: params.orgName }).subscribe(translated => {
          this.errorMsg = translated;
        });
      }
    });
  }

  resolved(captchaResponse: string) {
    this._captchaRes = captchaResponse;
    //console.log(`Resolved captcha with response: ${captchaResponse}`);
  }

  redirectToSignUp() {
    this._reqJoinToken
      ? this.router.navigate(["/register"], {
        queryParams: { reqJoinToken: this._reqJoinToken }
      })
      : this.router.navigate(["/signup"]);
  }

  /**
   * Login method
   */
  async login(event) {
    //return;
    this.showLoading = true;
    this.showError = false;
    this.showSuccess = false;
    this.successMsg = "";
    this.errorMsg = "";
    event.preventDefault();

    if (this.email === "" || !this.email || this.password === "" || !this.password) {
      this.showLoading = false;
      this.showError = true;
      this.errorMsg = "Email and password cannot be empty!";
      return;
    }

    if (!this._captchaRes && this.showCaptcha) {
      this.showLoading = false;
      this.showError = true;
      this.errorMsg = "Please enter recaptcha!";
      return;
    }

    var token = await this.turnstileComponent.getToken();
    this.auth
      .login(this.email, this.password, this._captchaRes, token)
      .then((res: AuthStatus) => {
        console.log(res);
        if (res && res.success) {
          if (!res.emailVerified) {
            pocolog.trace("email not verified");
            this.showLoading = false;
            // show verify UI
            this.goTo2faPage();
            // this.show2faVerifyWindow = true;
            // this.showEnter2faCode = true;
            // this.sentTo = this.email;
          } else {
            if (res.twoFactorEnabled || !res.recognizedDevice) {
              // 2fa login
              pocolog.trace("2fa detected");
              this.showLoading = false;
              this.isUnrecognizedDevice2fa = !res.recognizedDevice;
              if (!res.twoFactorEnabled && !res.recognizedDevice) { // cause of 2FA is unrecognized device
                const dialogRef = this.dialog.open(DialogPromptComponent, {
                  width: "500px", panelClass: "border-dialog",
                  disableClose: true,
                  data: {
                    title: this.translateService.instant('LOGIN.UNRECOGNIZED_DEVICE'),
                    content: this.translateService.instant('LOGIN.UNRECOGNIZED_DEVICE_REASON'),
                  },
                });
                dialogRef.afterClosed().subscribe((result) => {
                  if (result) {
                    this.goTo2faPage();
                  }
                });
              } else {
                // cause of 2FA is user enabled 2FA
                this.goTo2faPage();
              }
            } else {
              this.initLoginDetails();
            }
          }
        } else {
          this.showLoading = false;
          this.showError = true;
          this.errorMsg = res.error;
          if (res.showCaptcha) {
            this.showCaptcha = true;
          }
        }
      })
      .catch(error => {
        this.showLoading = false;
        this.showError = true;
        pocolog.error(error);
        this.errorMsg = error;
      });
    if (this._captchaRes) grecaptcha.reset();
  }

  private goTo2faPage() {
    this.show2faVerifyWindow = true;
    // this.router.navigate(["/acc/verify"], {
    //   queryParams: {
    //     redirectUrl: "/main/dashboard",
    //   },
    // });
  }

  async verified2fa() {
    this.show2faVerifyWindow = false;
    if (this.isUnrecognizedDevice2fa) {
      this.isUnrecognizedDevice2fa = false;
      this.userService.updateSkip2FA(true);
    }
    this.router.navigate(["/main/dashboard"]);
  }

  initLoginDetails() {
    if (this._redirectType) {
      this.redirectService.redirectHandler(parseInt(this._redirectType));
    } else if (this._reqJoinToken) {
      this.router.navigate([`/join/${this._reqJoinToken}`], { queryParams: { token: this._reqJoinToken, sendReq: true } });
    } else {
      this.router.navigateByUrl("/main");
    }


    // init login details
    // return this.userService.initLoginDetails().then(loginRes => {
    // this.showLoading = false;
    // if (loginRes) {
    //   } else {
    //     this.showError = true;
    //     this.errorMsg = 'Service unavailable. Please try again later';
    //     this.auth.logout();
    //   }
    // })
    //   .catch(error => {
    //     this.showLoading = false;
    //     this.showError = true;
    //     pocolog.error(error);
    //     this.errorMsg = 'Error contacting login service. Please try again';
    //   });
  }

  request2FAEmail() {
    this.showEnter2faCode = true;
    this.sentTo = this.email2fa;
    this.login2faType = Login2faType.Email;
    this.auth.get2FAEmail().then(res => { });
  }
  request2FAPhone() {
    this.showEnter2faCode = true;
    this.sentTo = this.phone2fa;
    this.login2faType = Login2faType.Sms;
    this.auth.get2FASms().then(res => { });
  }

  requestNewCode() {
    this.showError = false;
    this.showSuccess = false;
    if (this.login2faType === Login2faType.Email) {
      this.auth.get2FAEmail().then(res => {
        if (res.success === false) {
          this.showError = true;
          this.errorMsg = res.error;
        } else {
          this.showSuccess = true;
          this.successMsg = "Code resent successful!";
        }
      });
    } else if (this.login2faType === Login2faType.Sms) {
      this.auth.get2FASms().then(res => {
        if (res.success === false) {
          this.showError = true;
          this.errorMsg = res.error;
        } else {
          this.showSuccess = true;
          this.successMsg = "Code resent successful!";
        }
      });
    } else if (this.login2faType === Login2faType.None) {
      // send verify email
      this.auth
        .sendAccountVerificationEmail(this.email)
        .then(res => {
          this.showSuccess = true;
          this.successMsg = "Code resent successful!";
        })
        .catch(err => {
          this.showError = true;
          this.errorMsg = err.statusText;
        });
    }
  }

  verify(event) {
    this.showLoading = true;
    this.showError = false;
    this.showSuccess = false;

    if (this.login2faType === Login2faType.Email) {
      this.auth.verify2FAEmailWithJWTToken(this.code).then(res => {
        if (res && res.success) {
          this.initLoginDetails();
        } else {
          this.showLoading = false;
          if (!res.error) {
            res.error = "Unexpected error occured";
          }
          this.showError = true;
          this.errorMsg = res.error;
        }
      });
    } else if (this.login2faType === Login2faType.Sms) {
      this.auth.verify2FAPhoneWithJWTToken(this.sentTo, this.code).then(res => {
        if (res && res.success) {
          this.initLoginDetails();
        } else {
          this.showLoading = false;
          if (!res.error) {
            res.error = "Unexpected error occured";
          }
          this.showError = true;
          this.errorMsg = res.error;
        }
      });
    } else if (this.login2faType === Login2faType.None) {
      this.auth
        .exchangeTo2FAJWT()
        .then(jwt => {
          if (jwt && jwt.success) {
            this.auth
              .verify2FAEmailWithJWTToken(this.code)
              .then(res => {
                if (res && res.success) {
                  this.initLoginDetails();
                } else {
                  this.showLoading = false;
                  if (!res.error) {
                    res.error.error =
                      "Verification failed. Unexpected error occured";
                  }
                  this.showError = true;
                  this.errorMsg = res.error;
                }
              });
          }
        })
        .catch(err => {
          this.showLoading = false;
          if (!err.error) {
            err.error.error = "Unexpected error occured";
          }
          this.showError = true;
          this.errorMsg = err.error.error;
        });
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.login(event);
    }
  }

  ngOnDestroy() {
    if (this._subSink) this._subSink.unsubscribe();
  }

  unfreezeInput(el) {
    // setting input value again makes it editable
    el.target.value = el.target.value;

  }
}

export enum Login2faType {
  None = 0,
  Sms = 1,
  Email = 2
}
