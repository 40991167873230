import { LocalStorageService } from './../localStorage/local-storage.service';
import { ApiService } from './../api/api.service';
import { Injectable } from '@angular/core';

@Injectable()
export class CardService {
    constructor(private api: ApiService, private localDb: LocalStorageService) {
    }

    getCards(ouId?: string): Promise<any> {
        return this.api.getAsync<any>('v2/card/', { ouId: ouId }, this.localDb.getUserId())
            .then(data => {
                return Promise.resolve(data);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    dismissCard(cardId: string): Promise<any> {
        return this.api.postAsync<any>('v2/card/' + cardId + '/dismiss', null, this.localDb.getUserId())
            .then(res => {
                return true;
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }
}
