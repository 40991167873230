export class ShortGuid {
  public static New(): string {
    var encoded = btoa(ShortGuid.NewGuid());
    encoded = encoded.replace("/", "_").replace("+", "-");
    return encoded.substring(0, 22);
  }

  static NewGuid(): string {
    const foo: any = [1e7];
    return (foo + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }
}
