import { ShortGuid } from '../util/shortGuid';

export class FileObjState {
  orgId: string;
  chnlID: string;
  id: string;
  name: string;
  mimeType: string;
  // isImage: boolean;
  type: FileType;
  extension: string;
  icon: string;
  size: number;//in bytes
  timeStamp: number;
  url: string;//server storage path
  // realFilePath: string; // not used in web app
  fwt: string;
  fwtEncoded: boolean;
  attachmentType: AttachmentType;
  // file: File; // js file
  createdBy: string;
  createdAs: string;

  //#region Download
  downloadStatus: FileDownloadStatus;
  downloadAttempt: number;
  downloadedPath: string;
  cloudContainerName: string = null;
  //#endregion

  constructor() {
    this.id = ShortGuid.New();
    this.downloadAttempt = 0;
    this.type = FileType.File;
    this.downloadStatus = FileDownloadStatus.PendingToSend;
  }

  static parse(file: File): FileObjState {
    let fileObj = new FileObjState();
    // check if image
    if (file.type.split('/')[0] === 'image') {
      fileObj.type = FileType.Image;
    }
    // fileObj.url = file.;
    // fileObj.realFilePath = realFilePath;
    fileObj.size = file.size;
    fileObj.name = file.name;
    fileObj.extension = this.getFileExtension(file.name);
    fileObj.mimeType = file.type;
    // fileObj.file = file;
    return fileObj;
  }

  private static getFileExtension(fileName: string): string {
    if (fileName) {
      return (/[.]/.exec(fileName)
        ? /[^.]+$/.exec(fileName).toString()
        : ""
      ).toLowerCase();
      // file.extension=file.extension.split('?')[0].toLowerCase();
    }
  }
}

export enum FileType {
  Image,
  File,
  Audio
}

export enum FileDownloadStatus {
  InQueue,
  Downloading,
  Ready,
  Failed,
  PendingToDownload,
  PendingToSend,
  Sending,
  ReadyToDownload
}

export enum AttachmentType {
  None = 0,
  Image = 1,
  File = 2
}