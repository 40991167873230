import { ShortGuid } from "../util/shortGuid";

export class CloudObjState {
  id: string;
  orgId: string;
  appId: string;
  path: string;
  displayName: string; //name to be displayed in upload/download notification
  files: File[];
  storageType: CloudStorageType;
  type: CloudObjType;
  get isCompleted(){
    return this.status == CloudObjStatus.Completed;
  }
  get isError(){
    return this.status == CloudObjStatus.Error;
  }
  status: CloudObjStatus = CloudObjStatus.None;//upload/download status
  progress: number;//in percentage
  error: any;
  estimatedSize: number;

  constructor(
    orgId: string,
    appId: string,
    path: string,
    files: File[],
    storageType: CloudStorageType,
    type: CloudObjType,
    name: string = null,
    size: number = 0,
    status: CloudObjStatus = null
  ) {
    this.id = ShortGuid.New(),
    this.orgId = orgId;
    this.appId = appId;
    if (path) {
      if (storageType == CloudStorageType.Personal) {
        path = path.replace("_default", "/");
        this.path = path;
      } else {
        path = path.replace("users/", "");
        if (path.indexOf("/") > 0) {
          this.path = `/${path.substring(path.indexOf("/"))}`;
        } else {
          this.path = "/";
        }
      }
    }
    this.displayName = name ? name : this.path;
    this.files = files;
    this.storageType = storageType;
    this.type = type;
    this.estimatedSize = size;
    if(status != null){
      this.status = status;
    }
  }
}

export enum CloudStorageType {
  App,
  User,
  Personal,
}

export enum CloudObjType {
  Upload,
  Download,
  Move,
  Copy,
  Zip,
  ExportChat
}

export enum CloudObjStatus {
  None,
  Preparing,
  InProgress,
  Completed,
  Error
}