
export class FileObj {

    id: string;
    source: FileSource;
    name: string;
    url: string;
    mimeType: string;
    isImage: boolean;
    fullUrl: string;
    extension: string;
    icon: string;
    size: string;
    byteSize: number;
    createdOn: string;
    createdTime: string;
    timeStamp: number;
    downloadedPath: string;
    realFilePath: string;
    isDownloading: boolean;
    state: FileState;
    downloadAttempt: number;
    fwt: string;
    fwtEncoded: boolean;

    constructor(url: string, source: FileSource, realFilePath?: string, size?: number, name?: string) {
        this.isImage = false;
        this.source = source;
        this.url = url;
        this.name = "";
        this.isDownloading = false;
        this.realFilePath = realFilePath;
        this.byteSize = size;
        this.downloadAttempt = 0;
        this.name = name;
        this.parseMetadata();
        this.state = FileState.Pending;
        this.fwtEncoded = false;
    }

    static parse(d: any) {
        if (d) {
            let file = new FileObj(d.url, d.source);
            file.id = d.id;
            file.name = d.name;
            file.mimeType = d.mimeType;
            file.isImage = d.isImage;
            file.fullUrl = d.fullUrl;
            file.extension = d.extension;
            file.icon = d.icon;
            file.size = d.size;
            file.byteSize = d.byteSize;
            file.realFilePath = d.realFilePath;
            file.downloadedPath = d.downloadedPath;
            file.createdOn = d.createdon;
            file.timeStamp = d.timeStamp;
            file.createdTime = d.createdTime;
            file.fwt = d.fwt;
            file.fwtEncoded = d.fwtEncoded;

            return file;
        }
    }

    private parseMetadata() {
        this.getFileNameWithExtension();
        this.getFileExtension();
        this.getFileMimeType();
        this.getFileIcon();
        this.getFileSizeWithUnit();
    }

    private getFileNameWithExtension() {
        if (!this.name) {
            if ((this.realFilePath && this.realFilePath.length !== 0) || (this.url && this.url.length !== 0)) {
                let path = this.realFilePath ? this.realFilePath : this.url;
                let startIndex: number = Math.max(path.lastIndexOf('\\'), path.lastIndexOf('/')) + 1;
                let fileName: string = path.substring(startIndex);
                this.name = fileName.split('?')[0]; // replace special character and space with underscore
            } else {
                var date = new Date();
                var datestring = date.getFullYear() + '_' + date.getMonth() + 1 + '_' + date.getDate() + '_' + date.getTime();
                this.name = this.isImage ? "IMG_" + datestring + ".jpg" : "FILE_" + datestring + ".pdf";
            }
        }
    }

    private getFileExtension() {
        if (!this.extension) {
            if (this.name) {
                this.extension = (/[.]/.exec(this.name) ? /[^.]+$/.exec(this.name).toString() : "");
                this.extension = this.extension.split('?')[0];
            } else {
                this.extension = "";
            }
        }
    }

    private getFileMimeType() {
        if (!this.mimeType) {
            this.mimeType = (FileExtension.supportedFileExtension[this.extension] !== undefined ?
                FileExtension.supportedFileExtension[this.extension]["mime"].trim() : "application/octet-stream");
        }
    }

    private getFileIcon() {
        let docType: DocType = (FileExtension.supportedFileExtension[this.extension] !== undefined ?
            FileExtension.supportedFileExtension[this.extension]["type"] : DocType.UNKNOWN);
        let cssClass = "";

        switch (docType) {
            case DocType.Doc: cssClass = "custom-word"; break;
            case DocType.XLS: cssClass = "custom-excel"; break;
            case DocType.PPT: cssClass = "custom-powerpoint"; break;
            case DocType.XML: cssClass = "custom-code"; break;
            case DocType.TXT: cssClass = "custom-text"; break;
            case DocType.PDF: cssClass = "custom-pdf"; break;
            case DocType.VIDEO: cssClass = "custom-video"; break;
            case DocType.AUDIO: cssClass = "custom-audio"; break;
            case DocType.ARCHIVE: cssClass = "custom-archive"; break;
            case DocType.IMAGE: cssClass = "custom-image"; break;
            default: cssClass = "custom-unknown-file"; break;
        }
        this.icon = cssClass;
    }

    private getFileSizeWithUnit() {
        if (this.byteSize > 0) {
            let thresh = 1024;
            let units: string[] = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            if (Math.abs(this.byteSize) < thresh) {
                this.size = this.byteSize.toFixed(1) + ' B';
            }

            let u = -1;
            do {
                this.byteSize = this.byteSize / thresh;
                ++u;
            } while (Math.abs(this.byteSize) >= thresh && u < units.length - 1);
            this.size = this.byteSize.toFixed(1) + ' ' + units[u];
        } else {
            this.size = "0 B";
        }
    }

}

export enum FileSource {
    Message,
    Post,
    Profile
};

export enum FileState {
    InQueue,
    Downloading,
    Ready,
    Failed,
    Pending
};

enum DocType {
    Doc,
    XLS,
    PPT,
    XML,
    TXT,
    PDF,
    VIDEO,
    AUDIO,
    ARCHIVE,
    IMAGE,
    UNKNOWN,
};

export class FileExtension {
    static supportedFileExtension: {} = {
        // word
        doc: { type: DocType.Doc, mime: "application/msword" },
        dot: { type: DocType.Doc, mime: "application/msword" },
        docx: { type: DocType.Doc, mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" },
        dotx: { type: DocType.Doc, mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.template" },
        dotm: { type: DocType.Doc, mime: "application/vnd.ms-word.template.macroenabled.12" },
        docm: { type: DocType.Doc, mime: "application/vnd.ms-word.document.macroenabled.12" },
        // excel
        xls: { type: DocType.XLS, mime: "application/vnd.ms-excel" },
        xlt: { type: DocType.XLS, mime: "application/vnd.ms-excel" },
        xlm: { type: DocType.XLS, mime: "application/vnd.ms-excel" },
        xla: { type: DocType.XLS, mime: "application/vnd.ms-excel" },
        xll: { type: DocType.XLS, mime: "application/vnd.ms-excel" },
        xlw: { type: DocType.XLS, mime: "application/vnd.ms-excel" },
        xlsx: { type: DocType.XLS, mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
        xltm: { type: DocType.XLS, mime: "application/vnd.ms-excel.addin.macroenabled.12" },
        xltx: { type: DocType.XLS, mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
        xlsm: { type: DocType.XLS, mime: "application/vnd.ms-excel.sheet.macroenabled.12" },
        xlsb: { type: DocType.XLS, mime: "application/vnd.ms-excel.sheet.binary.macroenabled.12" },
        xlam: { type: DocType.XLS, mime: "application/vnd.ms-excel.addin.macroenabled.12" },
        // power point
        ppt: { type: DocType.PPT, mime: "application/vnd.ms-powerpoint" },
        pps: { type: DocType.PPT, mime: "application/vnd.ms-powerpoint" },
        pptx: { type: DocType.PPT, mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation" },
        pptm: { type: DocType.PPT, mime: "application/vnd.ms-powerpoint.presentation.macroenabled.12" },
        ppsx: { type: DocType.PPT, mime: "application/vnd.openxmlformats-officedocument.presentationml.slideshow" },
        potx: { type: DocType.PPT, mime: "application/vnd.openxmlformats-officedocument.presentationml.template" },
        potm: { type: DocType.PPT, mime: "application/vnd.ms-powerpoint.template.macroenabled.12" },
        ppsm: { type: DocType.PPT, mime: "application/vnd.ms-powerpoint.slideshow.macroenabled.12" },
        ppam: { type: DocType.PPT, mime: "application/vnd.ms-powerpoint.addin.macroenabled.12" },
        sldx: { type: DocType.PPT, mime: "application/vnd.openxmlformats-officedocument.presentationml.slide" },
        sldm: { type: DocType.PPT, mime: "application/vnd.ms-powerpoint.slide.macroenabled.12" },
        // other text document
        odt: { type: DocType.UNKNOWN, mime: "application/vnd.oasis.opendocument.text" },
        pub: { type: DocType.UNKNOWN, mime: "application/x-mspublisher" },
        xps: { type: DocType.UNKNOWN, mime: "application/vnd.ms-xpsdocument" },
        pdf: { type: DocType.PDF, mime: "application/pdf" },
        txt: { type: DocType.TXT, mime: "text/plain" },
        rtf: { type: DocType.TXT, mime: "application/rtf" },
        rtx: { type: DocType.TXT, mime: "text/richtext" },
        xml: { type: DocType.XML, mime: "text/xml" },
        // archive
        zip: { type: DocType.ARCHIVE, mime: "application/zip" },
        rar: { type: DocType.ARCHIVE, mime: "application/x-rar-compressed" },
        // video
        avi: { type: DocType.VIDEO, mime: "video/x-msvideo" },
        mpeg: { type: DocType.VIDEO, mime: "video/mpeg" },
        mp4: { type: DocType.VIDEO, mime: "video/mp4" },
        mov: { type: DocType.VIDEO, mime: "video/quicktime" },
        mpg: { type: DocType.VIDEO, mime: "video/mpeg" },
        wmv: { type: DocType.VIDEO, mime: "video/x-ms-wmv" },
        ogv: { type: DocType.VIDEO, mime: "video/ogg" },
        // audio
        mp3: { type: DocType.AUDIO, mime: "audio/mpeg" },
        m4a: { type: DocType.AUDIO, mime: "audio/m4a" },
        ogg: { type: DocType.AUDIO, mime: "audio/ogg" },
        wav: { type: DocType.AUDIO, mime: "audio/x-wav" },
        // apple keynote
        key: { type: DocType.UNKNOWN, mime: "application/vnd.apple.keynote" },
        // image
        jpg: { type: DocType.IMAGE, mime: "image/jpeg" },
        jpeg: { type: DocType.IMAGE, mime: "image/jpeg" },
        png: { type: DocType.IMAGE, mime: "image/png" },
        bmp: { type: DocType.IMAGE, mime: "image/bmp" },
        ico: { type: DocType.IMAGE, mime: "image/x-icon" },
        svg: { type: DocType.IMAGE, mime: "image/svg+xml" },
        gif: { type: DocType.IMAGE, mime: "image/gif" }
    };

}

