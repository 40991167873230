export class UserMentions {
    id: string;
    displayName: string;
    startIndex: number;
  
    get endIndex(): number {
      if (this.startIndex == null) return null;
  
      return this.startIndex + this.displayName.length - 1;
    }
  
    constructor(id: string, name: string) {
      this.id = id;
      this.displayName = name;
    }
  
    moveToRight(increment: number = 1) {
      this.startIndex += increment;
    }

    // updates this UserMention's start index given text with user-friendly mentions (in format @displayname)
    updateStartIndex(text: string = '') {
      let newIndex = text.indexOf('@' + this.displayName);
      this.startIndex = newIndex > 0 ? newIndex+1 : this.startIndex;
    }
  
    //from @{id,name} to userMention object
    static constructFromString(mentionString: string): UserMentions {
      try {
        var mention = mentionString
          .substr(2, mentionString.length - 3)
          .split(",");
  
        return new UserMentions(mention[1], mention[0]);
      } catch {
        return null;
      }
    }
  }
  