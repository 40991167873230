import { Observable } from 'rxjs';
import { DownloadService } from './download.service';
import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class OrgService {
  private _apiUrl: string = environment.apiLink;

  constructor(private api: ApiService
    , private _downloadService: DownloadService) { }

  getOrgs() {
    return this.api
      .getAsync("v2/org/list")
      .then((data) => {
        return Promise.resolve(data);
      })
      .catch((res) => {
        return Promise.reject(res.error);
      });
  }

  getContractPDF(contractId: string): Observable<any> {
    return this._downloadService.downloadFileWithProgress(this._apiUrl + "/v2/org/contract/" + contractId + "/pdf", false);
}
}
