import { Convert } from "./convert";

export class MessageKey {
  public Key: string;
  public IV: string;

  public encode(): string {
    var serialized = JSON.stringify(this);
    var encoder = new TextEncoder();

    return Convert.toBase64String(encoder.encode(serialized));
  }

  public static decode(source: string): MessageKey {
    var uint8 = Convert.fromBase64String(source);
    var decoder = new TextDecoder();
    var decoded = decoder.decode(uint8);
    var json = JSON.parse(decoded);
    if (json) {
      var msgKey = new MessageKey();
      msgKey.IV = json.IV;
      msgKey.Key = json.Key;
      return msgKey;
    } else {
      return null;
    }
  }
}
