import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MembershipReqService } from '../../../../core/services/membership-req.service';

@Component({
  selector: 'app-dialog-delete-invite',
  templateUrl: './dialog-delete-invite.component.html',
  styleUrls: ['./dialog-delete-invite.component.scss']
})
export class DialogDeleteInviteComponent implements OnInit {
  email: any;
  id: any;
  ouId: any;
  isLoading = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
  private membershipReqService: MembershipReqService,
  public dialogRef: MatDialogRef<DialogDeleteInviteComponent>) { }

  ngOnInit() {
    this.email = this.data.email;
    this.id = this.data.id;
    this.ouId = this.data.ouId;
  }

  deleteInvite() {
    this.isLoading = true;
    this.membershipReqService
      .deleteInvitation(this.id)
      .then((res) => {
        this.isLoading = false;
        this.dialogRef.close("success");
      })
      .catch((err) => {
        this.isLoading = false;
        this.dialogRef.close(err);
      });
  }
}
