import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { ChatReplyToComponent } from "../../chat/chat-room/chat-replyto/chat-replyto.component";
import { MessageType } from "../model/message.model";
import { ReplyToPipe } from "../pipe/reply-to.pipe";

@Directive({
  selector: "[el-reply-to]",
  providers: [ReplyToPipe],
})
export class ChatReplyToDirective {
  @Input()
  replyTo: string;
  @Input()
  replyToMsg: string;
  @Output()
  onReplyClicked = new EventEmitter<string>();

  type: MessageType;
  senderName: string;
  fileName: string;
  meetingTitle: string;
  sendTime: number;
  msgId: string;
  content: string;

  constructor(
    private elemRef: ElementRef,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    private replyToPipe: ReplyToPipe
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.transform(changes.replyTo.currentValue);
    }
  }

  private transform(replyTo: string) {
    if (this.replyToMsg == null || this.replyToMsg == "") return;

    replyTo = this.replyToPipe.transform(replyTo);
    if (!replyTo) return;
    this.renderer.setProperty(this.elemRef.nativeElement, "innerHTML", replyTo);

    var replyToEl = document.getElementsByTagName("el-reply-to").item(0);
    if (replyToEl) {
      this.content = replyToEl.innerHTML;
      for (let i = 0; i < replyToEl.attributes.length; i++) {
        let attribute = replyToEl.attributes[i] as Attr;
        let key = attribute.name;
        let value = attribute.value;
        if (key == "sender") {
          this.senderName = value;
        }
        if (key == "ts") {
          this.sendTime = parseInt(value);
        }

        if (key == "eventid") {
          this.msgId = value;
        }

        if (key == "type") {
          this.type = MessageType[value];
        }

        if (key == "filename") {
          this.fileName = value;
        }

        if (key == "meetingtitle") {
          this.meetingTitle = value;
        }
      }

      var el = this.elemRef.nativeElement as HTMLElement;

      //remove existing replyTo component if any
      for (var i = 0; i < el.parentElement.children.length; i++) {
        let child = el.parentElement.children.item(i);
        if (child.tagName.toLowerCase() == "app-chat-replyto")
          el.parentElement.removeChild(child);
      }

      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          ChatReplyToComponent
        );
      var componentRef =
        this.viewContainerRef.createComponent(componentFactory);
      var replyToComponent = (componentRef.hostView as EmbeddedViewRef<any>)
        .rootNodes[0] as HTMLElement;

      var el: HTMLElement = this.elemRef.nativeElement;
      //insert reply to before current
      el.parentElement.insertBefore(replyToComponent, el);

      (<ChatReplyToComponent>componentRef.instance).senderName =
        this.senderName;
      (<ChatReplyToComponent>componentRef.instance).fileName = this.fileName;
      (<ChatReplyToComponent>componentRef.instance).meetingTitle =
        this.meetingTitle;
      (<ChatReplyToComponent>componentRef.instance).sendTime = this.sendTime;
      (<ChatReplyToComponent>componentRef.instance).msgId = this.msgId;
      (<ChatReplyToComponent>componentRef.instance).content = this.content;
      (<ChatReplyToComponent>componentRef.instance).type = this.type;
      (<ChatReplyToComponent>componentRef.instance).onClick.subscribe((res) => {
        this.onReplyClicked.emit(res);
      });

      //remove existing reply to
      el.removeChild(el.firstChild);

      this.changeDetectorRef.detectChanges();
    }
  }
}
