import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-success-msg',
  templateUrl: './dialog-success-msg.component.html',
  styleUrls: ['./dialog-success-msg.component.scss']
})
export class DialogSuccessMsgComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
