import { AccessPermission, AccessRight } from "./cloud-access-right";
import { CloudFile } from "./cloud-file";

export class SharedDriveFile extends CloudFile {
  driveId: string;

  hasDrivePermission(
    userId: string,
    permission: AccessPermission,
    driveGrantees: AccessRight[]
  ) {
    var grantees = driveGrantees;
    if (!grantees || grantees.length == 0) return false;
    var currentGrantee = grantees
      .filter((g) => !g.isSharedAccess)
      .find((g) => g.identity == userId);
    if (!currentGrantee) return false;
    return (
      (currentGrantee.permission & permission) != 0 ||
      currentGrantee.permission == permission
    );
  }
  
  static parseWithDriveId(dto: any, driveId: string): SharedDriveFile {
    if (!dto) return null;
    var file = new SharedDriveFile();
    file.container = dto.container;
    file.name = dto.displayName;
    file.path = dto.path;
    file.createdOn = dto.createdOn;
    file.modifiedOn = dto.modifiedOn;

    file.contentType = dto.contentType;
    file.sizeInBytes = dto.sizeInBytes;
    file.sizeInString = dto.sizeInString;
    file.extension = dto.extension;

    file.enableNotifications = dto.enableNotifications;
    file.ownerId = dto.ownerIdentity;

    if (dto.grantees) {
      file.grantees = dto.grantees.map((f) => AccessRight.parse(f));
    }

    file.driveId = driveId;
    return file;
  }
}
