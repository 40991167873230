import { Component, Input, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Phone, ContactDetailLabel } from '../../../model/contact.model';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'input-contact-phone',
    templateUrl: './input-contact-phone.component.html',
    styleUrls: ['./input-contact-phone.component.scss']
})
export class InputContactPhoneComponent implements OnInit {
    // 2-way binding
    @Output() phonesChange: EventEmitter<Phone[]> = new EventEmitter<Phone[]>();
    @Input()
    get phones(): Phone[] {
        return this._phones;
    }
    set phones(value) {
        this._phones = [...value];
        if (this._phones.length <= 0) {
            this.addDummyFields();
        }
    }
    _phones: Phone[] = [];

    @Input() showIcon = false;

    showAddPhoneButton = true;
    @ViewChild('form', { static: true }) form: NgForm;

    constructor() {
    }

    ngOnInit() {
        this.addDummyFields();
    }

    // addDummyFields() {
    //     if (this._phones.length <= 0) {
    //         let dummy: Phone = {
    //             phoneNumber: "",
    //             labelName: "Work",
    //             label: ContactDetailLabel.Work,
    //         }
    //         this._phones.push(dummy);
    //     }
    //     this.determineAddButtonState();
    // }

    // deletePhone(phone) {
    //     if (this._phones.length > 1) {
    //         this._phones.forEach((item, index) => {
    //             if (item === phone) {
    //                 this._phones.splice(index, 1);
    //             }
    //         });
    //     } else {
    //         // reset
    //         this._phones = [];
    //         this.addNewPhone();
    //     }
    //     this.determineAddButtonState();
    //     this.updateBindedObject();
    // }

    // onChangePhoneInput(event) {
    //     // remove blank fields except first field
    //     if (this._phones.length > 1) {
    //         this._phones = this._phones.filter(x => x.phoneNumber);
    //         // failsafe add if no field exists
    //         if (this._phones.length <= 0) {
    //             this.addNewPhone();
    //         }
    //     }
    //     this.determineAddButtonState();
    //     this.updateBindedObject();
    // }

    determineAddButtonState() {
        // determine if add button should show
        this.showAddPhoneButton = true;

        // hide if there is only 1 line and its empty
        if (this._phones.length === 1 && !this._phones[0].phoneNumber) {
            this.showAddPhoneButton = false;
        }
    }

    // addNewPhone() {
    //     let dummy: Phone = {
    //         phoneNumber: "",
    //         labelName: "Work",
    //         label: ContactDetailLabel.Work,
    //     }
    //     this._phones.push(dummy);
    //     this.showAddPhoneButton = false;
    // }

    deletePhone(phone) {
        this._phones = this._phones.filter(p => p !== phone);
        this.determineAddButtonState();
        this.updateBindedObject();
    }
    
    addDummyFields() {
        if (this._phones.length === 0) {
            this.addNewPhone();
        }
    }

    addNewPhone() {
        let dummy: Phone = {
            phoneNumber: "",
            labelName: "Work",
            label: ContactDetailLabel.Work,
        };
        this._phones.push(dummy);
        this.determineAddButtonState();
    }

    onChangePhoneInput(event) {
        // Validate each phone number
        this._phones = this._phones.map(phone => {
            if (this.isValidPhoneNumber(phone.phoneNumber)) {
                return phone;
            } else {
                return { ...phone, phoneNumber: "" }; // Clear the invalid phone number
            }
        });
    
        // Remove empty phone entries, except if it's the only one
        if (this._phones.length > 1) {
            this._phones = this._phones.filter(phone => phone.phoneNumber);
        }
    
        // Failsafe: Ensure at least one phone entry is present
        if (this._phones.length === 0) {
            this.addNewPhone();
        }
    
        this.determineAddButtonState();
        this.updateBindedObject();
    }
    
    isValidPhoneNumber(phoneNumber: string | number): boolean {
        // If phoneNumber is a number, convert it to a string for validation
        let phoneStr = typeof phoneNumber === 'number' ? phoneNumber.toString() : phoneNumber;
    
        // Implement phone number validation logic here
        return phoneStr && /^[0-9+\-\s()]+$/.test(phoneStr);
    }

    updateBindedObject() {
        // this.phonesChange.emit(this._phones.filter(x => x.phoneNumber));
        this.phonesChange.emit(this._phones);
    }
}
