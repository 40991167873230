import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './../api/api.service';
import { ErrorStateMatcher } from '@angular/material/core';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class InvitationService {
    constructor(private api: ApiService, private auth: AuthService) { }

    resend(reqId: string, orgUnitId: string) {
        return this.api.postAsync('invite/reissue/' + reqId, JSON.stringify(orgUnitId), orgUnitId)
            .then(data => {
                return Promise.resolve(true);
            }).catch(res => {
                return Promise.reject(res.error);
            });
    }

    delete(reqId: string, orgUnitId: string) {
        return this.api.deleteAsync('invite/' + reqId, null, orgUnitId)
            .then(data => {
                return Promise.resolve(true);
            }).catch(res => {
                return Promise.reject(res.error);
            });
    }

    send(email: string, firstName: string, lastName: string, roleId: string, orgUnitId: string, preassignedSettings: any,
        ccEmail: string): Promise<any> {
        let params: any = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            roleId: roleId,
            orgUnitId: orgUnitId,
            preassignedSettings: preassignedSettings,
            ccEmails: [ccEmail]
        };
        return this.api.postAsync<any>('invite/issue', params, orgUnitId)
            .then(data => {
                return Promise.resolve(true);
            }).catch(res => {
                this.api.handleError(res);
                return Promise.reject(res.error);
            });
    }

    validateInvite(token: string) {
        return this.api.getAsync<any>('invite/' + token)
            .then(data => {
                return Promise.resolve(data);
            }).catch(err => {
                return Promise.reject(err.error);
            });
    }

    confirmInvite(token: string, password: string, firstName: string, lastName: string, email: string, orgUnitRole: string, captcha?: string) {
        let params: any = {
            password: password,
            firstName: firstName,
            lastName: lastName,
            email: email,
            role: orgUnitRole,
            RecaptchaToken: captcha
        };
        return this.api.postAsync<any>('invite/' + token, params)
            .then(data => {
                // call login success
                this.auth.successLogin(data).then(() => {
                    return Promise.resolve(data);
                });
            }).catch(err => {
                return Promise.reject(err.error);
            });
    }

    // old api for get invite list in org
    // getList(orgUnitId: string) {
    //     return this.api.getAysnc<any>('invite/' + orgUnitId)
    //         .then(data => {
    //             return Promise.resolve(data);
    //         }).catch(err => {
    //             return Promise.reject(err.error);
    //         });
    // }

    // public _getInvitationQuery(orgUnitId: string) {
    //     return this.api.ApiUrl + '/invite/list/' + orgUnitId;
    // }
}
