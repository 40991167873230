import { FileObj, FileSource } from "./file-obj.model";
import { Participant } from "./participant.model";
import { Room } from "./room.model";
import { PostChatType, PostChat } from "./postchat.model";
import { Subscription, Subject } from "rxjs";
import { EntityStatus } from "../enum/entity-status.enum";
import { environment } from "../../../environments/environment";
import { UserMentions } from "./user-mentions";
import { FilenameToMimeType } from '../util/filename-to-mimetype';

export class Message {
  id: string;
  tempId: string;
  sendStatus: MessageSendStatus;
  serverTimeStamp: number;
  serverDateTime: string;
  serverDisplayDateTime: string;
  roomMatrixId: string;
  typeValue: string;
  type: MessageType;
  content: string;
  senderMatrixId: string;
  sender: Participant;
  status: MessageStatus;
  orgUnitId: string;
  isPostChat: boolean;
  onStatusUpdateSubject: Subject<any>;
  onResend: Subscription;
  mentions: UserMentions[];
  isEncrypted: boolean;
  plaintext: string;

  // media
  fileName: string;
  file: FileObj;
  mediaId: string;
  mediaUrl: string;
  fileSize: number;
  mimeType: string;
  mediaFile: any;
  fwt: string;
  fwtEncoded: boolean;

  // metadata card
  showCard = true;
  isMetaCardEnabled = true;

  // meet
  url: string;
  exp: number; // jwt expiry
  iat: number; // jwt issued at

  // audio msg
  customDuration: number;

  flags: MessageFlag[];
  isStarred: boolean;
  isReplyLater: boolean;

  // UI var
  isExp = false;
  isImgLoaded = false;

  constructor(id: string) {
    this.id = id;
    this.sendStatus = MessageSendStatus.Sent;
    this.customDuration = -1;
    this.showCard = false;
    this.isMetaCardEnabled = true;
    this.isPostChat = false;
    this.onStatusUpdateSubject = new Subject<any>();
    this.flags = [];
    this.isStarred = false;
    this.isReplyLater = false;
    this.fwtEncoded = false;
    this.mentions = [];
    this.isEncrypted = false;
    this.plaintext = "";
  }

  updateStatus(status: MessageSendStatus) {
    this.sendStatus = status;
    this.onStatusUpdateSubject.next(status);
  }

  static newMessage(roomId: string, content: string, msgType?: MessageType) {
    var now = new Date();
    var msg = new Message(now.getTime().toString());
    msg.content = content;
    msg.sendStatus = MessageSendStatus.PendingToSent;
    msg.serverTimeStamp = now.getTime();
    msg.roomMatrixId = roomId;
    msg.tempId = msg.id;
    if (msgType) {
      msg.type = msgType;
    } else {
      msg.type = MessageType.Text;
    }
    return msg;
  }

  static parseList(data: any[], participant: Participant[]) {
    let result: Message[] = [];
    if (data) {
      data.forEach(d => {
        let msg = this.parse(d, participant);

        result.push(msg);
      });
    }
    return result;
  }

  passByValue() {
    let obj = Object.create(this);
    obj.id = this.id;
    obj.tempId = this.tempId;
    obj.orgUnitId = this.orgUnitId;
    obj.sendStatus = this.sendStatus;
    obj.status = this.status;
    obj.serverTimeStamp = this.serverTimeStamp;
    obj.serverDateTime = this.serverDateTime;
    obj.serverDisplayDateTime = this.serverDisplayDateTime;
    obj.roomMatrixId = this.roomMatrixId;
    obj.typeValue = this.typeValue;
    obj.type = this.type;
    obj.content = this.content;
    obj.senderMatrixId = this.senderMatrixId;
    obj.sender = this.sender;
    obj.mediaId = this.mediaId;
    obj.mediaUrl = this.mediaUrl;
    obj.isEncrypted = this.isEncrypted;

    obj.showCard = this.showCard;
    obj.isMetaCardEnabled = this.isMetaCardEnabled;

    obj.customDuration = this.customDuration;

    obj.fileSize = this.fileSize;
    obj.mimeType = this.mimeType;
    obj.fileName = this.fileName;

    obj.flags = this.flags ? this.flags : [];
    obj.isStarred = this.isStarred;
    obj.isReplyLater = this.isReplyLater;

    obj.fwt = this.fwt;
    obj.fwtEncoded = this.fwtEncoded;
    obj.mentions = this.mentions ? this.mentions : [];
    switch (this.type) {
      case 1:
        {
          // image
          if (this.file == null) {
            obj.file = new FileObj(obj.mediaUrl, FileSource.Message);
            obj.file.isImage = true;
            obj.file.mimeType = obj.mimeType;
            obj.file.name = obj.fileName;
            obj.file.byteSize = obj.fileSize;
            obj.file.id = obj.mediaId;
          } else {
            obj.file = FileObj.parse(this.file);
          }
        }
        break;
      case 2:
        {
          // file
          if (this.file == null) {
            obj.file = new FileObj(obj.mediaUrl, FileSource.Message);
            obj.file.mimeType = obj.mimeType;
            obj.file.name = obj.fileName;
            obj.file.byteSize = obj.fileSize;
            obj.file.id = obj.mediaId;
          } else {
            obj.file = FileObj.parse(this.file);
          }
        }
        break;
      case 3:
        {
          // audio
          obj.customDuration = this.customDuration;
        }
        break;
      case 4:
        {
          // meet
          obj.url = this.url;
          obj.iat = this.iat;
          obj.exp = this.exp;
        }
        break;
    }
    return obj;
  }

  static parse(d: any, participant?: Participant[]) {
    if (d) {
      let msg = new Message(d.id);
      msg.fwt = d.fwt;
      msg.fwtEncoded = d.fwtEncoded;
      msg.tempId = d.tempId;
      msg.orgUnitId = d.orgUnitId;
      msg.sendStatus = d.sendStatus;
      msg.status = d.status;
      msg.serverTimeStamp = d.serverTimeStamp;
      msg.serverDateTime = d.serverDateTime;
      msg.serverDisplayDateTime = d.serverDisplayDateTime;
      msg.roomMatrixId = d.roomMatrixId;
      msg.typeValue = d.typeValue;
      msg.type = d.type;
      msg.content = d.content;
      msg.senderMatrixId = d.senderMatrixId;
      msg.mentions = d.mentions ? d.mentions : [];
      if (msg.senderMatrixId) {
        if (participant) {
          msg.sender = participant.find(p => p.matrixId === msg.senderMatrixId);
        } else {
          msg.sender = Participant.parse({ matrixId: msg.senderMatrixId });
        }
      }
      msg.isEncrypted = d.isEncrypted ? d.isEncrypted : msg.isEncrypted;

      // msg.sender = d.senderMatrixId && participant ? participant.find
      // (p=>p.matrixId==msg.senderMatrixId) : Participant.parse({ matrixId: d.senderMatrixId });
      msg.mediaId = d.mediaId;
      msg.mediaUrl = d.mediaUrl;

      msg.showCard = d.showCard;
      msg.isMetaCardEnabled = d.isMetaCardEnabled;

      msg.customDuration = d.customDuration;

      msg.fileSize = d.fileSize;
      msg.mimeType = d.mimeType;
      msg.fileName = d.fileName;

      msg.flags = d.flags ? d.flags : [];
      msg.isStarred =
        msg.flags.findIndex(f => f === MessageFlag.STARRED) !== -1;
      msg.isReplyLater =
        msg.flags.findIndex(f => f === MessageFlag.REPLYLATER) !== -1;

      switch (d.type) {
        case 1:
          {
            // image
            if (d.file == null) {
              msg.file = new FileObj(msg.mediaUrl, FileSource.Message);
              msg.file.isImage = true;
              msg.file.mimeType = msg.mimeType;
              msg.file.name = msg.fileName;
              msg.file.byteSize = msg.fileSize;
              msg.file.id = msg.mediaId;
              msg.file.fwt = msg.fwt;
              msg.file.fwtEncoded = msg.fwtEncoded;
            } else {
              msg.file = FileObj.parse(d.file);
            }
          }
          break;
        case 2:
          {
            // file
            if (d.file == null) {
              msg.file = new FileObj(msg.mediaUrl, FileSource.Message);
              msg.file.mimeType = msg.mimeType;
              msg.file.name = msg.fileName;
              msg.file.byteSize = msg.fileSize;
              msg.file.id = msg.mediaId;
              msg.file.fwt = msg.fwt;
              msg.file.fwtEncoded = msg.fwtEncoded;
            } else {
              msg.file = FileObj.parse(d.file);
            }
          }
          break;
        case 3:
          {
            // audio
            msg.customDuration = d.customDuration;
          }
          break;
        case 4:
          {
            // meet
            msg.url = d.url;
            msg.iat = d.iat;
            msg.exp = d.exp;

            // check for expiry
            if (msg.exp) {
              if (this.checkExpiry(msg.exp)) {
                msg.isExp = true;
              } else {
                let expiryTime = msg.exp * 1000;
                let currentTime = new Date().getTime();
                let timer = expiryTime - currentTime;
                // set to expired when timer hits
                setTimeout(() => {
                  msg.isExp = true;
                }, timer);
              }
            }
          }
          break;
      }
      return msg;
    }
  }

  parseSender(room: Room) {
    if (this.senderMatrixId) {
      this.sender = room.participants.find(
        p => p.matrixId === this.senderMatrixId
      );
    }
  }

  // parseToPostChat(): PostChat {
  //     let postChat = new PostChat(this.id);
  //     postChat.content = this.content;
  //     postChat.senderId = this.senderMatrixId;
  //     switch (this.type) {
  //         case MessageType.Image:
  //             postChat.type = PostChatType.Image; break;
  //         case MessageType.File:
  //             postChat.type = PostChatType.File; break;
  //         case MessageType.Text: default:
  //             postChat.type = PostChatType.Text; break;
  //     }
  //     postChat.mediaUrl = this.mediaUrl;
  //     postChat.mediaId = this.mediaId;
  //     postChat.mimeType = this.mimeType;
  //     postChat.file = this.file;
  //     postChat.postId = this.roomMatrixId;
  //     postChat.sender = this.sender;
  //     postChat.createdOn = new Date(this.serverTimeStamp).toLocaleString();;
  //     postChat.tempId = this.tempId;
  //     postChat.orgUnitId = this.orgUnitId;
  //     postChat.entityStatus = EntityStatus.Active;
  //     return postChat;
  // }

  setFlag(flag: MessageFlag) {
    if (
      this.flags.length === 0 ||
      this.flags.findIndex(f => f === flag) === -1
    ) {
      this.flags.push(flag);
      switch (flag) {
        case MessageFlag.STARRED:
          this.isStarred = true;
          break;
        case MessageFlag.REPLYLATER:
          this.isReplyLater = true;
          break;
      }
    }
  }

  removeFlag(flag: MessageFlag) {
    let index = this.flags.findIndex(f => f === flag);
    if (index !== -1) {
      this.flags.splice(index, 1);
    }

    switch (flag) {
      case MessageFlag.STARRED:
        this.isStarred = false;
        break;
      case MessageFlag.REPLYLATER:
        this.isReplyLater = false;
        break;
    }
  }

  static checkExpiry(exp) {
    let expiryTime = exp * 1000;
    let currentTime = new Date().getTime();

    if (currentTime > expiryTime) {
      return true;
    } else {
      return false;
    }
  }

  getFileUrl(): string {
    if (this.fwtEncoded) {
      // get download link
      // let storageLink = environment.storageLink + this.mediaUrl;
      let storageLink = environment.apiLink + "/media/download?fwt=" + this.fwt;
      return storageLink;
    } else {
      return this.mediaUrl;
    }
  }

  getMimeType(): string {
    if (this.mimeType) return this.mimeType;

    // get from file
    if (this.file && this.file.mimeType) return this.file.mimeType;

    // get from filename
    if (this.fileName) {
      return FilenameToMimeType.Convert(this.fileName);
    }
  }

  addMentions(mentions: UserMentions[]): Message {
    if (mentions && mentions.length != 0) {
      let newMentions = mentions.filter(mention => {
        return this.mentions.findIndex(m => m.id == mention.id) == -1;
      });
      this.mentions = this.mentions.concat(newMentions);
    }

    return this;
  }
}

export enum MessageSendStatus {
  PendingToSent,
  Sending,
  Sent,
  Failed
}

export enum MessageStatus {
  Valid,
  Deleted
}

export enum MessageType {
  Text,
  Image,
  File,
  Audio,
  Meet,
  Calendar
}

export class MessageFlag {
  static STARRED = "Starred";
  static REPLYLATER = "ReplyLater";
}
