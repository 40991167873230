import { MsgReaction } from './../model/message.state';
import { Participant, ParticipantStatus } from './../model/participant.model';
import { State, StateContext, Selector, createSelector } from "@ngxs/store";

import { ImmutableContext, ImmutableSelector } from "@ngxs-labs/immer-adapter";
import { produce } from "immer";
import { Receiver, EmitterAction } from "@ngxs-labs/emitter";
import { of } from "rxjs";
import { notContains, contains, distinct } from "../util/array.extension";
import { DecryptStatus, MessageState } from "../model/message.state";
import { RoomState } from "../model/room.state";
import { ParticipantState } from "../model/participant.state";
import {
  MessageStatus,
  MessageSendStatus,
  MessageType,
  MessageFlag
} from "../model/message.model";
import { EntityStatus } from "../enum/entity-status.enum";
import { Injector, Injectable } from "@angular/core";
import { UserDataSelector } from "./user-data.state.selector";
import * as _ from "lodash";
import { InMemMessageSelector } from './inmem.message.selector';
import { AppState } from './app.state';
import { InMemMessageState } from './inmem.message.state';
import { Dictionary, StringDictionary } from '../util/dictionary';
import { EnterpriseSelector } from "./enterprise.state.selector";
import { ClearUnreadDto, UnreadDto } from "../model/unread.state";

export class MessagingStateModel {
  rooms: RoomState[];
  messages: MessageState[];
  participants: ParticipantState[];
  unreads: Dictionary<string[]>;
  flags: Dictionary<string[]>;

  constructor() {
    this.rooms = [];
    this.messages = [];
    this.participants = [];
    const dict = new StringDictionary({
      [MessageFlag.STARRED]: [],
      [MessageFlag.REPLYLATER]: [],
    });
    this.flags = dict.toDictionary();
    this.unreads = {};
  }
}

@State<MessagingStateModel>({
  name: "messaging",
  defaults: new MessagingStateModel()
})
@Injectable()
export class MessagingState {
  private static userStateSelector: UserDataSelector;
  private static inMemMsgSelector: InMemMessageSelector;
  private static MAX_OFFLINE_RECORDS: number = 500;
  private static enterpriseSelector: EnterpriseSelector;

  constructor(injector: Injector, private appState: AppState) {
    MessagingState.userStateSelector = injector.get<UserDataSelector>(
      UserDataSelector
    );

    MessagingState.inMemMsgSelector = injector.get<InMemMessageSelector>(
      InMemMessageSelector
    );
    MessagingState.enterpriseSelector = injector.get<EnterpriseSelector>(
      EnterpriseSelector
    );
  }

  ngxsAfterBootstrap(ctx: StateContext<MessagingStateModel>) {
    this.appState.reportReady("messaging");
    console.log("[MessagingState] - ngxsAfterBootstrap");
  }

  //#region Selectors

  @Selector([MessagingState])
  @ImmutableSelector()
  static rooms(state: MessagingStateModel): RoomState[] | null {
    return _.cloneDeep(state.rooms);
  }

  //All messages include offline and in-memory
  @Selector([MessagingState, InMemMessageState.messages])
  //@ImmutableSelector()
  static allMessages(state: MessagingStateModel, inMemMessages: MessageState[]): MessageState[] {
    const offline = [...state.messages];
    const inmemory = inMemMessages ? [...inMemMessages] : [];
    //console.log("[MessagingState] allMessages, Offline: %s, inmemory", (offline) ? offline.length : "undefined", (inMemMessages) ? inMemMessages.length : "undefined");
    var result = [...offline, ...inmemory];
    return _.cloneDeep(result);
  }

  @Selector([MessagingState])
  @ImmutableSelector()
  static messages(state: MessagingStateModel): MessageState[] | null {
    return _.cloneDeep(state.messages);
  }

  @Selector([MessagingState])
  @ImmutableSelector()
  static participants(state: MessagingStateModel): ParticipantState[] | null {
    return _.cloneDeep(state.participants);
  }

  @Selector([MessagingState])
  static flags(state: MessagingStateModel): StringDictionary | null {
    var result = new StringDictionary({ ...state.flags });
    return _.cloneDeep(result);
  }

  @Selector()
  static unreads(state: MessagingStateModel): StringDictionary | null {
    var result = new StringDictionary({ ...state.unreads });
    return _.cloneDeep(result);
  }

  @Selector([MessagingState.messages, InMemMessageState.messages])
  @ImmutableSelector()
  static pendingMessages(offline: MessageState[], inMemory: MessageState[]): MessageState[] {
    const all = [...(offline ? offline : []), ...(inMemory ? inMemory : [])]
    const messages = all.filter(
      (r) =>
        r.status !== MessageStatus.Deleted &&
        r.sendStatus === MessageSendStatus.PendingToSent
    );
    var result = _.orderBy(messages, (o) => o.serverTimeStamp, ["desc"]);
    return _.cloneDeep(result);
  }

  @Selector([MessagingState.pendingMessages])
  @ImmutableSelector()
  static pendingTextMessages(messages: MessageState[]): MessageState[] | null {
    var result = messages.filter(p => p.type === MessageType.Text);
    return _.cloneDeep(result);
  }

  @Selector([MessagingState.pendingMessages])
  @ImmutableSelector()
  static pendingMediaMessages(messages: MessageState[]): MessageState[] | null {
    var result = messages.filter(
      p =>
        p.type === MessageType.Image ||
        p.type === MessageType.File ||
        p.type === MessageType.Audio
    );
    return _.cloneDeep(result);
  }

  @Selector([MessagingState.pendingMessages])
  @ImmutableSelector()
  static pendingMeetMessages(messages: MessageState[]): MessageState[] | null {
    var result = messages.filter(p => p.type === MessageType.Meet);
    return _.cloneDeep(result);
  }

  @Selector([MessagingState.pendingMessages])
  @ImmutableSelector()
  static pendingCalendarMeetMessages(messages: MessageState[]): MessageState[] | null {
    var result = messages.filter((p) => p.type === MessageType.Calendar);
    return _.cloneDeep(result);
  }
  //#endregion

  //#region Room Actions

  @Receiver()
  @ImmutableContext()
  public static addOrUpdateRoom(
    ctx: StateContext<MessagingStateModel>,
    arg: EmitterAction<RoomState>
  ) {
    if (!arg || !arg.payload) return;
    if (!this.enterpriseSelector.canSaveState(arg.payload.orgId)) return;

    console.time("[addOrUpdateRoom]");
    const state = ctx.getState(); //test
    const rooms = _.clone(state.rooms);

    let room = _.find(rooms, r => r.id === arg.payload.id);
    let index = _.findIndex(rooms, r => r.id === arg.payload.id);

    if (index !== -1) {
      rooms[index] = this.mutateRoomState(rooms[index], arg.payload);
      state.rooms = [...rooms]
    }else{
      state.rooms = [...rooms, arg.payload];
    } 

    ctx.setState(state);
    console.timeEnd("[addOrUpdateRoom]");
  }

  @Receiver()
  @ImmutableContext()
  public static addOrUpdateRooms(
    ctx: StateContext<MessagingStateModel>,
    arg: EmitterAction<RoomState[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;
    const payload = arg.payload.filter((p) =>
      this.enterpriseSelector.canSaveState(p.orgId)
    );
    if (payload.length == 0) return;
    console.time("[addOrUpdateRooms]");

    const state = ctx.getState();
    const rooms = _.clone(state.rooms);

    //new
    const newRooms = _.differenceBy(payload, rooms, "id");

    //existing
    const existingRooms = _.intersectionBy(payload, rooms, "id");

    existingRooms.forEach(room => {
      let index = _.findIndex(rooms, r => r.id === room.id);
      if (index !== -1) {
        rooms[index] = this.mutateRoomState(rooms[index], room);
      }
    });

    if (newRooms.length > 0) {
      state.rooms = [...rooms, ...newRooms];
    } else {
      state.rooms = [...rooms];
    }

    ctx.setState(state);
    console.timeEnd("[addOrUpdateRooms]");
  }

  private static mutateRoomState(existing: RoomState, room: RoomState) {
    if (!existing || !room) return existing;

    //existing.flags = room.flags;
    existing.imageUrl = room.imageUrl;
    existing.lastBatchNumber = room.lastBatchNumber;
    //existing.lastMsg = room.lastMsgId;
    //server returns lastBatchNumber = null if no more history
    existing.isHistoryLoaded = room.isHistoryLoaded;
    existing.name = room.name;
    existing.nextBatchNumber = room.nextBatchNumber;
    existing.status = room.status;
    existing.timeStamp = room.timeStamp;

    if (!existing.lastMsg || (room.lastMsg && existing.lastMsg.serverTimeStamp <= room.lastMsg.serverTimeStamp)) {
      existing.lastMsg = room.lastMsg;
    }

    if (!existing.participants) {
      existing.participants = [];
    }

    if (room.participants) {
      existing.participants = Array.from(
        new Set([...existing.participants, ...room.participants])
      );
    }

    if (!existing.messages) {
      existing.messages = [];
    }

    if (room.messages) {
      existing.messages = Array.from(
        new Set([...existing.messages, ...room.messages])
      );
    }

    return existing;
  }

  @Receiver()
  @ImmutableContext()
  public static removeRoom(
    ctx: StateContext<MessagingStateModel>,
    arg: EmitterAction<string>
  ) {
    if (!arg.payload) return;
    console.time("[removeRoom]");
    const state = ctx.getState();
    const room = _.find(state.rooms, r => r.id === arg.payload);

    if (!room) return;
    if (!this.enterpriseSelector.canSaveState(room.orgId)) return;

    room.status = EntityStatus.Deleted;

    // let unreads = new StringDictionary({ ...state.unreads });
    // unreads.remove(room.id);
    // state.unreads = unreads.toDictionary();

    const msgIds = state.messages
      .filter(m => m.roomId === room.id)
      .map(m => m.id);

    //clear all flags
    let flags = new StringDictionary({ ...state.flags });
    flags.removeValues(MessageFlag.REPLYLATER, msgIds);
    flags.removeValues(MessageFlag.STARRED, msgIds);
    state.flags = flags.toDictionary();

    ctx.setState(state);
    console.timeEnd("[removeRoom]");
  }

  //#endregion

  //#region Message Action
  @Receiver()
  @ImmutableContext()
  public static addOrUpdateMsgFlags(
    ctx: StateContext<MessagingStateModel>,
    arg: EmitterAction<{ msgId: string, flags: string[] }[]>
  ) {
    if (!arg.payload) return;
    // console.time("[addOrUpdateMsgFlag]");
    try {
      const state = ctx.getState();
      const strDict = new StringDictionary({ ...state.flags });

      //add new flags
      const newStarredMsgs = arg.payload
        .filter(
          (m) =>
            m.flags.includes(MessageFlag.STARRED) &&
            !strDict.isExists(MessageFlag.STARRED, m.msgId)
        )
        .map((m) => m.msgId);

      const newReplyLaterMsgs = arg.payload
        .filter(
          (m) =>
            m.flags.includes(MessageFlag.REPLYLATER) &&
            !strDict.isExists(MessageFlag.REPLYLATER, m.msgId)
        )
        .map((m) => m.msgId);

      strDict.addValues(MessageFlag.STARRED, newStarredMsgs);
      strDict.addValues(MessageFlag.REPLYLATER, newReplyLaterMsgs);

      //update removed flags
      const nonFlaggedMsgs = arg.payload.filter((m) => m.flags.length == 0).map((m) => m.msgId);
      nonFlaggedMsgs.forEach((msgId) => {
        //clear flags if exists
        strDict.keys().forEach((key) => {
          if (strDict.isExists(key, msgId)) {
            //if exists, remove it
            strDict.removeValue(key, msgId);
          }
        });
      });

      state.flags = strDict.toDictionary();
      ctx.setState(state);
    } catch (err) {
      console.error(err);
    }

    // console.timeEnd("[addOrUpdateMsgFlag]");
  }

  @Receiver()
  @ImmutableContext()
  public static removeMsgFlag(
    ctx: StateContext<MessagingStateModel>,
    arg: EmitterAction<{ msgId: string, flags: string[] }>
  ) {
    if (!arg.payload) return;
    if (!arg.payload.msgId) return;
    if (!arg.payload.flags || arg.payload.flags.length == 0) return;

    // console.time("[removeMsgFlag]");
    try {
      const state = ctx.getState();
      const strDict = new StringDictionary({ ...state.flags });
      const msgId = arg.payload.msgId;
      const appliedFlags = arg.payload.flags;

      appliedFlags.forEach(key => {
        if (strDict.isExists(key, msgId)) {
          //if exists, remove it
          strDict.removeValue(key, msgId);
        }
      });

      state.flags = strDict.toDictionary();
      ctx.setState(state);
    } catch (err) {
      console.error(err);
    }

    // console.timeEnd("[removeMsgFlag]");
  }

  @Receiver()
  @ImmutableContext()
  public static updateSendStatus(ctx: StateContext<MessagingStateModel>, arg: EmitterAction<MessageState>) {
    console.time("[msg.state] - updateSendStatus");
    try {
      if (!arg.payload) return;

      const state = ctx.getState();
      var all = this.getAllMessages(state);

      var msg: MessageState = all.find(i => i.id == arg.payload.id);
      if (!msg) {
        msg = all.find(f => f.tempId == arg.payload.tempId);
      }

      if (!msg) {
        console.error("[msg.state] intend to change send status but msg was not found. payload: %o", arg.payload);
        return;
      }

      //is in memory
      if (!msg.isOffline) {
        MessagingState.inMemMsgSelector.updateSendStatus.emit(arg.payload);
        return;
      }

      if (msg.sendStatus != arg.payload.sendStatus || msg.sendAttempt != arg.payload.sendAttempt) {
        var idx = all.findIndex(m => m.id ? m.id == arg.payload.id : m.tempId == arg.payload.tempId);
        if (idx != -1) {
          all[idx].sendStatus = arg.payload.sendStatus;
          all[idx].sendAttempt = arg.payload.sendAttempt;
          state.messages = MessagingState.sliceLatest(_.clone(all));

          ctx.setState(state);
        }
      }
    } catch (e) {
      console.error("[msg.state] %o", e);
    } finally {
      console.timeEnd("[msg.state] - updateSendStatus");
    }
  }

  @Receiver()
  @ImmutableContext()
  public static addOrUpdateMessage(
    ctx: StateContext<MessagingStateModel>,
    arg: EmitterAction<MessageState>
  ) {
    if (!arg.payload) return;
    if (!this.enterpriseSelector.canSaveState(arg.payload.orgId)) return;
    console.time("[addOrUpdateMessage]");
    const state = ctx.getState();
    const all = MessagingState.getAllMessages(state);

    const matrixId = MessagingState.userStateSelector.userProfile.matrixId;

    const room: RoomState = _.find(state.rooms, ["id", arg.payload.roomId]);
    if (room == null) return;

    let index = all.findIndex(
      (i) =>
        (i.id != null && i.id === arg.payload.id) ||
        (i.tempId != null && i.tempId === arg.payload.tempId)
    );

    if (index === -1) {
      //perform add
      state.messages = MessagingState.sliceLatest([...all, arg.payload]);

      if (!!room) {
        room.messages = [...room.messages, arg.payload.id]; //add newly sent msg into room.messages
        const allRoomMsgs = state.messages.filter((o) => o.roomId === arg.payload.roomId);
        const latestMsg = _.head(
          _.orderBy(allRoomMsgs, ["serverTimeStamp"], ["desc"])
        );
        room.lastMsg = { ...latestMsg };
      }
    } else {
      //perform update
      all[index] = MessagingState.mutateMessageState(all[index], arg.payload);
      state.messages = MessagingState.sliceLatest(_.clone(all));

      if (
        !!room &&
        !!room.lastMsg &&
        ((room.lastMsg.tempId && room.lastMsg.tempId === arg.payload.tempId) ||
          (room.lastMsg.id && room.lastMsg.id === arg.payload.id))
      ) {
        room.lastMsg = { ...arg.payload };
      }
    }

    ctx.setState(state);
    console.timeEnd("[addOrUpdateMessage]");
  }

  @Receiver()
  @ImmutableContext()
  public static addOrUpdateMessages(
    ctx: StateContext<MessagingStateModel>,
    arg: EmitterAction<MessageState[]>
  ) {
    if (!arg) return;
    if (!arg.payload || arg.payload.length == 0) return;
    const payload = arg.payload.filter((p) =>
      this.enterpriseSelector.canSaveState(p.orgId)
    );
    if (payload.length == 0) return;
    console.time("[addOrUpdateMessages]");

    const state = ctx.getState();
    const all: MessageState[] = MessagingState.getAllMessages(state);

    //new
    const newList = _.differenceBy(payload, all, "id");

    //existing
    const existingList = _.intersectionBy(payload, all, "id");

    //update existing
    existingList.forEach(msg => {
      let index = _.findIndex(all, o => o.id && o.id === msg.id);
      if (index == -1) {
        index = _.findIndex(all, o => o.tempId && o.tempId === msg.tempId);
      }

      if (index !== -1) {
        all[index] = this.mutateMessageState(all[index], msg);
      }
    });

    //add new
    if (newList.length > 0) {
      const roomIds = _.uniq(_.map(newList, o => o.roomId));
      const currentUserMatrixId =
        MessagingState.userStateSelector.userProfile.matrixId;

      for (let i = 0; i < roomIds.length; i++) {
        const roomId = roomIds[i];
        const newMsgs = _.filter(newList, o => o.roomId === roomId);
        const newMsgIds = _.map(newMsgs, o => o.id);
        //console.log("roomId: %s, new msgs: %o", roomId, newMsgs);
        if (newMsgs.length === 0) break;

        const latestMsg = _.head(
          _.orderBy(newMsgs, ["serverTimeStamp"], ["desc"])
        );

        let room: RoomState = _.find(state.rooms, o => o.id === roomId);
        if (!!room) {
          room.messages = _.uniq([...room.messages, ...newMsgIds]);
          if (!!latestMsg && !!room.lastMsg) {

            //compare date between room.lastMsg and new latestMsg
            if (latestMsg.serverTimeStamp > room.lastMsg.serverTimeStamp)
              room.lastMsg = { ...latestMsg };
          }else{
            room.lastMsg = { ...latestMsg };
          }
        }
      }

      state.messages = MessagingState.sliceLatest([...all, ...newList]);
    } else {
      state.messages = MessagingState.sliceLatest([...all]);
    }

    ctx.setState(state);
    console.timeEnd("[addOrUpdateMessages]");
  }

  @Receiver()
  @ImmutableContext()
  public static addOrUpdateMsgPreviews(
    ctx: StateContext<MessagingStateModel>,
    arg: EmitterAction<MessageState[]>
  ) {
    if (!arg) return;
    if (!arg.payload || arg.payload.length == 0) return;
    const msgPreviews = arg.payload.filter((p) =>
      this.enterpriseSelector.canSaveState(p.orgId)
    );
    if (msgPreviews.length == 0) return;
    console.time("[addOrUpdateMsgPreviews]");

    const state = ctx.getState();
    const allRooms = _.clone(state.rooms);

    msgPreviews.forEach((m) => {
      let room:RoomState = _.find(
        allRooms,
        r => (m.roomId === r.id)
      );

      if (!room) return;
      if (!room.lastMsg || room.lastMsg.serverTimeStamp <= m.serverTimeStamp) {
        room.lastMsg = { ...m };
      } 
    });

    state.rooms = [...allRooms];
    ctx.setState(state);
    console.timeEnd("[addOrUpdateMsgPreviews]");
  }

  @Receiver()
  @ImmutableContext()
  public static addOrUpdateMsgReactions(
    ctx: StateContext<MessagingStateModel>,
    arg: EmitterAction<{ msgId: string; reactions: MsgReaction[] }>
  ) {
    if (!arg.payload) return;

    const state = ctx.getState();
    const all = MessagingState.getAllMessages(state);
    let msg = all.find((m) => m.id === arg.payload.msgId);
    if (msg == null || !this.enterpriseSelector.canSaveState(msg.orgId)) return;

    msg.reactions = arg.payload.reactions;

    state.messages = MessagingState.sliceLatest([...all]);
    ctx.setState(state);
  }

  static mutateMessageState(
    existing: MessageState,
    state: MessageState
  ) {
    if (existing == null || state == null) return existing;
    if (!existing.id && state.id) {
      existing.id = state.id;
      //existing.tempId = null;
    }
    if (existing.content != state.content || state.plaintext != null 
      && existing.plaintext != state.plaintext) {
      existing.plaintext = state.plaintext;
      if (state.content == null) {
        existing.isDecrypted = DecryptStatus.NotApplicable;
      } else if (existing.plaintext == null) {
        existing.isDecrypted = DecryptStatus.Pending;
      } else{
        existing.isDecrypted = state.isDecrypted;
      }
    }else{
      existing.isDecrypted = state.isDecrypted;
    }
    existing.content = state.content;
    existing.decryptAttempt = state.decryptAttempt;
    //existing.flags = state.flags;
    existing.mediaId = state.mediaId;
    existing.sendStatus = state.sendStatus;
    existing.sendAttempt = state.sendAttempt;
    existing.status = state.status;
    existing.fwt = state.fwt;
    existing.mediaUrl = state.mediaUrl;
    existing.serverTimeStamp = state.serverTimeStamp;
    existing.type = state.type;

    existing.mediaName = state.mediaName;
    existing.mediaSize = state.mediaSize;
    existing.mimeType = state.mimeType;
    existing.showCard = state.showCard;
    existing.meetUrl = state.meetUrl;
    existing.meetExp = state.meetExp;
    existing.meetIat = state.meetIat;
    existing.isEncrypted = state.isEncrypted;
    existing.isOffline = state.isOffline;
    existing.flags = state.flags;

    existing.startDate = state.startDate;
    existing.endDate = state.endDate;
    existing.recurrence = state.recurrence;
    existing.meetingTitle = state.meetingTitle;

    existing.lastEditedTime = state.lastEditedTime;
    existing.reactions = state.reactions;

    //existing.serverDateTime = state.serverDateTime;
    //existing.serverDisplayDateTime = state.serverDisplayDateTime;
    //prevent msg already read become unread again
    return existing;
  }

  //#region  Utility
  // Slice the latest N elements and save the older to in-memory state
  private static sliceLatest(msgs: MessageState[]) {
    var sorted = _.orderBy(msgs, ["serverTimeStamp"], ["desc"]);

    var latestRecords: MessageState[] = _.take(sorted, MessagingState.MAX_OFFLINE_RECORDS);

    var slicedOlderRecords: MessageState[] = _.slice(sorted, MessagingState.MAX_OFFLINE_RECORDS);

    if (slicedOlderRecords && slicedOlderRecords.length > 0) {
      MessagingState.inMemMsgSelector.addOrUpdateMessages.emit(slicedOlderRecords);
    }

    latestRecords.forEach(msg => {
      msg.isOffline = true;
    });

    console.log("[MessagingState] offline: %s, in-memory: %s", latestRecords.length, slicedOlderRecords.length);

    return latestRecords;
  }

  private static getAllMessages(state: MessagingStateModel): MessageState[] {
    const offline: MessageState[] = [...state.messages]; //offline

    offline.forEach(msg => {
      msg.isOffline = true;
    });

    const inmem: MessageState[] = MessagingState.inMemMsgSelector.getAllMessages();

    inmem.forEach(msg => {
      msg.isOffline = false;
    });
    return [...(offline ? offline : []), ...(inmem ? inmem : [])];
  }
  //#endregion

  @Receiver()
  @ImmutableContext()
  public static addOrUpdateParticipants(
    ctx: StateContext<MessagingStateModel>,
    arg: EmitterAction<ParticipantState[]>
  ) {
    if (!arg || arg.payload.length == 0) return;
    console.time("[addOrUpdateParticipants]");
    var payload: ParticipantState[] = _.clone(arg.payload);
    const state = ctx.getState();
    const prts = _.clone(state.participants);

    const roomIds: string[] = _.uniq(_.map(payload, (i) => i.roomId));
    var rooms = _.filter(state.rooms, (i) => roomIds.includes(i.id));

    const notCurrentOrgRoom: RoomState[] = _.filter(rooms, (i: RoomState) => !this.enterpriseSelector.canSaveState(i.orgId));
    payload = _.differenceWith(
      payload,
      notCurrentOrgRoom,
      (a: ParticipantState, r: RoomState) =>
        r.id === a.roomId 
    );
    if (payload.length == 0) return;

    const newPrts: ParticipantState[] = _.differenceWith(
      payload,
      prts,
      (a: ParticipantState, r: ParticipantState) =>
        (r.roomId === a.roomId && r.matrixId === a.matrixId)
    );

    const existingPrts: ParticipantState[] = _.intersectionWith(
      payload,
      prts,
      (a: ParticipantState, r: ParticipantState) =>
        (r.roomId === a.roomId && r.matrixId === a.matrixId)
    );

    existingPrts.forEach(a => {
      const existing = _.find(
        prts,
        m => (m.roomId === a.roomId && m.matrixId == a.matrixId)
      );

      if (existing) {
        //perform update
        existing.isLeaved = a.isLeaved;
        existing.firstName = a.firstName;
        existing.lastName = a.lastName;
        existing.status = a.status;
        existing.isRoomHidden = a.isRoomHidden;
      }
    });

    if (newPrts.length > 0) {
      state.participants = [...prts, ...newPrts];
    } else {
      state.participants = [...prts];
    }
    //update room.participant
    rooms.forEach((room) => {
      room.participants = _.uniq([
        ..._.map(
          state.participants.filter(
            (i) => i.roomId === room.id && i.status != ParticipantStatus.Leaved
          ),
          (r) => r.matrixId
        ),
      ]);
    });

    ctx.setState(state);
    console.timeEnd("[addOrUpdateParticipants]");
  }

  @Receiver()
  @ImmutableContext()
  public static addOrUpdateParticipant(
    ctx: StateContext<MessagingStateModel>,
    arg: EmitterAction<ParticipantState>
  ) {
    if (!arg || !arg.payload) return;
    console.time("[addOrUpdateParticipant]");
    const state = ctx.getState();
    let existing = _.find(
      state.participants,
      m => m.roomId === arg.payload.roomId && m.matrixId == arg.payload.matrixId
    );
    const room: RoomState = _.find(
      state.rooms,
      (r) => r.roomId === arg.payload.roomId
    );
    if (room) {
      if (!this.enterpriseSelector.canSaveState(room.orgId)) return;
    }

    if (existing) {
      //perform update
      existing.firstName = arg.payload.firstName;
      existing.lastName = arg.payload.lastName;
      existing.isLeaved = arg.payload.isLeaved;
      existing.status = arg.payload.status;
      existing.isRoomHidden = arg.payload.isRoomHidden;
    } else {
      //perform add
      const room = _.find(state.rooms, r => r.roomId === arg.payload.roomId);
      room.participants = [...room.participants, arg.payload.matrixId];
      state.participants = [...state.participants, arg.payload];
    }

    if (room) {
      //update room.participant
      room.participants = _.uniq([
        ..._.map(
          state.participants.filter(
            (i) => i.roomId === room.id && i.status != ParticipantStatus.Leaved
          ),
          (r) => r.matrixId
        ),
      ]);
    }

    ctx.setState(state);
    console.timeEnd("[addOrUpdateParticipant]");
  }

  @Receiver()
  @ImmutableContext()
  public static deleteMessage(
    ctx: StateContext<MessagingStateModel>,
    arg: EmitterAction<MessageState>
  ) {
    if (!arg.payload) return;
    if (!this.enterpriseSelector.canSaveState(arg.payload.orgId)) return;

    console.time("[deleteMessage]");
    var msg = arg.payload;
    const state = ctx.getState();
    const all = MessagingState.getAllMessages(state);

    const existing: MessageState = _.find(all, (m: MessageState) => m.id === msg.id);
    if (!existing) return;

    if (existing.isOffline) {
      existing.status = MessageStatus.Deleted;
    } else {
      MessagingState.inMemMsgSelector.deleteMessage.emit(msg);
    }

    const room = _.find(state.rooms, (r) => r.id === existing.roomId);
    if (!!room && !!room.lastMsg && room.lastMsg.id === existing.id) {
      room.lastMsg = { ...existing };
    }

    // let unreads = new StringDictionary({ ...state.unreads });
    // unreads.removeValue(room.id, existing.id);
    // state.unreads = unreads.toDictionary();

    //clear all flags
    let flags = new StringDictionary({ ...state.flags });
    flags.removeValue(MessageFlag.REPLYLATER, existing.id);
    flags.removeValue(MessageFlag.STARRED, existing.id);
    state.flags = flags.toDictionary();

    ctx.setState(state);

    console.timeEnd("[deleteMessage]");
  }

  @Receiver()
  @ImmutableContext()
  public static hardDeleteMessage(
    ctx: StateContext<MessagingStateModel>,
    arg: EmitterAction<string>
  ) {
    if (!arg.payload) return;

    console.time("[hardDeleteMessage]");
    const state = ctx.getState();
    const all = MessagingState.getAllMessages(state);
    const msg = _.find(all, (m) => m.id === arg.payload);
    if (!msg) return;

    //delete room messages first
    const room = _.find(state.rooms, (m) => m.id === msg.roomId);
    if (!!room) {
      room.messages = _.filter(room.messages, (i) => i !== arg.payload);
      if (!!room.lastMsg && room.lastMsg.id === arg.payload) {
        room.lastMsg = null;
      }
    }

    state.messages = MessagingState.sliceLatest(_.filter(all, (m) => m.id !== arg.payload));

    // let unreads = new StringDictionary({ ...state.unreads });
    // unreads.removeValue(room.id, msg.id);
    // state.unreads = unreads.toDictionary();

    let flags = new StringDictionary({ ...state.flags });
    flags.removeValue(MessageFlag.REPLYLATER, msg.id);
    flags.removeValue(MessageFlag.STARRED, msg.id);
    state.flags = flags.toDictionary();

    ctx.setState(state);
    console.timeEnd("[hardDeleteMessage]");
  }

  //#endregion

  @Receiver()
  @ImmutableContext()
  public static addMsgUnreads(
    ctx: StateContext<MessagingStateModel>,
    arg: EmitterAction<UnreadDto[]>
  ) {
    if (!arg.payload || arg.payload.length == 0) return;

    const allUnreads = arg.payload;
    const state = ctx.getState();

    let unreads = new StringDictionary({ ...state.unreads });

    allUnreads.forEach(unread => {
      
      unreads.addValues(unread.threadId, unread.unreadIds);
      
    });

    state.unreads = unreads.toDictionary();

    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  public static addMsgUnread(
    ctx: StateContext<MessagingStateModel>,
    arg: EmitterAction<UnreadDto>
  ) {
    if (!arg.payload) return;

    const unreadDto = arg.payload;
    const state = ctx.getState();

    let unreads = new StringDictionary({ ...state.unreads });
    unreads.addValues(unreadDto.threadId, unreadDto.unreadIds);
    state.unreads = unreads.toDictionary();

    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  public static clearMsgUnread(
    ctx: StateContext<MessagingStateModel>,
    arg: EmitterAction<ClearUnreadDto>
  ) {
    if (!arg.payload) return;

    const unreadDto = arg.payload;
    const state = ctx.getState();

    let chUnreads = new StringDictionary({ ...state.unreads });
    chUnreads.remove(unreadDto.threadId);
    state.unreads = chUnreads.toDictionary(); 
    
    ctx.setState(state);
  }

  @Receiver() static clean(ctx: StateContext<MessagingStateModel>) {
    ctx.setState({ ...new MessagingStateModel() });
  }

  @Receiver()
  @ImmutableContext()
  static cleanPartial(ctx: StateContext<MessagingStateModel>) {
    const state = ctx.getState();

    state.messages = [];
    state.rooms = [];
    state.participants = [];
    state.unreads = {};

    ctx.setState(state);
  }
}
