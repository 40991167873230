// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: true,
  version: require('../../package.json').version,
  build: require('../../package.json').build,
  lastSupportedVersion: '0.30.0', // last version with local cache changes
  device: "web",
  apiLink: 'https://api.everleagues.com/api',
  apiEsignLink: 'https://el-esign.azurewebsites.net/api',
  roomHubLink: 'https://api.everleagues.com/roomhub',
  orgHubLink: 'https://api.everleagues.com/orghub',
  systemHubLink: 'https://api.everleagues.com/systemhub',
  storageLink: 'https://everleaguesstorage.blob.core.windows.net/',
  meetDefaultUrl: 'https://conference1.everleagues.com',
  apirpLink: "https://elrpapi.azurewebsites.net/api/v1/",
  apiclmLink: "https://elclmserviceprodapi.azurewebsites.net/api/v1/",
  apiELNetLink: "https://elnetapi.everleagues.com/",
  publicStorageLink: 'https://elprodpublicstorage.blob.core.windows.net/',
  apipsLink: "https://elpsadminapi.azurewebsites.net/api/v1/",
  apiLibreOfficeLink:"https://eloffice.azurewebsites.net/",
  apiLibreOfficeOrigin: "https://app.everleagues.com",
  fileServerLink: "https://elprodfileserver.everleagues.com",
  turnstileEnabled: true,
  turnstileSiteKey: '0x4AAAAAAAFWr_IsKcop8ZDA',

  firebase: {
    appId: "1:269334807478:android:15c608b1fdca4cce",
    apiKey: "AIzaSyDjldqavTzACMP-gEuO3eEv7JsqSMcGSbg",
    authDomain: "everleagues1.firebaseapp.com",
    databaseURL: "https://everleagues1.firebaseio.com",
    projectId: "everleagues1",
    storageBucket: "everleagues1.appspot.com",
    messagingSenderId: "269334807478"
  },

  googlePicker: {
    developerKey: "AIzaSyAujiCBDx6oTvhrx7_Trq7ezzU79h65nN8",
    clientId: "269334807478-gbsp0qnhhlsusn652154gggv19nam09j.apps.googleusercontent.com",
    appIds: "269334807478",
    scope: ['https://www.googleapis.com/auth/drive.file'],
  },
  stripePublishKey: 'pk_live_ycxdQub5xVUH5tVZwMTxVg9o00RCFA1lEI',
  recaptchaSiteKey: "6LcCPMsUAAAAAGGUr-Ar_ZEThII2RpWwM14H074_"
};
