export interface MyTypeResult {
    Result: boolean;
    Message: string;
    Action: string;
  }
  
  export enum AzureStorageWopiActionType
  {
      none,
      view,
      edit,
      view_comment
  }

  export enum AzureStorageType
  {
      cloud,
      team,
      chat
  }
  
  export interface AzureStorageModel {
    fileId: string;
    Container: string;
    blobPath: string;
    BaseFileName: string;
    Action: string;
    OwnerId: string;
    UserId: string;
    UserName: string;
    AccessToken: string;
  }

  export interface WopiParameter {
    UserName: string;
    UserId: string;
    ReadOnly: boolean;
    UserAvator: string;
    UserEmail:string;
    PostMessageOrigin:string;
    Collaboration: boolean;
    StorageType: number;
  }

  