import { AbstractType, InjectionToken, Injector, Type } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { MenuItem } from "../core/ui/menu-item";

export abstract class Plugin {
    abstract get appId(): string;
    abstract get path(): string;
    //Redirect path when user tap on system notification, return null if want to skip
    abstract get tapRedirectPath(): string;
    //Launch to redirect path
    abstract get launchToRedirectPath(): string;
    //Launch to redirect priority, -1 as lowest
    abstract get launchToRedirectPriority(): number;
    //Enabled Launch to redirect
    abstract get launchToRedirectEnabled(): boolean;
    abstract get iconUrl(): string;
    abstract get loadModule(): Promise<any>;

    onMenuUpdated$: BehaviorSubject<MenuItem[]>;


    constructor(private injector: Injector) {
        this.onMenuUpdated$ = new BehaviorSubject<MenuItem[]>([]);
    }

    inject<T>(token: Type<T> | InjectionToken<T> | AbstractType<T>) {
        return this.injector.get<T>(token);
    }

    abstract onOrgSwitched(args: any): Promise<void>;
    abstract onFeedsReceived(data: any): Promise<void>;
    abstract onCardAction(actionName: string, args: any): Promise<void>;
    abstract onToolDisabled(): Promise<void>;
}