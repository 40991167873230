export class HubConnectionState {
  public id: string;
  public status: HubConnectionStatus = HubConnectionStatus.Offline;
  public handshakeStatus: HubHandshakeStatus = HubHandshakeStatus.Pending;

  static offline(): HubConnectionState {
    const state = new HubConnectionState();
    state.status = HubConnectionStatus.Offline;
    state.handshakeStatus = HubHandshakeStatus.Pending;

    return state;
  }

  static online(id: string): HubConnectionState {
    const state = new HubConnectionState();
    state.id = id;
    state.status = HubConnectionStatus.Connected;
    return state;
  }

  static connecting(): HubConnectionState {
    const state = new HubConnectionState();
    state.status = HubConnectionStatus.Connecting;
    state.handshakeStatus = HubHandshakeStatus.Pending;

    return state;
  }
}

export class RoomHubConnectionState extends HubConnectionState {
  public lastBatchKey: string;
  constructor() {
    super();
    this.lastBatchKey = "";
  }
}

export enum HubConnectionStatus {
  Offline = 0,
  Connected = 1,
  Connecting = 2
}

export enum HubHandshakeStatus {
  Pending = 0,
  Completed = 1,
  Failed = 2
}
