import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject, Subscription, Observable } from "rxjs";
import { Room } from "../model/room.model";
import { OrgUnit } from "../model/orgunit.model";
import { Post } from "../model/post.model";
import { pocolog } from "pocolog";
import { Permission } from "../enum/permissions.enum";
import { isObject } from "ngx-bootstrap/chronos/utils/type-checks";
import { IFStorageEngine } from "../states/if.storage.engine";
import { map } from "rxjs/operators";

@Injectable()
export class LocalStorageService {
  private AUTH_KEY = "Auth-Data";
  private CURRENT_OU_KEY = "CurrentOU-Data";
  private ROOT_OU_KEY = "RootOU-Data";
  private USER_OU_KEY = "UserOU-Data";
  private PERMISSION_KEY = "Permissions";
  private LAST_LOGIN_KEY = "LastLogin-Data";
  private APP_VERSION = "AppVer";
  private FCM_TOKEN = "FcmToken";
  private CONTACT = "Contact";
  private DEVICE_ID = "Device-Id";

  // chat
  private CURRENT_ACTIVE_CHAT_KEY = "CurrentActive-Chat";
  private BATCH_KEY = "BatchKey-Chat";
  private CONVERSATION_KEY = "Conversation-Chat";

  // post
  private POST_KEY = "Post";

  public onActiveChat: Subject<string> = new Subject<string>();

  public onCurrentRooms: Subject<Room[]> = new Subject<Room[]>();

  public onOURefreshed: Subject<OrgUnit[]> = new Subject<OrgUnit[]>();

  public onOUChanged: Subject<void> = new Subject<void>();

  // post
  public onPostChanged: Subject<LocalStorageEvent> = new Subject<
    LocalStorageEvent
  >();

  public onPermissionChanged: Subject<Permission> = new Subject<Permission>();

  // e2e
  private E2E_KEYS_KEY = "E2E-Keys";
  private GOOGLE_ACCESS_TOKEN = "Google-Access-Token";

  constructor(
    private lfStorage: IFStorageEngine
  ) {}

  setContact(contact: any) {
    localStorage.setItem(this.CONTACT, JSON.stringify(contact));
  }

  getContact() {
    const res = localStorage.getItem(this.CONTACT);
    if (res) {
      return JSON.parse(res);
    } else {
      return null;
    }
  }

  setAuth(token: any) {
    localStorage.setItem(this.AUTH_KEY, JSON.stringify(token));
  }

  updateAuth(givenName: string) {
    // update name
    let auth = this.getAuth();
    auth.givenName = givenName;
    this.setAuth(auth);
  }
  removeAuth() {
    localStorage.removeItem(this.AUTH_KEY);
  }

  getAuth() {
    const res = localStorage.getItem(this.AUTH_KEY);
    if (res) {
      return JSON.parse(res);
    } else {
      return null;
    }
  }
  setRootOU(ouId: string) {
    localStorage.setItem(this.ROOT_OU_KEY, JSON.stringify(ouId));
  }

  /** Get Root OU ID from cache */
  getRootOU(): string {
    const res = localStorage.getItem(this.ROOT_OU_KEY);
    if (res) {
      return JSON.parse(res);
    } else {
      return null;
    }
  }

  getUserId() {
    if (this.getAuth()) {
      return this.getAuth().id;
    } else {
      return null;
    }
  }

  setCurrentOU(ouId: string) {
    localStorage.setItem(this.CURRENT_OU_KEY, JSON.stringify(ouId));

    // set last login
    let userId = this.getUserId();
    if (userId) {
      this.setLastLogin(userId, ouId);
    }
  }

  removeCurrentOU() {
    localStorage.removeItem(this.CURRENT_OU_KEY);
  }

  getCurrentOU() {
    const res = localStorage.getItem(this.CURRENT_OU_KEY);
    if (res) {
      return JSON.parse(res);
    } else {
      return null;
    }
  }

  getOrgUnit() {
    const res = localStorage.getItem(this.USER_OU_KEY);
    if (res) {
      return JSON.parse(res);
    } else {
      return null;
    }
  }

  removeOrgUnit(orgUnitId: any) {
    const res = localStorage.getItem(this.USER_OU_KEY);
    if (res) {
      let orgUnits = JSON.parse(res);
      let selectedOU = orgUnits.find(x => x.orgUnitId === orgUnitId);
      let index = orgUnits.indexOf(selectedOU);
      orgUnits.splice(index, 1);
      localStorage.setItem(this.USER_OU_KEY, JSON.stringify(orgUnits));
    } else {
      return null;
    }
  }

  setOrgUnit(orgUnit: any) {
    localStorage.setItem(this.USER_OU_KEY, orgUnit);
  }

  getVersion() {
    let version = localStorage.getItem(this.APP_VERSION);

    if (version) {
      return JSON.parse(version);
    } else {
      return null;
    }
  }

  setVersion(version: any) {
    localStorage.setItem(this.APP_VERSION, JSON.stringify(version));
  }

  setFcmToken(token: any) {
    localStorage.setItem(this.FCM_TOKEN, JSON.stringify(token));
  }

  getFcmToken(): string {
    let token = localStorage.getItem(this.FCM_TOKEN);
    if (token) {
      return JSON.parse(token);
    } else {
      return null;
    }
  }

  setPermission(permissions: any) {
    localStorage.setItem(this.PERMISSION_KEY, permissions);
    this.onPermissionChanged.next(permissions);
  }

  getPermissions() {
    let permissionString = localStorage.getItem(this.PERMISSION_KEY);

    if (permissionString) {
      let permission = JSON.parse(permissionString);
      return permission;
    } else {
      return null;
    }
  }

  setLastLogin(userId: string, orgUnitId: string) {
    let userLogin = { userId: userId, orgUnitId: orgUnitId };

    let lastLogin = this.getAllLastLogin();
    if (lastLogin) {
      let newLastLogin = lastLogin.filter(x => x.userId !== userId);
      newLastLogin.push(userLogin);
      localStorage.setItem(this.LAST_LOGIN_KEY, JSON.stringify(newLastLogin));
    } else {
      let newLoginArray = [];
      newLoginArray.push(userLogin);
      localStorage.setItem(this.LAST_LOGIN_KEY, JSON.stringify(newLoginArray));
    }
  }

  getAllLastLogin() {
    let lastLoginString = localStorage.getItem(this.LAST_LOGIN_KEY);
    if (lastLoginString) {
      let lastLogin = JSON.parse(lastLoginString);
      return lastLogin;
    } else {
      return null;
    }
  }

  getLastLogin(userId: string) {
    let lastLoginString = localStorage.getItem(this.LAST_LOGIN_KEY);

    if (lastLoginString) {
      let lastLogin = JSON.parse(lastLoginString);
      let userLastLogin = lastLogin.filter(x => x.userId === userId)[0];
      if (userLastLogin) {
        return userLastLogin.orgUnitId;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  getAccessToken(): string {
    let authData = this.getAuth();
    if (!authData) {
      pocolog.error("Failed to get Auth data");
      return null;
    }

    return authData.accessToken;
  }

  // matrix
  saveBatchKey(key: string) {
    localStorage.setItem(this.BATCH_KEY, JSON.stringify(key));
  }

  getBatchKey(): Promise<string> {
    const res = localStorage.getItem(this.BATCH_KEY);
    if (res) {
      return Promise.resolve(JSON.parse(res));
    } else {
      return Promise.resolve(null);
    }
  }

  saveConversations(updates: Room[], otherOuId?: string): Promise<any> {
    // let rooms: Room[] = [];
    // updates.forEach(r => {
    //     rooms.push(r.passByValue());
    // });

    // return this.lfStorage.setItem(this.getKey(this.CONVERSATION_KEY, otherOuId), rooms)
    //     .then(res => {
    //         this.updateRoomChats(updates);
    //         //this.onChatUpdated.next();
    return Promise.resolve();
    //     })
    //     .catch(err => {
    //         pocolog.error(err);
    //         return Promise.reject(err);
    //     });
  }

  getConversations(otherOuId?: string): Promise<Room[]> {
    // return this.lfStorage.getItem(this.getKey(this.CONVERSATION_KEY, otherOuId))
    //     .then(data => {
    //         if (data) {
    //             let rooms = Room.parseList(data);
    //             return Promise.resolve(rooms);
    //         } else {
    return Promise.resolve([]);
    //         }
    //     }).catch(error => {
    //         return Promise.reject(error);
    //     });
  }

  private getKey(key: string, otherOuId?: string) {
    // console.log(this.CurrentOU.orgUnitId+" "+key);
    console.log("current ou" + this.getCurrentOU());
    let currentOu = this.getCurrentOU();
    if (currentOu) {
      if (otherOuId === undefined || otherOuId == currentOu) {
        return `${currentOu}_${key}`;
      } else {
        return otherOuId + "_" + key;
      }
    } else {
      throw new Error("LocalDb: CurrentOU is null");
    }
  }

  updateActiveChat(roomId) {
    this.onActiveChat.next(roomId);
  }

  updateRoomChats(room: Room[]) {
    console.log("updateroom chats");
    console.log(room);
    this.onCurrentRooms.next(room);
  }

  // post
  savePosts(posts: Post[]) {
    let res = localStorage.getItem(this.POST_KEY);
    let isNew = true;
    try {
      // remove deleted posts
      posts = posts.filter(x => x.statusName !== "Deleted");

      if (posts && posts.length > 0) {
        let ouId = this.getCurrentOU();
        let resObj: OrgPost[] = JSON.parse(res);
        if (!resObj) {
          resObj = [];
          resObj.push(new OrgPost(ouId, posts));
        } else {
          let orgPosts = resObj.filter(x => x.orgId === ouId)[0];
          if (orgPosts) {
            posts.forEach(c => {
              let index = orgPosts.posts.findIndex(r => r.postId === c.postId);
              if (index !== -1) {
                // update post in storage
                orgPosts.posts[index] = c;
                isNew = false;
              } else {
                // no post detected, push entire post
                orgPosts.posts.push(c);
              }
            });
          } else {
            // current org id has no room, push new org post
            resObj.push(new OrgPost(ouId, posts));
          }
        }
        localStorage.setItem(this.POST_KEY, JSON.stringify(resObj));

        this.createPostEvent(
          posts,
          isNew ? EventMethod.Add : EventMethod.Update
        );
      }
    } catch (e) {
      let ouId = this.getCurrentOU();
      let obj = [];
      obj.push(new OrgPost(ouId, posts));
      localStorage.setItem(this.POST_KEY, JSON.stringify(obj));
      this.createPostEvent(posts, EventMethod.Add);
    }
    return true;
  }

  // delete post from local storage
  deletePost(posts: Post[]) {
    let res = localStorage.getItem(this.POST_KEY);
    try {
      let ouId = this.getCurrentOU();
      let resObj: OrgPost[] = JSON.parse(res);
      if (resObj) {
        let orgPosts = resObj.filter(x => x.orgId === ouId)[0];
        if (orgPosts) {
          posts.forEach(post => {
            orgPosts.posts = orgPosts.posts.filter(
              x => x.postId !== post.postId
            );
          });
        } else {
          // no post detected in org
          return true;
        }

        localStorage.setItem(this.POST_KEY, JSON.stringify(resObj));
        this.createPostEvent(posts, EventMethod.Delete);
      }
    } catch (e) {
      return false;
    }
    return true;
  }

  getPosts(channelId: string = null): Post[] {
    try {
      let res = localStorage.getItem(this.POST_KEY);
      let ouId = this.getCurrentOU();
      let resObj: OrgPost[] = JSON.parse(res);
      if (resObj) {
        let orgPosts = resObj.filter(x => x.orgId === ouId)[0];
        if (orgPosts) {
          if (orgPosts.posts) {
            if (channelId) {
              // get posts for selected channel
              let channelPosts = orgPosts.posts.filter(
                x => x.channelId === channelId
              );
              return Post.parseList(channelPosts);
            } else {
              // return all posts from all channels
              let channelPosts = orgPosts.posts;
              return Post.parseList(channelPosts);
            }
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (e) {
      console.log("mismatch data org posts");
    }
  }

  getPostById(postId: string): Post {
    try {
      let res = localStorage.getItem(this.POST_KEY);
      let ouId = this.getCurrentOU();
      let resObj: OrgPost[] = JSON.parse(res);
      if (resObj) {
        let orgPosts = resObj.filter(x => x.orgId === ouId)[0];
        if (orgPosts) {
          if (orgPosts.posts) {
            let post = orgPosts.posts.filter(x => x.postId === postId)[0];
            return Post.parse(post);
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    } catch (e) {
      console.log("mismatch data org posts");
    }
  }

  createPostEvent(post: Post[], method: EventMethod) {
    let event: LocalStorageEvent = {
      method: method,
      targetObj: post
    };
    this.onPostChanged.next(event);
  }

  clear() {
    this.removeCurrentOU();
    this.removeAuth();
    localStorage.removeItem(this.USER_OU_KEY);
    localStorage.removeItem(this.ROOT_OU_KEY);
    localStorage.removeItem(this.PERMISSION_KEY);
    localStorage.removeItem(this.CONVERSATION_KEY);
    localStorage.removeItem(this.CURRENT_ACTIVE_CHAT_KEY);
    localStorage.removeItem(this.BATCH_KEY);
    localStorage.removeItem(this.POST_KEY);
    localStorage.removeItem(this.FCM_TOKEN);
    this.lfStorage.clear();

    // clear UIService BehaviorSubject
    // this.uiService.updateProfileImage("");
  }

  clearUserLastLogin(userId) {
    let allLogins = this.getAllLastLogin();

    if (allLogins) {
      allLogins = allLogins.filter(x => x.userId !== userId);
      if (allLogins && allLogins.length > 0) {
        localStorage.setItem(this.LAST_LOGIN_KEY, JSON.stringify(allLogins));
      } else {
        localStorage.removeItem(this.LAST_LOGIN_KEY);
      }
    }
  }

  clearOrgUnit() {
    localStorage.removeItem(this.USER_OU_KEY);
  }

  setDeviceId(ouId: string): void {
    localStorage.setItem(this.DEVICE_ID, JSON.stringify(ouId));
  }

  getDeviceId(): string {
    const res = localStorage.getItem(this.DEVICE_ID);
    if (res) {
      return JSON.parse(res);
    } else {
      return null;
    }
  }

  //#region E2E
  saveKeys(keys: string) {
    return Promise.resolve(localStorage.setItem(this.E2E_KEYS_KEY, keys));
  }

  getKeys(): Promise<string> {
    return Promise.resolve(localStorage.getItem(this.E2E_KEYS_KEY));
  }

  removeKeys() {
    localStorage.removeItem(this.E2E_KEYS_KEY);
    return Promise.resolve(true);
  }
  //#endregion

  //#region GooglePicker
  saveGoogleAccessToken(token: string) {
    return Promise.resolve(localStorage.setItem(this.GOOGLE_ACCESS_TOKEN, token));
  }

  getGoogleAccessToken(): Promise<string> {
    return Promise.resolve(localStorage.getItem(this.GOOGLE_ACCESS_TOKEN));
  }

  removeGoogleAccessToken() {
    localStorage.removeItem(this.GOOGLE_ACCESS_TOKEN);
    return Promise.resolve(true);
  }
  //#endregion
}

// wrappers used in local storage
export class Conversation {
  orgId: string;
  rooms: Room[];

  constructor(orgId?: string, rooms?: Room[]) {
    this.orgId = orgId;
    this.rooms = rooms;
  }
}

export class OrgPost {
  orgId: string;
  posts: Post[];

  constructor(orgId?: string, posts?: Post[]) {
    this.orgId = orgId;
    this.posts = posts;
  }
}

export class PermissionStorage {
  orgUnitId: string;
  permission: {};
  roleId: string;
  roleName: string;
}

export interface LocalStorageEvent {
  method: EventMethod;
  targetObj: any;
}

export enum EventMethod {
  Add,
  Update,
  Delete
}
