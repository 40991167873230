import { EntityStatus } from "./../enum/entity-status.enum";
import { InMemPostState } from "./inmem.post.state";
import { Injectable } from "@angular/core";
import { Store, createSelector } from "@ngxs/store";
import { Emitter, Emittable } from "@ngxs-labs/emitter";
import { PostState, PostCommentState } from "../model/org.state";
import { FileObjState } from "../model/file-obj.state";
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class InMemPostSelector {
  constructor(private store: Store) {}
  @Emitter(InMemPostState.addOrUpdatePosts)
  public addOrUpdatePosts: Emittable<PostState[]>;

  @Emitter(InMemPostState.deletePost)
  public deletePost: Emittable<PostState>;

  @Emitter(InMemPostState.addOrUpdateComments)
  public addOrUpdateComments: Emittable<PostCommentState[]>;

  @Emitter(InMemPostState.deleteComment)
  public deleteComment: Emittable<PostCommentState>;

  @Emitter(InMemPostState.deleteOUPostsAndComments)
  public deleteOUPostsAndComments: Emittable<string>;

  @Emitter(InMemPostState.deleteTeamPostsAndComments)
  public deleteTeamPostsAndComments: Emittable<string>;

  @Emitter(InMemPostState.deleteChPostsAndComments)
  public deleteChPostsAndComments: Emittable<string>;

  getChMediaPosts(channelId: string) {
    console.log("this is storeee: ", JSON.stringify(this.store));
    return this.store.selectSnapshot(
      InMemPostSelector.channelMediaPosts(channelId)
    );
  }

  getChMediaComments(channelId: string) {
    return this.store.selectSnapshot(
      InMemPostSelector.channelMediaComments(channelId)
    );
  }

  static channelMediaPosts(channelId: string) {
    return createSelector(
      [InMemPostState.mediaPosts],
      (mediaPosts: PostState[]) => {
        return mediaPosts.filter((p) => p.channelId == channelId);
      }
    );
  }

  static channelMediaComments(channelId: string) {
    return createSelector(
      [InMemPostState.mediaComments],
      (mediaComments: PostCommentState[]) => {
        var result = mediaComments.filter((p) => p.channelId == channelId);
        return _.cloneDeep(result);
      }
    );
  }

  getAllMedias() {
    return this.store.selectSnapshot(InMemPostSelector.allMedias());
  }

  static allMedias() {
    return createSelector(
      [
        InMemPostState.mediaPosts,
        InMemPostState.mediaComments,
      ],
      (posts: PostState[], comments: PostCommentState[]) => {
        const postFiles = _.map(
          posts.filter((p) => p.status === EntityStatus.Active && !!p.mediaId),
          (p: PostState) => {
            let file = new FileObjState();
            file.id = p.mediaId;
            file.name = p.mediaName;
            file.orgId = p.orgId;
            file.createdBy = p.createdBy;
            file.timeStamp = p.createdOn;
            file.fwt = p.fwt;
            file.fwtEncoded = file.fwt ? true : false;
            file.icon = this.getType(p.mediaName);
            file.createdAs = "Post";
            return file;
          }
        );
        const commentFiles = _.map(
          comments.filter(
            (c) => c.status === EntityStatus.Active && !!c.mediaId
          ),
          (c: PostCommentState) => {
            let file = new FileObjState();
            file.id = c.mediaId;
            file.name = c.mediaName;
            file.orgId = c.orgId;
            file.createdBy = c.createdBy;
            file.timeStamp = c.createdOn;
            file.fwt = c.fwt;
            file.fwtEncoded = file.fwt ? true : false;
            file.icon = this.getType(c.mediaName);
            file.createdAs = "Comment";
            return file;
          }
        );

        var result = [_.cloneDeep(postFiles), _.cloneDeep(commentFiles)];
        return result;
      }
    );
  }


  getChannelMedias(channelId: string) {
    return this.store.selectSnapshot(InMemPostSelector.channelMedias(channelId));
  }

  static channelMedias(channelId: string) {
    return createSelector(
      [
        InMemPostSelector.channelMediaPosts(channelId),
        InMemPostSelector.channelMediaComments(channelId),
      ],
      (posts: PostState[], comments: PostCommentState[]) => {
        const postFiles = _.map(
          posts.filter((p) => p.status === EntityStatus.Active && !!p.mediaId),
          (p: PostState) => {
            let file = new FileObjState();
            file.id = p.mediaId;
            file.name = p.mediaName;
            file.orgId = p.orgId;
            file.createdBy = p.createdBy;
            file.timeStamp = p.createdOn;
            file.fwt = p.fwt;
            file.fwtEncoded = file.fwt ? true : false;
            file.icon = this.getType(p.mediaName);
            file.createdAs = "Post";
            return file;
          }
        );
        const commentFiles = _.map(
          comments.filter(
            (c) => c.status === EntityStatus.Active && !!c.mediaId
          ),
          (c: PostCommentState) => {
            let file = new FileObjState();
            file.id = c.mediaId;
            file.name = c.mediaName;
            file.orgId = c.orgId;
            file.createdBy = c.createdBy;
            file.timeStamp = c.createdOn;
            file.fwt = c.fwt;
            file.fwtEncoded = file.fwt ? true : false;
            file.icon = this.getType(c.mediaName);
            file.createdAs = "Comment";
            return file;
          }
        );

        return [_.cloneDeep(postFiles), _.cloneDeep(commentFiles)];
      }
    );
  }

  getAllPosts() {
    const posts = this.store.selectSnapshot(InMemPostState.posts);
    if (!posts) return [];
    return [...posts];
  }

  getAllComments() {
    const comments = this.store.selectSnapshot(InMemPostState.comments);
    if (!comments) return [];
    return [...comments];
  }

  static getType(filename: string): string {
    var fileNameArr = filename.toLocaleUpperCase().split('.');
    switch(fileNameArr[fileNameArr.length-1]){
      case "PDF":
        return "file-pdf-o";
      case "DOC":
      case "DOCX":
        return "file-word-o";
      case "XLS":
      case "XLSX":
      case "CSV":
        return "file-excel-o";
      case "TXT":
        return "file-text-o";
      case "JPG":
      case "JPEG":
      case "GIF":
      case "PNG":
      case "BMP":
            return "file-photo-o";
      case "MP4":
      case "MOV":
      case "AVI":
            return "file-movie-o";
      case "MP3":
      case "ACC":
            return "file-sound-o";
      case "RAR":
      case "ZIP":
            return "file-zip-o";
        default:
        return "file-o";
    }
  }
}
