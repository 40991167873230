import { Component, OnInit, HostBinding, ViewChild, OnDestroy, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogAddFriendComponent } from './dialog/dialog-add-friend.component';
import { FriendsTableComponent } from './friends-table/friends-table.component';
import { routerAnimation } from '../utils/page.animation';
import { TranslateService } from '@ngx-translate/core';
import { Select } from '@ngxs/store';
import { EnterpriseState } from '../core/states/enterprise.state';
import { Observable } from 'rxjs';
import { OrgUserState, OrgState, MembershipReqState, MembershipReqStatus, OUState } from '../core/model/org.state';
import { SubSink } from 'subsink';
import { UserDataSelector } from '../core/states/user-data.state.selector';
import { UserStatus } from '../core/enum/user-status.enum';
import * as _ from "lodash";
import { EnterpriseSelector } from '../core/states/enterprise.state.selector';
import { DialogPSJoinReqLinkComponent } from './dialog/dialog-ps-join-req-link.component';
import { OrgHub } from '../core/hub/org.hub';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-friends',
  templateUrl: './friends.component.html',
  styleUrls: ['./friends.component.scss'],
  animations: [routerAnimation]
})
@Injectable({
  providedIn: 'root'
})
export class FriendsComponent implements OnInit, OnDestroy {
  // Add router animation
  @HostBinding('@routerAnimation') routerAnimation = true;
  @ViewChild('friendsTable', { static: true }) friendsTable: FriendsTableComponent;

  @Select(EnterpriseState.currentOrgUsers)
  currentOrgUsers$: Observable<OrgUserState[]>;

  @Select(EnterpriseState.selectedOrg)
  selectedOrg$: Observable<OrgState>;

  @Select(EnterpriseState.currentInvitationReqs)
  currentInvitationReqs$: Observable<MembershipReqState[]>;

  @Select(EnterpriseState.currentOrgOUs)
  currentOrgOUs$: Observable<OUState[]>;

  @Select(EnterpriseState.currentPendingJoinReqs)
  currentPendingJoinReqs$: Observable<MembershipReqState[]>

  users: OrgUserState[] = [];
  currentOrg: OrgState
  currentUserId: string;
  invitedReqs: MembershipReqState[] = [];
  currentOus: OUState[] = [];
  pendingJoinReqs: MembershipReqState[] = [];

  selectedIndex: number = 0;

  private _subSink: SubSink;

  get currentUser(): OrgUserState {
    return this.users.find(x => x.userId === this.currentUserId);
  }
  constructor(public dialog: MatDialog,
    private translateService: TranslateService,
    public snackBar: MatSnackBar,
    private userDataSelector: UserDataSelector,
    private enterpriseSelector: EnterpriseSelector,
    private route: ActivatedRoute,
    private orgHub: OrgHub) {
    this._subSink = new SubSink();
  }

  ngOnInit() {
    this.currentUserId = this.userDataSelector.userId;

    this._subSink.sink = this.currentOrgUsers$.subscribe(users => {
      this.users = users;
    });

    this._subSink.sink = this.selectedOrg$.subscribe(state => {
      this.currentOrg = state;
    });

    this._subSink.sink = this.currentOrgOUs$.subscribe(ous => {
      this.currentOus = ous
    });

    this._subSink.sink = this.currentPendingJoinReqs$.subscribe(reqs => {
      this.pendingJoinReqs = reqs;
    });

    this._subSink.sink = this.currentInvitationReqs$.subscribe(reqs => {
      this.invitedReqs = reqs;
      // reqs = reqs.filter(
      //   (r) => r.status == MembershipReqStatus.PendingToVerify
      // );
      // const invitedReqs = this.invReqToOrgUser(reqs);
      // const newReqs = _.differenceBy(invitedReqs, this.users, "userId");
      // this.users = this.users.concat(newReqs);
      // //remove accepted 
      // const acceptedInv = reqs.filter((r) => {
      //   (r) => r.status == MembershipReqStatus.Completed;
      // });
      // _.remove(this.users, (u: OrgUserState) => {
      //   acceptedInv.findIndex((i) => i.id == u.userId) != -1;
      // });
    });

    this._subSink.sink = this.route.queryParams.subscribe(qParams => {
      let reqId = qParams["reqId"];
      if (reqId) this.selectedIndex = 2;
    });

    this.orgHub.getMembershipReq();

  }

  private invReqToOrgUser(reqs: MembershipReqState[]): OrgUserState[] {
    return reqs.map((req) => {
      let newData: OrgUserState = new OrgUserState();
      newData.orgId = req.orgId;
      newData.userId = req.id;
      newData.firstName = req.firstName;
      newData.lastName = req.lastName;
      newData.email = req.email;
      newData.role = req.roleName;
      newData.status = UserStatus.Invited;
      newData.ouId = req.engagedOuId;
      newData.imageUrl = req.userAvatar;

      return newData;
    });
  }

  addUser() {
    // get current personal org
    let personalOrg = this.enterpriseSelector.getCurrentOrg();

    const dialogRef = this.dialog.open(DialogAddFriendComponent, {
      width: '400px', height: '650px', panelClass: "border-dialog",
      data: {
        personalOrg: personalOrg
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res === 'success') {
        // refresh if true
        this.openSnackBar(this.translateService.instant('FRIEND.SUCC_INVITED_USER'), this.translateService.instant('REPEAT.OK'));
      } else if (res && res.error) {
        // error
        this.openSnackBar(res.error, this.translateService.instant('REPEAT.OK'));
      }
    });
  }

  toggleShowDelete($event) {
    this.friendsTable.showDeletedUser($event.checked);
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  ngOnDestroy() {
    if (this._subSink) this._subSink.unsubscribe();
  }

  showInvitationLink(){
    const dialogRef = this.dialog.open(DialogPSJoinReqLinkComponent, {
      width: '400px', height: '270px', panelClass: "border-dialog",
    });

  }
}
