import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import {LibreOfficeSessionService} from './libreoffice-session.service'
//import { ExtStateSelector } from "../../../core/states/ext.state.selector";
//import { elnet_configuration } from "../configurations/elnet_configuration";
import { environment } from '../../../../environments/environment';
import { LocalStorageService } from '../../localStorage/local-storage.service';
import { AbstractStateSelector } from "../../states/abstract.state.selector";

@Injectable({
  providedIn: 'root'
})
export class LibreOfficeRequestsService {

  basePathLibreOffice: string;
  USERID_LO: string;
  USERNAME_LO:string;
  ORGID_LO: string;
  NODEID_LO: string;
  Authorization_LO: string;

  constructor(public http: HttpClient,
              public session: LibreOfficeSessionService, 
              public localauthstate: LocalStorageService, 
              private authstate: AbstractStateSelector) {

    this.session.set('basePathLibreOffice', environment.apiLibreOfficeLink);

  }

   setRequestParam() {
    this.basePathLibreOffice = this.session.get('basePathLibreOffice') ? this.session.get('basePathLibreOffice') : '';
  
    if (this.authstate.getCurrentOrg() !=null) {
      this.ORGID_LO =this.authstate.getCurrentOrg().id;
    } else{
      if ((this.localauthstate.getCurrentOU()) != null) {
        this.ORGID_LO = this.localauthstate.getCurrentOU();
      }
      else {
        this.ORGID_LO = '';
      }
 }

    if (this.authstate.getCurrentUser() !=null) {
      this.USERID_LO = this.authstate.getCurrentUser().userId;
    } else {
      if ((this.localauthstate.getUserId()) != null) {
        this.USERID_LO = this.localauthstate.getUserId();
      }else {
        this.USERID_LO ='';
      }
    }

    if (this.authstate.getCurrentUser() !=null) {
      this.USERNAME_LO = this.authstate.getCurrentUser().firstName + " " + this.authstate.getCurrentUser().lastName;
    } else {
      if ((this.localauthstate.getUserId()) != null) {
        this.USERNAME_LO = this.localauthstate.getAuth().userName;
      }else {
        this.USERNAME_LO ="Unkown User";
      }
    }

    
    this.NODEID_LO = this.session.get('nodeid') ? this.session.get('nodeid') : '';
  
    if (this.authstate.getAuthData() != null) {
      this.Authorization_LO = 'Bearer ' + this.authstate.getAuthData().accessToken;
    } else {
      if ((this.localauthstate.getAccessToken()) != null) {
        this.Authorization_LO = 'Bearer ' + (this.localauthstate.getAccessToken());
      } else {
        ;
      }
    }
  }

  get(req: any): Observable<any> {
    this.setRequestParam();
    return this.http.get(
      this.basePathLibreOffice + req.uri,
      {
        headers: new HttpHeaders({
          //'Content-Type': req.headerContentType,
          'USERID': this.USERID_LO,
          'USERNAME':this.USERNAME_LO,
          'ORGID' :this.ORGID_LO,
          'NODEID': this.NODEID_LO,
          'Authorization': this.Authorization_LO
        }),
        params: req.data,
        responseType: (undefined === req.headerResponseType || null === req.headerResponseType) ?
        'json' : req.headerResponseType
      }
    );
  }

  post(req: any): Observable<any> {
    this.setRequestParam();
    return this.http.post(
      this.basePathLibreOffice + req.uri,
      req.data,
      {
        headers: (undefined === req.headerContentType || null === req.headerContentType) ? 
        new HttpHeaders({
          'USERID': this.USERID_LO,
          'USERNAME':this.USERNAME_LO,
          'ORGID' :this.ORGID_LO,
          'NODEID': this.NODEID_LO,
          'Authorization': this.Authorization_LO
        }) :
        new HttpHeaders({
          'Content-Type': req.headerContentType,
          'USERID': this.USERID_LO,
          'USERNAME':this.USERNAME_LO,
          'ORGID' :this.ORGID_LO,
          'NODEID': this.NODEID_LO,
          'Authorization': this.Authorization_LO
        }),
        responseType: (undefined === req.headerResponseType || null === req.headerResponseType) ?
        'json' : req.headerResponseType
      });
  }

  put(req: any): Observable<any> {
   this.setRequestParam();
    return this.http.put(this.basePathLibreOffice + req.uri, req.data);
  }

  delete(req: any): Observable<any> {
   this.setRequestParam();
    return this.http.delete(this.basePathLibreOffice + req.uri);
  }
}