import { Injectable } from "@angular/core";
import { AuthService } from '../auth/auth.service';
import { AuthStateSelector } from '../states/auth.state.selector';

@Injectable({
    providedIn: "root",
})
export class TokenProvider {
    constructor(private authStateSelector: AuthStateSelector, private authService: AuthService) { }
    private EXPIRY = "exp";

    getAccessToken(): Promise<string> {
        try {
            return new Promise<string>((resolve, reject) => {
                // main method to get access token. checks the token's expiry
                let accessToken = this.authStateSelector.accessToken;

                // check for expiry
                let token = this.parseJwt(accessToken);
                let expiry = token[this.EXPIRY];
                let isExpired = this.isTimeExpired(expiry);
                if (isExpired) {
                    console.log("[TokenProvider] JWT token expired. Refreshing token");
                    this.authService.refreshAccessToken().then(resToken => {
                        resolve(resToken);
                    }).catch(res => {
                        console.log("[TokenProvider] Access token failed");
                        reject("Access token failed");
                    });
                } else {
                    // console.log("[TokenProvider] Access token is valid");
                    resolve(accessToken);
                }
            });
        } catch (ex) {
            return Promise.reject("Error getting token");
        }
    }


    parseJwt(token) {
        try {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            return JSON.parse(window.atob(base64));
        } catch {
            throw new Error("Error parsing token");
        }
    }

    isTimeExpired(epochTime): boolean {
        // uses system clock to compare with epoch time. System clock needs to be accurate.
        try {
            if (Date.now() >= epochTime * 1000) {
                return true;
            }
            return false;
        } catch {
            throw new Error("Error time expired");
        }
    }
}
