import { ContactAddressState } from './../../core/model/org.state';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-profile-address',
  templateUrl: './dialog-profile-address.component.html',
  styleUrls: ['./dialog-profile-address.component.scss']
})
export class DialogProfileAddressComponent implements OnInit {

  address: ContactAddressState;
  isNew = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogProfileAddressComponent>) { }

  ngOnInit() {
    this.address = this.data.address ? Object.assign({}, this.data.address) : new ContactAddressState();
    if (!this.address) {
      this.isNew = true;
    }
  }

  editAddress() {
    this.dialogRef.close({ status: 'success', address: this.address });
  }
}
