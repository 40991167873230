import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Email, ContactDetailLabel } from '../../../model/contact.model';
import { FormControl } from '@angular/forms';
import { Validators } from '@angular/forms';
import { each } from 'hammerjs';
import * as _ from "lodash";
import { ContactEmailState } from '../../../model/org.state';

@Component({
    selector: 'input-contact-email',
    templateUrl: './input-contact-email.component.html',
    styleUrls: ['./input-contact-email.component.scss']
})
export class InputContactEmailComponent implements OnInit {
    // 2-way binding
    @Output() emailsChange: EventEmitter<Email[]> = new EventEmitter<Email[]>();
    @Input()

    set emails(value) {
        
        if(!value||value.length <= 0){
            this.addDummyFields();
        }
        else{
            const result = _.differenceWith(value,this._emailsInput,(a:ContactEmailState,r:EmailInput) =>a.email===r.email.email)
            result.forEach((a)=>{
                let emailinput = new EmailInput(a);
                this._emailsInput.push(emailinput);
            })   
        }
    }
   
    frmCtrlemail: FormControl;
    _emailsInput:EmailInput[] = [];

    @Input() showIcon = false;

    showAddEmailButton = false;
    constructor() {
    }

    ngOnInit() {
        this.addDummyFields();
        
    }

    addDummyFields() {
        // add email dummy
        if (this._emailsInput.length <= 0) {
            let dummy: Email = {
                email: "",
                labelName: "Work",
                label: ContactDetailLabel.Work
            }
            let emailinput = new EmailInput(dummy);
            this._emailsInput.push(emailinput);
        }

        this.determineAddButtonState();     
    }

    deleteEmail(email) {
        if (this._emailsInput.length > 1) {
            this._emailsInput.forEach((item, index) => {
                if (item === email) {
                    this._emailsInput.splice(index, 1);
                }
            });
        } else {
            // reset
            this._emailsInput = [];
            this.addNewEmail();
        }
        this.determineAddButtonState();
        this.updateBindedObject();
    }

    onChangeEmailInput(event) { 
        // remove blank fields except first field
        if (this._emailsInput.length > 1) {
            this._emailsInput = this._emailsInput.filter(x => x.email ? true : false);
            console.log(this._emailsInput)
            // failsafe add if no field exists
            if (this._emailsInput.length <= 0) {
                this.addNewEmail();
            }
        }
        this.determineAddButtonState();
        this.updateBindedObject();
    }

    determineAddButtonState() {
        // determine if add button should show
        this.showAddEmailButton = true;
        if (this._emailsInput.length === 1 && (this._emailsInput[0].email ? false : true)) {
            this.showAddEmailButton = false;
        }
    }

    addNewEmail() {
        
        let dummy: Email = {
            email: "",
            labelName: "Work",
            label: ContactDetailLabel.Work
        }

        let dummymail: Email={
        
        }

        let emailinput = new EmailInput(dummy); 
        
        
        this._emailsInput.push(emailinput); 
        
        this.showAddEmailButton = false;
    }

    updateBindedObject() {
        this.emailsChange.emit(this._emailsInput.filter(x => x.email.email).map(y => y.email));
    }
}

export class EmailInput{
    email:Email;
    frmCtrl:FormControl;

    constructor(email:Email)
    {
        this.email=email;
        this.frmCtrl = new FormControl("",[Validators.email]);
    }
}
