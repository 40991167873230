import {
  HubConnectionState,
  RoomHubConnectionState,
  HubConnectionStatus,
  HubHandshakeStatus
} from "../model/hubConnection.state";
import { State, Selector, StateContext } from "@ngxs/store";
import { Receiver, EmitterAction } from "@ngxs-labs/emitter";
import { ImmutableContext, ImmutableSelector } from "@ngxs-labs/immer-adapter";
import { Injectable } from '@angular/core';
import * as _ from "lodash";

export class HubStateModel {
  roomhub: RoomHubConnectionState;
  orghub: HubConnectionState;
  syshub: HubConnectionState;
  constructor() {
    this.roomhub = new RoomHubConnectionState();
    this.orghub = new HubConnectionState();
    this.syshub = new HubConnectionState();
  }
}

@State<HubStateModel>({
  name: "hub",
  defaults: new HubStateModel()
})
@Injectable()
export class HubState {
  constructor() { }

  ngxsAfterBootstrap(ctx: StateContext<HubStateModel>) {
    console.log("[HubState] - ngxsAfterBootstrap");
  }

  @Selector()
  @ImmutableSelector()
  static roomhub(state: HubStateModel): RoomHubConnectionState {
    return _.cloneDeep(state.roomhub);
  }

  @Selector()
  @ImmutableSelector()
  static orghub(state: HubStateModel): HubConnectionState {
    return _.cloneDeep(state.orghub);
  }

  @Selector()
  @ImmutableSelector()
  static syshub(state: HubStateModel): HubConnectionState {
    return _.cloneDeep(state.syshub);
  }

  @Receiver()
  @ImmutableContext()
  public static setRoomHubConnection(
    ctx: StateContext<HubStateModel>,
    arg: EmitterAction<RoomHubConnectionState>
  ) {
    const state = ctx.getState();
    const roomhub = { ...state.roomhub };

    console.log(
      "[HubState]-setRoomHubConnection id: %s, before: %s, after: %s",
      arg.payload.id,
      roomhub.status,
      arg.payload.status
    );

    roomhub.id = arg.payload.id;
    roomhub.status = arg.payload.status;
    roomhub.handshakeStatus = arg.payload.handshakeStatus;

    if (
      roomhub.status === HubConnectionStatus.Connected &&
      arg.payload.lastBatchKey
    ) {
      roomhub.lastBatchKey = arg.payload.lastBatchKey;
    }

    state.roomhub = { ...roomhub };

    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  public static updateBatchNumber(
    ctx: StateContext<HubStateModel>,
    arg: EmitterAction<string>
  ) {
    const state = ctx.getState();
    const roomhub = { ...state.roomhub };
    roomhub.lastBatchKey = arg.payload;

    state.roomhub = { ...roomhub };
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  public static updateRoomHubHandshakeStatus(
    ctx: StateContext<HubStateModel>,
    arg: EmitterAction<HubHandshakeStatus>
  ) {
    const state = ctx.getState();
    const roomhub = { ...state.roomhub };
    roomhub.handshakeStatus = arg.payload;

    state.roomhub = { ...roomhub };
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  public static updateOrgHubHandshakeStatus(
    ctx: StateContext<HubStateModel>,
    arg: EmitterAction<HubHandshakeStatus>
  ) {
    const state = ctx.getState();
    const orghub = { ...state.orghub };
    orghub.handshakeStatus = arg.payload;

    state.orghub = { ...orghub };
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  public static updateSysHubHandshakeStatus(
    ctx: StateContext<HubStateModel>,
    arg: EmitterAction<HubHandshakeStatus>
  ) {
    const state = ctx.getState();
    const syshub = { ...state.syshub };
    syshub.handshakeStatus = arg.payload;

    state.syshub = { ...syshub };
    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  public static setOrgHubConnection(
    ctx: StateContext<HubStateModel>,
    arg: EmitterAction<HubConnectionState>
  ) {
    const state = ctx.getState();
    const orghub = { ...state.orghub };

    console.log(
      "[HubState]-setOrgHubConnection id: %s, before: %s, after: %s",
      arg.payload.id,
      orghub.status,
      arg.payload.status
    );
    state.orghub = { ...arg.payload };

    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  public static setSysHubConnection(
    ctx: StateContext<HubStateModel>,
    arg: EmitterAction<HubConnectionState>
  ) {
    const state = ctx.getState();
    const syshub = { ...state.syshub };

    console.log(
      "[HubState]-setSysHubConnection id: %s, before: %s, after: %s",
      arg.payload.id,
      syshub.status,
      arg.payload.status
    );
    state.syshub = { ...arg.payload };

    ctx.setState(state);
  }

  @Receiver()
  static clean(ctx: StateContext<HubStateModel>) {
    ctx.setState({ ...new HubStateModel() });
  }
}
