import { AccessRight } from "./cloud-access-right";
import { SharedFile } from "./shared-file";
import { SharedResource } from "./shared-resource";

export class SharedFolder extends SharedResource {
    isRoot: boolean;
    folders: SharedFolder[] = [];
    files: SharedFile[] = [];

    constructor() {
        super();
        this.resourceType = "folder";
        // this.scope = "SHARED";

        this.name = "";
        this.description = "Folder";
        this.icon = "folder_shared";
        this.isRoot = false;
    }

    isSameResource(resource){
        return resource instanceof SharedFolder && super.isSameResource(resource);
    }

    /**DTO is SharedDummyFolderDto from file server. */
    public static parse(dto: any) {
        if (!dto) return null;
        var obj = new SharedFolder();

        obj.path = dto.path; // shared dummy path
        obj.container = dto.container; // shared dummy container
        obj.name = dto.displayName; // folder name
        obj.createdOn = dto.createdOn;
        obj.modifiedOn = dto.modifiedOn;
        obj.enableNotifications = dto.enableNotifications;
        obj.ownerId = dto.ownerIdentity;

        obj.originContainer = dto.originContainer;
        obj.originPath = dto.originPath;

        if (dto.grantees) {
            dto.grantees.forEach(f => obj.grantees.push(AccessRight.parse(f)));
        }
        
        return obj;
    }

}