import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { InMemFeedState } from "./inmem.feed.state";
import { Emittable, Emitter } from "@ngxs-labs/emitter";
import { Feed } from "../model/feed";

@Injectable({
    providedIn: "root",
})
export class InMemFeedSelector {
    constructor(private store: Store) { }

    @Emitter(InMemFeedState.addOrUpdateFeeds)
    public addOrUpdateFeeds: Emittable<Feed[]>;

    getAllFeeds() {
        const feeds = this.store.selectSnapshot(InMemFeedState.feeds);
        if (!feeds) return [];
        return [...feeds];
    }
}