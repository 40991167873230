export class FeatureState {
  id: string;
  subscriptionId: string;
  orgId: string;
  resourceCode: string;
  isRestricted: boolean;
  type: FeatureType;
  used: number;
  limit: number;
  displayName: string;
  unit: string;

  constructor() {
    this.isRestricted = false;
    this.used = 0;
    this.limit = 0;
  }
}

export enum FeatureType {
  System = 1,
  Custom = 2,
}

export class ResourceCode {
  static readonly CLOUD_STORAGE_DOWNLOAD = "cloud-storage-download";
  static readonly CLOUD_STORAGE_UPLOAD = "cloud-storage-upload";
  static readonly VIDEO_CONFERENCE = "video-conference";
  static readonly INT_LICENSE = "internal-user";
  static readonly EXT_LICENSE = "external-user";
}
