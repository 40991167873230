import * as _ from "lodash";
export interface Dictionary<T> {
    [key: string]: T;
}

export class StringDictionary {
    private _dict: Dictionary<string[]> = {};

    constructor(init: Dictionary<string[]> = null) {
        if (init) {
            this._dict = { ...init };
        }
    }

    get(key: string): string[] {
        return this.containsKey(key) && this._dict[key] ? [...this._dict[key]] : [];
    }

    count(key: string): number {
        return this.containsKey(key) && this._dict[key]
            ? this._dict[key].length
            : 0;
    }

    add(key: string, value: string): void {
        if (!this.containsKey(key)) {
            this._dict[key] = [value];
        } else {
            this._dict[key] = _.uniq([...this._dict[key], value]);
        }
    }

    addValues(key: string, values: string[]): void {
        if (!this.containsKey(key)) {
            this._dict[key] = [...values];
        } else {
            this._dict[key] = _.uniq([...this._dict[key], ...values]);
        }
    }

    remove(key: string) {
        if (this.containsKey(key)) {
            delete this._dict[key];
        }
    }

    removeValue(key: string, value: string) {
        if (!this.containsKey(key)) return;
        if (!value) return;

        this._dict[key] = this._dict[key].filter((i) => i !== value);
    }

    removeValues(key: string, values: string[]) {
        if (!this.containsKey(key)) return;
        if (!values && values.length == 0) return;

        this._dict[key] = this._dict[key].filter(
            (i) => !_.some(values, (c) => c === i)
        );
    }

    containsKey(key: string) {
        return typeof this._dict[key] !== "undefined";
    }

    keys(): string[] {
        const founds = Object.getOwnPropertyNames(this._dict);
        return founds ? [...founds] : [];
    }

    values(): string[] {
        const keys = this.keys();
        if (keys) {
            let values: string[] = [];
            keys.forEach((key) => {
                if (this._dict[key]) {
                    values = _.uniq([...values, ...this._dict[key]]);
                }
            });
            return values;
        }
        return [];
    }

    isExists(key: string, value: string) {
        if (!this.containsKey(key)) return false;
        if (!this._dict[key] || this._dict[key].length == 0) return false;

        return _.findIndex(this._dict[key], (o) => o === value) !== -1;
    }

    findKeys(value: string): string[] {
        const keys = this.keys();
        let found: string[] = [];
        for (var i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (!this._dict[key]) continue;
            const item = _.find(this._dict[key], (o) => o === value);
            if (item) {
                found.push(key);
            }
        }
        return found;
    }

    toDictionary(): Dictionary<string[]> {
        return _.clone(this._dict);
    }
}
