import { X } from '@angular/cdk/keycodes';
import { Component, Input, OnInit, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Email, ContactDetailLabel } from '../../../model/contact.model';
import { ValidatorService } from '../../../services/validator.service';

@Component({
    selector: 'input-new-guest',
    templateUrl: './input-new-guest.component.html',
    styleUrls: ['./input-new-guest.component.scss']
})
export class InputNewGuestComponent implements OnInit, OnChanges {
    @Input() saveAllContacts: boolean;
    @Output() guestsChange: EventEmitter<Guest[]> = new EventEmitter<Guest[]>();
    public guests: Guest[] = [];

    showAddNewGuestButton = true;

    // validation regex

    constructor(
        private validator: ValidatorService
        ) {
    }

    ngOnInit() {
        this.addDummyFields();
    }

    ngOnChanges() {
        if (this.guests.length > 0) {
            this.guests.forEach(guest => {
                guest.saveContact = this.saveAllContacts;
            });
        }
    }

    addDummyFields() {
        // add guest dummy
        if (this.guests.length <= 0) {
            let dummy = new Guest();

            this.guests.push(dummy);
            // this.showAddNewGuestButton = false;
        } else {

            // this.showAddNewGuestButton = true;
        }
    }

    deleteGuest(guest) {
        this.guests.forEach((item, index) => {
            if (item === guest) {
                this.guests.splice(index, 1);
            }
        });
        this.guestsChange.emit(this.guests);
        if (this.guests.length <= 0) {
            this.addDummyFields();
        }
    }

    onChangeInput(index) {
        this.guests[index].isEmail = this.guests[index].contact ? !this.guests[index].contact.startsWith('+') : true;
        this.guests.forEach((guest:Guest) =>{
            /*if(guest.firstName && guest.lastName
                && guest.contact 
                && (this.validator.validateEmail(guest.contact).success || this.validator.validatePhone(guest.contact).success))*/
            
                
                 if (guest.firstName && guest.lastName
                 && guest.contact 
                 && (this.validator.validateEmail(guest.contact).success)) {
                    guest.email = guest.contact;
                    guest.phone = null;
                    guest.isValid = true
                } else if(guest.firstName && guest.lastName
                    && guest.contact 
                    && (this.validator.validatePhone(guest.contact).success)){
                    guest.email = null;
                    guest.phone = guest.contact;
                    guest.isValid = true
                }
            
                else {
                guest.isValid=false
                }
                
            });

        this.guestsChange.emit(this.guests);
        
        
        /*this.guests[index].isEmail = this.guests[index].contact ? !this.guests[index].contact.startsWith('+') : true;
        this.guests[index].isValid=false;
        var validGuests = this.guests.filter(x =>
            (x.firstName && x.lastName
                && x.contact 
                && (this.validator.validateEmail(x.contact).success || this.validator.validatePhone(x.contact).success))
                ).map(x => {
                    if (this.validator.validateEmail(x.contact).success) {
                        x.email = x.contact;
                        x.phone = null;
                        x.isValid=true;
                    } else {
                        x.email = null;
                        x.phone = x.contact;
                        x.isValid=true;
                    }
                    //console.log(x)
                    return x;
            });*/
    }

    // onChangeInput(event) {
    //     // remove empty/invalid fields before emit
    //     this.guestsChange.emit(this.guests.filter(x =>
    //         (x.email && x.email.match("([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})"))
    //         && x.firstName));
    // }

    addNewGuest() {
        let hasDummy = this.guests.find(x => !x.contact && !x.firstName && !x.lastName);
        // dont add if there is blank field
        if (!hasDummy) {
            let dummy = new Guest();
            this.guests.push(dummy);
            // this.showAddNewGuestButton = false;
        }
    }

    resetFields() {
        this.guests = [];
        this.addDummyFields();
        this.showAddNewGuestButton = true;
    }
}

export class Guest {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    contact: string;
    isEmail: boolean;
    saveContact: boolean;
    isValid:boolean;

    constructor() {
        this.saveContact = true;
        this.isValid=false;
    }
}
