import { EnterpriseSelector } from './../../core/states/enterprise.state.selector';
import { Component, HostBinding, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { routerAnimation } from "../../utils/page.animation";
import { get } from "scriptjs";
import { Message } from "../../core/model/message.model";
import { DatePipe } from "@angular/common";
import { RelativeDatePipe } from "../../core/pipe/relative-date.pipe";
import { UserDataSelector } from '../../core/states/user-data.state.selector';
import { RoomState } from '../../core/model/room.state';
import { MessageState } from '../../core/model/message.state';
import { ParticipantState } from '../../core/model/participant.state';
import { SubSink } from 'subsink';
import { MsgRenderer } from '../../core/renderer/msg.renderer';
import { MessagingSelector } from '../../core/states/messaging.state.selector';
import { AppState } from '../../core/states/app.state';
import { RoomHub } from '../../core/hub/room.hub';
import { environment } from "../../../environments/environment";
import { BroadcastChannel as BC } from 'broadcast-channel';
import { ChatRoomComponent } from '../../chat/chat-room/chat-room.component';
import { UnreadType } from "../../core/model/unread.state";
import { UnreadsService } from "../../core/services/unreads.service";
import { UIStatus } from '../../core/states/ui.state';
import { UIStateSelector } from '../../core/states/ui.state.selector';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../core/auth/auth.service';
import { combineLatest, Observable } from 'rxjs';
import { Select } from '@ngxs/store';
import { HubStateSelector } from '../../core/states/hub.state.selector';
import { HubHandshakeStatus } from '../../core/model/hubConnection.state';
import { FlowManager } from '../../core/workflow';
import { UserDataState } from '../../core/states/user-data.state';
import { Emitter, Emittable } from '@ngxs-labs/emitter';
import { FcpService } from '../../core/fcp/fcp.service';
import { FeedService } from '../../core/services/feed.service';

declare var JitsiMeetExternalAPI: any;

@Component({
  selector: "app-meet-page",
  templateUrl: "./meet-page.component.html",
  styleUrls: ["./meet-page.component.scss"],
  animations: [routerAnimation],
  providers: [DatePipe, RelativeDatePipe]
})
export class MeetPageComponent implements OnInit, OnDestroy {
  @Select(HubStateSelector.roomHubHandshakeStatus)
  roomHubHandshakeStatus$: Observable<HubHandshakeStatus>;
  @Select(HubStateSelector.sysHubHandshakeStatus)
  sysHubHandshakeStatus$: Observable<HubHandshakeStatus>;
  @Select(UserDataState.language)
  onLangChanged$: Observable<string>;
  @Emitter(UserDataState.setLanguage)
  public setLanguage: Emittable<string>;

  // Add router animation
  @HostBinding("@routerAnimation") routerAnimation = true;
  @ViewChild("chatRoomComponent", { static: false }) private chatRoomComponent: ChatRoomComponent;
  // @Emitter(MessagingState.clearRoomUnread)
  // public clearRoomUnread: Emittable<string>;
  private _subSink: SubSink;
  userMatrixId: string;

  // query
  api: any;
  showCommands = false;
  jwt: string;
  meetRoomName: string;
  domain: string;
  displayName: string;

  // states
  roomMessages: MessageState[] = []; // this contains all messages from room
  roomPtcp: ParticipantState[] = [];
  chatRoom: RoomState;

  // chat
  roomId: string;
  roomName: string;

  // flag
  audioIcon = "mic_off";
  videoIcon = "videocam_off";
  isShareScreen = false;

  connection;
  room;

  isChatRoomReady = false;
  chatMessageLoading = true;

  // show global loader
  globalLoader = true;
  globalLoaderText: string;

  // broadcast channel to communication with multiple tabs
  channel = new BC('elMeet');
  get currentRoomName(): string {
    if (this.chatRoom) {
      return this.msgRenderer.renderRoomName(
        this.chatRoom,
        this.roomPtcp,
        this.userMatrixId);
    } else {
      return "";
    }
  }

  get isMsgEditEnabled(): boolean {
    var org = this.enterpriseSelector.getCurrentOrg();
    if (org == null) return false;
    return org.editMsgEnabled;
  }

  get isDeleteMsgEnabled(): boolean {
    var org = this.enterpriseSelector.getCurrentOrg();
    if (org == null) return false;
    return org.deleteMsgEnabled;
  }

  showCmdPanel: boolean = true;
  constructor(
    private route: ActivatedRoute,
    private userDataSelector: UserDataSelector,
    private msgSelector: MessagingSelector,
    private msgRenderer: MsgRenderer,
    private enterpriseSelector: EnterpriseSelector,
    private appState: AppState,
    private roomHub: RoomHub,
    private router: Router,
    private unreadsService: UnreadsService,
    private feedService: FeedService,
    private uiStateSelector: UIStateSelector,
    private translateService: TranslateService,
    private authService: AuthService,
    private flowManager: FlowManager
  ) {
    this._subSink = new SubSink();
  }

  async ngOnInit() {
    this.initBroadcastChannel();
    this.initGlobalLoader();
    //console.log("meetReq: %o", window["meetReq"]);
    if (window["meetReq"]) {
      var req = window["meetReq"];
      this.jwt = req.accessToken;
      this.roomId = req.roomId;
      this.meetRoomName = req.room;
      if (req.url) {
        this.domain = req.url;
      } else {
        this.domain = environment.meetDefaultUrl;
      }

      this.initJitsiSession();
      this.globalLoader = false;
      // delete window["meetReq"];
    } else {
      this.route.queryParams.subscribe(params => {
        let url = params["q"];
        this.jwt = params["jwt"];
        this.roomId = params["roomId"];
        this.meetRoomName = params["room"];
        if (url) {
          this.domain = url;
        } else {
          this.domain = environment.meetDefaultUrl;
        }

        this.initJitsiSession();
        this.globalLoader = false;
      });
    }


    // get displayName from state as soon appState is ready
    this.appState.ready(false)
      .then(() => {
        let userProfile = this.userDataSelector.userProfile;
        if (userProfile) this.displayName = userProfile.displayName;
        if (this.api) {
          this.api.executeCommand(
            "displayName",
            this.displayName
          );
        }
        // get jwt token to connect to chat room
        this.authService.refreshAccessToken().then(() => {
          this.appState.initTokenDone();
        }).catch((err) => {
          console.error("[AppComponent] Failed refreshing token");
        })
      });

    // only initiate chat room after token is ready
    this.appState.ready()
      .then(async () => {
        this.initApp();

        this.userMatrixId = this.userDataSelector.matrixId;
        this.isChatRoomReady = true;
        await this.hubHandshakeReady();
        this.initChatRoom();
      });
  }

  private hubHandshakeReady(): Promise<void> {
    // wait for sys hub and room hub to handshake before initializing chat
    return new Promise<void>((resolve) => {
      combineLatest(this.sysHubHandshakeStatus$, this.roomHubHandshakeStatus$).subscribe(results => {
        var sysHubReady = results[0];
        var roomHubReady = results[1];
        if (sysHubReady === HubHandshakeStatus.Completed && roomHubReady === HubHandshakeStatus.Completed) {
          resolve();
        }
      });
    });
  }

  onConnectionSuccess(e) {
    //console.log("connection success");
    //console.log(e);

    const confOptions = {
      openBridgeChannel: true
    };

    this.room = this.connection.initJitsiConference(this.room, confOptions);
    // this.room.on(JitsiMeetJS.events.conference.TRACK_ADDED, onRemoteTrack);
    // this.room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, onConferenceJoined);

    this.room.join();
  }

  initJitsiSession() {
    //console.log("[initJitsiSession]");
    get("assets/js/chat_api.js", () => {
      //console.log("[initJitsiSession] loaded");
      this.showCommands = true;
      // this.domain = "conference.everleagues.com";
      var options = {
        roomName: this.meetRoomName,
        width: "100%",
        height: "100%",
        parentNode: document.querySelector("#meet"),
        // interfaceConfigOverwrite: {
        //   TOOLBAR_BUTTONS: [
        //     "microphone",
        //     "camera",
        //     "hangup",
        //     "fullscreen",
        //     "desktop",
        //     "videoquality",
        //     "settings",
        //     //"recording",
        //     "tileview",
        //     //"videobackgroundblur",
        //     "sharedvideo",
        //     "select-background",
        //     "raisehand",
        //     "etherpad"
        //   ],
        //   SETTINGS_SECTIONS: ["devices", "language", "moderator", "profile"],
        //   TILE_VIEW_MAX_COLUMNS: 5
        // },
        configOverwrite: {
          startWithVideoMuted: true,
          //hideEmailInSettings: true,
          disableProfile: true,
          toolbarButtons: [
          "microphone",
          "camera",
          "hangup",
          "fullscreen",
          "desktop",
          "videoquality",
          "settings",
          "recording",
          "tileview",
          //"videobackgroundblur",
          "sharedvideo",
          "select-background",
          "raisehand",
          "etherpad"
          ],         
        },
        jwt: this.jwt
      };
      this.api = new JitsiMeetExternalAPI(this.domain, options);
      //this.api.executeCommand('toggleTileView');

      let self = this;
      this.api.addEventListeners({
        participantJoined: object => {
          // self.openSnackBar(object.displayName + ' joined', 'OK');
        },
        participantLeft: object => {
          // self.openSnackBar(object.displayName + ' left', 'OK');
        },
        audioMuteStatusChanged: object => {
          if (object.muted) {
            this.audioIcon = "mic_off";
          } else {
            this.audioIcon = "mic";
          }
        },
        videoMuteStatusChanged: object => {
          if (object.muted) {
            this.videoIcon = "videocam_off";
          } else {
            this.videoIcon = "videocam";
          }
        },
        screenSharingStatusChanged: object => {
          if (object.on) {
            this.isShareScreen = true;
          } else {
            this.isShareScreen = false;
          }
        },
        readyToClose: object => {
          window.close();
        }
      });
    });
  }

  initApp() {
    //######## default language
    this._subSink.sink = this.onLangChanged$.subscribe(res => {
      this.translateService.setDefaultLang(res);
      this.translateService.use(res);
    });
    //set default language to english if no preference
    if (!this.userDataSelector.language) {
      this.setLanguage.emit("en");
    }

    this.flowManager.prepareMeet();
  }

  initChatRoom() {
    //console.log("init chat room");
    //console.log("roomId: %s", this.roomId);
    this.chatRoom = this.msgSelector.getRoom(this.roomId);

    if (this._subSink) this._subSink.unsubscribe();

    this._subSink.sink = this.msgSelector
      .roomParticipants(this.roomId)
      .subscribe(ptcps => {
        //console.log("ptcps")
        this.roomPtcp = ptcps;
      });

    this.roomName = this.currentRoomName;

    //this.unreadsService.clearUnreads(this.roomId, UnreadType.ROOM);
    this.feedService.updateFeedStatusByKey(this.roomId);

    //this.clearRoomUnread.emit(this.roomId);
    this._subSink.sink = this.msgSelector
      .roomMessages(this.roomId)
      .subscribe(msgs => {
        // this.decryptMsgs(msgs);
        this.roomMessages = MsgRenderer.sortMessages(msgs);
      });

    if (this.roomMessages.length < 10) {
      //wait for get msgs hub method to return
      return this.getMsgsFromServer(this.chatRoom).then((res) => {
        this.chatMessageLoading = false;
        this.roomMessages = MsgRenderer.sortMessages(
          this.msgSelector.getMessages(this.roomId)
        );
        return;
      });
    } else {
      //call in background
      this.getMsgsFromServer(this.chatRoom).then((res) => {
        this.chatMessageLoading = false;
      })
    }

  }



  // private decryptMsgs(msgs: MessageState[]) {
  //   for (let i = 0; i < msgs.length; i++) {
  //     let msg = msgs[i];
  //     if (
  //       msg.isEncrypted &&
  //       msg.content &&
  //       this.plaintexts.findIndex(p => p.msgId == msg.id) == -1
  //     ) {
  //       this.fcp.getPlainText(msg.content, msg.id, msg.tempId);
  //     }
  //   }
  // }

  onSideNavOpened() {
    console.log("[MeetPage] onSideNavOpened");
    if (this.chatRoomComponent) {
      this.chatRoomComponent.scrollToBottom();
    }
  }

  toggleAudio() {
    this.api.executeCommand("toggleAudio");
    // this.isAudioMute = !this.isAudioMute;
  }
  toggleVideo() {
    this.api.executeCommand("toggleVideo");
  }
  toggleShareScreen() {
    this.api.executeCommand("toggleShareScreen");
  }

  hangUp() {
    this.api.executeCommand("hangup");
  }

  ngOnDestroy() {
    if (this._subSink) this._subSink.unsubscribe();
    this.channel.close();
  }

  private getMsgsFromServer(room: RoomState): Promise<void> {
    if (!room) return Promise.resolve();
    return this.roomHub.getMsgsByRoom(room);
  }

  private initBroadcastChannel() {
    // communicated with other tab
    this.channel.postMessage('ping-all-tabs');
    // post message before listening
    this.channel.addEventListener('message', (msg) => {
      console.log(msg)
      if (msg === 'ping-all-tabs') {
        // ping received from other tab, attempt to block others
        this.channel.postMessage('block');
      } else if (msg === 'block') {
        // an active tab is attempting to block all tabs
        this.router.navigate(["/multitab"], { skipLocationChange: true, queryParams: { mode: 'meet' } });
      }
    });
  }

  initGlobalLoader() {
    try {
      this._subSink.sink = this.uiStateSelector.onUIStatusChanged().subscribe(state => {
        this.globalLoaderText = null;
        if (state === undefined || state === UIStatus.Ready) {
          this.globalLoader = false;
        } else {
          this.globalLoader = true;

          // set global loader text for version update
          if (state === UIStatus.UpdatingVersion) {
            this.globalLoaderText = this.translateService.instant('UI.LOADING_UPDATE_VER');
          }
        }
      })
    } catch (err) {
      console.error("[AppComponent] initGlobalLoader unexpectedly failed. %o", err)
    }
  }
}

export interface ChatMessage {
  date: string;
  messages: Message[];
}
