import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Component, ViewEncapsulation, ViewChild, ElementRef, PipeTransform, Pipe, OnInit, Inject } from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import { InfoTrackerService } from '../../services/infotracker.service';

@Component({
  selector: 'app-infotracker-pdf-popup',
  templateUrl: './infotracker-pdf-popup.component.html',
  styleUrls: ['./infotracker-pdf-popup.component.scss']
})
export class InfotrackerPdfPopupComponent implements OnInit {
  pdfSrc = '';
  showclassify: boolean;
  fcctrl: UntypedFormControl = new UntypedFormControl();
  formval: string;
  formlist: string[];
  selectedForm: string;
  constructor(private service: InfoTrackerService, public dialogRef: MatDialogRef<InfotrackerPdfPopupComponent>) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
  }

  setPDF(loc: string): void {
    console.log(loc);
    this.service.GetPDFBlob(loc).subscribe(resp => {
       // console.log(resp);
       const file = new Blob([<any>resp], {type: 'application/pdf'});
       const fileURL = URL.createObjectURL(file);
       console.log('set pdf:' + fileURL);
       this.pdfSrc = fileURL;
    });
   }

  getTobeSignedPDF(orgUnitId: string, employeeId: string, reviewFormData: any): void {
     this.service.ReviewedFormsPrePosting(orgUnitId, employeeId, reviewFormData).subscribe(resp => {
        const file = new Blob([<any>resp], {type: 'application/pdf'});
       const fileURL = URL.createObjectURL(file);
       console.log('set pdf:' + fileURL);
       this.pdfSrc = fileURL;
    });
   }

   getInfoTrackerDocumentPDF(orgUnitId: string, employeeId: string, docId: string): void {
    this.service.GetInfoTrackerDocumentStream(orgUnitId, employeeId, docId).subscribe(resp => {
       const file = new Blob([<any>resp], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      console.log('set pdf:' + fileURL);
      this.pdfSrc = fileURL;
   });
  }

   closeme(): void {
    this.dialogRef.close();
  }

}
