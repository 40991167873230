import { ImmutableContext } from "@ngxs-labs/immer-adapter";
import { State, Selector, StateContext } from "@ngxs/store";
import { ImmutableSelector } from "@ngxs-labs/immer-adapter";
import { EmitterAction, Receiver } from "@ngxs-labs/emitter";
import { PlaintextState } from '../model/plaintext.state';
import { Injectable } from '@angular/core';
import * as _ from "lodash";

export class DecryptedMsgStateModel {
  plaintexts: PlaintextState[];

  constructor() {
    this.plaintexts = [];
  }
}

@State<DecryptedMsgStateModel>({
  name: "plaintext",
  defaults: new DecryptedMsgStateModel()
})
@Injectable()
export class DecryptedMsgState {
  constructor() { }

  ngxsAfterBootstrap(ctx: StateContext<DecryptedMsgStateModel>) {
    console.log("[DecryptedMsgState] - ngxsAfterBootstrap");
  }

  @Selector()
  static plaintexts(state: DecryptedMsgStateModel): PlaintextState[] | null {
    const texts = [...state.plaintexts];
    return _.cloneDeep(texts);
  }

  // @Receiver()
  // @ImmutableContext()
  // public static addPlaintext(
  //     ctx: StateContext<DecryptedMsgStateModel>,
  //     arg: EmitterAction<PlaintextState>
  // ) {
  //     if (!arg || !arg.payload) return;

  //     const state = ctx.getState();
  //     const plaintexts = state.plaintexts;

  //     let plaintext = plaintexts[arg.payload.msgId];
  //     if (!plaintext) {
  //         plaintexts[arg.payload.msgId] = arg.payload.plaintext;
  //     }
  //     state.plaintexts = plaintexts;

  //     ctx.setState(state);

  // }

  @Receiver()
  @ImmutableContext()
  public static addPlaintext(
    ctx: StateContext<DecryptedMsgStateModel>,
    arg: EmitterAction<PlaintextState>
  ) {
    if (!arg || !arg.payload) return;

    const state = ctx.getState();
    const plaintexts = [...state.plaintexts];

    const idx = plaintexts.findIndex(
      p => p.msgId === arg.payload.msgId || (p.tempId && p.tempId === arg.payload.tempId)
    );
    if (idx == -1) {
      state.plaintexts = [...plaintexts, arg.payload];
    }

    ctx.setState(state);
  }

  @Receiver()
  @ImmutableContext()
  public static updateMsgId(
    ctx: StateContext<DecryptedMsgStateModel>,
    arg: EmitterAction<PlaintextState>
  ) {
    if (!arg || !arg.payload) return;

    const state = ctx.getState();
    const plaintexts = [...state.plaintexts];

    const idx = plaintexts.findIndex(p => p.tempId === arg.payload.tempId);
    if (idx > -1) {
      plaintexts[idx] = DecryptedMsgState.mutatePlaintextState(
        plaintexts[idx],
        arg.payload
      );
      state.plaintexts = [...plaintexts];
    }

    ctx.setState(state);
  }

  private static mutatePlaintextState(
    existing: PlaintextState,
    state: PlaintextState
  ) {
    if (!existing.msgId && state.msgId) {
      existing.msgId = state.msgId;
    }
    existing.tempId = state.tempId;

    return existing;
  }

  @Receiver()
  static clean(ctx: StateContext<DecryptedMsgStateModel>) {
    ctx.setState({ ...new DecryptedMsgStateModel() });
  }
}
