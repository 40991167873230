import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RoomHub } from '../../core/hub/room.hub';
import { MessageState } from '../../core/model/message.state';

@Component({
  selector: 'app-dialog-delete-message',
  templateUrl: './dialog-delete-message.component.html',
  styleUrls: ['./dialog-delete-message.component.scss']
})
export class DialogDeleteMessageComponent implements OnInit {
  message: MessageState;
  isLoading = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogDeleteMessageComponent>,
    public roomHub: RoomHub) { }

  ngOnInit() {
    this.message = this.data.message;
  }

  deleteMessage() {
    this.isLoading = true;
    this.roomHub.deleteMsg(this.message.roomId, this.message.id)
      .subscribe(res => {
        this.isLoading = false;
        this.dialogRef.close({ status: 'success' });
      },
        error => {
          this.isLoading = false;
          this.dialogRef.close(error);
        });
  }
}
