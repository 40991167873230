import { EnterpriseSelector } from "./../states/enterprise.state.selector";
import { OrgHub } from "../hub/org.hub";
import { Flow, CancellationToken, GetOrgListFlow } from ".";
import { HubConnectionStatus } from "../model/hubConnection.state";
import { HistoryStateSelector } from "../states/history.state.selector";
import { Emitter, Emittable } from "@ngxs-labs/emitter";
import { EnterpriseState } from "../states/enterprise.state";
import { PubSub } from "../services/pubsub.service";
import { OrgService } from "../services/org.service";
import { UIState } from "../states/ui.state";

export class OrgHandshakeFlow extends Flow {

  @Emitter(UIState.setContactNotReady)
  setContactNotReady: Emittable<void>;

  @Emitter(UIState.setAssignmentsNotReady)
  setAssignmentsNotReady: Emittable<void>;

  @Emitter(UIState.clearReadyChannels)
  clearReadyChannels: Emittable<void>;
  
  constructor(
    private orgHub: OrgHub, 
    private enterpriseSelector: EnterpriseSelector,
    private pubSub: PubSub,
    ) {
    super();
  }

  public async execute(
    orgId: string,
    token: CancellationToken = null
  ): Promise<any> {
    // console.log("[OrgHandshakeFlow] processing...");
    // console.log("[OrgHandshakeFlow] orgid input: %o", orgId);

    this.setContactNotReady.emit(null);
    this.setAssignmentsNotReady.emit(null);
    this.clearReadyChannels.emit();
    
    if (!this.orgHub.isConnected) {
      var sysHubStarted = await new StartOrgHubFlow(this.orgHub).execute(
        null,
        token
      );
      if (sysHubStarted == false) {
        this.orgHub.failedOverHandling();
        return;
      }
    }

    this.orgHub.setNotReady();

    // get id of user's org role to check role change on server side
    let orgUser = this.enterpriseSelector.getUserByOrg(orgId);
    let orgRole = null;
    if (orgUser) {
      orgRole = orgUser ? orgUser.role : null;
    }

    let data = await this.orgHub.handshakeMethod(orgId, orgRole)
    .catch((err) => {
      if (err == "user_org_role_change") {
        console.log("[OrgHubFlow] user's org role changed, force logout");
        this.pubSub.next(PubSub.FORCE_LOGOUT, "rolechanged");
      }
      return err;
    });

    if (token != null && token.isCancelled) return null;

    var orgState = this.orgHub.updateHandshakeData(data);
    if (orgState == null) throw new Error("Failed to update enterprise. ");

    this.output = orgState.id;
    // console.log("[OrgHandshakeFlow] produced result: %o", this.output);

    await super.execute(this.output, token);

    this.orgHub.setNotReady();

    return this.output;
  }
}

export class StartOrgHubFlow extends Flow {
  constructor(private orgHub: OrgHub) {
    super();
  }

  public async execute(
    input: any = null,
    token: CancellationToken = null
  ): Promise<boolean> {
    if (this.orgHub.hubSnapshot.status !== HubConnectionStatus.Connected) {
      this.output = await this.orgHub
        .start()
        .toPromise()
        .catch((err) => {
          console.error("[StartOrgHubFlow] %o", err);
          return false;
        });
    } else {
      this.output = true;
    }
    // console.log("[StartOrgHubFlow] produced result: %o", this.output);

    return await super.execute(this.output, token);
  }
}

export class ReconnectOrgHubFlow extends Flow {
  @Emitter(EnterpriseState.switchOrg)
  switchOrg: Emittable<string>;

  constructor(
    private orgHub: OrgHub,
    private enterpriseSelector: EnterpriseSelector,
    private historySelector: HistoryStateSelector,
    private pubSub: PubSub, 
    private orgService: OrgService
  ) {
    super();
  }

  public sketch(): Flow {
    return super.sketch([
      new GetOrgListFlow(this.orgHub, this.orgService),
      new OrgHandshakeFlow(this.orgHub, this.enterpriseSelector, this.pubSub)]);
  }

  public async execute(
    input: any = null,
    token: CancellationToken = null
  ): Promise<boolean> {
    var orgId = this.enterpriseSelector.getCurrentOrgId();
    if (!orgId) {
      orgId = this.historySelector.userLastLogin;
    }

    await super.execute(orgId, token);

    if (
      this.output[1] != orgId ||
      !this.enterpriseSelector.isCurrentOrg(this.output[1])
    ) {
      await this.switchOrg.emit(this.output[1]).toPromise();
    }

    return this.output[1];
  }
}
