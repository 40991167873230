import { RedirectService } from './../../core/services/redirect.service';
import { AppState } from "./../../core/states/app.state";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { MeetService } from "../../core/services/meet.service";
import * as _ from "lodash";
import { MessagingSelector } from "../../core/states/messaging.state.selector";
import { RoomHub } from "../../core/hub/room.hub";
import { Select } from "@ngxs/store";
import { HubStateSelector } from "../../core/states/hub.state.selector";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { HubHandshakeStatus } from "../../core/model/hubConnection.state";
import { filter } from "rxjs/operators";
import { RoomState } from "../../core/model/room.state";
import { MatDialog } from "@angular/material/dialog";
import { DialogSwitchOUComponent } from "../../core/ui/dialog/switch-ou/dialog-switch-ou.component";
import { DialogConfirmComponent } from "../../core/ui/dialog/dialog-confirm/dialog-confirm.component";
import { EnterpriseSelector } from "../../core/states/enterprise.state.selector";
import { UserDataSelector } from "../../core/states/user-data.state.selector";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-join-meet-page",
  templateUrl: "./join-meet-page.component.html",
  styleUrls: ["./join-meet-page.component.scss"],
})
export class JoinMeetPageComponent implements OnInit {
  @Select(HubStateSelector.roomHubHandshakeStatus)
  roomHubHandshakeStatus$: Observable<HubHandshakeStatus>;

  private _roomStatusSub: Subscription;
  isHubReady = new BehaviorSubject(false);
  isHubReady$: Observable<boolean>;
  roomState: RoomState ;
  
  constructor(
    private route: ActivatedRoute,
    private meetService: MeetService,
    private redirectService: RedirectService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private appState: AppState,
    private router: Router,
    public msgSelector: MessagingSelector,
    private roomHub: RoomHub,
    public dialog: MatDialog,
    public enterpriseSelector: EnterpriseSelector,
    private userDataSelector: UserDataSelector,
  ) {this.isHubReady$ = this.isHubReady.pipe(filter(res => res));}

  ngOnInit(): void {
    this._roomStatusSub = this.roomHubHandshakeStatus$.subscribe(async (status) => {
      if (status === HubHandshakeStatus.Completed) {
        this.isHubReady.next(true);
        // run only once
        this._roomStatusSub.unsubscribe();
      }
    })

    this.route.params.subscribe((params: Params) => { 
      var room = params.room;
      if (room) {
        this.route.queryParams.subscribe((params) => {
          let jwt = params["jwt"];
          let isUser = params["isUser"] ? params["isUser"] : false;
          this.appState.ready().then(() => {
            if (isUser) {
              this.joinMeet(room, jwt);
            } else {
              //check if user is logged in
              this.redirectService.joinMeetHandler(room, jwt);
            }
          });
        });
      }
    });
  }

  joinMeet(room: string, jwt: string) {
    this.meetService.joinMeetWithoutUrl(room, jwt).then((res) => {
      if (res && res.url){
        this.isHubReady$.subscribe(async () => {
          this.roomHub.getRoomByRoomMatrixID(res.matrixRoom).then((room) => {
            this.roomState = room;
            this.openMeetPage(res);

            this.isHubReady.unsubscribe();
          })
        });
      }
      else{
        this.openSnackBar(
          this.translateService.instant("CHAT.MEETING_ERROR"),
          this.translateService.instant("REPEAT.OK")
        );
        this.router.navigate(['/main/dashboard']);
      }
    },
    res => {this.joinMeetOnRejected(res);});
  }

  private joinMeetOnRejected(res: HttpErrorResponse) {
    if (res.status == 400) { // Bad request with custom response code
      this.showConfirmDialog(
        this.translateService.instant("CHAT.JOIN_MEETING_ERROR"), 
        res.error.error
        );
    }
    else if (res.status == 401) { // Unauthorized
      this.showConfirmDialog(
        this.translateService.instant("DASHBOARD.UNAUTHORIZED.TITLE"),
        this.translateService.instant("DASHBOARD.UNAUTHORIZED.DESC_3")
        );
    }
    else {
      this.openSnackBar(
        this.translateService.instant("CHAT.MEETING_ERROR"),
        this.translateService.instant("REPEAT.OK")
      );
    }
    this.router.navigate(['/main/dashboard']);
  }

  private showConfirmDialog(title: string, content: string) {
    const dialogRef = this.dialog.open(DialogConfirmComponent, {
      width: "400px",
      data: {
        title: title,
        content: content,
      },
    });
  }

  openMeetPage(res: any) {
    const url = this.meetService.getHostName(res.url);
    const jwt = res.accessToken;
    const room = res.room;
    // var roomId = res.matrixRoom;
    const roomId = this.roomState.id;
    const orgId = this.roomState.orgId;

    if (!this.enterpriseSelector.isCurrentOrg(orgId)){
      const dialogRef = this.dialog.open(DialogSwitchOUComponent, {
        width: "400px",
        data: {
          orgId: this.roomState.orgId,
          disableRedirect: true
        },
      });
      dialogRef.afterClosed().subscribe((r) => {
        // var w = window.open("/meet");
        // w["meetReq"] = { url: url, accessToken: jwt, room: room, roomId: roomId };

        // this.router.navigate(['/main/dashboard']);
        this.router.navigate(['/meet'], { queryParams: { q: url, jwt: jwt, room: room, roomId: roomId } });
      });
    }else{
      // var w = window.open("/meet");
      // w["meetReq"] = { url: url, accessToken: jwt, room: room, roomId: roomId };

      // this.router.navigate(['/main/dashboard']);
      this.router.navigate(['/meet'], { queryParams: { q: url, jwt: jwt, room: room, roomId: roomId } });
    };
    

    
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}
