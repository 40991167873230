import { SubRealtimeData } from '../model/sub-realtime-data.model';
import { Invoice } from './../model/subscription.state';
import { RoleEntity, RoleType } from './../model/userRole.model';
import { PubSub } from './../services/pubsub.service';
import { AssignmentState, ContactType, MembershipReqState } from './../model/org.state';
import { MediaService } from "./../services/media.service";
import { BaseHub } from "./base.hub";
import { SubSink } from "subsink";
import { environment as ENV } from "../../../environments/environment";
import { Select } from "@ngxs/store";
import {
  Observable,
  throwError,
  Subscription,
  forkJoin,
  Subject,
} from "rxjs";
import { Injectable } from "@angular/core";
import { pocolog } from "pocolog";
import {
  map,
  tap,
  distinctUntilChanged,
  flatMap,
  filter,
} from "rxjs/operators";
import { HubStateSelector } from "../states/hub.state.selector";
import {
  OrgState,
  PostState,
  PostCommentState,
  OUState,
  OrgUserState,
  TeamState,
  TeamMemberState,
  ChannelState,
  ContactState,
  OrgRoleState,
  OrgPermissionState,
  PostType,
  CommentType,
  TeamRole,
  ContactPhoneState,
  ContactEmailState,
  ContactAddressState,
  PaymentMethodState,
  BuiltInUserState,
} from "../model/org.state";
import { MessagingSelector } from "../states/messaging.state.selector";
import { AuthStateSelector } from "../states/auth.state.selector";
import { NetworkService } from "../services/network.service";
import { EnterpriseSelector } from "../states/enterprise.state.selector";
import { UserDataSelector } from "../states/user-data.state.selector";
import { EnterpriseState } from "../states/enterprise.state";
import { Emitter, Emittable } from "@ngxs-labs/emitter";
import { HubState } from "../states/hub.state";
import {
  HubConnectionState,
  HubHandshakeStatus,
} from "../model/hubConnection.state";
import { OrgType } from "../enum/org-type";
import { EntityStatus } from "../enum/entity-status.enum";
import { UserStatus } from "../enum/user-status.enum";
import { MessageSendStatus } from "../model/message.model";
import { ShortGuid } from "../util/shortGuid";
import { FileStateSelector } from "../states/file.state.selector";
import { FileObjState, FileType } from "../model/file-obj.state";
import { FileState } from "../states/file.state";
import { ChangeState } from "../enum/change-state.enum";
import {
  SubscriptionState, SubStatus
} from "../model/subscription.state";
import * as _ from "lodash";
import { UIState } from '../states/ui.state';
import { AppStateSelector } from '../states/app.state.selector';
import { HubEvent } from './hub.event';
import { ClearUnreadDto, UnreadDto } from '../model/unread.state';
import { MessagingState } from '../states/messaging.state';
import { TokenProvider } from '../services/jwt-token.provider';
import { SubType } from "../../core/enum/sub-type";
import { LicenseState } from '../states/license.state';
import { FeatureState } from '../model/feature.state';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmComponent } from "./../ui/dialog/dialog-confirm/dialog-confirm.component";
import moment from 'moment';
import { OrgExtTool } from '../model/org-ext-tool';
// import { InMemLicenseState } from '../states/inmem.license.state';
import { FeatureDailyUsage } from "../model/feature-daily-usage.state";
import { LicenseStateSelector } from '../states/license.state.selector';
import { Contract } from '../model/contract.model';
@Injectable({
  providedIn: "root",
})
export class OrgHub extends BaseHub {
  //#region Hub listener
  private ORG_CONTACTS: string = "org-contacts";
  private ORG_POSTS: string = "org-posts";
  private ORG_INFO: string = "org-general";
  private ORG_MEMBERS: string = "org-member";
  private ORG_ASSIGNMENTS: string = "org-assignments";
  private ORG_TEAMS: string = "org-teams";
  private ORG_CHANNELS: string = "org-channels";
  private ORG_PAYMENT_METHOD: string = "org-payment-method";
  private ORG_MAIN: string = "org-main";
  private OU_MAIN: string = "ou-main";
  private ORG_MEMBERSHIP_REQ: string = "org-membership-req";
  private UNREADS: string = "org-unreads";
  private SUB_MAIN: string = "sub-main";
  private EXT_TOOLS: string = "org-ext-tools";

  //#endregion  
  //#region Hub method
  private POST_CONTENT_UDPATE_METHOD: string = "post-update-content";
  private POST_DELETE_METHOD: string = "post-delete";
  private POST_NEW_METHOD: string = "post-new";
  private POST_CHAT_NEW_METHOD = "post-chat-new";
  private POST_CHAT_DELETE_METHOD = "post-chat-delete";
  private OLD_POSTS_GET_METHOD = "old-posts-get";
  private OLD_POST_CHATS_GET_METHOD = "old-post-chats-get";
  private OLD_CHANNEL_MEDIAS_GET_METHOD = "old-ch-medias-get";
  private ALL_CHANNEL_MEDIAS_GET_METHOD = "all-ch-medias-get";
  private POST_GET_METHOD = "post-get";

  private ORG_HANDSHAKE_METHOD: string = "org-handshake";
  private HANDSHAKE_BY_ORG_METHOD: string = "handshake-by-org-2";
  private GET_ORGS_METHOD: string = "orgs-get-2";

  private ADD_OFFLINE_CONTACT_METHOD: string = "contact-new-offline";
  private UPDATE_CONTACT_METHOD: string = "contact-update";
  private DELETE_CONTACT_METHOD: string = "contact-delete";
  private GET_CONTACT_METHOD: string = "contact-get";
  private CONTACT_CLIENT_CREATE = "contact-client-create";
  private CONTACTS_GET_METHOD: string = "contacts-get";
  private MUTUAL_FRIENDS_GET_METHOD: string = "mutual-friends";
  private USER_GET_METHOD: string = "get-user";

  private ADD_USER_BY_CONTACT_MEHTOD: string = "add-user-by-contact";
  private ADD_USER_TO_PERSONAL_METHOD: string = "add-user-to-personal";

  private OU_CREATE: string = "ou-create";
  private OU_UPDATE: string = "ou-update";
  private OU_DELETE: string = "ou-delete";

  private GET_MEMBERSHIP_REQ = "membership-req-get"
  private GET_ROLETYPES = "roletypes-get"

  private TEAM_CREATE: string = "team-create";
  private TEAM_UDPATE: string = "team-update";
  private TEAM_DELETE: string = "team-delete";
  private TEAM_CHANGE_OWNER: string = "team-change-owner";
  private TEAM_GET_METHOD: string = "teams-get";
  private TEAM_OWNED_GET_METHOD: string = "owned-teams-get";
  private TEAM_UPDATE_NAME: string = "team-update-name";
  private TEAM_UPDATE_MEM_VISIBILITY: string = "team-update-member-visibility";
  private TEAM_UPDATE_CREATE_CHANNEL: string = "team-update-create-channel";
  private TEAM_UPDATE_CREATE_POST: string = "team-update-create-post";
  private TEAM_UPDATE_CREATE_POST_COMMENT: string = "team-update-create-post-comment";
  private TEAM_UPDATE_IMPLICIT: string = "team-update-implicit";
  private TEAM_ADD_MEMBERS: string = "team-add-members";
  private TEAM_DELETE_MEMBERS: string = "team-delete-members";
  private TEAM_UPDATE_MEMBERS: string = "team-update-members";

  private CHANNEL_CREATE: string = "channel-create";
  private CHANNEL_UDPATE: string = "channel-update";
  private CHANNEL_DELETE: string = "channel-delete";
  private CHANNEL_SEARCH_METHOD: string = "channel-search";

  private ORG_DELETE: string = "org-delete";
  private ORG_UPDATE: string = "org-update";
  private ORG_UPDATE_EDIT_MSG: string = "org-update-edit-msg";
  private ORG_UPDATE_DELETE_MSG: string = "org-update-delete-msg";
  private ORG_UPDATE_AUTO_JOIN: string = "org-update-auto-join";
  private ORG_UPDATE_MYDRIVE: string = "org-update-my-drive";
  private ORG_UPDATE_RETENTION: string = "org-update-retention";
  private ORG_CREATE: string = "org-create";

  private PAYMENT_METHOD_READ: string = "payment-method-read";
  private PAYMENT_METHOD_CREATE: string = "payment-method-create";
  private PAYMENT_METHOD_DELETE: string = "payment-method-delete";
  private PAYMENT_METHOD_SET_DEFAULT: string = "payment-method-set-default";

  private INVITE_ISSUE: string = "invite-issue";
  private INVITE_REISSUE: string = "invite-reissue";
  private INVITE_DELETE: string = "invite-delete";

  private GET_USER_PUBLIC_PROFILE: string = "user-public-profile-get";
  private GET_PUBKEY: string = "publickey-get";

  private UNREADS_CLEAR: string = "unreads-clear";
  private UNREADS_CLEAR_MULTIPLE: string = "unreads-clear-multiple";
  private FEATURE_DAILY_USAGES_GET_METHOD = "feature-daily-usage-get";
  private GET_SUB_REALTIME_DATA_METHOD = "sub-realtime-data-get";

  private TOOLS_VISIBILITY: string = "tools-visibility";
  private ORG_TOOLS_GET: string = "org-tool-get";

  //#endregion
  //#region Hub Event Name
  NEW_ORG: string = "new-org";
  UPDATE_ORG: string = "update-org";
  DELETE_ORG: string = "delete-org";
  UPDATE_FEATURES: string = "update-features";
  DELETE_FEATURES: string = "delete-features";
  NEW_FEATURE_USAGES: string = "new-usages";

  NEW_OU: string = "new-ou";
  UPDATE_OU: string = "update-ou";
  DELETE_OU: string = "delete-ou";

  LIST_POST: string = "ls-post";
  UPDATE_POST: string = "update-post";
  DELETE_POST: string = "delete-post";
  NEW_POST: string = "new-post";
  DELETE_POST_CHAT: string = "delete-post-chat";
  NEW_POST_CHAT: string = "new-post-chat";

  UPDATE_SUB: string = "update-subscription";
  GET_INVOICES: string = "invoices-get";
  GET_CONTRACTS: string = "contracts-get";

  // payg
  UPGRADE_SUB: string = "sub-upgrade";
  UPDATE_FAILED_PAYMENT_SUB: string = "sub-update-failed-payment";
  GET_FEATURES: string = "features-get";
  GET_FEATURE: string = "feature-get";

  //team hub event
  NEW_TEAM: string = "new-team";
  DELETE_TEAM: string = "delete-team";
  UPDATE_TEAM: string = "update-team";
  NEW_TEAM_MEMBERS: string = "new-team-members";
  DELETE_TEAM_MEMBERS: string = "delete-team-members";
  CHANGE_TEAM_ROLE: string = "change-team-role";
  CHANGE_TEAM_OWNER: string = "change-team-owner";

  NEW_CHANNEL: string = "new-channel";
  NEW_CHANNELS: string = "new-channels";
  UPDATE_CHANNEL: string = "update-channel";
  DELETE_CHANNEL: string = "delete-channel";

  UPDATE_ORGMEMBER_STATUS: string = "update-orgmember-status";
  CHANGE_ORGMEMBER_ROLE: string = "change-orgmember-role";
  MOVE_ORGMEMBER_OU: string = "move-orgmember-ou";
  ORGUSER_MOVE: string = "orguser-move";
  ORGUSER_ROLE_CHANGE: string = "orguser-role-change";
  ORGUSER_PROMOTE_ADMIN: string = "orguser-promote-admin";
  ORGUSER_DEMOTE_ADMIN: string = "orguser-demote-admin";
  ORGUSER_SUSPEND: string = "orguser-suspend";
  ORGUSER_REACTIVATE: string = "orguser-reactivate";
  ORGUSER_DELETE: string = "orguser-delete";
  ORGUSER_RESTORE: string = "orguser-restore";
  DELETE_USER: string = "delete-user";

  NEW_INVITES: string = "new-invites";

  //delegation
  ASSIGN_CLIENTS_TO_STAFF = "assign-clients-to-staff";
  REMOVE_CLIENTS_FROM_STAFF = "remove-clients-from-staff";
  ASSIGN_STAFFS_TO_CLIENT = "assign-staffs-to-client";
  REMOVE_STAFFS_FROM_CLIENT = "remove-staffs-from-client";
  ASSIGNMENTS_GET = "assignments-get";

  NEW_CONTACTS: string = "new-contacts";
  DELETE_CONTACT: string = "delete-contact";
  DELETE_CONTACTS: string = "delete-contacts";
  UPDATE_CONTACTS: string = "update-contacts";

  NEW_PAYMENT_METHOD: string = "new-payment-method";
  DELETE_PAYMENT_METHOD: string = "delete-payment-method";

  ISSUE_INVITE: string = "issue-invite";
  REISSUE_INVITE: string = "reissue-invite";
  DELETE_INVITE: string = "delete-invite";
  DELETE_INVITES: string = "delete-invites";

  LIST_ORG: string = "ls-org";
  LIST_CLIENT_ASSIGNMENT = "ls-client-assignment";

  ADD_UNREAD = "add-unread";
  CLEAR_UNREAD = "clear-unread";
  CLEAR_UNREADS = "clear-unreads";

  // rework subscription
  SUB_PENDING_UPDATE_PAYMENT = "sub-pending-update-payment";
  SUB_CHANGE = "sub-change";

  UPDATE_EXT_TOOLS = "update-ext-tools-visibility";

  //#endregion
  private _subs: SubSink;
  private _hubStateSelector: HubStateSelector;
  private _onPendingTextPostSub: Subscription;
  private _onPendingMediaPostSub: Subscription;
  private _onPendingTextCommentSub: Subscription;
  private _onPendingMediaCommentSub: Subscription;
  private _userPublicProfiles: any = {};
  private _maxResendAttempt: number = 3;
  private _isFirstLogin: boolean = false;
  private _activeChannelId: string;
  private _activeRoomId: string;

  onToolsUpdate: Subject<any>;

  constructor(
    msgSelector: MessagingSelector,
    hubStateSelector: HubStateSelector,
    authSelector: AuthStateSelector,
    networkService: NetworkService,
    pubSub: PubSub,
    appStateSelector: AppStateSelector,
    userDataSelector: UserDataSelector,
    tokenProvider: TokenProvider,
    private dialog: MatDialog,
    private enterpriseSelector: EnterpriseSelector,
    private fileStateSelector: FileStateSelector,
    private mediaService: MediaService,
    private licenseSelector: LicenseStateSelector,
    public snackBar: MatSnackBar,
    private router: Router
  ) {
    super(
      "orgHub",
      ENV.orgHubLink,
      msgSelector,
      appStateSelector,
      authSelector,
      networkService,
      pubSub,
      userDataSelector,
      tokenProvider
    );
    this._hubStateSelector = hubStateSelector;
    this._subs = new SubSink();
    console.log("[orgHub] init");
    this.initOrgHub();
    this.onToolsUpdate = new Subject<any>();
  }

  //#region Select, Emitter
  @Select(EnterpriseState.posts)
  posts$: Observable<PostState[]>;

  @Select(EnterpriseState.comments)
  comments$: Observable<PostCommentState[]>;

  @Select(EnterpriseState.selectedOrg)
  selectedOrg$: Observable<OrgState>;

  @Select(EnterpriseState.orgs)
  orgs$: Observable<OrgState[]>;

  @Select(EnterpriseState.pendingMediaComments)
  pendingMediaComments$: Observable<PostCommentState[]>;

  @Select(EnterpriseState.pendingTextComments)
  pendingTextComments$: Observable<PostCommentState[]>;

  @Select(EnterpriseState.pendingMediaPosts)
  pendingMediaPosts$: Observable<PostState[]>;

  @Select(EnterpriseState.pendingTextPosts)
  pendingTextPosts$: Observable<PostState[]>;

  @Select(EnterpriseState.pendingComments)
  pendingComments$: Observable<PostCommentState[]>;

  @Select(UIState.activeChannel)
  activeChannel$: Observable<string>;

  @Select(UIState.activeRoom)
  activeRoom$: Observable<string>;

  @Emitter(FileState.addOrUpdateFile)
  addOrUpdateFile: Emittable<FileObjState>;

  @Emitter(EnterpriseState.switchOrg)
  public switchOrg: Emittable<string>;

  @Emitter(EnterpriseState.addOrUpdateOrgs)
  public addOrUpdateOrgs: Emittable<OrgState[]>;

  @Emitter(EnterpriseState.addOrUpdateOUs)
  public addOrUpdateOUs: Emittable<OUState[]>;

  @Emitter(EnterpriseState.deleteOU)
  public deleteOUState: Emittable<OUState>;

  @Emitter(EnterpriseState.deleteOrg)
  public deleteOrgState: Emittable<string>;

  @Emitter(EnterpriseState.updateExtTools)
  public updateExtTools: Emittable<OrgExtTool>;

  @Emitter(EnterpriseState.replaceOrgUsers)
  public replaceOrgUsers: Emittable<OrgUserState[]>;

  @Emitter(EnterpriseState.addOrUpdateOrgUser)
  public addOrUpdateOrgUser: Emittable<OrgUserState[]>;

  @Emitter(EnterpriseState.addOrUpdateAssignments)
  public addOrUpdateAssignments: Emittable<AssignmentState[]>;

  @Emitter(EnterpriseState.resetAssignments)
  public resetAssignments: Emittable<AssignmentState[]>;

  @Emitter(UIState.setAssignmentsReady)
  setAssignmentsReady: Emittable<void>;

  @Emitter(EnterpriseState.addOrUpdateTeams)
  public addOrUpdateTeams: Emittable<TeamState[]>;

  @Emitter(EnterpriseState.deleteTeam)
  public removeTeam: Emittable<TeamState>;

  @Emitter(EnterpriseState.addOrUpdateTeamMembers)
  public addOrUpdateTeamMembers: Emittable<TeamMemberState[]>;

  @Emitter(EnterpriseState.deleteTeamMembers)
  public deleteTeamMembers: Emittable<TeamMemberState[]>;

  @Emitter(EnterpriseState.addOrUpdateChannels)
  public addOrUpdateChannels: Emittable<ChannelState[]>;

  @Emitter(EnterpriseState.deleteChannel)
  public removeChannel: Emittable<ChannelState>;

  @Emitter(EnterpriseState.addOrUpdatePosts)
  public addOrUpdatePosts: Emittable<PostState[]>;

  @Emitter(EnterpriseState.addEnterpriseUnreads)
  addEnterpriseUnreads: Emittable<UnreadDto[]>;

  @Emitter(EnterpriseState.addEnterpriseUnread)
  addEnterpriseUnread: Emittable<UnreadDto>;

  @Emitter(EnterpriseState.clearEnterpriseUnread)
  clearEnterpriseUnread: Emittable<ClearUnreadDto>;

  @Emitter(MessagingState.addMsgUnreads)
  addMsgUnreads: Emittable<UnreadDto[]>;

  @Emitter(MessagingState.addMsgUnread)
  addMsgUnread: Emittable<UnreadDto>;

  @Emitter(MessagingState.clearMsgUnread)
  clearMsgUnread: Emittable<ClearUnreadDto>;

  @Emitter(EnterpriseState.addPendingUnreadsToClear)
  addPendingUnreadsToClear: Emittable<ClearUnreadDto>;

  @Emitter(EnterpriseState.removePendingUnreadsToClear)
  removePendingUnreadsToClear: Emittable<null>;

  @Emitter(EnterpriseState.deleteComment)
  deleteComment: Emittable<PostCommentState>;

  @Emitter(EnterpriseState.addOrUpdateComments)
  public addOrUpdateComments: Emittable<PostCommentState[]>;

  @Emitter(EnterpriseState.addOrUpdateContacts)
  public addOrUpdateContacts: Emittable<ContactState[]>;

  @Emitter(EnterpriseState.deleteContact)
  public removeContact: Emittable<ContactState>;

  @Emitter(EnterpriseState.deleteContacts)
  deleteContacts: Emittable<ContactState[]>;

  @Emitter(EnterpriseState.addOrUpdateRoles)
  public addOrUpdateRoles: Emittable<OrgRoleState[]>;

  @Emitter(EnterpriseState.addOrUpdatePermissions)
  public addOrUpdatePermissions: Emittable<OrgPermissionState[]>;

  @Emitter(EnterpriseState.addOrUpdatePaymentMethods)
  public addOrUpdatePaymentMethods: Emittable<PaymentMethodState[]>;

  @Emitter(EnterpriseState.deletePaymentMethod)
  public deletePaymentMethod: Emittable<string>;

  @Emitter(EnterpriseState.addOrUpdateBuiltInUsers)
  public addOrUpdateBuiltInUser: Emittable<BuiltInUserState[]>;

  @Emitter(LicenseState.addOrUpdateSubscriptions)
  public addOrUpdateSubscriptions: Emittable<SubscriptionState[]>;

  @Emitter(LicenseState.addOrUpdateFeatures)
  public addOrUpdateFeatures: Emittable<FeatureState[]>;

  @Emitter(LicenseState.deleteFeatures)
  public deleteFeatures: Emittable<string[]>;

  @Emitter(LicenseState.deleteSubscriptions)
  public deleteSubscription: Emittable<string>;

  @Emitter(EnterpriseState.addOrUpdateMembershipReq)
  public addOrUpdateMembershipReq: Emittable<MembershipReqState[]>;

  @Emitter(EnterpriseState.deleteMembershipReqs)
  public deleteMembershipReqs: Emittable<MembershipReqState[]>;

  @Emitter(UIState.setChannelPostsReady)
  public setChannelPostsReady: Emittable<string>;

  @Select(HubState.orghub)
  orghubConnection$: Observable<HubConnectionState>;

  @Emitter(HubState.setOrgHubConnection)
  setOrgHubConnection: Emittable<HubConnectionState>;

  @Emitter(HubState.updateOrgHubHandshakeStatus)
  updateHandshakeStatus: Emittable<HubHandshakeStatus>;

  @Emitter(EnterpriseState.deleteOrgs)
  deleteOrgs: Emittable<OrgState[]>;

  @Emitter(FileState.clean)
  _cleanFileState: Emittable<void>;

  // @Emitter(InMemLicenseState.addUsages)
  // addUsages: Emittable<FeatureDailyUsageState[]>;
  //#endregion

  get setHubConnectionStatus(): Emittable<HubConnectionState> {
    return this.setOrgHubConnection;
  }
  get hub$(): Observable<HubConnectionState> {
    return this.orghubConnection$;
  }
  get hubSnapshot(): HubConnectionState {
    return this._hubStateSelector.getOrgHub();
  }
  get setHandshakeStatus(): Emittable<HubHandshakeStatus> {
    return this.updateHandshakeStatus;
  }

  get _currentOrg(): OrgState {
    return this.enterpriseSelector.getCurrentOrg();
  }

  private initOrgHub() {
    let self = this;
    if (self._subs) self._subs.unsubscribe();

    self._subs.sink = this.userDataSelector.onLoggedInChanged().subscribe(() => {
      this._isFirstLogin = true;
    });

    self._subs.sink = self.onHubConnected$.subscribe(() => {
      console.log("[orgHub] onHubConnected$");
      if (!self.connection) {
        console.log("[orgHub] hub connection is null");
        return;
      }
      console.log("[orgHub] register org post event");
      self.register(self.ORG_POSTS);
      console.log("[orgHub] register org member event");
      self.register(self.ORG_MEMBERS);
      console.log("[orgHub] register org teams event");
      self.register(self.ORG_TEAMS);
      console.log("[orgHub] register org main event");
      self.register(self.ORG_MAIN);
      console.log("[orgHub] register ou main event");
      self.register(self.OU_MAIN);
      console.log("[orgHub] register org channels event");
      self.register(self.ORG_CHANNELS);
      console.log("[orgHub] register org contacts event");
      self.register(self.ORG_CONTACTS);
      console.log("[orgHub] register org payment method event");
      self.register(self.ORG_PAYMENT_METHOD);
      console.log("[orgHub] register org membership req event");
      self.register(self.ORG_MEMBERSHIP_REQ);
      self.register(self.UNREADS);
      console.log("[orgHub] register sub main event");
      self.register(self.SUB_MAIN);
      self.register(self.ORG_ASSIGNMENTS);
      self.register(self.EXT_TOOLS);
      console.log("[orgHub] register org assignments event");

      self.clearPendingUnreads();

      console.log("[orgHub] start hanshake");
      // this.prepareSelectedOrg()
      //   .then((preSelect) => {
      //     //if get orgs failed and current orgId is null in handshake method, server will return personal space
      //     var orgId = this.enterpriseSelector.getCurrentOrgId();
      //     console.log("[orgHub] - begin handshake");
      //     self.handshake(orgId)
      //       .then(() => {
      //         console.log("[orgHub] - done handshake");
      //         if (preSelect == true) {
      //           this.pubSub.next(PubSub.ON_FIRST_ORG_SWITCH, orgId);
      //         }
      //       });
      //   })
      //   .catch(err => console.error(err));
    });

    self._subs.sink = self.onHubConnectionStatusChanged.subscribe((state) => {
      if (!state) return;

      if (this.isConnected) {
        this.onPendingTextPost();
        this.onPendingTextComment();
      } else if (this.isOffline) {
        //stop sending if hub disconnected
        if (this._onPendingTextPostSub)
          this._onPendingTextPostSub.unsubscribe();
        if (this._onPendingTextCommentSub)
          this._onPendingTextCommentSub.unsubscribe();
      }
    });

    // Sync from mobile app
    if (this.networkService.isOnline) {
      this.onPendingMediaPost();
      this.onPendingMediaComment();
    }

    self._subs.sink = this.networkService.onNetworkChanged.subscribe(
      (connected) => {
        if (connected == true) {
          this.onPendingMediaPost();
          this.onPendingMediaComment();
        } else {
          //stop sending if no internet connection
          if (this._onPendingMediaPostSub)
            this._onPendingMediaPostSub.unsubscribe();
          if (this._onPendingMediaCommentSub)
            this._onPendingMediaCommentSub.unsubscribe();
        }
      }
    );

    self._subs.sink = self.activeChannel$.subscribe(res => {
      this._activeChannelId = res
    });

    self._subs.sink = self.activeRoom$.subscribe((res) => {
      this._activeRoomId = res;
    });

    //trigger when receive new posts
    self._subs.sink = this.posts$
      .pipe(distinctUntilChanged((prev, curr) => prev.length === curr.length))
      .subscribe((posts) => {
        //TODO: See how angular handle file in web app
        // posts
        //   .filter(p => p.mediaId && !this.fileStateSelector.getFile(p.mediaId))
        //   .forEach(p => {
        //     this.fileManager.prepareFileObj(
        //       p.orgId,
        //       p.mediaId,
        //       p.type == PostType.Image ? FileType.Image : FileType.File,
        //       p.fwt,
        //       p.mediaName
        //     );
        //   });
      });

    self._subs.sink = this.comments$
      .pipe(distinctUntilChanged((prev, curr) => prev.length === curr.length))
      .subscribe((comments) => {
        //TODO: See how angular handle file in web app
        // comments
        //   .filter(c => c.mediaId && !this.fileStateSelector.getFile(c.mediaId))
        //   .forEach(c => {
        //     this.fileManager.prepareFileObj(
        //       c.orgId,
        //       c.mediaId,
        //       c.type == CommentType.Image ? FileType.Image : FileType.File,
        //       c.fwt,
        //       c.mediaName
        //     );
        //   });
      });

    self._subs.sink = self
      .channelEvent$(self.ORG_MEMBERS)
      .subscribe((event) => {
        console.log("[orgHub]-ORG_MEMBERS, %o", event);
        self.onOrgUserUpdate(event);
      });

    self._subs.sink = self
      .channelEvent$(self.ORG_ASSIGNMENTS)
      .subscribe((event) => {
        console.log("[orgHub]-ORG_ASSIGNMENTS, %o", event);
        self.onOrgAssignmentsUpdate(event);
      });

    self._subs.sink = self.channelEvent$(self.ORG_TEAMS).subscribe((event) => {
      console.log("[orgHub]-ORG_TEAMS, %o", event);
      self.onTeamUpdate(event);
    });

    // self._subs.sink = self
    //   .channelEvent$(self.ORG_CHANNELS)
    //   .subscribe((event) => {
    //     console.log("[orgHub]-ORG_CHANNELS, %o", event);
    //     self.onChannelUpdate(event);
    //   });

    self._subs.sink = self.channelEvent$(self.ORG_POSTS).subscribe((event) => {
      console.log("[orgHub]-ORG_POSTS, %o", event);
      self.onPostUpdate(event);
    });

    self._subs.sink = self.channelEvent$(self.ORG_MAIN).subscribe((event) => {
      console.log("[orgHub]-ORG_MAIN, %o", event);
      self.onOrgUpdate(event);
    });

    self._subs.sink = self.channelEvent$(self.OU_MAIN).subscribe((event) => {
      console.log("[orgHub]-OU_MAIN, %o", event);
      self.onOuUpdate(event);
    });

    self._subs.sink = self
      .channelEvent$(self.ORG_CONTACTS)
      .subscribe((event) => {
        console.log("[orgHub]-ORG_CONTACTS, %o", event);
        self.onContactUpdate(event);
      });

    self._subs.sink = self
      .channelEvent$(self.ORG_PAYMENT_METHOD)
      .subscribe((event) => {
        console.log("[orgHub]-ORG_PAYMENT_METHOD, %o", event);
        self.onPaymentMethodUpdate(event);
      });

    self._subs.sink = self
      .channelEvent$(self.ORG_MEMBERSHIP_REQ)
      .subscribe((event) => {
        console.log("[orgHub]-ORG_MEMBERSHIP_REQ, %o", event);
        self.onMembershipReqUpdate(event);
      });

    self._subs.sink = self.channelEvent$(self.UNREADS).subscribe((event) => {
      self.onUnreadsEventReceived(event);
    });

    self._subs.sink = self.channelEvent$(self.SUB_MAIN).subscribe((event) => {
      console.log("[orgHub]-SUB_MAIN, %o", event);
      self.onSubscriptionUpdate(event);
    });
    self._subs.sink = self.channelEvent$(self.EXT_TOOLS).subscribe((event) => {
      self.onExtToolsEventReceived(event);
    })
  }

  // handshake(orgId: string = null) {
  //   this.updateHandshakeStatus.emit(HubHandshakeStatus.Pending);
  //   //passing null orgId will get personal org data from server
  //   return this.invoke(this.HANDSHAKE_BY_ORG_METHOD, orgId)
  //     .then((event: HubEvent) => {
  //       if (!event || !event.isSuccess) {
  //         this.updateHandshakeStatus.emit(HubHandshakeStatus.Failed);
  //         throw new Error(event.error);
  //       }
  //       console.log("[OrgHub] success handshake, %o", event);
  //       this.updateEnterpriseStates(event.dto, orgId);
  //       this.updateHandshakeStatus.emit(HubHandshakeStatus.Completed);
  //       return Promise.resolve();
  //     })
  //     .catch(err => {
  //       console.error(err);
  //       return Promise.reject(err);
  //     });
  // }

  // private getOrgs(): Promise<void> {
  //   return this.invoke(this.GET_ORGS_METHOD)
  //     .then((event: HubEvent) => {
  //       if (!event || !event.isSuccess) throw new Error(event.error);
  //       if (!event.dto)
  //         throw new Error(
  //           "Error getting event dto from " + this.GET_ORGS_METHOD
  //         );

  //       var promises: Promise<void>[] = [];
  //       console.log("[OrgHub] success getOrgs, %o", event);
  //       let orgs = this.parseList<OrgState>(event.dto, this.parseOrgState);
  //       if (orgs && orgs.length > 0) {
  //         console.log("[OrgHub] update orgs");
  //         promises.push(this.addOrUpdateOrgs.emit(orgs).toPromise());
  //       }

  //       //update org users for current member
  //       var users = event.dto.map((o) => o.membership).filter((m) => !!m);
  //       if (users) {
  //         let orgUsers = this.parseList<OrgUserState>(
  //           users,
  //           this.parseOrgUserState
  //         );
  //         if (orgUsers && orgUsers.length > 0) {
  //           console.log("[OrgHub] update users");
  //           promises.push(this.addOrUpdateOrgUser.emit(orgUsers).toPromise());
  //         }
  //       }

  //       return Promise.all(promises);
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       return err;
  //     });
  // }

  async updateGetOrgs(data: any) {
    let orgUsers: OrgUserState[] = [];
    let orgs = this.parseList<OrgState>(data, this.parseOrgState.bind(this));
    var subs = data.filter(o => o.subscription).map(x => x.subscription);
    if (subs) {
      subs = this.parseList<SubscriptionState>(
        subs,
        this.parseSubscriptionState.bind(this)
      )

      try {
        var sub = subs.find(
          (s) => s.orgId == this.enterpriseSelector.getCurrentOrgId()
        );
        if (sub.status == SubStatus.PaymentFailed) {
          var orgUser = this.enterpriseSelector.getCurrentOrgUser();
          if (orgUser && orgUser.role != RoleEntity[RoleEntity.OWNER])
            this.pubSub.next(PubSub.ON_USER_SUSPENDED, "usersuspended");
        }
      } catch (exp) { }
    }

    var features = subs
      .filter((s) => s.features && s.features.length != 0)
      .map((x) => x.features)
      .reduce((a, b) => a.concat(b), []);

    if (features) {
      features = this.parseList<FeatureState>(features, this.parseFeatureState.bind(this));
    }

    var users = data.map((o) => o.membership).filter((m) => !!m);
    if (users) {
      orgUsers = this.parseList<OrgUserState>(
        users,
        this.parseOrgUserState.bind(this)
      );
    }

    //check before update state
    //handle deleted org user or deleted org or ouuser changed
    const selectedOrgState = this.enterpriseSelector.getCurrentOrg();
    if (selectedOrgState) {
      const orgListBeforeUpdate = this.enterpriseSelector.getOrgs();
      var updatedCurrentOrg = orgListBeforeUpdate.find(
        (o) => o.id === this._currentOrg.id
      );

      //remove deleted org from org list
      const deletedOrgs = _.differenceBy(orgListBeforeUpdate, orgs, "id");
      this.deleteOrgs.emit(deletedOrgs);

      if (updatedCurrentOrg == null) {
        //TODO
        // this.userDeletedHandler();
      } else {
        const orgUserBeforeUpdate = this.enterpriseSelector.getCurrentOrgUser();
        //check prev org user and current org user for role or ou change
        if (orgUserBeforeUpdate) {
          const updatedOrgUser = orgUsers.find(
            (u) =>
              u.userId == orgUserBeforeUpdate.userId &&
              u.orgId == orgUserBeforeUpdate.orgId
          );

          if (!updatedOrgUser) {
            // this.userDeletedHandler();
          } else {
            if (orgUserBeforeUpdate.ouId !== updatedOrgUser.ouId) {
              // this.ouChangeHandler();
            } else if (orgUserBeforeUpdate.role !== updatedOrgUser.role) {
              // this.roleChangeHandler();
            }
          }
        }
      }
    }

    await this.addOrUpdateOrgs.emit(orgs).toPromise();
    await this.addOrUpdateOrgUser.emit(orgUsers).toPromise();
    await this.addOrUpdateSubscriptions.emit(subs).toPromise();
    await this.addOrUpdateFeatures.emit(features).toPromise();
  }

  handshakeMethod(orgId: string = null, orgRole: string = null): Promise<string> {
    return this.invoke(this.HANDSHAKE_BY_ORG_METHOD, orgId, orgRole).then((event) => {
      if (!event || !event.isSuccess) {
        this.updateHandshakeStatus.emit(HubHandshakeStatus.Failed);
        throw new Error(event.error);
      }

      return event.dto;
    }).catch((err) => {
      return Promise.reject(err);
    });
  }

  // private prepareSelectedOrg(): Promise<boolean> {
  //   return this.getOrgs()
  //     .then(() => {
  //       return this.preselectOrg();
  //     })
  //     .catch(() => {
  //       return Promise.resolve(true);
  //     });
  // }

  // //return true if preselect org, false if use back current org id
  // private preselectOrg(): Promise<boolean> {
  //   var orgs = this.enterpriseSelector.getOrgs();
  //   //current org is null, select org from list of orgs
  //   if (
  //     orgs &&
  //     orgs.length != 0 &&
  //     (!this._currentOrg ||
  //       this.enterpriseSelector.getCurrentOrgUser().status ===
  //       UserStatus.Deleted)
  //   ) {
  //     try {
  //       let activeOrgs = this.getActiveOrgs(orgs);
  //       // get last login org
  //       var lastLoginId = this.historySelector.userLastLogin;
  //       console.log(lastLoginId);
  //       //select last login org as default
  //       var defaultOrg = activeOrgs.find((o) => o.id == lastLoginId);
  //       if (!defaultOrg) {
  //         //select first business org as default
  //         defaultOrg = activeOrgs.filter((o) => o.type == OrgType.Business)[0];
  //       }
  //       if (!defaultOrg) {
  //         //select personal org as final default
  //         defaultOrg = orgs.find((o) => o.type == OrgType.Personal);
  //       }
  //       if (defaultOrg) {
  //         return this.switchOrg.emit(defaultOrg.id).toPromise()
  //           .then(() => {
  //             return true;
  //           });
  //       } else {
  //         return this.switchOrg.emit(activeOrgs[0].id).toPromise().then(() => {
  //           return true;
  //         });
  //       }
  //     } catch (ex) {
  //       pocolog.fatal(ex);
  //       return this.switchOrg.emit(orgs[0].id).toPromise().then(() => {
  //         return true;
  //       });
  //     }
  //   } else {
  //     return Promise.resolve(false);
  //   }
  // }

  // private getActiveOrgs(orgs: OrgState[]): OrgState[] {
  //   const orgUsers: OrgUserState[] = this.enterpriseSelector.getOrgUsers();
  //   const userId = this.userDataSelector.userId;
  //   return orgs.filter((o) => {
  //     if (o.status == EntityStatus.Inactive) return false;
  //     //filter active membership(not suspended or deleted)
  //     var orgUser = orgUsers.find(
  //       (i) => i.orgId === o.id && i.userId === userId
  //     );
  //     return orgUser && orgUser.status == UserStatus.Active;
  //   });
  // }

  updateHandshakeData(data: any) {
    // if (data.orgs) {
    //   let orgs = this.parseList<OrgState>(data.orgs, this.parseOrgState);
    //   if (orgs && orgs.length > 0) {
    //     console.log("[OrgHub] update orgs");
    //     this.addOrUpdateOrgs.emit(orgs);
    //   }
    // }
    if (data == null) return null;
    var org: OrgState;

    if (data.org) {
      org = this.parseOrgState(data.org);
      if (org) {
        console.log(`[OrgHub] update org ${org.id}`);
        this.addOrUpdateOrgs.emit([org]);
      }
    }

    if (data.users) {
      let users = this.parseList<OrgUserState>(
        data.users,
        this.parseOrgUserState.bind(this)
      );

      // // check if user's org role has changed while disconnected from hub.
      // let currentOrgUser = this.enterpriseSelector.getCurrentOrgUser();
      // let userId = this.userDataSelector.userId;
      // if (currentOrgUser && userId) {
      //   let newOrgUser = users.find(u => u.orgId == currentOrgUser.orgId && u.userId == userId); // use currentOrgUser.orgId instead? 
      //   if (newOrgUser && currentOrgUser.role !== newOrgUser.role) {
      //     console.log("[OrgHubFlow]user's org role changed, force logout");
      //     // if so, log the user out.
      //   this.pubSub.next(PubSub.FORCE_LOGOUT, "rolechanged");
      //   }
      // }

      if (users && users.length > 0) {
        console.log(`[OrgHub] update users ${users.length}`);
        this.replaceOrgUsers.emit(users);
      }
    }

    if (data.ous) {
      let ous = this.parseList<OUState>(data.ous, this.parseOuState.bind(this));
      if (ous && ous.length > 0) {
        console.log(`[OrgHub] update ous ${ous.length}`);
        this.addOrUpdateOUs.emit(ous);
      }
    }

    if (data.roles) {
      let roles = this.parseList<OrgRoleState>(data.roles, this.parseRoleState.bind(this));
      if (roles && roles.length > 0) {
        console.log("[OrgHub] update roles");
        this.addOrUpdateRoles.emit(roles);
      }
    }

    if (data.permissions) {
      let permissions = this.parseList<OrgPermissionState>(
        data.permissions,
        this.parsePermissionState.bind(this)
      );
      if (permissions && permissions.length > 0) {
        console.log("[OrgHub] update permissions");
        this.addOrUpdatePermissions.emit(permissions);
      }
    }

    if (data.builtInUsers) {
      let builtInUsers = this.parseSet(BuiltInUserState, data.builtInUsers);
      // let builtInUsers = this.parseList<BuiltInUserState>(
      //   data.builtInUsers,
      //   this.parseBuiltInUser
      // );
      if (builtInUsers && builtInUsers.length > 0) {
        console.log("[OrgHub] update builtInUsers");
        this.addOrUpdateBuiltInUser.emit(builtInUsers);
      }
    }

    if (data.membershipReqs) {
      let membershipReqs = this.parseSet(MembershipReqState, data.membershipReqs);
      if (membershipReqs) {
        console.log("[OrgHub] update membershipReqs");
        this.addOrUpdateMembershipReq.emit(membershipReqs);
      }
    }

    if (data.unreads) {
      let allUnreads = this.parseUnreadsDto(data.unreads);
      if (allUnreads && allUnreads.length > 0) {
        let enterpriseUnreads = allUnreads.filter(u => u.isChUnread || u.isPostUnread);
        if (enterpriseUnreads && enterpriseUnreads.length > 0) {
          this.addEnterpriseUnreads.emit(enterpriseUnreads);
        }
        let msgUnreads = allUnreads.filter(u => u.isRoomUnread);
        if (msgUnreads && msgUnreads.length > 0) {
          this.addMsgUnreads.emit(msgUnreads);
        }
      }
    }

    return org;
  }

  //#region Org
  private onOrgUpdate(event: HubEvent) {
    if (!event) return;

    if (event.name == this.UPDATE_FEATURES) {
      const features = this.parseList<FeatureState>(event.dto, this.parseFeatureState.bind(this));
      this.addOrUpdateFeatures.emit(features);
      return;
    } else if (event.name == this.DELETE_FEATURES) {
      const features = this.parseList<FeatureState>(
        event.dto,
        this.parseFeatureState.bind(this)
      );
      this.deleteFeatures.emit(features.map((f) => f.id));

      return;
    }
    // else if (event.name == this.NEW_FEATURE_USAGES) {
    //   const usages = this.parseList<FeatureDailyUsageState>(event.dto, this.parseFeatureDailyUsage);
    //   this.addUsages.emit(usages);
    //   return;
    // }

    const org = this.parseOrgState(event.dto);
    if (event.name === this.UPDATE_ORG || event.name === this.NEW_ORG) {
      this.addOrUpdateOrgs.emit([org]);
    } else if (event.name === this.DELETE_ORG) {
      this.deleteOrgState.emit(org.id);
      if (org && this._currentOrg && org.id == this._currentOrg.id) {
        this.pubSub.next(PubSub.ON_CURRENT_ORG_DELETED);
      }
    } else {
      console.error(
        "[OrgHub]-onOrgUpdate, event: %s not supported",
        event.name
      );
    }
  }

  deleteOrg(orgId: string) {
    return this.invoke$(this.ORG_DELETE, orgId).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOrgState(event.dto);
      }),
      tap((org: OrgState) => this.deleteOrgState.emit(org.id))
    );
  }

  updateOrg(state: OrgState) {
    let dto = {
      id: state.id,
      name: state.name,
      email: state.email,
      phoneNumber: state.phoneNumber,
      mailingAddress: state.mailingAddress,
      city: state.city,
      state: state.state,
      country: state.country,
      postalCode: state.postalCode,
      fax: state.fax,
      website: state.website,
      autoJoinEnabled: state.autoJoinEnabled,
      retentionPeriod: state.retentionPeriod,
      editMsgEnabled: state.editMsgEnabled,
      deleteMsgEnabled: state.deleteMsgEnabled,
      myDriveEnabled: state.myDriveEnabled,
    };
    return this.invoke$(this.ORG_UPDATE, dto).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOrgState(event.dto);
      }),
      tap((org: OrgState) => this.addOrUpdateOrgs.emit([org]))
    );
  }

  updateOrgEditMsgSetting(orgId: string, enable: boolean) {
    return this.invoke$(this.ORG_UPDATE_EDIT_MSG, orgId, enable).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOrgState(event.dto);
      }),
      tap((org: OrgState) => this.addOrUpdateOrgs.emit([org]))
    );
  }

  updateOrgDeleteMsgSetting(orgId: string, enable: boolean) {
    return this.invoke$(this.ORG_UPDATE_DELETE_MSG, orgId, enable).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOrgState(event.dto);
      }),
      tap((org: OrgState) => this.addOrUpdateOrgs.emit([org]))
    );
  }

  updateOrgAutoJoinSetting(orgId: string, enable: boolean) {
    return this.invoke$(this.ORG_UPDATE_AUTO_JOIN, orgId, enable).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOrgState(event.dto);
      }),
      tap((org: OrgState) => this.addOrUpdateOrgs.emit([org]))
    );
  }

  updateOrgMyDriveSetting(orgId: string, enable: boolean, groupName: string) {
    return this.invoke$(this.ORG_UPDATE_MYDRIVE, orgId, enable, groupName).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOrgState(event.dto);
      }),
      tap((org: OrgState) => this.addOrUpdateOrgs.emit([org]))
    );
  }

  updateOrgRetentionSetting(orgId: string, retentionPeriod: string) {
    return this.invoke$(this.ORG_UPDATE_RETENTION, orgId, retentionPeriod).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOrgState(event.dto);
      }),
      tap((org: OrgState) => this.addOrUpdateOrgs.emit([org]))
    );
  }

  createOrg(
    name: string,
    code: string,
    industryId: string,
    street: string,
    city: string,
    zip: string,
    state: string,
    country: string,
    subType: SubType,
    paymentToken?: string,
    couponCode?: string
  ) {
    var timezone = moment.tz.guess();
    let dto = {
      name: name,
      code: code,
      industryId: industryId,
      mailingAddress: street,
      city: city,
      postalCode: zip,
      state: state,
      country: country
    };

    return this.invoke$(
      this.ORG_CREATE,
      dto,
      subType,
      paymentToken,
      timezone,
      couponCode
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        console.log("[OrgHub] created org %o", event.dto.org, event.dto.users, event.dto.subscription)
        var orgUsers = this.parseList<OrgUserState>(
          event.dto.users,
          this.parseOrgUserState.bind(this)
        );
        this.addOrUpdateOrgUser.emit(orgUsers);
        var sub = this.parseSubscriptionState(event.dto.subscription);

        // don't update if subscription exists. Subscription already exist because Subscription Service finished processing before EL Service. Subscription Service is the updated one
        var existing = this.licenseSelector.getCurrentOrgSubscription();
        if (!existing) this.addOrUpdateSubscriptions.emit([sub]);
        else console.log("[OrgHub] Sub exists, ignoring update %o", sub);

        return this.parseOrgState(event.dto.org);
      }),
      tap((org: OrgState) => this.addOrUpdateOrgs.emit([org]))
    );
  }

  updateSubPendingPayment(orgId: string, paymentToken: string) {
    return this.invoke$(
      this.SUB_PENDING_UPDATE_PAYMENT,
      orgId,
      paymentToken
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOrgState(event.dto);
      }),
      tap((org: OrgState) => this.addOrUpdateOrgs.emit([org]))
    );
  }

  subChange(orgId: string, newSubType: SubType, paymentToken: string) {
    return this.invoke$(
      this.SUB_CHANGE,
      orgId,
      newSubType,
      paymentToken
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOrgState(event.dto);
      }),
      tap((org: OrgState) => this.addOrUpdateOrgs.emit([org]))
    );
  }

  // payg
  subUpgrade(orgId: string, paymentToken: string, couponCode?: string) {
    return this.invoke$(
      this.UPGRADE_SUB,
      orgId,
      paymentToken,
      couponCode
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseSubscriptionState(event.dto);
      }),
      tap((sub: SubscriptionState) => this.addOrUpdateSubscriptions.emit([sub]))
    );
  }

  subUpdateFailedPayment(orgId: string, paymentToken: string) {
    return this.invoke$(
      this.UPDATE_FAILED_PAYMENT_SUB,
      orgId,
      paymentToken
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseSubscriptionState(event.dto);
      }),
      tap((sub: SubscriptionState) => this.addOrUpdateSubscriptions.emit([sub]))
    );
  }

  getFeatures(orgId: string) {
    return this.invoke$(
      this.GET_FEATURES,
      orgId,
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseList<FeatureState>(event.dto, this.parseFeatureState.bind(this));
      }),
      tap((features: FeatureState[]) => this.addOrUpdateFeatures.emit(features))
    );
  }

  async getFeature(orgId: string, resourceCode: string) {
    var feature = this.licenseSelector.getFeatureByCode(resourceCode);
    if (feature == null) {
      await this.getFeatureFromServer(this.enterpriseSelector.getCurrentOrgId(), resourceCode)
        .toPromise();
      feature = this.licenseSelector.getFeatureByCode(resourceCode);
    }

    return feature;
  }
  
  getFeatureFromServer(orgId: string, resourceCode: string) {
    return this.invoke$(
      this.GET_FEATURE,
      orgId,
      resourceCode
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseFeatureState(event.dto);
      }),
      tap((features: FeatureState) => this.addOrUpdateFeatures.emit([features]))
    );
  }
  //#endregion

  //#region OU

  private onOuUpdate(event: HubEvent) {
    const ou = this.parseOuState(event.dto);
    if (event.name == this.NEW_OU || event.name == this.UPDATE_OU) {
      this.addOrUpdateOUs.emit([ou]);
    } else if (event.name == this.DELETE_OU) {
      this.deleteOUState.emit(ou);
    } else {
      console.error("[OrgHub]-onOuUpdate INVALID EVENT: %s", event.name);
    }
  }

  addOU(orgId: string, parentId: string, name: string) {
    return this.invoke$(this.OU_CREATE, orgId, parentId, name).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOuState(event.dto);
      }),
      tap((ou: OUState) => this.addOrUpdateOUs.emit([ou]))
    );
  }

  updateOU(orgId: string, ouId: string, name: string) {
    return this.invoke$(this.OU_UPDATE, orgId, ouId, name).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOuState(event.dto);
      }),
      tap((ou: OUState) => this.addOrUpdateOUs.emit([ou]))
    );
  }

  deleteOU(orgId: string, ouId: string) {
    return this.invoke$(this.OU_DELETE, orgId, ouId).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOuState(event.dto);
      }),
      tap((ou: OUState) => this.deleteOUState.emit(ou))
    );
  }

  //#endregion

  //#region Org User
  private onOrgUserUpdate(event: HubEvent) {
    console.log("[orgHub] onOrgUserUpdate %o", event);
    if (event.name === this.ASSIGN_CLIENTS_TO_STAFF ||
      event.name === this.ASSIGN_STAFFS_TO_CLIENT ||
      event.name === this.REMOVE_CLIENTS_FROM_STAFF ||
      event.name === this.REMOVE_STAFFS_FROM_CLIENT) {

      const dto = this.parseList<OrgUserState>(
        event.dto,
        this.parseOrgUserState.bind(this)
      );
      this.addOrUpdateOrgUser.emit(dto);
      this.pubSub.next(PubSub.GET_CONTACTS);
    } else if (event.name === this.DELETE_USER) {
      this.pubSub.next(PubSub.FORCE_LOGOUT, "userdeleted");
    }else {
      const dto = this.parseOrgUserState(event.dto);
      this.addOrUpdateOrgUser.emit([dto]);

      switch (event.name) {
        case this.UPDATE_ORGMEMBER_STATUS:
          {
            if (
              dto.status === UserStatus.Deleted &&
              dto.userId == this.userDataSelector.userId
            ) {
              this.pubSub.next(PubSub.FORCE_LOGOUT, "orguserdeleted");
            }
            else if (
              this._currentOrg.id === dto.orgId &&
              this.userDataSelector.userId === dto.userId &&
              (dto.status === UserStatus.Suspended || dto.status === UserStatus.SubscriptionLimitExceed)
            ) {
              // handle if user is suspended while actively using org
              this.pubSub.next(PubSub.ON_USER_SUSPENDED, "usersuspended");
            }
          }
          break;
        case this.CHANGE_ORGMEMBER_ROLE:
          {
            if (dto.userId == this.userDataSelector.userId) {
              this.pubSub.next(PubSub.FORCE_LOGOUT, "rolechanged");
            }
          }
          break;
        case this.MOVE_ORGMEMBER_OU:
          {
            if (dto.userId == this.userDataSelector.userId) {
              this.pubSub.next(PubSub.FORCE_LOGOUT, "ouchanged");
            }
          }
          break;
      }

      //if org user change role/ou/status, get contacts again
      if (
        dto.userId != this.userDataSelector.userId &&
        this._currentOrg.id == dto.orgId
      ) {
        var user = this.enterpriseSelector.getOrgMembership(
          dto.orgId,
          dto.userId
        );

        if (!user) return;
        if (
          user.ouId != dto.ouId ||
          user.role != dto.role ||
          user.status != dto.status
        ) {
          this.pubSub.next(PubSub.GET_CONTACTS);
        }
      }
    }
  }

  addUserToPersonalOrg(userId: string) {
    return this.invoke$(
      this.ADD_USER_TO_PERSONAL_METHOD,
      this._currentOrg.id,
      userId
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOrgUserState(event.dto);
      }),
      tap((orgUser: OrgUserState) => this.addOrUpdateOrgUser.emit([orgUser]))
    );
  }

  addUser(orgId: string, ouId: string, contactId: string, roleId: string) {
    return this.invoke$(
      this.ADD_USER_BY_CONTACT_MEHTOD,
      orgId,
      ouId,
      contactId,
      roleId
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOrgUserState(event.dto);
      }),
      tap((orgUser: OrgUserState) => this.addOrUpdateOrgUser.emit([orgUser]))
    );
    //return this.api.postAsync('v2/ou/' + ouId + '/user/' + userId, JSON.stringify(roleId), this.localDb.CurrentOU.id);
    // return this.api.postAsync('v2/ou/' + ouId + '/invite/contact/' + contactId, JSON.stringify(roleId),this.enterpriseSnapshot.getCurrentOrgId());
  }

  moveUser(fromOuId: string, toOuId: string, userId: string, roleId: string) {
    return this.invoke$(
      this.ORGUSER_MOVE,
      fromOuId,
      toOuId,
      userId,
      roleId
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOrgUserState(event.dto);
      }),
      tap((state: OrgUserState) => this.addOrUpdateOrgUser.emit([state]))
    );
  }

  changeUserRole(ouId: string, userId: string, roleId: string, roleTypeId: string) {
    return this.invoke$(this.ORGUSER_ROLE_CHANGE, ouId, userId, roleId, roleTypeId).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOrgUserState(event.dto);
      }),
      tap((state: OrgUserState) => this.addOrUpdateOrgUser.emit([state]))
    );
  }

  promoteAdmin(ouId: string, userId: string) {
    return this.invoke$(this.ORGUSER_PROMOTE_ADMIN, ouId, userId).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOrgUserState(event.dto);
      }),
      tap((state: OrgUserState) => this.addOrUpdateOrgUser.emit([state]))
    );
  }

  demoteAdmin(ouId: string, userId: string) {
    return this.invoke$(this.ORGUSER_DEMOTE_ADMIN, ouId, userId).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOrgUserState(event.dto);
      }),
      tap((state: OrgUserState) => this.addOrUpdateOrgUser.emit([state]))
    );
  }

  suspendOrgUsers(ouId: string, userIds: string[]) {
    return this.invoke$(this.ORGUSER_SUSPEND, ouId, userIds).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseList<OrgUserState>(event.dto, this.parseOrgUserState.bind(this));
      }),
      tap((states: OrgUserState[]) => this.addOrUpdateOrgUser.emit([...states]))
    );
  }

  reactivateOrgUsers(ouId: string, userIds: string[]) {
    return this.invoke$(this.ORGUSER_REACTIVATE, ouId, userIds).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseList<OrgUserState>(event.dto, this.parseOrgUserState.bind(this));
      }),
      tap((states: OrgUserState[]) => this.addOrUpdateOrgUser.emit([...states]))
    );
  }

  deleteOrgUsers(ouId: string, userId: string) {
    return this.invoke$(this.ORGUSER_DELETE, ouId, userId).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOrgUserState(event.dto);
      }),
      tap((state: OrgUserState) => this.addOrUpdateOrgUser.emit([state]))
    );
  }

  restoreOrgUsers(ouId: string, userId: string) {
    return this.invoke$(this.ORGUSER_RESTORE, ouId, userId).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOrgUserState(event.dto);
      }),
      tap((state: OrgUserState) => this.addOrUpdateOrgUser.emit([state]))
    );
  }

  getUserPublicProfile(userId: string) {
    if (!userId) return null;

    return this.invoke(this.GET_USER_PUBLIC_PROFILE, userId)
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return event.dto;
      })
      .catch((err) => {
        pocolog.error(err);
      });
  }

  getUserProfile(comment: PostCommentState) {
    var userId = comment.createdBy;
    const existing = this._userPublicProfiles[userId];
    if (existing) return existing;

    if (
      Object.keys(this._userPublicProfiles).findIndex((p) => p === userId) ===
      -1
    ) {
      // this._userPublicProfiles[userId] = null;
      this.getUserPublicProfile(userId).then((res) => {
        this._userPublicProfiles[userId] = res;
        const temp = { ...comment };
        temp.senderAvatar = res.imageUrl;
        temp.senderName = res.name;
        this.addOrUpdateComments.emit([temp]);
      });
    }
  }
  //#endregion

  //#region Contact
  private onContactUpdate(event: HubEvent) {
    if (event.name == this.DELETE_CONTACT) {
      const contact = this.parseContactState(event.dto);
      this.removeContact.emit(contact);
      // this.deleteContact.emit(contact.id);
    } else if (event.name == this.DELETE_CONTACTS) {
      const contacts = this.parseList<ContactState>(event.dto, this.parseContactState.bind(this));
      this.deleteContacts.emit([...contacts]);
    } else if (event.name == this.UPDATE_CONTACTS ||
      event.name == this.NEW_CONTACTS) {
      const contacts = this.parseList<ContactState>(event.dto, this.parseContactState.bind(this));
      this.addOrUpdateContacts.emit([...contacts]);
    }
    else {
      const contact = this.parseContactState(event.dto);
      this.addOrUpdateContacts.emit([contact]);
    }
  }

  addOfflineContact(contact: ContactState) {
    return this.invoke$(
      this.ADD_OFFLINE_CONTACT_METHOD,
      contact.orgId,
      contact.firstName,
      contact.lastName,
      contact.position,
      contact.orgName,
      contact.addresses,
      contact.phones,
      contact.emails,
      contact.note
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseContactState(event.dto);
      }),
      tap((contact: ContactState) => {
        this.addOrUpdateContacts.emit([contact]);
      })
    );
    // return this.api.postAsync(
    //   "v2/contact/offline",
    //   JSON.stringify(result),
    //   this.enterpriseSnapshot.getCurrentOrgId()
    // );
  }

  updateContact(contact: ContactState) {
    return this.invoke$(
      this.UPDATE_CONTACT_METHOD,
      contact.orgId,
      contact.id,
      contact.firstName,
      contact.lastName,
      contact.position,
      contact.orgName,
      contact.addresses,
      contact.phones,
      contact.emails,
      contact.note
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseContactState(event.dto);
      }),
      tap((contact: ContactState) => this.addOrUpdateContacts.emit([contact]))
    );
    // return this.api.putAsync(
    //   "v2/contact/offline",
    //   JSON.stringify(result),
    //   contact.contactId
    // );
  }

  deleteOfflineContact(contact: ContactState) {
    return this.invoke$(
      this.DELETE_CONTACT_METHOD,
      contact.orgId,
      contact.id
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseContactState(event.dto);
      }),
      tap((c: ContactState) => this.removeContact.emit(c))
    );
    // return this.api.deleteAsync(
    //   "v2/contact/offline/" + contact.contactId,
    //   {},
    //   contact.contactId
    // );
  }

  getContact(contactId: string) {
    return this.invoke$(this.GET_CONTACT_METHOD, contactId).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseContactState(event.dto);
      })
    );
  }

  createContactForClient(
    ouId: string,
    clientId: string,
    firstName: string,
    lastName: string,
    position: string = null,
    orgName: string = null,
    addresses: ContactAddressState[] = [],
    phones: ContactPhoneState[] = [],
    emails: ContactEmailState[] = [],
    note: string = null
  ) {
    return this.invoke$(
      this.CONTACT_CLIENT_CREATE,
      ouId,
      clientId,
      firstName,
      lastName,
      position,
      orgName,
      addresses,
      phones,
      emails,
      note
    ).pipe(
      map(event => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseContactState(event.dto);
      }),
      tap((contact: ContactState) =>
        this.addOrUpdateContacts.emit([contact])
      )
    );
    //      return this.api.postAsync("v2/contact/" + contactId + "/circle", JSON.stringify(labelId), contactId)
  }

  // getContactsOnDemand() {
  //   return this.invoke(
  //     this.CONTACTS_GET_METHOD,
  //     this._currentOrg.id
  //   ).then(event => {
  //     if (!event || !event.isSuccess) throw new Error(event.error);
  //     return this.parseList<ContactState>(event.dto, this.parseContactState.bind(this));
  //   }).then(async (contacts) => {
  //     var currentContacts = this.enterpriseSelector.getContacts();
  //     const deletedContacts = _.differenceBy(currentContacts, contacts, "id");
  //     if (deletedContacts.length > 0) {
  //       await this.deleteContacts.emit(deletedContacts).toPromise();
  //     }

  //     if (contacts && contacts.length > 0) {
  //       this.addOrUpdateContacts.emit(contacts);
  //     }
  //   });
  // }

  getContactsMethod(orgId: string): Promise<ContactState[]> {
    if (!orgId) return;
    return this.invoke(this.CONTACTS_GET_METHOD, orgId)
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseList<ContactState>(event.dto, this.parseContactState.bind(this));
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getMutualFriend(userId: string): Promise<OrgUserState[]> {
    if (!userId) return;
    return this.invoke(this.MUTUAL_FRIENDS_GET_METHOD, userId)
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseList<OrgUserState>(event.dto, this.parseOrgUserState.bind(this));
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  getUserInfo(userId: string): Promise<OrgUserState> {
    if (!userId) return;
    return this.invoke(this.USER_GET_METHOD, userId)
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseOrgUserState(event.dto);
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }



  //#endregion

  //#region Teams
  private onTeamUpdate(event: HubEvent) {
    if (
      event.name == this.NEW_TEAM_MEMBERS
    ) {
      const teamMembers = this.parseList<TeamMemberState>(
        event.dto,
        this.parseTeamMemberState.bind(this)
      );
      this.addOrUpdateTeamMembers.emit(teamMembers);
    } else if (
      event.name == this.CHANGE_TEAM_ROLE
    ) {
      const teamMember = this.parseTeamMemberState(event.dto);
      if (!teamMember) return;
      this.addOrUpdateTeamMembers.emit([teamMember]);
    } else if (
      event.name == this.DELETE_TEAM_MEMBERS
    ) {
      const teamMembers = this.parseList<TeamMemberState>(
        event.dto,
        this.parseTeamMemberState.bind(this)
      );
      if (!teamMembers || teamMembers.length == 0) return;
      this.deleteTeamMembers.emit(teamMembers);
      this.refreshTeamContacts(teamMembers[0].orgId, teamMembers[0].teamId);
    } else if (
      event.name == this.NEW_CHANNEL ||
      event.name == this.UPDATE_CHANNEL
    ) {
      const channel = this.parseChannelState(event.dto);
      this.addOrUpdateChannels.emit([channel]);
    } else if (event.name == this.NEW_CHANNELS) {
      const channels = this.parseList<ChannelState>(
        event.dto,
        this.parseChannelState.bind(this)
      );
      this.addOrUpdateChannels.emit(channels);
    } else if (
      event.name == this.DELETE_CHANNEL
    ) {
      const channel = this.parseChannelState(event.dto);
      this.removeChannel.emit(channel);
    } else if (event.name == this.DELETE_TEAM) {
      const team = this.parseTeamState(event.dto);
      this.removeTeam.emit(team);
      if (team.channels.includes(this._activeChannelId)) {
        const dialogRef = this.dialog.open(DialogConfirmComponent, {
          width: "400px",
          data: {
            title: "Channel removed",
            content: "The channel has been removed or you have been removed from the team. ",
          },
        });
        this.router.navigate(["/main/teams"]);
      }
      this.refreshTeamContacts(team.orgId, team.id, team);
    } else {
      const team = this.parseTeamState(event.dto);
      this.addOrUpdateTeams.emit([team]);
    }
  }

  private refreshTeamContacts(orgId: string, teamId: string, team: TeamState = null) {
    if (this._currentOrg.id != orgId) return;

    if (team == null) team = this.enterpriseSelector.getTeam(teamId);
    if (team && team.memberVisibility) this.pubSub.next(PubSub.GET_CONTACTS);
  }

  addTeam(
    orgId: string,
    ouId: string,
    name: string,
    type: number,
    members: string[] = [],
    memberVisibility: boolean = true,
    canCreateChannel: boolean = true,
    canCreatePost: boolean = true,
    canCreatePostComment: boolean = true,
    enableImplicitOwner: boolean = false,
    enableImplicitAdmin: boolean = false,
    enableImplicitSubOuMembers: boolean = false,
    enableImplicitCoworkers: boolean = false,
    enableImplicitClients: boolean = false,
    enableImplicitPartners: boolean = false,
    ownerId?: string
  ) {
    if (!members) members = [];

    var dto = {
      ownerId: ownerId,
      type: type,
      name: name,
      memberVisibility: memberVisibility,
      canCreateChannel: canCreateChannel,
      canCreatePost: canCreatePost,
      canCreatePostComment: canCreatePostComment,
      enabledImplicitOwner: enableImplicitOwner,
      enabledImplicitAdmins: enableImplicitAdmin,
      enabledImplicitPartners: enableImplicitPartners,
      enabledImplicitClients: enableImplicitClients,
      enabledImplicitCoWorkers: enableImplicitCoworkers,
      enabledImplicitSubOuMembers: enableImplicitSubOuMembers,
      members: members.map((m) => {
        return {
          memberId: m,
          role: 9,
          state: ChangeState.New,
        };
      }),
    };
    return this.invoke$(this.TEAM_CREATE, orgId, ouId, dto).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseTeamState(event.dto);
      }),
      tap((team: TeamState) => this.addOrUpdateTeams.emit([team]))
    );
  }

  updateTeamName(teamId: string, name: string) {
    return this.invoke(this.TEAM_UPDATE_NAME, teamId, name)
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        const team = this.parseTeamState(event.dto);
        this.addOrUpdateTeams.emit([team]);
        return team;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateTeamMemberVisibility(teamId: string, allowMemberVisibility: boolean) {
    return this.invoke(this.TEAM_UPDATE_MEM_VISIBILITY, teamId, allowMemberVisibility)
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        const team = this.parseTeamState(event.dto);
        this.addOrUpdateTeams.emit([team]);
        return team;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateTeamCanCreateChannel(teamId: string, allowCreateChannel: boolean) {
    return this.invoke(this.TEAM_UPDATE_CREATE_CHANNEL, teamId, allowCreateChannel)
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        const team = this.parseTeamState(event.dto);
        this.addOrUpdateTeams.emit([team]);
        return team;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateTeamCanCreatePost(teamId: string, allowCreatePost: boolean) {
    return this.invoke(this.TEAM_UPDATE_CREATE_POST, teamId, allowCreatePost)
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        const team = this.parseTeamState(event.dto);
        this.addOrUpdateTeams.emit([team]);
        return team;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateTeamImplicitMembers(
    teamId: string,
    enableImplicitOwner: boolean = false,
    enableImplicitAdmins: boolean = false,
    enableImplicitSubOUMembers: boolean = false,
    enableImplicitCoworkers: boolean = false,
    enableImplicitClients: boolean = false,
    enableImplicitPartners: boolean = false) {
    var dto = {
      teamId: teamId,
      enabledImplicitOwner: enableImplicitOwner,
      enabledImplicitAdmins: enableImplicitAdmins,
      enabledImplicitPartners: enableImplicitPartners,
      enabledImplicitClients: enableImplicitClients,
      enabledImplicitCoWorkers: enableImplicitCoworkers,
      enabledImplicitSubOuMembers: enableImplicitSubOUMembers
    };
    return this.invoke(this.TEAM_UPDATE_IMPLICIT, dto)
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        const team = this.parseTeamState(event.dto);
        this.addOrUpdateTeams.emit([team]);
        return team;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateTeamCanCreatePostComment(teamId: string, allowCreatePostComment: boolean) {
    return this.invoke(this.TEAM_UPDATE_CREATE_POST_COMMENT, teamId, allowCreatePostComment)
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        const team = this.parseTeamState(event.dto);
        this.addOrUpdateTeams.emit([team]);
        return team;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  addTeamMembers(
    teamId: string,
    members: { memberId: string; role?: number; state: ChangeState }[] = []) {
    var dto = { members: members };
    return this.invoke(this.TEAM_ADD_MEMBERS, teamId, dto)
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        const team = this.parseTeamState(event.dto);
        this.addOrUpdateTeams.emit([team]);
        return team;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateTeamMember(
    teamId: string,
    member: { memberId: string; role?: number; state: ChangeState }) {
    return this.invoke(this.TEAM_UPDATE_MEMBERS, teamId, member)
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        const teamMember = this.parseTeamMemberState(event.dto);
        this.addOrUpdateTeamMembers.emit([teamMember]);
        return teamMember;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  deleteMembersFromTeam(
    teamId: string,
    member: { memberId: string; role?: number; state: ChangeState }) {
    return this.invoke(this.TEAM_DELETE_MEMBERS, teamId, member)
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        const team = this.parseTeamState(event.dto);
        this.addOrUpdateTeams.emit([team]);
        return team;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }

  updateTeam(
    orgId: string,
    teamId: string,
    name: string,
    type: number,
    members: { memberId: string; role?: number; state: ChangeState }[] = [],
    memberVisibility: boolean = true,
    canCreateChannel: boolean = true,
    canCreatePost: boolean = true,
    canCreatePostComment: boolean = true,
    state: ChangeState = ChangeState.Unchanged
  ) {
    if (!members) members = [];
    var dto = {
      teamId: teamId,
      type: type,
      name: name,
      memberVisibility: memberVisibility,
      canCreateChannel: canCreateChannel,
      canCreatePost: canCreatePost,
      canCreatePostComment: canCreatePostComment,
      members: members,
      state: state
    };
    return this.invoke$(this.TEAM_UDPATE, orgId, dto).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseTeamState(event.dto);
      }),
      tap((team: TeamState) => this.addOrUpdateTeams.emit([team]))
    );
  }

  deleteTeam(orgId: string, teamId: string) {
    return this.invoke$(this.TEAM_DELETE, orgId, teamId).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseTeamState(event.dto);
      }),
      tap((team: TeamState) => this.removeTeam.emit(team))
    );
  }

  changeTeamOwner(teamId: string, userId: string) {
    return this.invoke$(this.TEAM_CHANGE_OWNER, teamId, userId).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);

        return this.parseTeamState(event.dto);
      }),
      tap((team: TeamState) => this.addOrUpdateTeams.emit([team]))
    );
  }

  getTeamsOnDemand() {
    return this.invoke(
      this.TEAM_GET_METHOD,
      this._currentOrg.id
    ).then(event => {
      if (!event || !event.isSuccess) throw new Error(event.error);
      return event.dto;
    }).then((res) => {
      if (res.teams) {
        let teams = this.parseList<TeamState>(res.teams, this.parseTeamState.bind(this));
        if (teams && teams.length > 0) {
          console.log("[OrgHub] update teams");
          this.addOrUpdateTeams.emit(teams);
        }
      }

      if (res.teamMembers) {
        let members = this.parseSet(TeamMemberState, res.teamMembers);

        if (members && members.length > 0) {
          console.log("[OrgHub] update team members");
          this.addOrUpdateTeamMembers.emit(members);
        }
      }

      if (res.channels) {
        let channels = this.parseList<ChannelState>(
          res.channels,
          this.parseChannelState.bind(this)
        );
        if (channels && channels.length > 0) {
          console.log("[OrgHub] update channels");
          this.addOrUpdateChannels.emit(channels);
        }
      }
    });
  }

  getOwnedTeams(userId: string): Promise<{ id: string, name: string }[]> {
    return this.invoke(
      this.TEAM_OWNED_GET_METHOD,
      this._currentOrg.id,
      userId
    ).then(event => {
      if (!event || !event.isSuccess) throw new Error(event.error);
      return event.dto;
    });
  }
  //#endregion

  //#region Channels


  // private onChannelUpdate(event: HubEvent) {
  //   if (event.name == this.NEW_CHANNEL || event.name == this.UPDATE_CHANNEL) {
  //     const channel = this.parseChannelState(event.dto);
  //     this.addOrUpdateChannels.emit([channel]);
  //   } else if (event.name == this.DELETE_CHANNEL) {
  //     const channel = this.parseChannelState(event.dto);
  //     this.removeChannel.emit(channel.id);
  //   }
  // }

  createChannel(teamId: string, name: string) {
    return this.invoke$(this.CHANNEL_CREATE, teamId, name).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseChannelState(event.dto);
      }),
      tap((ch: ChannelState) => this.addOrUpdateChannels.emit([ch]))
    );
  }

  updateChannel(channelId: string, name: string) {
    return this.invoke$(this.CHANNEL_UDPATE, channelId, name).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseChannelState(event.dto);
      }),
      tap((ch: ChannelState) => this.addOrUpdateChannels.emit([ch]))
    );
  }

  deleteChannel(channelId: string) {
    return this.invoke$(this.CHANNEL_DELETE, channelId).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseChannelState(event.dto);
      }),
      tap((ch: ChannelState) => this.removeChannel.emit(ch))
    );
  }

  //search content and filename in posts and comments for matched string
  //pass oldest matched results' createdOn date from last batch as date to get next batch
  //if date is empty, hub method returns latest results
  //each batch returns 10 results
  //sample returned obj, might be post or comment(with postId)
  //   {
  //     "id": "281adfc9-71e5-4d3b-a17f-b63c600eadc6",
  //     "postId": null,
  //     "channelId": "0723419e-815d-47d9-838b-39bed15b64de",
  //     "teamId": "46a3f87d-cf68-4805-8149-273945a738c9",
  //     "content": "test3",
  //     "createdOn": "2020-03-02T07:58:25.3966217",
  //     "createdBy": "35a9d6ad-5d15-49d9-bf53-614395295feb",
  //     "mediaName": null
  // }    
  searchChannel(searchString: string, date: Date = null) {
    return this.invoke$(
      this.CHANNEL_SEARCH_METHOD,
      this._currentOrg.id,
      searchString,
      date ? date : ""
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return event.dto;
      })
    );
  }
  //#endregion

  //#region Post
  private onPostUpdate(event: HubEvent) {
    if (
      event.name == this.NEW_POST_CHAT ||
      event.name == this.DELETE_POST_CHAT
    ) {
      const comment = this.parsePostCommentState(event.dto);
      if (comment.status == EntityStatus.Deleted) {
        this.deleteComment.emit(comment);
      } else {
        this.addOrUpdateComments.emit([comment]);
        //this.addCommentUnreads.emit([comment]);
      }
    } else {
      const post = this.parsePostState(event.dto);
      this.addOrUpdatePosts.emit([post]);
      //console.log("[onPostUpdate] active ch: %s, post ch: %s", this._activeChannelId, post.channelId);
      if (post.channelId !== this._activeChannelId) {
        //console.log("[onPostUpdate] trigger unread ch: %s, post ch: %s", this._activeChannelId, post.channelId);
        //this.addPostUnreads.emit([post]);
      } else if (this._currentOrg.type == OrgType.Personal && this._activeChannelId) {
        //console.log("[onPostUpdate] personal org");
        const idx = _.findIndex(this._currentOrg.connectedOrgs, c => c === post.orgId);
        // if (idx === -1) {
        //   this.addPostUnreads.emit([post]);
        // }
      }
    }
  }

  addTextPost(
    content: string,
    channelId: string,
    teamId: string
  ): Observable<PostState> {
    var post = new PostState();
    post.channelId = channelId;
    post.orgId = this._currentOrg.id;
    post.content = content??"";
    post.sendStatus = MessageSendStatus.PendingToSent;
    post.createdBy = this.userDataSelector.userId;
    post.teamId = teamId;
    post.type = PostType.Text;

    return this.addOrUpdatePosts.emit([post]);
  }

  private checkPostSendAttempt(post: PostState) {
    post.sendAttempt++;
    if (post.sendAttempt >= this._maxResendAttempt) {
      post.sendStatus = MessageSendStatus.Failed;
      this.addOrUpdatePosts.emit([post]);
    } else {
      setTimeout(() => {
        post.sendStatus = MessageSendStatus.PendingToSent;
        this.addOrUpdatePosts.emit([post]);
      }, 5000);
    }
  }

  private invokeSendTextPost(post: PostState) {
    post.sendStatus = MessageSendStatus.Sending;
    this.addOrUpdatePosts.emit([post]);

    return this.invoke$(
      this.POST_NEW_METHOD,
      post.orgId,
      post.content,
      post.channelId,
      post.tempId
    )
      .pipe(
        map((event) => {
          if (!event || !event.isSuccess) throw new Error(event.error);
          return this.parsePostState(event.dto);
        })
      )
      .subscribe(
        (sentPost) => {
          if (sentPost) {
            sentPost.sendStatus = MessageSendStatus.Sent;
            this.addOrUpdatePosts.emit([sentPost]);
          } else {
            this.checkPostSendAttempt(post);
          }
        },
        (err) => {
          console.log(err);
          this.checkPostSendAttempt(post);
        }
      );
  }

  private onPendingTextPost() {
    if (this._onPendingTextPostSub) this._onPendingTextPostSub.unsubscribe();

    this._onPendingTextPostSub = this.pendingTextPosts$
      .pipe(
        filter((p) => !!p),
        flatMap((p) => p)
      )
      .subscribe((post) => {
        this.invokeSendTextPost(post);
      }, err => {
        console.log(err);
      });
  }

  private onPendingMediaPost() {
    if (this._onPendingMediaPostSub) this._onPendingMediaPostSub.unsubscribe();
    console.log("subscribe onPendingMediaPost");
    this._onPendingMediaPostSub = this.pendingMediaPosts$
      .pipe(flatMap((p) => p))
      .subscribe(
        (post) => {
          if (post.fileFromCloud) {
            this.invokeSendStorageMediaPostApi(post);
          } else {
            this.invokeSendMediaPostApi(post);
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  private invokeSendMediaPostApi(post: PostState) {
    const file = this.fileStateSelector.getFile(post.mediaId);
    if (file) {
      //call server to send, update status to sending
      post.sendStatus = MessageSendStatus.Sending;
      this.addOrUpdatePosts.emit([post]);

      return (this.addMediaPostToServer(
        post.channelId,
        post.tempId,
        post.content,
        file
      )).then(
        (res) => {
          if (res) {
            const sentPost = this.parsePostState(res);
            //server return success, update status to sent
            //update file fwt
            sentPost.sendStatus = MessageSendStatus.Sent;
            this.addOrUpdatePosts.emit([sentPost]);
          } else {
            this.checkPostSendAttempt(post);
          }
        },
        (err) => {
          console.log(err);
          this.checkPostSendAttempt(post);
        }
      );
    }
  }

  private invokeSendStorageMediaPostApi(post: PostState) {
    const file = this.fileStateSelector.getFile(post.mediaId);
    if (file) {
      //call server to send, update status to sending
      post.sendStatus = MessageSendStatus.Sending;
      this.addOrUpdatePosts.emit([post]);

      return (this.addStorageMediaPostToServer(
        post.channelId,
        post.tempId,
        post.content,
        file,
        post.fileFromCloud
      )).then(
        (res) => {
          if (res) {
            const sentPost = this.parsePostState(res);
            //server return success, update status to sent
            //update file fwt
            sentPost.sendStatus = MessageSendStatus.Sent;
            this.addOrUpdatePosts.emit([sentPost]);
          } else {
            this.checkPostSendAttempt(post);
          }
        },
        (err) => {
          console.log(err);
          this.checkPostSendAttempt(post);
        }
      );
    }
  }

  addMediaPost(
    content: string,
    channelId: string,
    file: FileObjState,
    teamId: string,
    containerName: string
  ) {
    const channel = this.enterpriseSelector.getChannel(channelId);
    if (!channel) return Promise.reject("Invalid channel Id");

    var post = new PostState();
    post.channelId = channelId;
    post.orgId = this._currentOrg.id;
    post.teamId = channel.teamId;
    post.ouId = channel.ouId;
    post.content = content??"";
    post.sendStatus = MessageSendStatus.PendingToSent;
    post.mediaUrl = file.url;
    post.mediaName = file.name;
    post.fwt = file.fwt;
    post.teamId = teamId;
    post.mediaId = file.id;
    post.createdBy = this.userDataSelector.userId;
    post.fileFromCloud = containerName;

    if (file.type == FileType.Image) {
      post.type = PostType.Image;
      post.isImage = true;
    } else if (file.type == FileType.File) {
      post.type = PostType.File;
      post.isFile = true;
    }

    return forkJoin(
      this.addOrUpdateFile.emit(file),
      this.addOrUpdatePosts.emit([post])
    ).toPromise();

    // return this.invokeSendMediaApi(post, file);
  }

  private async addMediaPostToServer(
    channelId: string,
    tempId: string,
    content: string,
    file: FileObjState
  ) {
    // create form-data
    const formData = new FormData();
    const newFile = await this.mediaService.convertObjUrlToFile(file.url,
      file.name,
      file.mimeType)
    formData.append("File", newFile, file.name);
    formData.append("Content", !!content ? content : "");
    formData.append("AttachmentType", file.attachmentType.toString());
    formData.append("HubConnectionId", this.hubSnapshot.id);
    formData.append("TempId", tempId);

    var endpoint = "v2/ch/" + channelId + "/post/stream";
    return this.mediaService.streamFile(endpoint, formData, file);
  }

  private async addStorageMediaPostToServer(
    channelId: string,
    tempId: string,
    content: string,
    file: FileObjState,
    cloudContainerName: string
  ) {
    // create form-data
    const formData = new FormData();
    formData.append("Content", !!content ? content : "");
    formData.append("AttachmentType", file.attachmentType.toString());
    formData.append("HubConnectionId", this.hubSnapshot.id);
    formData.append("TempId", tempId);
    formData.append("CloudContainerName", cloudContainerName);
    formData.append("MediaUrl", file.url);

    var endpoint = "v2/ch/" + channelId + "/post/storageMedia";
    return this.mediaService.streamFile(endpoint, formData, file);
  }

  async editMediaPost(
    postId: string,
    content: string,
    file: FileObjState
  ) {
    // create form-data
    const formData = new FormData();
    const newFile = await this.mediaService.convertObjUrlToFile(file.url,
      file.name,
      file.mimeType)
    formData.append("File", newFile, file.name);
    formData.append("Content", !!content ? content : "");
    formData.append("AttachmentType", file.attachmentType.toString());
    formData.append("HubConnectionId", this.hubSnapshot.id);
    formData.append("TempId", postId);

    var endpoint = "v2/ch/post/" + postId + "/edit/stream";
    return this.mediaService.streamFile(endpoint, formData, file)
      .then((res) => {
        const post = this.parsePostState(res);
        this.addOrUpdatePosts.emit([post]);
        return post;
      })
      .catch((err) => {
        pocolog.error(err);
        return Promise.reject(err);
      });
  }

  async editStorageMediaPost(
    postId: string,
    content: string,
    file: FileObjState,
    cloudContainerName: string
  ) {
    // create form-data
    const formData = new FormData();
    const newFile = await this.mediaService.convertObjUrlToFile(file.url,
      file.name,
      file.mimeType)
    formData.append("File", newFile, file.name);
    formData.append("Content", !!content ? content : "");
    formData.append("AttachmentType", file.attachmentType.toString());
    formData.append("HubConnectionId", this.hubSnapshot.id);
    formData.append("TempId", postId);
    formData.append("CloudContainerName", cloudContainerName);
    formData.append("MediaUrl", file.url);

    console.log("editStorageMediaPost");

    var endpoint = "v2/ch/post/" + postId + "/edit/storageMedia";
    return this.mediaService.streamFile(endpoint, formData, file)
      .then((res) => {
        console.log("editStorageMediaPost res", res);
        const post = this.parsePostState(res);
        this.addOrUpdatePosts.emit([post]);
        return post;
      })
      .catch((err) => {
        pocolog.error(err);
        return Promise.reject(err);
      });
  }

  editTextPost(content: string, isMediaRemoved: boolean, postId: string): Promise<PostState> {
    return this.invoke(
      this.POST_CONTENT_UDPATE_METHOD,
      this._currentOrg.id,
      postId,
      content,
      isMediaRemoved
    )
      .then((res) => {
        const post = this.parsePostState(res.dto);
        this.addOrUpdatePosts.emit([post]);
        return post;
      })
      .catch((err) => {
        pocolog.error(err);
        return Promise.reject(err);
      });
  }

  deletePost(postId: string): Promise<PostState> {
    return this.invoke(this.POST_DELETE_METHOD, this._currentOrg.id, postId)
      .then((res) => {
        const post = this.parsePostState(res.dto);
        post.status = EntityStatus.Deleted;
        this.addOrUpdatePosts.emit([post]);
        return post;
      })
      .catch((err) => {
        pocolog.error(err);
        return Promise.reject(err);
      });
  }

  eligibleToAddPostComment(postId: string): boolean {
    try {
      const post = this.enterpriseSelector.getPost(postId);
      if (!post) return false;

      const teamMember = this.enterpriseSelector.getTeamMember(
        post.teamId,
        this.userDataSelector.userId
      );
      if (!teamMember) return false;

      return (
        post.canCreatePostComment ||
        teamMember.role == TeamRole.Owner ||
        teamMember.role == TeamRole.Admin
      );
    } catch (err) {
      return false;
    }
  }

  addTextComment(
    content: string,
    postId: string
  ): Observable<PostCommentState> {
    const post: PostState = this.enterpriseSelector.getPost(postId);
    if (!post) return throwError("Invalid post Id");

    var comment = new PostCommentState();
    comment.postId = postId;
    comment.orgId = this._currentOrg.id;
    comment.channelId = post.channelId;
    comment.ouId = post.ouId;
    comment.teamId = post.teamId;

    comment.content = content;
    comment.sendStatus = MessageSendStatus.PendingToSent;
    comment.createdBy = this.userDataSelector.userId;
    comment.type = CommentType.Text;

    return this.addOrUpdateComments.emit([comment]);
  }

  private checkCommentSendAttempt(comment: PostCommentState) {
    comment.sendAttempt++;
    if (comment.sendAttempt >= this._maxResendAttempt) {
      comment.sendStatus = MessageSendStatus.Failed;
      this.addOrUpdateComments.emit([comment]);
    } else {
      setTimeout(() => {
        comment.sendStatus = MessageSendStatus.PendingToSent;
        this.addOrUpdateComments.emit([comment]);
      }, 5000);
    }
  }

  private invokeSendTextComment(comment: PostCommentState) {
    comment.sendStatus = MessageSendStatus.Sending;
    this.addOrUpdateComments.emit([comment]);

    return this.invoke$(
      this.POST_CHAT_NEW_METHOD,
      comment.orgId,
      comment.content,
      comment.postId,
      comment.tempId
    )
      .pipe(
        map((event) => {
          if (!event || !event.isSuccess) throw new Error(event.error);
          return this.parsePostCommentState(event.dto);
        })
      )
      .subscribe(
        (sentComment) => {
          if (sentComment) {
            sentComment.sendStatus = MessageSendStatus.Sent;
            this.addOrUpdateComments.emit([sentComment]);
          } else {
            this.checkCommentSendAttempt(comment);
          }
        },
        (err) => {
          console.log(err);
          this.checkCommentSendAttempt(comment);
        }
      );
  }

  private onPendingTextComment() {
    if (this._onPendingTextCommentSub)
      this._onPendingTextCommentSub.unsubscribe();

    this._onPendingTextCommentSub = this.pendingTextComments$
      .pipe(flatMap((p) => p))
      .subscribe((post) => {
        this.invokeSendTextComment(post);
      });
  }

  private onPendingMediaComment() {
    if (this._onPendingMediaCommentSub)
      this._onPendingMediaCommentSub.unsubscribe();

    this._onPendingMediaCommentSub = this.pendingMediaComments$
      .pipe(flatMap((p) => p))
      .subscribe((comment) => {
        if (comment.fileFromCloud) {
          this.invokeSendCommentStorageMediaApi(comment);
        } else {
          this.invokeSendCommentMediaApi(comment);
        }

      });
  }

  private async addMediaCommentToServer(
    channelId: string,
    tempId: string,
    content: string,
    postId: string,
    file: FileObjState
  ) {
    // create form-data
    const formData = new FormData();
    const newFile = await this.mediaService.convertObjUrlToFile(file.url,
      file.name,
      file.mimeType)
    formData.append("File", newFile, file.name);
    formData.append("Content", !!content ? content : "");
    formData.append("Type", file.attachmentType.toString());
    formData.append("HubConnectionId", this.hubSnapshot.id);
    formData.append("TempId", tempId);

    var endpoint = "v2/ch/" + channelId + "/post/" + postId + "/comment/stream";
    return this.mediaService.streamFile(endpoint, formData, file);
  }

  private async addStorageMediaCommentToServer(
    channelId: string,
    tempId: string,
    content: string,
    postId: string,
    file: FileObjState,
    cloudContainerName: string
  ) {
    // create form-data
    const formData = new FormData();
    const newFile = await this.mediaService.convertObjUrlToFile(file.url,
      file.name,
      file.mimeType)
    formData.append("File", newFile, file.name);
    formData.append("Content", !!content ? content : "");
    formData.append("Type", file.attachmentType.toString());
    formData.append("HubConnectionId", this.hubSnapshot.id);
    formData.append("TempId", tempId);
    formData.append("CloudContainerName", cloudContainerName);
    formData.append("MediaUrl", file.url);

    var endpoint = "v2/ch/" + channelId + "/post/" + postId + "/comment/storageMedia";
    return this.mediaService.streamFile(endpoint, formData, file);
  }

  private invokeSendCommentMediaApi(comment: PostCommentState) {
    const file = this.fileStateSelector.getFile(comment.mediaId);
    if (file) {
      //call server to send, update status to sending
      comment.sendStatus = MessageSendStatus.Sending;
      this.addOrUpdateComments.emit([comment]);
      return this.addMediaCommentToServer(
        comment.channelId,
        comment.tempId,
        comment.content,
        comment.postId,
        file
      ).then(
        (res) => {
          const sentPost = this.parsePostCommentState(res);
          //server return success, update status to sent
          //update file fwt
          sentPost.sendStatus = MessageSendStatus.Sent;
          this.addOrUpdateComments.emit([sentPost]);
        },
        (err) => {
          //temp
          comment.sendStatus = MessageSendStatus.Failed;
          this.addOrUpdateComments.emit([comment]);
        }
      );
    }
  }

  private invokeSendCommentStorageMediaApi(comment: PostCommentState) {
    const file = this.fileStateSelector.getFile(comment.mediaId);
    if (file) {
      //call server to send, update status to sending
      comment.sendStatus = MessageSendStatus.Sending;
      this.addOrUpdateComments.emit([comment]);
      return this.addStorageMediaCommentToServer(
        comment.channelId,
        comment.tempId,
        comment.content,
        comment.postId,
        file,
        comment.fileFromCloud
      ).then(
        (res) => {
          const sentPost = this.parsePostCommentState(res);
          //server return success, update status to sent
          //update file fwt
          sentPost.sendStatus = MessageSendStatus.Sent;
          this.addOrUpdateComments.emit([sentPost]);
        },
        (err) => {
          //temp
          comment.sendStatus = MessageSendStatus.Failed;
          this.addOrUpdateComments.emit([comment]);
        }
      );
    }
  }

  addMediaComment(
    content: string,
    postId: string,
    channelId: string,
    file: FileObjState,
    containerName: string
  ) {
    var comment = new PostCommentState();
    comment.postId = postId;
    comment.channelId = channelId;
    comment.orgId = this._currentOrg.id;
    comment.content = content;
    comment.mediaName = file.name;
    comment.sendStatus = MessageSendStatus.PendingToSent;
    comment.mediaId = file.id;
    comment.createdBy = this.userDataSelector.userId;
    comment.fileFromCloud = containerName;

    if (file.type == FileType.Image) {
      comment.type = CommentType.Image;
    } else if (file.type == FileType.File) {
      comment.type = CommentType.File;
    }

    return forkJoin(
      this.addOrUpdateFile.emit(file),
      this.addOrUpdateComments.emit([comment])
    ).toPromise();

    // return this.invokeSendCommentMediaApi(comment, file);
  }

  deletePostChat(postChatId: string): Promise<PostCommentState> {
    return this.invoke(
      this.POST_CHAT_DELETE_METHOD,
      this._currentOrg.id,
      postChatId
    )
      .then((res) => {
        const comment = this.parsePostCommentState(res.dto);
        comment.status = EntityStatus.Deleted;
        this.addOrUpdateComments.emit([comment]);
        return comment;
      })
      .catch((err) => {
        pocolog.error(err);
        return Promise.reject(err);
      });
  }


  getPosts(channelId: string) {
    return this.invoke(
      this.OLD_POSTS_GET_METHOD,
      this._currentOrg.id,
      channelId,
      ""
    ).then(event => {
      if (!event || !event.isSuccess) throw new Error(event.error);
      return this.parseList<PostState>(event.dto, this.parsePostState.bind(this));
    }).then((res) => {
      if (res.length > 0) {
        this.addOrUpdatePosts.emit(res);
        this.setChannelPostsReady.emit(channelId);
      }
      return res;
    });
  }

  getOldPosts(channelId: string) {
    let timestamp = null;
    let posts = this.enterpriseSelector.getChPosts(channelId);
    if (posts && posts.length != 0) {
      let sortedList = _.sortBy(posts, [function (o) { return o.createdOn; }]);
      timestamp = sortedList[0].createdOn;
    }

    return this.invoke(
      this.OLD_POSTS_GET_METHOD,
      this._currentOrg.id,
      channelId,
      timestamp ? new Date(timestamp) : ""
    ).then(event => {
      if (!event || !event.isSuccess) throw new Error(event.error);
      return this.parseList<PostState>(event.dto, this.parsePostState.bind(this));
    }).then((res) => {
      if (res.length > 0) {
        this.addOrUpdatePosts.emit(res);
      }
      return res;
    });
  }

  getComments(postId: string) {
    return this.invoke(
      this.OLD_POST_CHATS_GET_METHOD,
      this._currentOrg.id,
      postId,
      ""
    ).then(event => {
      if (!event || !event.isSuccess) throw new Error(event.error);
      return this.parseList<PostCommentState>(
        event.dto,
        this.parsePostCommentState.bind(this)
      );
    }).then((res) => {
      if (res.length > 0) {
        this.addOrUpdateComments.emit(res);
      }
      return res;
    });
  }

  getOldComments(postId: string) {
    let timestamp = null;
    let comments = this.enterpriseSelector.getPostComments(postId);
    if (comments && comments.length != 0) {
      let sortedList = _.sortBy(comments, [function (o) { return o.createdOn; }]);
      timestamp = sortedList[0].createdOn;
    }

    return this.invoke(
      this.OLD_POST_CHATS_GET_METHOD,
      this._currentOrg.id,
      postId,
      timestamp ? new Date(timestamp) : ""
    ).then(event => {
      if (!event || !event.isSuccess) throw new Error(event.error);
      return this.parseList<PostCommentState>(
        event.dto,
        this.parsePostCommentState.bind(this)
      );
    }).then((res) => {
      if (res.length > 0) {
        this.addOrUpdateComments.emit(res);
      }
      return res;
    });
  }

  //return N latest medias(files/images) in the channel
  //N medias may include media from posts or comments
  getChannelMedias(channelId: string) {
    return this.invoke(
      this.OLD_CHANNEL_MEDIAS_GET_METHOD,
      this._currentOrg.id,
      channelId,
      new Date()
      , 20
    )
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        if (!event.dto) return null;
        let posts = [];
        if (event.dto.posts) {
          posts = this.parseList<PostState>(
            event.dto.posts,
            this.parsePostState.bind(this)
          );
        }

        let comments = [];
        if (event.dto.comments) {
          comments = this.parseList<PostCommentState>(
            event.dto.comments,
            this.parsePostCommentState.bind(this)
          );
        }
        return { posts, comments };
      })
      .then((res) => {
        const postFiles = _.map(
          res.posts.filter((p) => !!p.mediaId),
          (p: PostState) => {
            let file = new FileObjState();
            file.id = p.mediaId;
            file.name = p.mediaName;
            file.orgId = p.orgId;
            file.createdBy = p.createdBy;
            file.timeStamp = p.createdOn;
            file.fwt = p.fwt;
            file.fwtEncoded = file.fwt ? true : false;
            file.icon = this.getType(p.mediaName);
            file.createdAs = "Post";
            file.chnlID = channelId;
            // this.addOrUpdateFile.emit(file);
            return file;
          }
        );
        const commentFiles = _.map(
          res.comments.filter(
            (c) => !!c.mediaId
          ),
          (c: PostCommentState) => {
            let file = new FileObjState();
            file.id = c.mediaId;
            file.name = c.mediaName;
            file.orgId = c.orgId;
            file.createdBy = c.createdBy;
            file.timeStamp = c.createdOn;
            file.fwt = c.fwt;
            file.fwtEncoded = file.fwt ? true : false;
            file.icon = this.getType(c.mediaName);
            file.createdAs = "Comment";
            file.chnlID = channelId;
            // this.addOrUpdateFile.emit(file);
            return file;
          }
        );
        const files = [...postFiles, ...commentFiles]
        return files;
      });
  }

  //return N old medias(files/images) in the channel from last active date
  //N medias may include media from posts or comments
  getChannelMediasHistory(channelId: string, timestamp: string) {
    return this.invoke(
      this.OLD_CHANNEL_MEDIAS_GET_METHOD,
      this._currentOrg.id,
      channelId
      , timestamp ? new Date(timestamp) : ""
      , 10
    ).then((event) => {
      if (!event || !event.isSuccess) throw new Error(event.error);
      if (!event.dto) return null;
      let posts = [];
      if (event.dto.posts) {
        posts = this.parseList<PostState>(
          event.dto.posts,
          this.parsePostState.bind(this)
        );
      }

      let comments = [];
      if (event.dto.comments) {
        comments = this.parseList<PostCommentState>(
          event.dto.comments,
          this.parsePostCommentState.bind(this)
        );
      }
      return { posts, comments };
    }).then((res) => {
      const postFiles = _.map(
        res.posts.filter((p) => !!p.mediaId),
        (p: PostState) => {
          let file = new FileObjState();
          file.id = p.mediaId;
          file.name = p.mediaName;
          file.orgId = p.orgId;
          file.createdBy = p.createdBy;
          file.timeStamp = p.createdOn;
          file.fwt = p.fwt;
          file.fwtEncoded = file.fwt ? true : false;
          file.icon = this.getType(p.mediaName);
          file.createdAs = "Post";
          file.chnlID = channelId;
          // this.addOrUpdateFile.emit(file);
          return file;
        }
      );
      const commentFiles = _.map(
        res.comments.filter(
          (c) => !!c.mediaId
        ),
        (c: PostCommentState) => {
          let file = new FileObjState();
          file.id = c.mediaId;
          file.name = c.mediaName;
          file.orgId = c.orgId;
          file.createdBy = c.createdBy;
          file.timeStamp = c.createdOn;
          file.fwt = c.fwt;
          file.fwtEncoded = file.fwt ? true : false;
          file.icon = this.getType(c.mediaName);
          file.createdAs = "Comment";
          file.chnlID = channelId;
          // this.addOrUpdateFile.emit(file);
          return file;
        }
      );
      const files = [...postFiles, ...commentFiles]
      return files;
    });
  }

  getPost(postId: string) {
    return this.invoke(this.POST_GET_METHOD, postId)
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        if (!event.dto) return null;
        let post: PostState;
        if (event.dto.post) {
          post = this.parsePostState(event.dto.post);
        }

        let comments: PostCommentState[] = [];
        if (event.dto.comments) {
          comments = this.parseList<PostCommentState>(
            event.dto.comments,
            this.parsePostCommentState.bind(this)
          );
        }
        return { post, comments };
      })
      .catch((err) => {
        console.log(err);
        return null;
      });
  }
  //#endregion

  //#region Payment method

  private onPaymentMethodUpdate(event: HubEvent) {
    if (!event || !event.dto) {
      console.error("[OrgHub]-onPaymentMethodUpdate, INVALID HUB EVENT");
      return;
    }
    console.log("[OrgHub] onPaymentMethodUpdate %o", event);

    if (event.name === this.NEW_PAYMENT_METHOD) {
      const dto = this.parsePaymentMethod(event.dto);
      this.addOrUpdatePaymentMethods.emit([dto]);
    } else if (event.name === this.DELETE_PAYMENT_METHOD) {
      this.deletePaymentMethod.emit(event.dto);
    }
  }

  readPaymentMethod(orgId: string) {
    return this.invoke$(this.PAYMENT_METHOD_READ, orgId).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseSet(PaymentMethodState, event.dto);
      }),
      tap((states: PaymentMethodState[]) =>
        this.addOrUpdatePaymentMethods.emit([...states])
      )
    );
  }

  addPaymentMethod(orgId: string, token: string, chargeInvoice: boolean = false) {
    return this.invoke$(this.PAYMENT_METHOD_CREATE, orgId, token, chargeInvoice).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parse(PaymentMethodState, event.dto);
      }),
      tap((obj: PaymentMethodState) =>
        this.addOrUpdatePaymentMethods.emit([obj])
      )
    );
  }

  removePaymentMethod(orgId: string, token: string) {
    return this.invoke$(this.PAYMENT_METHOD_DELETE, orgId, token).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parse(PaymentMethodState, event.dto);
      }),
      tap((obj: PaymentMethodState) => this.deletePaymentMethod.emit(obj.id))
    );
  }

  setDefaultPaymentMethod(orgId: string, token: string) {
    return this.invoke$(this.PAYMENT_METHOD_SET_DEFAULT, orgId, token).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseSet(PaymentMethodState, event.dto);
      }),
      tap((states: PaymentMethodState[]) =>
        this.addOrUpdatePaymentMethods.emit([...states])
      )
    );
  }

  //#endregion

  //#region Invitation methods
  private onMembershipReqUpdate(event: HubEvent) {
    console.log("[OrgHub]-onMembershipReqUpdate %o", event);
    if (!event || !event.dto) {
      console.error("[OrgHub]-onMembershipReqUpdate, INVALID HUB EVENT");
      return;
    }

    if (event.name === this.NEW_INVITES) {
      const dtos = this.parseList<MembershipReqState>(event.dto, this.parseMembershipReq.bind(this));
      this.addOrUpdateMembershipReq.emit(dtos);
    } else if (event.name === this.DELETE_INVITES) {
      const dtos = this.parseList<MembershipReqState>(event.dto, this.parseMembershipReq.bind(this));
      this.deleteMembershipReqs.emit(dtos);
    }else {
      const dto = this.parseMembershipReq(event.dto);
      this.addOrUpdateMembershipReq.emit([dto]);
    }


  }

  parseMembershipReq(dto: any): MembershipReqState {
    if (dto == null) return null;

    let state: MembershipReqState = new MembershipReqState();
    _.assign(state, dto);

    return state;
  }

  issueInvitation(
    email: string,
    firstName: string,
    lastName: string,
    roleId: string,
    orgUnitId: string,
    preassignedSettings: any,
    ccEmail: string
  ) {
    let req: any = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      roleId: roleId,
      orgUnitId: orgUnitId,
      preassignedSettings: preassignedSettings,
      ccEmails: [ccEmail],
    };

    return this.invoke$(this.INVITE_ISSUE, req).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return true;
      })
    );
  }

  reissueInvitation(reqId: string, ouId: string) {
    return this.invoke$(this.INVITE_REISSUE, reqId, ouId).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return true;
      })
    );
  }

  deleteInvitation(reqId: string, ouId: string) {
    return this.invoke$(this.INVITE_DELETE, reqId, ouId).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return true;
      })
    );
  }

  //#endregion

  //#region Subscription
  private onSubscriptionUpdate(event: HubEvent) {
    const sub = this.parseSubscriptionState(event.dto);
    if (event.name == this.UPDATE_SUB) {
      this.addOrUpdateSubscriptions.emit([sub]);

      if (sub.status == SubStatus.PaymentFailed) {
        var orgUser = this.enterpriseSelector.getCurrentOrgUser();
        if (orgUser && orgUser.role != RoleEntity[RoleEntity.OWNER])
          this.pubSub.next(PubSub.ON_USER_SUSPENDED, "usersuspended");
      }
    } else {
      console.error("[OrgHub]-onSubscriptionUpdate INVALID EVENT: %s", event.name);
    }
  }

  getOrgInvoices(): Promise<Invoice[]> {
    return this.invoke(this.GET_INVOICES, this._currentOrg.id)
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        if (!event.dto) return null;
        var invoices = this.parseList<Invoice>(event.dto, this.parseInvoice.bind(this));
        return invoices;
      })
      .catch(err => {
        console.error(err);
        return Promise.reject();
      });
  }

  getOrgContracts(): Promise<Contract[]> {
    return this.invoke(this.GET_CONTRACTS, this._currentOrg.id)
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        if (!event.dto) return null;
        var contracts = this.parseList<Contract>(event.dto, this.parseContract.bind(this));
        return contracts;
      })
      .catch(err => {
        console.error(err);
        return Promise.reject();
      });
  }
  //#endregion

  //#region Delegate clients/staffs
  assignClientsToStaff(
    ouId: string,
    staffId: string,
    clientIds: string[]
  ) {
    return this.invoke$(
      this.ASSIGN_CLIENTS_TO_STAFF,
      ouId,
      staffId,
      clientIds
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseList<AssignmentState>(event.dto, this.parseAssignmentState.bind(this));
      }),
      tap((state: AssignmentState[]) => {
        this.addOrUpdateAssignments.emit(state);
      })
    );
  }

  assignStaffsToClient(
    ouId: string,
    clientId: string,
    staffIds: string[]
  ) {
    return this.invoke$(
      this.ASSIGN_STAFFS_TO_CLIENT,
      ouId,
      clientId,
      staffIds
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseList<AssignmentState>(event.dto, this.parseAssignmentState.bind(this));
      }),
      tap((state: AssignmentState[]) => {
        this.addOrUpdateAssignments.emit(state);
      })
    );
  }

  removeClientsFromStaff(
    ouId: string,
    staffId: string,
    clientIds: string[]
  ) {
    return this.invoke$(
      this.REMOVE_CLIENTS_FROM_STAFF,
      ouId,
      staffId,
      clientIds
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseList<AssignmentState>(event.dto, this.parseAssignmentState.bind(this));
      }),
      tap((state: AssignmentState[]) => {
        this.resetAssignments.emit(state);
      })
    );
  }

  removeStaffsFromClient(
    ouId: string,
    clientId: string,
    staffIds: string[]
  ) {
    return this.invoke$(
      this.REMOVE_STAFFS_FROM_CLIENT,
      ouId,
      clientId,
      staffIds
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseList<AssignmentState>(event.dto, this.parseAssignmentState.bind(this));
      }),
      tap((state: AssignmentState[]) => {
        this.resetAssignments.emit(state);
      })
    );
  }

  getClientAssignments(): Promise<boolean> {
    return this.invoke(this.ASSIGNMENTS_GET, this._currentOrg.id)
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        if (!event.dto) return null;

        var assignments = this.parseList<AssignmentState>(event.dto, this.parseAssignmentState.bind(this));
        this.resetAssignments.emit(assignments);
        this.setAssignmentsReady.emit(null);
        return true;
      })
      .catch(err => {
        console.error(err);
        return Promise.reject(false)
      });
  }

  private parseAssignmentState(dto: any): AssignmentState {
    if (dto == null) return null;

    const assignment = new AssignmentState();
    assignment.ouId = dto.orgUnitId;
    assignment.clientId = dto.clientId;
    assignment.staffId = dto.staffId;

    return assignment;
  }

  private onOrgAssignmentsUpdate(event: HubEvent) {
    if (
      event.name === this.ASSIGN_CLIENTS_TO_STAFF
      || event.name === this.ASSIGN_STAFFS_TO_CLIENT) {
      const dto = this.parseList<AssignmentState>(
        event.dto,
        this.parseAssignmentState.bind(this)
      );
      this.addOrUpdateAssignments.emit(dto);

    } else if (
      event.name === this.REMOVE_CLIENTS_FROM_STAFF
      || event.name === this.REMOVE_STAFFS_FROM_CLIENT) {
      const dto = this.parseList<AssignmentState>(
        event.dto,
        this.parseAssignmentState.bind(this)
      );
      this.resetAssignments.emit(dto);

    } else {
      console.error("[OrgHub]-onOrgAssignmentsUpdate INVALID EVENT: %s", event.name);
    }
  }
  //#endregion

  //gets public keys of users that does not have a direct relationship with current user in personal space
  getPublicKeys(orgId: string, userIds: string[]) {
    if (!orgId || !userIds || userIds.length == 0) return;

    return this.invoke(this.GET_PUBKEY, orgId, userIds)
      .then((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return event.dto;
      })
  }

  // get membership req
  getMembershipReq() {
    return this.invoke(
      this.GET_MEMBERSHIP_REQ,
      this._currentOrg.id
    ).then(event => {
      if (!event || !event.isSuccess) throw new Error(event.error);
      return this.parseList<MembershipReqState>(event.dto, this.parseMembershipReq.bind(this));
    }).then((membershipReq) => {
      if (membershipReq.length) {
        console.log("[OrgHub] update membershipReq");
        this.addOrUpdateMembershipReq.emit(membershipReq);
      }
    });
  }

  // get industry role types
  getCurrentIndustryRoleTypes() {
    if (!this._currentOrg.industryId) return;
    return this.invoke(
      this.GET_ROLETYPES,
      this._currentOrg.industryId
    ).then(event => {
      if (!event || !event.isSuccess) throw new Error(event.error);
      return this.parseList<RoleType>(event.dto, this.parseRoleType.bind(this));
    });
  }

  //#region Unreads
  onUnreadsEventReceived(event: HubEvent) {
    console.log("[orgHub] unreads event: %o", event);
    if (!event.dto) return;
    if (event.name == this.ADD_UNREAD) {
      if (event.dto.orgId != this._currentOrg.id) return;
      const unread = this.parseUnreadDto(event.dto);

      if (!this.appStateSelector.isBackground && (
        this._activeRoomId == unread.threadId ||
        this._activeChannelId == unread.threadId
      )) {
        this.clearUnreads(unread.threadId, unread.type);
        return;
      }

      if (unread.isRoomUnread) {
        this.addMsgUnread.emit(unread);
      } else {
        this.addEnterpriseUnread.emit(unread);
      }

    } else if (event.name == this.CLEAR_UNREAD) {
      if (event.dto.orgId != this._currentOrg.id) return;
      this.clearLocalUnread(event.dto);
    } else if (event.name == this.CLEAR_UNREADS) {
      this.clearLocalUnreads(event.dto);
    }

  }

  async clearLocalUnreads(dto: any) {
    if (!dto || dto.length == 0) return;

    for (let i = 0; i < dto.length; i++) {
      await this.clearLocalUnread(dto[i]);
    }
  }

  async clearLocalUnread(dto: any) {
    if (!dto) return;
    const clearUnread: ClearUnreadDto = this.parseClearUnreadDto(dto);
    if (clearUnread.isRoomUnread) {
      await this.clearMsgUnread.emit(clearUnread);
    } else {
      await this.clearEnterpriseUnread.emit(clearUnread);
    }
  }

  clearPendingUnreads() {

    let unreads = this.enterpriseSelector.pendingUnreadsToClear;
    //console.log("[pending unreads] clearPendingUnreads %o", unreads);
    if (!unreads || unreads.length == 0) return;

    return this.invoke(this.UNREADS_CLEAR_MULTIPLE, unreads).then((res) => {
      if (!res || !res.isSuccess) throw new Error(res.error);
      this.removePendingUnreadsToClear.emit(null);
      return Promise.resolve();
    })
      .catch((err) => {
        pocolog.error(err);
        return Promise.reject(err);
      })
  }

  clearUnreads(threadId: string, type: string) {
    if (!threadId || !type) return;

    return this.invoke(this.UNREADS_CLEAR, this._currentOrg.id, threadId, type).then(res => {
      if (!res || !res.isSuccess) {
        //console.log("[pending unreads] add pending unreads 1");
        this.addtoPendingUnreads(this._currentOrg.id, threadId, type);
        throw new Error(res.error);
      }
      return Promise.resolve();
    })
      .catch((err) => {
        pocolog.error(err);
        //console.log("[pending unreads] add pending unreads 2");
        this.addtoPendingUnreads(this._currentOrg.id, threadId, type);
        return Promise.reject(err);
      })
  }

  addtoPendingUnreads(orgId: string, threadId: string, type: string) {
    var unread = new ClearUnreadDto();
    unread.orgId = orgId;
    unread.threadId = threadId;
    unread.type = type;

    this.addPendingUnreadsToClear.emit(unread);
  }

  getDailyUsage(subId: string): Observable<FeatureDailyUsage[]> {
    return this.invoke$(
      this.FEATURE_DAILY_USAGES_GET_METHOD,
      subId
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        var res = this.parseList<FeatureDailyUsage>(
          event.dto,
          this.parseFeatureDailyUsage.bind(this)
        );

        res.forEach((r) => {
          r.subId = subId;
        });

        return res
      }),
      // tap((state: FeatureDailyUsageState[]) => {
      //   this.addUsages.emit(state);
      // })
    );
  }

  getSubRealtimeData(subId: string): Observable<SubRealtimeData> {
    return this.invoke$(
      this.GET_SUB_REALTIME_DATA_METHOD,
      subId
    ).pipe(
      map((event) => {
        if (!event || !event.isSuccess) throw new Error(event.error);
        return this.parseSubRealtimeData(event.dto);
      }),
    );
  }
  //#endregion

  //#region ext tools
  private async onExtToolsEventReceived(event: HubEvent) {
    if (event.name == this.UPDATE_EXT_TOOLS) {
      let dto = event.dto;
      if (!dto.orgId || !dto.appId) return;

      let tool = new OrgExtTool(dto.orgId, dto.appId, dto.isEnabled);

      await this.updateExtTools.emit(tool);

      this.onToolsUpdate.next(dto);

    }
  }

  updateExtToolsVisibility(orgId: string, appId: string, isEnabled: boolean = false) {
    return this.invoke(this.TOOLS_VISIBILITY, orgId, appId, isEnabled)
      .then((res) => {
        if (!res || !res.isSuccess) return Promise.reject(res.error);
        return Promise.resolve();
      })
      .catch((err) => {
        pocolog.error(err);
        return Promise.reject(err);
      });
  }

  getIndustryTools(orgId: string) {
    return this.invoke(this.ORG_TOOLS_GET, orgId).then((res) => {
      if (!res || !res.isSuccess) return Promise.reject(res.error);
      return res.dto;
    }).catch((err) => {
      pocolog.error(err);
      return Promise.reject(err);
    })
  }
  //#endregion

  //#region Parse functions

  private parseOrgState(dto: any): OrgState {
    if (dto == null) return null;

    const org = new OrgState();
    org.id = dto.id;
    org.name = dto.name;
    org.number = dto.number;
    org.type = dto.type;
    org.industryId = dto.industryId;
    org.industryName = dto.industryName;
    org.imageUrl = dto.imageUrl ? ENV.publicStorageLink + "avatars/orgs/" + dto.id + "_logo_compressed?ts=" + dto.imageUrl : null;
    org.mailingAddress = dto.mailingAddress;
    org.postalCode = dto.postalCode;
    org.state = dto.state;
    org.country = dto.country;
    org.city = dto.city;
    org.joinedOn = dto.membership ? new Date(dto.membership.joinedOn).getTime() : null;

    org.phoneNumber = dto.phoneNumber;
    org.email = dto.email;
    org.website = dto.website;
    org.fax = dto.fax;
    org.contacts = dto.contacts;
    org.ous = dto.ous;
    org.users = dto.users;
    org.connectedOrgs = dto.connectedOrgs
      ? dto.connectedOrgs
      : org.connectedOrgs;
    org.defaultOu = dto.defaultOu;
    org.status = dto.status;
    org.autoJoinEnabled = dto.autoJoinEnabled;
    org.retentionPeriod = dto.retentionPeriod;
    org.editMsgEnabled = dto.editMsgEnabled;
    org.deleteMsgEnabled = dto.deleteMsgEnabled;
    org.myDriveEnabled = dto.myDriveEnabled;
    org.myDriveEnabledGroup = dto.myDriveEnabledGroup;
    // org.subscriptionPlanType = dto.subscriptionPlanType;
    // org.subscriptionStatus = dto.subscriptionStatus;

    if (dto.extTools) {
      org.extTools = dto.extTools;
    }

    return org;
  }

  private parseOrgUserState(dto: any): OrgUserState {
    if (dto == null) return null;

    const user = new OrgUserState();
    user.orgId = dto.orgId;
    user.userId = dto.userId;
    user.matrixId = dto.matrixId;
    user.firstName = dto.firstName;
    user.lastName = dto.lastName;
    user.imageUrl = dto.imageUrl ? (!dto.imageUrl.includes(ENV.publicStorageLink) ? ENV.publicStorageLink + "avatars/users/" + dto.imageUrl : dto.imageUrl) : null;
    user.role = dto.role;
    user.roleTypeCode = dto.roleTypeCode;
    user.roleTypeName = dto.roleTypeName;
    user.status = dto.status;
    user.publicKey = dto.publicKey;
    user.ouId = dto.ouId;
    user.contactId = dto.contactId;
    user.email = dto.email;

    return user;
  }

  private parseOuState(dto: any): OUState {
    if (dto == null) return null;

    const ou = new OUState();
    ou.id = dto.id;
    ou.orgId = dto.orgId;
    ou.parentId = dto.parentId;
    ou.name = dto.name;
    ou.imageUrl = dto.imageUrl;
    ou.isDefault = dto.isDefault;
    ou.status = dto.status;
    ou.teams = dto.teams;
    ou.childs = dto.childs;
    ou.createdBy = dto.createdBy;

    return ou;
  }

  private parseTeamState(dto: any): TeamState {
    if (dto == null) return null;

    const team = new TeamState();
    team.id = dto.id;
    team.name = dto.name;
    team.ouId = dto.ouId;
    team.orgId = dto.orgId;
    team.imageUrl = dto.imageUrl;
    team.type = dto.type;
    team.isDefault = dto.isDefault;
    team.status = dto.status;
    team.canCreateChannel = dto.canCreateChannel;
    team.canCreatePost = dto.canCreatePost;
    team.canCreatePostComment = dto.canCreatePostComment;
    team.memberVisibility = dto.memberVisibility;
    team.enableImplicitOwner = dto.enabledImplicitOwner;
    team.enableImplicitAdmins = dto.enabledImplicitAdmins;
    team.enableImplicitSubOuMembers = dto.enabledImplicitSubOuMembers;
    team.enableImplicitCoworkers = dto.enabledImplicitCoWorkers;
    team.enableImplicitClients = dto.enabledImplicitClients;
    team.enableImplicitPartners = dto.enabledImplicitPartners;
    team.members = dto.members ? [...dto.members] : [];
    //team.implicitMembers = dto.implicitMembers ? [...dto.implicitMembers] : [];
    team.channels = dto.channels ? [...dto.channels] : [];

    return team;
  }

  private parseTeamMemberState(dto: any): TeamMemberState {
    if (dto == null) return null;

    const member = new TeamMemberState();
    member.userId = dto.userId;
    member.teamId = dto.teamId;
    member.ouId = dto.ouId;
    member.orgId = dto.orgId;
    member.role = dto.role;
    member.isImplicit = dto.isImplicit;
    member.implicitRole = dto.implicitRole;
    //member.type = dto.type;
    return member;
  }

  private parseChannelState(dto: any): ChannelState {
    if (dto == null) return null;

    const ch = new ChannelState();
    ch.id = dto.id;
    ch.ouId = dto.ouId;
    ch.orgId = dto.orgId;
    ch.name = dto.name;
    ch.teamId = dto.teamId;
    ch.isDefault = dto.isDefault;
    ch.participants = dto.participants;
    ch.canCreatePost = dto.canCreatePost;
    ch.canCreatePostComment = dto.canCreatePostComment;
    ch.totalPosts = dto.totalPosts;
    ch.totalFiles = dto.totalFiles;

    return ch;
  }

  parsePostState(dto: any): PostState {
    if (dto == null) return null;

    const post = new PostState();
    post.id = dto.id;
    post.tempId = dto.tempId;
    post.channelId = dto.channelId;
    post.teamId = dto.teamId;
    post.ouId = dto.ouId;
    post.orgId = dto.orgId;
    post.content = dto.content??"";
    post.createdOn = new Date(dto.createdOn).getTime();
    post.createdBy = dto.createdBy;
    post.canCreatePostComment = dto.canCreatePostComment;
    post.fwt = dto.fwt;
    post.comments = dto.comments;

    post.status = dto.status;
    post.mediaName = dto.fileName;
    post.mediaUrl = dto.mediaUrl;
    post.isImage = dto.mediaUrl ? dto.mediaUrl.includes("/images/") : false;
    post.isFile = dto.mediaUrl ? dto.mediaUrl.includes("/files/") : false;
    post.type = dto.type;
    if (dto.mediaId) {
      post.mediaId = dto.mediaId;
    } else {
      post.mediaId = dto.fwt ? ShortGuid.New() : null;
    }
    if (post.id !== post.createdOn.toString()) {
      post.sendStatus = MessageSendStatus.Sent;
    }

    return post;
  }

  parsePostCommentState(dto: any): PostCommentState {
    if (dto == null) return null;

    const comment = new PostCommentState();
    comment.id = dto.id;
    comment.tempId = dto.tempId;
    comment.postId = dto.postId;
    comment.channelId = dto.channelId;
    comment.teamId = dto.teamId;
    comment.ouId = dto.ouId;
    comment.orgId = dto.orgId;
    comment.createdOn = new Date(dto.createdOn).getTime();
    comment.createdBy = dto.createdBy;
    comment.status = dto.status;
    comment.fwt = dto.fwt;
    comment.content = dto.content;
    comment.type = dto.type;
    if (dto.mediaId) {
      comment.mediaId = dto.mediaId;
    } else {
      comment.mediaId = dto.fwt ? ShortGuid.New() : null;
    }
    comment.mediaId = dto.fwt ? ShortGuid.New() : null;
    comment.mediaName = dto.fileName;
    if (comment.id !== comment.createdOn.toString()) {
      comment.sendStatus = MessageSendStatus.Sent;
    }

    return comment;
  }

  private parseContactState(dto: any): ContactState {
    if (dto == null) return null;

    const contact = new ContactState();
    contact.id = dto.id;
    contact.userId = dto.userId;
    contact.ownerId = dto.ownerId;
    contact.orgId = dto.orgId;
    contact.firstName = dto.firstName;
    contact.lastName = dto.lastName;
    contact.displayName = dto.firstName + " " + dto.lastName;
    contact.imageUrl = dto.imageUrl;
    contact.position = dto.position;
    contact.status = dto.status;
    contact.type = dto.type;
    contact.phones = this.parseContactPhoneList(dto.phones);
    contact.emails = this.parseContactEmailList(dto.emails);
    contact.addresses = this.parseContactAddressList(dto.addresses);
    contact.orgName = dto.orgName;
    contact.note = dto.note;

    if (contact.type == ContactType.Offline) {
      contact.imageUrl = dto.imageUrl ? ENV.publicStorageLink + "avatars/contacts/" + dto.imageUrl : null;
    } else {
      contact.imageUrl = dto.imageUrl ? ENV.publicStorageLink + "avatars/users/" + dto.imageUrl : null;
    }

    return contact;
  }

  private parseContactPhoneList(list: any[]) {
    let phone: ContactPhoneState[] = [];
    list.forEach((d) => {
      let p = new ContactPhoneState();
      p.contactId = d.contactId;
      p.label = d.label;
      p.labelName = d.labelName;
      p.phoneNumber = d.phoneNumber;
      phone.push(p);
    });

    return phone;
  }

  private parseContactEmailList(list: any[]) {
    let email: ContactEmailState[] = [];
    list.forEach((d) => {
      let p = new ContactEmailState();
      p.contactId = d.contactId;
      p.label = d.label;
      p.labelName = d.labelName;
      p.email = d.email;
      email.push(p);
    });

    return email;
  }

  private parseContactAddressList(list: any[]) {
    let addr: ContactAddressState[] = [];
    list.forEach((d) => {
      let p = new ContactAddressState();
      p.contactId = d.contactId;
      p.label = d.label;
      p.labelName = d.labelName;
      p.street1 = d.street1;
      p.street2 = d.street2;
      p.postCode = d.postCode;
      p.city = d.city;
      p.state = d.state;
      p.country = d.country;
      addr.push(p);
    });

    return addr;
  }

  private parseRoleState(dto: any): OrgRoleState {
    if (dto == null) return null;

    const role = new OrgRoleState();
    role.id = dto.id;
    role.name = dto.name;
    role.normalizedName = dto.normalizedName;
    role.label = dto.label;
    role.orgId = dto.orgId;

    return role;
  }

  private parseRoleType(dto: any): RoleType {
    if (dto == null) return null;

    const type = new RoleType();
    type.id = dto.id;
    type.name = dto.name;
    type.code = dto.code;
    type.roleId = dto.roleId;
    type.industryId = dto.industryId;

    return type;
  }

  private parsePermissionState(dto: any): OrgPermissionState {
    if (dto == null) return null;

    const per = new OrgPermissionState();
    per.orgId = dto.orgId;
    per.roleId = dto.roleId;
    per.permissions = dto.permissions;

    return per;
  }

  private parsePaymentMethod(dto: any): PaymentMethodState {
    if (dto == null) return null;

    const per = new PaymentMethodState();
    per.orgId = dto.orgId;
    per.id = dto.id;
    per.brand = dto.brand;
    per.expMonth = dto.expMonth;
    per.expYear = dto.expYear;
    per.last4 = dto.last4;
    per.name = dto.name;
    per.isDefault = dto.isDefault;
    return per;
  }

  private parseBuiltInUser(dto: any): BuiltInUserState {
    if (dto == null) return null;

    let state: BuiltInUserState = new BuiltInUserState();
    _.assign(state, dto);

    // console.log("[parseBuiltInUser] %o", state);
    // console.log("[parseBuiltInUser] type of %s", state.constructor.name);

    return state;
  }

  private parseSubscriptionState(dto: any): SubscriptionState {
    let sub: SubscriptionState = new SubscriptionState();
    _.assign(sub, dto);

    // if (dto.plans && dto.plans.length > 0) {
    //   sub.plans = [];
    //   dto.plans.forEach((p) => {
    //     if (!p) return;
    //     let plan: PlanState = new PlanState();
    //     _.assign(plan, p);

    //     if (p.tiers && p.tiers.length > 0) {
    //       plan.tiers = [];
    //       p.tiers.forEach((t) => {
    //         let tier: PlanTierState = new PlanTierState();
    //         _.assign(tier, t);
    //         plan.tiers.push(tier);
    //       });
    //     }
    //     sub.plans.push(plan);
    //   });
    // }

    // if (dto.invoices && dto.invoices.length > 0) {
    //   sub.invoices = [];
    //   dto.invoices.forEach((p) => {
    //     let inv: InvoiceState = new InvoiceState();
    //     _.assign(inv, p);

    //     if (p.lines && p.lines.length > 0) {
    //       inv.lines = [];
    //       p.lines.forEach((t) => {
    //         let line: InvoiceLineState = new InvoiceLineState();
    //         _.assign(line, t);
    //         inv.lines.push(line);
    //       });
    //     }

    //     sub.invoices.push(inv);
    //   });
    // }
    return sub;
  }

  private parseFeatureState(dto: any): FeatureState {
    if (dto == null) return null;
    let feature: FeatureState = new FeatureState();
    _.assign(feature, dto);
    return feature;
  }

  private parseUnreadsDto(list: any[]): UnreadDto[] {
    let per: UnreadDto[] = [];
    list.forEach((d) => per.push(this.parseUnreadDto(d)));

    return per;
  }

  private parseUnreadDto(dto: any): UnreadDto {
    if (dto == null) return null;

    const per = new UnreadDto();
    per.threadId = dto.id;
    per.type = dto.type;
    per.unreadIds = dto.unreads;

    return per;
  }

  private parseClearUnreadDto(dto: any): ClearUnreadDto {
    if (dto == null) return null;

    const per = new ClearUnreadDto();
    per.orgId = dto.orgId;
    per.threadId = dto.threadId;
    per.type = dto.type;

    return per;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }

  private getType(filename: string): string {
    var fileNameArr = filename.toLocaleUpperCase().split('.');
    switch (fileNameArr[fileNameArr.length - 1]) {
      case "PDF":
        return "file-pdf-o";
      case "DOC":
      case "DOCX":
        return "file-word-o";
      case "XLS":
      case "XLSX":
      case "CSV":
        return "file-excel-o";
      case "TXT":
        return "file-text-o";
      case "JPG":
      case "JPEG":
      case "GIF":
      case "PNG":
      case "BMP":
        return "file-photo-o";
      case "MP4":
      case "MOV":
      case "AVI":
        return "file-movie-o";
      case "MP3":
      case "ACC":
        return "file-sound-o";
      case "RAR":
      case "ZIP":
        return "file-zip-o";
      default:
        return "file-o";
    }
  }

  private parseFeatureDailyUsage(dto: any) {
    let d = new FeatureDailyUsage();
    d.month = dto.month;
    d.year = dto.year;
    d.unit = dto.unit;
    d.resource = dto.resource;
    d.subId = dto.subId;
    d.dailyUsage = dto.dailyUsage;

    return d;
  }

  private parseSubRealtimeData(dto: any) {
    let d = new SubRealtimeData();
    d.subId = dto.subId;
    d.cost = dto.cost;
    d.usages = this.parseList<FeatureDailyUsage>(
      dto.usages,
      this.parseFeatureDailyUsage.bind(this)
    )

    d.usages.forEach((r) => {
      r.subId = d.subId;
    });

    return d;
  }

  private parseInvoice(dto: any): Invoice {
    if (dto == null) return null;

    let invoice = new Invoice();
    _.assign(invoice, dto);

    invoice.invoiceNo = dto.number
    invoice.createdOn = new Date(invoice.createdOn);
    invoice.startedOn = new Date(invoice.startedOn);
    invoice.endedOn = new Date(invoice.endedOn);
    invoice.dueOn = new Date(invoice.dueOn);

    return invoice;
  }

  private parseContract(dto: any): Contract {
    if (dto == null) return null;

    let contract = new Contract();
    _.assign(contract, dto);

    contract.createdOn = new Date(contract.createdOn);
    contract.effectiveDate = new Date(contract.effectiveDate);
    contract.expiredDate = new Date(contract.expiredDate);
    contract.terminatedOn = new Date(contract.terminatedOn);

    return contract;
  }
  //#endregion
}
