export class OrgExtTool {
    orgId: string;
    appId: string;
    isEnabled: boolean;

    constructor(orgId: string, appId: string, isEnabled: boolean = false) {
        this.orgId = orgId;
        this.appId = appId;
        this.isEnabled = isEnabled;
    }
}