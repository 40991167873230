import { State, StateContext, Selector, createSelector } from "@ngxs/store";
import { ImmutableContext, ImmutableSelector } from "@ngxs-labs/immer-adapter";
import { Receiver, EmitterAction } from "@ngxs-labs/emitter";
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { Dictionary } from "../util/dictionary";
import { StorageLocationState } from "./storage-location.state";

export class AppStateModel {
  version: string;
  deviceId: string;
  background: boolean;
  exportChatLocation: Dictionary<StorageLocationState[]>;

  constructor() {
    this.version = null;
    this.background = false;
    this.deviceId = null;
    this.exportChatLocation = {};
  }
}

@State<AppStateModel>({
  name: "app",
  defaults: new AppStateModel()
})
@Injectable()
export class AppState {

  private _stateWaitingList = [
    "app",
    // "AuthState",
    "enterprise",
    // "file",
    // "hub",
    "messaging",
    // "ui",
    // "userData",
    // "history",
    // "plaintext"
    "license"
  ];
  private _isReady = new BehaviorSubject(false);
  private _readyStateNames: string[] = [];
  private _hasInitToken = new BehaviorSubject(false);

  constructor() { }

  private isStatesReady(): boolean {
    const names = [...this._stateWaitingList];

    for (var i = 0; i < names.length; i++) {
      if (this._readyStateNames.findIndex(n => n === names[i]) === -1) {
        console.log("[AppState] not ready %o", this._readyStateNames);
        return false;
      }
    }
    console.log("[AppState] ready %o", this._readyStateNames);
    return true;
  }

  ngxsAfterBootstrap(ctx: StateContext<AppStateModel>) {
    this.reportReady("app");
    console.log("[AppState] - ngxsAfterBootstrap");
  }

  reportReady(name: string): void {
    if (this._readyStateNames.findIndex(i => i === name) === -1) {
      this._readyStateNames.push(name);
    }

    // update isReady subject
    this._isReady.next(this.isStatesReady());

  }

  ready(checkToken = true): Promise<void> {
    return new Promise<void>((resolve) => {
      // wait for states to report when they are ready
      if (checkToken) {
        // additionally wait for token to be init if checkToken is set
        combineLatest(this._isReady, this._hasInitToken).subscribe(results => {
          var statesReady = results[0];
          var tokenReady = results[1];
          if (statesReady && tokenReady) {
            resolve();
          }
        });
      } else {
        this._isReady.subscribe((ready) => {
          if (ready) {
            resolve();
          }
        });
      }
    });
  }

  initTokenDone() {
    this._hasInitToken.next(true);
  }

  @Selector()
  static version(state: AppStateModel): string | null {
    return state.version;
  }

  @Selector()
  @ImmutableSelector()
  static deviceId(state: AppStateModel): string {
    return state.deviceId;
  }

  @Selector()
  @ImmutableSelector()
  static background(state: AppStateModel): boolean {
    return state.background;
  }

  @Selector()
  @ImmutableSelector()
  static exportChatLocation(state: AppStateModel): Dictionary<StorageLocationState[]> {
    return state.exportChatLocation;
  }

  @Receiver()
  @ImmutableContext()
  static setVersion(
    ctx: StateContext<AppStateModel>,
    arg: EmitterAction<string>
  ) {
    const state = ctx.getState();
    if (arg.payload != state.version) {
      state.version = arg.payload;
      ctx.setState(state);
    }
  }

  @Receiver()
  @ImmutableContext()
  static setDeviceId(
    ctx: StateContext<AppStateModel>,
    arg: EmitterAction<string>
  ) {
    const state = ctx.getState();
    if (arg.payload != state.deviceId) {
      state.deviceId = arg.payload;
      ctx.setState(state);
    }
  }

  @Receiver()
  @ImmutableContext()
  static setBackground(
    ctx: StateContext<AppStateModel>,
    arg: EmitterAction<boolean>
  ) {
    const state = ctx.getState();
    if (arg.payload != state.background) {
      state.background = arg.payload;
      ctx.setState(state);
    }
  }

  @Receiver()
  @ImmutableContext()
  static setExportChatLocation(
    ctx: StateContext<AppStateModel>,
    arg: EmitterAction<{userId: string, location: StorageLocationState}>
){
    if (!arg && !arg.payload) return;
    const state = ctx.getState();
    if (!arg.payload.userId && !arg.payload.location) return;

    if (!state.exportChatLocation) {
      state.exportChatLocation = {};
    }

    if (state.exportChatLocation[arg.payload.userId]) {
      var existings = state.exportChatLocation[arg.payload.userId];
      var existing = existings.find(
        (e) => e.orgId == arg.payload.location.orgId
      );
      if (existing) {
        existing.data = arg.payload.location.data;
        existing.path = arg.payload.location.path;
        existing.type = arg.payload.location.type;
        existing.name = arg.payload.location.name;
        existing.isRoot = arg.payload.location.isRoot;
      } else {
        existings.push(arg.payload.location);
      }
    } else {
      state.exportChatLocation[arg.payload.userId] = [arg.payload.location];
    }

    ctx.setState(state);
  }

  @Receiver() static clean(ctx: StateContext<AppStateModel>) {
    ctx.setState(new AppStateModel());
  }
}
