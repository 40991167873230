import { LocalStorageService } from "./../localStorage/local-storage.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Observable, firstValueFrom, lastValueFrom } from "rxjs";
import { environment } from "../../../environments/environment";
import { pocolog } from "pocolog";
import { AuthStateSelector } from '../states/auth.state.selector';
import { EnterpriseSelector } from '../states/enterprise.state.selector';
import { AuthService } from "../auth/auth.service";
import { Router } from "@angular/router";
import { UserService } from "../services/user.service";
import { remove } from "jszip";

@Injectable()
export class ApiService {
  protected _apiUrl: string = environment.apiLink;
  private readonly HEADER_DOMAIN_IDENTITY: string = "EL-Domain-Identity";
  private readonly HEADER_RESOUCE_IDENTITY: string = "EL-Resource-Identity";

  constructor(
    private http: HttpClient,
    private authStateSelector: AuthStateSelector,
    private enterpriseSelector: EnterpriseSelector
  ) {
  }


  private _getJwtAccessToken() {
    let token: any = this.authStateSelector.accessToken;
    if (token) {
      // console.log(token);
      return token;
    } else {
      //pocolog.error("Failed to get access token from cache.");
      return null;
    }
  }

  private _getRooOUId() {
    let rooOuId: string = this.enterpriseSelector.getCurrentOrgId();

    // if (!rooOuId) {
    //   pocolog.error("Failed to retrieve root ou id from cache.");
    // }

    return rooOuId;
  }

  private _buildJwtAuthHeader(
    accessToken: string,
    resourceId?: string,
    customOuId?: string
  ): HttpHeaders {
    let header: HttpHeaders = new HttpHeaders();
    let rootOuId: string = this._getRooOUId();
    header = header.append("Content-Type", "application/json");

    if (header.get("Device") == null) {
      header = header.append("Device", environment.device);
    }

    if (!accessToken) {
      return header;
    }

    if (!header.has("Accept")) {
      header = header.append("Accept", "application/json");
    }

    if (!header.has("Authorization")) {
      header = header.append("Authorization", "Bearer " + accessToken);
    }

    if (customOuId) {
      header = header.append(this.HEADER_DOMAIN_IDENTITY, customOuId);
    }
    else if (rootOuId) {
      header = header.append(this.HEADER_DOMAIN_IDENTITY, rootOuId);
    }

    if (resourceId) {
      header = header.append(this.HEADER_RESOUCE_IDENTITY, resourceId);
    }
    return header;
  }

  private _buildParams(data?: any) {
    const params = new HttpParams();
    if (!data) {
      return params;
    }
    // tslint:disable-next-line:forin
    for (const k in data) {
      params.set(k, data[k]);
    }
    return params;
  }

  getUrl<T>(url: string, params?: any, elResourceIdentity?: string) {
    const options = {
      headers: this._buildJwtAuthHeader(
        this._getJwtAccessToken(),
        elResourceIdentity
      ),
      params: this._buildParams(params)
    };
    // console.log(options);
    return this.http.get<T>(url, options);
  }

  get<T>(endpoint: string, params?: any, elResourceIdentity?: string, options?: any) {
    let opt = {
      headers: this._buildJwtAuthHeader(
        this._getJwtAccessToken(),
        elResourceIdentity,
      ),
      params: this._buildParams(params)
    };
    if (options) {
      opt = options;
      opt.headers = this._buildJwtAuthHeader(
        this._getJwtAccessToken(),
        elResourceIdentity,
      );
      opt.params = this._buildParams(params)
    }
    // console.log(options);
    return this.http.get<T>(this._apiUrl + "/" + endpoint, opt);
  }

  getAsync<T>(endpoint: string, params?: any, elResourceIdentity?: string, options?: any) {
    return lastValueFrom(this.get<T>(endpoint, params, elResourceIdentity, options));
  }

  getAsyncWithJwtToken<T>(
    endpoint: string,
    JWTAuthToken: any,
    elResourceIdentity?: string
  ) {
    let options = {
      headers: this._buildJwtAuthHeader(JWTAuthToken, elResourceIdentity)
    };

    return lastValueFrom(this.http.get<T>(this._apiUrl + "/" + endpoint, options));
  }

  post<T>(
    endpoint: string,
    body: any,
    removeContentType?: boolean,
    elResourceIdentity?: string,
    headerParams?: any,
    customOuId?: string,
    additionalHeaders?: { key: string, val: string }[],
  ) {
    let options = {
      headers: this._buildJwtAuthHeader(
        this._getJwtAccessToken(),
        elResourceIdentity,
        customOuId
      )
    };
    if (headerParams) {
      options = headerParams;
      options.headers = this._buildJwtAuthHeader(
        this._getJwtAccessToken(),
        elResourceIdentity,
        customOuId
      );
    }

    if (removeContentType) {
      options.headers = options.headers.delete("Content-Type");
    }

    if (additionalHeaders && additionalHeaders.length > 0) {
      additionalHeaders.forEach((h) => {
        var a = h.val;
        if (typeof h.val !== "string") {
          h.val = a.toString();//to force value to be string
        }
        options.headers = options.headers.append(h.key, h.val);
      });
    }

    return this.http.post<T>(this._apiUrl + "/" + endpoint, body, options);
  }

  postAsync<T>(
    endpoint: string,
    body: any,
    elResourceIdentity?: string,
    headerParams?: any,
    customOuId?: string,
    removeContentType: boolean = false,
    additionalHeaders?: { key: string, val: string }[],
  ): Promise<any> {
    return lastValueFrom(
      this.post<T>(
        endpoint,
        body,
        removeContentType,
        elResourceIdentity,
        headerParams,
        customOuId,
        additionalHeaders
      )
    );
  }

  postWithCaptchaTokenAsync<T>(
    endpoint: string,
    body: any,
    captchaToken: string,
    elResourceIdentity?: string,
    headerParams?: any,
    customOuId?: string,
    withCredentials?: boolean
  ): Promise<any> {

    let options: any = {};
    if (headerParams) {
      options = headerParams;
    }
    if (withCredentials != null) {
      options.withCredentials = withCredentials;
    }


    return firstValueFrom(
      this.post<T>(
        endpoint,
        body,
        false,
        elResourceIdentity,
        options,
        customOuId,
        captchaToken ? [{ key: "captcha-token", val: captchaToken }] : []
      )
    );
  }

  postAsyncWithCredentials<T>(
    endpoint: string,
    body: any,
    elResourceIdentity?: string,
    headerParams?: any,
    customOuId?: string
  ): Promise<any> {
    let options: any = {};
    if (headerParams) {
      options = headerParams;
    }
    options.withCredentials = true;

    return lastValueFrom(
      this.post<T>(
        endpoint,
        body,
        false,
        elResourceIdentity,
        options,
        customOuId
      )
    );
  }

  postAsyncWithJwtToken<T>(
    endpoint: string,
    JWTAuthToken: any,
    body: any,
    elResourceIdentity?: string
  ) {
    let options = {
      headers: this._buildJwtAuthHeader(JWTAuthToken, elResourceIdentity)
    };

    return lastValueFrom(
      this.http
      .post<T>(this._apiUrl + "/" + endpoint, body, options)
    );
  }

  postAsyncFile(
    endpoint: string,
    body: any,
    elResourceIdentity?: string,
    headerParams?: any
  ) {
    return this.post<any>(
      endpoint,
      body,
      true,
      elResourceIdentity,
      headerParams
    );
  }

  put<T>(endpoint: string, body: any, elResourceIdentity?: string) {
    const options = {
      headers: this._buildJwtAuthHeader(
        this._getJwtAccessToken(),
        elResourceIdentity
      )
    };

    return this.http.put<T>(this._apiUrl + "/" + endpoint, body, options);
  }

  putAsync<T>(endpoint: string, body: any, elResourceIdentity?: string): Promise<any> {
    return lastValueFrom(this.put<T>(endpoint, body, elResourceIdentity));
  }

  delete<T>(endpoint: string, params?: any, elResourceIdentity?: string) {
    const options = {
      headers: this._buildJwtAuthHeader(
        this._getJwtAccessToken(),
        elResourceIdentity
      ),
      body: params
    };

    return this.http.delete<T>(this._apiUrl + "/" + endpoint, options);
  }

  deleteAsync<T>(endpoint: string, params?: any, elResourceIdentity?: string) {
    return lastValueFrom(this.delete<T>(endpoint, params, elResourceIdentity));
  }

  handleError(error: any) {
    pocolog.error(error);
    if (error && error.error) {
    } else {
      error.error = {
        code: "error",
        error: error.statusText,
        status: error.status
      };
    }
    if (error.error.status) {
      error.error.status = error.status;
    }
  }
}
