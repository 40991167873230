import { CloudFileService } from './../services/cloud-file.service';
import { GCStateHelper } from "./../states/GC.state";
import { FcpService } from "./../fcp/fcp.service";
import { SystemHub } from "./../hub/system.hub";
import { OrgHub } from "./../hub/org.hub";
import { RoomHub } from "./../hub/room.hub";
import { CancellationToken, Flow } from ".";
import { UIState } from "../states/ui.state";
import { Emittable, Emitter } from "@ngxs-labs/emitter";

export class LogoutFlow extends Flow {

  @Emitter(UIState.setContactNotReady)
  setContactNotReady: Emittable<void>;

  @Emitter(UIState.setAssignmentsNotReady)
  setAssignmentsNotReady: Emittable<void>;

  constructor(
    private roomHub: RoomHub,
    private orgHub: OrgHub,
    private systemHub: SystemHub,
    private fcp: FcpService,
    private gcStateHelper: GCStateHelper,
    private cloudFileService: CloudFileService
  ) {
    super();
  }

  public async execute(input: any = null, token: CancellationToken = null) {
    await this.fcp.deleteKeys().catch((err) => console.error(err));

    await this.roomHub.stop().toPromise();
    await this.orgHub.stop().toPromise();
    await this.systemHub.stop().toPromise();

    this.setContactNotReady.emit(null);
    this.setAssignmentsNotReady.emit(null);

    await this.gcStateHelper.cleanUp().catch((err) => console.error(err));

    this.cloudFileService.clearUploadNotifications();

    return await super.execute(input, token);
  }
}
