import { Component, OnInit, ViewChild } from "@angular/core";
import { MembershipReqService } from "../../core/services/membership-req.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { DialogEnterEmailComponent } from "../request-to-join/dialog/dialog-enter-email/dialog-enter-email.component";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { TurnstileComponent } from "../../core/auth/turnstile/turnstile.component";

declare let gtag:Function;
@Component({
  selector: "app-simple-sign-up",
  templateUrl: "./simple-sign-up.component.html",
  styleUrls: ["./simple-sign-up.component.scss"],
})
export class SimpleSignUpComponent implements OnInit {
  @ViewChild('elturnstile') turnstileComponent: TurnstileComponent;
  showError: boolean = false;
  errorMsg: string;
  showLoading: boolean = false;
  isEmailSent: boolean = false;
  firstName: string;
  lastName: string;
  email: string;
  reqId: string;

  //req-to-join token
  private _reqJoinToken: string;

  constructor(
    private membershipReqService: MembershipReqService,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {}

  // get loginUrl(): string {
  //   return this._reqJoinToken ? `/login?reqJoinToken=${this._reqJoinToken}` : "/login";
  // }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params: Params) => {
      console.log("[sign up page] params %o", params);
      if (params && params.reqJoinToken) {
        this._reqJoinToken = params.reqJoinToken;
        console.log("[sign up page] has token");
        this.showError = true;
        this.errorMsg = "Sign up for an account first before completing the request.";
      }
    });
  }

  redirectToLogin() {
    this._reqJoinToken ? this.router.navigate(["/login"], {
      queryParams: { reqJoinToken: this._reqJoinToken }
    }) : this.router.navigate(["/login"]);
  }

  register() {
    this.showLoading = true;
    this.showError = false;

    if (this._reqJoinToken) {
      this.reqJoinRegistration();
    } else {
      this.normalRegistration();
    }
    gtag('event', 'conversion', {'send_to': 'AW-640313908/P9hwCMHBqbgDELTUqbEC'});
  }

  private async reqJoinRegistration() {
    var captchaToken = await this.turnstileComponent.getToken();
    this.membershipReqService
      .requestToJoin(
        this._reqJoinToken,
        this.firstName,
        this.lastName,
        this.email,
        captchaToken
      )
      .then((res) => {
        this.handleSuccessRegistration(res);
      })
      .catch((err) => {
        console.error(err);
        this.handleErrorMsg(err);
      });
  }

  private async normalRegistration() {
    var token = await this.turnstileComponent.getToken();
    this.membershipReqService
      .reqSignUp(this.email, this.firstName, this.lastName,token)
      .then((res) => {
        this.handleSuccessRegistration(res);
      })
      .catch((err) => {
        console.error(err);
        this.handleErrorMsg(err);
      });
  }

  private handleSuccessRegistration(req: any) {
    if (req) {
      this.reqId = req.id;
    }
    this.showLoading = false;
    this.isEmailSent = true;
  }

  private handleErrorMsg(err: any) {
    this.showLoading = false;
    this.showError = true;
    if (err.error && err.error.error) {
      this.errorMsg = err.error.error;
    } else {
      this.errorMsg = this.translate.instant("REPEAT.UNEXPECTED_ERROR");
    }
  }

  async resendEmail() {
    var token = await this.turnstileComponent.getToken();
    if (this.reqId) {
      this.membershipReqService.resendEmailByReqId(this.reqId,token).then((res) => {
        this.openSnackBar(
          this.translate.instant("REPEAT.EMAIL_RESEND_SUCCESS"),
          "OK"
        );
        this.isEmailSent = true;
      });
    } else {
      this.openDialog();
    }
  }

  private async openDialog(): Promise<void> {
    const dialogRef = this.dialog.open(DialogEnterEmailComponent, {
      width: "250px",
    });
    var token = await this.turnstileComponent.getToken();
    dialogRef.afterClosed().subscribe((result) => {
      if (result)
        this.membershipReqService.resendSignUpEmail(result,true,token).then((res) => {
          this.openSnackBar(
            this.translate.instant("REPEAT.EMAIL_RESEND_SUCCESS"),
            "OK"
          );
          this.isEmailSent = true;
        });
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
    });
  }
}
