import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, OnDestroy, HostBinding, forwardRef, Optional, Self } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import { UntypedFormBuilder, UntypedFormGroup, RequiredValidator } from '@angular/forms';
import { Subject } from 'rxjs';
import { DialogOrgSelectorComponent } from '../../dialog/org-selector/dialog-org-selector.component';
import { ControlValueAccessor, NgControl, NG_VALIDATORS, AbstractControl } from '@angular/forms';
import { SelectOU } from '../../../model/select-ou.model';

const noop = () => {
};
export function selectOUValidator(control: AbstractControl) {
    if (control.errors && control.errors.required === true && !control.value.ouName) {
        return { isValid: false, required: true }
    }
    return null;
}
@Component({
    selector: 'input-select-ou',
    templateUrl: './input-select-ou.component.html',
    styleUrls: ['./input-select-ou.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: InputSelectOUComponent },
    { provide: NG_VALIDATORS, useValue: selectOUValidator, multi: true }]
})
export class InputSelectOUComponent implements MatFormFieldControl<SelectOU>, OnDestroy, ControlValueAccessor {
    static nextId = 0;
    parts: UntypedFormGroup;
    stateChanges = new Subject<void>();
    focused = false;
    controlType = 'input-select-ou';

    @HostBinding() id = `input-select-ou-${InputSelectOUComponent.nextId++}`;

    get empty() {
        let n = this.parts.value;
        if (n) {
            return !n.ouName;
        }
        return true;
    }
    get shouldLabelFloat() { return this.focused || !this.empty; }
    describedBy = '';

    @Input()
    get placeholder() { return this._placeholder; }
    set placeholder(plh) {
        this._placeholder = plh;
        this.stateChanges.next();
    }
    private _placeholder: string;

    @Input()
    get required() { return this._required; }
    set required(req) {
        this._required = coerceBooleanProperty(req);
        this.stateChanges.next();
    }
    private _required = false;

    @Input()
    get disabled() { return this._disabled; }
    set disabled(dis) {
        this._disabled = coerceBooleanProperty(dis);
        this.stateChanges.next();
    }
    private _disabled = false;

    @Input() get errorState() {
        return this.ngControl.errors !== null && this.ngControl.touched;
    }

    @Input()
    get value(): SelectOU | null {
        let n = this.parts.value;
        return new SelectOU(n.ouId, n.ouName);
    }
    set value(ou: SelectOU | null) {
        // call control value accessor writeValue
        this.writeValue(ou);
    }

    // Control Value Accessor Interface
    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    onBlur() {
        this.onTouchedCallback();
    }


    writeValue(value: SelectOU) {
        value = value || new SelectOU();
        if (value.ouId) {
            this.parts.patchValue({ ouId: value.ouId });
        }
        if (value.ouName) {
            this.parts.patchValue({ ouName: value.ouName })
        }

        if (typeof value === 'string') {
            this.parts.patchValue({ ouName: value })
        }
        this.onChangeCallback(value);

    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }
    // End Control Value Accessor Interface

    constructor(@Optional() @Self() public ngControl: NgControl, fb: UntypedFormBuilder, private fm: FocusMonitor,
        private elRef: ElementRef, public dialog: MatDialog) {

        if (this.ngControl) {
            this.ngControl.valueAccessor = this;
        }

        this.parts = fb.group({
            'ouId': '',
            'ouName': ''
        });

        fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
            this.focused = !!origin;
            this.stateChanges.next();
        });
    }

    ngOnDestroy() {
        this.stateChanges.complete();
        this.fm.stopMonitoring(this.elRef.nativeElement);
    }

    setDescribedByIds(ids: string[]) {
        this.describedBy = ids.join(' ');
    }

    onContainerClick(event: MouseEvent) {
        if ((event.target as Element).tagName.toLowerCase() !== 'input') {
            this.elRef.nativeElement.querySelector('input').focus();
        }
    }

    selectOU() {
        if (!this.disabled) {
            const dialogRef = this.dialog.open(DialogOrgSelectorComponent, {
                width: '400px',
                height: '70vh',
                panelClass: "border-dialog"
            });

            dialogRef.afterClosed().subscribe(res => {
                if (res) {
                    if (res.ouPath) {
                        this.value = new SelectOU(res.ouId, res.ouPath);
                    }
                }
            });
        }
    }
}
