import { Injectable } from "@angular/core";
import { Store } from "@ngxs/store";
import { AppState } from "./app.state";
import { Emitter, Emittable } from "@ngxs-labs/emitter";
import { Guid } from "../model/guid.model";
import { StorageLocationState } from "./storage-location.state";

@Injectable({
  providedIn: "root"
})
export class AppStateSelector {
  @Emitter(AppState.setDeviceId)
  public setDeviceIdState: Emittable<string>;

  constructor(private store: Store) {}

  getDeviceId() {
    const deviceId = this.store.selectSnapshot(AppState.deviceId);
    return deviceId;
  }

  initDeviceId() {
    const deviceId = this.getDeviceId();
    if (!!deviceId) {
      return deviceId;
    } else {
      var id = Guid.NewGuid();
      this.setDeviceIdState.emit(id);
      return id;
    }
  }

  getExportChatLocation(userId: string, orgId: string): StorageLocationState{
    const locations = this.store.selectSnapshot(AppState.exportChatLocation);
    if (!locations) return null;
    var existings = locations[userId];
    if (!existings) return null;
    return existings.find((e) => e.orgId == orgId);
  }

  get isBackground() {
    return this.store.selectSnapshot(AppState.background);
  }

  get version() {
    const version = this.store.selectSnapshot(AppState.version);
    return version;
  }
}
