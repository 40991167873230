import { Directive, Input, ElementRef, OnInit, OnChanges } from '@angular/core';
import { Subscription, Observable } from 'rxjs'
import { OrgPermissionState } from '../model/org.state';
import { Select } from '@ngxs/store';
import { EnterpriseState } from '../states/enterprise.state';
import { UserDataSelector } from '../states/user-data.state.selector';
import { PermissionService } from '../services/permission.service';


/**
 * Generated class for the PermissionDirective directive.
 *
 * See https://angular.io/api/core/Directive for more info on Angular
 * Directives.
 */
@Directive({
    selector: '[el-permission]' // Attribute selector
})
export class PermissionDirective implements OnInit {
    @Input('permission-type') permissionType: string;
    @Input('resource-owner-id') resourceOwnerId: string;
    @Input('list-permission') listPermission = []; // show if has at least 1 permission from list

    @Select(EnterpriseState.currentPermissions)
    currentOrgPermissions$: Observable<OrgPermissionState>;
    private _orgPermissionSub: Subscription;

    private _updatePerListSub: Subscription;

    private defaultDisplay: string;
    constructor(private userDataSelector: UserDataSelector,
        private el: ElementRef,
        private permissionService: PermissionService) {
    }

    ngOnInit() {
        this.defaultDisplay = this.el.nativeElement.style.display;
        this.hideOrShow();

        // update UI whenever orgpermission changes
        if (this._orgPermissionSub) {
            this._orgPermissionSub.unsubscribe();
        }
        this._orgPermissionSub = this.currentOrgPermissions$.subscribe(res => {
            this.hideOrShow();
        });
    }

    ngOnDestroy() {
        if (this._updatePerListSub) {
            this._updatePerListSub.unsubscribe();
        }
        if (this._orgPermissionSub) {
            this._orgPermissionSub.unsubscribe();
        }
    }

    hideOrShow(): void {
        if (this.permissionService.isOrgPermissionListAvailable()) {
            // if listPermission exist, check list with permission
            if (this.listPermission.length > 1) {
                if (this.permissionService.hasPermissionInList(this.resourceOwnerId, this.listPermission)) {
                    this.showContent();
                } else {
                    this.hideContent();
                }
            }
            // list doesn't exist, check using permissionType
            else if (this.permissionService.hasPermission(this.resourceOwnerId, this.permissionType)) {
                this.showContent();
            } else {
                this.hideContent();
            }
        } else {
            this.updateData();
        }
    }


    updateData() {
        //call when cannot get permission
        if (this._updatePerListSub) {
            this._updatePerListSub.unsubscribe();
        }

        this._updatePerListSub = this.permissionService.permissionListAvailable.subscribe(isAvailable => {
            if (isAvailable) {
                this.hideOrShow();
                this._updatePerListSub.unsubscribe();
            }
        });
    }

    hideContent(): void {
        this.el.nativeElement.style.display = 'none';
    }

    showContent() {
        if (!this.defaultDisplay) {
            this.defaultDisplay = 'block';
        }
        this.el.nativeElement.style.display = this.defaultDisplay;
    }
}
