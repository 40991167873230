import { SearchService } from './core/services/search.service';
import { DialogMsgReactionsComponent } from './chat/dialog/dialog-msg-reactions.component';
import { DialogSelectReactionComponent } from './chat/dialog/dialog-select-reaction.component';
import { SelfSignUpComponent } from './pages/self-sign-up/self-sign-up.component';
import { DialogLicenseLimitExceedComponent } from './organization/user/user-table/dialog/dialog-license-limit-exceed.component';
import { NotificationService } from './core/services/notify.service';
import { DialogInviteUsersComponent } from './core/ui/dialog/invite-users/dialog-invite-users.component';
import { InMemPostState } from './core/states/inmem.post.state';
import { NgxsModule } from "@ngxs/store";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { NgxsSelectSnapshotModule } from "@ngxs-labs/select-snapshot";
import { AuthGuard } from "./core/auth/auth.guard";
import { CoreModule } from "./core/core.module";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, ApplicationRef } from "@angular/core";
import { SharedBaseModule } from "./shared/shared.module";
import { NgxEditorModule } from 'ngx-editor';

import { AppComponent } from "./app.component";
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule
} from "ngx-perfect-scrollbar";

import { MainPageComponent } from "./pages/main-page/main-page.component";
import { DashboardPageComponent } from "./pages/dashboard-page/dashboard-page.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { MeetPageComponent } from "./pages/meet-page/meet-page.component";
import { SignUpPageComponent } from "./pages/sign-up-page/sign-up-page.component";
import { InvitePageComponent } from "./pages/invite-page/invite-page.component";
import { ForgotPasswordPageComponent } from "./pages/forgot-password-page/forgot-password-page.component";
import { AppRoutesModule } from "./routes/app-routes.module";
import { SidemenuModule } from "./sidemenu/sidemenu.module";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { PortalModule } from "@angular/cdk/portal";
import { NgxEchartsModule } from "ngx-echarts";
import { CurrentOUComponent } from "./core/ui/current-ou/current-ou.component";
import { ForgotResetComponent } from "./pages/forgot-password-page/forgot-reset/forgot-reset.component";
import { UIService } from "./core/services/ui.service";
import { DialogNewOrgComponent } from "./core/ui/dialog/new-org/dialog-new-org.component";
import { DialogChangePasswordComponent } from "./core/ui/dialog/change-password/dialog-change-password.component";

// import { EsignserviceService } from "./esign/service/esignservice.service";
// import { EsignuiserviceService } from "./esign/service/esignuiservice.service";
// import { EsignAuthService } from "./esign/service/esignauth.service";
import { GuestEzsignService } from "./pages/guestezsign/service/guestezsign.service";
import { GuestEzsignGuard } from "./pages/guestezsign/service/guestezsignauth";
import { GuestEZsignAuthService } from "./pages/guestezsign/service/guestezsignauth.service";

import { CardComponent } from "./pages/dashboard-page/card/card.component";
import { TreeTableModule } from "primeng/treetable";
import { DialogSwitchOUComponent } from "./core/ui/dialog/switch-ou/dialog-switch-ou.component";
import { NgIdleModule } from "@ng-idle/core";
import { environment } from "../environments/environment";
import { AngularFireMessagingModule } from "@angular/fire/compat/messaging";
import { AngularFireDatabaseModule } from "@angular/fire/compat/database";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { AngularFireModule } from "@angular/fire/compat";

// import { ConfirmationDialogComponent } from "./esign/controls/shared/confirmation-dialog/confirmation-dialog.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
// friends and org module uses this
import { DialogDeleteInviteComponent } from "./organization/user/user-table/dialog/dialog-delete-invite.component";
import { DialogDeleteUserComponent } from "./organization/user/user-table/dialog/dialog-delete-user.component";

import { RecaptchaModule } from "ng-recaptcha";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {};
const DEFAULT_MAT_TAB_CONFIG: MatTabsConfig = { animationDuration: "0ms" }

// AoT requires an exported function for factories for translate module
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

import { NgxStripeModule } from "ngx-stripe";
import { DialogRestoreUserComponent } from "./organization/user/user-table/dialog/dialog-restore-user.component";
import { DialogProfileAddressComponent } from "./user-profile/dialog/dialog-profile-address.component";
import { DialogDeleteMessageComponent } from "./chat/dialog/dialog-delete-message.component";
import { DialogForwardMessageComponent } from "./chat/dialog/dialog-forward-message.component";
import { DialogRemoveRoomComponent } from './chat/dialog/dialog-remove-room.component';
import { DialogAddChannelComponent } from "./organization/team/team-table/dialog/dialog-add-channel.component";
import { DialogEditChannelComponent } from "./organization/team/team-table/dialog/dialog-edit-channel.component";
import { DialogDeleteChannelComponent } from "./organization/team/team-table/dialog/dialog-delete-channel.component";
import { DialogOrderConfirmationComponent } from "./core/ui/dialog/order-confirmation/dialog-order-confirmation.component";
import { EnterpriseState } from "./core/states/enterprise.state";
import { AuthState } from "./core/states/auth.state";
import { NgxsEmitPluginModule } from "@ngxs-labs/emitter";
import { HubState } from "./core/states/hub.state";
import { AppState } from "./core/states/app.state";
import { NetworkService } from "./core/services/network.service";
import { UserDataState } from "./core/states/user-data.state";
import { MessagingState } from "./core/states/messaging.state";
import { FileState } from "./core/states/file.state";
import { NgxsAsyncStoragePluginModule } from "@ngxs-labs/async-storage-plugin";
import { IFStorageEngine } from "./core/states/if.storage.engine";
import { UIState } from "./core/states/ui.state";
import { HistoryState } from "./core/states/history.state";
import { DecryptedMsgState } from "./core/states/decrypted-msg.state";
import { PubSub } from "./core/services/pubsub.service";
import { AbstractStateSelector } from "./core/states/abstract.state.selector";
import { ExtStateSelector } from "./core/states/ext.state.selector";
import { VerifyMembershipComponent } from './pages/verify-membership/verify-membership.component';
import { RequestToJoin2Component } from './pages/request-to-join/request-to-join-2.component';
import { DialogEnterEmailComponent } from './pages/request-to-join/dialog/dialog-enter-email/dialog-enter-email.component';
import { DialogRequestSuccessComponent } from './pages/request-to-join/dialog/dialog-success/dialog-success.component';
import { SimpleSignUpComponent } from './pages/simple-sign-up/simple-sign-up.component';
import { DialogForceLogoutComponent } from './pages/verify-membership/dialog/dialog-force-logout.component';
import { DialogDeleteTeamComponent } from './organization/team/team-table/dialog/dialog-delete-team.component';
import { InMemMessageState } from './core/states/inmem.message.state';
import { GlobalLoaderComponent } from './core/ui/global-loader/global-loader.component';
import { Verify2faComponent } from './pages/verify-2fa/verify-2fa.component';
import { BlankPageComponent } from './pages/blank-page/blank-page.component';
import { DialogMultiTabComponent } from './pages/blank-page/dialog/dialog-multi-tab.component';
import { DialogSuccessMsgComponent } from './pages/verify-membership/dialog/dialog-success-msg/dialog-success-msg.component';
import { DialogAddUserComponent } from './organization/user/user-table/dialog/dialog-add-user.component';
import { DialogAddFriendComponent } from './friends/dialog/dialog-add-friend.component';
import { BrowserSupportComponent } from './pages/browser-support/browser-support.component';
// import { ELNetAuthService } from "./elremote/services/elnetauth.service";
import { CloudFileService } from './core/services/cloud-file.service';
import { DialogConfirmComponent } from './core/ui/dialog/dialog-confirm/dialog-confirm.component';
import { DialogPromptComponent } from './core/ui/dialog/dialog-prompt/dialog-prompt.component';
import { PluginManager } from './tools/plugin.manager';
import { ToolsAuthService } from './tools/common-services/tools-auth.service';
import { ToolsGuard } from './tools/common-services/tools-auth.guard';
import { JoinMeetPageComponent } from './pages/join-meet-page/join-meet-page.component';
import { TokenProvider } from './core/services/jwt-token.provider';
import { RedirectPageComponent } from './pages/redirect-page/redirect-page.component';
import { LicenseState } from './core/states/license.state';
import { DialogOfflineLogoutComponent } from './core/ui/dialog/dialog-offline-logout/dialog-offline-logout.component';
import { DialogPaymentMethodComponent } from './core/ui/dialog/dialog-payment-method/dialog-payment-method.component';
import { DialogNewOrgWizardComponent } from './core/ui/dialog/dialog-new-org-wizard/dialog-new-org-wizard.component';
import { DialogWelcomeNewAccountComponent } from './core/ui/dialog/welcome-new-account/dialog-welcome-new-account.component';
import { DialogWelcomeNewOrgComponent } from './core/ui/dialog/welcome-new-org/dialog-welcome-new-org.component';
// import { InMemLicenseState } from './core/states/inmem.license.state';
import { DialogCountdownComponent } from './core/ui/dialog/dialog-countdown/dialog-countdown.component';
import { DialogInvitationMethodComponent } from './organization/user/user-table/dialog/dialog-invitation-method/dialog-invitation-method.component';
import { DialogCsvInviteComponent } from './organization/user/user-table/dialog/dialog-csv-invite/dialog-csv-invite.component';
// import Rollbar from 'rollbar';
import {
  Injectable,
  Injector,
  Inject,
  InjectionToken,
  ErrorHandler
} from '@angular/core';
import { DialogMoveResourceComponent } from './storage/dialog/dialog-move-resource/dialog-move-resource.component';
import { MatTabsConfig, MAT_TABS_CONFIG } from '@angular/material/tabs';
import { AnimationDurations } from '@angular/material/core';
import { AgGridModule } from 'ag-grid-angular';
import { InfotrackerComponent } from './tools/health-check-infotracker/infotracker.component';
import { FormassignmentComponent } from './tools/health-check-infotracker/formassignment/formassignment.component';
import { SelfreportComponent } from './tools/health-check-infotracker/selfreport/selfreport.component';
import { ReportforothersComponent } from './tools/health-check-infotracker/reportforothers/reportforothers.component';
import { AddupdateuserComponent } from './tools/health-check-infotracker/reportforothers/addupdateuser/addupdateuser.component';
import { ReportforothersummaryComponent } from './tools/health-check-infotracker/reportforothersummary/reportforothersummary.component';
import { AdminreportComponent } from './tools/health-check-infotracker/adminreport/adminreport.component';
import { DesignatedusersComponent } from './tools/health-check-infotracker/designatedusers/designatedusers.component';
import { IncomeExpenseComponent } from './tools/income-expense/income-and-expense.component';
import { IncomeExpenseSettingsComponent } from './tools/income-expense/controls/settings/iet-settings.component';
import { IetCompanyComponent } from './tools/income-expense/controls/iet-company/iet-company.component';
import { IetAddreceiptComponent } from './tools/income-expense/controls/iet-addreceipt/iet-addreceipt.component';
import { IetViewreportComponent } from './tools/income-expense/controls/iet-viewreport/iet-viewreport.component';
import { TurnstileComponent } from './core/auth/turnstile/turnstile.component';
import { NgxTurnstileModule } from 'ngx-turnstile';

import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';

import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';

import { EmptyLayoutModule } from 'app/layout/layouts/empty/empty.module';

import { ClassyLayoutModule } from 'app/layout/layouts/vertical/classy/classy.module';

import { SettingsModule } from 'app/layout/common/settings/settings.module';

import { SharedFusedModule } from 'app/shared/shared.fuse.module';

import { FuseLoadingBarModule } from '@fuse/components/loading-bar';

import { FuseNavigationModule } from '@fuse/components/navigation';

import { NotificationsModule } from 'app/layout/common/notifications/notifications.module';
import { UserModule } from 'app/layout/common/user/user.module';
import { QuickChatModule } from 'app/layout/common/quick-chat/quick-chat.module';

import { MatDividerModule } from '@angular/material/divider';
import { FuseScrollbarModule } from '@fuse/directives/scrollbar/public-api';

import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseFullscreenModule } from '@fuse/components/fullscreen/fullscreen.module';
import { LanguagesModule } from 'app/layout/common/languages/languages.module';
import { MessagesModule } from 'app/layout/common/messages/messages.module';
import { SearchModule } from 'app/layout/common/search/search.module';
import { ShortcutsModule } from 'app/layout/common/shortcuts/shortcuts.module';
import { ClassyLayoutComponent } from 'app/layout/layouts/vertical/classy/classy.component';

import { InMemFeedState } from './core/states/inmem.feed.state';
import { FeedState } from './core/states/feed.state';
import { StorageLocationService } from './core/services/storage-location.service';
// export const RollbarService = new InjectionToken<Rollbar>('rollbar');

const rollbarConfig = {
  accessToken: 'ce840dd4fe2a45f2a6ed46215c6f9bff',
  captureUncaught: true,
  captureUnhandledRejections: true,
  code_version: environment.version
};

@Injectable()
// export class RollbarErrorHandler implements ErrorHandler {
//   constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

//   handleError(err:any) : void {
//     this.rollbar.error(err.originalError || err);
//   }
// }

// export function rollbarFactory() {
//     return new Rollbar(rollbarConfig);
// }


@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    PageNotFoundComponent,
    //DashboardPageComponent,
    LoginPageComponent,
    MeetPageComponent,
    JoinMeetPageComponent,
    CurrentOUComponent,
    SignUpPageComponent,
    InvitePageComponent,
    ForgotPasswordPageComponent,
    ForgotResetComponent,
    DialogNewOrgComponent,
    DialogChangePasswordComponent,
    DialogSwitchOUComponent,
    //CardComponent,
    // ConfirmationDialogComponent,
    DialogDeleteInviteComponent,
    DialogDeleteUserComponent,
    DialogRestoreUserComponent,
    DialogProfileAddressComponent,
    DialogDeleteMessageComponent,
    DialogForwardMessageComponent,
    DialogRemoveRoomComponent,
    DialogAddChannelComponent,
    DialogEditChannelComponent,
    DialogDeleteChannelComponent,
    DialogOrderConfirmationComponent,
    VerifyMembershipComponent,
    RequestToJoin2Component,
    DialogEnterEmailComponent,
    DialogRequestSuccessComponent,
    SimpleSignUpComponent,
    SelfSignUpComponent,
    DialogDeleteTeamComponent,
    DialogForceLogoutComponent,
    GlobalLoaderComponent,
    Verify2faComponent,
    BlankPageComponent,
    DialogMultiTabComponent,
    DialogInviteUsersComponent,
    DialogSuccessMsgComponent,
    DialogInvitationMethodComponent,
    DialogAddUserComponent,
    DialogCsvInviteComponent,
    DialogAddFriendComponent,
    BrowserSupportComponent,
    DialogConfirmComponent,
    DialogCountdownComponent,
    DialogPromptComponent,
    DialogOfflineLogoutComponent,
    JoinMeetPageComponent,
    RedirectPageComponent,
    DialogLicenseLimitExceedComponent,
    DialogPaymentMethodComponent,
    DialogNewOrgWizardComponent,
    DialogWelcomeNewAccountComponent,
    DialogWelcomeNewOrgComponent,
    DialogInvitationMethodComponent,
    DialogCsvInviteComponent,
    DialogSelectReactionComponent,
    DialogMsgReactionsComponent,
    DialogMoveResourceComponent,
    InfotrackerComponent,
    FormassignmentComponent,
    SelfreportComponent,
    ReportforothersComponent,
    AddupdateuserComponent,
    ReportforothersummaryComponent,
    DesignatedusersComponent,
    AdminreportComponent,
    IncomeExpenseComponent,
    IncomeExpenseSettingsComponent,
    IetCompanyComponent,
    IetAddreceiptComponent,
    IetViewreportComponent,
    TurnstileComponent,
    ClassyLayoutComponent
  ],
  entryComponents: [
    AppComponent,
    MeetPageComponent,
    DialogNewOrgComponent,
    DialogChangePasswordComponent,
    DialogSwitchOUComponent,
    // ConfirmationDialogComponent,
    DialogDeleteInviteComponent,
    DialogDeleteUserComponent,
    DialogRestoreUserComponent,
    DialogProfileAddressComponent,
    DialogDeleteMessageComponent,
    DialogForwardMessageComponent,
    DialogRemoveRoomComponent,
    DialogAddChannelComponent,
    DialogEditChannelComponent,
    DialogDeleteChannelComponent,
    DialogOrderConfirmationComponent,
    DialogEnterEmailComponent,
    DialogRequestSuccessComponent,
    DialogDeleteTeamComponent,
    DialogForceLogoutComponent,
    DialogMultiTabComponent,
    DialogInviteUsersComponent,
    DialogSuccessMsgComponent,
    DialogInvitationMethodComponent,
    DialogAddUserComponent,
    DialogCsvInviteComponent,
    DialogAddFriendComponent,
    DialogConfirmComponent,
    DialogCountdownComponent,
    DialogPromptComponent,
    DialogOfflineLogoutComponent,
    DialogLicenseLimitExceedComponent,
    DialogOfflineLogoutComponent,
    DialogPaymentMethodComponent,
    DialogNewOrgWizardComponent,
    DialogWelcomeNewAccountComponent,
    DialogWelcomeNewOrgComponent,
    DialogSelectReactionComponent,
    DialogMsgReactionsComponent
  ],
  imports: [
    SharedBaseModule,
    BrowserModule,
    CoreModule,
    BrowserAnimationsModule,
    SidemenuModule,
    PortalModule,
    // PerfectScrollbarModule,
    NgxEchartsModule,
    HttpClientModule,
    TreeTableModule,
    AgGridModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgIdleModule.forRoot(),
    AppRoutesModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    NgxStripeModule.forRoot(environment.stripePublishKey),
    RecaptchaModule,
    NgxEditorModule,
    NgxsModule.forRoot(
      [
        EnterpriseState,
        AuthState,
        HubState,
        AppState,
        UserDataState,
        FileState,
        MessagingState,
        InMemMessageState,
        UIState,
        HistoryState,
        DecryptedMsgState,
        InMemPostState,
        LicenseState,
        FeedState,
        InMemFeedState
        // InMemLicenseState
      ],
      {
        developmentMode: !environment.production,
        selectorOptions: {
          suppressErrors: false,
          injectContainerState: false
        },
        compatibility: {
          strictContentSecurityPolicy: true
        }
      }
    ),
    NgxsAsyncStoragePluginModule.forRoot(IFStorageEngine, {
      key: [
        "app",
        // "AuthState",
        "enterprise",
        "file",
        // "hub", //should reinitiliaze after restart
        "messaging",
        // "ui",
        "userData",
        "history",
        "plaintext",
        "license",
        "feeds"
      ]
    }),
    NgxsEmitPluginModule.forRoot(),
    //NgxsStoragePluginModule.forRoot(),
    NgxsSelectSnapshotModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production
    }),
    NgxTurnstileModule,
         // Fuse, FuseConfig & FuseMockAPI
         FuseModule,
         FuseConfigModule.forRoot(appConfig),
         FuseMockApiModule.forRoot(mockApiServices),
        
         MarkdownModule.forRoot({}),
         EmptyLayoutModule,
         ClassyLayoutModule,
         SettingsModule,
         FuseLoadingBarModule,
         FuseNavigationModule,
         NotificationsModule,
         UserModule,
         QuickChatModule,
         SharedFusedModule,
         MatDividerModule,
         FuseScrollbarModule,
         RouterModule,
         MatButtonModule,
         MatDividerModule,
         MatIconModule,
         MatMenuModule,
         FuseFullscreenModule,
         LanguagesModule,
         MessagesModule,
         SearchModule,
         ShortcutsModule,
  ],

  exports:[
    EmptyLayoutModule,
    ClassyLayoutModule,
    SettingsModule,
    ClassyLayoutComponent
  ],  
  providers: [
    ExtStateSelector,
    {
      provide: AbstractStateSelector,
      useClass: ExtStateSelector
    },
    // {
    //   provide: PERFECT_SCROLLBAR_CONFIG,
    //   useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    // },
    {
      provide: MAT_TABS_CONFIG,
      useValue: DEFAULT_MAT_TAB_CONFIG
    },
    AuthGuard,
    NetworkService,
    // EsignserviceService,
    // EsignuiserviceService,
    // EsignAuthService,
    GuestEzsignService,
    GuestEzsignGuard,
    GuestEZsignAuthService,
    UIService,
    PubSub,
    NotificationService,
    CloudFileService,
    SearchService,
    PluginManager,
    ToolsAuthService,
    ToolsGuard,
    // ELNetAuthService,
    TokenProvider,
    StorageLocationService
    // { provide: ErrorHandler, useClass: RollbarErrorHandler },
    // { provide: RollbarService, useFactory: rollbarFactory }

  ]
})
export class AppModule {
  ngDoBootstrap(app: ApplicationRef) {

    var url = window.location.href;
    console.log("[AppModule] ngDoBootstrap url %s", url)

    // /meet is meeting page, /meet/join is a redirect page, it should not be treated as a meet page
    var isMeet = url.includes("/meet") && !url.includes("/meet/join")

    // start room hub only in meet
    if (!isMeet) {
      console.log("[AppModule] Bootstraping AppComponent")
      this.bootstrapRootComponent(app, "app-root");
    } else {
      console.log("[AppModule] Bootstraping MeetPageComponent")
      this.bootstrapRootComponent(app, "app-meet-page");
    }
  }

  // app - reference to the running application (ApplicationRef)
  // name - name (selector) of the component to bootstrap
  bootstrapRootComponent(app, name) {
    // define the possible bootstrap components
    // with their selectors (html host elements)
    const options = {
      'app-root': AppComponent,
      'app-meet-page': MeetPageComponent
    };
    // create DOM element for the component being bootstrapped
    // and add it to the DOM
    const componentElement = document.createElement(name);
    const componentAppBody = document.getElementById("app-body");
    componentAppBody.parentNode.replaceChild(componentElement, componentAppBody);
    // bootstrap the application with the selected component
    const component = options[name];
    app.bootstrap(component);
  }
}
