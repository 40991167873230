import { MembershipReqService } from './../../core/services/membership-req.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, HostBinding, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { routerAnimation } from '../../utils/page.animation';
import { OUService } from '../../core/services/ou.service';
import { AuthService } from '../../core/auth/auth.service';
import { RegistrationDto } from '../../core/auth/registration.dto';
import { UserService } from '../../core/services/user.service';
import { pocolog } from 'pocolog';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { StripeService, Elements, Element as StripeElement, CardDataOptions } from 'ngx-stripe';
import { ELStripeElementOptions } from '../../core/model/stripe-element.model';
import { DecimalPipe } from '@angular/common';
import { PlanType } from '../../core/model/subscription.model';
import { BillingScheme, TiersMode, Plan, Interval, ProductType } from '../../core/model/plan.model';
import { DialogOrderConfirmationComponent } from '../../core/ui/dialog/order-confirmation/dialog-order-confirmation.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { InputNumberStateMatcher } from '../../core/state-matcher/input-number-min-max-state-matcher';
import { Product } from '../../core/model/product.dto';
import { Coupon } from '../../core/model/coupon.model';
import { DialogEnterEmailComponent } from '../request-to-join/dialog/dialog-enter-email/dialog-enter-email.component';
import { TurnstileComponent } from '../../core/auth/turnstile/turnstile.component';
declare let gtag:Function;
@Component({
  selector: 'app-sign-up-page',
  templateUrl: './sign-up-page.component.html',
  styleUrls: ['./sign-up-page.component.scss'],
  animations: [routerAnimation],
  providers: [DecimalPipe]
})

export class SignUpPageComponent implements OnInit {
  @ViewChild('elturnstile') turnstileComponent: TurnstileComponent;
  TiersMode = TiersMode;
  PlanType = PlanType;
  BillingScheme = BillingScheme;
  Interval = Interval;
  showError: boolean;
  errorMsg: string;
  showLoading: boolean;
  industries: any;
  isValidName = false;
  isEmailSent: boolean = false;

  orgId: any;
  password: string;
  industry: string;
  firstName: string;
  lastName: string;
  email: string;
  code: string;
  orgName: string;
  ouCode: any;
  passcode: string;

  // payment
  cardName: string;
  cardAddress: string;
  cardCity: string;
  cardState: string;
  cardZip: string;

  // stripe
  elements: Elements;
  cardNumber: StripeElement;
  cardExpiry: StripeElement;
  cardCVC: StripeElement;
  stripeTest: UntypedFormGroup;
  runInitStripe = true;

  // sub cost calculator
  subTotalInCents = 0;
  extTotalInCents = 0;
  intTotalInCents = 0;

  // products
  products: Product[] = [];
  selectedProduct: Product;

  // plans
  plans: Plan[] = [];
  selectedPlan: Plan; // internal plans
  previousPlan: Plan; // previous selected plan

  // subscription
  intQuantity: number;
  extQuantity: number;

  // coupon
  coupon: Coupon = new Coupon();

  // details
  showIntDetails = false;
  showExtDetails = false;

  matcher = new InputNumberStateMatcher();

  // preset dropdown
  presetInternalQtySettings = [3, 5, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  presetExternalQtySettings = [0, 15, 25, 50, 75, 100, 150, 200, 300, 400, 500, 600, 700, 800, 900, 1000];
  internalQtySettings = [...this.presetInternalQtySettings]
  externalQtySettings = [...this.presetExternalQtySettings]

  //#region getters
  get currentPlanType(): PlanType {
    if (this.selectedProduct) return this.selectedProduct.planType;
    return PlanType.Unknown;
  }

  get isFreePlan(): boolean {
    return this.currentPlanType === PlanType.Free;
  }

  get currentInternalPlan(): Plan {
    return this.selectedPlan;
  }
  get currentExternalPlan(): Plan {
    if (this.selectedProduct && this.selectedProduct.plans) {
      let externalPlan = this.selectedProduct.plans.filter(x => x.productType === ProductType.External &&
        x.interval === this.selectedPlan.interval)[0];
      return externalPlan;
    }
    return null;
  }

  get internalPlanAssignedUnit(): number {
    let plan = this.currentInternalPlan;
    if (plan) return plan.preassignedUnit;
    return null;
  }

  get externalPlanAssignedUnit(): number {
    let plan = this.currentExternalPlan;
    if (plan) return plan.preassignedUnit;
    return null;
  }
  //#endregion

  // Add router animation
  @HostBinding('@routerAnimation') routerAnimation = true;
  constructor(private router: Router, private ouService: OUService, private membershipReqService: MembershipReqService, private fb: UntypedFormBuilder,
    private stripeService: StripeService, private activatedRoute: ActivatedRoute, private userService: UserService,
    public dialog: MatDialog, private snackBar: MatSnackBar,
    private translate: TranslateService) {



    this.ouService.getIndustry().then(res => {
      this.industries = res;
    })
      .catch(error => {
        pocolog.error(error);
        this.showError = true;
        this.errorMsg = error.error;
      });

    this.userService.getProducts().then((res: Product[]) => {
      this.products = res;
      // select free as default
      let freePlan = this.products.filter(x => x.planType === PlanType.Free)[0];
      if (freePlan) {
        this.selectedProduct = freePlan;
        this.initProduct();
        // remember previous plan
        this.previousPlan = { ...this.selectedPlan }
      }
    });
  }

  ngOnInit() {
    this.stripeTest = this.fb.group({
      cardName: ['', [Validators.required]],
      cardAddress: ['', [Validators.required]],
      cardCity: ['', [Validators.required]],
      cardState: ['', [Validators.required]],
      cardZip: ['', [Validators.required]],
    });

    this.activatedRoute.params.subscribe((params: Params) => {
      if (params && params.type) {
        // this.prodType = Parser.parseEnum(params.type, PlanType);
      }
    });
  }

  private initProduct() {
    if (this.selectedProduct.plans) {
      // if there is a previous plan selected, select the plan with same interval
      var interval = this.previousPlan ? this.previousPlan.interval : this.selectedProduct.plans[0].interval;
      // if there is no same interval, select default
      if (!this.selectedProduct.plans.find(x => x.interval === interval)) interval = this.selectedProduct.plans[0].interval;

      let internalPlan = this.selectedProduct.plans.filter(x => x.productType === ProductType.Internal && x.interval === interval)[0];

      let externalPlan = this.selectedProduct.plans.filter(x => x.productType === ProductType.External &&
        x.interval === internalPlan.interval)[0];

      // reset qty dropdown to default
      this.internalQtySettings = [...this.presetInternalQtySettings];
      this.externalQtySettings = [...this.presetExternalQtySettings];
      this.intQuantity = this.internalQtySettings[0];
      this.extQuantity = this.externalQtySettings[0];

      // set preassignedUnit, add preassigned unit to dropdown
      if (internalPlan && internalPlan.preassignedUnit) {
        this.internalQtySettings.push(internalPlan.preassignedUnit);
        this.intQuantity = internalPlan.preassignedUnit;
      }
      if (externalPlan && externalPlan.preassignedUnit) {
        this.externalQtySettings.push(externalPlan.preassignedUnit);
        this.extQuantity = externalPlan.preassignedUnit;
      }
      // set plans
      this.plans = this.selectedProduct.plans.filter(x => x.productType === ProductType.Internal);
      this.selectedPlan = this.plans.find(x => x.planId === internalPlan.planId);
    }
  }

  initPaymentForm() {
    if (this.runInitStripe) {
      this.runInitStripe = false;
      this.stripeService.elements({
        // Stripe's examples are localized to specific languages, but if
        // you wish to have Elements automatically detect your user's locale,
        // use `locale: 'auto'` instead.
        locale: 'auto'
      }).subscribe(elements => {
        // Floating labels
        var elementStyles = {
          base: {
            '::placeholder': {
              color: 'grey'
            }
          }
        };

        var elementClasses: ELStripeElementOptions = {
          style: elementStyles,
          classes: {
            focus: 'focused',
            empty: 'empty',
            invalid: 'invalid',
          }
        };

        if (!this.cardNumber) {
          this.cardNumber = elements.create('cardNumber', elementClasses);
          this.cardNumber.mount('#example2-card-number');
        }

        if (!this.cardExpiry) {
          this.cardExpiry = elements.create('cardExpiry', elementClasses);
          this.cardExpiry.mount('#example2-card-expiry');
        }
        if (!this.cardCVC) {
          this.cardCVC = elements.create('cardCvc', elementClasses);
          this.cardCVC.mount('#example2-card-cvc');
        }
      });
    }
  }

  async register(cardToken = null) {
    this.showLoading = true;
    this.showError = false;
    this.errorMsg = '';
    // let regDto: RegistrationDto = {
    //   email: this.email,
    //   password: this.password,
    //   firstName: this.firstName,
    //   lastName: this.lastName,
    //   isTermAgree: true,
    //   passcode: this.passcode,
    //   ouName: this.orgName,
    //   ouCode: this.ouCode,
    //   industryId: this.industry,
    //   planId: this.selectedPlan ? this.selectedPlan.planId : null
    // }

    // if (cardToken) {
    //   regDto.paymentToken = cardToken;
    //   regDto.internalQuantity = this.intQuantity;
    //   regDto.externalQuantity = this.extQuantity;
    // }

    // if (this.coupon && this.coupon.isValid)
    //   regDto.couponId = this.coupon.couponId;
    var token = await this.turnstileComponent.getToken();
    this.membershipReqService
      .reqSignUp(this.email, this.firstName, this.lastName, token)
      .then(res => {
        this.showLoading = false;
        this.isEmailSent = true;
        // this.authService.sendAccountVerificationEmail(this.email)
        // .then(res=>{
        //   this.isEmailSent = true;
        // });

        // redirect to login page to verify
        // this.router.navigateByUrl("/login?status=verify");
      })
      .catch(err => {
        pocolog.error(err);
        this.showLoading = false;
        this.showError = true;
        if (err.error && err.error.error) {
          this.errorMsg = err.error.error;
        } else {
          this.errorMsg =
            "An unexpected error has occured. Please contact support if problem persists";
        }
      });
    gtag('event', 'conversion', {'send_to': 'AW-640313908/P9hwCMHBqbgDELTUqbEC'});
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogEnterEmailComponent, {
      width: "250px"
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) this.resendEmail(result);
    });
  }

  async resendEmail(email: string) {
    var token = await this.turnstileComponent.getToken();
    this.membershipReqService.resendSignUpEmail(email,true,token).then(res => {
      this.openSnackBar(this.translate.instant("REPEAT.EMAIL_RESEND_SUCCESS"), "OK");
      this.isEmailSent = true;
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

  buy() {
    this.showLoading = true;
    let additionalData: CardDataOptions = {
      name: this.stripeTest.get('cardName').value,
      address_line1: this.stripeTest.get('cardAddress').value,
      address_city: this.stripeTest.get('cardCity').value,
      address_state: this.stripeTest.get('cardState').value,
      address_zip: this.stripeTest.get('cardZip').value,
    }

    this.stripeService
      .createToken(this.cardNumber, additionalData)
      .subscribe(result => {
        if (result.token) {
          // Use the token to create a charge or a customer
          // https://stripe.com/docs/charges
          console.log(result.token);
          this.confirmOrder(result.token);
        } else if (result.error) {
          this.showLoading = false;
          this.errorMsg = result.error.message;
          this.showError = true;
          // Error creating the token
          console.log(result.error.message);
        }
      });
  }

  //#region events
  onChangeTab($event) {
    if ($event.index === 3) {
      this.initPaymentForm();
    }
  }

  onChangeProduct($event) {
    this.coupon = new Coupon();
    this.initProduct();
    // remember previous plan
    this.previousPlan = { ...this.selectedPlan }
  }
  onChangePlan($event) {
    this.coupon = new Coupon();
    // remember previous plan
    this.previousPlan = { ...this.selectedPlan }
  }
  ////#endregion

  //#region calculator
  updateExtTotal($event) {
    this.extTotalInCents = $event;
    this.subTotalInCents = $event + this.intTotalInCents;
  }
  updateIntTotal($event) {
    this.intTotalInCents = $event;
    this.subTotalInCents = $event + this.extTotalInCents;
  }
  ////#endregion

  confirmOrder(token) {
    const dialogRef = this.dialog.open(DialogOrderConfirmationComponent, {
      data: {
        plan: this.selectedPlan,
        intQuantity: this.intQuantity,
        extQuantity: this.extQuantity,
        total: this.subTotalInCents,
        intTotal: this.intTotalInCents,
        extTotal: this.extTotalInCents,
        card: token ? token.card : null
      },
      width: "600px"
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res && res.status === 'success') {
        this.register(token.id);
      } else {
        this.showLoading = false;
      }
    });
  }

  couponSuccessEvent($event) {
    let couponId = this.coupon.couponId
    this.coupon = new Coupon(); // new obj to trigger product-calc-table ngOnChange
    this.coupon.couponId = couponId;
    if ($event) {
      this.coupon.isValid = true;
      this.coupon.name = $event.name;
      this.coupon.percentOff = $event.percent_off;
    } else {
      this.coupon.isValid = false;
      this.coupon.percentOff = null;
      this.coupon.name = null;
    }
  }
}
