import { UserDataSelector } from './../../core/states/user-data.state.selector';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppState } from '../../core/states/app.state';
import { RedirectService } from '../../core/services/redirect.service';

@Component({
  selector: 'app-redirect-page',
  templateUrl: './redirect-page.component.html',
  styleUrls: ['./redirect-page.component.scss']
})
export class RedirectPageComponent implements OnInit {

  //globalLoaderText: string = "Redirecting..."; 

  constructor(
    private appState: AppState,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private redirectService: RedirectService
  ) { }

  ngOnInit(): void {
    this.appState.ready().then(() => {
      this.activatedRoute.params.subscribe((params: Params) => {
        let param = params.token;
        if (param) {
          this.alertMailHandler(param);
        } else {
          this.checkQueryParams(); 
        }
      });
    })
  }

  checkQueryParams() {
    this.activatedRoute.queryParams.subscribe((qParams: Params) => {
      if (qParams) {
        const isFolder = qParams.isFile.toLowerCase() === 'false';
        const email = qParams.email ? atob(qParams.email) : null;
        this.redirectService.initStorageLinkData(qParams.orgId, qParams.path, isFolder, qParams.storageType, qParams.ownerId, email, qParams.driveId);
        this.redirectService.handleAllStorageLink();
      } else {
        this.router.navigateByUrl("/main");
      }
    });
  }

  private alertMailHandler(param: string) {
    const decodedStr = atob(param);
    console.log(decodedStr);
    var data = JSON.parse(decodedStr);
    console.log(data);
    
    this.redirectService.alertMailHandler(data);
  }


}
