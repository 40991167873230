import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LibreOfficeSessionService {

  constructor() { }
  set(key: any, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  get(key: any) {
    return JSON.parse(sessionStorage.getItem(key));
  }

  remove(key: any) {
    sessionStorage.removeItem(key);
  }
}
