import { pairwise } from 'rxjs/operators';

import { Injectable, Injector } from "@angular/core";
import { Route, Router } from "@angular/router";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { PubSub } from "../core/services/pubsub.service";
import { MenuItem } from "../core/ui/menu-item";

import { Plugin } from "./plugin.abstract";
import { SamplePlugin } from "./sample-plugin/sample.plugin";
import { USTaxPlugin } from "./ustax/ustax.plugin";
import { EzSignPlugin } from "./ezsign/ezsign.plugin";
import { IETPlugIn } from "./income-expense/iet.plugin";
import { HealthCheckPlugin } from "./health-check-infotracker/healthcheck.plugin";
import * as _ from "lodash";
import { ReproductionPlugin } from "./reproduction/reproduction.plugin";
import { ClmPlugin } from "./clm/reproduction.plugin"
import { ELNetPlugin } from './elnet/elnet.plugin';
import { SubSink } from "subsink";
import { Dictionary } from "../core/util/dictionary";
import { OpenAIPlugin } from "./openai/openai.plugin";
//mport { ClmPlugin } from "./clm/reproduction.plugin"
import { ContractManagementPlugin } from "./contractManagement/contractManagement.plugin";

@Injectable({
    providedIn: 'root'
})
export class PluginManager {
    private _parentRoute: string = "tools";
    private _pubSubSink: SubSink = new SubSink();
    private _menuUpdateSubSink: SubSink = new SubSink();
    public static readonly EXT_PREFIX: string = "ext-";

    public plugins: Plugin[] = [];
    public onMenuUpdated$: BehaviorSubject<MenuItem[]>;
    public onLaunchRedirect$: Subject<string>;


    constructor(private pubSub: PubSub
        , private injector: Injector) {
        console.log("[PluginManager] hello %s", new Date());
        this.onMenuUpdated$ = new BehaviorSubject<MenuItem[]>([]);
        this.onLaunchRedirect$ = new Subject<string>();
        this._menuUpdateSubSink = new SubSink();
    }

    load() {
        console.time("[PluginManager] load");
        this.buildPlugins();
        //this.buildRoutes();
        this.handlePubSubEvents();
        console.timeEnd("[PluginManager] load");
    }

    get(appId: string) {
        var idx = this.plugins.findIndex(i => i.appId == appId);
        if (idx != -1) {
            return this.plugins[idx];
        } else {
            return null;
        }
    }

    getLaunchToRedirectPlugin(): Plugin {
        //order by priority
        var founds = _.filter(this.plugins, (i: Plugin) => i.launchToRedirectEnabled && i.launchToRedirectPath);
        //console.log("plugins: %o", this.plugins);
        //console.log("founds: %o", founds);
        if (!founds || founds.length == 0) return null;
        var sorted = _.orderBy(founds, (i: Plugin) => i.launchToRedirectPriority, ['desc']);
        if (!sorted || sorted.length == 0) return null;

        return sorted[0];
    }

    private add(plugin: Plugin) {
        var idx = this.plugins.findIndex(i => i.appId == plugin.appId);
        if (idx == -1) {
            this.plugins.push(plugin);
        } else {
            this.plugins[idx] = plugin;
        }
    }

    private buildPlugins() {
        if (this._menuUpdateSubSink) this._menuUpdateSubSink.unsubscribe();

        // sample plugin
        // this.includePlugin(SamplePlugin);
        // this.includePlugin(USTaxPlugin);
        // this.includePlugin(EzSignPlugin);
        // this.includePlugin(IETPlugIn);
        //temporary workaround to hide HealthCheck
        // this.includePlugin(HealthCheckPlugin);
         this.includePlugin(ReproductionPlugin);
         //this.includePlugin(ClmPlugin);
         this.includePlugin(ContractManagementPlugin);
        // this.includePlugin(ELNetPlugin);
        console.log("[PluginManager] total plugins: %o", this.plugins);
    }

    private includePlugin<T extends Plugin>(pluginType: new (injector: Injector) => T) {
        let plugin = new pluginType(this.injector);

        this._menuUpdateSubSink.sink = plugin.onMenuUpdated$.subscribe(menu => this.onMenuUpdated$.next(menu));
        this.add(plugin);
    }

    private buildRoutes() {
        // this.plugins.forEach(plugin => {
        //     var mainRouteIdx = config.findIndex(i => i.path == "main");
        //     console.log("[PluginManager] main: %o", config[mainRouteIdx]);

        //     var toolsRouteIdx = config[mainRouteIdx].children.findIndex(i => i.path == this._parentRoute);
        //     console.log("[PluginManager] toolsRoute: %o", config[mainRouteIdx].children[toolsRouteIdx]);

        //     var toolsRoute = config[mainRouteIdx].children[toolsRouteIdx];
        //     var index = toolsRoute.children ? toolsRoute.children.findIndex(i => i.path == plugin.path) : -1;

        //     if (index == -1) {
        //         let r: Route = {
        //             path: plugin.path,
        //             loadChildren: () => plugin.loadModule,
        //             data: {
        //                 plugin: plugin.appId
        //             }
        //         };
        //         if (!toolsRoute.children) {
        //             toolsRoute.children = [];
        //         }
        //         toolsRoute.children.push(r);
        //         this.router.resetConfig(this.router.config);
        //         console.log("[PluginManager] buid route: %o", this.router.config);
        //     } else {
        //         console.log("[PluginManager] route exists: %s", plugin.appId);
        //     }
        // });

    }

    private getEnabledPlugins(toolsDict: Dictionary<string[]>) {
        return this.plugins.filter(p => toolsDict[p.appId] ? toolsDict[p.appId] : false);
    }

    private handlePubSubEvents() {
        if (this._pubSubSink) this._pubSubSink.unsubscribe();

        console.log("[PluginManager] handlePubSubEvents")
        this._pubSubSink.sink = this.pubSub.subscribe(PubSub.ON_ORG_SWITCHED, data => {
            console.log("[PluginManager] [check tools] ON_ORG_SWITCHED data: %o", data);
            if (!data || !this.plugins) return;
            console.log("[PluginManager] ON_ORG_SWITCHED all plugins: %o", this.plugins);
            //temporary code; delete after database initialization
            for (let plugin in this.plugins) {
                if (this.plugins[plugin].appId == "ext-openai-plugin") {
                    data.extTools['ext-openai-plugin'] = true
                }
            }
            //end temporary code
            let enabledPlugins = this.getEnabledPlugins(data.extTools);
            console.log("[PluginManager] ON_ORG_SWITCHED enabledPlugins: %o", enabledPlugins);
            for (let plugin of _.orderBy(enabledPlugins, (i: Plugin) => i.launchToRedirectPriority, ['desc'])) {
                console.log("[PluginManager] [check tools]onOrgSwitched begin: %o", { plugin: plugin.appId, org: data.name });
                this._pubSubSink.sink = plugin.onOrgSwitched(data)
                    .then(o => {
                        console.log("[PluginManager] onOrgSwitched finish: %o", { plugin: plugin.appId, org: data.name });
                        // console.log("[PluginManager] getLaunchToRedirectPlugin");
                        var launchToRedirectPlugin = this.getLaunchToRedirectPlugin();

                        if (launchToRedirectPlugin && launchToRedirectPlugin.launchToRedirectPath
                            && plugin.appId == launchToRedirectPlugin.appId) {
                            console.log("[PluginManager] launch plugin found: %o", { plugin: plugin.appId, path: plugin.launchToRedirectPath });
                            this.onLaunchRedirect$.next(plugin.launchToRedirectPath);
                        }
                    })
                    .catch(err => console.error("[PluginManager] error: %o", err));
            }

        });

        this._pubSubSink.sink = this.pubSub.subscribe(PubSub.ON_EXT_FEED_RECEIVED, (dataList: any) => {
            console.log("[PluginManager] ON_EXT_FEED_RECEIVED: %o", dataList);
            if (!dataList) { return };

            try {
                var grouped = _.groupBy(dataList, i => i.event);

                var mapped = _.map(grouped, (value: any, key: string) => ({ event: key, payloads: value }));
                mapped = _.map(mapped, a => ({ event: a.event, payloads: a.payloads ? a.payloads.map(i => i.payload) : [] }));
                console.log("[PluginManager] group by: %o", { grouped, mapped });

                this.plugins.forEach(plugin => {
                    var found = _.find(mapped, m => m.event == plugin.appId);
                    if (found && _.startsWith(found.event, PluginManager.EXT_PREFIX)) {
                        console.log("[PluginManager] push to plugin: %o", { appId: found.event, payloads: found.payloads });
                        plugin.onFeedsReceived(found.payloads);
                    }
                });
            } catch (e) {
                console.error(e);
            }
        });

        this._pubSubSink.sink = this.pubSub.sub<{
            id: string;
            name: string;
            isPersonal: boolean;
            industryId: string;
            userId: string;
            role: string;
            extTools: Dictionary<boolean>
          }>(PubSub.ON_TOOLS_UPDATED)
        .pipe(pairwise()).subscribe(([prev, curr])=>{
            const keys = Object.getOwnPropertyNames(curr.extTools);
            keys.forEach((key) => {
              if (prev) {
                if (typeof prev.extTools[key] !== "undefined") {
                  if (prev.extTools[key] !== curr.extTools[key]) {
                    let plugin = this.get(key);
                    this.updatePlugin(plugin, curr, curr.extTools[key] == true)
                  }
                }
              } else {
                let plugin = this.get(key);
                this.updatePlugin(plugin, curr, curr.extTools[key] == true)
              }
            });
        });

        // this._pubSubSink.sink = this.pubSub.subscribe(PubSub.ON_TOOLS_UPDATED, data => {
        //     console.log("[PluginManager] ON_TOOLS_UPDATED %o", data);
        //     if (!data || !this.plugins) return;
        //     let enabledPlugins = this.getEnabledPlugins(data.extTools);
        //     console.log("[PluginManager] ON_TOOLS_UPDATED %o", enabledPlugins);
        //     enabledPlugins.forEach(plugin => {
        //         try {
        //             plugin.onOrgSwitched(data);
        //         } catch (e) {
        //             console.error(e);
        //         }

        //     });
        // });
    }

    private updatePlugin(plugin: Plugin, payload: any, enabled: boolean){
        if (!plugin) return;
        if (enabled) {
          if (plugin.onOrgSwitched) {
            plugin.onOrgSwitched(payload);
          }
        } else {
          if (plugin.onToolDisabled) {
            plugin.onToolDisabled();
          }
        }
    }

    public stop() {
        if (this._pubSubSink) this._pubSubSink.unsubscribe();
        if (this._menuUpdateSubSink) this._menuUpdateSubSink.unsubscribe();
    }
}
