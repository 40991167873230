import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrgHub } from '../../../../core/hub/org.hub';
import { EnterpriseSelector } from '../../../../core/states/enterprise.state.selector';
import { ChannelState } from '../../../../core/model/org.state';

@Component({
  selector: "app-dialog-delete-channel",
  templateUrl: "./dialog-delete-channel.component.html",
  styleUrls: ["./dialog-delete-channel.component.scss"]
})
export class DialogDeleteChannelComponent implements OnInit {
  isLoading = false;
  channel: ChannelState;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogDeleteChannelComponent>, private orgHub: OrgHub,
    private enterpriseSelector: EnterpriseSelector) { }

  ngOnInit() {
    this.channel = this.enterpriseSelector.getChannel(this.data.channelId);
  }

  deleteChannel() {
    this.isLoading = true;
    this.orgHub.deleteChannel(this.channel.id)
      .subscribe(res => {
        this.isLoading = false;
        if (!res) {
          this.dialogRef.close(res);
        } else {
          this.isLoading = false;
          this.dialogRef.close({ status: "success" });
        }
      },
        err => {
          this.isLoading = false;
          this.dialogRef.close(err);
        });
  }
}
