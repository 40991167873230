import { Channel } from "./channel.model";
import { PostChat } from "./postchat.model";
import { EntityStatus } from "../enum/entity-status.enum";
import { environment } from '../../../environments/environment';
import { FilenameToMimeType } from '../util/filename-to-mimetype';

export class Post {
    postId: string;
    content: string;
    channelId: string;
    channel: Channel;
    fileUrl: string;
    chats: PostChat[];
    senderId: string;
    sender: any;
    fileName: string;
    fileExtension: string;
    channelName: string;
    totalLike: number;
    totalChat: number;
    totalFile: number;
    groupId: string;
    groupName: string;
    participantIds: string[];
    roomId: string;
    imageUrl: string;
    chatIds: string[];
    orgUnitId: string;
    tenantId: string;
    createdOn: Date;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
    entityStatus: EntityStatus;
    statusName: string;
    fwt: string;
    fwtEncoded: boolean;

    constructor() {
    }

    static parseList(data: any[]) {
        let result: Post[] = [];
        if (data) {
            data.forEach(d => {
                let msg = this.parse(d);

                result.push(msg);
            });
        }
        return result;
    }

    static parse(d: any) {
        if (d) {
            let post = new Post();

            post.postId = d.postId;
            post.content = d.content;
            post.channelId = d.channelId;
            post.channel = d.channel;
            post.fileUrl = d.fileUrl;
            post.chats = d.chats;
            post.senderId = d.senderId;
            post.sender = d.sender;
            post.fileName = d.fileName;
            post.fileExtension = d.fileExtension;
            post.channelName = d.channelName;
            post.totalLike = d.totalFile;
            post.totalChat = d.totalChat;
            post.totalFile = d.totalFile;
            post.groupId = d.groupId;
            post.groupName = d.groupName;
            post.participantIds = d.participantIds;
            post.roomId = d.roomId;
            post.imageUrl = d.imageUrl;
            post.chatIds = d.chatIds;
            post.orgUnitId = d.orgUnitId;
            post.tenantId = d.tenantId;
            post.createdOn = d.createdOn;
            post.createdBy = d.createdBy;
            post.modifiedOn = d.modifiedOn;
            post.modifiedBy = d.modifiedBy;
            post.entityStatus = d.entityStatus;
            post.statusName = d.statusName;
            post.fwt = d.fwt;
            post.fwtEncoded = d.fwtEncoded;
            post.chats = PostChat.parseList(d.chats);

            return post;
        }
    }

    getFileUrl(): string {
        if (this.fwtEncoded) {
            // get download link
            // let storageLink = environment.storageLink + this.mediaUrl;
            let storageLink = environment.apiLink + '/media/download?fwt=' + this.fwt;
            return storageLink;
        } else {
            return this.imageUrl;
        }
    }

    getMimeType(): string {
        // get from filename
        if (this.fileName) {
            return FilenameToMimeType.Convert(this.fileName);
        }
    }
}
