import { ParticipantStatus } from "./participant.model";

export class ParticipantState {
  roomId: string; //key
  userId: string; //key
  matrixId: string;
  firstName: string;
  lastName: string;
  isLeaved: boolean;
  status: ParticipantStatus;
  isRoomHidden: boolean;
}
