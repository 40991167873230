
export class CardState {
  cardId: string;
  orgId: string; //rootOuId
  avatarUrl: string;
  module: string;
  title: string;
  subTitle: string;
  mediaUrl: string;
  mediaName: string;
  description: string;
  actions: string[];
  createdOn: string;
  createdBy: string;
  mediaId: string;
  isFile: boolean;
  isImage: boolean;
  isSystem: boolean;
  fwt: string;
  fwtEncoded: boolean;
  dismissWhenClick: boolean;//if false, only clicking dismiss button will clear the card

  constructor() {
    this.fwtEncoded = false;
    this.dismissWhenClick = true;
  }
}
