import { Channel } from "./channel.model";
import { EntityStatus } from "../enum/entity-status.enum";
import { environment } from '../../../environments/environment';
import { FilenameToMimeType } from '../util/filename-to-mimetype';

export class PostChat {
    postChatId: string;
    content: string;
    postId: string;
    post: any;
    senderId: string;
    sender: any;
    fileName: string;
    mediaUrl: string;
    mediaId: string;
    linkPosition: number;
    type: PostChatType;
    mediaSize: number;
    orgUnitId: string;
    groupId: string;
    tenantId: string;
    createdOn: Date;
    serverTimeStamp: number;
    createdBy: string;
    modifiedOn: Date;
    modifiedBy: string;
    entityStatus: EntityStatus;

    tempId: string;
    statusName: string;
    fwt: string;
    fwtEncoded: boolean;

    constructor() {
    }

    static parse(d: any) {
        if (d) {
            let post = new PostChat();

            post.postChatId = d.postChatId;
            post.content = d.content;
            post.postId = d.postId;
            post.post = d.post;
            post.senderId = d.senderId;
            post.sender = d.sender;
            post.fileName = d.fileName;
            post.mediaUrl = d.mediaUrl;
            post.mediaId = d.mediaId;
            post.linkPosition = d.linkPosition;
            post.type = d.type;
            post.mediaSize = d.mediaSize;
            post.orgUnitId = d.orgUnitId;
            post.groupId = d.groupId;
            post.tenantId = d.tenantId;
            post.createdOn = d.createdOn;
            post.serverTimeStamp = d.serverTimeStamp;
            post.createdBy = d.createdBy;
            post.modifiedOn = d.modifiedOn;
            post.modifiedBy = d.modifiedBy;
            post.entityStatus = d.entityStatus;
            post.tempId = d.tempId;
            post.statusName = d.statusName;
            post.fwt = d.fwt;
            post.fwtEncoded = d.fwtEncoded;

            return post;
        }
    }

    static parseList(data: any[]) {
        let result: PostChat[] = [];
        if (data) {
            data.forEach(d => {
                let msg = this.parse(d);

                result.push(msg);
            });
        }
        return result;
    }

    getFileUrl(): string {
        if (this.fwtEncoded) {
            // get download link
            // let storageLink = environment.storageLink + this.mediaUrl;
            let storageLink = environment.apiLink + '/media/download?fwt=' + this.fwt;
            return storageLink;
        } else {
            return this.mediaUrl;
        }
    }

    getMimeType(): string {
        // get from filename
        if (this.fileName) {
            return FilenameToMimeType.Convert(this.fileName);
        }
    }
}


export enum PostChatType {
    Image = 1, File = 2, Text = 0
}
