import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class FileRenderer {
  renderFileName(name: string, maxLength: number = 50) {
    try {
      if (!name) return "";
      if (name.length <= maxLength) return name;

      return (
        name.split(".").slice(0, -1).join(".").substr(0, maxLength) +
        "..." +
        name.split(".").splice(-1)
      );
    } catch {
      return name;
    }
  }
}
