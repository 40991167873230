import { PluginManager } from "../../tools/plugin.manager";
import * as _ from "lodash";
import { ELRedirectHandler } from "./ELRedirectHandler";
import { ExternalRedirectHandler } from "./ExternalRedirectHandler";

export class RedirectHandler {
  static map(event: string) {
    if (_.startsWith(event, PluginManager.EXT_PREFIX)) {
      return ExternalRedirectHandler;
    } else {
      return ELRedirectHandler;
    }
  }
}
